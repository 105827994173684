import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ObjectIdentifier, WidgetData,} from "@kvers/alpha-core-common";
import {CalculationLogicTypeService} from "../../service/api/calculation-logic-type.service";
import {LgCalculationLogicTypeObjectInterface} from "../object-components/calculation-logic-type-object-component/lg-calculation-logic-type-object.interface";
import {MvsUiObjectService} from "@kvers/alpha-ui";

@Component({
    selector: 'lg-calculation-logic-type-page-component',
    templateUrl: 'lg-calculation-logic-type-page.component.html',
    styleUrls: ['lg-calculation-logic-type-page.component.scss']
})
export class LgCalculationLogicTypePageComponent implements OnInit, OnChanges, LgCalculationLogicTypeObjectInterface {

    @Input() objectIdentifier: ObjectIdentifier;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    calculationLogicTypeFieldWidgetData: WidgetData;


    constructor(
        protected calculationLogicTypeService: CalculationLogicTypeService,
        protected objectService: MvsUiObjectService,
    ) {
    }

    ngOnInit(): void {

        this.refreshWidgets();
        this.initialized = true;

    }


    refreshWidgets() {
        this.refreshCalculationLogicTypeFieldWidgetData();
    }


    refreshCalculationLogicTypeFieldWidgetData() {
        this.calculationLogicTypeFieldWidgetData = this.calculationLogicTypeService.getCalculationLogicTypeFieldWidget(this.objectIdentifier.objectId);
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshWidgets();
    }

}
