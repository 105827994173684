import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {
    DtoDetail,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum, ObjectRequestComplex, ObjectRequestComplexNode,
    WidgetData,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {CustomerContractService} from "../../../../service/api/customer-contract.service";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";


@Component({
    selector: 'cr-customer-external-ids',
    templateUrl: './cr-customer-external-ids.component.html',
})
export class CrCustomerExternalIdsComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() viewType: string;

    customerExternalAlias: WidgetData;


    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected navigationService:MvsObjectNavigationService,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected customerService: CustomerService,
                protected customerContractService: CustomerContractService,
                protected configService: MvsConfigService,
                ) {
        super(coreService, confirmationService, messageService, configService,navigationService);

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshWidgets();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {

        const customerFilter = [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];
        const objectRequest = new ObjectRequestList(true, customerFilter, []);
        objectRequest.objectRequestComplex = ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createSimpleAttributeNode('system'));
        this.customerExternalAlias = WidgetFactory.createWidgetList(
            "cr.customer.external.alias.basic",
            "External Alias",
            'table',
            'list',
            'entity',
            "cr.CustomerExternalAlias",
            "Keine Daten vorhanden",
            objectRequest
        );
        this.customerExternalAlias.functionCallbacks = this.customerFunctionCallback();

    }

    customerFunctionCallback() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['customerDtoId'] = this.objectIdentifier.objectId;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

}
