import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {
    FilterCriteria,
    ObjectBaseComponent,
    ObjectIdentifierData,
    ObjectRequestList,
    WidgetData,
    MvsCoreService,
    MvsMessageService,
    WidgetHelperButton,
    ObjectIdentifier,
    DtoDetail,
    WidgetToolbarCallbackInterface,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    WidgetDataParam
} from '@kvers/alpha-core-common';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {WidgetFactory} from '@kvers/alpha-ui';
import {TicketTypeService} from '../../../service/api/ticket-type.service';
import {ObserverService} from "@kvers/alpha-core-common";
import {TmTicketTypeActionDto} from "../../../dto/tm-ticket-type-action.dto";
import {TicketTypeActionTypeInternal} from "../../../enum/ticket-type-action-type-internal.enum";
import {TicketTypeDto} from "../../../dto/ticket-type.dto";
import {TicketTypeActionService} from "../../../service/api/ticket-type-action.service";
import {TicketTypeActionDto} from "../../../dto/ticket-type-action.dto";
import {TicketTypeObjectRoleService} from "../../../service/api/ticket-type-object-role.service";
import {TicketTypeObjectTypeService} from "../../../service/api/ticket-type-object-type.service";
import {TicketTypeNotificationTypeService} from "../../../service/api/ticket-type-notification-type.service";
import {TicketTypeCompleteStatusService} from "../../../service/api/ticket-type-complete-status.service";
import {TicketTypeObjectRoleDto} from "../../../dto/ticket-type-object-role.dto";
import {TicketTypeObjectTypeDto} from "../../../dto/ticket-type-object-type.dto";
import {TicketTypeNotificationTypeDto} from "../../../dto/ticket-type-notification-type.dto";
import {TicketTypeCompleteStatusDto} from "../../../dto/ticket-type-complete-status.dto";


@Component({
    selector: 'ticket-type-object-component',
    templateUrl: './ticket-type-object.component.html',
    styleUrls: ['./ticket-type-object.component.scss'],
})
export class TicketTypeObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    sideBarMenuItems: MenuItem[];
    breadCrumbItems: MenuItem[] = [];
    selectedItem: MenuItem;
    objectViewWidget: WidgetData;
    objectWidget: WidgetData;

    ticketTypeWidget: WidgetData;
    ticketTypeUpdateWidget: WidgetData;
    ticketTypeActionWidget: WidgetData;
    ticketTypeActionTicketMappingWidget: WidgetData;
    ticketTypeActionProcessMappingWidget: WidgetData;
    ticketTypeObjectRoleWidget: WidgetData;
    ticketTypeObjectTypeWidget: WidgetData;
    ticketTypeNotificationTypeWidget: WidgetData;
    ticketTypeNotificationTypeMappingWidget: WidgetData;
    ticketTypeCompletionStatusWidget: WidgetData;
    ticketTypeFieldWidget: WidgetData;

    widgetToolbarCreateButtons: WidgetHelperButton[];
    widgetToolbarUpdateButtons: WidgetHelperButton[];
    ticketTypeToolbarButton: WidgetHelperButton[];
    ticketTypeUpdateToolbarButton: WidgetHelperButton[];

    ticketTypes: TicketTypeDto[] = [];
    ticketTypeActionList: TicketTypeActionDto[];
    ticketTypeObjectRoleList: TicketTypeObjectRoleDto[];
    ticketTypeObjectTypeList: TicketTypeObjectTypeDto[];
    ticketTypeNotificationTypeList: TicketTypeNotificationTypeDto[];
    ticketTypeCompletionList: TicketTypeCompleteStatusDto[];

    ticketTypeActionInternalType: TicketTypeActionTypeInternal;
    protected readonly TicketTypeActionTypeInternal = TicketTypeActionTypeInternal;
    selectedObject: ObjectIdentifier;

    activeIndex: number;
    showBreadCrumb: boolean = false;
    isTicketTypeUpdate: boolean;
    isCreateMode: boolean;
    selectedObjectType: string;


    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected observerService: ObserverService,
        protected ticketTypeService: TicketTypeService,
        protected ticketTypeActionService: TicketTypeActionService,
        protected ticketTypeObjectRoleService: TicketTypeObjectRoleService,
        protected ticketTypeObjectTypeService: TicketTypeObjectTypeService,
        protected ticketTypeNotificationTypeService: TicketTypeNotificationTypeService,
        protected ticketTypeCompletionStatusService: TicketTypeCompleteStatusService,
    ) {
        super(coreService, messageService, confirmationService, observerService);
    }

    /**
     * On Object Change. When object is retrieved
     */
    onObjectChanged() {

        // initialize the widget toolbar buttons
        this.initWidgetToolbarButtons();


        // push the object to breadcrumb
        this.breadCrumbItems.push({
            icon: this.dto['image'],
            label: this.dto['name'],
            id: this.dto.id as unknown as string,
        });

        // initialize all widgets
        this.refreshWidgets();

        // initialize navigation items
        this.loadSideBarMenuItems();

        // checks if ticket type has parent then gets all of its parent
        if (this.dto['parentDtoId']) {
            this.getTicketType(this.dto['parentDtoId'])
        } else {
            this.breadCrumbItems[0]['lastItem'] = true;
            this.showBreadCrumb = true;
        }

        // setting tab title
        const title = `${this.dto.name} - Konfiguration`;

        this.setPageTitle(title);
    }

    /**
     * load and initialize sidebar navigations
     */
    loadSideBarMenuItems() {
        this.sideBarMenuItems = [
            {
                id: 'tm.TicketType',
                label: 'Ticket Type',
                icon: 'fa-regular fa-typewriter text-xl',
                tooltip: 'Ticket Type',
                anchorId: 'TicketType'
            },
            {
                id: 'tm.TicketTypeAction',
                label: 'Ticket Type Action',
                icon: 'fa-solid fa-hand-point-right text-xl',
                tooltip: 'Ticket Type Action',
                anchorId: 'TicketTypeAction'
            },
            {
                id: 'tm.TicketTypeObjectRole',
                label: 'Ticket Type Object Role',
                icon: 'fa-solid fa-user-tag text-xl',
                tooltip: 'Ticket Type Object Role',
                anchorId: 'TicketTypeObjectRole'
            },
            {
                id: 'tm.TicketTypeObjectType',
                label: 'Ticket Type Object Type',
                icon: 'fa-solid fa-layer-group text-xl',
                tooltip: 'Ticket Type Object Type',
                anchorId: 'TicketTypeObjectType'
            },
            {
                id: 'tm.TicketTypeNotificationType',
                label: 'Ticket Type Notification Type',
                icon: 'fa-regular fa-bell text-xl',
                tooltip: 'Ticket Type Notification Type',
                anchorId: 'TicketTypeNotificationType'
            },
            {
                id: 'tm.TicketTypeCompleteStatus',
                label: 'Ticket Type Completion Status',
                icon: 'fa-solid fa-check-circle text-xl',
                tooltip: 'Ticket Type Completion Status',
                anchorId: 'TicketTypeCompleteStatus'
            },
            {
                id: 'tm.TicketTypeField',
                label: 'Ticket Type Field',
                icon: 'fa-solid fa-list-alt text-xl',
                tooltip: 'Ticket Type Field',
                anchorId: 'TicketTypeField'
            }
        ];

        this.selectedItem = this.sideBarMenuItems[0];
    }

    /**
     * initialize widget toolbar buttons
     */
    initWidgetToolbarButtons() {
        this.widgetToolbarCreateButtons = [
            {
                label: null,
                icon: 'fa-regular fa-plus text-primary',
                display: true,
                action: 'create',
                tooltip: 'Create'
            }
        ];

        this.widgetToolbarUpdateButtons = [
            {
                label: null,
                icon: 'fa-light fa-pen-to-square text-primary',
                display: true,
                action: 'update',
                tooltip: 'Edit'
            }
        ];

        this.ticketTypeToolbarButton = [
            {
                label: null,
                icon: 'fa-light fa-pen-to-square text-primary',
                display: true,
                action: 'ticketToggle',
                tooltip: 'Edit'
            }
        ];

        this.ticketTypeUpdateToolbarButton = [
            {
                label: null,
                icon: 'fa-light fa-table text-primary',
                display: true,
                action: 'ticketToggle',
                tooltip: 'Data View'
            }
        ];
    }

    /**
     * handle when an object is ticket type action is selected
     * @param object
     */
    handleTicketTypeAction(object: ObjectIdentifierData) {

        this.ticketTypeActionTicketMappingWidget = null;
        this.ticketTypeActionProcessMappingWidget = null;

        const ticketTypeAction = <TmTicketTypeActionDto>object.data;
        this.clearWidget();
        this.ticketTypeActionInternalType = ticketTypeAction['internalType'];

        const filterCriteria = FilterCriteria.create('ticketTypeAction', FilterCriteria.cOperatorEqual, object.objectId)
        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        if (this.ticketTypeActionInternalType == TicketTypeActionTypeInternal.ticket) {
            this.refreshTicketTypeActionTicketMappingWidget(objectRequestList, object.objectId);
        } else if (this.ticketTypeActionInternalType == TicketTypeActionTypeInternal.process) {
            this.refreshTicketTypeActionProcessMappingWidget(objectRequestList, object.objectId);
        }
        this.handleObjectViewWidget(object, false);

    }

    handleTicketTypeNotificationType(object: ObjectIdentifierData) {

        const filterCriteria = FilterCriteria.create('ticketTypeNotificationType', FilterCriteria.cOperatorEqual, object.objectId)
        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.ticketTypeNotificationTypeMappingWidget = WidgetFactory.createWidgetTableEntity(
            'tm.ticket.type.notification.type.mapping.widget.basic',
            'Ticket Type Notification Type Mapping',
            'tm.TicketTypeNotificationTypeMapping',
            'No Data',
            objectRequestList
        );
        this.ticketTypeNotificationTypeMappingWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeNotificationTypeDtoId', object.objectId);


        this.handleObjectViewWidget(object, false);
    }

    /**
     * handle whether to show data widget or object widget
     * @param object
     * @param createMode
     * @param updateMode
     */
    handleObjectViewWidget(object: ObjectIdentifier, createMode: boolean = false, updateMode: boolean = false) {
        this.clearWidget();
        this.selectedObject = object;

        if (createMode) {
            this.isCreateMode = true;
        }

        this.objectViewWidget = WidgetFactory.createWidgetEntityData(
            `${object.objectType}.object.view.widget`,
            'Object',
            object.objectType,
            object.objectId
        );


        this.objectViewWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeDtoId', this.objectIdentifier.objectId);


        const defaultDto: DtoDetail = new DtoDetail();
        defaultDto['ticketTypeDtoId'] = this.objectIdentifier.objectId;

        const formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('ticketTypeDtoId', MvsFormFieldAccessEnum.READ);

        let params: WidgetDataParam[] = [
            WidgetDataParam.create('createDefaultDto', defaultDto),
            WidgetDataParam.create('formControlOverwrite', formControlOverwrite)];

        if (updateMode) {
            params = [];
        }

        this.objectWidget = WidgetFactory.createWidgetObject(
            `${object.objectType}.object.view.widget.create`,
            'Object',
            object.objectType,
            object.objectId,
            ...params
        );


    }

    /**
     * clear widget data when navigate
     */
    clearWidget() {
        this.isCreateMode = false;
        this.isTicketTypeUpdate = false;
        this.selectedObject = null;
        this.objectViewWidget = null;
        this.objectWidget = null;
    }

    /**
     * initialize widgets data
     */
    refreshWidgets() {
        this.refreshTicketTypeWidget();
        this.refreshTicketTypeActionWidget();
        this.refreshTicketTypeObjectRoleWidget();
        this.refreshTicketTypeObjectTypeWidget();
        this.refreshTicketTypeNotificationTypeWidget();
        this.refreshTicketTypeCompletionStatusWidget();
        this.refreshTicketTypeFieldWidget();
    }

    /**
     * return object request for widgets to show objects related to selected ticket type
     */
    getObjectRequestList(): ObjectRequestList {
        const filterCriteria = [FilterCriteria.create('ticketType', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];
        return ObjectRequestList.createBasic(true, filterCriteria, []);
    }

    /**
     * refresh ticket type widget
     */
    refreshTicketTypeWidget() {
        this.ticketTypeWidget = WidgetFactory.createWidgetEntityData(
            'tm.ticket.type.widget.basic',
            'Ticket Type',
            'tm.TicketType',
            this.objectIdentifier.objectId
        );

        this.ticketTypeUpdateWidget = WidgetFactory.createWidgetObject(
            'tm.ticket.type.widget.basic.create',
            'Ticket Type',
            'tm.TicketType',
            this.objectIdentifier.objectId
        );
    }

    /**
     * refresh ticket type action widget
     */
    refreshTicketTypeActionWidget() {
        this.ticketTypeActionWidget = WidgetFactory.createWidgetListEntity(
            'tm.TicketTypeAction.widget.basic',
            'Ticket Type Action',
            'tm.TicketTypeAction',
            'No Data',
            this.getObjectRequestList()
        );
        this.ticketTypeActionWidget.uiComponent = 'selectable';
        this.ticketTypeActionWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeDtoId', this.objectIdentifier.objectId);
    }

    /**
     * refresh ticket type action ticket mapping widget
     */
    refreshTicketTypeActionTicketMappingWidget(request: ObjectRequestList, ticketTypeActionId: number) {
        this.ticketTypeActionTicketMappingWidget = WidgetFactory.createWidgetTableEntity(
            'tm.ticket.type.action.ticket.mapping.widget.basic',
            'Ticket Type Action Ticket Mapping',
            'tm.TicketTypeActionTicketMapping',
            'No Data',
            request
        );
        this.ticketTypeActionTicketMappingWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeActionDtoId', ticketTypeActionId);
    }

    /**
     * refresh ticket action process mapping type widget
     */
    refreshTicketTypeActionProcessMappingWidget(request: ObjectRequestList, ticketTypeActionId: number) {
        this.ticketTypeActionProcessMappingWidget = WidgetFactory.createWidgetTableEntity(
            'tm.ticket.type.action.process.mapping.widget.basic',
            'Ticket Type Action Process Mapping',
            'tm.TicketTypeActionProcessMapping',
            'No Data',
            request
        );
        this.ticketTypeActionProcessMappingWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeActionDtoId', ticketTypeActionId);
    }

    /**
     * refresh ticket type object role widget
     */
    refreshTicketTypeObjectRoleWidget() {
        this.ticketTypeObjectRoleWidget = WidgetFactory.createWidgetListEntity(
            'tm.TicketTypeObjectRole.widget.basic',
            'Ticket Type Object Role',
            'tm.TicketTypeObjectRole',
            'No Data',
            this.getObjectRequestList()
        );
        this.ticketTypeObjectRoleWidget.uiComponent = 'selectable';
        this.ticketTypeObjectRoleWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeDtoId', this.objectIdentifier.objectId);
    }

    /**
     * refresh ticket type object type widget
     */
    refreshTicketTypeObjectTypeWidget() {
        this.ticketTypeObjectTypeWidget = WidgetFactory.createWidgetListEntity(
            'tm.TicketTypeObjectType.widget.basic',
            'Ticket Type Object Type',
            'tm.TicketTypeObjectType',
            'No Data',
            this.getObjectRequestList()
        );
        this.ticketTypeObjectTypeWidget.uiComponent = 'selectable';
        this.ticketTypeObjectTypeWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeDtoId', this.objectIdentifier.objectId);
    }

    /**
     * refresh ticket type notification type widget
     */
    refreshTicketTypeNotificationTypeWidget() {
        this.ticketTypeNotificationTypeWidget = WidgetFactory.createWidgetListEntity(
            'tm.TicketTypeNotificationType.widget.basic',
            'Ticket Type Notification Type',
            'tm.TicketTypeNotificationType',
            'No Data',
            this.getObjectRequestList()
        );
        this.ticketTypeNotificationTypeWidget.uiComponent = 'selectable';
        this.ticketTypeNotificationTypeWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeDtoId', this.objectIdentifier.objectId);
    }

    /**
     * refresh ticket type completion status widget
     */
    refreshTicketTypeCompletionStatusWidget() {
        this.ticketTypeCompletionStatusWidget = WidgetFactory.createWidgetListEntity(
            'tm.TicketTypeCompleteStatus.widget.basic',
            'Ticket Type Completion Status',
            'tm.TicketTypeCompleteStatus',
            'No Data',
            this.getObjectRequestList()
        );
        this.ticketTypeCompletionStatusWidget.uiComponent = 'selectable';
        this.ticketTypeCompletionStatusWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeDtoId', this.objectIdentifier.objectId);
    }

    /**
     * refresh ticket type field widget
     */
    refreshTicketTypeFieldWidget() {
        this.ticketTypeFieldWidget = WidgetFactory.createWidgetListEntity(
            'tm.TicketTypeField.widget.basic',
            'Ticket Type Field',
            'tm.TicketTypeField',
            'No Data',
            this.getObjectRequestList()
        );
        this.ticketTypeFieldWidget.uiComponent = 'selectable';
        this.ticketTypeFieldWidget.functionCallbacks = this.widgetToolbarCreate('ticketTypeDtoId', this.objectIdentifier.objectId);
    }

    /**
     * handle what happens if different toolbar button on widgets are clicked
     */
    handleToolbarButtonClick(event: WidgetHelperButton, objectType: string) {

        if (event.action == 'create') {
            this.ticketTypeActionInternalType = undefined;
            this.handleObjectViewWidget(new ObjectIdentifier(objectType, 0), true);
        }

        if (event.action == 'ticketToggle') {
            this.isTicketTypeUpdate = !this.isTicketTypeUpdate;
        }

        if (event.action == 'update') {
            this.ticketTypeActionInternalType = undefined;
            this.handleObjectViewWidget(this.selectedObject, true, true);
        }
    }

    /**
     * pass ticket type parent id to retrieve parent ticket type
     * @param ticketTypeId
     */
    getTicketType(ticketTypeId: number) {

        if (!ticketTypeId) {
            return;
        }

        this.ticketTypeService.get(ticketTypeId, null, false).subscribe((res: TicketTypeDto) => {
            this.ticketTypes.push(res)
            if (res?.parentDtoId) {
                this.getTicketType(res?.parentDtoId);
            } else {
                this.adjustBreadCrumb();
            }
        });
    }

    /**
     * based on parent ticket types adjust the bread crumb
     */
    adjustBreadCrumb() {
        for (let ticketType of this.ticketTypes) {
            this.breadCrumbItems.unshift({
                icon: ticketType.image,
                label: ticketType.name,
                id: ticketType.id as unknown as string,
            });
        }

        this.breadCrumbItems[this.breadCrumbItems.length - 1]['lastItem'] = true;

        this.showBreadCrumb = true;
    }

    /**
     * handle navigation change
     * @param event
     */
    handleNavigationChange(event: MenuItem) {
        this.clearWidget();
        this.refreshWidgets();

        this.selectedItem = event;

        if (event.id == 'tm.TicketTypeAction') {
            this.getTicketTypeActionsForParents();
        }
        if (event.id == 'tm.TicketTypeObjectRole') {
            this.getTicketTypeObjectRoleForParents();
        }
        if (event.id == 'tm.TicketTypeObjectType') {
            this.getTicketTypeObjectTypeForParents();
        }
        if (event.id == 'tm.TicketTypeNotificationType') {
            this.getTicketTypeNotificationTypeForParents();
        }
        if (event.id == 'tm.TicketTypeCompleteStatus') {
            this.getTicketTypeCompletionStatusForParents();
        }
        if (event.id == 'tm.TicketTypeField') {
        }


    }

    /**
     * return object request to get all objects related to parent ticket types
     */
    getObjectRequestForParents(): ObjectRequestList {
        let filterCriteria: FilterCriteria = FilterCriteria.createOrFromArray('ticketType', 'id', this.ticketTypes);

        return ObjectRequestList.createBasic(
            false,
            [filterCriteria],
            [],
        );
    }

    /**
     * retrieve ticket type actions for parent ticket types to show in accordion
     */
    getTicketTypeActionsForParents() {
        if (this.ticketTypes.length) {
            this.ticketTypeActionService.list(this.getObjectRequestForParents()).subscribe(res => {
                this.ticketTypeActionList = res.entries;
            });
        }
    }

    /**
     * retrieve ticket type object role for parent ticket types to show in accordion
     */
    getTicketTypeObjectRoleForParents() {
        if (this.ticketTypes.length) {
            this.ticketTypeObjectRoleService.list(this.getObjectRequestForParents()).subscribe(res => {
                this.ticketTypeObjectRoleList = res.entries;
            });
        }
    }

    /**
     * retrieve ticket type object type for parent ticket types to show in accordion
     */
    getTicketTypeObjectTypeForParents() {
        if (this.ticketTypes.length) {
            this.ticketTypeObjectTypeService.list(this.getObjectRequestForParents()).subscribe(res => {
                this.ticketTypeObjectTypeList = res.entries;
            });
        }
    }

    /**
     * retrieve ticket type notification type for parent ticket types to show in accordion
     */
    getTicketTypeNotificationTypeForParents() {
        if (this.ticketTypes.length) {
            this.ticketTypeNotificationTypeService.list(this.getObjectRequestForParents()).subscribe(res => {
                this.ticketTypeNotificationTypeList = res.entries;
            });
        }
    }

    /**
     * retrieve ticket type completion status for parent ticket types to show in accordion
     */
    getTicketTypeCompletionStatusForParents() {
        if (this.ticketTypes?.length) {
            this.ticketTypeCompletionStatusService.list(this.getObjectRequestForParents()).subscribe(res => {
                this.ticketTypeCompletionList = res.entries;
            });
        }
    }

    /**
     * returns data based on ticket type id that will be shown in accordion
     * @param ticketType
     * @param list
     * @param field
     */
    getItemData(ticketType: TicketTypeDto, list: DtoDetail[], field: string) {
        return list.filter(item => ticketType.id == item[field]);
    }

    /**
     * handle row select on table in accordion
     * @param objectType
     * @param objectId
     */
    handleRowSelect(objectType: string, objectId: number) {
        this.handleObjectViewWidget(new ObjectIdentifier(objectType, objectId));
    }

    /**
     * handle row select on table in accordion for ticket type actions
     * @param ticketTypeAction
     */
    handleActionRowSelect(ticketTypeAction: TicketTypeActionDto) {
        const object: ObjectIdentifierData = new ObjectIdentifierData('tm.TicketTypeAction', ticketTypeAction.id, ticketTypeAction);
        this.handleTicketTypeAction(object)
    }

    /**
     * defaulting fields for adding new entry
     * @param fieldName
     * @param fieldValue
     */
    widgetToolbarCreate(fieldName: string, fieldValue: number) {
        return {
            onFunctionCreateNew: (): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto[fieldName] = fieldValue;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(fieldName, MvsFormFieldAccessEnum.READ);

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }

}
