import {
    DtoDetail,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";

export interface WidgetToolbarCreateInterface {
    fieldName: string,
    fieldValue: number
}

export class WidgetFunctionCallBackCreate {
    public static widgetToolbarCreate(widgetToolbarCreateInterface: WidgetToolbarCreateInterface[]) {
        return {
            onFunctionCreateNew: (): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                const formControlOverwrite = new MvsFormControlOverwrite();
                for (let entry of widgetToolbarCreateInterface) {
                    dto[entry.fieldName] = entry.fieldValue;
                    formControlOverwrite.addField(entry.fieldName, MvsFormFieldAccessEnum.READ);
                }

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }

}