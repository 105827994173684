<p-sidebar [(visible)]="appMain.rightMenuActive" [position]="app.isRTL ? 'left' : 'right'" [blockScroll]="true"
           [showCloseIcon]="false" [baseZIndex]="1000"
           [styleClass]="'layout-rightmenu p-sidebar-sm fs-small py-4 px-3'">
<!--    + (app.isRTL ? 'pl-0 pr-3' : 'pl-3 pr-0')"-->

    <div class="flex flex-column">
<!--        <div-->
<!--                class="cursor-pointer"-->
<!--                style="display: flex;-->
<!--            padding: 12.25px 7px;-->
<!--            align-items: center;-->
<!--            gap: 14px;-->
<!--            align-self: stretch;-->
<!--            border-radius: 5px;-->
<!--            background: rgba(0, 0, 0, 0.04);">-->
<!--            <i class="fa-regular fa-star"></i>-->
<!--            <span>Add to favorite</span>-->
<!--        </div>-->

<!--        <div-->
<!--                class="cursor-pointer p-ripple"-->
<!--                style="display: flex;-->
<!--            padding: 12.25px 7px;-->
<!--            align-items: center;-->
<!--            gap: 14px;-->
<!--            align-self: stretch;-->
<!--            border-radius: 5px;-->
<!--            background: rgba(0, 0, 0, 0.04);"-->
<!--                (click)="copyRouteToClipboard()">-->
<!--            <i class="fa-regular fa-link"></i>-->
<!--            <span>copy URL</span>-->
<!--        </div>-->

            <div>
                <p-button
                        styleClass="p-button-text w-full border-bottom-1 border-200 px-0"
                        (click)="copyRouteToClipboard()">
                    <ng-template pTemplate="content">
                        <span class="text-color-secondary">
                            <i class="fa-regular fa-link w-2rem h-2rem surface-100 text-sm p-2 border-round-bottom-lg"></i>
                            Copy URL
                        </span>
                    </ng-template>
                </p-button>
            </div>
            <div>
                <p-button
                        [routerLink]="['tm/me']"
                        styleClass="p-button-text w-full border-bottom-1 border-200 px-0">
                    <ng-template pTemplate="content">
                        <span class="text-color-secondary">
                            <i class="fa-regular fa-ticket w-2rem h-2rem surface-100 text-sm p-2 border-round-bottom-lg"></i>
                            Go to my tickets
                        </span>
                    </ng-template>
                </p-button>
            </div>

                <div class="side-menu-wrapper">
                    <div class="layout-menu-container">
<!--                        <a (click)="copyRouteToClipboard()" class="layout-root-menuitem w-full block cursor">-->
<!--                            <i class="fa-regular fa-link layout-menuitem-icon text-sm"></i>-->
<!--                            <span class="layout-menuitem-text">Copy URL</span>-->
<!--                        </a>-->

                        <app-menu></app-menu>
                    </div>
                </div>
    </div>

</p-sidebar>
