import {Component, SimpleChanges} from '@angular/core';
import {
    MvsCoreService,
    MvsMessageService,
    ObjectBaseComponent,
    ObserverService, WidgetData, WidgetHelperButton,
} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {CalculationLogicTypeService} from "../../../service/api/calculation-logic-type.service";
import {LgCalculationLogicTypeObjectInterface} from "./lg-calculation-logic-type-object.interface";
import {MvsUiObjectService} from "@kvers/alpha-ui";

@Component({
    selector: 'mvs-lg-calculation-logic-type-object-component',
    templateUrl: './lg-calculation-logic-type-object.component.html',
    styleUrls: ['./lg-calculation-logic-type-object.component.scss']
})
export class LgCalculationLogicTypeObjectComponent
    extends ObjectBaseComponent implements LgCalculationLogicTypeObjectInterface {

    defaultLabel: string = "Calculation Logic Type";
    currentView: string = 'logicCalculation';

    calculationLogicTypeWidgetData: WidgetData;
    calculationLogicTypeFieldWidgetData: WidgetData;
    widgetButtons: WidgetHelperButton[];

    handleSidebarButtons() {

        this.widgetButtons = [
            {
                label: null,
                icon: 'fa-regular fa-typewriter',
                display: true,
                action: 'logicCalculation',
                tooltip: 'Logic Calculation'
            }, {
                label: null,
                icon: 'fa-regular fa-flask-vial',
                display: true,
                action: 'testCases',
                tooltip: 'Test Cases'
            },
            {
                label: null,
                icon: 'fa-regular fa-info-circle',
                display: true,
                action: 'info',
                tooltip: 'Info',
            }
        ];

    }

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected observerService: ObserverService,
        protected calculationLogicTypeService: CalculationLogicTypeService,
        protected objectService: MvsUiObjectService,
    ) {
        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.handleSidebarButtons();
    }

    onObjectChanged() {
        this.busy = false;

        this.setPageTitle(this.dto.name);
        this.refreshWidgets();
    }

    refreshWidgets() {
        this.refreshCalculationLogicTypeWidgetData();
        this.refreshCalculationLogicTypeFieldWidgetData();
    }

    refreshCalculationLogicTypeWidgetData() {
        this.calculationLogicTypeWidgetData = this.calculationLogicTypeService.getCalculationLogicTypeWidget(this.objectIdentifier.objectId);
    }

    refreshCalculationLogicTypeFieldWidgetData() {
        this.calculationLogicTypeFieldWidgetData = this.calculationLogicTypeService.getCalculationLogicTypeFieldWidget(this.objectIdentifier.objectId);
    }

    setView(view: string) {
        this.currentView = view;
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if (!this.initialized) {
            return;
        }

    }


}
