import {Injectable, Type} from '@angular/core';
import {DtoList, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {WfProcessDto} from "../../../wf/dto/wf-process.dto";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {map} from "rxjs/operators";
import {
    MvsApiCrudServiceFlexibleSearchInterface
} from "@kvers/alpha-core-common";
import {
    CrSearchFlexibleCustomerComponent
} from "../../search/cr-search-flexible-customer/cr-search-flexible-customer.component";
import {
    WfProcessStepActivityMainProviderInterface
} from "../../../wf/component/process-activity/wf-process-step-activity-main-provider.interface";
import {
    PmCreatePersonWfProcessStepActivityComponent
} from "../../../pm/component/wf/pm-create-person-wf-process-step-activity/pm-create-person-wf-process-step-activity.component";
import {WfProcessTypeStepActivityDto} from "../../../wf/dto/wf-process-type-step-activity.dto";
import {
    WfProcessStepActivityBaseComponent
} from "../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {MvsCoreGetNameInterface} from "@kvers/alpha-ui";
import {CustomerDto} from "../../dto/customer.dto";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {CrCustomerComponent} from "../../component/cr-customer/cr-customer.component";
import {CustomerContactDto} from "../dto/customer-contact.dto";
import {CrCustomerInlineComponent} from "../../component/cr-customer-inline-component/cr-customer-inline-component";
import {CrCustomerObjectPage} from "../../page/object/cr-customer-object-page/cr-customer-object.page";
import {BatchRequestHandler} from "../../../core/batch-interceptor/batch-request-handler.interface";
import {ProfileCheckResultDto} from "../dto/profile-check-result.dto";
import {CustomerAssessmentResultDto} from "../dto/customer-assessment-result.dto";
import {CustomerHostedRuntimeDto} from "../dto/customer-household-runtime.dto";
// import {BatchRequestHandler} from "../../../core/batch-interceptor/batch-request-handler.interface";

@Injectable({
    providedIn: 'root'
})
export class CustomerService
    extends MvsCrudService
    implements
        MvsApiCrudServiceFlexibleSearchInterface,
        WfProcessStepActivityMainProviderInterface,
        MvsCoreGetNameInterface<CustomerDto>,
        BatchRequestHandler
{



    constructor(
        protected http: HttpClient) {

        super(http, MvsCrudService.baseUrl + '/cr/customers');
    }

    executeBatchUpdateNames() : Observable<any> {
        return this.getInternal(this.apiUrl + "/batch/updateNames");
    }

    /**
     * Get Object Component.
     */
    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
        if (mode == MvsCrudModeEnum.create) {
            return null;
        }
        return CrCustomerComponent;
    }


    /**
     * Get Customer Photo URL.
     * @param customerId
     */
    getPhotoUrl(customerId: number): string {

        return MvsCrudService.baseUrl + '/cr/customers/' + customerId + '/photo/$';

    }

    /**
     * Get Customer (incl. Person).
     * @param id
     * @param includeTemplate
     */
    public getCustomerWithPersonAndAddress(id: number, includeTemplate: boolean = false): Observable<WfProcessDto> {

        const complexSelection =
            ObjectRequestComplex.build(true, false,

                ObjectRequestComplexNode.createSimpleAttributeNode("person").addNodes(
                    ObjectRequestComplexNode.createSimpleAttributeNode("personDetail")
                ),
                ObjectRequestComplexNode.createRelationNode("addresses",
                    ObjectRequestRelation.createList(
                        "cr.CustomerAddress",
                        "customer",
                        null,
                        [new Sorting("lastModifiedDate", false)],
                        ObjectRequestComplexRelationBindingEnum.ALL)
                ).addNodes(
                    ObjectRequestComplexNode.createSimpleAttributeNode("address")
                ),
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            includeTemplate,
            complexSelection,
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, id)],
            null,
            null,
            null);


        return this.list(objectRequestList).pipe(
            map(
                value => {
                    return <WfProcessDto>value.entries[0];
                }
            ));
    }

    public getCustomerWithPersonComplexSelection(): ObjectRequestComplex {

        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("person")
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("personDetail"))
        )
    }

    public getCustomerWithPersonComplexSelectionWoText(): ObjectRequestComplex {

        return ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("person")
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("personDetail"))
        )
    }

    public getCustomerWithAgentComplexRequest() {
        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode('person', ObjectRequestRelation.createJoin('person')) ,
            // ObjectRequestComplexNode.createRelationNode("latestAgent",
            //     ObjectRequestRelation.createList(
            //         "cr.CustomerAgent",
            //         "customer",
            //         null,
            //         null,
            //         ObjectRequestComplexRelationBindingEnum.LATEST)
            // )
        );
    }

    public getCustomerWithAddressComplexSelection(): ObjectRequestComplex {

        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode("addresses",
                ObjectRequestRelation.createList(
                    "cr.CustomerAddress",
                    "customer",
                    null,
                    [new Sorting("lastModifiedDate", false)],
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ).addNodes(
                ObjectRequestComplexNode.createSimpleAttributeNode("address")
            ),
        );
    }

    public getCustomerWithContactComplexSelection(): ObjectRequestComplex {

        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode("contacts",
                ObjectRequestRelation.createList(
                    "cr.CustomerContact",
                    "customer",
                    null,
                    [new Sorting("lastModifiedDate", false)],
                    ObjectRequestComplexRelationBindingEnum.ALL)
            ).addNodes(
                ObjectRequestComplexNode.createSimpleAttributeNode("contact")
            ),
        );
    }

    public getCustomerWithContractsComplexSelection(): ObjectRequestComplex {

        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode("contracts",
                ObjectRequestRelation.createList(
                    "cr.CustomerContract",
                    "customer",
                    null,
                    [new Sorting("lastModifiedDate", false)],
                    ObjectRequestComplexRelationBindingEnum.ALL)
            )
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contract")
                    .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contractType")
                        .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("group")))
                    .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contractPartner"))
                    .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contractPrice"))
                )

            //     .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("group")))
            //
            // .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contractPrice"))
            // .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contractStatus"))
            // .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contractInsuredObjects"))
        );
    }

    getFlexibleSearchComponent(): Type<any> {
        return CrSearchFlexibleCustomerComponent;
    }

    getActivityMainComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivityBaseComponent | null {
        return PmCreatePersonWfProcessStepActivityComponent;
    }

    /**
     * Return object name from DTO.
     * @param dto
     */
    getObjectNameFromDto(dto: CustomerDto): string {
        return dto.personDtoName;
    }


    geContactsEmail(customerId: number) : Observable<CustomerContactDto[]> {
        return this.getGeneric<CustomerContactDto[]>(`/${customerId}/contacts/email`);
    }

    geContactsMobile(customerId: number) : Observable<CustomerContactDto[]> {
        return this.getGeneric<CustomerContactDto[]>(`/${customerId}/contacts/mobile`);
    }

    getInlineComponent() {
        return CrCustomerInlineComponent;
    }

    getObjectPageComponent() {
        return CrCustomerObjectPage;
    }

    getObjectIcon(): string {
        return 'pi pi-user';
    }

    getObjectLabels(): string[] {
        return ['personDtoName', 'alias', 'name'];
    }

    shouldBatch(url: string): boolean {
        return url.includes('/cr/customers');
    }
    combinationLogic(requests: HttpRequest<any>[]): any {
        let filter: FilterCriteria = new FilterCriteria();
        for (let item of requests) {
            const id = this.extractIdFromUrl(item.url);
            filter.addFilterCriteriaToOr(FilterCriteria.create('id',FilterCriteria.cOperatorEqual,id));
        }
        const request = new ObjectRequestList(false, [filter], []);
        return request;
    }

    separationLogic(response: DtoList<any>, requests: HttpRequest<any>[]): HttpResponse<any>[] {
        return requests.map(req => {
            const id = this.extractIdFromUrl(req.url);
            const matchingEntry = response.entries.find(entry => entry.id === id);
            return new HttpResponse({ body: matchingEntry });
        });
    }

    private extractIdFromUrl(url: string): number {
        const urlSegments = url.split('/');
        return +urlSegments[urlSegments.length - 1];
    }

    public checkProfile(customerId: number, includeSuccess: boolean = false): Observable<ProfileCheckResultDto> {
        let queryParams = {includeSuccess: includeSuccess};
        let url = `${this.apiUrl}/${customerId}/profileCheck`;

        return this.http.get<ProfileCheckResultDto>(url, { params: queryParams });
    }

    public assessCustomer(customerId: number): Observable<CustomerAssessmentResultDto> {
        let queryParams = {};
        let url = `${this.apiUrl}/${customerId}/assessCustomer`;

        return this.http.get<CustomerAssessmentResultDto>(url, { params: queryParams });
    }

    public getHousehold(customerId: number): Observable<CustomerHostedRuntimeDto> {
        let queryParams = {};
        let url = `${this.apiUrl}/${customerId}/household`;

        return this.http.get<CustomerHostedRuntimeDto>(url, { params: queryParams });
    }

}
