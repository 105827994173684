import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UmUserService} from "../../../um/service/api/um-user.service";
import {
    DmDocumentRelevantField, DocumentLabels,
    UserAction
} from "../dm-document-processing/dm-document-processing.component";
import {ContractDto} from "../../../cm/dto/contract.dto";
import {CustomerDto} from "../../../cr/dto/customer.dto";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {WidgetHelperButton} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {IdentifiedField} from "../dm-document-processing/data/identified-field.interface";

@Component({
    selector: 'document-process-actions',
    templateUrl: './document-process-actions.component.html',
    styleUrls: ['./document-process-actions.component.scss']
})
export class DocumentProcessActionsComponent implements OnInit, OnChanges {
    @Input() userActions: UserAction[];
    @Input() selectedContract: ContractDto;
    @Input() selectedCustomer: CustomerDto;
    @Input() prefilledLabels: IdentifiedField[];
    @Input() fields: DocumentLabels[];
    selectedUserAction: UserAction;
    historyWidgetToolbarButtons: WidgetHelperButton[];
    actionWidget: WidgetData;
    historyWidget: WidgetData;
    initialized: boolean;

    constructor(protected serviceUser: UmUserService) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    initComponent() {
        this.refreshComponent();
        this.initialized = true;
    }

    refreshComponent() {
        this.prepareUserActions();
    }

    prepareUserActions() {
        if (!this.userActions || !this.userActions.length) {
            this.getDefaultUserActions();
        }
    }

    getDefaultUserActions() {
        this.userActions = [];
        const actions: UserAction[] = [];
        if (this.selectedContract) {
            actions.push({action:'Contract Price?', entity: 'cm.ContractPrice', relation: 'contract', color: ''});
            actions.push({action:'Contract Status?', entity: 'cm.ContractStatus', relation: 'contract', color: ''});
            actions.push({action:'Contract Partner?', entity: 'cm.ContractPartner', relation: 'contract', color: ''});
        }

        if (this.selectedCustomer) {
            actions.push({action:'Customer Contact?', entity: 'cr.CustomerContact', relation: 'customer', color: ''});
            actions.push({action:'Customer Address?', entity: 'cr.CustomerAddress', relation: 'customer', color: ''});
        }
        this.userActions = actions;
    }

    displayHistory(item: UserAction) {

        this.selectedUserAction = item;

        this.historyWidgetToolbarButtons = [{
            icon: 'fa fa-plus',
            label: '',
            display: true,
            action: 'createNew',
            tooltip: 'Add New'
        }];

        const objectRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create(item.relation, FilterCriteria.cOperatorEqual, +this.getObjectId())],
            [new Sorting('createdDate', false)]
        );

        this.historyWidget = WidgetFactory.createWidgetList(
            "dm.document.processing.show.action.history",
            item.entity + ' History',
            "list",
            "list",
            "entity",
            item.entity,
            'Keine Daten vorhanden',
            objectRequest,
            WidgetDataParam.create('paging', 2)
        )
        this.actionWidget = null;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes['prefilledLabels']) {
            this.refreshComponent();
        }

    }

    createNewUserAction() {

        const requiredFields = this.getRelevantFields();

        const defaultDto = new DtoDetail();
        const overwrite = new MvsFormControlOverwrite();

        if (requiredFields && requiredFields.length) {
            const joinedAttribute = this.getJoinedAttribute();
            const completeAttributeName = joinedAttribute + 'DtoId';
            defaultDto[completeAttributeName] = this.getObjectId();
            if (completeAttributeName == this.selectedUserAction.relation + 'DtoId') {
                overwrite.addField(this.selectedUserAction.relation + 'DtoId', MvsFormFieldAccessEnum.HIDDEN);
            }
        } else {
            defaultDto[this.selectedUserAction.relation + 'DtoId'] = this.getObjectId();
            overwrite.addField(this.selectedUserAction.relation + 'DtoId', MvsFormFieldAccessEnum.HIDDEN);
        }

        for (let item of requiredFields) {
            if (item.value.includes(',')) {
                item.value = item.value.replace(/,/g, '');
            }
            defaultDto[item.field] = item.value;
        }

        this.actionWidget = WidgetFactory.createWidgetList(
            "dm.document.processing.basic.create",
            this.selectedUserAction.entity,
            "object",
            "list",
            "entity",
            this.selectedUserAction.entity,
            null,
            null,
            WidgetDataParam.create('createDefaultDto', defaultDto),
            WidgetDataParam.create('formControlOverwrite', overwrite)
        );


    }

    getObjectId(): number {

        if (this.selectedUserAction.relation == 'customer') {
            return this.selectedCustomer.id;
        } else if (this.selectedUserAction.relation == 'contract') {
            return this.selectedContract.id;
        }
        return null;
    }

    getRelevantFields(): DmDocumentRelevantField[] {
        const fields = this.fields;
        const relevantFields = [];
        for (let item of fields) {
            const field = this.getLastEntity(item.label);
            if (field == this.selectedUserAction.entity) {
                const attributeName = this.getAttributeName(item.label)
                relevantFields.push({actualField: item.label,field: attributeName, value: item.value[0].text})
            }
        }
        return relevantFields;
    }

    getLastEntity(fieldLabel: string): string {
        const parts = fieldLabel.split('.');
        if (parts.length > 1) {
            const lastPart = parts[parts.length - 2];
            if (lastPart.includes('#')) {
                const subParts = lastPart.split('#');
                if (subParts.length > 1) {
                    return this.constructEntityName(subParts[0]);
                }
            }
            return this.constructEntityName(parts[parts.length - 2]);
        }
        return this.constructEntityName(fieldLabel);
    }

    constructEntityName(prefix: string): string {
        const firstCapitalIndex = prefix.search(/[A-Z]/);
        if (firstCapitalIndex > 0) {
            const prefixPart = prefix.substring(0, firstCapitalIndex);
            const entityPart = prefix.substring(firstCapitalIndex);
            return `${prefixPart}.${entityPart}`;
        }
        return prefix;
    }

    getAttributeName(field: string) {
        const subPaths = field.split('.');
        return subPaths[subPaths.length - 1];
    }

    getJoinedAttribute() {
        const fields = this.getRelevantFields();
        if (!fields) {
            return null;
        }
        const field = fields[0].actualField;
        const subPaths = field.split('.');
        const entityName = subPaths[subPaths.length - 2];
        if (entityName.includes('#')) {
            const subParts = entityName.split('#');
            if (subParts.length == 1) {
                return ''
            } else if (subParts.length == 2) {
                return subParts[subParts.length - 1];
            }
        }
        return null;
    }

    getLabelColors(label: any): string {
        const lcLabel = label.toLowerCase();

        if (lcLabel.includes('price')) {
            return 'pink';
        } else if (lcLabel.includes('customer')) {
            return 'green';
        } else if (lcLabel.includes('contract')) {
            return 'purple';
        }
        return 'black';
    }

}
