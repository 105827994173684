import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {LogicResultDto} from "../dto/logic-result.dto";
import {LogicCheckTestCaseVariablesResult} from "../dto/logic-check-test-case-variables-result.dto";
import {CalculationResultDataDto} from "../dto/calculation-result-data.dto";
import {CalculationTestCaseVariablesResultDto} from "../dto/calculation-test-case-variables-result.dto";

@Injectable({
  providedIn: 'root'
})
export class CalculationLogicTestCaseService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/lg/calculationLogicTestCases')
  }

  evaluateLogic(testCaseId: number): Observable<CalculationResultDataDto> {
    const url: string = MvsCrudService.baseUrl + `/lg/calculationLogicTestCases/${testCaseId}/evaluate`;

    return this.http.post<CalculationResultDataDto>(url,null);
  }

  checkVariables(testCaseId: number): Observable<CalculationTestCaseVariablesResultDto> {
    const url: string = MvsCrudService.baseUrl + `/lg/calculationLogicTestCases/${testCaseId}/variables`;

    return this.http.post<CalculationTestCaseVariablesResultDto>(url,null);
  }


}