import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MenuItem, MessageService} from "primeng/api";
import {DtoDetail, MvsCoreService} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {ContextMenu} from "primeng/contextmenu";
import {CustomerDto} from "../../../../../dto/customer.dto";
import {HouseholdPersonDto} from "../../../../../../pm/dto/household-person.dto";
import {HouseholdService} from "../../../../../../pm/service/api/household.service";
import {HouseholdPersonService} from "../../../../../../pm/service/api/household-person.service";
import {CustomerPageHouseholdPersonService} from "../../../../../service/ui/customer-page-household-person.service";
import {
    PmPersonRelationPersonWidgetStyle
} from "../../../../../../pm/component/pm-household/uiStyle/pm-person-relation-person-widget-style";
import {CustomerHostedRuntimeDto} from "../../../../../service/dto/customer-household-runtime.dto";
import {PersonDto} from "../../../../../../pm/dto/person.dto";

@Component({
    selector: 'customer-person-household-persons',
    templateUrl: './customer-person-household.component.html',
    styleUrls: ['./customer-person-household.component.scss'],
    providers: [CustomerPageHouseholdPersonService],
})
export class CustomerPersonHouseholdComponent
    implements OnInit, OnDestroy, OnChanges {


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    value: boolean;
    @Input() customerDto: CustomerDto;
    @Output() selectedRelatedPersonsChanged = new EventEmitter<PmPersonRelationPersonWidgetStyle>();
    @Output() householdPersonsLoaded = new EventEmitter<HouseholdPersonDto[]>();
    householdPersons: MenuItem[];
    uiHouseholdPersons: MenuItem[];
    broadcastSubscription: Subscription;
    households: HouseholdPersonDto[];
    selectedPersonWidgetStyle: PmPersonRelationPersonWidgetStyle;
    householdPersonsCount: number;
    selectedPersonId: number;
    items: MenuItem[];

    ////////////////////////
    @Input() customerId: number;
    householdSubscription: Subscription;
    customerHouseholdRuntimeDto: CustomerHostedRuntimeDto;

    constructor(public messageService: MessageService,
                protected navigationService: MvsObjectNavigationService,
                protected householdService: HouseholdService,
                protected householdPersonService: HouseholdPersonService,
                protected objectService: MvsUiObjectService,
                protected coreService: MvsCoreService,
                protected configService: MvsConfigService,
                protected customerPageHouseholdPersonService: CustomerPageHouseholdPersonService) {}

    ngOnInit(): void {
        this.householdSubscription = this.customerPageHouseholdPersonService.getHouseholdSubject().subscribe(res => {
            this.customerHouseholdRuntimeDto = res;
            this.refreshInternal();
        });
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.items = [
            {
                label: 'Edit',
                icon: 'pi pi-pencil',
                command: () => {
                    this.handleEditNavigation();
                }
            }
        ];

        this.refreshComponent();
    }

    handleEditNavigation() {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('pm.Household', this.customerHouseholdRuntimeDto.household.id, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    openContextMenu(event: MouseEvent, contextMenu: ContextMenu) {
        event.preventDefault();
        contextMenu.toggle(event);
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.customerPageHouseholdPersonService.setCustomerId(this.customerId);
        // this.refreshInternal();

    }

    refreshInternal() {
        this.householdPersons = [];
        const customerPersonId = this.customerHouseholdRuntimeDto.personCustomers.find(item => item.customerId == this.customerId)?.personId;

        for (let person of this.customerHouseholdRuntimeDto.persons) {

            let isMainCustomer: boolean = false;
            let isCustomer: boolean;
            let customerId: number;

            if (person.id == customerPersonId) {
                // skip the person from household which is already the main customer in input parameter
                isMainCustomer = true;
            }

            const customerRelationObject = this.customerHouseholdRuntimeDto.personCustomers.find(item => item.personId == person.id);

            if (customerRelationObject) {
                isCustomer = true;
                customerId = customerRelationObject.customerId;
            }

            const uiPerson = {
                label: person.calculatedName,
                display: true,
                command: () => this.handleSelectRelatedPerson(person),
                isCustomer: isCustomer,
                customerId: customerId
            };

            if (isMainCustomer) {
                uiPerson.label = `${uiPerson.label}*`
            }

            if (isCustomer) {
                uiPerson.label = `${uiPerson.label} (C)`
            }

            // person.style = {'background-color': person.__color};

            this.householdPersons.push(uiPerson);
        }
        // this.createUi();
        this.initialized = true;
    }

    // prepareHouseholdPersons() {
    //     this.householdPersons = [];
    //
    //     if (this.households && this.households.length) {
    //         for (let household of this.households) {
    //             for (let person of household.householdDto['householdPerson']) {
    //                 if (person.personDtoId == this.customerDto.personDtoId) {
    //                     // skip the person from household which is already the main customer in input parameter
    //                     person['__display'] = false;
    //                     // continue;
    //                 }
    //                 person['label'] = person.personDtoName;
    //                 person.command = () => this.handleSelectRelatedPerson(person);
    //                 person.style = {'background-color': person.__color};
    //                 this.householdPersons.push(person);
    //             }
    //         }
    //     }
    //
    //     // this.householdPersonsLoaded.emit(this.householdPersons);
    //     this.selectedPersonWidgetStyle = new PmPersonRelationPersonWidgetStyle(this.configService, this.customerDto, []);
    //     this.createUi();
    // }

    createUi() {
        // this.uiHouseholdPersons = this.householdPersons.filter(item => item.display != false);
        this.initialized = true;
    }

    handleHouseholdClick() {
        // for (let item of this.uiHouseholdPersons) {
        //     this.onSelectRelatedPerson(item);
        // }
        this.selectedRelatedPersonsChanged.emit(this.selectedPersonWidgetStyle);
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["customerDto"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        if (this.broadcastSubscription) {
            this.broadcastSubscription.unsubscribe();
        }
    }

    handleSelectRelatedPerson(person: PersonDto) {
        //TODO Implement coloring here

        // this.onSelectRelatedPerson(person);
        this.selectedRelatedPersonsChanged.emit(this.selectedPersonWidgetStyle);
    }

    onSelectRelatedPerson(person: HouseholdPersonDto) {
        person['__selected'] = !person['__selected'];

        const customerId: number = person['personDto']?.['customerDto']?.id;

        if (!customerId) {
            return;
        }

        if (person['__selected'] && customerId) {
            this.selectedPersonWidgetStyle.addRelatedPerson(person, customerId);
        } else {
            this.selectedPersonWidgetStyle.removeRelatedPerson(person);
        }

        person['style'] = this.selectedPersonWidgetStyle.getStyleObjectForAvatar(person);
    }

    removeFromHousehold(personId: number) {
        this.selectedPersonId = personId;
        this.createNewHousehold();
    }

    createNewHousehold() {
        const dto = new DtoDetail();
        this.householdService.create(dto).subscribe(res => {
            const newlyCreatedHouseholdId = res.id;
            this.assignHousehold(newlyCreatedHouseholdId);
        });
    }

    assignHousehold(newlyCreatedHouseholdId: number) {
        const dto = new HouseholdPersonDto();
        dto.householdDtoId = newlyCreatedHouseholdId;
        dto.personDtoId = this.selectedPersonId;
        dto.endDate = new Date();

        this.householdPersonService.create(dto).subscribe(res => {
            console.log('Household person is removed and new is created');
            this.selectedPersonId = null;
        });
    }
}
