<!--<mvs-crud-page [objectStructureRequest]="objectBrowserRequest"-->
<!--               [defaultLabel]="defaultLabel">-->
<!--</mvs-crud-page>-->

<div class="bg-white mt-5" *ngIf="initialized">
    <mvs-config-header header="EU Konfiguration" [categoryTypeMode]="'cross'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of uiWidgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2" >
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                                </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'eu.EuFrontEndText'">
            <mvs-widget *ngIf="euFrontEndTextWidget"
                        [widgetData]="euFrontEndTextWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'eu.EuThemeColor'">
            <mvs-widget *ngIf="EuThemeColorWidget"
                        [widgetData]="EuThemeColorWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'eu.EuTmConfiguration'">
            <mvs-widget *ngIf="EuTmConfigurationWidget"
                        [widgetData]="EuTmConfigurationWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>



        <ng-container *ngIf="activeWidget.id == 'eu.EuPromotion'">
            <mvs-widget *ngIf="EuPromotionWidget"
                        [widgetData]="EuPromotionWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'eu.EuNotificationType'">
            <mvs-widget *ngIf="EuNotificationTypeWidget"
                        [widgetData]="EuNotificationTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'eu.EuUserDocumentType'">
            <mvs-widget *ngIf="EuUserDocumentTypeWidget"
                        [widgetData]="EuUserDocumentTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'eu.EuDefaultRole'">
            <mvs-widget *ngIf="euDefaultRoleWidget"
                        [widgetData]="euDefaultRoleWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="activeWidget && activeWidget.id == 'eu.EuStaticNotification' && activeNavItem == 'notifications'">

        <div class="grid p-2">
            <div class="col-3">
                <div class="shadow-1">
                <ng-container *ngIf="activeWidget.id == 'eu.EuStaticNotification'">
                    <mvs-widget *ngIf="EuStaticNotificationWidget"
                                [widgetData]="EuStaticNotificationWidget"
                                (onObjectSelect)="handleStaticNotifications($event)">
                    </mvs-widget>
                </ng-container>
                </div>
            </div>

            <div class="col-9">
                <div class="shadow-1 flex flex-column gap-3">
                    <mvs-widget *ngIf="EuStaticNotificationContractPersonTypeWidget"
                                [widgetData]="EuStaticNotificationContractPersonTypeWidget"
                                (onObjectSelect)="handleTemplateSelect($event)">
                    </mvs-widget>

                    <mvs-widget *ngIf="EuStaticNotificationContractTypeWidget"
                                [widgetData]="EuStaticNotificationContractTypeWidget"
                                (onObjectSelect)="handleTemplateSelect($event)">
                    </mvs-widget>

                    <mvs-widget *ngIf="EuStaticNotificationInsurableObjectTypeWidget"
                                [widgetData]="EuStaticNotificationInsurableObjectTypeWidget"
                                (onObjectSelect)="handleTemplateSelect($event)">
                    </mvs-widget>
                </div>
            </div>

        </div>



    </ng-container>

</div>