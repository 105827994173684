import {Injectable, Type} from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {map} from "rxjs/operators";
import {WfProcessDto} from "../../dto/wf-process.dto";
import {WfProcessTypeDto} from "../../dto/wf-process-type.dto";
import {
    WfProcessObjectQuickViewComponent
} from "../../component/wf-process-object-quick-view/wf-process-object-quick-view.component";
import {WfProcessTypeService} from "./wf-process-type.service";
import {WfProcessComponent} from "../../component/wf-process/wf-process.component";
import {WfProcessRuntimeDto} from "../dto/wf-process-runtime.dto";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {WfProcessContinueRequestDto} from "../dto/wf-process-continue-request.dto";

@Injectable({
    providedIn: 'root'
})
export class WfProcessService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/wf/processes');
    }

    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return WfProcessComponent;
        }
        return null;
    }

    getObjectQuickViewComponent(): Type<any> {
        return WfProcessObjectQuickViewComponent;
    }

    /**
     * Create new process.
     * @param processType
     */
    public createNewProcess(processType: WfProcessTypeDto): Observable<WfProcessDto> {

        const wfProcessDto = new WfProcessDto();
        wfProcessDto.typeDtoId = processType.id;

        return <Observable<WfProcessDto>>this.create(wfProcessDto);
    }

    /**
     * Create new process with parameters.
     * @param processType
     * @param dto
     */
    public start(processType: WfProcessTypeDto, dto: WfProcessDto): Observable<WfProcessDto> {

        const wfProcessDto: WfProcessDto = dto;
        wfProcessDto.typeDtoId = processType.id;

        return <Observable<WfProcessDto>>this.createInternal(wfProcessDto, "/start");

    }

    public completeMainActivity(request: WfProcessContinueRequestDto): Observable<WfProcessDto> {
        return <Observable<WfProcessDto>>this.postBasic(request, "/completeActivity");
    }


    public resume(processId: number, addMeta: boolean = false): Observable<WfProcessRuntimeDto> {

        return <Observable<WfProcessRuntimeDto>>this.getInternalEndpoint("/" + processId + "/resume", {addMeta: addMeta});

    }


    /**
     * Get process including steps.
     * @param id
     * @param includeTemplate
     */
    public getProcessAndSteps(id: number, includeTemplate: boolean = false): Observable<WfProcessDto> {

        const complexSelection =
            ObjectRequestComplex.build(true, false,

                ObjectRequestComplexNode.createRelationNode("steps",
                    ObjectRequestRelation.createList(
                        "wf.WfProcessStep",
                        "process",
                        null,
                        [new Sorting("lastModifiedDate", false)],
                        ObjectRequestComplexRelationBindingEnum.ALL)
                )
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            includeTemplate,
            complexSelection,
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, id)],
            null,
            null,
            null);

        return this.list(objectRequestList).pipe(
            map(
                value => {
                    return <WfProcessDto>value.entries[0];
                }
            ));
    }

    /**
     * Get process including last step.
     * @param id
     * @param includeTemplate
     */
    public getProcessAndLastStep(id: number, includeTemplate: boolean = false): Observable<WfProcessDto> {

        const complexSelection =
            ObjectRequestComplex.build(true, false,

                ObjectRequestComplexNode.createRelationNode("lastStep",
                    ObjectRequestRelation.createList(
                        "wf.WfProcessStep",
                        "process",
                        null,
                        null,
                        ObjectRequestComplexRelationBindingEnum.LATEST)
                )
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            includeTemplate,
            complexSelection,
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, id)],
            null,
            null,
            null);

        return this.list(objectRequestList).pipe(
            map(
                value => {
                    return <WfProcessDto>value.entries[0];
                }
            ));
    }

    /**
     * Get Full Object.
     * @param id
     */
    public getFull(id: number, includeTemplate: boolean = false): Observable<WfProcessDto> {

        const complexSelection =
            ObjectRequestComplex.build(true, false,

                ObjectRequestComplexNode.createRelationNode("steps",
                    ObjectRequestRelation.createList(
                        "wf.WfProcessStep",
                        "process",
                        null,
                        [new Sorting("lastModifiedDate", false)],
                        ObjectRequestComplexRelationBindingEnum.ALL)
                ).addNodes(
                    ObjectRequestComplexNode.createRelationNode("activities",
                        ObjectRequestRelation.createList(
                            "wf.WfProcessStepActivity",
                            "processStep",
                            null,
                            [new Sorting("lastModifiedDate", false)],
                            ObjectRequestComplexRelationBindingEnum.ALL)
                    )
                ),
                ObjectRequestComplexNode.createSimpleAttributeNode("type")
                    .addNodes(
                        ...WfProcessTypeService.complexSelection()
                    )
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            includeTemplate,
            complexSelection,
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, id)],
            null,
            null,
            null);

        return this.list(objectRequestList).pipe(
            map(
                value => {
                    return <WfProcessDto>value.entries[0];
                }
            ));

    }

    public listProcessesForObjectViaId(objectTypeId: number, objectId: number, onlyActive: boolean): Observable<DtoList> {
        const request = this.getRequestForProcessForObjectViaId(objectTypeId, objectId, onlyActive);
        return this.list(request);
    }

    public listProcessesForObjectViaAlias(objectTypeAlias: string, objectId: number, onlyActive: boolean): Observable<DtoList> {
        const request = this.getRequestForProcessForObjectViaAlias(objectTypeAlias, objectId, onlyActive);
        return this.list(request);
    }


    public getRequestForProcessForObjectViaId(objectTypeId: number, objectId: number, onlyActive: boolean): ObjectRequestList {
        return this.getRequestForProcessForObject(objectTypeId, null, objectId, onlyActive);
    }

    public getRequestForProcessForObjectViaAlias(objectTypeAlias: string, objectId: number, onlyActive: boolean): ObjectRequestList {
        return this.getRequestForProcessForObject(null, objectTypeAlias, objectId, onlyActive);
    }


    protected getRequestForProcessForObject(
        objectTypeId: number,
        objectTypeAlias: string,
        objectId: number,
        onlyActive: boolean): ObjectRequestList {

        if (onlyActive) {
            return this.getRequestForProcess(objectTypeId, objectTypeAlias, objectId, true);
        } else {
            return this.getRequestForProcess(objectTypeId, objectTypeAlias, objectId, false);
        }


    }

    protected getRequestForProcess(
        objectTypeId: number,
        objectTypeAlias: string,
        objectId: number,
        onlyPending: boolean): ObjectRequestList {

        // a specific filter is used which is resolved within the ALPHA Platform

        const filterList = [];
        const onlyPendingChar: string = (onlyPending ? "O" : "A");
        const providedObjectType: any = (objectId ? objectId : objectTypeAlias);

        return new ObjectRequestList(
            true,
            [FilterCriteria.create("id", FilterCriteria.cOperatorIn, "${WF_OBJ-" + providedObjectType + "-" + objectId + "-" + onlyPendingChar + "}")],
            []);
    }

    public getLatestProcessStepComplexSelection(): ObjectRequestComplex {

        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode("steps",
                ObjectRequestRelation.createList(
                    "wf.WfProcessStep",
                    "process",
                    null,
                    [],
                    ObjectRequestComplexRelationBindingEnum.LATEST)
            ));
    }


}
