import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import BpmnModdle from 'bpmn-moddle';
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WfProcessTypeStepService} from "../../../../wf/service/api/wf-process-type-step.service";
import {WfProcessTypeStepDto} from "../../../../wf/dto/wf-process-type-step.dto";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessTypeFlowTypeEnum} from "../../../../wf/enum/wf-process-type-flow-type.enum";
import {WfActivityTypeEnum} from "../../../../wf/enum/wf-activity-type.enum";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {WfProcessTypeStepNextService} from "../../../../wf/service/api/wf-process-type-step-next.service";
import {WfProcessTypeStepNextDto} from "../../../../wf/dto/wf-process-type-step-next.dto";
import {Sorting} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-test-form',
    templateUrl: "test-daud.page.html",
    styleUrls: ['./test-daud.page.scss']
})
export class TestDaudPage implements OnInit, AfterViewInit {

    visible: boolean = false;
    selectedActivity: any;

    wfProcessTypeStepWidget: WidgetData;
    wfProcessTypeStepActivityWidget: WidgetData;
    wfProcessTypeStepActivityListWidget: WidgetData;
    wfProcessTypeStepHintWidget: WidgetData;
    wfProcessTypeStepNextListWidget: WidgetData;
    wfProcessTypeStepNextWidget: WidgetData;

    selectedProcessTypeId = 9000010;


    xml: string;


    constructor(
        protected processTypeStepService: WfProcessTypeStepService,
        protected processTypeStepNextStepService: WfProcessTypeStepNextService,
    ) {
    }


    @ViewChild('canvas') canvas: ElementRef;
    modeler: BpmnModeler;

    private initializeModeler() {
        this.modeler = new BpmnModeler({
            container: this.canvas.nativeElement,
        });
    }

    private async loadOrInitializeDiagram() {
        if (this.ttt) {
            await this.modeler.importXML(this.ttt);
        } else {
            await this.handleDiagramCreation();
        }
    }

    private setupEventHandlers() {
        const eventBus: any = this.modeler.get('eventBus');

        eventBus.on('element.dblclick', (event) => this.handleElementDoubleClick(event));
        eventBus.on('commandStack.shape.create.postExecute', async (event) => this.handleShapeCreate(event));
        eventBus.on('commandStack.shape.delete.postExecute', async (event) => this.handleShapeDelete(event));

        let selectedElementInitialState = null;

// Listen for selection changes
        eventBus.on('selection.changed', function (e) {
            const newSelection = e.newSelection[0];
            if (newSelection && newSelection.businessObject && (newSelection.type === 'bpmn:SequenceFlow' || newSelection.type === 'bpmn:MessageFlow')) {
                // Store initial state
                selectedElementInitialState = {
                    sourceRef: newSelection.businessObject.sourceRef,
                    targetRef: newSelection.businessObject.targetRef,
                };
            }
            // else {
            //     selectedElementInitialState = null;
            // }
        });

// Listen for changes to elements
        eventBus.on('element.changed', (event) => {
            const element = event.element;
            if (selectedElementInitialState && element.businessObject && (element.type === 'bpmn:SequenceFlow' || element.type === 'bpmn:MessageFlow')) {
                // Compare current state to initial state
                const sourceRefChanged = selectedElementInitialState.sourceRef !== (element.businessObject.sourceRef);
                const targetRefChanged = selectedElementInitialState.targetRef !== (element.businessObject.targetRef);

                if (sourceRefChanged && targetRefChanged) {
                    const processTypeStepId = element.businessObject.sourceRef.$attrs.objectId;
                    const nextProcessTypeStepId = element.businessObject.targetRef.$attrs.objectId;
                    this.handleCreateStepNextStep(processTypeStepId, nextProcessTypeStepId, element.businessObject.sourceRef.name);
                } else if (sourceRefChanged) {
                    const processTypeStepId = selectedElementInitialState.sourceRef.$attrs.objectId;
                    const newProcessTypeStepId = element.businessObject.sourceRef.$attrs.objectId;
                    const nextProcessTypeStepId = element.businessObject.targetRef.$attrs.objectId;
                    this.updateProcessTypeStepNext(processTypeStepId, nextProcessTypeStepId, newProcessTypeStepId);
                } else if (targetRefChanged) {

                } else {
                    const processTypeStepId = selectedElementInitialState.sourceRef.$attrs.objectId;
                    const nextProcessTypeStepId = selectedElementInitialState.targetRef.$attrs.objectId;
                    this.handleCreateStepNextStep(processTypeStepId, nextProcessTypeStepId, selectedElementInitialState.sourceRef.name);
                }
            }
            // else if(!selectedElementInitialState && element.businessObject && (element.type === 'bpmn:SequenceFlow' || element.type === 'bpmn:MessageFlow')) {
            //     const processTypeStepId = element.businessObject.sourceRef.$attrs.objectId;
            //     const nextProcessTypeStepId = element.businessObject.targetRef.$attrs.objectId;
            //     this.handleCreateStepNextStep(processTypeStepId, nextProcessTypeStepId, element.businessObject.sourceRef.name);
            // }
        });

    }

    updateProcessTypeStepNext(processTypeStepId: number, nextProcessTypeStepId: number, newProcessTypeStepId: number) {

        const filterCriteria = [
            FilterCriteria.create('processTypeStep', FilterCriteria.cOperatorEqual, Number(processTypeStepId)),
            FilterCriteria.create('nextProcessTypeStep', FilterCriteria.cOperatorEqual, Number(nextProcessTypeStepId)),
        ];

        const dtoRequest = ObjectRequestList.createBasic(false, filterCriteria, []);

        this.processTypeStepNextStepService.list(dtoRequest).subscribe(res => {
            if (res?.entries?.length) {
                const dto = new WfProcessTypeStepNextDto();
                dto.id = res.entries[0].id;
                dto.processTypeStepDtoId = newProcessTypeStepId;
                this.processTypeStepNextStepService.update(dto).subscribe(res => {
                })
            }

        })


    }

    private isTaskOrGateway(type: string) {
        return type.includes('Task') || type.includes('Gateway');
    }

    private async updateXml() {
        const xmlResult = await this.modeler.saveXML({format: true});
        this.xml = xmlResult.xml;
    }

    private async handleDiagramCreation() {
        await this.modeler.createDiagram();
        await this.saveDiagram();
    }

    private async saveDiagram() {
        await this.updateXml();
    }

    private async handleElementDoubleClick(e) {
        this.selectedActivity = e.element;
        if (this.isTaskOrGateway(this.selectedActivity.type)) {
            this.refreshStepWidget();
            this.visible = true;
        }
    }

    private async handleShapeCreate(event) {
        const shape = event.context.shape;
        if (this.isTaskOrGateway(shape.type)) {
            await this.updateXml();
            if (this.selectedActivity?.id !== shape.id) {
                this.selectedActivity = shape;
                await this.createProcessTypeStep(shape);
            }
        }
    }

    private async handleShapeDelete(event) {
        const shape = event.context.shape;
        if (this.isTaskOrGateway(shape.type)) {
            this.selectedActivity = shape;
            await this.processShapeDeletion(shape);
        }
    }

    private async createProcessTypeStep(shape) {
        // Optimization: Save XML only once after updates
        const xmlResult = await this.modeler.saveXML({format: true});
        this.xml = xmlResult.xml;

        // if (this.selectedActivity?.id === shape.id) {
        //     return;
        // }

        this.selectedActivity = shape;

        const dto = new WfProcessTypeStepDto();
        dto.processTypeDtoId = this.selectedProcessTypeId;
        dto.name = shape.id; // Use shape ID directly
        dto.position = 10000;
        dto.flowType = WfProcessTypeFlowTypeEnum.structure;

        this.processTypeStepService.create(dto).subscribe(async (res) => {
            await this.handelChangeObject(res.id);
        });
    }

    // private async processShapeDeletion(shape) {
    //     // Simplify XML handling and deletion logic
    //     const bpmnModdle = new BpmnModdle();
    //     const {rootElement: definitions} = await bpmnModdle.fromXML(this.xml);
    //     const process = definitions.get('rootElements').find(element => element.$type === 'bpmn:Process');
    //     const task = process.flowElements.find(element => element.id === shape.id);
    //
    //
    //     let previousTaskObjectId = null;
    //     let currentTaskObjectId = null;
    //
    //     if (task.incoming && task.incoming.length > 0) {
    //         let incomingFlow; // Get the first incoming flow
    //         let outgoingFlow; // Get the first outgoing flow
    //         if (task?.outgoing?.length) {
    //             outgoingFlow = task?.outgoing[0];
    //         }
    //
    //         if (task?.incoming?.length) {
    //             incomingFlow = task?.incoming[0];
    //         }
    //
    //         const sourceElementIn = process.flowElements.find(element => element.id === incomingFlow?.sourceRef?.id);
    //         const targetElementIn = process.flowElements.find(element => element.id === incomingFlow?.targetRef?.id);
    //
    //         const sourceElementOut = process.flowElements.find(element => element.id === outgoingFlow?.sourceRef?.id);
    //         const targetElementOut = process.flowElements.find(element => element.id === outgoingFlow?.targetRef?.id);
    //
    //         if (sourceElementIn && (sourceElementIn.$type.includes('Task') || sourceElementIn.$type.includes('Gateway'))) {
    //             // If the source element of the incoming flow is a Task, get its objectId
    //             previousTaskObjectId = sourceElementIn.$attrs['objectId'];
    //             currentTaskObjectId = targetElementIn.$attrs['objectId'];
    //
    //             this.handleDeleteStepNextStep(previousTaskObjectId, currentTaskObjectId, currentTaskObjectId);
    //
    //             await this.modeler.saveXML({format: true})
    //             const xmlResult = await this.modeler.saveXML({format: true})
    //             this.xml = xmlResult.xml;
    //
    //             await this.modeler.importXML(this.xml);
    //         } else if (targetElementIn && (targetElementIn.$type === 'bpmn:Task' || targetElementIn.$type === 'bpmn:ExclusiveGateway')) {
    //
    //             const nextTaskObjectId = targetElementOut.$attrs['objectId'];
    //
    //             currentTaskObjectId = targetElementIn.$attrs['objectId'];
    //
    //             this.handleDeleteStepNextStep(currentTaskObjectId, nextTaskObjectId, currentTaskObjectId);
    //
    //
    //         } else {
    //             const objectId = task.$attrs['objectId'];
    //             this.handleDeleteStep(objectId);
    //         }
    //     }
    //
    //
    //     // Save XML after deletion handling
    //     const xmlResult = await this.modeler.saveXML({format: true});
    //     this.xml = xmlResult.xml;
    //     await this.modeler.importXML(this.xml);
    // }

    private async processShapeDeletion(shape) {

        const bpmnModdle = new BpmnModdle();
        const {rootElement: definitions} = await bpmnModdle.fromXML(this.xml);
        const process = definitions.get('rootElements').find(element => element.$type === 'bpmn:Process');
        const task = process.flowElements.find(element => element.id === shape.id);

        // Check for adjacent tasks
        const hasPreviousTask = task.incoming && task.incoming.length > 0 && this.isTaskOrGateway(task?.incoming[0]?.sourceRef?.$type);
        const hasNextTask = task.outgoing && task.outgoing.length > 0 && this.isTaskOrGateway(task?.outgoing[0]?.targetRef?.$type);

        // Initialize objectIds
        let previousTaskObjectId = null;
        let currentTaskObjectId = task.$attrs['objectId'];
        let nextTaskObjectId = null;

        let sourceElementIn = null;
        let targetElementOut = null;

        // Determine previous and next task objectIds, if applicable
        if (hasPreviousTask) {
            const incomingFlow = task.incoming[0];
            sourceElementIn = process.flowElements.find(element => element.id === incomingFlow.sourceRef.id);
            previousTaskObjectId = sourceElementIn.$attrs['objectId'];
        }

        if (hasNextTask) {
            const outgoingFlow = task.outgoing[0];
            targetElementOut = process.flowElements.find(element => element.id === outgoingFlow.targetRef.id);
            nextTaskObjectId = targetElementOut.$attrs['objectId'];
        }

        // Conditionally handle deletion based on the presence of adjacent tasks
        if (!hasPreviousTask && !hasNextTask) {
            // No adjacent tasks
            this.handleDeleteStep(currentTaskObjectId);
        } else if (hasNextTask && !hasPreviousTask) {
            // Only next task
            this.handleDeleteStepNextStep(currentTaskObjectId, nextTaskObjectId, currentTaskObjectId);
        } else if (!hasNextTask && hasPreviousTask) {
            // Only previous task
            this.handleDeleteStepNextStep(previousTaskObjectId, currentTaskObjectId, currentTaskObjectId);
        } else if (hasPreviousTask && hasNextTask) {
            // Both previous and next tasks

            this.handleCreateStepNextStep(previousTaskObjectId, nextTaskObjectId, targetElementOut.name, () => {

                this.handleDeleteStepNextStep(currentTaskObjectId, nextTaskObjectId, currentTaskObjectId, false, () => {
                    this.handleDeleteStepNextStep(previousTaskObjectId, currentTaskObjectId, currentTaskObjectId);
                });
                // this.handleDeleteStepNextStep(previousTaskObjectId, currentTaskObjectId, currentTaskObjectId,false, () => {
                //     this.handleDeleteStepNextStep(nextTaskObjectId, currentTaskObjectId, currentTaskObjectId);
                // });
            });

            // this.handleDeleteStep(previousTaskObjectId, currentTaskObjectId, nextTaskObjectId);
        }

        // Save and re-import the updated XML
        const xmlResult = await this.modeler.saveXML({format: true});
        this.xml = xmlResult.xml;
        await this.modeler.importXML(this.xml);
    }

    async ngAfterViewInit() {

        this.initializeModeler();
        await this.loadOrInitializeDiagram();
        this.setupEventHandlers();


        // eventBus.on('commandStack.shape.delete.postExecute', async (event) => {
        //
        //
        //     const shape = event.context.shape;
        //
        //     if (shape.type === 'bpmn:Task' || shape.type === 'bpmn:ExclusiveGateway') {
        //
        //         this.selectedActivity = shape;
        //
        //         const bpmnModdle = new BpmnModdle();
        //         const {rootElement: definitions} = await bpmnModdle.fromXML(this.xml);
        //         const mySelectedID = this.selectedActivity.id;
        //         const bpmnProcess = definitions.get('rootElements').find(element => element.$type === 'bpmn:Process');
        //         const taskToUpdate = bpmnProcess.flowElements.find(element => element.id === mySelectedID);
        //         let previousTaskObjectId = null;
        //         let currentTaskObjectId = null;
        //
        //         if (taskToUpdate.incoming && taskToUpdate.incoming.length > 0) {
        //             let incomingFlow; // Get the first incoming flow
        //             let outgoingFlow; // Get the first outgoing flow
        //             if (taskToUpdate?.outgoing?.length) {
        //                 outgoingFlow = taskToUpdate?.outgoing[0];
        //             }
        //
        //             if (taskToUpdate?.incoming?.length) {
        //                 incomingFlow = taskToUpdate?.incoming[0];
        //             }
        //
        //             const sourceElementIn = bpmnProcess.flowElements.find(element => element.id === incomingFlow?.sourceRef?.id);
        //             const targetElementIn = bpmnProcess.flowElements.find(element => element.id === incomingFlow?.targetRef?.id);
        //
        //             const sourceElementOut = bpmnProcess.flowElements.find(element => element.id === outgoingFlow?.sourceRef?.id);
        //             const targetElementOut = bpmnProcess.flowElements.find(element => element.id === outgoingFlow?.targetRef?.id);
        //
        //             if (sourceElementIn && (sourceElementIn.$type === 'bpmn:Task' || sourceElementIn.$type === 'bpmn:ExclusiveGateway')) {
        //                 // If the source element of the incoming flow is a Task, get its objectId
        //                 previousTaskObjectId = sourceElementIn.$attrs['objectId'];
        //                 currentTaskObjectId = targetElementIn.$attrs['objectId'];
        //
        //                 this.handleDeleteStepNextStep(previousTaskObjectId, currentTaskObjectId, currentTaskObjectId);
        //
        //                 await this.modeler.saveXML({format: true})
        //                 const xmlResult = await this.modeler.saveXML({format: true})
        //                 this.xml = xmlResult.xml;
        //
        //                 await this.modeler.importXML(this.xml);
        //             } else if (targetElementIn && (targetElementIn.$type === 'bpmn:Task' || targetElementIn.$type === 'bpmn:ExclusiveGateway')) {
        //
        //                 const nextTaskObjectId = targetElementOut.$attrs['objectId'];
        //
        //                 currentTaskObjectId = targetElementIn.$attrs['objectId'];
        //
        //                 this.handleDeleteStepNextStep(currentTaskObjectId, nextTaskObjectId, currentTaskObjectId);
        //
        //                 // this.processTypeStepService.delete(currentTaskObjectId).subscribe(res => {
        //                 // });
        //
        //             }
        //         }
        //
        //     }
        // });

        // eventBus.on('element.changed', function (event) {
        //     const element = event.element;
        //
        //     // Check if the changed element is a task
        //     if (element.type === 'bpmn:Task' || element.type === 'bpmn:ExclusiveGateway') {
        //
        //         const objectId = this.selectedActivity?.businessObject?.$attrs?.objectId;
        //
        //         const dto: WfProcessTypeStepDto = new WfProcessTypeStepDto();
        //
        //         dto.id = objectId;
        //         dto.name = element.businessObject.name;
        //
        //         this.processTypeStepService.update(objectId).subscribe(res => {
        //
        //         });
        //     }
        // });

    }


    // async handleDiagramCreation() {
    //     await this.modeler.createDiagram();
    //     this.saveDiagram();
    // }


    // async saveDiagram() {
    //     const xmlResult = await this.modeler.saveXML({format: true});
    //     this.xml = xmlResult.xml
    // }


    async handelChangeObject(id: number) {

        const bpmnModdle = new BpmnModdle();
        const {rootElement: definitions} = await bpmnModdle.fromXML(this.xml);
        const mySelectedID = this.selectedActivity.id;
        const bpmnProcess = definitions.get('rootElements').find(element => element.$type === 'bpmn:Process');
        const taskToUpdate = bpmnProcess.flowElements.find(element => element.id === mySelectedID);
        let previousTaskObjectId = null;

        if (taskToUpdate.incoming && taskToUpdate.incoming.length > 0) {
            const incomingFlow = taskToUpdate.incoming[0]; // Get the first incoming flow
            const sourceElement = bpmnProcess.flowElements.find(element => element.id === incomingFlow.sourceRef.id);

            if (sourceElement && (sourceElement.$type === 'bpmn:Task' || sourceElement.$type === 'bpmn:ExclusiveGateway')) {
                // If the source element of the incoming flow is a Task, get its objectId
                previousTaskObjectId = sourceElement.$attrs['objectId'];
                this.handleCreateStepNextStep(previousTaskObjectId, id, this.selectedActivity.name);
            }
        }


        if (taskToUpdate) {
            taskToUpdate.name = this.selectedActivity.id;
            taskToUpdate.$attrs['objectId'] = id;

            const {xml: updatedXml} = await bpmnModdle.toXML(definitions);

            // Update your XML with the new changes
            this.xml = updatedXml;

            await this.modeler.importXML(this.xml);
            console.log(this.xml);
        } else {
            console.log("Task not found.");
        }

    }


    handleDeleteStepNextStep(stepId: number, nextStepId: number, objectId: number, deleteStep: boolean = true, callBack?: () => void) {

        const filterCriteria = [
            FilterCriteria.create('processTypeStep', FilterCriteria.cOperatorEqual, Number(stepId)),
            FilterCriteria.create('nextProcessTypeStep', FilterCriteria.cOperatorEqual, Number(nextStepId)),
        ];

        const dtoRequest = ObjectRequestList.createBasic(false, filterCriteria, []);

        this.processTypeStepNextStepService.list(dtoRequest).subscribe(res => {
            if (res?.entries?.length) {
                this.processTypeStepNextStepService.delete(res.entries[0].id).subscribe(res => {
                    if (deleteStep) {
                        this.handleDeleteStep(objectId, callBack);
                    } else {
                        if (callBack) {
                            callBack();
                        }
                    }
                })
            } else {
                this.handleDeleteStep(objectId, callBack);
            }

        })
    }

    handleDeleteStep(objectId: number, callBack?: () => void) {
        this.processTypeStepService.delete(objectId).subscribe(res => {
            if (callBack) {
                callBack();
            }
        });
    }

    refreshStepWidget() {
        const objectId = this.selectedActivity?.businessObject?.$attrs?.objectId;
        this.wfProcessTypeStepWidget = WidgetFactory.createWidgetObject('test.bpmn.step.widget', 'Contract', 'wf.WfProcessTypeStep', Number(objectId));
        this.refreshStepActivityList(objectId);
        this.refreshActivityWidget(objectId);
        this.refreshStepNextStepList(objectId);
        this.refreshHintsWidget(objectId);
        this.refreshStepNextWidget(objectId);
    }

    refreshStepActivityList(stepId: number) {


        this.wfProcessTypeStepActivityListWidget = WidgetFactory.createWidgetListEntity(
            "test.bpmn.activity.list.widget",
            "Activity List",
            "wf.WfProcessTypeStepActivity",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create('processTypeStep', FilterCriteria.cOperatorEqual, Number(stepId))],
                [new Sorting("createdDate", false)],
            ));

    }

    refreshStepNextStepList(stepId: number) {

        this.wfProcessTypeStepNextListWidget = WidgetFactory.createWidgetListEntity(
            "test.bpmn.step.next.list.widget",
            "Next Steps List",
            "wf.WfProcessTypeStepNext",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create('processTypeStep', FilterCriteria.cOperatorEqual, Number(stepId))],
                [new Sorting("createdDate", false)],
            ));

    }

    refreshActivityWidget(stepId: number, id: number = 0) {

        const defaultDto = new DtoDetail();
        defaultDto['processTypeStepDtoId'] = stepId;
        const overwrite = new MvsFormControlOverwrite();
        overwrite.addField("processTypeStepDtoId", MvsFormFieldAccessEnum.HIDDEN);

        if (this.selectedActivity.type == 'bpmn:ExclusiveGateway') {
            defaultDto['activityType'] = WfActivityTypeEnum.choose_next_step;
            overwrite.addField("activityType", MvsFormFieldAccessEnum.HIDDEN);
        }

        this.wfProcessTypeStepActivityWidget = WidgetFactory.createWidgetObject(
            'test.bpmn.activity.widget',
            'Activity',
            'wf.WfProcessTypeStepActivity',
            id,
            WidgetDataParam.create('createDefaultDto', defaultDto),
            WidgetDataParam.create('formControlOverwrite', overwrite)
        );
    }

    refreshHintsWidget(stepId: number, id: number = 0) {

        const defaultDto = new DtoDetail();
        defaultDto['processTypeStepDtoId'] = stepId;

        const overwrite = new MvsFormControlOverwrite();
        overwrite.addField("processTypeStepDtoId", MvsFormFieldAccessEnum.HIDDEN);

        this.wfProcessTypeStepHintWidget = WidgetFactory.createWidgetObject(
            'test.bpmn.hint.widget',
            'Hint',
            'wf.WfProcessTypeStepHint',
            id,
            WidgetDataParam.create('createDefaultDto', defaultDto),
            WidgetDataParam.create('formControlOverwrite', overwrite)
        );
    }

    refreshStepNextWidget(stepId: number, id: number = 0) {

        const defaultDto = new DtoDetail();
        defaultDto['processTypeStepDtoId'] = stepId;

        const overwrite = new MvsFormControlOverwrite();
        overwrite.addField("processTypeStepDtoId", MvsFormFieldAccessEnum.HIDDEN);

        this.wfProcessTypeStepNextWidget = WidgetFactory.createWidgetObject(
            'test.bpmn.step.next.widget',
            'Step Next',
            'wf.WfProcessTypeStepNext',
            id,
            WidgetDataParam.create('createDefaultDto', defaultDto),
            WidgetDataParam.create('formControlOverwrite', overwrite)
        );
    }

    handleCreateStepNextStep(stepId: number, nextStepId: number, name: string, callBack?: () => void) {
        const dto = new WfProcessTypeStepNextDto();
        dto.processTypeStepDtoId = stepId;
        dto.nextProcessTypeStepDtoId = nextStepId;
        dto.name = name;
        dto.position = 10;
        this.processTypeStepNextStepService.create(dto).subscribe(res => {
            if (callBack) {
                callBack();
            }
        })
    }

    handleUpdateStepNextStep(id: number, stepId: number, nextStepId: number, callBack?: () => void) {
        const dto = new WfProcessTypeStepNextDto();
        dto.id = id;
        dto.processTypeStepDtoId = stepId;
        dto.nextProcessTypeStepDtoId = nextStepId;
        this.processTypeStepNextStepService.update(dto).subscribe(res => {
            if (callBack) {
                callBack();
            }
        })
    }

    ngOnInit(): void {
        this.test();
    }


    generateBPMNXML(json) {
        let xml = `<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                                xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL"
                                xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI"
                                xmlns:dc="http://www.omg.org/spec/DD/20100524/DC"
                                xmlns:di="http://www.omg.org/spec/DD/20100524/DI"
                                xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd"
                                id="Definitions"
                                targetNamespace="http://bpmn.io/schema/bpmn">
                <bpmn:process id="Process_${json[0].id}" isExecutable="true">`;

        let x = 100, y = 100; // Starting positions for drawing elements
        const taskWidth = 100, taskHeight = 80;
        const flowLength = 200; // Length of the flow lines between tasks
        const verticalSpacing = 120; // Vertical space between branches from a gateway

        json.forEach(process => {
            let nextYStart = y;
            process.steps.forEach((step, index) => {
                if (step?.nextSteps?.length > 1) {
                    xml += `<bpmn:exclusiveGateway id="Gateway_${step.id}" name="${step.name}"/>`;
                } else {
                    xml += `<bpmn:task id="Task_${step.id}" name="${step.name}"/>`;
                }

                let maxY = y;
                if (step?.nextSteps?.length >= 1) {
                    step.nextSteps.forEach((nextStep, idx) => {
                        const sourceRef = step.nextSteps.length > 1 ? `Gateway_${step.id}` : `Task_${step.id}`;
                        const targetY = nextYStart + idx * verticalSpacing;
                        xml += `<bpmn:sequenceFlow id="Flow_${sourceRef}_to_${nextStep.nextProcessTypeStepDtoId}" sourceRef="${sourceRef}" targetRef="Task_${nextStep.nextProcessTypeStepDtoId}"/>`;
                        maxY = Math.max(maxY, targetY);
                    });
                }
                // Update y to the maximum y used in this step
                nextYStart = maxY;
                x += taskWidth + flowLength; // move x to the right for the next step
            });
        });

        xml += `</bpmn:process>`;
        xml += `<bpmndi:BPMNDiagram id="BPMNDiagram_1">
                <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_${json[0].id}">`;

        x = 100; // Reset X position for DI drawing
        y = 100; // Reset Y position for DI drawing
        json.forEach(process => {
            let nextYStart = y;
            process.steps.forEach((step, index) => {
                const elementId = step?.nextSteps?.length > 1 ? `Gateway_${step.id}` : `Task_${step.id}`;
                xml += `<bpmndi:BPMNShape id="Shape_${elementId}" bpmnElement="${elementId}">
                        <dc:Bounds x="${x}" y="${nextYStart}" width="${taskWidth}" height="${taskHeight}"/>
                    </bpmndi:BPMNShape>`;

                let maxY = nextYStart;
                if (step?.nextSteps?.length >= 1) {
                    step.nextSteps.forEach((nextStep, idx) => {
                        const sourceRef = step.nextSteps.length > 1 ? `Gateway_${step.id}` : `Task_${step.id}`;
                        const targetY = nextYStart + idx * verticalSpacing;
                        xml += `<bpmndi:BPMNEdge id="Edge_Flow_${sourceRef}_to_${nextStep.nextProcessTypeStepDtoId}" bpmnElement="Flow_${sourceRef}_to_${nextStep.nextProcessTypeStepDtoId}">
                            <di:waypoint x="${x + taskWidth}" y="${nextYStart + taskHeight / 2}"/>
                            <di:waypoint x="${x + taskWidth + flowLength / 2}" y="${nextYStart + taskHeight / 2}"/>
                            <di:waypoint x="${x + taskWidth + flowLength / 2}" y="${targetY + taskHeight / 2}"/>
                            <di:waypoint x="${x + taskWidth + flowLength}" y="${targetY + taskHeight / 2}"/>
                        </bpmndi:BPMNEdge>`;
                        maxY = Math.max(maxY, targetY);
                    });
                }

                nextYStart = maxY;
                x += taskWidth + flowLength; // Move x to the right for the next shape
            });
        });

        xml += `</bpmndi:BPMNPlane></bpmndi:BPMNDiagram></bpmn:definitions>`;

        return xml;
    }






    test() {
        const json = [
            {
                "id": 9000011,
                "createdBy": "root",
                "createdDate": "2024-04-08T12:31:17.200593Z",
                "lastModifiedBy": "root",
                "lastModifiedDate": "2024-04-08T12:31:17.200593Z",
                "instanceAble": true,
                "processGroupDtoName": "Create Customers",
                "processGroupDtoId": 1093930,
                "name": "okoko",
                "active": true,
                "steps": [
                    {
                        "id": 9000258,
                        "createdBy": "root",
                        "createdDate": "2024-04-08T12:31:17.275042Z",
                        "lastModifiedBy": "root",
                        "lastModifiedDate": "2024-04-08T12:31:17.275042Z",
                        "nextSteps": [
                            {
                                "id": 9000148,
                                "createdBy": "root",
                                "createdDate": "2024-04-08T12:31:17.429359Z",
                                "lastModifiedBy": "root",
                                "lastModifiedDate": "2024-04-08T12:31:17.429359Z",
                                "stepNextType": 0,
                                "nextProcessTypeStepDtoId": 9000259,
                                "name": "Zur Bearbeitung",
                                "processTypeStepDtoId": 9000258,
                                "position": 1,
                                "processTypeStepDtoName": "Kick-off",
                                "nextProcessTypeStepDtoName": "Bearbeitung"
                            },
                            {
                                "id": 9000152,
                                "createdBy": "root",
                                "createdDate": "2024-04-16T03:57:44.462753Z",
                                "lastModifiedBy": "root",
                                "lastModifiedDate": "2024-04-16T03:57:44.462753Z",
                                "nextProcessTypeStepDtoId": 9000260,
                                "name": "Zur Abschluss",
                                "processTypeStepDtoId": 9000258,
                                "position": 10,
                                "processTypeStepDtoName": "Kick-off",
                                "nextProcessTypeStepDtoName": "Abschluss"
                            }
                        ],
                        "processTypeDtoId": 9000011,
                        "name": "Kick-off",
                        "description": "Der Startschuss! Hier geht's los, und alle notwendigen Infos werden gesammelt.",
                        "position": 10000,
                        "processTypeDtoName": "okoko",
                        "flowType": 0
                    },
                    {
                        "id": 9000259,
                        "createdBy": "root",
                        "createdDate": "2024-04-08T12:31:17.332616Z",
                        "lastModifiedBy": "root",
                        "lastModifiedDate": "2024-04-08T12:31:17.332616Z",
                        "nextSteps": [
                            {
                                "id": 9000149,
                                "createdBy": "root",
                                "createdDate": "2024-04-08T12:31:17.476937Z",
                                "lastModifiedBy": "root",
                                "lastModifiedDate": "2024-04-08T12:31:17.476937Z",
                                "stepNextType": 0,
                                "nextProcessTypeStepDtoId": 9000260,
                                "name": "Zum Abschluss",
                                "processTypeStepDtoId": 9000259,
                                "position": 1,
                                "processTypeStepDtoName": "Bearbeitung",
                                "nextProcessTypeStepDtoName": "Abschluss"
                            }
                        ],
                        "processTypeDtoId": 9000011,
                        "name": "Bearbeitung",
                        "description": "Bearbeitung oder Verarbeitung der eingeleiteten Aufgaben. In diesem Schritt werden die erforderlichen Maßnahmen ergriffen.",
                        "position": 20000,
                        "processTypeDtoName": "okoko",
                        "flowType": 1
                    },
                    {
                        "id": 9000260,
                        "createdBy": "root",
                        "createdDate": "2024-04-08T12:31:17.376798Z",
                        "lastModifiedBy": "root",
                        "lastModifiedDate": "2024-04-08T12:31:17.376798Z",
                        "processTypeDtoId": 9000011,
                        "name": "Abschluss",
                        "description": "Finalisierung des Arbeitsablaufs. Alle erforderlichen Aufgaben wurden abgeschlossen, und der Prozess endet hier.",
                        "position": 30000,
                        "processTypeDtoName": "okoko",
                        "flowType": 2
                    }
                ],
                "results": [
                    {
                        "id": 9000022,
                        "createdBy": "root",
                        "createdDate": "2024-04-08T12:31:17.608639Z",
                        "lastModifiedBy": "root",
                        "lastModifiedDate": "2024-04-08T12:31:17.608639Z",
                        "resultCategoryDtoId": 1,
                        "processTypeDtoId": 9000011,
                        "resultCategoryDtoName": "Erfolgreich verarbeitet",
                        "resultCategoryDtoImage": "fa-solid fa-badge-check",
                        "name": "Erfolgreich verarbeitet",
                        "processTypeDtoName": "okoko"
                    },
                    {
                        "id": 9000023,
                        "createdBy": "root",
                        "createdDate": "2024-04-08T12:31:17.656520Z",
                        "lastModifiedBy": "root",
                        "lastModifiedDate": "2024-04-08T12:31:17.656520Z",
                        "resultCategoryDtoId": 2,
                        "processTypeDtoId": 9000011,
                        "resultCategoryDtoName": "Frühzeitig abgebrochen",
                        "resultCategoryDtoImage": "fa-solid fa-rectangle-xmark",
                        "name": "Frühzeitig abgebrochen",
                        "processTypeDtoName": "okoko"
                    }
                ]
            }];

        this.ttt = this.generateBPMNXML(json);
        console.log(this.ttt);
    }

    ttt

}