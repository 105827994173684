import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {DtoImportObjectContext, FilterCriteria, MvsCoreService, ObjectRequestList} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {CustomerDto} from "../../../cr/dto/customer.dto";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {
  CmContractObjectViewTypeUiEnum
} from "../../page/overview/cm-contract-page/data/cm-contract-object-view-type-ui.enum";
import {ContractDto} from "../../dto/contract.dto";
import {MvsObjectCount} from "../../../core/workflow/component/mvs-workflow/mvs-workflow.component";
import {WfProcessService} from "../../../wf/service/api/wf-process.service";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
  selector: 'mvs-cm-contract',
  templateUrl: './cm-contract.component.html',
  styleUrls: ['./cm-contract.component.scss']
})
export class CmContractComponent
        extends ObjectBaseComponent {

  @Input() transientDto: ContractDto;
  @Input() viewType: CmContractObjectViewTypeUiEnum = CmContractObjectViewTypeUiEnum.standard;
  @Input() isOverlay: boolean = false;

  @Output() onTicketsCount: EventEmitter<MvsObjectCount> = new EventEmitter<MvsObjectCount>();
  @Output() onWorkflowsCount: EventEmitter<MvsObjectCount> = new EventEmitter<MvsObjectCount>();

  dto: ContractDto;
  ticketIndicatorFilterCriteria: FilterCriteria[];
  workflowObjectRequestList: ObjectRequestList;
  navObjectIdParameterObserver: Subscription;
  @Input() customerImportObjectContext: DtoImportObjectContext;

  constructor(
      protected coreService: MvsCoreService,
      protected confirmationService: ConfirmationService,
      protected messageService: MvsMessageService,
      protected navigationService: MvsObjectNavigationService,
      protected route: ActivatedRoute,
      protected processService: WfProcessService,
      protected override observerService: ObserverService
  ) {

    super(coreService, messageService, confirmationService, observerService);

  }



  /**
   * Object Changed
   */
  onObjectChanged() {
    super.onObjectChanged();

    // refresh the widgets
    this.refreshWidgets();

  }

  protected getObjectType(): string {
    // return Object Type
    return "cm.Contract";
  }


  ngOnInit(): void {
    // this.navObjectIdParameterObserver = this.route.params.subscribe(params => {
    //
    //   if (this.isOverlay) {
    //     return;
    //   }
    //
    //   const newObject = new ObjectIdentifier(this.getObjectType(), +params["id"]);
    //
    //   if (!ObjectIdentifier.compare(this.objectIdentifier, newObject)) {
    //     this.changeObject(newObject);
    //   }
    //
    // });

    super.ngOnInit();

    this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());
    this.setGeneralTabsData();

  }

  changeObject(objectIdentifier: ObjectIdentifier) {

    // store new object
    this.objectIdentifier = objectIdentifier;
    this.onObjectChanged();

  }


  /**
   * Initialize the widgets.
   */
  refreshWidgets() {

    // make sure that the widget is loaded
    if (!this.isObjectLoaded()) {
      return;
    }
  }

  setGeneralTabsData() {

    this.ticketIndicatorFilterCriteria = [
      FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, this.dto?.joinCurrentMainCustomer.id),
    ];

    this.workflowObjectRequestList = this.processService.getRequestForProcessForObjectViaAlias(
        this.objectIdentifier.objectType,
        this.objectIdentifier.objectId,
        true
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (!this.initialized) {
      return;
    }

    if (changes["viewType"]) {
      this.refreshObject(false);
    }

    if (changes["transientDto"]) {
      this.dto = null;
      this.refreshObject(false);
    }

  }

  handleTicketsCount(event: MvsObjectCount) {
    this.onTicketsCount.emit(event);
  }

  handleWorkflowsCount(event: MvsObjectCount) {
    this.onWorkflowsCount.emit(event);
  }

    protected readonly ObjectIdentifier = ObjectIdentifier;
}
