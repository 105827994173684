import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    FilterCriteria,
    ObjectBaseComponent,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation, WidgetData
} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {ObserverService} from "@kvers/alpha-core-common";
import {HouseholdService} from "../../service/api/household.service";
import {WidgetFactory} from "@kvers/alpha-ui";


@Component({
    selector: 'object-household-component',
    templateUrl: './object-household.component.html',
})
export class ObjectHouseholdComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    householdWidget: WidgetData;
    householdPersonsWidget: WidgetData;

    constructor(protected coreService: MvsCoreService,
                protected messageService: MvsMessageService,
                protected confirmationService: ConfirmationService,
                protected observerService: ObserverService,
                protected householdService: HouseholdService) {
        super(coreService, messageService, confirmationService, observerService);
    }

    /**
     * On Object Change.
     */
    onObjectChanged() {

    }

    protected retrieveObject() {
        const request = new ObjectRequestList(
            true,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
        );

        this.householdWidget = WidgetFactory.createWidgetList(
            "object.household.household.widget",
            "Haushalt",
            "list",
            "list",
            "entity",
            "pm.Household",
            null,
            request
        );

        const objectRequestHouseholdPerson = new ObjectRequestList(
            true,
            [FilterCriteria.create('household', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
        );

        this.householdPersonsWidget = WidgetFactory.createWidgetList(
            "object.household.household.persons.widget",
            "Personen im Haushalt",
            "table",
            "list",
            "entity",
            "pm.HouseholdPerson",
            null,
            objectRequestHouseholdPerson
        );

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
