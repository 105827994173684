import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FilterCriteria, MvsCrudService} from "@kvers/alpha-core-common";
import {
  CmCreateInsurableObjectPersonComponent
} from "../../../cm/components/cm-create-insurable-object-person/cm-create-insurable-object-person.component";
import {
  CmCreateInsurableObjectPersonRelationPersonComponent
} from "../../../cm/components/cm-create-insurable-object-person-relation-person/cm-create-insurable-object-person-relation-person.component";

@Injectable({
  providedIn: 'root'
})
export class PersonRelationPersonService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/pm/personRelationPersons')
  }

  getObjectComponent() {
    return CmCreateInsurableObjectPersonRelationPersonComponent;
  }

  getPersonRelationFilterOr(personId: number): FilterCriteria {

    return FilterCriteria.create('personA', FilterCriteria.cOperatorEqual, personId);
    // const filterCriteria = new FilterCriteria()
    // filterCriteria.addFilterCriteriaToOr(FilterCriteria.create('personA', FilterCriteria.cOperatorEqual, personId));
    // filterCriteria.addFilterCriteriaToOr(FilterCriteria.create('personB', FilterCriteria.cOperatorEqual, personId));
    // return filterCriteria;
  }

}