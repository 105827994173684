<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="col-3">
            <mvs-widget *ngIf="ticketAppointmentWidget" [widgetData]="ticketAppointmentWidget"
                        (onObjectSelect)="handleObjectSelect($event)"></mvs-widget>
        </div>

        <div class="col-9">
            <div class="grid">

                <div class="col-12">

                    <p-floatLabel>
                        <input pInputText id="username"  />
                        <label for="username">Addresses, cities, zip codes</label>
                    </p-floatLabel>

                </div>

                <div class="col-12">

                    <p-button label="show Calendar" (click)="handleSideDrawer()"></p-button>
                    <p-button label="Pending List"></p-button>
                    <p-button label="All Customers"></p-button>
                    <p-button label="Add Filter" (onClick)="menu.toggle($event)"></p-button>
                    <p-dropdown
                            [options]="entityStatus"
                            [(ngModel)]="selectedStatus"
                            optionLabel="label"
                            placeholder="Status"/>
                    <p-dropdown
                            [options]="statusList"
                            [(ngModel)]="selectedStatus"
                            optionLabel="name"
                            placeholder="Tag"/>
                    <p-dropdown
                            [options]="statusList"
                            [(ngModel)]="selectedStatus"
                            optionLabel="name"
                            placeholder="History"/>
                    <p-dropdown
                            [options]="statusList"
                            [(ngModel)]="selectedStatus"
                            optionLabel="name"
                            placeholder="Last Contacted"/>

                </div>
                <div class="col-12">

                    <am-agent-map *ngIf="customersCoords" [customersCoords]="customersCoords" [agentsCoords]="testAgents"></am-agent-map>

                </div>
            </div>
        </div>

        <!--        <div class="col-3">-->
        <!--            <div class="p-4">-->
        <!--                <scheduler-component></scheduler-component>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</ng-container>

<mvs-sidebar
        sidebarPosition="right-30%"
        [isSidebarOpen]="showSideDrawer"
        (check)="showSideDrawer = $event">

    <ng-template mvs-sidebar-directive>
        <scheduler-component [persons]="persons" [events]="events" *ngIf="events"></scheduler-component>
    </ng-template>
</mvs-sidebar>

<p-menu #menu [model]="items" [popup]="true"/>