import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {DtoListAttributeRequestAggregateEnum} from "@kvers/alpha-core-common";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";


@Component({
    selector: 'mvs-widgets-overview-page',
    templateUrl: './widgets.page.html',
    styleUrls: ['widgets.page.scss']
})
export class WidgetsPage extends PageComponent implements OnInit, OnChanges, OnDestroy {

    objectCreateWidgetData: WidgetData;
    objectUpdateWidgetData: WidgetData;
    dataWidgetData: WidgetData;
    listWidgetData: WidgetData;
    tableWidgetData: WidgetData;
    cardWidgetData: WidgetData;
    chartWidgetData: WidgetData;
    timeLineWidgetData: WidgetData;
    categoryWidgetData: WidgetData;
    historyChartWidgetData: WidgetData;
    historyPeriodWidgetData: WidgetData;
    anonymousId:number = 357092;
    displayWidget = 1; // Default to the first widget

    sidebarOptions = [
        { id: 1, name: 'Object Widget Create' },
        { id: 2, name: 'Object Widget Update' },
        { id: 3, name: 'Data Widget' },
        { id: 4, name: 'List Widget' },
        { id: 5, name: 'Table Widget' },
        { id: 6, name: 'Card Widget' },
        { id: 7, name: 'Chart Widget' },
        { id: 8, name: 'Timeline Widget' },
        { id: 9, name: 'Category Widget' },
        { id: 10, name: 'History Chart Widget' },
        { id: 11, name: 'History Period Widget' },
        // Add more options as needed
    ];
    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
        super(route,coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectWidget();
        this.dataWidget();
        this.refreshComponent();
        this.listWidget();
        this.tableWidget();
        this.cardWidget();
        this.chartWidget();
        this.timelineWidget();
        this.categoryWidget();
        this.historyChartWidget();
        this.historyPeriodWidget();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    objectWidget() {
        this.objectCreateWidgetData = WidgetFactory.createWidgetObject('xu.widget.create.object', 'Object Widget', 'xx.XxAnonymizationData', 0);
        this.objectUpdateWidgetData = WidgetFactory.createWidgetObject('xu.widget.update.object', 'Object Widget', 'xx.XxAnonymizationData', this.anonymousId);
    }

    dataWidget() {
        this.dataWidgetData = WidgetFactory.createWidgetEntityData('xu.widget.data', 'Data Widget', 'xx.XxAnonymizationData', this.anonymousId)
    }

    listWidget() {

        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('id', FilterCriteria.cOperatorBetween, this.anonymousId,357132)],
            []
        )

        this.listWidgetData = WidgetFactory.createWidgetListEntity(
            'xu.widget.list',
            'List Widget',
            'xx.XxAnonymizationData',
            'Keine Daten vorhanden',
            dtoRequest,
        );
    }

    tableWidget() {

        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('id', FilterCriteria.cOperatorBetween, this.anonymousId,357132)],
            []
        )
        this.tableWidgetData = WidgetFactory.createWidgetTableEntity(
            'xu.widget.table',
            'Table Widget',
            'xx.XxAnonymizationData',
            'Keine Daten vorhanden',
            dtoRequest,
        );
    }

    cardWidget() {

        this.cardWidgetData = WidgetFactory.createWidget(
            'xu.widget.card',
            'Card Widget',
            'card',
            'list',
            'entity.groupBy',
            'xx.XxAnonymizationData',
            new WidgetDataParam("fieldCategory", "anonymizationDataType"),
            new WidgetDataParam("fieldCategoryCount", "anonymizationDataType_count"),
        );
        this.cardWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["anonymizationDataType"],
            [new ObjectRequestListAttribute("anonymizationDataType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

    }

    chartWidget() {

        this.chartWidgetData = WidgetFactory.createWidget(
            'xu.widget.chart',
            'Chart Widget',
            'chart',
            'list',
            'entity',
            'xx.XxAnonymizationData',
            new WidgetDataParam("chartType", "pie"),
            new WidgetDataParam("chartField1st", "anonymizationDataType"),
            new WidgetDataParam("chartField1stLabel", "anonymizationDataType"),
            new WidgetDataParam("chartAggregateFunction", "count"),
        );

    }

    timelineWidget() {

        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.anonymousId)],
            []
        )
        this.timeLineWidgetData = WidgetFactory.createWidgetList(
            'xu.widget.timeline',
            'Timeline Widget',
            'timeline',
            'list',
            'entity',
            'xx.XxAnonymizationData',
            'Keine Daten vorhanden',
            dtoRequest
        );
    }

    categoryWidget() {

            this.categoryWidgetData = WidgetFactory.createWidget(
            'xu.widget.category',
            'Category Widget',
            'category',
            'list',
            'entity.groupBy',
            'xx.XxAnonymizationData',
            new WidgetDataParam("fieldCategory", "anonymizationDataType"),
            new WidgetDataParam("fieldCategoryCount", "anonymizationDataType_count"),
                new WidgetDataParam("fieldName", "objectType"),
                new WidgetDataParam("sortAscending", true),
        );
        this.categoryWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["anonymizationDataType"],
            [new ObjectRequestListAttribute("anonymizationDataType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

    }

    historyChartWidget() {


        this.historyChartWidgetData = WidgetFactory.createWidget(
            'xu.widget.history.chart',
            'History Chart Widget',
            'history.chart',
            'list',
            'entity.groupBy',
            'cr.Customer',
            new WidgetDataParam("fieldCategory", "customerType"),
            new WidgetDataParam("fieldCategoryCount", "customerType_count"),
            new WidgetDataParam("historyPeriodMode", "year"),
            new WidgetDataParam("fieldDate", "createdDate"),
        );
        this.historyChartWidgetData.dataProviderListRequest = ObjectRequestListGroupBy.create(
            true,
            [],
            [],
            ["customerType", "createdDate"],
            [new ObjectRequestListAttribute("customerType", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

    }

    historyPeriodWidget() {
        this.historyPeriodWidgetData = WidgetFactory.createWidgetList(
            'xu.widget.history.period',
            'History Period Widget',
            'category.period',
            'list',
            'entity.groupBy',
            'cr.Customer',
            'No processes found',
            ObjectRequestListGroupBy.create(
                true,
                [],
                [],
                ["customerType","createdDate"],
                [ new ObjectRequestListAttribute("customerType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]),
            WidgetDataParam.create('fieldCategory','customerType'),
            WidgetDataParam.create('fieldCategoryCount','customerType_count'),
        )
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }
    selectWidget(id: number) {
        this.displayWidget = id;
    }
    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
