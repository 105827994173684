<ng-container *ngIf="initialized">



    <div class="grid">
        <div class="col-3">

            <mvs-widget [widgetData]="widgetNotificationType" *ngIf="widgetNotificationType"
            ></mvs-widget>

            <div class="surface-card shadow-1 p-3 border-round-md flex flex-column gap-3">
                <div class="flex justify-content-between align-items-center">
                    <div class="flex flex-column gap-1"><span
                            class="font-semibold text-900 text-lg">Channels</span><span
                            class="text-sm text-color-secondary"
                            *ngIf="notificationTypeChannelTypeList && notificationTypeChannelTypeList.length">{{notificationTypeChannelTypeList.length}}
                        Channels</span></div>
                    <p-button
                            *ngIf="channelsList && channelsList.length"
                            label="Create"
                            icon="fa-solid fa-plus"
                            class="cursor"
                            (click)="showDialog()"
                    ></p-button>

                </div>
                <div class="flex flex-column gap-1">

                    <div *ngFor="let notificationChannel of notificationTypeChannelTypeList ; let i = index"
                         (click)="onNotificationChannelSelect(notificationChannel); selectedIndex = i"
                         [ngClass]="{'bg-primary-50 border-primary-100':selectedIndex==i}"
                         class="flex justify-content-between align-items-center cursor border-1 border-transparent border-round-md p-3 -mx-2 hover:surface-hover">
                        <div class="flex align-items-center gap-3">
                            <i [class]="notificationChannel['channelTypeDtoImage']"></i>
                            <span>{{notificationChannel.channelTypeDtoName}}</span></div>
                        <div class="flex gap-2 align-items-center" *ngIf="selectedIndex==i">
                            <i class="pi pi-chevron-right text-primary "></i></div>
                    </div>

                </div>
            </div>

            <br />
            <mvs-widget
                    *ngIf="widgetFields"
                    [widgetData]="widgetFields"
                    [importObjectContext]="contextNotificationType"
            ></mvs-widget>

        </div>
        <div class="col-6">
            <div
                    *ngIf="widgetNotificationTypeChannelType || widgetSubjectTemplate || widgetTemplate"
                    class="p-3 flex flex-column gap-3 surface-card">

                <div class="mvs-widget" *ngIf="widgetNotificationTypeChannelType">
                    <mvs-widget
                            [widgetData]="widgetNotificationTypeChannelType">
                    </mvs-widget>
                </div>

                <div class="mvs-widget" *ngIf="widgetSubjectTemplate">
                    <mvs-widget
                            [widgetData]="widgetSubjectTemplate">
                        <ng-template let-object="object" mvs-widget-custom-field-directive area="columns" label="Show"
                                     fieldId="showButton">
                            <p-button label="Betreff Template Bearbeiten" icon="fa-sharp fa-regular fa-pen-to-square" (click)="navigateToTemplate(object)"></p-button>
                            <!-- <i class="fa-sharp fa-regular fa-pen-to-square"></i>-->
                        </ng-template>
                    </mvs-widget>
                </div>

                <div class="mvs-widget" *ngIf="widgetTemplate">
                    <mvs-widget
                            [widgetData]="widgetTemplate">
                        <ng-template let-object="object" mvs-widget-custom-field-directive area="columns" label="Show"
                                     fieldId="showButton">
                            <p-button label="Template Bearbeiten" icon="fa-sharp fa-regular fa-pen-to-square" (click)="navigateToTemplate(object)"></p-button>
                        </ng-template>
                    </mvs-widget>
                </div>
            </div>

        </div>
        <div class="col-3">

            <mvs-widget
                    *ngIf="widgetTemplateFieldBinding"
                    [widgetData]="widgetTemplateFieldBinding"
            ></mvs-widget>

            <mvs-widget
                    *ngIf="widgetContactType"
                    [widgetData]="widgetContactType"
            ></mvs-widget>

            <mvs-widget
                    *ngIf="widgetDocumentType"
                    [widgetData]="widgetDocumentType"
            ></mvs-widget>

<!--            [importObjectContext]="contextNotificationTypeChannel"-->

        </div>
    </div>


</ng-container>

<p-dialog
    #pDialog
    header="Create Channel"
    [(visible)]="visible"
    [style]="{width: '30vw'}">

    <div class="flex flex-column gap-4">

        <div class="flex flex-column gap-2">
            <span class="bold">Channel Types</span>
            <p-dropdown
                    *ngIf="channelsList && channelsList.length"
                    [options]="channelsList"
                    [(ngModel)]="selectedChannel"
                    optionLabel="name"
                    [showClear]="true"
                    placeholder="Select Channel"
                    [style]="{'min-width': '10vw'}"
            ></p-dropdown>
        </div>

        <div class="flex flex-column gap-2">
            <span class="bold">Flexible Subject</span>
            <p-selectButton
                    [options]="subjectOptions"
                    [(ngModel)]="selectedSubject"
                    optionLabel="label"
                    optionValue="value"
            ></p-selectButton>
        </div>

        <div class="flex flex-column gap-2">
            <span class="bold">Template Group</span>
            <p-dropdown
                    *ngIf="templateGroupList && templateGroupList.length"
                    [options]="templateGroupList"
                    [(ngModel)]="selectedTemplateGroup"
                    optionLabel="name"
                    [showClear]="true"
                    placeholder="Select Template Group"
                    appendTo="body"
                    class="w-full"
                    [style]="{'min-width': '10vw'}"
            ></p-dropdown>
        </div>

        <div>
            <p-button
                    label="Create"
                    class="cursor"
                    (click)="create(pDialog, $event)"
                    [disabled]="!selectedTemplateGroup || !selectedChannel"
            ></p-button>
        </div>

    </div>

</p-dialog>
