import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {CustomerFormTypePage} from "./page/overview/customer-form-type/customer-form-type.page";
import {CustomerSignatureTypePagePage} from "./page/overview/customer-signature-type-page/customer-signature-type-page.page";
import {CustomerOnboardingTypePage} from "./page/overview/customer-onboarding-type/customer-onboarding-type.page";
import {CustomerInteractionTypePage} from "./page/overview/customer-interaction-type/customer-interaction-type.page";
import {CustomerWelcomePage} from "./page/overview/customer-welcome/customer-welcome.page";
import {CustomerObjectPagePage} from "./page/overview/customer-object-page/customer-object-page.page";
import {CustomerTypePage} from "./page/overview/customer-type/customer-type.page";
import {CrDashboardPage} from "./page/dashboard/cr-dashboard/cr-dashboard.page";
import {CrCustomerComponent} from "./component/cr-customer/cr-customer.component";
import {UmModule} from "../um/um.module";
import {CrCreateObjectCustomerComponent} from "./component/cr-create-object-customer/cr-create-object-customer.component";
import {PmModule} from "../pm/pm.module";
import {CustomerExternalAliasPage} from "./page/overview/customer-external-alias/customer-external-alias.page";
import {DmModule} from "../dm/dm.module";
import {ConfigPage} from "./page/overview/config/config.page";
import {
  CrCustomerRelatedPersonsComponent
} from "./component/cr-customer-related-persons/cr-customer-related-persons.component";
import {
  CrSearchFlexibleCustomerComponent
} from "./search/cr-search-flexible-customer/cr-search-flexible-customer.component";
import {
  CrCustomerContractDetailsComponent
} from "./component/cr-customer-contract-details/cr-customer-contract-details.component";
import {
  CrCustomerPhoneContactAvailabilityComponent
} from "./component/cr-customer-phone-contact-availability/cr-customer-phone-contact-availability.component";
import {CrCustomerCommentsComponent} from "./component/cr-customer-comments/cr-customer-comments.component";
import {CustomerNotificationTypePage} from "./page/overview/customer-notification-type/customer-notification-type.page";
import {WfModule} from "../wf/wf.module";
import {TmModule} from "../tm/tm.module";
import {
  CrCustomerPhoneAvailabilityWfProcessStepActivityComponent
} from "./component/wf/cr-customer-phone-availability-wf-process-step-activity/cr-customer-phone-availability-wf-process-step-activity.component";
import {
  CrCustomerCommentWfProcessStepActivityComponent
} from "./component/wf/cr-customer-comment-wf-process-step-activity/cr-customer-comment-wf-process-step-activity.component";
import {PcModule} from "../pc/pc.module";
import {EuModule} from "../eu/eu.module";
import {NsModule} from "../ns/ns.module";
import {CrCustomerObjectPage} from "./page/object/cr-customer-object-page/cr-customer-object.page";
import {CustomerSearchPage} from "./page/overview/customer-search/customer-search.page";
import {
  CrCreateCustomerContractComponent
} from "./component/cr-create-customer-contract-component/cr-create-customer-contract.component";
import {ObjectSubBaseComponent} from "./component/cr-customer/component/object-sub-base.component";
import {
  CrCustomerStandardNavigationComponent
} from "./component/cr-customer/component/cr-customer-standard-navigation/cr-customer-standard-navigation.component";
import {
  CrCustomerServiceNavigationComponent
} from "./component/cr-customer/component/cr-customer-service-navigation/cr-customer-service-navigation.component";
import {
  CrCustomerSalesNavigationComponent
} from "./component/cr-customer/component/cr-customer-sales-navigation/cr-customer-sales-navigation.component";
import {
  CrCustomerRegistrationNavigationComponent
} from "./component/cr-customer/component/cr-customer-registration-navigation/cr-customer-registration-navigation.component";
import {
  CrCustomerTicketsWorkflowsNavigationComponent
} from "./component/cr-customer/component/cr-customer-tickets-workflows-navigation/cr-customer-tickets-workflows-navigation.component";
import {
  CrCreateCustomerContractObjectComponent
} from "./component/cr-create-customer-contract-component/object/cr-create-customer-contract-object/cr-create-customer-contract-object.component";
import {
  CrCustomerCommunicationNavigationComponent
} from "./component/cr-customer/component/cr-customer-communication-navigation/cr-customer-communication-navigation.component";
import {
  CrCustomerContractsNavigationComponent
} from "./component/cr-customer/component/cr-customer-contracts-navigation/cr-customer-contracts-navigation.component";
import {
  CrCustomerInteractionNavigationComponent
} from "./component/cr-customer/component/cr-customer-interaction-navigation/cr-customer-interaction-navigation.component";
import {
  CrCustomerAdditionalInformationNavigationComponent
} from "./component/cr-customer/component/cr-customer-additional-information-navigation/cr-customer-additional-information-navigation.component";
import {
  CrCustomerDocumentUploadComponent
} from "./component/cr-customer-document-upload/cr-customer-document-upload.component";
import {CrCustomerCreatePage} from "./page/object/cr-customer-create/cr-customer-create.page";
import {
  CrCustomerRelatedPersonComponent
} from "./component/cr-customer-related-person/cr-customer-related-person.component";
import {CrCustomerLastPage} from "./page/object/cr-customer-last/cr-customer-last.page";
import {CrCreateUserComponent} from "./component/eu/cr-create-user/cr-create-user.component";
import {CmModule} from "../cm/cm.module";
import {
  CrManageRegistrationWidgetComponent
} from "./component/cr-manage-registration/cr-manage-registration-widget/cr-manage-registration-widget.component";
import {
  CrManageRegistrationUserComponent
} from "./component/cr-manage-registration/cr-manage-registration-user/cr-manage-registration-user.component";
import {
  CrManageRegistrationDocumentComponent
} from "./component/cr-manage-registration/cr-manage-registration-document/cr-manage-registration-document.component";
import {CrRegistrationManageComponent} from "./page/overview/cr-registration-manage/cr-registration-manage.component";
import {
  CrCustomerPromoComponent
} from "./component/cr-customer/component/cr-customer-promo/cr-customer-promo.component";
import {CrFormConfigPage} from "./page/overview/cr-form-config/cr-form-config-page.component";
import {CrCustomerInlineComponent} from "./component/cr-customer-inline-component/cr-customer-inline-component";
import {
  CrCreateCustomerEmergencyContactComponent
} from "./component/cr-create-customer-emergency-contact/cr-create-customer-emergency-contact.component";
import {
  CrCreateEmergencyContactComponent
} from "./component/cr-customer/component/cr-create-emergency-contact/cr-create-emergency-contact.component";
import {CustomerOverviewPage} from "./page/customer-overview-page/customer-overview.page";
import {
  CustomerStatisticsComponent
} from "./page/customer-overview-page/components/statisticsComponent/customer-statistics.component";
import {CustomerKpiComponent} from "./page/customer-overview-page/components/kpiComponent/customer-kpi.component";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {UiModule} from "../ui/ui.module";
import {CrConfigPage} from "./page/overview/cr-config/cr-config.page";
import {
  CrCustomerNewContractsComponent
} from "./component/cr-customer/component/new-contract-navigation/cr-customer-new-contracts.component";
import {
  ContractsRelationshipsComponent
} from "./component/cr-customer/component/new-contract-navigation/components/contracts-relationships/contracts-relationships.component";
import {
  CustomerPersonHouseholdComponent
} from "./page/object/cr-customer-object-page/component/pm-household/customer-person-household.component";
import {
  CheckCustomerHouseholdComponent
} from "./component/cr-customer/component/cr-customer-standard-navigation/component/check-person-household/check-customer-household.component";
import {
  CheckIsPersonCustomerComponent
} from "./component/cr-customer/component/cr-customer-standard-navigation/component/check-is-person-customer/check-is-person-customer.component";
import {
  CrCustomerExternalIdsComponent
} from "./component/cr-customer/component/cr-customer-external-ids/cr-customer-external-ids.component";
import {
  CrCustomerAppointmentsComponent
} from "./component/cr-customer/component/cr-customer-appointments/cr-customer-appointments.component";


@NgModule({
  declarations: [
    CustomerFormTypePage,
    CustomerSignatureTypePagePage,
    CustomerOnboardingTypePage,
    CustomerInteractionTypePage,
    CustomerWelcomePage,
    CustomerObjectPagePage,
    CustomerTypePage,
    CrDashboardPage,
    CrCustomerComponent,
    CustomerExternalAliasPage,
    ConfigPage,
    CrCustomerRelatedPersonsComponent,
    CrCreateObjectCustomerComponent,
    CrSearchFlexibleCustomerComponent,
    CrCustomerContractDetailsComponent,
    CrCustomerPhoneContactAvailabilityComponent,
    CrCustomerCommentsComponent,
    CustomerNotificationTypePage,
    CrCustomerPhoneAvailabilityWfProcessStepActivityComponent,
    CrCustomerCommentWfProcessStepActivityComponent,
    CrCustomerObjectPage,
    CustomerSearchPage,
    CrCreateCustomerContractComponent,
    ObjectSubBaseComponent,
    CrCustomerStandardNavigationComponent,
    CrCustomerServiceNavigationComponent,
    CrCustomerSalesNavigationComponent,
    CrCustomerCommunicationNavigationComponent,
    CrCustomerRegistrationNavigationComponent,
    CrCustomerTicketsWorkflowsNavigationComponent,
    CrCreateCustomerContractObjectComponent,
    CrCustomerContractsNavigationComponent,
    CrCustomerInteractionNavigationComponent,
    CrCustomerAdditionalInformationNavigationComponent,
    CrCustomerDocumentUploadComponent,
    CrCustomerCreatePage,
    CrCustomerRelatedPersonComponent,
    CrCustomerLastPage,
    CrCreateUserComponent,
    CrManageRegistrationWidgetComponent,
    CrManageRegistrationUserComponent,
    CrManageRegistrationDocumentComponent,
    CrRegistrationManageComponent,
    CrCustomerPromoComponent,
    CrFormConfigPage,
    CrCustomerInlineComponent,
    CrCreateCustomerEmergencyContactComponent,
    CrCreateEmergencyContactComponent,
    CustomerOverviewPage,
    CustomerStatisticsComponent,
    CustomerKpiComponent,
    CrConfigPage,
    CrCustomerNewContractsComponent,
    ContractsRelationshipsComponent,
    CustomerPersonHouseholdComponent,
    CheckCustomerHouseholdComponent,
    CheckIsPersonCustomerComponent,

    CrCustomerExternalIdsComponent,
    CrCustomerAppointmentsComponent,
  ],
  exports:[
    CustomerFormTypePage,
    CustomerSignatureTypePagePage,
    CustomerOnboardingTypePage,
    CustomerInteractionTypePage,
    CustomerWelcomePage,
    CustomerObjectPagePage,
    CustomerTypePage,
    CrDashboardPage,
    CrCustomerComponent,
    CustomerExternalAliasPage,
    ConfigPage,
    CrCustomerRelatedPersonsComponent,
    CrCreateObjectCustomerComponent,
    CrSearchFlexibleCustomerComponent,
    CrCustomerContractDetailsComponent,
    CrCustomerPhoneContactAvailabilityComponent,
    CrCustomerCommentsComponent,
    CustomerNotificationTypePage,
    CrCustomerPhoneAvailabilityWfProcessStepActivityComponent,
    CrCustomerCommentWfProcessStepActivityComponent,
    CrCustomerObjectPage,
    CustomerSearchPage,
    CrCreateCustomerContractComponent,
    ObjectSubBaseComponent,
    CrCustomerStandardNavigationComponent,
    CrCustomerServiceNavigationComponent,
    CrCustomerSalesNavigationComponent,
    CrCustomerCommunicationNavigationComponent,
    CrCustomerRegistrationNavigationComponent,
    CrCustomerTicketsWorkflowsNavigationComponent,
    CrCreateCustomerContractObjectComponent,
    CrCustomerContractsNavigationComponent,
    CrCustomerInteractionNavigationComponent,
    CrCustomerAdditionalInformationNavigationComponent,
    CrCustomerDocumentUploadComponent,
    CrCustomerCreatePage,
    CrCustomerRelatedPersonComponent,
    CrCustomerLastPage,
    CrCreateUserComponent,
    CrManageRegistrationWidgetComponent,
    CrManageRegistrationUserComponent,
    CrManageRegistrationDocumentComponent,
    CrRegistrationManageComponent,
    CrCustomerInlineComponent,
    CrCreateCustomerEmergencyContactComponent,
    CrCreateEmergencyContactComponent,
    CrConfigPage,
    CrCustomerNewContractsComponent,
    ContractsRelationshipsComponent,
    CustomerPersonHouseholdComponent,
    CheckCustomerHouseholdComponent,
    CheckIsPersonCustomerComponent,
  ],
    imports: [
        CoreModule,
        UmModule,
        PmModule,
        DmModule,
        WfModule,
        TmModule,
        PcModule,
        EuModule,
        NsModule,
        CmModule,
      UiModule,
    ]
})
export class CrModule implements DynamicModule{

  alias = 'cr';

  constructor(private moduleRegistry: ModuleRegistryService) {
    this.moduleRegistry.registerModule(this);
  }

  getKpiComponent() {
    return CustomerKpiComponent;
  }

  getStatisticsComponent() {
    return CustomerStatisticsComponent;
  }



}



