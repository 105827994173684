import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {TmTicketDto} from "../../../../dto/tm-ticket.dto";
import {DmDocumentDto} from "../../../../../dm/dto/dm-document.dto";

@Component({
    selector: 'tm-ticket-attachment',
    templateUrl: './tm-ticket-attachment.component.html',
    styleUrls: ['./tm-ticket-attachment.component.scss']
})
export class TmTicketAttachmentComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    toggle: boolean;
    @Input() processMode: boolean = false;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() ticketDto: TmTicketDto;
    @Output() onDocumentUploaded = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {

        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        if (this.processMode == true) {
            this.toggle = true;
        }
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }

    handleDocumentUploaded(event: DmDocumentDto) {

    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
