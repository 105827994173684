<ng-container *ngIf="initialized">

    <div class="grid min-h-screen mx-0">
        <div class="p-0 mb-3" [ngClass]="screen === 'sm' ? 'col-12' : 'lg:col-4 col-12'">
            <div class="surface-border border-round shadow-1 flex-auto h-full surface-section p-2">

                <div class="col-12" *ngIf="testCaseDtoList">
                    <p-panel header="Auswahl Test Case" styleClass="shadow-1">
                        <div class="flex flex-wrap gap-2 align-items-center">
                            <p-dropdown
                                    [options]="testCaseDtoList.entries"
                                    [(ngModel)]="selectedTestCase"
                                    placeholder="Select a test case"
                                    optionLabel="name"
                                    (onChange)="refreshTestCaseVariables()"
                                    class="col-5 p-0" styleClass="w-full">
                            </p-dropdown>

                            <p-button [disabled]="busy" icon="fa-solid fa-arrows-rotate text-color-secondary"
                                      styleClass="surface-200" (click)="onRefreshTestCases()"></p-button>

                            <p-button [disabled]="busy" icon="fa-sharp fa-solid fa-flask-vial" label="View Result"
                                      (click)="onRunLogic()" *ngIf="selectedTestCase"></p-button>

                        </div>
                    </p-panel>

                </div>

                <div class="col-12">


                    @if (testCaseVariableCheck) {
                        <p-panel header="Mapping Variablen" styleClass="h-full shadow-1">

                            @for (mappedVariable of testCaseVariableCheck.mappedVariables; track mappedVariable) {

                                <div class="flex mt-3 mb-1">
                                    <div class="text-color-secondary w-20rem">
                                        {{ mappedVariable.field.name }}
                                    </div>

                                    <div class="font-medium">
                                        @if (mappedVariable.valueIsNull) {
                                            NULL
                                        } @else if (mappedVariable.valueObject) {
                                            Object ID: {{ mappedVariable.valueObject.id }}
                                            @if (mappedVariable.valueObject.name) {
                                                {{ mappedVariable.valueObject.name }}
                                            }
                                        } @else if (mappedVariable.valueSimple) {
                                            {{ mappedVariable.valueSimple }}
                                        }
                                    </div>
                                </div>
                            }

                        </p-panel>

                        @if (testCaseVariableCheck.missingVariables && testCaseVariableCheck.missingVariables.length > 0) {
                            <p-panel header="Fehlende Variablen" styleClass="h-full shadow-1">

                                @for (missingVariable of testCaseVariableCheck.missingVariables; track missingVariable) {

                                    <div class="flex mt-3 mb-1">
                                        <!--                                        <div style="color: var(&#45;&#45;red-500);">-->
                                        <!--                                            <i class="fa fa-exclamation-circle"></i>-->
                                        <!--                                        </div>-->
                                        <div class="text-color-secondary">
                                            {{ missingVariable.name }}
                                        </div>
                                    </div>
                                }

                            </p-panel>
                        }

                    }

                </div>

                <div class="col-12" *ngIf="occurredException">
                    <p-message severity="error" [text]="occurredException"></p-message>

                    <p-message *ngIf="occurredExceptionDetails" severity="error"
                               [text]="occurredExceptionDetails"></p-message>
                </div>

                @if (testCaseEvaluation) {
                    <div class="col-12">
                        <p-panel header="Result" styleClass="h-full shadow-1">

                            <div class="flex mt-3 mb-1">
                                <div class="text-color-secondary w-20rem">
                                    Complete Value
                                </div>
                                <div class="font-medium">
                                    @if (testCaseEvaluation.mainCalculationValueIsNull) {
                                        NULL
                                    } @else if (testCaseEvaluation.mainCalculationValueSimple) {
                                        {{ testCaseEvaluation.mainCalculationValueSimple }}
                                    } @else if (testCaseEvaluation.mainCalculationValueObject) {
                                        Object ID: {{ testCaseEvaluation.mainCalculationValueObject.id }}
                                        @if (testCaseEvaluation.mainCalculationValueObject.name) {
                                            {{ testCaseEvaluation.mainCalculationValueObject.name }}
                                        }
                                    }
                                </div>
                            </div>

                            @for (fieldValue of testCaseEvaluation.fieldValues; track fieldValue) {

                                <div class="flex mt-3 mb-1">
                                    <!--                                <div style="color: var(&#45;&#45;green-500);">-->
                                    <!--                                        <i class="fa fa-check-circle p-success"></i>-->
                                    <!--                                    </div>-->
                                    <div class="text-color-secondary w-20rem">
                                        {{ fieldValue.field.name }}
                                    </div>
                                    <div class="font-medium">
                                        @if (fieldValue.valueIsNull) {
                                            NULL
                                        } @else if (fieldValue.valueObject) {
                                            Object ID: {{ fieldValue.valueObject.id }}
                                            @if (fieldValue.valueObject.name) {
                                                {{ fieldValue.valueObject.name }}
                                            }
                                        } @else if (fieldValue.valueSimple) {
                                            {{ fieldValue.valueSimple }}
                                        }
                                    </div>
                                </div>
                            }

                        </p-panel>
                    </div>
                }

            </div>
        </div>

        <div class="p-0 mb-3" [ngClass]="screen === 'sm' ? 'col-5' : 'col-12 lg:col-3'">
            <div class="min-h-screen surface-section border-round shadow-1"  [ngClass]="screen === 'sm' ? 'mr-3' : 'mx-3'">
                <mvs-widget
                        *ngIf="widgetTestCases"
                        [widgetData]="widgetTestCases"
                        [importObjectContext]="logicImportObjectContext"
                        (onObjectSelect)="handleTestCaseSelect($event)"
                ></mvs-widget>
            </div>
        </div>

        <div class="p-0 mb-3" [ngClass]="screen === 'sm' ? 'col-7' : 'col-12 lg:col-5'">
            <div class="min-h-screen surface-section border-round shadow-1">
                <mvs-widget
                        *ngIf="widgetTestCaseVariables"
                        [widgetData]="widgetTestCaseVariables"
                ></mvs-widget>
            </div>
        </div>

    </div>


</ng-container>