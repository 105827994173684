import {Component} from '@angular/core';
import {
    MvsCoreService, MvsMessageService,
    NavigationItem, ObjectIdentifierData,
    ObjectRequestList,
    PageComponent, Sorting,
    TabEntries, WidgetData, WidgetDataParam,
} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {MessageService} from "primeng/api";


@Component({
    selector: 'mvs-em-config',
    templateUrl: './em-config.page.html',
})
export class EmConfigPage extends PageComponent {

    objectBrowserRequest: any;

    objectRequestList: ObjectRequestList;

    defaultLabel: string = "External Schema Management";

    activeNavItem: string = "em";

    queryParamSubscription: Subscription;
    activeWidget: TabEntries | null = null;
    activeTabIndex: number = -1;

    navigationItems: NavigationItem[] = [
        {
            label: 'basic',
            tooltip: 'Schema Types',
            icon: 'fa-solid fa-link', // Represents connections or adapters
            toggleable: false,
            default: false
        },
        {
            label: 'schema',
            tooltip: 'Schema',
            icon: 'fa-solid fa-server', // Represents systems or infrastructure
            toggleable: false,
            default: false
        },
    ];

    managedEntities: { module: string, alias: string, label: string, widgetData?: WidgetData }[] = [
        { module: 'basic', alias: 'em.EmSchemaType', label: 'Schema Types' },
        { module: 'schema', alias: 'em.EmSchema', label: 'Schemas' },
        { module: 'schema', alias: 'em.EmSchemaField', label: 'Fields' },
        { module: 'schema', alias: 'em.EmSchemaFieldComment', label: 'Field Comments' },
        { module: 'schema', alias: 'em.EmSchemaFieldValue', label: 'Field Values' },
        { module: 'schema', alias: 'em.EmSchemaFieldValueComment', label: 'Field Value Comments' },
        { module: 'schema', alias: 'em.EmSchemaStructure', label: 'Structure' },
    ];

    batchBusy: boolean = false;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService,) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.refreshWidgets();
        this.subscribeToParams()
        this.initialized = true;
    }

    refreshWidgets() {

        for (let managedEntity of this.managedEntities) {

            managedEntity.widgetData = WidgetFactory.createWidgetTableEntity(
                "config.em." + managedEntity.alias + ".simple",
                managedEntity.label,
                managedEntity.alias,
                "Keine Daten vorhanden",
                ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
            );

        }


    }

    // Method to get filtered entities
    getFilteredManagedEntities() {
        return this.managedEntities.filter(entity => entity.module === this.activeNavItem);
    }


    subscribeToParams() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
            this.activeNavItem = params['navItem'];
        });
    }

    getObjectRequestList() {
        return ObjectRequestList.createBasic(true, [], [])
    }


    handleTabSelection(selectedIndex: number) {
        this.activeTabIndex = selectedIndex;
    }


    isConfigurationPage(): boolean {
        return true;
    }

    isNavComponent(): NavigationItem[] {
        return this.navigationItems;
    }


}


