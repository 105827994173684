import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
    FilterCriteria,
    MvsCoreService, ObjectRequestComplex, ObjectRequestComplexNode, ObjectRequestList, ObjectRequestRelation,
    PageComponent, WidgetData,
} from "@kvers/alpha-core-common";
import {EditorView} from "@codemirror/view";
import {autocompletion, Completion} from "@codemirror/autocomplete";
import {EditorState, Extension} from "@codemirror/state";
import {basicSetup} from "codemirror";
import {javascript} from "@codemirror/lang-javascript";
import { CompletionSource, CompletionContext, CompletionResult } from '@codemirror/autocomplete';
import {history} from "@codemirror/commands";
import {ProvisionTypeService} from "../../../../cp/service/api/provision-type.service";
import {ActivatedRoute} from "@angular/router";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {DmDocumentService} from "../../../../dm/service/api/dm-document.service";
@Component({
    selector: 'mvs-test-form-ather',
    templateUrl: 'test-ather.page.html',
    styleUrls: ['./test-ather.page.scss']
})
export class TestAtherPage extends PageComponent implements OnInit, AfterViewInit {

    editorContent: string = 'hello world';

    provisionTypeWidget: WidgetData;
    provisionTypeContractAgentProvisionWidget: WidgetData;

    steps = [
        { label: 'Step 1: Start', summary: 'Initial Setup', content: 'Step 1 details go here.', index: 0 },
        { label: 'Step 2: Processing', summary: 'Processing Data', content: 'Step 2 details go here.', index: 1 },
        { label: 'Step 3: Finish', summary: 'Final Step', content: 'Step 3 details go here.', index: 2 }
    ];

    expandedSteps: { [key: number]: boolean } = {}; // Track expanded steps

    toggleStep(index: number) {
        this.expandedSteps[index] = !this.expandedSteps[index]; // Toggle visibility
    }

    nextStep(index: number) {
        if (index < this.steps.length - 1) {
            this.expandedSteps[index + 1] = true; // Auto-open next step
        }
    }

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected provisionTypeService: ProvisionTypeService,
                protected documentService: DmDocumentService
    ) {
        super(route, coreService);
    }


    loadFormData() {
        this.documentService.getFormData(233115).subscribe(res => {
            debugger
        })
    }

    ngOnInit() {
        super.ngOnInit();

        this.loadFormData();
        // const crudService = this.coreService.getCrudService('cm.Contract');


        // const objectRequestList = ObjectRequestList.createBasic(true,[FilterCriteria.create('id', FilterCriteria.cOperatorEqual, 12071)],[]);
        // objectRequestList.objectRequestComplex = ObjectRequestComplex.build(true,false,ObjectRequestComplexNode.createRelationNode('daud', ObjectRequestRelation.createJoin('cm.ContractStatus#contract')))


        const objectRequestList = ObjectRequestList.createBasic(true,[FilterCriteria.create('analyzed', FilterCriteria.cOperatorEqual, true)],[]);
        // objectRequestList.objectRequestComplex = ObjectRequestComplex.build(true,false,ObjectRequestComplexNode.createRelationNode('daud', ObjectRequestRelation.createJoin('cm.ContractStatus#contract')))

        // this.documentService.list(objectRequestList).subscribe(res => {
        //     debugger
        // })

        // crudService.list(objectRequestList).subscribe(res => {
        //     debugger;
        // })

        // this.provisionTypeWidget = WidgetFactory.createWidgetObject('sszxssawqe','asdasd','cp.ProvisionType',0)
//         this.provisionTypeContractAgentProvisionWidget = WidgetFactory.createWidgetObject('ssssxxxawqe','asdzxasd','cp.ProvisionTypeAgentPayment',0)
//
//         const objectRequestList = ObjectRequestList.createBasic(true,[],[]);
//         objectRequestList.objectRequestComplex = ObjectRequestComplex.build(true,false,ObjectRequestComplexNode.createRelationNode('contractType', ObjectRequestRelation.createJoin('cp.ProvisionContractType#partnerProvisionType')))
//
//
//         this.provisionTypeService.list(objectRequestList).subscribe(res => {
// debugger
//         })

    }

    @ViewChild('editor') editorElement!: ElementRef;
    private view!: EditorView;

    ngAfterViewInit(): void {
        this.initializeEditor();
    }

    initializeEditor(): void {
        const myEditorElement = this.editorElement.nativeElement;

        // Define your tree node structure
        const treeNode = {
            field: 'customer',
            children: [
                { field: 'id' },
                { field: 'name' },
            ],
        };

        const combinedCompletions: CompletionSource = async (context) => {
            const word = context.matchBefore(/\w*/);
            if (!word || word.from === word.to) return null;

            // Fetch built-in completions
            const languageData = context.state.languageDataAt('autocomplete', word.from);

            // Extract options if available
            const builtInCompletions = (languageData.find((data: any) => data.options) as any)?.options || [];

            // Custom completions
            const customCompletions = [
                { label: 'customer', type: 'variable', detail: 'Root object' },
                { label: 'customer.id', type: 'property', detail: 'Customer ID' },
                { label: 'customer.name', type: 'property', detail: 'Customer name' },
            ];

            return {
                from: word.from,
                options: [
                    ...builtInCompletions, // Add built-in options if any
                    ...customCompletions, // Add custom options
                ],
            };
        };

        // Define CodeMirror extensions
        const extensions: Extension[] = [
            basicSetup,
            history(),
            javascript(), // Use JavaScript highlighting for Groovy
            autocompletion(), // Autocomplete setup
            EditorView.updateListener.of((update) => {
                if (update.docChanged) {
                }
            }),
            EditorView.domEventHandlers({
                dragover: (event) => {
                    event.preventDefault(); // Allow dropping
                },
                drop: (event) => {
                    event.preventDefault();
                },
            }),
        ];

        // Create the editor state
        const state = EditorState.create({
            doc: this.editorContent, // Initial content
            extensions,
        });

        // Initialize the editor view
        this.view = new EditorView({
            state,
            parent: myEditorElement,
        });
    }

}
