// category.interface.ts
export interface ReportModel {
    id: number;
    name: string;
    label: string;
    categoryDtoId: number;
    reportFieldGroups:ReportFieldGroups[];
}

export interface ReportFieldGroups {
    fields:ReportField[];
    internalType: ReportFieldGroupEnum
}

export interface ReportField {
    name: string;
    role: ReportFieldGroupRoleEnum;
}

export enum ReportFieldGroupEnum {
    PERIOD_DEPENDENT,
    KEY_DATE_DEPENDENT = "KEY_DATE_DEPENDENT"
}

export enum ReportFieldGroupRoleEnum {
    START_DATE,
    END_DATE,
    KEY_DATE
}