<p-button label="sidebar" (click)="handleSideDrawer()"></p-button>

<mvs-sidebar  *ngIf="showSideDrawer"
        sidebarPosition="right-30%"
        [isSidebarOpen]="showSideDrawer"
        (check)="showSideDrawer = $event">

    <ng-template mvs-sidebar-directive>
        <scheduler-component [persons]="persons" [events]="events" *ngIf="events"></scheduler-component>
    </ng-template>
</mvs-sidebar>


<scheduler-component [persons]="persons" [events]="events" *ngIf="events"></scheduler-component>