import {
    Component, OnInit,
} from '@angular/core';
import {
    WfProcessStepActivitySideBaseComponent
} from "../wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";
import {WfProcessStepActivityEvent} from "../../../logic/wf-process-step-activity.event";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../../cc/dto/object-type.dto";
import {WfActivityTypeEnum} from "../../../enum/wf-activity-type.enum";
import {WfActivityTypeService} from "../../../service/api/wf-activity-type.service";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {WfProcessTypeStepActivityService} from "../../../service/api/wf-process-type-step-activity.service";

@Component({
    selector: 'mvs-wf-process-step-activity-side-generic',
    templateUrl: './wf-process-step-activity-side-generic.component.html',
    styleUrls: ['./wf-process-step-activity-side-generic.component.scss']
})
export class WfProcessStepActivitySideGenericComponent extends WfProcessStepActivitySideBaseComponent implements OnInit {

    objectTypeDto: ObjectTypeDto;
    constructor(
        protected coreService: MvsCoreService,
        protected objectTypeService: ObjectTypeService) {

        super(coreService);
    }

    refreshComponent() {
        this.initialized = true;
    }

    async onStartActivity() {

        let objectId: number = 0;
        let objectAlias = "";

        // if (this.activityType.activityObjectDto.activityEnum !== WfActivityTypeObjectActivityEnum.create) {
        //     const processedObjectBinding = WfProcessRuntimeBindingDto.deriveBinding(this.bindings, "objectProcess");
        //
        //     //processedObjectBinding.objectValue
        //     objectId = processedObjectBinding.objectValue.id;
        //
        // }

        // if (!this.objectTypeDto || this.objectTypeDto.id !== this.activityType.activityObjectDto.objectTypeDtoId) {
        //     // this.objectTypeDto = await this.objectTypeService.getViaObjectId(this.activityType.objectTypeDtoId);
        //
        //     this.objectTypeDto = await this.objectTypeService.getViaObjectId(this.activityType.activityObjectDto.objectTypeDtoId);
        // }
        //
        // objectAlias = this.objectTypeDto.alias;

        this.onAction.emit(new WfProcessStepActivityEvent(new ObjectIdentifier(objectAlias, objectId), this.activityType));
    }

}
