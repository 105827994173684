import {
    WidgetTableUiStyle
} from "@kvers/alpha-ui";
import {DtoDetail, MvsConfigColors} from "@kvers/alpha-core-common";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerDto} from "../../../../../dto/customer.dto";
import {HouseholdPersonDto} from "../../../../../../pm/dto/household-person.dto";
import {CustomerContractDto} from "../../../../../dto/customer-contract.dto";
import {ContractInsurableObjectDto} from "../../../../../../ci/dto/contract-insurable-object.dto";
import {ContractPersonDto} from "../../../../../../ci/dto/contract-person.dto";
import {PersonDto} from "../../../../../../pm/dto/person.dto";
import {ObjectInfo} from "../data/object-info";
import {ObjectRelation} from "../data/object-relation";

export class CustomerContractPersonWidgetStyle extends WidgetTableUiStyle {
    configColors: MvsConfigColors;
    selectedContractCustomerId: number;
    selectedId: number;
    customerContracts: ObjectRelation[];

    constructor(protected configService: MvsConfigService,
                protected mainCustomer: CustomerDto,
                protected householdPersons: PersonDto[]) {
        super();
        this.configColors = this.configService.getColors();
    }

    public hasHouseholdPersons(): boolean {
        return (this.householdPersons && this.householdPersons.length > 0);
    }

    public getHouseholdPersons(): PersonDto[] {
        return this.householdPersons;
    }

    public getHouseholdPerson(id: number): PersonDto {
        const dto: PersonDto = this.householdPersons.find(item => item.id == id);
        return dto;
    }

    public handleHouseholdPerson(person: PersonDto) {

        const exists = this.getHouseholdPerson(person.id);
        if (!exists) {
            this.addHouseholdPerson(person);
        } else {
            this.removeHouseholdPerson(person);
        }
    }

    public setCustomerContracts(customerContracts: ObjectRelation[]) {
        this.customerContracts = customerContracts;
    }

    public addHouseholdPerson(householdPerson: PersonDto) {

        if (!householdPerson['__color']) {
            const nextColor = this.configColors.getNextColor();
            householdPerson['__color'] = nextColor + '80';
            householdPerson['__backgroundColor'] = nextColor + '10';
        }
        this.householdPersons.push(householdPerson);


    }

    protected removeHouseholdPerson(householdPerson: PersonDto) {
        const index = this.householdPersons.findIndex(item => item.id == householdPerson.id);
        if (index > -1) {
            this.householdPersons.splice(index, 1);
        }
    }


    getRowStyle(rowDto: CustomerContractDto, rowData: any): object {

        for (let item of this.customerContracts) {
            if (rowDto.id == item.toId) {
                const person = this.householdPersons.find(value => value.id == item.fromId);
                if (person) {

                    return {
                        "box-shadow": "inset 5px 0 0 0 " + person['__color'],
                        // "border-radius": "6px",
                        // "background-color": person['__color']
                    };
                }
            }
        }

        // const contractDto = rowDto?.contractDto;
        //
        // if (!contractDto) {
        //     return {};
        // }
        //
        // if (contractDto.contractPerson) {
        //
        //     for (let householdPerson of this.householdPersons) {
        //         for (let person of contractDto.contractPerson) {
        //             if (householdPerson.personDtoId == person.personDtoId) {
        //                 return {
        //                     "border": "2px",
        //                     "background-color": householdPerson['__color']
        //                 };
        //             }
        //         }
        //     }
        // }
        //
        // if (!this.selectedId) {
        //     return {}
        //
        // } else if (contractDto.contractInsurableObject && contractDto.contractPerson) {
        //
        //     const insurableObjectClass = this.checkInsurableObjectClass(contractDto.contractInsurableObject);
        //     const contractPersonClass = this.checkContractPersonClass(contractDto.contractPerson);
        //
        //     //checking if object is not empty
        //     if (Object.keys(insurableObjectClass).length || Object.keys(contractPersonClass).length) {
        //         return this.getRowStyleClass();
        //     }
        //
        // } else if (contractDto.contractInsurableObject) {
        //     return this.checkInsurableObjectClass(rowDto?.contractDto.contractInsurableObject)
        //
        //
        // } else if (contractDto.contractPerson) {
        //     return this.checkContractPersonClass(rowDto?.contractDto.contractPerson);
        // }
        return {};

    }

    protected checkInsurableObjectClass(insurableObject: ContractInsurableObjectDto[]): object {
        for (let object of insurableObject) {
            if (object.insurableObjectDto?.id == this.selectedId) {
                return this.getRowStyleClass();
            }
        }
        return {};
    }

    protected checkContractPersonClass(contractPersons: ContractPersonDto[]): object {
        for (let object of contractPersons) {
            if (object.personDtoId == this.selectedId) {
                return this.getRowStyleClass();
            }
        }
        return {};
    }

    getRowStyleClass(): object {
        return {
            "border": "2px",
            "background-color": 'var(--blue-100)'
        };
    }

    getTransientExpandableStyle(rowDto: DtoDetail, rowData?: any): object {

        for (let item of this.householdPersons) {
            if (rowDto.id == item.id) {
                return {
                    "border-left": "4px solid " + item['__color'],
                    "border-radius": '6px',
                    "display": 'block'
                };
            }
        }
        return {};
    }

    public clone(): CustomerContractPersonWidgetStyle {

        const widgetStyle = new CustomerContractPersonWidgetStyle(this.configService, this.mainCustomer, this.householdPersons);
        widgetStyle.configColors = this.configColors;
        widgetStyle.selectedContractCustomerId = this.selectedContractCustomerId;
        widgetStyle.selectedId = this.selectedId;
        widgetStyle.customerContracts = this.customerContracts;

        return widgetStyle;
    }

}
