
import {AddressService} from "../../ad/service/api/address.service";
import {CountryService} from "../../ad/service/api/country.service";
import {AddressTypeService} from "../../ad/service/api/address-type.service";
import {UiFieldService} from "../../ui/service/api/ui-field.service";
import {UiFieldTypeService} from "../../ui/service/api/ui-field-type.service";
import {UiFieldGroupService} from "../../ui/service/api/ui-field-group.service";
import {InsuranceCompanyGroupService} from "../../cm/service/api/insurance-company-group.service";
import {InsuranceTypeService} from "../../cm/service/api/insurance-type.service";
import {DivisionService} from "../../cm/service/api/division.service";
import {RatingService} from "../../cm/service/api/rating.service";
import {InsuranceCompanyService} from "../../cm/service/api/insurance-company.service";
import {InsuranceCompanyDivisionService} from "../../cm/service/api/insurance-company-division.service";
import {FieldService} from "../../cc/service/api/field.service";
import {FieldNamespaceService} from "../../cc/service/api/field-namespace.service";
import {FieldTypeService} from "../../cc/service/api/field-type.service";
import {FieldValueListService} from "../../cc/service/api/field-value-list.service";
import {FieldValueListEntryService} from "../../cc/service/api/field-value-list-entry.service";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeAttributeService} from "../../cc/service/api/object-type-attribute.service";
import {InsuranceTypeInsurableObjectTypeService} from "../../cm/service/api/insurance-type-insurable-object-type.service";
import {OrganizationalUnitService} from "../../om/service/api/organizationalUnit.service";
import {PositionService} from "../../om/service/api/position.service";
import {EmployeeService} from "../../om/service/api/employee.service";
import {DepartmentService} from "../../om/service/api/department.service";
import {PersonService} from "../../pm/service/api/person.service";
import {PersonTypeService} from "../../pm/service/api/person-type.service";
import {PersonDetailService} from "../../pm/service/api/person-detail.service";
import {AddressTypeObjectTypeService} from "../../ad/service/api/address-type-object-type.service";
import {ContactTypeService} from "../../cd/service/api/contact-type.service";
import {ContactTypeFieldService} from "../../cd/service/api/contact-type-field.service";
import {ContactTypeObjectTypeService} from "../../cd/service/api/contact-type-object-type.service";
import {EmployeePositionService} from "../../om/service/api/employeePosition.service";
import {AgentService} from "../../am/service/api/agent.service";
import {AgentContactService} from "../../am/service/api/agent-contact.service";
import {AgentPoolService} from "../../am/service/api/agent-pool.service";
import {AgentPoolAgentService} from "../../am/service/api/agent-pool-agent.service";
import {AgentPoolContactService} from "../../am/service/api/agent-pool-contact.service";
import {CfFrequencyService} from "../../cf/service/api/cf-frequency.service";
import {CfPayFrequencyService} from "../../cf/service/api/cf-pay-frequency.service";
import {CfPaymentMomentService} from "../../cf/service/api/cf-payment-moment.service";
import {CfPaymentTypeService} from "../../cf/service/api/cf-payment-type.service";
import {CfRoundingRuleService} from "../../cf/service/api/cf-rounding-rule.service";
import {CfTemplateCalculationBaseService} from "../../cf/service/api/cf-template-calculation-base.service";
import {CfTemplateCancellationBufferService} from "../../cf/service/api/cf-template-cancellation-buffer.service";
import {CfTemplateLiabilityService} from "../../cf/service/api/cf-template-liability.service";
import {InsuranceService} from "../../cm/service/api/insurance.service";
import {InsuranceAcquisitionCommissionService} from "../../cm/service/api/insurance-acquisition-commission.service";
import {InsuranceDiscountService} from "../../cm/service/api/insurance-discount.service";
import {InsurancePaymentService} from "../../cm/service/api/insurance-payment.service";
import {InsurancePriceService} from "../../cm/service/api/insurance-price.service";
import {InsurancePriceDiscountService} from "../../cm/service/api/insurance-price-discount.service";
import {InsurancePriceRangeService} from "../../cm/service/api/insurance-price-range.service";
import {InsuranceStatusService} from "../../cm/service/api/insurance-status.service";
import {InsuranceStatusTypeService} from "../../cm/service/api/insurance-status-type.service";
import {InsuranceStatusTypeEntryService} from "../../cm/service/api/insurance-status-type-entry.service";
import {InsuranceTariffService} from "../../cm/service/api/insurance-tariff.service";
import {InsuranceTaxService} from "../../cm/service/api/insurance-tax.service";
import {CustomerService} from "../../cr/service/api/customer.service";
import {CustomerAddressService} from "../../cr/service/api/customer-address.service";
import {CustomerContactService} from "../../cr/service/api/customer-contact.service";
import {CustomerInsurableObjectService} from "../../cr/service/api/customer-insurable-object.service";
import {CustomerInsuranceService} from "../../cr/service/api/customer-insurance.service";
import {CustomerTypeService} from "../../cr/service/api/customer-type.service";
import {TemplateAcquisitionCommissionService} from "../../ac/service/api/template-acquisition-commission.service";
import {AgentCancellationBufferAssignmentService} from "../../ac/service/api/agent-cancellation-buffer-assignment.service";
import {AcquisitionCommissionAssignmentService} from "../../ac/service/api/acquisition-commission-assignment.service";
import {ContactService} from "../../cd/service/api/contact.service";
import {WfProcessTypeService} from "../../wf/service/api/wf-process-type.service";
import {TicketService} from "../../tm/service/api/ticket.service";
import {TmCommunicationChannelService} from "../../tm/service/api/tmcommunicationchannel.service";
import {TicketGroupService} from "../../tm/service/api/ticket-group.service";
import {TicketTypeService} from "../../tm/service/api/ticket-type.service";
import {TicketTypeActionService} from "../../tm/service/api/ticket-type-action.service";
import {TicketTypeFieldService} from "../../tm/service/api/ticket-type-field.service";
import {TicketTypeObjectTypeService} from "../../tm/service/api/ticket-type-object-type.service";
import {TicketActionService} from "../../tm/service/api/ticket-action.service";
import {TicketCommentService} from "../../tm/service/api/ticket-comment.service";
import {TicketWatchListService} from "../../tm/service/api/ticket-watch-list.service";
import {TicketRelTicketService} from "../../tm/service/api/ticket-rel-ticket.service";
import {CustomerFormService} from "../../cr/service/api/customer-form.service";
import {CustomerFormTypeService} from "../../cr/service/api/customer-form-type.service";
import {CustomerFormGroupService} from "../../cr/service/api/customer-form-group.service";
import {CustomerOnboardingService} from "../../cr/service/api/customer-onboarding.service";
import {CustomerOnboardingTypeService} from "../../cr/service/api/customer-onboarding-type.service";
import {CustomerOnboardingTypeContractTypeService} from "../../cr/service/api/customer-onboarding-type-contract-type.service";
import {CustomerOnboardingTypeInfoStepService} from "../../cr/service/api/customer-onboarding-type-info-step.service";
import {CustomerSignatureTypeService} from "../../cr/service/api/customer-signature-type.service";
import {CustomerWelcomeService} from "../../cr/service/api/customer-welcome.service";
import {SignatureService} from "../../sm/service/api/signature.service";
import {SignatureGroupService} from "../../sm/service/api/signature-group.service";
import {SignatureTypeService} from "../../sm/service/api/signature-type.service";
import {CustomerFormTypeFieldService} from "../../cr/service/api/customer-form-type-field.service";
import {CustomerRegistrationConfigurationService} from "../../cr/service/api/customer-registration-configuration.service";
import {CustomerPhoneContactAvailabilityService} from "../../cr/service/api/customer-phone-contact-availability.service";
import {DmDocumentTypeService} from "../../dm/service/api/dm-document-type.service";
import {DmDocumentGroupService} from "../../dm/service/api/dm-document-group.service";
import {DmDocumentTypeMimeTypeService} from "../../dm/service/api/dm-document-type-mime-type.service";
import {DmMimeTypeService} from "../../dm/service/api/dm-mime-type.service";
import {DmDocumentStorageService} from "../../dm/service/api/dm-document-storage.service";
import {CoChannelTypeService} from "../../co/service/api/co-channel-type.service";
import {CoChannelTypeContactTypeService} from "../../co/service/api/co-channel-type-contact-type.service";
import {AppointmentGroupService} from "../../as/service/api/appointment-group.service";
import {AppointmentTypeService} from "../../as/service/api/appointment-type.service";
import {AppointmentTypeScheduleTypeService} from "../../as/service/api/appointment-type-schedule-type.service";
import {ScheduleTypeService} from "../../as/service/api/schedule-type.service";
import {EuFrontEndTextService} from "../../eu/service/api/eu-front-end-text.service";
import {CustomerUserConfigurationService} from "../../cr/service/api/customer-user-configuration.service";
import {InsuranceConfigDocumentGroupService} from "../../cm/service/api/insurance-config-document-group.service";
import {CustomerInteractionTypeService} from "../../cr/service/api/customer-interaction-type.service";
import {CustomerInteractionTypeInfoStepService} from "../../cr/service/api/customer-interaction-type-info-step.service";
import {CustomerInfoPageService} from "../../cr/service/api/customer-info-page.service";
import {ReportService} from "../../rp/service/api/report.service";
import {ReportFieldService} from "../../rp/service/api/report-field.service";
import {ReportFilterService} from "../../rp/service/api/report-filter.service";
import {ReportGroupService} from "../../rp/service/api/report-group.service";
import {ReportObjectService} from "../../rp/service/api/report-object.service";
import {ReportObjectLinkService} from "../../rp/service/api/report-object-link.service";
import {ReportSortingService} from "../../rp/service/api/report-sorting.service";
import {UiWidgetService} from "../../ui/service/api/ui-widget.service";
import {UiWidgetLayoutService} from "../../ui/service/api/ui-widget-layout.service";
import {CustomerRelatedPersonTypeService} from "../../cr/service/api/customer-related-person-type.service";
import {CustomerRelatedPersonService} from "../../cr/service/api/customer-related-person.service";
import {EuRegistrationService} from "../../eu/service/api/eu-registration.service";
import {EuUserAddedInsuranceService} from "../../eu/service/api/eu-user-added-insurance.service";
import {UiMutableAreaService} from "../../ui/service/api/ui-mutable-area.service";
import {UiMutableAreaLayoutService} from "../../ui/service/api/ui-mutable-area-layout.service";
import {UiDashboardService} from "../../ui/service/api/ui-dashboard.service";
import {UserRoleService} from "../../um/service/api/user-role.service";
import {RoleService} from "../../um/service/api/role.service";
import {UserService} from "../../um/service/api/user.service";
import {DmDocumentService} from "../../dm/service/api/dm-document.service";
import {DmDocumentCommentService} from "../../dm/service/api/dm-document-comment.service";
import {DmDocumentStatusService} from "../../dm/service/api/dm-document-status.service";
import {DmFormRecognizerTypeService} from "../../dm/service/api/dm-form-recognizer-type.service";
import {TicketAttachmentService} from "../../tm/service/api/ticket-attachment.service";
import {TicketTagService} from "../../tm/service/api/ticket-tag.service";
import {TicketStatusService} from "../../tm/service/api/ticket-status.service";
import {TeTemplateService} from "../../te/service/api/te-template.service";
import {TeTemplateGroupService} from "../../te/service/api/te-template-group.service";
import {TeTemplateArtefactService} from "../../te/service/api/te-template-artefact.service";
import {TeArtefactService} from "../../te/service/api/te-artefact.service";
import {TeArtefactGroupService} from "../../te/service/api/te-artefact-group.service";
import {TeArtefactVariableService} from "../../te/service/api/te-artefact-variable.service";
import {TeTemplateArtefactVariableLinkService} from "../../te/service/api/te-template-artefact-variable-link.service";
import {TeTestCaseService} from "../../te/service/api/te-test-case.service";
import {TeTestCaseVariableService} from "../../te/service/api/te-test-case-variable.service";
import {TeTemplateTestCaseService} from "../../te/service/api/te-template-test-case.service";
import {TeArtefactTestCaseService} from "../../te/service/api/te-artefact-test-case.service";
import {TicketObjectService} from "../../tm/service/api/ticket-object.service";
import {DmDashboardService} from "../../dm/service/api/dm-dashboard.service";
import {CustomerCommentService} from "../../cr/service/api/customer-comment.service";
import {NsNotificationGroupService} from "../../ns/service/api/ns-notification-group.service";
import {NsNotificationTypeService} from "../../ns/service/api/ns-notification-type.service";
import {CustomerTagService} from "../../cr/service/api/customer-tag.service";
import {TeTemplateVariableService} from "../../te/service/api/te-template-variable.service";
import {WfProcessService} from "../../wf/service/api/wf-process.service";
import {EuThemeColorService} from "../../eu/service/api/eu-theme-color.service";
import {ImImportService} from "../../im/service/api/im-import.service";
import {ImImportRecordService} from "../../im/service/api/im-import-record.service";
import {InsuranceAgentService} from "../../cm/service/api/insurance-agent.service";
import {InsuranceDataService} from "../../cm/service/api/insurance-data.service";
import {InsuranceFormerInsuranceService} from "../../cm/service/api/insurance-former-insurance.service";
import {XxSimpleTestEntityService} from "../../xx/service/api/xx-simple-test-entity.service";
import {InsuranceTypeFieldService} from "../../cm/service/api/insurance-type-field.service";
import {UiPageUserService} from "../../ui/service/api/ui-page-user.service";
import {UiPageService} from "../../ui/service/api/ui-page.service";
import {UiPageUserFavoriteService} from "../../ui/service/api/ui-page-user-favorite.service";
import {CustomerAgentService} from "../../cr/service/api/customer-agent.service";
import {AssessmentGroupService} from "../../cm/service/api/assessment-group.service";
import {AssessmentGroupInsuranceTypeService} from "../../cm/service/api/assessment-group-insurance-type.service";
import {DmAssignmentTypeService} from "../../dm/service/api/dm-assignment-type.service";
import {DmAssignmentTypeObjectTypeService} from "../../dm/service/api/dm-assignment-type-object-type.service";
import {DmDocumentAssignmentService} from "../../dm/service/api/dm-document-assignment.service";
import {
    WfProcessTypeStepActivityFormDefaultService
} from "../../wf/service/api/wf-process-type-step-activity-form-default.service";
import {WfProcessStepService} from "../../wf/service/api/wf-process-step.service";
import {WfProcessTypeStepNextService} from "../../wf/service/api/wf-process-type-step-next.service";
import {ImSystemService} from "../../im/service/api/im-system.service";
import {ObjectTypeMetaJoinExtensionService} from "../../cc/service/api/object-type-meta-join-extension.service";
import {ContractOriginTypeService} from "../../cm/service/api/contract-origin-type.service";
import {ContractTerminationReasonTypeService} from "../../cm/service/api/contract-termination-reason-type.service";
import {ContractCategoryTypeService} from "../../cm/service/api/contract-category-type.service";
import {ContractTerminationTypeService} from "../../cm/service/api/contract-termination-type.service";

import {ContractPaymentService} from "../../cm/service/api/contract-payment.service";
import {ProductService} from "../../cm/service/api/product.service";
import {ContractStatusTypeEntryService} from "../../cm/service/api/contract-status-type-entry.service";
import {BrokerService} from "../../cm/service/api/broker.service";
import {TagTypeScopeService} from "../../cm/service/api/tag-type-scope.service";
import {PartnerGroupService} from "../../cm/service/api/partner-group.service";
import {ContractTagService} from "../../cm/service/api/contract-tag.service";
import {ContractStatusService} from "../../cm/service/api/contract-status.service";
import {ContractPriceService} from "../../cm/service/api/contract-price.service";
import {ContractDiscountService} from "../../cm/service/api/contract-discount.service";
import {ContractFormerService} from "../../cm/service/api/contract-former.service";
import {ContractDataService} from "../../cm/service/api/contract-data.service";
import {ProductDataService} from "../../cm/service/api/product-data.service";
import {ContractAgentService} from "../../cm/service/api/contract-agent.service";
import {ContractBundleService} from "../../cm/service/api/contract-bundle.service";
import {ContractDocumentService} from "../../cm/service/api/contract-document.service";
import {PartnerTypeService} from "../../cm/service/api/partner-type.service";
import {ProductDataFieldService} from "../../cm/service/api/product-data-field.service";
import {PartnerService} from "../../cm/service/api/partner.service";
import {TaxService} from "../../cm/service/api/tax.service";
import {ContractAcquisitionCommissionService} from "../../cm/service/api/contract-acquisition-commission.service";
import {CategoryService} from "../../cm/service/api/category.service";
import {ContractPartnerService} from "../../cm/service/api/contract-partner.service";
import {TagTypeService} from "../../cm/service/api/tag-type.service";
import {ContractDataFieldService} from "../../cm/service/api/contract-data-field.service";
import {ContractStatusTypeService} from "../../cm/service/api/contract-status-type.service";
import {ProductPartnerService} from "../../cm/service/api/product-partner.service";
import {PartnerCategoryService} from "../../cm/service/api/partner-category.service";
import {PriceRangeDiscountService} from "../../cm/service/api/price-range-discount.service";
import {AssessmentGroupContractTypeService} from "../../cm/service/api/assessment-group-contract-type.service";
import {ContractTypeFieldService} from "../../cm/service/api/contract-type-field.service";
import {ContractService} from "../../cm/service/api/contract.service";
import {ContractTypeService} from "../../cm/service/api/contract-type.service";
import {RatingTypeObjectTypeService} from "../../cm/service/api/rating-type-object-type.service";
import {GroupService} from "../../cm/service/api/group.service";
import {RatingTypeService} from "../../cm/service/api/rating-type.service";
import {PriceRangeService} from "../../cm/service/api/price-range.service";

import {DmDocumentAccessService} from "../../dm/service/api/dm-document-access.service";
import {DmDocumentFormDataService} from "../../dm/service/api/dm-document-form-data.service";
import {DmDocumentDataService} from "../../dm/service/api/dm-document-data.service";

import {WfReminderTypeService} from "../../wf/service/api/wf-reminder-type.service";
import {WfProcessTypeFieldService} from "../../wf/service/api/wf-process-type-field.service";
import {WfActivityTypeObjectService} from "../../wf/service/api/wf-activity-type-object.service";
import {WfProcessTypeStepService} from "../../wf/service/api/wf-process-type-step.service";
import {WfEventTypeService} from "../../wf/service/api/wf-event-type.service";
import {WfProcessGroupService} from "../../wf/service/api/wf-process-group.service";
import {WfProcessTypeStepReminderService} from "../../wf/service/api/wf-process-type-step-reminder.service";
import {WfProcessObjectService} from "../../wf/service/api/wf-process-object.service";
import {WfProcessStepActivityService} from "../../wf/service/api/wf-process-step-activity.service";
import {WfProcessFieldService} from "../../wf/service/api/wf-process-field.service";
import {WfProcessTypeStepEventService} from "../../wf/service/api/wf-process-type-step-event.service";
import {WfActivityTypeNotificationService} from "../../wf/service/api/wf-activity-type-notification.service";
import {WfActivityTypeStartProcessService} from "../../wf/service/api/wf-activity-type-start-process.service";
import {WfProcessTypeStepActivityContextService} from "../../wf/service/api/wf-process-type-step-activity-context.service";
import {WfProcessStepTypeService} from "../../wf/service/api/wf-process-step-type.service";
import {WfProcessTypeStepActivityService} from "../../wf/service/api/wf-process-type-step-activity.service";
import {WfActivityTypeService} from "../../wf/service/api/wf-activity-type.service";

import {CustomerInteractionDocumentService} from "../../cr/service/api/customer-interaction-document.service";
import {CustomerFormFieldService} from "../../cr/service/api/customer-form-field.service";
import {CustomerEmailService} from "../../cr/service/api/customer-email.service";
import {CustomerInteractionService} from "../../cr/service/api/customer-interaction.service";
import {CustomerUserService} from "../../cr/service/api/customer-user.service";
import {CustomerAutopilotService} from "../../cr/service/api/customer-autopilot.service";
import {CustomerEmergencyContactRelatedPersonService} from "../../cr/service/api/customer-emergency-contact-related-person.service";
import {CustomerChannelService} from "../../cr/service/api/customer-channel.service";
import {CustomerSuperContactRelatedPersonService} from "../../cr/service/api/customer-super-contact-related-person.service";
import {CustomerConsultingTypeService} from "../../cr/service/api/customer-consulting-type.service";
import {CustomerExternalAliasService} from "../../cr/service/api/customer-external-alias.service";
import {CustomerSignatureService} from "../../cr/service/api/customer-signature.service";
import {CustomerContractService} from "../../cr/service/api/customer-contract.service";
import {PartnerContractTypeService} from "../../cm/service/api/partner-contract-type.service";
import {ContractConfigDocumentGroupService} from "../../cm/service/api/contract-config-document-group.service";
import {PartnerAddressService} from "../../cm/service/api/partner-address.service";

import {InsurableObjectFieldService} from "../../ci/service/api/insurable-object-field.service";
import {ContractTypeInsurableObjectTypeService} from "../../ci/service/api/contract-type-insurable-object-type.service";
import {InsurableObjectTypeService} from "../../ci/service/api/insurable-object-type.service";
import {ContractPersonTypeService} from "../../ci/service/api/contract-person-type.service";
import {InsurableObjectTypeFieldService} from "../../ci/service/api/insurable-object-type-field.service";
import {ContractInsurableObjectService} from "../../ci/service/api/contract-insurable-object.service";
import {ContractPersonService} from "../../ci/service/api/contract-person.service";
import {InsurableObjectService} from "../../ci/service/api/insurable-object.service";


import {CommonStoredObjectRelationService} from "../../cc/service/api/common-stored-object-relation.service";
import {ObjectAttributeValueService} from "../../cc/service/api/object-attribute-value.service";
import {SystemConfigService} from "../../cc/service/api/system-config.service";
import {ActivityContextObjectService} from "../../cc/service/api/activity-context-object.service";
import {CommonStoredSortingService} from "../../cc/service/api/common-stored-sorting.service";
import {ActivityContextService} from "../../cc/service/api/activity-context.service";
import {CommonStoredGroupByService} from "../../cc/service/api/common-stored-group-by.service";
import {CommonStoredFilterService} from "../../cc/service/api/common-stored-filter.service";
import {ActivityContextRelationService} from "../../cc/service/api/activity-context-relation.service";

import {WfHintTypeService} from "../../wf/service/api/wf-hint-type.service";
import {WfProcessTypeStepHintService} from "../../wf/service/api/wf-process-type-step-hint.service";
import {CustomerCategoryTypeService} from "../../cr/service/api/customer-category-type.service";
import {CustomerBrokerPoaTypeService} from "../../cr/service/api/customer-broker-poa-type.service";
import {CustomerBrokerPoaTypeReasonService} from "../../cr/service/api/customer-broker-poa-type-reason.service";
import {UiNavigationMainService} from "../../ui/service/api/ui-navigation-main.service";
import {UiMutableAreaLayoutItemService} from "../../ui/service/api/ui-mutable-area-layout-item.service";
import {UiNavigationSubService} from "../../ui/service/api/ui-navigation-sub.service";
import {UiMutableAreaLayoutUserService} from "../../ui/service/api/ui-mutable-area-layout-user.service";
import {UiLayoutService} from "../../ui/service/api/ui-layout.service";
import {UiWidgetLayoutUserService} from "../../ui/service/api/ui-widget-layout-user.service";
import {UiNavigationSubPageService} from "../../ui/service/api/ui-navigation-sub-page.service";
import {UiTableLayoutService} from "../../ui/service/api/ui-table-layout.service";
import {NsNotificationEmailRecipientService} from "../../ns/service/api/ns-notification-email-recipient.service";
import {NsEmailConfigurationService} from "../../ns/service/api/ns-email-configuration.service";
import {NsNotificationRecipientService} from "../../ns/service/api/ns-notification-recipient.service";
import {NsSmsSentService} from "../../ns/service/api/ns-sms-sent.service";
import {NsSmsConfigurationService} from "../../ns/service/api/ns-sms-configuration.service";
import {NsRecipientBlackListService} from "../../ns/service/api/ns-recipient-black-list.service";
import {NsNotificationEmailService} from "../../ns/service/api/ns-notification-email.service";
import {NsRecipientWhiteListService} from "../../ns/service/api/ns-recipient-white-list.service";
import {NsNotificationChannelService} from "../../ns/service/api/ns-notification-channel.service";
import {NsNotificationContentService} from "../../ns/service/api/ns-notification-content.service";
import {NsNotificationService} from "../../ns/service/api/ns-notification.service";
import {NsNotificationChannelRecipientService} from "../../ns/service/api/ns-notification-channel-recipient.service";
import {AgentActiveObjectService} from "../../am/service/api/agent-active-object.service";

import {CallTypeService} from "../../pc/service/api/call-type.service";
import {CallService} from "../../pc/service/api/call.service";
import {CallRecordingService} from "../../pc/service/api/call-recording.service";
import {CallConfigurationService} from "../../pc/service/api/call-configuration.service";
import {CallProtocolService} from "../../pc/service/api/call-protocol.service";
import {NsNotificationTypeChannelTypeService} from "../../ns/service/api/ns-notification-type-channel-type.service";
import {CustomerNotificationTypeService} from "../../cr/service/api/customer-notification-type.service";

import {WfProcessTypeStepActivityTicketCreateService} from "../../wf/service/api/wf-process-type-step-activity-ticket-create.service";
import {WfProcessTypeStepActivityNotificationService} from "../../wf/service/api/wf-process-type-step-activity-notification.service";
import {WfProcessTypeStepActivityStartProcessService} from "../../wf/service/api/wf-process-type-step-activity-start-process.service";
import {WfProcessTypeStepActivityObjectService} from "../../wf/service/api/wf-process-type-step-activity-object.service";
import {WfProcessStepActivityObjectService} from "../../wf/service/api/wf-process-step-activity-object.service";
import {WfProcessTypeStepActivityBindingService} from "../../wf/service/api/wf-process-type-step-activity-binding.service";
import {WfProcessTypeResultService} from "../../wf/service/api/wf-process-type-result.service";
import {WfResultCategoryService} from "../../wf/service/api/wf-result-category.service";
import {WfProcessTypeStepActivityDocumentService} from "../../wf/service/api/wf-process-type-step-activity-document.service";
import {WfProcessTypeStepActivityChooseNextStepService} from "../../wf/service/api/wf-process-type-step-activity-choose-next-step.service";
import {WfProcessTypeStepActivityTicketStatusService} from "../../wf/service/api/wf-process-type-step-activity-ticket-status.service";
import {WfProcessGroupFieldService} from "../../wf/service/api/wf-process-group-field.service";

import {ContractOutstandingPaymentService} from "../../cm/service/api/contract-outstanding-payment.service";
import {IndexedEntryService} from "../../si/service/api/indexed-entry.service";
import {IndexRunService} from "../../si/service/api/index-run.service";
import {IndexedTokenService} from "../../si/service/api/indexed-token.service";
import {IndexRunObjectService} from "../../si/service/api/index-run-object.service";
import {IndexedReferenceService} from "../../si/service/api/indexed-reference.service";
import {IndexRunReferenceService} from "../../si/service/api/index-run-reference.service";
import {SearchResultObjectTypeService} from "../../si/service/api/search-result-object-type.service";
import {PriceReasonService} from "../../cm/service/api/price-reason.service";
import {ContractCommentService} from "../../cm/service/api/contract-comment.service";

import {ContractTypeNotificationTypeService} from "../../cm/service/api/contract-type-notification-type.service";
import {EuRoleService} from "../../eu/service/api/eu-role.service";
import {EuUserRelatedPersonImageService} from "../../eu/service/api/eu-user-related-person-image.service";
import {EuUserTestService} from "../../eu/service/api/eu-user-test.service";
import {EuOtpCodeService} from "../../eu/service/api/eu-otp-code.service";
import {EuFrontEndThemeColorDto} from "../../eu/service/api/eu-front-end-theme-color-dto.service";
import {EuStaticNotificationContractPersonTypeService} from "../../eu/service/api/eu-static-notification-contract-person-type.service";
import {EuUserRoleService} from "../../eu/service/api/eu-user-role.service";
import {EuUserService} from "../../eu/service/api/eu-user.service";
import {EuPasswordResetTokenService} from "../../eu/service/api/eu-password-reset-token.service";
import {EuTmConfigurationService} from "../../eu/service/api/eu-tm-configuration.service";
import {EuPromotionService} from "../../eu/service/api/eu-promotion.service";
import {EuUserObjectAccessService} from "../../eu/service/api/eu-user-object-access.service";
import {EuStaticNotificationService} from "../../eu/service/api/eu-static-notification.service";
import {EuRegistrationSignatureService} from "../../eu/service/api/eu-registration-signature.service";
import {EuUserAddedInsuranceDocumentService} from "../../eu/service/api/eu-user-added-insurance-document.service";
import {EuStaticNotificationContractTypeService} from "../../eu/service/api/eu-static-notification-contract-type.service";
import {EuUserInsurableObjectImageService} from "../../eu/service/api/eu-user-insurable-object-image.service";
import {EuUserDeviceService} from "../../eu/service/api/eu-user-device.service";
import {EuStaticNotificationInsurableObjectTypeService} from "../../eu/service/api/eu-static-notification-insurable-object-type.service";
import {PartnerLinkContactService} from "../../cm/service/api/partner-link-contact.service";
import {PartnerLinkService} from "../../cm/service/api/partner-link.service";
import {PartnerContactService} from "../../cm/service/api/partner-contact.service";

import {ContractNotificationTypeService} from "../../cm/service/api/contract-notification-type.service";
import {ContractNotificationTypeRestrictGroupService} from "../../cm/service/api/contract-notification-type-restrict-group.service";
import {ContractNotificationTypeRestrictContractTypeService} from "../../cm/service/api/contract-notification-type-restrict-contract-type.service";
import {NsNotificationVariableService} from "../../ns/service/api/ns-notification-variable.service";
import {NsNotificationTypeChannelTypeTeVariableService} from "../../ns/service/api/ns-notification-type-channel-type-te-variable.service";
import {NsNotificationTypeVariableService} from "../../ns/service/api/ns-notification-type-variable.service";
import {NsNotificationDataFieldService} from "../../ns/service/api/ns-notification-data-field.service";
import {NsNotificationTypeFieldService} from "../../ns/service/api/ns-notification-type-field.service";
import {NsNotificationDataService} from "../../ns/service/api/ns-notification-data.service";

import {PersonContactService} from "../../pm/service/api/person-contact.service";
import {PersonAddressService} from "../../pm/service/api/person-address.service";
import {IndexedFieldService} from "../../si/service/api/indexed-field.service";
import {SearchObjectTypeAttributeService} from "../../si/service/api/search-object-type-attribute.service";

import {TeSystemVariablePredefinedService} from "../../te/service/api/te-system-variable-predefined.service";
import {NsNotificationTypeChannelTypeContactTypeService} from "../../ns/service/api/ns-notification-type-channel-type-contact-type.service";
import {NsNotificationTypeChannelTypeDocumentService} from "../../ns/service/api/ns-notification-type-channel-type-document.service";
import {IgPassTokenUsedService} from "../../ig/service/api/ig-pass-token-used.service";
import {IgAccessTokenService} from "../../ig/service/api/ig-access-token.service";
import {IgAccessTokenUsedService} from "../../ig/service/api/ig-access-token-used.service";
import {IgPassTokenService} from "../../ig/service/api/ig-pass-token.service";
import {EuNotificationTypeService} from "../../eu/service/api/eu-notification-type.service";
import {MsBookingService} from "../../as/service/api/ms-booking.service";
import {AppointmentParticipantAgentService} from "../../as/service/api/appointment-participant-agent.service";
import {AppointmentService} from "../../as/service/api/appointment.service";
import {AppointmentParticipantCustomerService} from "../../as/service/api/appointment-participant-customer.service";
import {MsBookingTypeService} from "../../as/service/api/ms-booking-type.service";
import {CalendarConfigurationService} from "../../am/service/api/calendar-configuration.service";
import {EuUserDocumentTypeService} from "../../eu/service/api/eu-user-document-type.service";
import {AgentNotificationTypeService} from "../../am/service/api/agent-notification-type.service";
import {AgentReferralService} from "../../cs/service/api/agent-referral.service";
import {CustomerLevelOptionService} from "../../cs/service/api/customer-level-option.service";
import {CustomerReferralService} from "../../cs/service/api/customer-referral.service";
import {CustomerReferralRedeemService} from "../../cs/service/api/customer-referral-redeem.service";
import {ReferralAccessService} from "../../cs/service/api/referral-access.service";
import {ReferralTypeService} from "../../cs/service/api/referral-type.service";
import {ReferralTypeLevelService} from "../../cs/service/api/referral-type-level.service";
import {ReferralTypeLevelOptionService} from "../../cs/service/api/referral-type-level-option.service";
import {XhAnonymizationDataService} from "../../xh/service/api/xh-anonymization-data.service";
import {MvsCrudObjectInterfaceComponent, MvsCrudService} from "@kvers/alpha-core-common";
import {RoleAuthConfigService} from "../../um/service/api/role-auth-config.service";
import {RoleAuthModuleService} from "../../um/service/api/role-auth-module.service";
import {ContractPriceEntryTypeFieldService} from "../../cm/service/api/contract-price-entry-type-field.service";
import {ContractBaseStatusService} from "../../cm/service/api/contract-base-status.service";
import {ContractBaseCategoryService} from "../../cm/service/api/contract-base-category.service";
import {ContractClosureChannelTypeService} from "../../cm/service/api/contract-closure-channel-type.service";
import {ContractBaseTypeReasonService} from "../../cm/service/api/contract-base-type-reason.service";
import {RoleAuthContractService} from "../../cm/service/api/role-auth-contract.service";
import {ContractLabelService} from "../../cm/service/api/contract-label.service";
import {RoleAuthCustomerTypeService} from "../../cr/service/api/role-auth-customer-type.service";
import {BusinessCustomerService} from "../../cr/service/api/business-customer.service";
import {CustomerBankAccountService} from "../../cr/service/api/customer-bank-account.service";
import {RoleAuthCustomerRegionService} from "../../cr/service/api/role-auth-customer-region.service";
import {PersonRelationTypeService} from "../../pm/service/api/person-relation-type.service";
import {HouseholdService} from "../../pm/service/api/household.service";
import {PersonRelationPersonService} from "../../pm/service/api/person-relation-person.service";
import {RoleAuthCustomerService} from "../../cr/service/api/role-auth-customer.service";
import {ContractPriceEntryTypeService} from "../../cm/service/api/contract-price-entry-type.service";
import {ContractBaseTypeService} from "../../cm/service/api/contract-base-type.service";
import {HouseholdPersonService} from "../../pm/service/api/household-person.service";
import {TicketAppointmentService} from "../../tm/service/api/ticket-appointment.service";
import {ContractTypeAmountTypeService} from "../../cm/service/api/contract-type-amount-type.service";
import {GroupAmountTypeService} from "../../cm/service/api/group-amount-type.service";
import {ContractBaseTypeTicketTypeService} from "../../cm/service/api/contract-base-type-ticket-type.service";
import {TicketFieldService} from "../../tm/service/api/ticket-field.service";
import {TicketTypeNotificationTypeService} from "../../tm/service/api/ticket-type-notification-type.service";
import {TicketCommentEuService} from "../../tm/service/api/ticket-comment-eu.service";
import {TicketGroupObjectTypeService} from "../../tm/service/api/ticket-group-object-type.service";
import {TicketTypeObjectRoleService} from "../../tm/service/api/ticket-type-object-role.service";
import {CustomerEmergencyContactService} from "../../cr/service/api/customer-emergency-contact.service";
import {TicketTypeCompleteStatusService} from "../../tm/service/api/ticket-type-complete-status.service";
import {TicketTypeActionProcessMappingService} from "../../tm/service/api/ticket-type-action-process-mapping.service";
import {TicketTypeActionTicketMappingService} from "../../tm/service/api/ticket-type-action-ticket-mapping.service";
import {TicketActionCommentService} from "../../tm/service/api/ticket-action-comment.service";
import {UmDocumentConfigurationService} from "../../um/service/api/um-document-configuration.service";
import {UserImageService} from "../../um/service/api/user-image.service";
import {RoleAuthObjectTypeService} from "../../um/service/api/role-auth-object-type.service";
import {UmUserService} from "../../um/service/api/um-user.service";
import { ActivityTestService } from "src/app/test/services/activity-test.service";
import {FieldValueService} from "../../cc/service/api/field-value.service";
import {ObjectActionGuidService} from "../../cc/service/api/object-action-guid.service";
import {FieldValueBigDecimalService} from "../../cc/service/api/field-value-big-decimal.service";
import {FieldValueDateService} from "../../cc/service/api/field-value-date.service";
import {FieldValueStringService} from "../../cc/service/api/field-value-string.service";
import {ApplicationService} from "../../cc/service/api/application.service";
import {FieldValueIntegerService} from "../../cc/service/api/field-value-integer.service";
import {FieldValueDoubleService} from "../../cc/service/api/field-value-double.service";
import {FieldValueObjectService} from "../../cc/service/api/field-value-object.service";
import {ApplicationLogService} from "../../cc/service/api/application-log.service";
import {FieldValueDateTimeService} from "../../cc/service/api/field-value-date-time.service";
import {ObjectGuidService} from "../../cc/service/api/object-guid.service";
import {FieldValueFloatService} from "../../cc/service/api/field-value-float.service";
import {ObjectUserAccessService} from "../../cc/service/api/object-user-access.service";
import {FieldValueLongService} from "../../cc/service/api/field-value-long.service";
import {FieldValueTimeService} from "../../cc/service/api/field-value-time.service";
import {NsMicrosoftTeamsConfigurationService} from "../../ns/service/api/ns-microsoft-teams-configuration.service";
import {NsMicrosoftTeamsSentService} from "../../ns/service/api/ns-microsoft-teams-sent.service";
import {UserNotificationTypeService} from "../../um/service/api/user-notification-type.service";
import {
    TicketGroupFilterRestrictionAgentPoolService
} from "../../tm/service/api/ticket-group-filter-restriction-agent-pool.service";
import {
    TicketGroupFilterSelectionTicketGroupService
} from "../../tm/service/api/ticket-group-filter-selection-ticket-group.service";
import {
    TicketGroupFilterRestrictionAgentService
} from "../../tm/service/api/ticket-group-filter-restriction-agent.service";
import {TicketGroupFilterService} from "../../tm/service/api/ticket-group-filter.service";
import {TicketGroupFilterSelectionAgentService} from "../../tm/service/api/ticket-group-filter-selection-agent.service";
import {
    TicketGroupFilterSelectionAgentPoolService
} from "../../tm/service/api/ticket-group-filter-selection-agent-pool.service";
import {
    TicketGroupFilterSelectionTicketTypeService
} from "../../tm/service/api/ticket-group-filter-selection-ticket-type.service";
import {LogicImportService} from "../../lg/service/api/logic-import.service";
import {LogicGroupService} from "../../lg/service/api/logic-group.service";
import {LogicService} from "../../lg/service/api/logic.service";
import {LogicExportService} from "../../lg/service/api/logic-export.service";
import {
    TicketGroupFilterSelectionUrgencyService
} from "../../tm/service/api/ticket-group-filter-selection-urgency.service";
import {
    TicketTypeNotificationTypeMappingService
} from "../../tm/service/api/ticket-type-notification-type-mapping.service";
import {
    TicketGroupFilterSelectionStatusService
} from "../../tm/service/api/ticket-group-filter-selection-status.service";
import {ProfileCheckTypeService} from "../../cr/service/api/profile-check-type.service";
import {AssessmentGroupCheckService} from "../../cr/service/api/assessment-group-check.service";
import {CrAssessmentGroupService} from "../../cr/service/api/assessment-group.service";
import {EmploymentStatusService} from "../../pm/service/api/employment-status.service";
import {PersonRelationTypeReverseService} from "../../pm/service/api/person-relation-type-reverse.service";
import {ProfileCheckCompletenessRangeService} from "../../cr/service/api/profile-check-completeness-range.service";
import {AssessmentOverwriteOptionService} from "../../cr/service/api/assessment-overwrite-option.service";
import {AssessmentResultRangeService} from "../../cr/service/api/assessment-result-range.service";
import {AssessmentOverwriteService} from "../../cr/service/api/assessment-overwrite.service";
import {ContractOneTimePaymentService} from "../../cm/service/api/contract-one-time-payment.service";
import {ContractOneTimePaymentReasonService} from "../../cm/service/api/contract-one-time-payment-reason.service";
import {JbJobRunService} from "../../jb/service/api/jb-job-run.service";
import {JbJobRunLogEntryService} from "../../jb/service/api/jb-job-run-log-entry.service";
import {JbJobTypeService} from "../../jb/service/api/jb-job-type.service";
import {EuDefaultRoleService} from "../../eu/service/api/eu-default-role.service";
import {AppointmentResultTypeService} from "../../as/service/api/appointment-result-type.service";
import {
    IntegrationAlphaBatchEntityStepActivityRequestService
} from "../../ha/service/api/integration-alpha-batch-entity-step-activity-request.service";
import {IntegrationAlphaAuthService} from "../../ha/service/api/integration-alpha-auth.service";
import {IntegrationAlphaBatchEntityStepService} from "../../ha/service/api/integration-alpha-batch-entity-step.service";
import {IntegrationAlphaBatchThreadingService} from "../../ha/service/api/integration-alpha-batch-threading.service";
import {IntegrationAlphaSystemService} from "../../ha/service/api/integration-alpha-system.service";
import {IntegrationAlphaBatchEntityService} from "../../ha/service/api/integration-alpha-batch-entity.service";
import {
    IntegrationAlphaBatchEntityStepActivityService
} from "../../ha/service/api/integration-alpha-batch-entity-step-activity.service";
import {IntegrationBiproAuthTypeService} from "../../hb/service/api/integration-bipro-auth-type.service";
import {IntegrationSystemBiproAuthService} from "../../hb/service/api/integration-system-bipro-auth.service";
import {IntegrationSystemBiproShipmentService} from "../../hb/service/api/integration-system-bipro-shipment.service";
import {IntegrationBiproTemplateService} from "../../hb/service/api/integration-bipro-template.service";
import {IntegrationBiproVersionService} from "../../hb/service/api/integration-bipro-version.service";
import {IntegrationTypeStepOutputService} from "../../hc/service/api/integration-type-step-output.service";
import {IntegrationSystemTypeService} from "../../hc/service/api/integration-system-type.service";
import {IntegrationStepService} from "../../hc/service/api/integration-step.service";
import {IntegrationTypeStepService} from "../../hc/service/api/integration-type-step.service";
import {IntegrationTypeService} from "../../hc/service/api/integration-type.service";
import {IntegrationAdapterTypeService} from "../../hc/service/api/integration-adapter-type.service";
import {IntegrationTypeVariableService} from "../../hc/service/api/integration-type-variable.service";
import {IntegrationTransformationTypeService} from "../../hc/service/api/integration-transformation-type.service";
import {IntegrationService} from "../../hc/service/api/integration.service";
import {IntegrationTypeStepTransitionService} from "../../hc/service/api/integration-type-step-transition.service";
import {IntegrationTypeStepInputService} from "../../hc/service/api/integration-type-step-input.service";
import {IntegrationStepAdapterService} from "../../hc/service/api/integration-step-adapter.service";
import {IntegrationSftpConnectionService} from "../../he/service/api/integration-sftp-connection.service";
import {IntegrationExcodaConfigService} from "../../ye/service/api/integration-excoda-config.service";
import {
    IntegrationExcodaConfigFileProcessorService
} from "../../ye/service/api/integration-excoda-config-file-processor.service";
import {DmDocumentRelatedDataService} from "../../dm/service/api/dm-document-related-data.service";
import {DmDocumentAssignmentEuService} from "../../dm/service/api/dm-document-assignment-eu.service";
import {DmDataTypeService} from "../../dm/service/api/dm-data-type.service";
import {DmDataGroupService} from "../../dm/service/api/dm-data-group.service";
import {DmDataService} from "../../dm/service/api/dm-data.service";
import {LogicTestCaseImportVariableService} from "../../lg/service/api/logic-test-case-import-variable.service";
import {LogicTestCaseService} from "../../lg/service/api/logic-test-case.service";
import {ReportAgentFavoriteService} from "../../rp/service/api/report-agent-favorite.service";
import {ReportRuntimeService} from "../../rp/service/api/report-runtime.service";
import {PaymentCollectionViaService} from "../../bm/service/api/payment-collection-via.service";
import {CustomerDunningLevelService} from "../../bm/service/api/customer-dunning-level.service";
import {ContractDunningLevelService} from "../../bm/service/api/contract-dunning-level.service";
import {DunningLevelService} from "../../bm/service/api/dunning-level.service";
import {CalculationLogicTypeStepService} from "../../lg/service/api/calculation-logic-type-step.service";
import {CalculationLogicTypeService} from "../../lg/service/api/calculation-logic-type.service";
import {CalculationLogicTypeFieldService} from "../../lg/service/api/calculation-logic-type-field.service";
import {CalculationLogicTypeStepMappingService} from "../../lg/service/api/calculation-logic-type-step-mapping.service";
import {CalculationLogicService} from "../../lg/service/api/calculation-logic.service";
import {CalculationLogicFieldService} from "../../lg/service/api/calculation-logic-field.service";
import {CalculationLogicTypeCommentService} from "../../lg/service/api/calculation-logic-type-comment.service";
import {BiproServiceTypeService} from "../../hb/service/api/bipro-service-type.service";
import {BiproServiceService} from "../../hb/service/api/bipro-service.service";
import {BiproVersionService} from "../../hb/service/api/bipro-version.service";
import {BiproPartnerAuthenticationService} from "../../hb/service/api/bipro-partner-authentication.service";
import {BiproPartnerService} from "../../hb/service/api/bipro-partner.service";
import {EmSchemaFieldService} from "../../em/service/api/em-schema-field.service";
import {EmSchemaFieldCommentService} from "../../em/service/api/em-schema-field-comment.service";
import {EmSchemaStructureService} from "../../em/service/api/em-schema-structure.service";
import {EmSchemaTypeService} from "../../em/service/api/em-schema-type.service";
import {EmSchemaFieldValueService} from "../../em/service/api/em-schema-field-value.service";
import {EmSchemaService} from "../../em/service/api/em-schema.service";
import {EmSchemaFieldValueCommentService} from "../../em/service/api/em-schema-field-value-comment.service";
import {ProvisionPeriodService} from "../../cp/service/api/provision-period.service";
import {ProvisionAgentService} from "../../cp/service/api/provision-agent.service";
import {ProvisionAgentGroupService} from "../../cp/service/api/provision-agent-group.service";
import {ProvisionAgentCommentService} from "../../cp/service/api/provision-agent-comment.service";
import {ProvisionAgentPoolService} from "../../cp/service/api/provision-agent-pool.service";
import {ProvisionAgentPoolGroupService} from "../../cp/service/api/provision-agent-pool-group.service";
import {ProvisionAgentPoolAgentService} from "../../cp/service/api/provision-agent-pool-agent.service";
import {
    ProvisionContractOneTimePaymentEligibilityService
} from "../../cp/service/api/provision-contract-one-time-payment-eligibility.service";
import {
    ProvisionContractPriceEligibilityService
} from "../../cp/service/api/provision-contract-price-eligibility.service";
import {
    ProvisionContractEligibilityCommentService
} from "../../cp/service/api/provision-contract-eligibility-comment.service";
import {
    ProvisionContractEligibilityTypeService
} from "../../cp/service/api/provision-contract-eligibility-type.service";
import {
    ProvisionContractChangeEligibilityTypeService
} from "../../cp/service/api/provision-contract-change-eligibility-type.service";
import {ProvisionContractEligibilityService} from "../../cp/service/api/provision-contract-eligibility.service";
import {ProvisionCategoryTypeService} from "../../cp/service/api/provision-category-type.service";
import {ProvisionGroupTypeService} from "../../cp/service/api/provision-group-type.service";
import {ProvisionCategoryTypeAssignmentService} from "../../cp/service/api/provision-category-type-assignment.service";
import {ProvisionGroupTypeAssignmentService} from "../../cp/service/api/provision-group-type-assignment.service";
import {ProvisionLiabilityTemplateService} from "../../cp/service/api/provision-liability-template.service";
import {ProvisionCurrencyExchangeService} from "../../cp/service/api/provision-currency-exchange.service";
import {
    ProvisionCancellationAccountTemplateService
} from "../../cp/service/api/provision-cancellation-account-template.service";
import {ProvisionCurrencyService} from "../../cp/service/api/provision-currency.service";
import {ProvisionCurrencyMonetaryValueService} from "../../cp/service/api/provision-currency-monetary-value.service";
import {ProvisionTypeAgentPaymentService} from "../../cp/service/api/provision-type-agent-payment.service";
import {
    ProvisionTypeContractAgentProvisionService
} from "../../cp/service/api/provision-type-contract-agent-provision.service";
import {ProvisionTypeAgentPaymentAgentService} from "../../cp/service/api/provision-type-agent-payment-agent.service";
import {ProvisionTemplateTypeService} from "../../cp/service/api/provision-template-type.service";
import {ProvisionTemplateTypeFieldService} from "../../cp/service/api/provision-template-type-field.service";
import {ProvisionTemplateTypeMappingService} from "../../cp/service/api/provision-template-type-mapping.service";
import {
    ProvisionTypeAgentPaymentAgentPoolService
} from "../../cp/service/api/provision-type-agent-payment-agent-pool.service";
import {
    ProvisionTypeAgentPaymentCategoryService
} from "../../cp/service/api/provision-type-agent-payment-category.service";
import {
    ProvisionTypeAgentPaymentContractAssignmentService
} from "../../cp/service/api/provision-type-agent-payment-contract-assignment.service";
import {ProvisionTypePartnerPaymentService} from "../../cp/service/api/provision-type-partner-payment.service";
import {
    ProvisionTypeAgentPaymentPartnerPaymentService
} from "../../cp/service/api/provision-type-agent-payment-partner-payment.service";
import {
    ProvisionTypeAgentPaymentProvisionAgentPoolService
} from "../../cp/service/api/provision-type-agent-payment-provision-agent-pool.service";
import {
    ProvisionTypePartnerPaymentCategoryService
} from "../../cp/service/api/provision-type-partner-payment-category.service";
import {
    ProvisionTypePartnerPaymentCommentService
} from "../../cp/service/api/provision-type-partner-payment-comment.service";
import {ProvisionTypeService} from "../../cp/service/api/provision-type.service";
import {CalculationLogicCommentService} from "../../lg/service/api/calculation-logic-comment.service";
import {CalculationLogicTestCaseService} from "../../lg/service/api/calculation-logic-test-case.service";
import {
    CalculationLogicTestCaseImportVariableService
} from "../../lg/service/api/calculation-logic-test-case-import-variable.service";
import {LogicCommentService} from "../../lg/service/api/logic-comment.service";
import {BiproFieldTypeService} from "../../hb/service/api/bipro-field-type.service";
import {MsCalendarTypeService} from "../../as/service/api/ms-calendar-type.service";
import {IntegrationAzureGraphApiService} from "../../hm/service/api/integration-azure-graph-api.service";
import {
    IntegrationAzureContainerConnectionService
} from "../../hm/service/api/integration-azure-container-connection.service";


//TODO: IMPL other readyToGo ObjectTypes and Impl Services + clean up
export const projectDtoServiceMap = new Map< string, {route: string, objectRoute?:string, service: typeof MvsCrudService, crudSuperComponent?: typeof MvsCrudObjectInterfaceComponent}>([
    ['ac.AcTemplateAcquisitionCommission', {route: '/ac/templateAcquisitionCommissions',service: TemplateAcquisitionCommissionService}],
    ['ac.AcAgentCancellationBufferAssignment', {route: '/ac/agentCancellationBufferAssignments',service: AgentCancellationBufferAssignmentService}],
    ['ac.AcAcquisitionCommissionAssignment', {route: '/ac/acquisitionCommissionAssignments',service: AcquisitionCommissionAssignmentService}],

    ['ad.Country', {route: '/ad/countries',service: CountryService}],
    ['ad.Address', {route: '/ad/addresses',service: AddressService}],
    ['ad.AddressType', {route: '/ad/addressTypes',service: AddressTypeService}],
    ['ad.AddressTypeObjectType', {route: '/ad/addressTypesObjectTypes',service: AddressTypeObjectTypeService}],

    ['as.AppointmentGroup', {route: '/as/appointmentGroups',service: AppointmentGroupService}],
    ['as.AppointmentResultType', {route: 'about:blank',service: AppointmentResultTypeService}],
    ['as.AppointmentType', {route: '/as/appointmentTypes',service: AppointmentTypeService}],
    ['as.AppointmentTypeScheduleType', {route: '/as/appointmentTypeScheduleTypes',service: AppointmentTypeScheduleTypeService}],
    ['as.ScheduleType', {route: '/as/scheduleTypes',service: ScheduleTypeService}],
    ['as.MsCalendarType', {route: 'about:blank',service: MsCalendarTypeService}],
    ['as.MsBooking', {route: 'about:blank',service: MsBookingService}],
    ['as.AppointmentParticipantAgent', {route: 'about:blank',service: AppointmentParticipantAgentService}],
    ['as.Appointment', {route: 'about:blank',service: AppointmentService}],
    ['as.AppointmentParticipantCustomer', {route: 'about:blank',service: AppointmentParticipantCustomerService}],
    ['as.MsBookingType', {route: 'about:blank',service: MsBookingTypeService}],

    ['am.Agent', {route: '/am/agents',service: AgentService}],
    ['am.AgentActiveObject', {route: 'about:blank',service: AgentActiveObjectService}],
    ['am.AgentContact', {route: '/am/agentContacts',service: AgentContactService}],
    ['am.AgentPool', {route: '/am/agentPools',service: AgentPoolService}],
    ['am.AgentPoolAgent', {route: '/am/agentPoolAgents',service: AgentPoolAgentService}],
    ['am.AgentPoolContact', {route: '/am/agentPoolContacts',service: AgentPoolContactService}],
    ['am.CalendarConfiguration', {route: 'about:blank',service: CalendarConfigurationService}],
    ['am.AgentNotificationType', {route: 'about:blank',service: AgentNotificationTypeService}],

    ['bm.PaymentCollectionVia', {route: 'about:blank',service: PaymentCollectionViaService}],
    ['bm.CustomerDunningLevel', {route: 'about:blank',service: CustomerDunningLevelService}],
    ['bm.ContractDunningLevel', {route: 'about:blank',service: ContractDunningLevelService}],
    ['bm.DunningLevel', {route: 'about:blank',service: DunningLevelService}],

    ['cc.Field', {route: '/cc/fields',service: FieldService}],
    ['cc.ObjectTypeMetaJoinExtension', {route: '/cc/fields',service: ObjectTypeMetaJoinExtensionService}],
    ['cc.FieldNamespace', {route: '/cc/fieldNamespaces',service: FieldNamespaceService}],
    ['cc.FieldType', {route: '/cc/fieldTypes',service: FieldTypeService}],
    ['cc.FieldValueList', {route: '/cc/fieldsValueLists',service: FieldValueListService}],
    ['cc.FieldValueListEntry', {route: '/cc/fieldValueListEntries',service: FieldValueListEntryService}],
    ['cc.ObjectType', {route: '/cc/objectTypes',service: ObjectTypeService}],
    ['cc.ObjectTypeAttribute', {route: '/cc/objectTypeAttributes',service: ObjectTypeAttributeService}],

    ['cc.CommonStoredObjectRelation', {route: 'about:blank',service: CommonStoredObjectRelationService}],
    ['cc.ObjectAttributeValue', {route: 'about:blank',service: ObjectAttributeValueService}],
    ['cc.SystemConfig', {route: 'about:blank',service: SystemConfigService}],
    ['cc.ActivityContextObject', {route: 'about:blank',service: ActivityContextObjectService}],
    ['cc.CommonStoredSorting', {route: 'about:blank',service: CommonStoredSortingService}],
    ['cc.ActivityContext', {route: 'about:blank',service: ActivityContextService}],
    ['cc.CommonStoredGroupBy', {route: 'about:blank',service: CommonStoredGroupByService}],
    ['cc.CommonStoredFilter', {route: 'about:blank',service: CommonStoredFilterService}],
    ['cc.ActivityContextRelation', {route: 'about:blank',service: ActivityContextRelationService}],
    ['cc.FieldValue', {route: 'about:blank',service: FieldValueService}],
    ['cc.ObjectActionGuid', {route: 'about:blank',service: ObjectActionGuidService}],
    ['cc.FieldValueBigDecimal', {route: 'about:blank',service: FieldValueBigDecimalService}],
    ['cc.FieldValueDate', {route: 'about:blank',service: FieldValueDateService}],
    ['cc.FieldValueString', {route: 'about:blank',service: FieldValueStringService}],
    ['cc.Application', {route: 'about:blank',service: ApplicationService}],
    ['cc.FieldValueInteger', {route: 'about:blank',service: FieldValueIntegerService}],
    ['cc.FieldValueDouble', {route: 'about:blank',service: FieldValueDoubleService}],
    ['cc.FieldValueObject', {route: 'about:blank',service: FieldValueObjectService}],
    ['cc.ApplicationLog', {route: 'about:blank',service: ApplicationLogService}],
    ['cc.FieldValueDateTime', {route: 'about:blank',service: FieldValueDateTimeService}],
    ['cc.ObjectGuid', {route: 'about:blank',service: ObjectGuidService}],
    ['cc.FieldValueFloat', {route: 'about:blank',service: FieldValueFloatService}],
    ['cc.ObjectUserAccess', {route: 'about:blank',service: ObjectUserAccessService}],
    ['cc.FieldValueLong', {route: 'about:blank',service: FieldValueLongService}],
    ['cc.FieldValueTime', {route: 'about:blank',service: FieldValueTimeService}],

    ['cd.Contact', {route: '/cd/contacts',service: ContactService}],
    ['cd.ContactType', {route: '/cd/contactTypes',service: ContactTypeService}],
    ['cd.ContactTypeField', {route: '/cd/contactTypeFields',service: ContactTypeFieldService}],
    ['cd.ContactTypeObjectType', {route: '/cd/contactTypeObjectTypes',service: ContactTypeObjectTypeService}],

    ['cf.CfFrequency', {route: '/cf/frequencies',service: CfFrequencyService}],
    ['cf.CfPayFrequency', {route: '/cf/payFrequencies',service: CfPayFrequencyService}],
    ['cf.CfPaymentMoment', {route: '/cf/paymentMoments',service: CfPaymentMomentService}],
    ['cf.CfPaymentType', {route: '/cf/paymentTypes',service: CfPaymentTypeService}],
    ['cf.CfRoundingRule', {route: '/cf/roundingRules',service: CfRoundingRuleService}],
    ['cf.CfTemplateCalculationBase', {route: '/cf/templateCalculationBases',service: CfTemplateCalculationBaseService}],
    ['cf.CfTemplateCancellationBuffer', {route: '/cf/templateCancellationBuffers',service: CfTemplateCancellationBufferService}],
    ['cf.CfTemplateLiability', {route: '/cf/templateLiabilities',service: CfTemplateLiabilityService}],

    ['ci.InsurableObjectField', {route: 'about:blank',service: InsurableObjectFieldService}],
    ['ci.ContractTypeInsurableObjectType', {route: 'about:blank',service: ContractTypeInsurableObjectTypeService}],
    ['ci.InsurableObjectType', {route: 'about:blank',service: InsurableObjectTypeService}],
    ['ci.ContractPersonType', {route: 'about:blank',service: ContractPersonTypeService}],
    ['ci.InsurableObjectTypeField', {route: 'about:blank',service: InsurableObjectTypeFieldService}],
    ['ci.ContractInsurableObject', {route: 'about:blank',service: ContractInsurableObjectService}],
    ['ci.ContractPerson', {route: 'about:blank',service: ContractPersonService}],
    ['ci.InsurableObject', {route: 'about:blank',service: InsurableObjectService}],

    ['ci.ContractPerson', {route: '/ci/contractPersons',service: ContractPersonService}],

    ['cm.GroupAmountType', {route: '/cm/groupAmountTypes',service: GroupAmountTypeService}],

    ['cm.Division', {route: '/cm/divisions',service: DivisionService}],
    ['cm.InsurableObject', {route: '/cm/insurableObjects',service: InsurableObjectService}],
    ['cm.InsurableObjectType', {route: '/cm/insurableObjectTypes',service: InsurableObjectTypeService}],
    ['cm.InsurableObjectTypeField', {route: '/cm/insurableObjectTypeFields',service: InsurableObjectTypeFieldService}],
    ['cm.PriceReason', {route: 'about:blank',service: PriceReasonService}],
    ['cm.ContractComment', {route: 'about:blank',service: ContractCommentService}],

    ['cm.ContractOutstandingPayment', {route: 'about:blank',service: ContractOutstandingPaymentService}],

    ['cm.PartnerLinkContact', {route: 'about:blank',service: PartnerLinkContactService}],
    ['cm.PartnerLink', {route: 'about:blank',service: PartnerLinkService}],
    ['cm.PartnerContact', {route: 'about:blank',service: PartnerContactService}],


    ['cm.AssessmentGroup', {route: 'about:blank',service: AssessmentGroupService}],
    ['cm.AssessmentGroupInsuranceType', {route: 'about:blank',service: AssessmentGroupInsuranceTypeService}],


    ['cm.Insurance', {route: '/cm/insurances',service: InsuranceService}],
    ['cm.InsuranceAgent', {route: '/cm/insuranceAgents',service: InsuranceAgentService}],
    ['cm.InsuranceData', {route: '/cm/insuranceDatas',service: InsuranceDataService}],
    ['cm.InsuranceFormerInsurance', {route: '/cm/insuranceFormerInsurances',service: InsuranceFormerInsuranceService}],

    ['cm.insuranceAcquisitionCommission', {route: '/cm/insuranceAcquisitionCommissions',service: InsuranceAcquisitionCommissionService}],
    ['cm.InsuranceCompany', {route: '/cm/insuranceCompanies',service: InsuranceCompanyService}],
    ['cm.InsuranceCompanyDivision', {route: '/cm/insuranceCompanyDivisions',service: InsuranceCompanyDivisionService}],
    ['cm.InsuranceCompanyGroup', {route: '/cm/insuranceCompanyGroups',service: InsuranceCompanyGroupService}],
    ['cm.InsuranceDiscount', {route: '/cm/insuranceDiscounts',service: InsuranceDiscountService}],
    ['cm.InsurancePayment', {route: '/cm/insurancePayments',service: InsurancePaymentService}],
    ['cm.InsurancePrice', {route: '/cm/insurancePrices',service: InsurancePriceService}],
    ['cm.InsurancePriceDiscount', {route: '/cm/insurancePriceDiscounts',service: InsurancePriceDiscountService}],
    ['cm.InsurancePriceRange', {route: '/cm/insurancePriceRanges',service: InsurancePriceRangeService}],
    ['cm.InsuranceStatus', {route: '/cm/insuranceStatus',service: InsuranceStatusService}],
    ['cm.InsuranceStatusType', {route: '/cm/insuranceStatusTypes',service: InsuranceStatusTypeService}],
    ['cm.InsuranceStatusTypeEntry', {route: '/cm/insuranceStatusTypeEntries',service: InsuranceStatusTypeEntryService}],
    ['cm.InsuranceTariff', {route: '/cm/insuranceTariffs',service: InsuranceTariffService}],
    ['cm.InsuranceTax', {route: '/cm/insuranceTaxes',service: InsuranceTaxService}],
    ['cm.InsuranceType', {route: '/cm/insuranceTypes',service: InsuranceTypeService}],
    ['cm.InsuranceTypeField', {route: '/cm/insuranceTypeFields',service: InsuranceTypeFieldService}],
    ['cm.InsuranceTypeInsurableObjectType', {route: '/cm/insuranceTypeInsurableObjectTypes',service: InsuranceTypeInsurableObjectTypeService}],
    ['cm.InsuranceConfigDocumentGroup', {route: '/cm/insuranceConfigDocumentGroups',service: InsuranceConfigDocumentGroupService}],

    ['cm.Rating', {route: '/cm/ratings',service: RatingService}],

    ['cm.ContractPayment', {route: 'about:blank',service: ContractPaymentService}],
    ['cm.Product', {route: 'about:blank',service: ProductService}],
    ['cm.ContractStatusTypeEntry', {route: 'about:blank',service: ContractStatusTypeEntryService}],
    ['cm.Broker', {route: 'about:blank',service: BrokerService}],
    ['cm.TagTypeScope', {route: 'about:blank',service: TagTypeScopeService}],
    ['cm.PartnerGroup', {route: 'about:blank',service: PartnerGroupService}],
    ['cm.ContractTag', {route: 'about:blank',service: ContractTagService}],
    ['cm.ContractStatus', {route: 'about:blank',service: ContractStatusService}],
    ['cm.ContractPrice', {route: 'about:blank',service: ContractPriceService}],
    ['cm.ContractDiscount', {route: 'about:blank',service: ContractDiscountService}],
    ['cm.ContractFormer', {route: 'about:blank',service: ContractFormerService}],
    ['cm.ContractData', {route: 'about:blank',service: ContractDataService}],
    ['cm.ProductData', {route: 'about:blank',service: ProductDataService}],
    ['cm.ContractAgent', {route: 'about:blank',service: ContractAgentService}],
    ['cm.ContractBundle', {route: 'about:blank',service: ContractBundleService}],
    ['cm.ContractDocument', {route: 'about:blank',service: ContractDocumentService}],
    ['cm.PartnerType', {route: 'about:blank',service: PartnerTypeService}],
    ['cm.ProductDataField', {route: 'about:blank',service: ProductDataFieldService}],
    ['cm.Partner', {route: 'about:blank',service: PartnerService}],
    ['cm.Tax', {route: 'about:blank',service: TaxService}],
    ['cm.ContractAcquisitionCommission', {route: 'about:blank',service: ContractAcquisitionCommissionService}],
    ['cm.Category', {route: 'about:blank',service: CategoryService}],
    ['cm.ContractPartner', {route: 'about:blank',service: ContractPartnerService}],
    ['cm.TagType', {route: 'about:blank',service: TagTypeService}],
    ['cm.ContractDataField', {route: 'about:blank',service: ContractDataFieldService}],
    ['cm.ContractStatusType', {route: 'about:blank',service: ContractStatusTypeService}],
    ['cm.ProductPartner', {route: 'about:blank',service: ProductPartnerService}],
    ['cm.PartnerCategory', {route: 'about:blank',service: PartnerCategoryService}],
    ['cm.PartnerContractType', {route: 'about:blank',service: PartnerContractTypeService}],
    ['cm.PriceRangeDiscount', {route: 'about:blank',service: PriceRangeDiscountService}],
    ['cm.AssessmentGroupContractType', {route: 'about:blank',service: AssessmentGroupContractTypeService}],
    ['cm.ContractTypeField', {route: 'about:blank',service: ContractTypeFieldService}],
    ['cm.ContractTypeAmountType', {route: 'about:blank',service: ContractTypeAmountTypeService}],
    ['cm.Contract', {route: 'about:blank',objectRoute:'/cm/contract/:id',service: ContractService}],
    ['cm.ContractType', {route: 'about:blank',service: ContractTypeService}],
    ['cm.RatingTypeObjectType', {route: 'about:blank',service: RatingTypeObjectTypeService}],
    ['cm.Group', {route: 'about:blank',service: GroupService}],
    ['cm.RatingType', {route: 'about:blank',service: RatingTypeService}],
    ['cm.PriceRange', {route: 'about:blank',service: PriceRangeService}],
    ['cm.ContractOriginType', {route: 'about:blank',service: ContractOriginTypeService}],
    ['cm.ContractTerminationReasonType', {route: 'about:blank',service: ContractTerminationReasonTypeService}],
    ['cm.ContractCategoryType', {route: 'about:blank',service: ContractCategoryTypeService}],
    ['cm.ContractTerminationType', {route: 'about:blank',service: ContractTerminationTypeService}],
    ['cm.ContractNotificationType', {route: 'about:blank',service: ContractNotificationTypeService}],
    ['cm.ContractNotificationTypeRestrictGroup', {route: 'about:blank',service: ContractNotificationTypeRestrictGroupService}],
    ['cm.ContractNotificationTypeRestrictContractType', {route: 'about:blank',service: ContractNotificationTypeRestrictContractTypeService}],

    ['cm.ContractOneTimePayment', {route: 'about:blank',service: ContractOneTimePaymentService}],
    ['cm.ContractOneTimePaymentReason', {route: 'about:blank',service: ContractOneTimePaymentReasonService}],

    ['cm.ContractConfigDocumentGroup', {route: 'about:blank',service: ContractConfigDocumentGroupService}],
    ['cm.PartnerAddress', {route: 'about:blank',service: PartnerAddressService}],
    ['cm.ContractBaseTypeTicketType', {route: 'about:blank',service: ContractBaseTypeTicketTypeService}],


    ['cp.ProvisionPeriod', {route: 'about:blank',service: ProvisionPeriodService}],

    ['cp.ProvisionAgent', {route: 'about:blank',service: ProvisionAgentService}],
    ['cp.ProvisionAgentGroup', {route: 'about:blank',service: ProvisionAgentGroupService}],
    ['cp.ProvisionAgentComment', {route: 'about:blank',service: ProvisionAgentCommentService}],
    ['cp.ProvisionAgentPool', {route: 'about:blank',service: ProvisionAgentPoolService}],
    ['cp.ProvisionAgentPoolGroup', {route: 'about:blank',service: ProvisionAgentPoolGroupService}],
    ['cp.ProvisionAgentPoolAgent', {route: 'about:blank',service: ProvisionAgentPoolAgentService}],

    ['cp.ProvisionCategoryType', {route: 'about:blank',service: ProvisionCategoryTypeService}],
    ['cp.ProvisionCategoryTypeAssignment', {route: 'about:blank',service: ProvisionCategoryTypeAssignmentService}],

    ['cp.ProvisionGroupType', {route: 'about:blank',service: ProvisionGroupTypeService}],
    ['cp.ProvisionGroupTypeAssignment', {route: 'about:blank',service: ProvisionGroupTypeAssignmentService}],

    ['cp.ProvisionContractEligibilityType', {route: 'about:blank',service: ProvisionContractEligibilityTypeService}],
    ['cp.ProvisionContractChangeEligibilityType', {route: 'about:blank',service: ProvisionContractChangeEligibilityTypeService}],
    ['cp.ProvisionContractEligibility', {route: 'about:blank',service: ProvisionContractEligibilityService}],
    ['cp.ProvisionContractEligibilityComment', {route: 'about:blank',service: ProvisionContractEligibilityCommentService}],
    ['cp.ProvisionContractOneTimePaymentEligibility', {route: 'about:blank',service: ProvisionContractOneTimePaymentEligibilityService}],
    ['cp.ProvisionContractPriceEligibility', {route: 'about:blank',service: ProvisionContractPriceEligibilityService}],

    ['cp.ProvisionCancellationAccountTemplate', {route: 'about:blank',service: ProvisionCancellationAccountTemplateService}],
    ['cp.ProvisionLiabilityTemplate', {route: 'about:blank',service: ProvisionLiabilityTemplateService}],

    ['cp.ProvisionCurrency', {route: 'about:blank',service: ProvisionCurrencyService}],
    ['cp.ProvisionCurrencyExchange', {route: 'about:blank',service: ProvisionCurrencyExchangeService}],
    ['cp.ProvisionCurrencyMonetaryValue', {route: 'about:blank',service: ProvisionCurrencyMonetaryValueService}],

    ['cp.ProvisionTemplateType', {route: 'about:blank',service: ProvisionTemplateTypeService}],
    ['cp.ProvisionTemplateTypeField', {route: 'about:blank',service: ProvisionTemplateTypeFieldService}],
    ['cp.ProvisionTemplateTypeMapping', {route: 'about:blank',service: ProvisionTemplateTypeMappingService}],

    ['cp.ProvisionType', {route: 'about:blank',service: ProvisionTypeService}],
    ['cp.ProvisionTypeAgentPayment', {route: 'about:blank',service: ProvisionTypeAgentPaymentService}],
    ['cp.ProvisionTypeAgentPaymentAgent', {route: 'about:blank',service: ProvisionTypeAgentPaymentAgentService}],
    ['cp.ProvisionTypeAgentPaymentAgentPool', {route: 'about:blank',service: ProvisionTypeAgentPaymentAgentPoolService}],
    ['cp.ProvisionTypeAgentPaymentCategory,', {route: 'about:blank',service: ProvisionTypeAgentPaymentCategoryService}],
    ['cp.ProvisionTypeAgentPaymentContractAssignment', {route: 'about:blank',service: ProvisionTypeAgentPaymentContractAssignmentService}],
    ['cp.ProvisionTypeAgentPaymentPartnerPayment', {route: 'about:blank',service: ProvisionTypeAgentPaymentPartnerPaymentService}],
    ['cp.ProvisionTypeAgentPaymentProvisionAgentPool', {route: 'about:blank',service: ProvisionTypeAgentPaymentProvisionAgentPoolService}],

    ['cp.ProvisionTypeContractAgentProvision.', {route: 'about:blank',service: ProvisionTypeContractAgentProvisionService}],
    ['cp.ProvisionTypePartnerPayment', {route: 'about:blank',service: ProvisionTypePartnerPaymentService}],
    ['cp.ProvisionTypePartnerPaymentCategory', {route: 'about:blank',service: ProvisionTypePartnerPaymentCategoryService}],
    ['cp.ProvisionTypePartnerPaymentComment', {route: 'about:blank',service: ProvisionTypePartnerPaymentCommentService}],


    ['cr.Customer', {route: '/cr/customers',objectRoute:'/cr/customers/:id',service: CustomerService}],
    ['cr.CustomerAddress', {route: '/cr/customerAddresses',service: CustomerAddressService}],
    ['cr.CustomerComment', {route: '/cr/customerComments',service: CustomerCommentService}],
    ['cr.CustomerAgent', {route: '/cr/customerAgents',service: CustomerAgentService}],
    ['cr.CustomerContact', {route: '/cr/customerContacts',service: CustomerContactService}],
    ['cr.CustomerInsurableObject', {route: '/cr/customerInsurableObjects',service: CustomerInsurableObjectService}],
    ['cr.CustomerInsurance', {route: '/cr/customerInsurances',service: CustomerInsuranceService}],
    ['cr.CustomerType', {route: '/cr/customerTypes',service: CustomerTypeService}],
    ['cr.CustomerInteractionType', {route: '/cr/customerTypes',service: CustomerInteractionTypeService}],
    ['cr.CustomerInteractionTypeInfoStep', {route: '/cr/customerTypes',service: CustomerInteractionTypeInfoStepService}],
    ['cr.CustomerPhoneContactAvailability', {route: 'about:blank',service: CustomerPhoneContactAvailabilityService}],
    ['cr.CustomerInteractionDocument', {route: 'about:blank',service: CustomerInteractionDocumentService}],
    ['cr.CustomerFormField', {route: 'about:blank',service: CustomerFormFieldService}],
    ['cr.CustomerEmail', {route: 'about:blank',service: CustomerEmailService}],
    ['cr.CustomerInteraction', {route: 'about:blank',service: CustomerInteractionService}],
    ['cr.CustomerUser', {route: 'about:blank',service: CustomerUserService}],
    ['cr.CustomerAutopilot', {route: 'about:blank',service: CustomerAutopilotService}],
    ['cr.CustomerEmergencyContactRelatedPerson', {route: 'about:blank',service: CustomerEmergencyContactRelatedPersonService}],
    ['cr.CustomerChannel', {route: 'about:blank',service: CustomerChannelService}],
    ['cr.CustomerSuperContactRelatedPerson', {route: 'about:blank',service: CustomerSuperContactRelatedPersonService}],
    ['cr.CustomerConsultingType', {route: 'about:blank',service: CustomerConsultingTypeService}],
    ['cr.CustomerExternalAlias', {route: 'about:blank',service: CustomerExternalAliasService}],
    ['cr.CustomerSignature', {route: 'about:blank',service: CustomerSignatureService}],
    ['cr.CustomerContract', {route: 'about:blank',service: CustomerContractService}],
    ['cr.ProfileCheckCompletenessRange', {route: 'about:blank',service: ProfileCheckCompletenessRangeService}],
    ['cr.CustomerEmergencyContact', {route: 'about:blank',service: CustomerEmergencyContactService}],


    ['cr.CustomerForm', {route: '/cr/customerForms',service: CustomerFormService}],
    ['cr.CustomerFormType', {route: '/cr/customerFormTypes',service: CustomerFormTypeService}],
    ['cr.CustomerFormTypeField', {route: '/cr/customerFormTypeFields',service: CustomerFormTypeFieldService}],
    ['cr.CustomerFormGroup', {route: '/cr/customerFormGroups',service: CustomerFormGroupService}],
    ['cr.CustomerOnboarding', {route: '/cr/customerOnboardings',service: CustomerOnboardingService}],
    ['cr.CustomerOnboardingType', {route: '/cr/customerOnboardingTypes',service: CustomerOnboardingTypeService}],
    ['cr.CustomerOnboardingTypeContractType', {route: '/cr/customerOnboardingTypeContractTypes',service: CustomerOnboardingTypeContractTypeService}],
    ['cr.CustomerOnboardingTypeInfoStep', {route: '/cr/customerOnboardingTypeInfoSteps',service: CustomerOnboardingTypeInfoStepService}],
    ['cr.CustomerSignatureType', {route: '/cr/customerSignatureTypes',service: CustomerSignatureTypeService}],
    ['cr.CustomerWelcome', {route: '/cr/customerWelcomes',service: CustomerWelcomeService}],
    ['cr.CustomerInfoPage', {route: '/cr/customerInfoPages',service: CustomerInfoPageService}],
    ['cr.CustomerRegistrationConfiguration', {route: '/cr/customerRegistrationConfigurations',service: CustomerRegistrationConfigurationService}],
    ['cr.CustomerUserConfiguration', {route: '/cr/customerUserConfigurations',service: CustomerUserConfigurationService}],
    ['cr.CustomerNotificationType', {route: 'about:blank',service: CustomerNotificationTypeService}],

    ['cr.CustomerRelatedPersonType', {route: '/cr/customerTypes',service: CustomerRelatedPersonTypeService}],
    ['cr.CustomerRelatedPerson', {route: '/cr/customerTypes',service: CustomerRelatedPersonService}],
    ['cr.CustomerTag', {route: '/cr/customerTags',service: CustomerTagService}],
    ['cr.CustomerCategoryType', {route: 'about:blank',service: CustomerCategoryTypeService}],
    ['cr.CustomerBrokerPoaType', {route: 'about:blank',service: CustomerBrokerPoaTypeService}],
    ['cr.CustomerBrokerPoaTypeReason', {route: 'about:blank',service: CustomerBrokerPoaTypeReasonService}],


    ['cs.Referral', {route: 'about:blank',service: AgentReferralService}],
    ['cs.CustomerLevelOption', {route: 'about:blank',service: CustomerLevelOptionService}],
    ['cs.CustomerReferral', {route: 'about:blank',service: CustomerReferralService}],
    ['cs.CustomerReferralRedeem', {route: 'about:blank',service: CustomerReferralRedeemService}],
    ['cs.ReferralAccess', {route: 'about:blank',service: ReferralAccessService}],
    ['cs.ReferralType', {route: 'about:blank',service: ReferralTypeService}],
    ['cs.ReferralTypeLevel', {route: 'about:blank',service: ReferralTypeLevelService}],
    ['cs.ReferralTypeLevelOption', {route: 'about:blank',service: ReferralTypeLevelOptionService}],


    ['co.CoChannelType', {route: '/co/coChannelTypes',service: CoChannelTypeService}],
    ['co.CoChannelTypeContactType', {route: '/co/coChannelTypeContactTypes',service: CoChannelTypeContactTypeService}],

    ['em.EmSchemaField', {route: 'about:blank',service: EmSchemaFieldService}],
    ['em.EmSchemaFieldComment', {route: 'about:blank',service: EmSchemaFieldCommentService}],
    ['em.EmSchemaStructure', {route: 'about:blank',service: EmSchemaStructureService}],
    ['em.EmSchemaType', {route: 'about:blank',service: EmSchemaTypeService}],
    ['em.EmSchemaFieldValue', {route: 'about:blank',service: EmSchemaFieldValueService}],
    ['em.EmSchema', {route: 'about:blank',service: EmSchemaService}],
    ['em.EmSchemaFieldValueComment', {route: 'about:blank',service: EmSchemaFieldValueCommentService}],


    ['ha.IntegrationAlphaBatchEntityStepActivityRequest', {route: 'about:blank',service: IntegrationAlphaBatchEntityStepActivityRequestService}],
    ['ha.IntegrationAlphaAuth', {route: 'about:blank',service: IntegrationAlphaAuthService}],
    ['ha.IntegrationAlphaBatchEntityStep', {route: 'about:blank',service: IntegrationAlphaBatchEntityStepService}],
    ['ha.IntegrationAlphaBatchThreading', {route: 'about:blank',service: IntegrationAlphaBatchThreadingService}],
    ['ha.IntegrationAlphaSystem', {route: 'about:blank',service: IntegrationAlphaSystemService}],
    ['ha.IntegrationAlphaBatchEntity', {route: 'about:blank',service: IntegrationAlphaBatchEntityService}],
    ['ha.IntegrationAlphaBatchEntityStepActivity', {route: 'about:blank',service: IntegrationAlphaBatchEntityStepActivityService}],

    ['hb.IntegrationBiproAuthType', {route: 'about:blank',service: IntegrationBiproAuthTypeService}],
    ['hb.IntegrationSystemBiproAuth', {route: 'about:blank',service: IntegrationSystemBiproAuthService}],
    ['hb.IntegrationSystemBiproShipment', {route: 'about:blank',service: IntegrationSystemBiproShipmentService}],
    ['hb.IntegrationBiproTemplate', {route: 'about:blank',service: IntegrationBiproTemplateService}],
    ['hb.IntegrationBiproVersion', {route: 'about:blank',service: IntegrationBiproVersionService}],

    ['hb.BiproType', {route: 'about:blank',service: BiproServiceTypeService}],
    ['hb.BiproService', {route: 'about:blank',service: BiproServiceService}],
    ['hb.BiproVersion', {route: 'about:blank',service: BiproVersionService}],
    ['hb.BiproPartnerAuthentication', {route: 'about:blank',service: BiproPartnerAuthenticationService}],
    ['hb.BiproPartner', {route: 'about:blank',service: BiproPartnerService}],
    ['hb.BiproFieldType', {route: 'about:blank',service: BiproFieldTypeService}],

    ['hm.IntegrationAzureGraphApi', {route: 'about:blank',service: IntegrationAzureGraphApiService}],
    ['hm.IntegrationAzureContainerConnection', {route: 'about:blank',service: IntegrationAzureContainerConnectionService}],

    ['hc.IntegrationTypeStepOutput', {route: 'about:blank',service: IntegrationTypeStepOutputService}],
    ['hc.IntegrationSystemType', {route: 'about:blank',service: IntegrationSystemTypeService}],
    ['hc.IntegrationStep', {route: 'about:blank',service: IntegrationStepService}],
    ['hc.IntegrationTypeStep', {route: 'about:blank',service: IntegrationTypeStepService}],
    ['hc.IntegrationType', {route: 'about:blank',service: IntegrationTypeService}],
    ['hc.IntegrationAdapterType', {route: 'about:blank',service: IntegrationAdapterTypeService}],
    ['hc.IntegrationTypeVariable', {route: 'about:blank',service: IntegrationTypeVariableService}],
    ['hc.IntegrationTransformationType', {route: 'about:blank',service: IntegrationTransformationTypeService}],
    ['hc.Integration', {route: 'about:blank',service: IntegrationService}],
    ['hc.IntegrationTypeStepTransition', {route: 'about:blank',service: IntegrationTypeStepTransitionService}],
    ['hc.IntegrationTypeStepInput', {route: 'about:blank',service: IntegrationTypeStepInputService}],
    ['hc.IntegrationStepAdapter', {route: 'about:blank',service: IntegrationStepAdapterService}],

    ['he.IntegrationSftpConnection', {route: 'about:blank',service: IntegrationSftpConnectionService}],

    ['ye.IntegrationExcodaConfig', {route: 'about:blank',service: IntegrationExcodaConfigService}],
    ['ye.IntegrationExcodaConfigFileProcessor', {route: 'about:blank',service: IntegrationExcodaConfigFileProcessorService}],

    ['dm.DmDocumentType', {route: '/dm/dmDocumentTypes',service: DmDocumentTypeService}],
    ['dm.DmDocumentGroup', {route: '/dm/dmDocumentGroups',service: DmDocumentGroupService}],
    ['dm.DmDocumentTypeMimeType', {route: '/dm/dmDocumentTypeMimeTypes',service: DmDocumentTypeMimeTypeService}],
    ['dm.DmMimeType', {route: '/dm/dmMimeTypes',service: DmMimeTypeService}],
    ['dm.DmDocumentStorage', {route: '/dm/dmDocumentStorages',service: DmDocumentStorageService}],
    ['dm.DmDocument', {route: '',service: DmDocumentService}],
    ['dm.DmDocumentAssignment', {route: '/dm/dmDocumentStorages',service: DmDocumentAssignmentService}],
    ['dm.DmDocumentComment', {route: '/dm/dmDocuments',service: DmDocumentCommentService}],
    ['dm.DmDocumentStatus', {route: '/dm/dmDocumentStatus',service: DmDocumentStatusService}],
    ['dm.DmFormRecognizerType', {route: '/dm/dmFormRecognizerTypes',service: DmFormRecognizerTypeService}],
    ['dm.DmDashboard', {route: '/dm/dmDashboards',service: DmDashboardService}],
    ['dm.DmAssignmentType', {route: '/dm/dmAssignmentTypes',service: DmAssignmentTypeService}],
    ['dm.DmAssignmentTypeObjectType', {route: '/dm/dmAssignmentTypeObjectTypes',service: DmAssignmentTypeObjectTypeService}],

    ['dm.DmDocumentRelatedData', {route: 'about:blank',service: DmDocumentRelatedDataService}],
    ['dm.DmDocumentAssignmentEu', {route: 'about:blank',service: DmDocumentAssignmentEuService}],
    ['dm.DmDataType', {route: 'about:blank',service: DmDataTypeService}],
    ['dm.DmDataGroup', {route: 'about:blank',service: DmDataGroupService}],
    ['dm.DmData', {route: 'about:blank',service: DmDataService}],

    ['dm.DmDocumentAccess', {route: 'about:blank',service: DmDocumentAccessService}],
    ['dm.DmDocumentFormData', {route: 'about:blank',service: DmDocumentFormDataService}],
    ['dm.DmDocumentData', {route: 'about:blank',service: DmDocumentDataService}],

    ['ig.IgPassTokenUsed', {route: 'about:blank',service: IgPassTokenUsedService}],
    ['ig.IgAccessToken', {route: 'about:blank',service: IgAccessTokenService}],
    ['ig.IgAccessTokenUsed', {route: 'about:blank',service: IgAccessTokenUsedService}],
    ['ig.IgPassToken', {route: 'about:blank',service: IgPassTokenService}],

    ['lg.LogicImport', {route: 'about:blank',service: LogicImportService}],
    ['lg.LogicGroup', {route: 'about:blank',service: LogicGroupService}],
    ['lg.Logic', {route: 'about:blank',service: LogicService}],
    ['lg.LogicExport', {route: 'about:blank',service: LogicExportService}],

    ['lg.CalculationLogicComment', {route: 'about:blank',service: CalculationLogicCommentService}],
    ['lg.CalculationLogicTestCase', {route: 'about:blank',service: CalculationLogicTestCaseService}],
    ['lg.CalculationLogicTestCaseImportVariable', {route: 'about:blank',service: CalculationLogicTestCaseImportVariableService}],
    ['lg.LogicComment', {route: 'about:blank',service: LogicCommentService}],

    ['lg.LogicTestCaseImportVariable', {route: 'about:blank',service: LogicTestCaseImportVariableService}],
    ['lg.LogicTestCase', {route: 'about:blank',service: LogicTestCaseService}],

    ['lg.CalculationLogicTypeStep', {route: 'about:blank',service: CalculationLogicTypeStepService}],
    ['lg.CalculationLogicType', {route: 'about:blank',service: CalculationLogicTypeService}],
    ['lg.CalculationLogicTypeField', {route: 'about:blank',service: CalculationLogicTypeFieldService}],
    ['lg.CalculationLogicTypeComment', {route: 'about:blank',service: CalculationLogicTypeCommentService}],
    ['lg.CalculationLogicTypeStepMapping', {route: 'about:blank',service: CalculationLogicTypeStepMappingService}],
    ['lg.CalculationLogic', {route: 'about:blank',service: CalculationLogicService}],
    ['lg.CalculationLogicField', {route: 'about:blank',service: CalculationLogicFieldService}],

    ['jb.JbJobRun', {route: 'about:blank',service: JbJobRunService}],
    ['jb.JbJobRunLogEntry', {route: 'about:blank',service: JbJobRunLogEntryService}],
    ['jb.JbJobType', {route: 'about:blank',service: JbJobTypeService}],

    ['im.ImImport', {route: '/im/config',service: ImImportService}],
    ['im.ImSystem', {route: '/im/config',service: ImSystemService}],
    ['im.ImImportRecord', {route: '/im/imImportRecords',service: ImImportRecordService}],

    ['eu.EuFrontEndText', {route: '/eu/euFrontEndTexts',service: EuFrontEndTextService}],
    ['eu.EuDefaultRole', {route: 'about:blank',service: EuDefaultRoleService}],
    ['eu.EuThemeColor', {route: '/eu/euThemeColors',service: EuThemeColorService}],
    ['eu.EuRegistration', {route: '/eu/euRegistrations',service: EuRegistrationService}],
    ['eu.EuUserAddedInsurance', {route: '/eu/euUserAddedInsurances',service: EuUserAddedInsuranceService}],
    ['eu.EuRole', {route: 'about:blank',service: EuRoleService}],
    ['eu.EuUserRelatedPersonImage', {route: 'about:blank',service: EuUserRelatedPersonImageService}],
    ['eu.EuUserTest', {route: 'about:blank',service: EuUserTestService}],
    ['eu.EuOtpCode', {route: 'about:blank',service: EuOtpCodeService}],
    ['eu.EuFrontEndThemeColorDto', {route: 'about:blank',service: EuFrontEndThemeColorDto}],
    ['eu.EuStaticNotificationContractPersonType', {route: 'about:blank',service: EuStaticNotificationContractPersonTypeService}],
    ['eu.EuUserRole', {route: 'about:blank',service: EuUserRoleService}],
    ['eu.EuUser', {route: 'about:blank',service: EuUserService}],
    ['eu.EuPasswordResetToken', {route: 'about:blank',service: EuPasswordResetTokenService}],
    ['eu.EuTmConfiguration', {route: 'about:blank',service: EuTmConfigurationService}],
    ['eu.EuPromotion', {route: 'about:blank',service: EuPromotionService}],
    ['eu.EuUserObjectAccess', {route: 'about:blank',service: EuUserObjectAccessService}],
    ['eu.EuStaticNotification', {route: 'about:blank',service: EuStaticNotificationService}],
    ['eu.EuRegistrationSignature', {route: 'about:blank',service: EuRegistrationSignatureService}],
    ['eu.EuUserAddedInsuranceDocument', {route: 'about:blank',service: EuUserAddedInsuranceDocumentService}],
    ['eu.EuStaticNotificationContractType', {route: 'about:blank',service: EuStaticNotificationContractTypeService}],
    ['eu.EuUserInsurableObjectImage', {route: 'about:blank',service: EuUserInsurableObjectImageService}],
    ['eu.EuUserDevice', {route: 'about:blank',service: EuUserDeviceService}],
    ['eu.EuStaticNotificationInsurableObjectType', {route: 'about:blank',service: EuStaticNotificationInsurableObjectTypeService}],
    ['eu.EuNotificationType', {route: 'about:blank',service: EuNotificationTypeService}],
    ['eu.EuUserDocumentType', {route: 'about:blank',service: EuUserDocumentTypeService}],

    ['cm.ContractTypeNotificationType', {route: 'about:blank',service: ContractTypeNotificationTypeService}],

    ['om.OrganizationalUnit', {route: '/om/organizationalUnits',service: OrganizationalUnitService}],
    ['om.Position', {route: '/om/positions',service: PositionService}],
    ['om.Employee', {route: '/om/employees',service: EmployeeService}],
    ['om.EmployeePosition', {route: '/om/employeePositions',service: EmployeePositionService}],
    ['om.Department', {route: '/om/department',service: DepartmentService}],

    ['pm.Person', {route: '/pm/persons',service: PersonService}],
    ['pm.PersonType', {route: '/pm/personTypes',service: PersonTypeService}],
    ['pm.PersonDetail', {route: '/pm/personDetails',service: PersonDetailService}],
    ['pm.PersonContact', {route: 'about:blank',service: PersonContactService}],
    ['pm.PersonAddress', {route: 'about:blank',service: PersonAddressService}],

    ['ui.UiField', {route: '/ui/uiFields',service: UiFieldService}],
    ['ui.UiFieldType', {route: '/ui/uiFieldTypes',service: UiFieldTypeService}],
    ['ui.UiFieldGroup', {route: '/ui/uiFieldGroup',service: UiFieldGroupService}],
    ['ui.UiWidget', {route: 'about:blank',service: UiWidgetService}],
    ['ui.UiWidgetLayout', {route: 'about:blank',service: UiWidgetLayoutService}],
    ['ui.UiMutableArea', {route: 'about:blank',service: UiMutableAreaService}],
    ['ui.UiMutableAreaLayout', {route: 'about:blank',service: UiMutableAreaLayoutService}],
    ['ui.UiDashboard', {route: 'about:blank',service: UiDashboardService}],

    ['ui.UiPage', {route: 'about:blank',service: UiPageService}],
    ['ui.UiPageUser', {route: 'about:blank',service: UiPageUserService}],
    ['ui.UiPageUserFavorite', {route: 'about:blank',service: UiPageUserFavoriteService}],

    ['um.UserRole', {route: 'about:blank',service: UserRoleService}],
    ['um.Role', {route: 'about:blank',service: RoleService}],
    ['um.User', {route: 'about:blank',service: UserService}],
    ['um.UmUser', {route: 'about:blank',service: UmUserService }],
    ['um.UmDocumentConfiguration', {route: 'about:blank',service: UmDocumentConfigurationService}],

    ['um.UmDocumentConfiguration', {route: 'about:blank',service: UmDocumentConfigurationService}],
    ['um.UserImage', {route: 'about:blank',service: UserImageService}],
    ['um.RoleAuthObjectType', {route: 'about:blank',service: RoleAuthObjectTypeService}],
    ['um.UserNotificationType', {route: 'about:blank',service: UserNotificationTypeService}],

    ['wf.WfProcessType', {route: 'wf/processTypes',service: WfProcessTypeService}],
    ['wf.WfProcessTypeStepReminder', {route: 'wf/processTypeStepReminder',service: WfProcessTypeStepReminderService}],
    ['wf.WfProcessTypeStepActivity', {route: 'wf/processTypeStepActivity',service: WfProcessTypeStepActivityService}],
    ['wf.WfActivityTypeObject', {route: 'wf/activityTypes',service: WfActivityTypeObjectService}],
    ['wf.WfActivityTypeNotification', {route: 'wf/activityTypes',service: WfActivityTypeNotificationService}],
    ['wf.WfActivityTypeStartProcess', {route: 'wf/activityTypes',service: WfActivityTypeStartProcessService}],

    ['wf.WfProcess', {route: 'wf/processes',service: WfProcessService}],
    ['wf.WfProcessStep', {route: 'wf/processSteps',service: WfProcessStepService}],
    ['wf.WfProcessTypeStepNext', {route: 'wf/processSteps',service: WfProcessTypeStepNextService}],
    ['wf.WfProcessTypeStepActivityFormDefault', {route: 'wf/processes',service: WfProcessTypeStepActivityFormDefaultService}],

    ['wf.WfReminderType', {route: 'about:blank',service: WfReminderTypeService}],
    ['wf.WfProcessTypeField', {route: 'about:blank',service: WfProcessTypeFieldService}],
    ['wf.WfActivityTypeObject', {route: 'about:blank',service: WfActivityTypeObjectService}],
    ['wf.WfProcessTypeStep', {route: 'about:blank',service: WfProcessTypeStepService}],
    ['wf.WfEventType', {route: 'about:blank',service: WfEventTypeService}],
    ['wf.WfProcessGroup', {route: 'about:blank',service: WfProcessGroupService}],
    ['wf.WfProcessTypeStepReminder', {route: 'about:blank',service: WfProcessTypeStepReminderService}],
    ['wf.WfProcessObject', {route: 'about:blank',service: WfProcessObjectService}],
    ['wf.WfProcessStepActivity', {route: 'about:blank',service: WfProcessStepActivityService}],
    ['wf.WfProcessField', {route: 'about:blank',service: WfProcessFieldService}],
    ['wf.WfProcessTypeStepEvent', {route: 'about:blank',service: WfProcessTypeStepEventService}],
    ['wf.WfActivityTypeNotification', {route: 'about:blank',service: WfActivityTypeNotificationService}],
    ['wf.WfActivityTypeStartProcess', {route: 'about:blank',service: WfActivityTypeStartProcessService}],
    ['wf.WfProcessTypeStepActivityContext', {route: 'about:blank',service: WfProcessTypeStepActivityContextService}],
    ['wf.WfProcessStepType', {route: 'about:blank',service: WfProcessStepTypeService}],
    ['wf.WfProcessTypeStepActivity', {route: 'about:blank',service: WfProcessTypeStepActivityService}],
    ['wf.WfActivityType', {route: 'about:blank',service: WfActivityTypeService}],

    ['wf.WfHintType', {route: 'about:blank',service: WfHintTypeService}],
    ['wf.WfProcessTypeStepHint', {route: 'about:blank',service: WfProcessTypeStepHintService}],

    ['wf.WfProcessTypeStepActivityTicketCreate', {route: 'about:blank',service: WfProcessTypeStepActivityTicketCreateService}],
    ['wf.WfProcessTypeStepActivityNotification', {route: 'about:blank',service: WfProcessTypeStepActivityNotificationService}],
    ['wf.WfProcessTypeStepActivityStartProcess', {route: 'about:blank',service: WfProcessTypeStepActivityStartProcessService}],
    ['wf.WfProcessTypeStepActivityObject', {route: 'about:blank',service: WfProcessTypeStepActivityObjectService}],
    ['wf.WfProcessStepActivityObject', {route: 'about:blank',service: WfProcessStepActivityObjectService}],
    ['wf.WfProcessTypeStepActivityBinding', {route: 'about:blank',service: WfProcessTypeStepActivityBindingService}],
    ['wf.WfProcessTypeResult', {route: 'about:blank',service: WfProcessTypeResultService}],
    ['wf.WfResultCategory', {route: 'about:blank',service: WfResultCategoryService}],
    ['wf.WfProcessTypeStepActivityDocument', {route: 'about:blank',service: WfProcessTypeStepActivityDocumentService}],
    ['wf.WfProcessTypeStepActivityChooseNextStep', {route: 'about:blank',service: WfProcessTypeStepActivityChooseNextStepService}],
    ['wf.WfProcessTypeStepActivityTicketStatus', {route: 'about:blank',service: WfProcessTypeStepActivityTicketStatusService}],
    ['wf.WfProcessGroupField', {route: 'about:blank',service: WfProcessGroupFieldService}],

    ['xx.XxSimpleTestEntity', {route: 'xx/xxSimpleTestEntities',service: XxSimpleTestEntityService}],
    ['xh.AnonymizationData', {route: 'xx/xxAnonymizationDatas',service: XhAnonymizationDataService}],


    ['tm.TmCommunicationChannel', {route: 'tm/communicationChannels',service: TmCommunicationChannelService}],
    ['tm.TicketGroup', {route: 'tm/none',service: TicketGroupService}],

    ['tm.TicketType', {route: 'tm/ticketTypes',service: TicketTypeService}],
    ['tm.TicketTypeAction', {route: 'tm/none',service: TicketTypeActionService}],
    ['tm.TicketTypeField', {route: 'tm/none',service: TicketTypeFieldService}],
    ['tm.TicketTypeObjectType', {route: 'tm/none',service: TicketTypeObjectTypeService}],

    ['tm.TicketTypeCompleteStatus', {route: 'about:blank',service: TicketTypeCompleteStatusService}],
    ['tm.TicketTypeActionProcessMapping', {route: 'about:blank',service: TicketTypeActionProcessMappingService}],
    ['tm.TicketTypeActionTicketMapping', {route: 'about:blank',service: TicketTypeActionTicketMappingService}],

    ['tm.Ticket', {route: 'tm/tickets',service: TicketService}],
    ['tm.TicketAction', {route: 'tm/none',service: TicketActionService}],
    ['tm.TicketActionComment', {route: 'tm/none',service: TicketActionCommentService}],
    ['tm.TicketObject', {route: 'tm/none',service: TicketObjectService}],
    ['tm.TicketAttachment', {route: 'tm/none',service: TicketAttachmentService}],
    ['tm.TicketAppointment', {route: 'tm/none',service: TicketAppointmentService}],
    ['tm.TicketComment', {route: 'tm/none',service: TicketCommentService}],
    ['tm.TicketWatchList', {route: 'tm/none',service: TicketWatchListService}],
    ['tm.TicketRelTicket', {route: 'tm/none',service: TicketRelTicketService}],
    ['tm.TicketTag', {route: 'tm/none',service: TicketTagService}],
    ['tm.TicketStatus', {route: 'tm/none',service: TicketStatusService}],

    ['tm.TicketGroupFilterSelectionUrgency', {route: 'about:blank',service: TicketGroupFilterSelectionUrgencyService}],
    ['tm.TicketTypeNotificationTypeMapping', {route: 'about:blank',service: TicketTypeNotificationTypeMappingService}],
    ['tm.TicketGroupFilterSelectionStatus', {route: 'about:blank',service: TicketGroupFilterSelectionStatusService}],

    ['tm.TicketGroupFilterRestrictionAgentPool', {route: 'about:blank',service: TicketGroupFilterRestrictionAgentPoolService}],
    ['tm.TicketGroupFilterSelectionTicketGroup', {route: 'about:blank',service: TicketGroupFilterSelectionTicketGroupService}],
    ['tm.TicketGroupFilterRestrictionAgent', {route: 'about:blank',service: TicketGroupFilterRestrictionAgentService}],
    ['tm.TicketGroupFilter', {route: 'about:blank',service: TicketGroupFilterService}],
    ['tm.TicketGroupFilterSelectionAgent', {route: 'about:blank',service: TicketGroupFilterSelectionAgentService}],
    ['tm.TicketGroupFilterSelectionAgentPool', {route: 'about:blank',service: TicketGroupFilterSelectionAgentPoolService}],
    ['tm.TicketGroupFilterSelectionTicketType', {route: 'about:blank',service: TicketGroupFilterSelectionTicketTypeService}],

    ['tm.TicketField', {route: 'about:blank',service: TicketFieldService}],
    ['tm.TmCommunicationChannel', {route: 'about:blank',service: TmCommunicationChannelService}],
    ['tm.TicketTypeNotificationType', {route: 'about:blank',service: TicketTypeNotificationTypeService}],
    ['tm.TicketCommentEu', {route: 'about:blank',service: TicketCommentEuService}],
    ['tm.TicketGroupObjectType', {route: 'about:blank',service: TicketGroupObjectTypeService}],
    ['tm.TicketTypeObjectRole', {route: 'about:blank',service: TicketTypeObjectRoleService}],

    ['te.TeTemplate', {route: 'te/config',service: TeTemplateService}],
    ['te.TeSystemVariablePredefined', {route: 'about:blank',service: TeSystemVariablePredefinedService}],

    ['te.TeTemplateGroup', {route: 'te/config',service: TeTemplateGroupService}],
    ['te.TeTemplateArtefact', {route: 'te/config',service: TeTemplateArtefactService}],
    ['te.TeArtefact', {route: 'te/config',service: TeArtefactService}],
    ['te.TeArtefactGroup', {route: 'te/config',service: TeArtefactGroupService}],
    ['te.TeArtefactVariable', {route: 'te/config',service: TeArtefactVariableService}],
    ['te.TeTemplateVariable', {route: 'te/config',service: TeTemplateVariableService}],
    ['te.TeTemplateArtefact', {route: 'te/config',service: TeTemplateArtefactService}],
    ['te.TeTemplateArtefactVariableLink', {route: 'te/config',service: TeTemplateArtefactVariableLinkService}],
    ['te.TeTestCase', {route: 'te/config',service: TeTestCaseService}],
    ['te.TeTestCaseVariable', {route: 'te/config',service: TeTestCaseVariableService}],
    ['te.TeTemplateTestCase', {route: 'te/config',service: TeTemplateTestCaseService}],
    ['te.TeArtefactTestCase', {route: 'te/config',service: TeArtefactTestCaseService}],


    ['sm.Signature', {route: 'sm/signatures',service: SignatureService}],
    ['sm.SignatureGroup', {route: 'sm/signatureGroups',service: SignatureGroupService}],
    ['sm.SignatureType', {route: 'sm/signatureTypes',service: SignatureTypeService}],

    ['rp.Report', {route: 'rp/reports',service: ReportService}],
    ['rp.ReportField', {route: 'rp/reportFields',service: ReportFieldService}],
    ['rp.ReportFilter', {route: 'rp/reportFilters',service: ReportFilterService}],
    ['rp.ReportGroup', {route: 'rp/reportGroups',service: ReportGroupService}],
    ['rp.ReportObject', {route: 'rp/reportObjects',service: ReportObjectService}],
    ['rp.ReportObjectLink', {route: 'rp/reportObjectLinks',service: ReportObjectLinkService}],
    ['rp.ReportSorting', {route: 'rp/reportSortings',service: ReportSortingService}],

    ['rp.ReportAgentFavorite', {route: 'about:blank',service: ReportAgentFavoriteService}],
    ['rp.ReportRuntime', {route: 'about:blank',service: ReportRuntimeService}],

    ['ui.UiNavigationMain', {route: 'about:blank',service: UiNavigationMainService}],
    ['ui.UiMutableAreaLayoutItem', {route: 'about:blank',service: UiMutableAreaLayoutItemService}],
    ['ui.UiNavigationSub', {route: 'about:blank',service: UiNavigationSubService}],
    ['ui.UiMutableAreaLayoutUser', {route: 'about:blank',service: UiMutableAreaLayoutUserService}],
    ['ui.UiLayout', {route: 'about:blank',service: UiLayoutService}],
    ['ui.UiWidgetLayoutUser', {route: 'about:blank',service: UiWidgetLayoutUserService}],
    ['ui.UiNavigationSubPage', {route: 'about:blank',service: UiNavigationSubPageService}],
    ['ui.UiTableLayout', {route: 'about:blank',service: UiTableLayoutService}],

    ['ns.NsNotificationGroup', {route: '/ns/nsNotificationGroups',service: NsNotificationGroupService}],
    ['ns.NsNotificationType', {route: '/ns/nsNotificationTypes',service: NsNotificationTypeService}],
    ['ns.NsNotificationTypeChannelType', {route: '/ns/nsNotificationTypes',service: NsNotificationTypeChannelTypeService}],

    ['ns.NsNotificationEmailRecipient', {route: 'about:blank',service: NsNotificationEmailRecipientService}],
    ['ns.NsEmailConfiguration', {route: 'about:blank',service: NsEmailConfigurationService}],
    ['ns.NsNotificationRecipient', {route: 'about:blank',service: NsNotificationRecipientService}],
    ['ns.NsSmsSent', {route: 'about:blank',service: NsSmsSentService}],
    ['ns.NsSmsConfiguration', {route: 'about:blank',service: NsSmsConfigurationService}],
    ['ns.NsRecipientBlackList', {route: 'about:blank',service: NsRecipientBlackListService}],
    ['ns.NsNotificationEmail', {route: 'about:blank',service: NsNotificationEmailService}],
    ['ns.NsRecipientWhiteList', {route: 'about:blank',service: NsRecipientWhiteListService}],
    ['ns.NsNotificationChannel', {route: 'about:blank',service: NsNotificationChannelService}],
    ['ns.NsNotificationContent', {route: 'about:blank',service: NsNotificationContentService}],
    ['ns.NsNotification', {route: 'about:blank', objectRoute: 'ns/notification/:id',service: NsNotificationService}],
    ['ns.NsNotificationChannelRecipient', {route: 'about:blank',service: NsNotificationChannelRecipientService}],

    ['ns.NsMicrosoftTeamsConfiguration', {route: 'about:blank',service: NsMicrosoftTeamsConfigurationService}],
    ['ns.NsMicrosoftTeamsSent', {route: 'about:blank',service: NsMicrosoftTeamsSentService}],

    ['ns.NsNotificationVariable', {route: 'about:blank',service: NsNotificationVariableService}],
    ['ns.NsNotificationTypeChannelTypeTeVariable', {route: 'about:blank',service: NsNotificationTypeChannelTypeTeVariableService}],
    ['ns.NsNotificationTypeVariable', {route: 'about:blank',service: NsNotificationTypeVariableService}],
    ['ns.NsNotificationDataField', {route: 'about:blank',service: NsNotificationDataFieldService}],
    ['ns.NsNotificationTypeField', {route: 'about:blank',service: NsNotificationTypeFieldService}],
    ['ns.NsNotificationData', {route: 'about:blank',service: NsNotificationDataService}],
    ['ns.NsNotificationTypeChannelTypeContactType', {route: 'about:blank',service: NsNotificationTypeChannelTypeContactTypeService}],
    ['ns.NsNotificationTypeChannelTypeDocument', {route: 'about:blank',service: NsNotificationTypeChannelTypeDocumentService}],

    ['pc.CallType', {route: 'about:blank',service: CallTypeService}],
    ['pc.Call', {route: 'about:blank',service: CallService}],
    ['pc.CallRecording', {route: 'about:blank',service: CallRecordingService}],
    ['pc.CallConfiguration', {route: 'about:blank',service: CallConfigurationService}],
    ['pc.CallProtocol', {route: 'about:blank',service: CallProtocolService}],

    ['si.IndexedEntry', {route: 'about:blank',service: IndexedEntryService}],
    ['si.IndexRun', {route: 'about:blank',service: IndexRunService}],
    ['si.IndexedToken', {route: 'about:blank',service: IndexedTokenService}],
    ['si.IndexRunObject', {route: 'about:blank',service: IndexRunObjectService}],
    ['si.IndexedReference', {route: 'about:blank',service: IndexedReferenceService}],
    ['si.IndexRunReference', {route: 'about:blank',service: IndexRunReferenceService}],
    ['si.SearchResultObjectType', {route: 'about:blank',service: SearchResultObjectTypeService}],
    ['si.IndexedField', {route: 'about:blank',service: IndexedFieldService}],
    ['si.SearchObjectTypeAttribute', {route: 'about:blank',service: SearchObjectTypeAttributeService}],


    ['um.RoleAuthConfig', {route: 'about:blank',service: RoleAuthConfigService}],
    ['um.RoleAuthModule', {route: 'about:blank',service: RoleAuthModuleService}],

    ['cm.ContractPriceEntryTypeField', {route: 'about:blank',service: ContractPriceEntryTypeFieldService}],
    ['cm.ContractBaseStatus', {route: 'about:blank',service: ContractBaseStatusService}],
    ['cm.ContractBaseCategory', {route: 'about:blank',service: ContractBaseCategoryService}],
    ['cm.ContractClosureChannelType', {route: 'about:blank',service: ContractClosureChannelTypeService}],
    ['cm.ContractBaseTypeReason', {route: 'about:blank',service: ContractBaseTypeReasonService}],
    ['cm.ContractBaseType', {route: 'about:blank',service: ContractBaseTypeService}],
    ['cm.RoleAuthContract', {route: 'about:blank',service: RoleAuthContractService}],
    ['cm.ContractPriceEntryType', {route: 'about:blank',service: ContractPriceEntryTypeService}],
    ['cm.ContractLabel', {route: 'about:blank',service: ContractLabelService}],

    ['cr.AssessmentOverwriteOption', {route: 'about:blank',service: AssessmentOverwriteOptionService}],
    ['cr.AssessmentResultRange', {route: 'about:blank',service: AssessmentResultRangeService}],
    ['cr.AssessmentOverwrite', {route: 'about:blank',service: AssessmentOverwriteService}],

    ['cr.RoleAuthCustomerType', {route: 'about:blank',service: RoleAuthCustomerTypeService}],
    ['cr.RoleAuthCustomer', {route: 'about:blank',service: RoleAuthCustomerService}],
    ['cr.BusinessCustomer', {route: 'about:blank',service: BusinessCustomerService}],
    ['cr.CustomerBankAccount', {route: 'about:blank',service: CustomerBankAccountService}],
    ['cr.RoleAuthCustomerRegion', {route: 'about:blank',service: RoleAuthCustomerRegionService}],

    ['cr.ProfileCheckType', {route: 'about:blank',service: ProfileCheckTypeService}],
    ['cr.AssessmentGroup', {route: 'about:blank',service: CrAssessmentGroupService}],
    ['cr.AssessmentGroupCheck', {route: 'about:blank',service: AssessmentGroupCheckService}],

    ['pm.PersonRelationPerson', {route: 'about:blank',service: PersonRelationPersonService}],
    ['pm.PersonRelationType', {route: 'about:blank',service: PersonRelationTypeService}],
    ['pm.HouseholdPerson', {route: 'about:blank',service: HouseholdPersonService}],
    ['pm.Household', {route: 'about:blank',service: HouseholdService}],

    ['pm.EmploymentStatus', {route: 'about:blank',service: EmploymentStatusService}],
    ['pm.PersonRelationTypeReverse', {route: 'about:blank',service: PersonRelationTypeReverseService}],

    // ['test.activityTest', {route: 'about:blank',service: ActivityTestService}],
]);
