import {DtoDetail} from "@kvers/alpha-core-common";
import { CalculationLogicTypeDto } from "./calculation-logic-type.dto";
import { LogicDto } from "./logic.dto";

export class CalculationLogicTypeStepDto extends DtoDetail{


    public calculationLogicTypeDto? : CalculationLogicTypeDto;
    public calculationLogicTypeDtoId : number;
    public calculationLogicTypeDtoName : string;
    public orderPriority: number;
    public name: string;
    public description: string;
    public logicDto? : LogicDto;
    public logicDtoId : number;
    public logicDtoName : string;

}