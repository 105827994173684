<ng-container *ngIf="initialized">

    <div class="grid">
        <div class="col-12">

            <mvs-widget
                    *ngIf="widgetTemplateArtefacts"
                    [widgetData]="widgetTemplateArtefacts"
                    (onObjectSelect)="handleSelectArtefact($event)"
                    [importObjectContext]="importContextTemplate"
            ></mvs-widget>

            <mvs-widget
                    *ngIf="widgetTemplateArtefactVariableLink"
                    [widgetData]="widgetTemplateArtefactVariableLink"
                    [importObjectContext]="importContextArtefact"
            ></mvs-widget>

        </div>
    </div>

</ng-container>


<p-dialog header="Header" [(visible)]="visible" [style]="{width: '50vw'}">

    <div class="grid">
        <div class="col-12">

            <h5 class="text-sm">Artefact Browser</h5>
        </div>
        <div class="col-12">

            Artefacts: {{ artefactsList | json }}

            <!-- dropdown to select artefact -->
<!--            <p-dropdown-->
<!--                    *ngIf="artefactsList && artefactsList.entries"-->
<!--                    [style]="{'min-width': '180px'}"-->
<!--                    [options]="artefactsList.entries"-->
<!--                    placeholder="Select Artefact"-->
<!--                    panelStyleClass="text-sm artefact"-->
<!--                    [(ngModel)]="selectedArtefact"-->
<!--                    optionLabel="name"-->
<!--                    appendTo="body"-->
<!--            ></p-dropdown>-->
        </div>

        <div class="col-12">

            <p-selectButton [options]="artefactLocation" [(ngModel)]="selectedLocation" optionLabel="icon"
                            optionValue="value">
                <ng-template let-item pTemplate>
                    <i [class]="item.icon"></i>
                </ng-template>
            </p-selectButton>

        </div>

        <div class="col-12">
            <!-- input to enter artefact priority -->
            <p-inputNumber
                    [(ngModel)]="selectedPriority"
                    placeholder="Enter Priority"
            ></p-inputNumber>
        </div>

        <div class="col-12">
            <p-button
                    label="Submit"
                    (click)="createTemplateArtefact()"
                    [disabled]="busy"
            ></p-button>
        </div>
    </div>

</p-dialog>