<!--<mvs-crud-page [objectStructureRequest]="objectBrowserRequest"-->
<!--               [defaultLabel]="defaultLabel">-->
<!--</mvs-crud-page>-->

<!--<pm-person-search-component [suggestionsObjectRequestList]="objectRequestList"></pm-person-search-component>-->


<div class="bg-white">
    <mvs-config-header header="Person Types" [categoryTypeMode]="'person'"
                       icon="fa-light fa-user">
    </mvs-config-header>

    <p-tabView (onChange)="handleTabSelection($event.index)" [(activeIndex)]="activeTabIndex">
        <ng-container *ngFor="let widgetEntry of widgetEntries; let i = index">
            <p-tabPanel>
                <ng-template pTemplate="header">

                    <div class="flex align-items-center gap-2" >
                        <span class="font-bold white-space-nowrap m-0">
                                    {{ widgetEntry.label }}
                                </span>
                    </div>

                </ng-template>
            </p-tabPanel>
        </ng-container>
    </p-tabView>

    <ng-container *ngIf="activeWidget">

        <ng-container *ngIf="activeWidget.id == 'pm.PersonType'">
            <mvs-widget *ngIf="pmPersonTypeWidget"
                        [widgetData]="pmPersonTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'pm.PersonRelationType'">
            <mvs-widget *ngIf="pmPersonRelationTypeWidget"
                        [widgetData]="pmPersonRelationTypeWidget"
                        (onObjectSelect)="handleTemplateSelect($event)"
            >
            </mvs-widget>

            <mvs-widget *ngIf="pmPersonRelationTypeReverseWidget"
                        [widgetData]="pmPersonRelationTypeReverseWidget"
            >
            </mvs-widget>
        </ng-container>

        <ng-container *ngIf="activeWidget.id == 'pm.EmploymentStatus'">
            <mvs-widget *ngIf="pmEmploymentStatusWidget"
                        [widgetData]="pmEmploymentStatusWidget"
                        (onObjectSelect)="handleTemplateSelect($event)">
            </mvs-widget>
        </ng-container>

    </ng-container>

</div>
