<form [formGroup]="widgetUiField">
    <div [formGroupName]="'uiConfigData'">
        <div class="grid">
            <div class="col-12">
                <h4>Navigation Config</h4>
            </div>

            <div class="col-6">
                <label for="objectType">Object Type</label>
                <mvs-object-type id="objectType"
                                 [objectTypeAlias]="widgetUiField.get('uiConfigData').get('objectType').value"
                                 (objectTypeChange)="handleObjectType($event)"></mvs-object-type>
            </div>

            <div class="col-5">
                <div class="flex flex-column">
                    <label for="objectId">Object ID</label>
                    <p-dropdown
                            [options]="objectIdOptions"
                            formControlName="objectId"
                            id="objectId"
                            appendTo="body"
                            class="w-full"
                            styleClass="w-full"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Select Object ID"
                    >
                    </p-dropdown>
                </div>
            </div>

            <div class="col-3">
                <div class="flex flex-column">
                    <label for="location">Location</label>
                    <p-dropdown
                            [options]="locationList"
                            id="location"
                            formControlName="location"
                            class="w-full"
                            styleClass="w-full"
                            placeholder="Select Location"
                            appendTo="body"
                    >
                    </p-dropdown>
                </div>
            </div>

            <div class="col-3">
                <div class="flex flex-column">
                    <label for="componentType">Component Type</label>
                    <p-dropdown
                            [options]="componentTypeList"
                            id="componentType"
                            formControlName="componentType"
                            class="w-full"
                            styleClass="w-full"
                            placeholder="Select Component"
                            appendTo="body"
                    >
                    </p-dropdown>
                </div>
            </div>

        </div>
    </div>
</form>
