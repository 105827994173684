<ng-container *ngIf="initialized && combinedFormArray">
<!--    <p-button (click)="askForAdditionalFormerContractStep($event)" label="TEST"></p-button>-->
    <ng-container *ngIf="objectIdentifier">
        <div class="flex justify-content-end">
            <p-button
                    styleClass="p-ripple p-element p-button p-component p-button-icon-only p-button-rounded p-button-text"
                    icon="fa fa-plus"
                    pTooltip="Neuen Vertrag erfassen"
                    tooltipPosition="left"
                    (click)="createNewContract()"
            ></p-button>
        </div>
    </ng-container>
    <mvs-combined-forms
            [forms]="combinedFormArray"
            [stickyScroll]="usedIn"
            (onObjectChange)="handleObjectChange($event)"
            (onObjectChangeForm)="handleObjectChangeForm($event)">
<!--        <ng-container>-->

<!--            <mvs-ns-notification-types-for-object-->
<!--                    *ngIf="isAllComplete && variation == 'Fremd'"-->
<!--                    [objectIdentifier]="objectIdentifier"-->
<!--                    [mode]="1"-->
<!--                    class="mvs-widget block"-->
<!--            ></mvs-ns-notification-types-for-object>-->
<!--        </ng-container>-->

        <ng-container *ngIf="objectIdentifier">
            <div class="flex justify-content-between p-5">
                <p-button label="Neuen Vertrag erfassen" (click)="createNewContract()"></p-button>
                <p-button label="Zum Vertrag" (click)="navigateToContract()"></p-button>
            </div>
        </ng-container>
    </mvs-combined-forms>


</ng-container>