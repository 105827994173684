import {Component, AfterViewInit, Renderer2, OnInit, OnDestroy} from '@angular/core';
import { MenuService } from './app.menu.service';
import {MenuItem, PrimeNGConfig} from 'primeng/api';
import { AppComponent } from './app.component';
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, Subscription} from "rxjs";
import {PageBehaviorInterface} from "@kvers/alpha-core-common";
import {PageService} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {NavigationItem} from "@kvers/alpha-core-common";

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    styleUrls: ['./app.main.component.scss']
})
export class AppMainComponent implements AfterViewInit, OnInit, OnDestroy {

    topbarMenuActive: boolean;

    menuActive: boolean;

    staticMenuDesktopInactive: boolean;

    mobileMenuActive: boolean;

    menuClick: boolean;

    mobileTopbarActive: boolean;

    topbarRightClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: string;

    documentClickListener: () => void;

    configActive: boolean;

    configClick: boolean;

    rightMenuActive: boolean;

    menuHoverActive = false;

    searchClick = false;

    search = false;

    currentInlineMenuKey: string;

    inlineMenuActive: any[] = [];

    inlineMenuClick: boolean;

    isConfigRoute: boolean = false;
    showTopBar: boolean = false;

    routerSubscription: Subscription;
    showBreadcrumb: boolean;
    pageChangeSubscription: Subscription;
    navigationObjectSubscription: Subscription;
    navigationOverlaySubscription: Subscription;
    navigationObject: BehaviorSubject<MvsObjectNavigationEntry>;
    currentPage: PageComponent;
    currentObject: ObjectIdentifier;
    selectedNavItem: string;
    navigationItems: NavigationItem[];
    currentOverlayObject: MvsObjectNavigationEntry;
    breadcrumbItems: MenuItem[];
    home = { icon: 'pi pi-home', routerLink: '/' };

    constructor(
        public renderer: Renderer2,
        private menuService: MenuService,
        private primengConfig: PrimeNGConfig,
        public app: AppComponent,
        public router: Router,
        protected navigationService: MvsObjectNavigationService,
        private activatedRoute: ActivatedRoute,
        public pageService: PageService
        ) {
    }

    ngOnInit() {

        this.menuActive = this.isStatic() && !this.isMobile();

        //check for breadcrumb visibility
        this.subscribePageChange();
        this.subscribePageNavigation();
    }

    ngAfterViewInit() {
        // hides the horizontal submenus or top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', () => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
                this.menuService.reset();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            if (!this.menuClick) {
                if (this.mobileMenuActive) {
                    this.mobileMenuActive = false;
                }

                if (this.isOverlay()) {
                    this.menuActive = false;
                }

                this.menuHoverActive = false;
                this.unblockBodyScroll();
            }

            if (!this.searchClick) {
                this.search = false;
            }

            if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
                this.inlineMenuActive[this.currentInlineMenuKey] = false;
            }

            this.inlineMenuClick = false;
            this.searchClick = false;
            this.configClick = false;
            this.topbarItemClick = false;
            this.topbarRightClick = false;
            this.menuClick = false;
        });
    }


    onMenuButtonClick(event) {
        this.menuActive = !this.menuActive;
        this.topbarMenuActive = false;
        this.topbarRightClick = true;
        this.menuClick = true;

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.mobileMenuActive = !this.mobileMenuActive;
            if (this.mobileMenuActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onTopbarMobileButtonClick(event) {
        this.mobileTopbarActive = !this.mobileTopbarActive;
        event.preventDefault();
    }

    onRightMenuButtonClick(event) {
        this.rightMenuActive = !this.rightMenuActive;
        event.preventDefault();
    }

    onMenuClick(event: NavigationItem) {

        if (event.customFunction){
            event.customFunction();
            return;
        }

        if (this.selectedNavItem == event.label) {
            //same nav item is selected

            if (event.toggleable == false) {
                //same nav item is selected and nav item can not be toggled if we click again so we do nothing
                return;
            }

            // otherwise nav item can be toggled
            this.selectedNavItem = null;

            const queryParams = { ...this.activatedRoute.snapshot.queryParams };
            delete queryParams['navItem'];
            this.removeNavItemParam(queryParams);
            return;
        }

        // different nav item is selected

        this.selectedNavItem = event.label;

        const queryParams = {};
        queryParams['navItem'] =  this.selectedNavItem;
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        });
    }

    removeNavItemParam(queryParams: object) {
        this.router.navigate([], { queryParams: queryParams });
    }

    onSearchKeydown(event) {
        if (event.keyCode === 27) {
            this.search = false;
        }
    }

    onInlineMenuClick(event, key) {
        if (key !== this.currentInlineMenuKey) {
            this.inlineMenuActive[this.currentInlineMenuKey] = false;
        }

        this.inlineMenuActive[key] = !this.inlineMenuActive[key];
        this.currentInlineMenuKey = key;
        this.inlineMenuClick = true;
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        }
        else {
            this.activeTopbarItem = item;
        }

        if (item === 'search') {
            this.search = !this.search;
            this.searchClick = !this.searchClick;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onRTLChange(event) {
        this.app.isRTL = event.checked;
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isStatic() {
        return this.app.menuMode === 'static';
    }

    isHorizontal() {
        return this.app.menuMode === 'horizontal';
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    checkConfigRoute(component: PageComponent): boolean {

        if (component['isConfigurationPage']) {
            const pageBehavior = <PageBehaviorInterface>component;
            return pageBehavior.isConfigurationPage();
        } else {
            return false;
        }

    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
       if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        this.isConfigRoute = false;

       if (this.pageChangeSubscription) {
           this.pageChangeSubscription.unsubscribe();
       }

       if (this.navigationObjectSubscription) {
            this.navigationObjectSubscription.unsubscribe();
       }

       if (this.navigationOverlaySubscription) {
            this.navigationOverlaySubscription.unsubscribe();
       }
    }

    private isBreadcrumbVisible(component: PageComponent): boolean {

        if (component['isBreadcrumbVisible']) {
            const pageBehavior = <PageBehaviorInterface>component;
            return pageBehavior.isBreadcrumbVisible();
        } else {
            return true;
        }
    }

    subscribePageChange() {
        this.pageChangeSubscription = this.pageService.pageChange.subscribe((page: PageComponent) => {
            // Handle the breadcrumb visibility as provided by the respective component
            if (!page['isOverlay']) {
                this.currentPage = page;
            }
            this.navigationItems = null;
            this.pageService.breadcrumbItems = null;
            this.showBreadcrumb = this.isBreadcrumbVisible(page);
            this.isConfigRoute = this.checkConfigRoute(page);
            this.handleNavigationItems(page);
            this.handleBreadcrumbItems(page);
            this.showTopBar = this.checkTopBarVisibility(page);

        });
    }

    handleNavigationItems(page: PageComponent) {
        this.navigationItems = this.checkNavComponent(page);

        if (this.navigationItems) {
            //there is a possibility that we have a navigation item and it is pre-selected in the route
            const currentRoute = page['route'].queryParams['value'];
            const initialValue = this.getNavItemInitialValue();

            if (initialValue && !currentRoute['navItem']) {
                this.selectedNavItem = initialValue;
            } else if (currentRoute['navItem']) {
                this.selectedNavItem = currentRoute['navItem'];
            } else {
                this.onMenuClick(this.navigationItems[0]);
                this.selectedNavItem = null;
            }

        }
    }

    handleBreadcrumbItems(page: PageComponent) {
        this.breadcrumbItems = this.pageService.breadcrumbItems;
    }

    private checkTopBarVisibility(component: PageComponent): boolean {

        if (component['isTopBarVisible']) {
            const pageBehavior = <PageBehaviorInterface>component;
            return pageBehavior.isTopBarVisible();
        } else {
            return null;
        }
    }

    getNavItemInitialValue() {
        const value = this.navigationItems.find(item => item.default == true);
        if (value) {
            return value.label;
        }
        return null;
    }

    private checkNavComponent(component: PageComponent): NavigationItem[] {

        if (component['isNavComponent']) {
            const pageBehavior = <PageBehaviorInterface>component;
            return pageBehavior.isNavComponent();
        } else {
            return null;
        }
    }

    private checkBreadcrumbItems(component: PageComponent): MenuItem[] {

        if (component['isBreadcrumbComponent']) {
            const pageBehavior = <PageBehaviorInterface>component;
            return pageBehavior.isBreadcrumbComponent();
        } else {
            return null;
        }
    }

    subscribePageNavigation() {
        this.navigationObject = this.navigationService.getNavigationBehaviourSubject('main');

        // react on navigation node change
        this.navigationObjectSubscription = this.navigationObject.subscribe(navigationObject => {
            this.refreshObject(navigationObject);
        });

        //page can also be loaded in overlay, so we need to check it as well for navigation items
        this.navigationOverlaySubscription = this.navigationService.navigationObjectOverlay.subscribe(navigationObject => {
            this.refreshOverlayObject(navigationObject[navigationObject?.length - 1]);
        })
    }

    refreshOverlayObject(navObject: MvsObjectNavigationEntry) {

        if (navObject) {
            this.currentOverlayObject = navObject;
        } else if (!navObject && this.currentOverlayObject) {
            this.navigationItems = null;
            this.currentOverlayObject = null;
            if (this.currentPage) {
                this.handleNavigationItems(this.currentPage);
            }
        }
    }

    refreshObject(navObject: MvsObjectNavigationEntry) {

        if (navObject) {
            this.currentObject = navObject.objectIdentifier;

        } else {
            // navigation object closed
            this.currentObject = null;

        }
    }

}
