import {DtoDetailConfigurable} from "@kvers/alpha-core-common";
import {AgentDto} from "@kvers/alpha-ui/lib/am/dto/agent.dto";

export class ReportAgentFavoriteDto extends DtoDetailConfigurable{


    public agentDto? : AgentDto;
    public agentDtoId : number;
    public agentDtoName : string;
    public name: string;
    public orderPriority: number;
    public module: string;
    public categoryId: number;
    public reportId: number;

}