import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    MvsFormFieldOutputBaseComponent,
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
} from "@kvers/alpha-ui";
import {MvsLinkReportOutputFieldInterface} from "./interface/mvs-link-report-output-field.interface";
import {FilterCriteria, MvsCoreService, ObjectTypeService} from "@kvers/alpha-core-common";
import {FormControl} from "@angular/forms";
import {ReportLinkage} from "./mvs-link-report-output-field-config/mvs-link-report-output-field-config.component";
import {ParamRequest} from "@kvers/alpha-ui/lib/navigation/mvs-object-navigation-entry-param.interface";

@Component({
    selector: 'mvs-link-report-output-field-component',
    templateUrl: './mvs-link-report-output-field.component.html',
})
export class MvsLinkReportOutputFieldComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy, MvsLinkReportOutputFieldInterface {


    module: string;
    category: number;
    report: number;
    reportLinkage: ReportLinkage[];
    location: string;
    filterCriteria: FilterCriteria[];

    busy: boolean;
    initialized: boolean;


    constructor(protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService,
                protected coreService: MvsCoreService,) {
        super(navigationService, objectService, coreService);
    }


    ngOnInit(): void {
        this.initComponent();
    }


    /**
     * Initialize Component.
     */
    initComponent() {

        this.module = this.formField['uiConfigData']['module'];
        this.category = this.formField['uiConfigData']['category'];
        this.report = this.formField['uiConfigData']['report'];
        this.location = this.formField['uiConfigData']['location'];
        this.reportLinkage = this.formField['uiConfigData']['reportLinkage'];

        if (this.reportLinkage?.length) {
            if (!this.filterCriteria) {
                this.filterCriteria = []
            }
            for (let reportLinkageElement of this.reportLinkage) {
                const filter = FilterCriteria.create(reportLinkageElement.toReportField, reportLinkageElement.operator, this.dto[reportLinkageElement.thisReportField]);
                this.filterCriteria.push(filter);
            }
        }

        this.refreshComponent();

    }

    onValueClick(event: MouseEvent) {
        event.stopPropagation();

        const reportParams: ParamRequest = {
            module: this.module,
            report: this.report,
            filters: this.filterCriteria
        };


        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('rp.Report', this.report, null, "Object", null, null, MvsObjectNavigationActionEnum.any, 'regular', reportParams);
        if (this.location == 'newTab') {
            this.navigationService.openObjectInNewTab(mvsObjectNavigationEntry);
        } else {
            this.navigationService.navigateTo(mvsObjectNavigationEntry, this.location);
        }

    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
