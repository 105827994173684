<div class="mt-5">
    @if (objectIdentifier) {
        @if (navigationComponent == 'Calculation-Logic-Fields') {
            <lg-calculation-logic-type-page-component
                    [objectIdentifier]="objectIdentifier"
            ></lg-calculation-logic-type-page-component>
        }

        @if (navigationComponent == 'TestCases') {
            <mvs-lg-calculation-test-case
                    [calculationLogicTypeId]="objectIdentifier.objectId"
            ></mvs-lg-calculation-test-case>
        }


        @if (navigationComponent == 'Info') {
            <mvs-widget
                    *ngIf="calculationLogicTypeWidgetData"
                    [widgetData]="calculationLogicTypeWidgetData"
            ></mvs-widget>
        }
    }
</div>
