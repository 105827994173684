<ng-container *ngIf="initialized">


    @if (size == 'M') {

        <div (click)="handleClick()" class="pointer" *ngIf="dto">
            <mvs-inline-component
                    [objectType]="'cm.Contract'"
                    [name]="dto.contractTypeDtoName"
                    [startDate]="dto.startDate"
                    [endDate]="dto.endDate"
                    [status]="dto.baseStatusEnumName"
                    [statusIcon]="dto.baseStatusEnumImage"
                    [statusColor]="dto.baseStatusEnumColor"
                    [statusColorBackground]="dto.baseStatusEnumColorBackground"
                    [buttons]="toolbarButtons"
                    (onButtonClick)="handleButtonClick($event)">
            </mvs-inline-component>
        </div>
    }


    <!--    [uiIcon]="ticketObjectRoleDto?.image"-->
    <!--    [uiName]="ticketObjectRoleDto?.name"-->
    <!--    [uiColor]="ticketObjectRoleDto?.rgbaColor"-->
    <!--    [uiColorBackground]="ticketObjectRoleDto?.rgbaColorBackground"-->

    <!--    <ng-container *ngIf="ticketObjectRoleDto">-->
    <!--        uiIcon: {{ticketObjectRoleDto?.image}}-->
    <!--        uiName: {{ticketObjectRoleDto?.name}}-->
    <!--        uiColor: {{ticketObjectRoleDto?.rgbaColor}}-->
    <!--        uiColorBackground: {{ticketObjectRoleDto?.rgbaColorBackground}}-->
    <!--    </ng-container>-->

</ng-container>