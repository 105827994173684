<div *ngIf="initialized">
    <mvs-config-header header="Vertragserweitung - Versicherungen" [categoryTypeMode]="'contractInsurance'"
                       icon="fa-sharp fa-thin fa-calendars">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Typen" leftIcon="fa-thin fa-ballot-check"></p-tabPanel>
        <p-tabPanel header="Kontaktypen Verfügbarkeit" leftIcon="fa-thin fa-layer-group"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetContactTypeObjectTypeWidget"
                        [widgetData]="widgetContactTypeObjectTypeWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==0">

        <div class="grid">
            <div class="col-6">
                <mvs-widget
                        *ngIf="widgetContactType"
                        [widgetData]="widgetContactType"
                        (onObjectSelect)="handleSelectInsurableObjectType($event)"
                ></mvs-widget>
            </div>

            <div class="col-6">
                <div class="mvs-widget">


                <div *ngIf="selectedContactType" class="py-2">
<!--                    <h4 class="text-lg font-medium">Hierarchie</h4>-->

                    <p-breadcrumb class="max-w-full" [model]="items" *ngIf="items && items.length && showBreadCrumb">
                        <ng-template pTemplate="item" let-item>
                            <ng-container>
                                <a class="p-menuitem-link">
                                     <mvs-form-control-output-image value="{{item.icon}}" imgHeight="32px"></mvs-form-control-output-image>
                                    <span class="font-semibold {{item.iconClass}}">{{ item.label }}</span>
                                </a>
                            </ng-container>
                        </ng-template>
                    </p-breadcrumb>
                    <core-gt-meta-info-component
                            *ngIf="selectedContactType.data['parentDtoId']"
                            objectTypeAlias="cd.ContactType"
                            [genericObjectTypeId]="selectedContactType.data['parentDtoId']"
                    ></core-gt-meta-info-component>
                </div>

                <div *ngIf="selectedContactType" class="py-2">
                    <core-gt-type-name-update-component
                            objectTypeAlias="cd.ContactType"
                            [genericObjectTypeId]="selectedContactType.objectId"
                    ></core-gt-type-name-update-component>
                </div>

                    <mvs-widget
                            *ngIf="widgetContactTypeField"
                            [widgetData]="widgetContactTypeField"
                            [importObjectContext]="importContextContactType"
                    ></mvs-widget>

                    <mvs-widget
                            *ngIf="widgetContactWidget"
                            [widgetData]="widgetContactWidget"
                            [importObjectContext]="importContextContactType"
                    ></mvs-widget>


            </div>
            </div>

        </div>
    </ng-container>


</div>