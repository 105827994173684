import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsFormFieldDto} from "@kvers/alpha-core-common";
import {MvsNavigateToNewWindowOutputInterface} from "../interface/mvs-navigate-to-new-window-output.interface";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'mvs-ticket-count-output-config-component',
  templateUrl: './mvs-navigate-to-new-window-output-config.component.html',
})
export class MvsNavigateToNewWindowOutputConfigComponent implements OnInit, OnChanges, OnDestroy, MvsNavigateToNewWindowOutputInterface {

  objectType: string;
  objectId: number;

  @Input() formGroup: FormGroup;
  @Input() formFields: MvsFormFieldDto[];
  @Input() widgetUiField: any;


  objectIdOptions: { label: string; value: any }[] = [];
  locationList: string[] = ['left', 'right', 'bottom', 'main', 'newTab'];
  componentTypeList: string[] = ['Object', 'Form'];

  ngOnInit(): void {


    let uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
    if (!uiConfigData) {
      uiConfigData = new FormGroup({});
      this.widgetUiField.addControl('uiConfigData', uiConfigData);
    }

    // Ensure objectType control exists
    if (!uiConfigData.get('objectType')) {
      uiConfigData.addControl('objectType', new FormControl(''));
    }

    // Ensure objectId control exists
    if (!uiConfigData.get('objectId')) {
      uiConfigData.addControl('objectId', new FormControl(null));
    }

    // Ensure location control exists
    if (!uiConfigData.get('location')) {
      uiConfigData.addControl('location', new FormControl(null));
    }

    // Ensure componentType control exists
    if (!uiConfigData.get('componentType')) {
      uiConfigData.addControl('componentType', new FormControl(null));
    }


    this.objectIdOptions = this.getObjectIdOptions();

    this.initComponent()
  }

  getObjectIdOptions(): { label: string; value: any }[] {
    return this.formFields.map((field) => ({
      label: field.uiLabel,
      value: field.id,
    }));
  }

  handleObjectType(objectType: string) {

    const uiConfigData = this.widgetUiField.get('uiConfigData');

    if (uiConfigData && uiConfigData.get('objectType')) {
      uiConfigData.get('objectType')?.setValue(objectType);
    }
  }


  /**
   * Initialize Component.
   */
  initComponent() {
    this.refreshComponent();
  }

  /**
   * Refresh Component.
   */
  refreshComponent() {
  }

  /**
   * Process changes within Binding.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {

  }

  /**
   * Destroy component.
   */
  ngOnDestroy(): void {
  }
}
