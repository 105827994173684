import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {ContactTypePage} from "./page/overview/contact-type/contact-type.page";
import {
    ContactTypeObjectTypePage
} from "./page/overview/contact-type-object-type/contact-type-object-type.page";
import {BasicConfigPage} from "./page/overview/basic-config/basic-config.page";

@NgModule({
    declarations: [
        ContactTypePage,
        ContactTypeObjectTypePage,
        BasicConfigPage,

    ],
    exports: [
        ContactTypePage,
        ContactTypeObjectTypePage,
        BasicConfigPage,
    ],
    imports: [
        CoreModule
    ]
})
export class CdModule { }
