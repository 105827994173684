<div class="min-h-screen surface-ground mt-5">
    @if (objectIdentifier) {
        @if (navigationComponent == 'Logic') {
<!--            <mvs-lg-logic-object-component [objectIdentifier]="objectIdentifier"></mvs-lg-logic-object-component>-->
            <lg-logic-page-component [objectIdentifier]="objectIdentifier"></lg-logic-page-component>
        }

        @if (navigationComponent == 'TestCases') {
            <mvs-lg-test-case [logicId]="objectIdentifier.objectId"></mvs-lg-test-case>
        }
    }
</div>
