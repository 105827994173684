import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CrCustomerObjectViewTypeUiEnum} from "./data/cr-customer-object-view-type-ui.enum";
import {ActivatedRoute, Router} from "@angular/router";
import {TmStatusEnum} from "../../../../tm/enum/tm-status.enum";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";
import {CustomerDto} from "../../../dto/customer.dto";
import {CustomerService} from "../../../service/api/customer.service";
import {
    CrCustomerRelatedPersonWidgetStyle
} from "../../../component/cr-customer-related-persons/uiStyle/cr-customer-related-person-widget-style";
import {
    DtoDetail,
    DtoList,
    FilterCriteria,
    IndicatorButton,
    MvsCoreService, MvsCrudService, MvsFormControlOverwrite, MvsFormDto, MvsFormFieldAccessEnum,
    NavigationItem,
    ObjectIdentifierData,
    ObjectPageComponent,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectRequestRelation,
    ObjectRequestSimple,
    PagingDto,
    WidgetData,
    WidgetToolbarCallbackInterface,
} from "@kvers/alpha-core-common";
import {MvsObjectCount} from "../../../../core/workflow/component/mvs-workflow/mvs-workflow.component";
import {
    PmPersonRelationPersonWidgetStyle
} from "../../../../pm/component/pm-household/uiStyle/pm-person-relation-person-widget-style";
import {OverlayPanel} from "primeng/overlaypanel";
import {HouseholdPersonDto} from "../../../../pm/dto/household-person.dto";
import {ProfileCheckResultDto} from "../../../service/dto/profile-check-result.dto";
import {CustomerAssessmentResultDto} from "../../../service/dto/customer-assessment-result.dto";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {CustomerPageHouseholdPersonService} from "../../../service/ui/customer-page-household-person.service";
import {CustomerExternalAliasDto} from "../../../dto/customer-external-alias.dto";
import {UiCustomerExternalAlias} from "./data/ui-customer-external-alias";


interface ObjectCountClass {
    countBadge: number,
    objectBadgeType: string
}

@Component({
    selector: 'mvs-cr-customer-object-page',
    templateUrl: './cr-customer-object.page.html',
    styleUrls: ['./cr-customer-object.page.scss'],
    providers: [CustomerPageHouseholdPersonService],
})
export class CrCustomerObjectPage
    extends ObjectPageComponent
    implements OnInit, OnDestroy, OnChanges {

    defaultLabel: string = "Kundenansicht";
    viewType: CrCustomerObjectViewTypeUiEnum = CrCustomerObjectViewTypeUiEnum.standard;

    ticketIndicatorFilterCriteria: FilterCriteria[];
    workflowIndicatorFilterCriteria: FilterCriteria[];
    workflowIndicatorObjectRequest: ObjectRequestList;
    customerName: string;
    customerFilter: FilterCriteria[] = [];
    changedFilter: FilterCriteria;
    photoUrl: string;
    customerDto: CustomerDto;
    customerRelatedPersonWidgetStyle: CrCustomerRelatedPersonWidgetStyle;
    personRelatedPersonWidgetStyle: PmPersonRelationPersonWidgetStyle;
    selectedNavigationItem: string;
    showCreateTicketOverlay: boolean;
    progressValue: string = '60'
    householdPersons: HouseholdPersonDto[];
    profileCheckData: ProfileCheckResultDto;
    contractHealthData: CustomerAssessmentResultDto;
    customerExternalAliasWidget: WidgetData;
    uiExternalAlias: UiCustomerExternalAlias[];
    navigateViewTypes: NavigationItem[] = [
        {
            label: 'standard',
            tooltip: 'Standard',
            icon: 'fa-regular fa-house',
            toggleable: false,
            default: true
        },
        {
            label: 'contracts',
            tooltip: 'Verträge',
            icon: 'fa-regular fa-file-contract',
            toggleable: false,
        },
        {
            label: 'externalIds',
            tooltip: `External Id's`,
            icon: 'fa-regular fa-hashtag',
            toggleable: false,
        },
        {
            label: 'communication',
            tooltip: 'Kommunikation',
            icon: 'fa-regular fa-comments',
            toggleable: false,
        },
        {
            label: 'appointments',
            tooltip: `Appointments`,
            icon: 'fa-regular fa-clock',
            toggleable: false,
        },
        {
            label: 'registration',
            tooltip: 'Registrierung und Onboarding',
            icon: 'fa-regular fa-user-plus',
            toggleable: false,
        },
        // {
        //     label: 'ticketsAndWorkflows',
        //     tooltip: 'Tickets und Workflows',
        //     icon: 'fa-regular fa-ticket',
        //     toggleable: false,
        // },
        {
            label: 'interactions',
            tooltip: 'Interaktionen',
            icon: 'fa-regular fa-handshake',
            toggleable: false,
        },
        {
            label: 'additionalInformation',
            tooltip: 'Weitere Informationen',
            icon: 'fa-regular fa-info-circle',
            toggleable: false,
        },
        {
            label: 'documentUpload',
            tooltip: 'Dokument hochladen',
            icon: 'fa-regular fa-file-circle-plus',
            toggleable: false,
        },
        {
            label: 'sales',
            tooltip: 'Sales',
            icon: 'fa-regular fa-dollar-sign',
            toggleable: false,
        },
        {
            label: 'promo',
            tooltip: 'Promo',
            icon: 'fa-regular fa-rectangle-ad',
            toggleable: false,
        },
        {
            label: '',
            divider: true,
        },
        {
            label: 'genericTickets',
            tooltip: 'General Tickets',
            icon: 'fa-regular fa-ticket',
            toggleable: false,
        },
        {
            label: 'addTicket',
            tooltip: 'Add Ticket',
            icon: 'pi pi-plus',
            toggleable: true,
            customFunction: () => this.customFunction()
        },
        {
            label: 'genericWorkflows',
            tooltip: 'General Workflows',
            icon: 'fa-sharp-duotone fa-solid fa-arrow-progress',
            toggleable: false,
        },
    ];

    @ViewChild("op", {static: true}) createTicketOverlay: OverlayPanel;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected processService: WfProcessService,
        // protected topbarService: TopbarService,
        protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,
        protected customerService: CustomerService,
        protected customerPageHouseholdPersonService: CustomerPageHouseholdPersonService) {
        super(route, coreService);
    }

    ngOnInit() {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {

            if (params['navItem'] && params['navItem'] == this.selectedNavigationItem) {
                return;
            }

            if (params['navItem'] == undefined && this.selectedNavigationItem == 'addTicket') {
                this.toggleAddTicket();
                return;
            }

            if (params['navItem']) {
                const exists = this.checkNavigationEntryExist(this.navigateViewTypes, params['navItem']);
                if (exists) {
                    this.selectedNavigationItem = params['navItem'];
                    const viewType = CrCustomerObjectViewTypeUiEnum[this.selectedNavigationItem];
                    if (viewType != undefined) {
                        this.viewType = viewType;
                    }
                    this.postNavItemSelection(this.selectedNavigationItem);
                } else {
                    this.removeNavItemParam();
                }
            } else {
                this.selectedNavigationItem = this.navigateViewTypes[0].label;
                this.viewType = CrCustomerObjectViewTypeUiEnum[this.selectedNavigationItem];
                const queryParams = {};
                queryParams['navItem'] = this.selectedNavigationItem;
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: queryParams,
                    queryParamsHandling: 'merge'
                });
            }
        });

        this.coreService.getObjectService().objectChangeEvent.subscribe(res => {
            if (res.objectType == 'cr.CustomerExternalAlias') {
                this.getCustomer();
            }
        })


        super.ngOnInit();
    }

    onRouteChangeAfter() {
    }


    protected getObjectType(): string {
        return "cr.Customer";
    }

    setCoreIndicators() {
        const ticketIndicator = new IndicatorButton('tm.Ticket', 'Tickets', this.ticketIndicatorFilterCriteria, 'top-right', null);
        const workFlowIndicator = new IndicatorButton('wf.WfProcess', 'Workflows', this.workflowIndicatorFilterCriteria, 'top-right', this.workflowIndicatorObjectRequest);

        const buttons: IndicatorButton[] = [];
        buttons.push(ticketIndicator);
        buttons.push(workFlowIndicator);

        // this.topbarService.setButtons(buttons);

    }

    refreshComponent() {

        this.customerPageHouseholdPersonService.setCustomerId(this.objectIdentifier.objectId);

        // this.setMegaMenuItems();
        this.getPhoto();
        this.getCustomer();
        this.checkProfile();
        this.assessCustomer();
        this.setActivePage();
        // this.setIndicatorsData();
        // this.setCoreIndicators();
    }

    getPhoto() {
        const crudServiceCustomer: CustomerService = <CustomerService>this.coreService.getCrudService("cr.Customer");
        this.photoUrl = crudServiceCustomer.getPhotoUrl(this.objectIdentifier.objectId);
    }

    getCustomer() {

        // Marko 20.12.2024 Method was retired as the Customer Name is passed within the field calculatedName of the Customer now
        // The references of this.customerDto.personDtoName where replaced with customer.calculatedName
        const crudService = this.coreService.getCrudService(this.objectIdentifier.objectType);
        const objectRequest = new ObjectRequestList(true,
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
        );

        objectRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode('customerExternalAlias', ObjectRequestRelation.createJoin('cr.CustomerExternalAlias#customer'))
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('system'))
        );

        crudService.list(objectRequest).subscribe((value: DtoList<CustomerDto>) => {
            this.customerDto = value.entries[0];
            this.defaultLabel = this.customerDto.calculatedName; // set customer name as tab name
            this.setActiveTitle();

            if (this.customerDto.customerExternalAlias && this.customerDto.customerExternalAlias.length) {
                this.prepareUiExternalAlias();
                this.refreshCustomerExternalAliasWidget(value.form);
            } else {
                this.uiExternalAlias = [];
            }

            this.initialized = true;
        });


    }

    prepareUiExternalAlias() {
        this.uiExternalAlias = [];

        for (let item of this.customerDto.customerExternalAlias) {

            const exists = this.uiExternalAlias.find(alias => alias.systemDtoId == item.systemDtoId);

            if (exists) {
                continue;
            }

            const uiAlias = new UiCustomerExternalAlias();

            uiAlias.systemDtoId = item.systemDtoId;
            uiAlias.systemDto = item.systemDto;
            uiAlias.uiAliasList = [];

            // find entries of same system id
            const entries = this.customerDto.customerExternalAlias.filter(v => v.systemDtoId == item.systemDtoId);

            for (let entry of entries) {
                uiAlias.uiAliasList.push(entry.externalAlias);
            }

            this.uiExternalAlias.push(uiAlias);
        }


    }

    refreshCustomerExternalAliasWidget(form: MvsFormDto) {
        const dtoList = new DtoList<CustomerExternalAliasDto>();
        dtoList.entries = this.customerDto.customerExternalAlias;
        dtoList.form = form.getSubForm('customerExternalAlias');

        this.customerExternalAliasWidget = WidgetFactory.createWidgetTransient(
            'cr.customer.main.page.external.alias.basic',
            "External Alias",
            'list',
            'transient',
            'transient',
            "cr.CustomerExternalAlias",
            MvsCrudService.transformDtoListOutToIn(dtoList),
        );

        this.customerExternalAliasWidget.functionCallbacks = this.customerFunctionCallback();
    }

    customerFunctionCallback() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['customerDtoId'] = this.objectIdentifier.objectId;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    setIndicatorsData() {

        this.ticketIndicatorFilterCriteria = [
            FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
            FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved),
            FilterCriteria.create("status", FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled)

        ];

        this.workflowIndicatorObjectRequest = this.processService.getRequestForProcessForObjectViaAlias(
            this.objectIdentifier.objectType,
            this.objectIdentifier.objectId,
            true
        );
    }

    isBreadcrumbVisible(): boolean {
        return false;
    }

    handleCustomerFilter(event: FilterCriteria[]) {
        this.customerFilter = event;
    }

    onRemoveFilterCriteria(filter: FilterCriteria) {
        let index = this.customerFilter.findIndex(item => item.field === filter.field);

        if (index > -1) {
            this.changedFilter = filter;
            this.customerFilter.splice(index, 1);
        }
    }

    handleSelectedRelatedPersonsChanged(event: CrCustomerRelatedPersonWidgetStyle) {
        this.customerRelatedPersonWidgetStyle = event;
        this.customerRelatedPersonWidgetStyle = this.customerRelatedPersonWidgetStyle.clone();
    }

    handlePersonRelationPersonsChanged(event: PmPersonRelationPersonWidgetStyle) {
        this.personRelatedPersonWidgetStyle = event;
        this.personRelatedPersonWidgetStyle = this.personRelatedPersonWidgetStyle.clone();
    }

    isNavComponent(): NavigationItem[] {
        return this.navigateViewTypes;
    }

    checkNavigationEntryExist(items: NavigationItem[], label: string): boolean {
        const exists = items.find(item => item.label == label);
        if (exists) {
            return true;
        }
        return false;
    }

    removeNavItemParam(): void {
        const queryParams = {...this.route.snapshot.queryParams};
        delete queryParams['navItem'];
        this.router.navigate([], {queryParams: queryParams});
    }

    handleTicketsCount(event: MvsObjectCount) {
        const data = this.getObjectCountClass(event);
        const ticketObject = this.navigateViewTypes.find(item => item.label == 'genericTickets');
        ticketObject.badge = data.countBadge;
        ticketObject.badgeType = data.objectBadgeType;
    }

    handleWorkflowsCount(event: MvsObjectCount) {
        const data = this.getObjectCountClass(event);

        const workflowObject = this.navigateViewTypes.find(item => item.label == 'genericWorkflows');
        workflowObject.badge = data.countBadge;
        workflowObject.badgeType = data.objectBadgeType;

    }

    getObjectCountClass(event: MvsObjectCount): ObjectCountClass {
        let countBadge = 0;
        let objectBadgeType: string;

        if (!event.pendingCount && !event.completedCount) {
            countBadge = 0;
        } else if (event.completedCount && !event.pendingCount) {
            countBadge = event.completedCount;
            objectBadgeType = 'gray';
        } else if (!event.completedCount && event.pendingCount) {
            countBadge = event.pendingCount;
            objectBadgeType = 'primary';
        } else if (event.completedCount && event.pendingCount) {
            countBadge = event.pendingCount;
            objectBadgeType = 'primary';
        }

        return {countBadge: countBadge, objectBadgeType: objectBadgeType};
    }


    ngOnDestroy() {
        // this.topbarService.clearButtons();
        this.removeNavItemParam();
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    postNavItemSelection(navItem: string) {
        if (navItem == 'addTicket') {
            if (this.createTicketOverlay.overlayVisible) {
                this.createTicketOverlay.hide();
            } else {
                this.showCreateTicketOverlay = true;
                this.createTicketOverlay.toggle(event);
            }

        }
    }

    handleTicketCreation(event: ObjectIdentifierData) {
        this.createTicketOverlay.hide();
        this.showCreateTicketOverlay = false;
    }

    toggleAddTicket() {
        if (this.createTicketOverlay.overlayVisible) {
            this.createTicketOverlay.hide();
            this.selectedNavigationItem = null;
        } else {
            this.createTicketOverlay.toggle(event);
        }
    }

    handleHouseholdPersonsLoaded(event: HouseholdPersonDto[]) {
        this.householdPersons = event;
    }

    customFunction() {
        this.showCreateTicketOverlay = true;
        this.toggleAddTicket();
    }

    checkProfile() {
        if (this.customerDto) {
            this.customerService.checkProfile(this.customerDto.id).subscribe(res => {
                this.profileCheckData = res;
            });
        }

    }

    assessCustomer() {
        if (this.customerDto) {
            this.customerService.assessCustomer(this.customerDto.id).subscribe(res => {
                this.contractHealthData = res;
            });
        }
    }

    handleGetData() {

        const customerService = this.coreService.getCrudService<CustomerService>("cr.Customer");


        customerService.list(
            ObjectRequestList.createSimpleRequestList(true, ObjectRequestSimple.create(true, false, 1, "cr.CustomerType"),
                [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, 84876)], [], PagingDto.createSize(1))).subscribe(value => {

        });
    }

    openObjectDrawer(id: number) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('pm.Person', id, null, "", null, null, MvsObjectNavigationActionEnum.display);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'left');
    }
}
