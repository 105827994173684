import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {TicketService} from "../../../../service/api/ticket.service";
import {TicketActionAvailableResponseDto} from "../../../../service/api/dto/ticket-action-available-response.dto";
import {TicketActionDto} from "../../../../dto/ticket-action.dto";
import {TicketActionAvailableDto} from "../../../../service/api/dto/ticket-action-available.dto";
import {TicketActionService} from "../../../../service/api/ticket-action.service";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {forkJoin} from "rxjs";
import {TmTicketTypeActionInternalTypeEnum} from "../../../../enum/tm-ticket-type-action-internal-type.enum";
import {UiAvailableActions} from "./data/ui-available-actions";

@Component({
    selector: 'tm-ticket-select-predefine-task',
    templateUrl: './tm-ticket-select-predefined-task.component.html',
    styleUrls: ['./tm-ticket-select-predefined-task.component.scss']
})
export class TmTicketSelectPredefinedTaskComponent implements OnInit, OnChanges, OnDestroy {

    @Input() ticketId: number;
    @Input() availableActionsResponse: TicketActionAvailableResponseDto;
    @Output() onCreateAction = new EventEmitter<boolean>();
    protected readonly TmTicketTypeActionInternalTypeEnum = TmTicketTypeActionInternalTypeEnum;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    tasks = [
        {name: 'Complete Customer Onboarding', completed: false},
        {name: 'Renew Customer Contract', completed: false},
        {name: 'Change Customer Contact Data', completed: true},
        {name: 'Process name here', completed: false},
        {name: 'Process name here', completed: false},
    ];
    uiAvailableActions: UiAvailableActions[];

    constructor(protected ticketService: TicketService,
                protected ticketActionService: TicketActionService,
                protected messageService: MvsMessageService,
    ) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        for (let action of this.availableActionsResponse.availableActions) {
            action.selected = false;
        }

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.prepareUiActions();
        this.initialized = true;
    }

    prepareUiActions() {
        this.uiAvailableActions = [];

        if (!this.availableActionsResponse || !this.availableActionsResponse.availableActions?.length) {
            return;
        }

        for (let item of this.availableActionsResponse.availableActions) {

            if (item.ticketTypeAction.internalType == TmTicketTypeActionInternalTypeEnum.user_defined) {
                continue; //not interested in user defined
            }

            const exists = this.uiAvailableActions.find(value => value.actionType == item.ticketTypeAction.internalType);

            if (exists) {
                continue;
            }

            const entries = this.availableActionsResponse.availableActions.filter(c => c.ticketTypeAction.internalType == item.ticketTypeAction.internalType);

            const uiAction = new UiAvailableActions();
            uiAction.actionType = item.ticketTypeAction.internalType;
            uiAction.availableActions = entries;
            const label = TmTicketTypeActionInternalTypeEnum[uiAction.actionType];
            uiAction.label = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();

            this.uiAvailableActions.push(uiAction);
        }

        console.log(this.uiAvailableActions);


    }

    handleClickTicketAction() {
        if (this.busy) {
            return;
        }

        this.busy = true;
        const createActions = [];

        for (let action of this.availableActionsResponse.availableActions) {
            if (action.selected) {
                const dto = new TicketActionDto();
                dto.ticketDtoId = this.ticketId;
                dto.ticketTypeActionDtoId = action.ticketTypeAction.id;
                dto.ticketObjectDtoId = action.ticketObject?.id;
                createActions.push(this.ticketActionService.create(dto));
            }
        }

        if (createActions.length > 0) {
            forkJoin(createActions).subscribe(responses => {
                this.messageService.showSuccessMessage('', 'Ticket Action Created!');
                this.handleObjectCreationFinished();
                this.busy = false;
            }, error => {
                this.busy = false;
            });
        } else {
            this.busy = false;
        }
    }

    handleObjectCreationFinished() {
        this.onCreateAction.emit(true);
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    taskSelection(task: TicketActionAvailableDto) {
        task.selected = !task.selected;
    }

    onCheckboxChange(event: any, task: TicketActionAvailableDto) {
        this.taskSelection(task);
    }
}
