import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {MvsMessageService, ObjectIdentifier} from "@kvers/alpha-core-common";
import {DmDocumentAssignmentService} from "../../../dm/service/api/dm-document-assignment.service";
import {HttpResponse} from "@angular/common/http";
import {firstValueFrom, forkJoin, of} from "rxjs";
import {DmDocumentService} from "../../../dm/service/api/dm-document.service";
import Quill from './data/quill-config';
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {catchError, map} from "rxjs/operators";
import { Delta } from 'quill';

@Component({
    selector: 'mvs-quill-editor',
    templateUrl: './mvs-quill-editor.component.html',
    styleUrl: './mvs-quill-editor.component.scss'
})
export class MvsQuillEditorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() content: string = '';
    @Input() documentTypeId: number;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() iconLabel: string = 'Comment';
    @Output() onContentChange = new EventEmitter<string>();
    @Output() onSave = new EventEmitter<string>();
    allowedColors = ['#ff0000', '#00ff00', '#0000ff', '#000000'];

    quill!: Quill;
    recognition: SpeechRecognition | null = null; // Speech recognition instance
    lastTranscript: string = ''; // To track the last processed speech transcript

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(protected documentAssignmentService: DmDocumentAssignmentService,
                protected messageService: MvsMessageService,
                protected documentService: DmDocumentService,
                protected navigationService: MvsObjectNavigationService,
    ) {
    }

    ngOnInit(): void {
        this.initializeQuill();
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    initializeQuill() {

        const updateContentCallback = (updatedContent: string) => {
            this.content = updatedContent; // Update the Angular-bound content variable
        };

        this.quill = new Quill('#editor-container', {
            theme: 'snow',
            modules: {
                imageResize: {
                    updateContent: updateContentCallback, // Pass the callback
                },
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],       // Lists
                    // [{'indent': '-1'}, {'indent': '+1'}],            // Indentation
                    // [{'align': []}],                                 // Alignment
                    [{'color': this.allowedColors}, {'background': this.allowedColors}],
                    ['image'],
                ],

            },
        });

        // editor.on('text-change', function (delta, oldDelta, source) {  });

        this.quill?.root.addEventListener('paste', (event: ClipboardEvent) => {
            this.handlePaste(event);
        });

        this.quill?.root.addEventListener('drop', (event: DragEvent) => {
            this.handleDrop(event);
        });

        this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            const sanitizedDelta = new Delta();

            if (!delta || !delta.ops) {
                return sanitizedDelta; // Return empty delta if invalid
            }

            delta.ops?.forEach(op => {
                if (op.insert) {
                    // Check if the inserted content is a string
                    if (typeof op.insert === 'string') {
                        // Allow plain text
                        const attributesToInsert: any = {};

                        // Check for formats and only keep allowed ones
                        const allowedFormats = ['bold', 'italic', 'underline', 'strike', 'list', 'indent', 'color', 'background'];
                        const formats = Object.keys(op.attributes || {});
                        const allowedAttributes = formats.filter(format => allowedFormats.includes(format));

                        // If the attributes are allowed, prepare them for insertion
                        allowedAttributes.forEach(format => {
                            attributesToInsert[format] = op.attributes[format];
                        });

                        // Check color and background color against allowed colors
                        if (attributesToInsert.color && !this.allowedColors.includes(attributesToInsert.color)) {
                            delete attributesToInsert.color; // Remove disallowed color
                        }
                        if (attributesToInsert.background && !this.allowedColors.includes(attributesToInsert.background)) {
                            delete attributesToInsert.background; // Remove disallowed background color
                        }

                        // Insert the text with the allowed attributes
                        sanitizedDelta.insert(op.insert, attributesToInsert);
                    } else if (op.insert.image) {
                        // Allow images if needed
                        sanitizedDelta.insert(op.insert);
                    }
                }
            });

            // Return the sanitized delta, but only if it has content
            return sanitizedDelta['ops']?.length > 0 ? sanitizedDelta : new Delta(); // Prevent duplicate entries
        });

        this.quill.on('text-change', (delta, oldDelta, source) => {
            const images = this.quill.root.querySelectorAll('img');
            images.forEach(img => {
                if (img.src.includes('data:image/png;base64')) {
                    img.remove()
                }
            });
            this.content = this.quill?.root.innerHTML;
            this.onContentChange.emit(this.content);
        });

        this.quill.root.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;

            if (target.tagName === 'A') {
                event.preventDefault(); // Prevent default navigation
                const href = target.getAttribute('href');
                this.handleLinkInteraction(href);
            }
        });
    }

    handleLinkInteraction(url: any) {
        const queryString = url.split('?')[1] || url.split('&')[1];
        const params = new URLSearchParams(queryString);

        // Retrieve the documentId
        const documentId = +params.get('documentId');

        if (!documentId) {
            return;
        }

        // open document in left sidebar
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(
            "dm.DmDocument",
            documentId,
            'object',
            "Dokument",
            null,
            null,
            MvsObjectNavigationActionEnum.any,
            'light');

        this.navigationService.navigateTo(mvsObjectNavigationEntry, "left");


    }

    handlePaste(event: ClipboardEvent) {
        const clipboardData = event.clipboardData;
        if (!clipboardData) {
            return;
        }

        const items = clipboardData.items;

        if (!this.documentTypeId || !this.objectIdentifier) {
            console.error('No document type or identifier provided');
            this.checkForBase64Images();
            return;
        }

        for (const item of items) {
            if (item.type.startsWith('image/')) {
                const file = item.getAsFile();
                if (file) {
                    // Upload the pasted image
                    this.uploadImage(file);
                }
            } else if (item.type === 'application/pdf') {
                const file = item.getAsFile();
                if (file) {
                    // Upload the pasted PDF
                    this.uploadDocument(file);
                }
            }
            event.preventDefault();
            event.stopPropagation();
        }
    }

    async uploadDocument(file: File) {

        if (this.busy) {
            return;
        }

        this.busy = true;

        this.uploadFile(file, 'pdf');
    }

    handleDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        const dataTransfer = event.dataTransfer;
        if (!dataTransfer || !dataTransfer.files.length) {
            return;
        }

        const fileImg = Array.from(dataTransfer.files).find((file) =>
            file.type.startsWith('image/')
        );

        const fileDoc = Array.from(dataTransfer.files).find((file) =>
            file.type.startsWith('application/pdf')
        );

        if (fileImg) {
            this.uploadImage(fileImg);
        } else if (fileDoc) {
            this.uploadDocument(fileDoc);
        }
    }

    async insertDocumentIntoEditor(documentId: number, type: 'image' | 'pdf', filename?: string) {
        try {
            if (this.busy) {
                return;
            }

            const range = this.quill?.getSelection() || {index: 0};

            if (type === 'image') {

                let fileUrl = await firstValueFrom(this.documentService.downloadViaUrl(documentId));
                fileUrl = `${fileUrl}#view=FitH&navpanes=0&documentId=${documentId}`;
                this.quill?.insertEmbed(range.index, 'image', fileUrl);
                this.checkForBase64Images();

            } else if (type === 'pdf') {
                let fileUrl = `&documentId=${documentId}`;


                this.quill?.insertEmbed(range.index, 'pdf', {
                    href: fileUrl,
                    text: filename ? filename : 'View Document',
                    style: 'color: blue; text-decoration: underline;'
                });
            }

        } catch (error) {
            console.error('Error inserting document into the editor:', error);
        }
    }

    async uploadImage(file: File) {
        if (this.busy) {
            return;
        }

        this.busy = true;

        this.uploadFile(file, 'image');
    }

    uploadFile(file: File, type: 'image' | 'pdf') {
        this.documentAssignmentService.uploadViaAlias(file, this.documentTypeId, this.objectIdentifier.objectType, this.objectIdentifier.objectId).subscribe({
            next: async (event: any) => {
                if (event instanceof HttpResponse) {
                    this.busy = false;

                    const documentId = event.body.documentDtoId;
                    await this.insertDocumentIntoEditor(documentId, type, file.name);
                }

            },
            error: (err: any) => {
                this.messageService.showErrorMessage('Error', err);
                this.busy = false;
            }
        });
        ;
    }

    // checkForBase64Images() {
    //     const editorContents = this.quill?.getContents();
    //     if (!editorContents) {
    //         return;
    //     }
    //
    //     const ops = editorContents.ops || [];
    //     ops.forEach((op: any, index: number) => {
    //         if (op.insert && op.insert.image && op.insert.image.startsWith('data:image/')) {
    //             // Remove the base64 image
    //             this.quill?.deleteText(index, 1);
    //         }
    //     });
    // }

    checkForBase64Images() {
        const editorContents = this.quill?.getContents();
        if (!editorContents) {
            return;
        }

        const ops = editorContents.ops || [];
        const indicesToDelete: number[] = []; // Collect indices of base64 images

        ops.forEach((op: any, index: number) => {
            if (op.insert && op.insert.image && op.insert.image.startsWith('data:image/')) {
                // Collect the index of the base64 image
                indicesToDelete.push(index);
            }
        });

        // Delete images in reverse order to avoid index shifting
        for (let i = indicesToDelete.length - 1; i >= 0; i--) {
            const index = indicesToDelete[i];
            this.quill?.deleteText(index, 1);
        }
    }

    // Start Speech-to-Text
    startSpeechToText(): void {
        const SpeechRecognition =
            (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

        if (!SpeechRecognition) {
            console.error('Speech recognition is not supported in this browser.');
            return;
        }

        this.recognition = new SpeechRecognition();
        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onresult = (event: SpeechRecognitionEvent) => {
            const transcript = Array.from(event.results)
                .map((result) => result[0].transcript)
                .join('');

            const newText = transcript.replace(this.lastTranscript, '');
            if (newText.trim()) {
                const range = this.quill.getSelection();
                if (range) {
                    this.quill.insertText(range.index, newText);
                } else {
                    this.quill.insertText(this.quill.getLength() - 1, newText);
                }
                this.lastTranscript = transcript;
            }
        };

        this.recognition.onerror = (event) => {
            console.error('Speech recognition error:', event.error);
        };

        this.recognition.start();
        console.log('Speech recognition started.');
    }

    // Stop Speech-to-Text
    stopSpeechToText(): void {
        if (this.recognition) {
            this.recognition.stop();
            this.recognition = null;
            console.log('Speech recognition stopped.');
        }
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        if (this.content) {
            this.loadContent(this.content);
        }
        this.initialized = true;
    }

    saveContent(): void {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.content, 'text/html');
        const images = doc.querySelectorAll('img');

        images.forEach((img) => {
            const src = img.getAttribute('src');
            if (src && src.startsWith('blob:')) {
                const match = src.match(/documentId=([^&]+)/);
                if (match) {
                    const documentId = match[1];
                    img.setAttribute('src', `documentId:${documentId}`);
                }
            }
        });

        const updatedContent = doc.body.innerHTML;
        this.onSave.emit(updatedContent);
        this.content = '';
        this.quill.clipboard.dangerouslyPasteHTML(this.content);

    }

    loadContent(content: string): void {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const images = doc.querySelectorAll('img');

        const imageObservables = Array.from(images).map((img) => {
            const src = img.getAttribute('src');
            if (src && src.startsWith('documentId:')) {
                const documentId = +src.split(':')[1];
                return this.documentService.downloadViaUrl(documentId).pipe(
                    map((blobUrl) => {
                        const enhancedUrl = `${blobUrl}#view=FitH&navpanes=0&documentId=${documentId}`;
                        return {img, blobUrl: enhancedUrl};
                    }),
                    catchError((error) => {
                        console.error(`Error downloading documentId ${documentId}:`, error);
                        return of({img, blobUrl: null});
                    })
                );
            }
            return of(null);
        });

        if (imageObservables.length) {
            forkJoin(imageObservables).subscribe((results) => {
                results.forEach((result) => {
                    if (result && result.blobUrl) {
                        result.img.setAttribute('src', result.blobUrl); // Update the image src with enhanced URL
                        const style = result.img.getAttribute('style');

                        if (style) {
                            // const style = `width: ${width}px; height: ${height}px;`
                            result.img.setAttribute('style', style); // Update the image src with enhanced URL
                        }


                    }
                });
                const cleanedContent = this.cleanAnchorText(doc.body.innerHTML);

                // Update the content in the editor
                this.quill.clipboard.dangerouslyPasteHTML(cleanedContent);
                this.content = cleanedContent;
            });
        } else {
            const cleanedContent = this.cleanAnchorText(doc.body.innerHTML);

            this.quill.clipboard.dangerouslyPasteHTML(cleanedContent);
            this.content = cleanedContent;
        }
    }

    cleanAnchorText(content: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const anchors = doc.querySelectorAll('a');

        anchors.forEach((anchor) => {
            // Ensure only one unique inner text
            anchor.textContent = anchor.textContent.trim();
            const uniqueText = anchor.textContent?.split(' ')[0]; // Take the first occurrence
            anchor.textContent = uniqueText || ''; // Reset anchor's inner text
        });

        return doc.body.innerHTML;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["content"]) {
            this.refreshComponent();
        }
    }

    buttonDisabled() {
        if (this.content == undefined || this.content.trim() == '' || this.content == '<p><br></p>') {
            return true;
        }
        return false;
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
