<ng-container *ngIf="initialized">
    <mvs-crud-object-generic
            [objectType]="'tm.Ticket'"
            [objectId]="ticketId"
            [defaultCreateDto]="createDefaultDto"
            [formControlOverwrite]="formControlOverwrite"
            [mandatoryDefaultValues]="true"
            [disabled]="ticketActionsList?.length > 0"
            (onChangedObject)="handleObjectChange($event)"
    ></mvs-crud-object-generic>

    <p-messages
            *ngIf="availableActionsResponse && ticketActionsList?.length > 0"
            severity="error">
        <ng-template pTemplate>
            <div class="w-full flex justify-content-between align-items-center">
                <div class="flex gap-3 align-items-center">
                    <i class="pi pi-times-circle text-2xl"></i>
                    <div class="flex gap-1 align-items-center">
                        <div class="font-medium text-lg">Fehler</div>
                        <span>Folgende Aktionen müssen noch abgeschlossen werden</span>
                    </div>
                </div>
<!--                <p-button styleClass="p-button-text" label="Active Ticket"-->
<!--                          icon="fa-regular fa-ticket"></p-button>-->
            </div>
        </ng-template>
    </p-messages>

    <tm-ticket-actions
            *ngIf="availableActionsResponse && ticketActionsList?.length > 0"
            class="mt-3 block"
            [addActions]="false"
            [ticketId]="ticketId"
            [ticketActionsList]="ticketActionsList"
            [processMode]="false"
            [availableActionsResponse]="availableActionsResponse"
            (onAddTicketAction)="handleAddTicketAction($event)"
            (onInitiateProcess)="handleInitiateProcess($event)"
            (onActionStatusChange)="handleStatusChange($event)"
    >
    </tm-ticket-actions>
</ng-container>