@if (initialized) {

    @if (householdWidget) {
        <mvs-widget [widgetData]="householdWidget"></mvs-widget>
    }

    @if (householdPersonsWidget) {
        <mvs-widget [widgetData]="householdPersonsWidget"></mvs-widget>
    }

}