import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,ViewEncapsulation } from '@angular/core';
import {MvsFormFieldBaseComponent} from "@kvers/alpha-ui";


@Component({
    selector: 'mvs-form-field-tiny-mce-editor',
    template: `
        <div [formGroup]="formGroup">
            <ng-container class="block mb-3">
                <editor
                        [init]="tinyMceConfig"
                        [formControlName]="formField.id"
                ></editor>
            </ng-container>
        </div>
    `
})
export class MvsFormFieldTinyMceEditorComponent
    extends MvsFormFieldBaseComponent
    implements OnInit, OnDestroy, OnChanges {

    tinyMceConfig: object = {
        base_url: '/assets/plugin/js/tinymce',
        suffix: '.min',
        menubar: true,
        branding: false,
        height: '67vh',
        inline: false,
        paste_block_drop: true,
        valid_elements: '*[*]',  // Allows all elements and attributes
        extended_valid_elements: 'img[class|src|border=0|alt|title|hspace|vspace|width|height|align|name|style|span[data-info]]',

        plugins: 'lists link image table code help wordcount emoticons',
        toolbar: 'copy undo redo formatselect | bold italic strikethrough codesample forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat hr pagebreak code | emoticons',
        table_toolbar: 'tableprops tabledelete | tableConditions'
    };

    /**
     * Initialize component.
     * @constructor
     */
    override ngOnInit() {

        super.ngOnInit();

        this.refreshComponent();

        this.initialized = true;


    }

    /**
     * Process on changes.
     * @param changes
     */
    override ngOnChanges(changes: SimpleChanges) {

        if (!this.initialized) {
            return;
        }

        this.refreshComponent();

    }

  override refreshComponent() {


    }


}
