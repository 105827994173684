import {Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectSubBaseComponent} from "../object-sub-base.component";
import {
    CrCustomerRelatedPersonWidgetStyle
} from "../../../cr-customer-related-persons/uiStyle/cr-customer-related-person-widget-style";
import {
    DtoImportObjectContextEntry,
    ObjectRequestComplexRelationBindingEnum,
    ObjectRequestRelation,
    WidgetData
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {CustomerDto} from "../../../../dto/customer.dto";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {CustomerRelatedPersonDto} from "../../../../dto/customer-related-person.dto";
import {PagingDto} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {CustomerService} from "../../../../service/api/customer.service";
import {CustomerAddressService} from "../../../../service/api/customer-address.service";
import {CustomerContractService} from "../../../../service/api/customer-contract.service";
import {CustomerChannelService} from "../../../../service/api/customer-channel.service";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {CustomerInsurableObjectService} from "../../../../service/api/customer-insurable-object.service";
import {CustomerRelatedPersonService} from "../../../../service/api/customer-related-person.service";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {GroupByWidgetData} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ActivatedRoute, Router} from "@angular/router";
import {WidgetHelperButton} from "@kvers/alpha-core-common";
import {PersonRelationPersonDto} from "../../../../../pm/dto/person-relation-person.dto";
import {
    PmPersonRelationPersonWidgetStyle
} from "../../../../../pm/component/pm-household/uiStyle/pm-person-relation-person-widget-style";
import {PersonRelationPersonService} from "../../../../../pm/service/api/person-relation-person.service";
import {CustomerPageHouseholdPersonService} from "../../../../service/ui/customer-page-household-person.service";
import {Subscription} from "rxjs";
import {CustomerHostedRuntimeDto} from "../../../../service/dto/customer-household-runtime.dto";


@Component({
    selector: 'cr-customer-standard-navigation',
    templateUrl: './cr-customer-standard-navigation.component.html',
    styleUrls: ['./cr-customer-standard-navigation.component.scss'],
    providers: [CustomerPageHouseholdPersonService],
})
export class CrCustomerStandardNavigationComponent extends ObjectSubBaseComponent implements OnInit, OnChanges, OnDestroy {

    selectedInsurableObject: DtoDetail;

    customerContractWidgetData: WidgetData;
    groupByContractWidgetData: GroupByWidgetData;
    customerInsurableObjectsWidgetData: WidgetData;
    customerContactsWidgetData: WidgetData;
    customerPersonObjectWidgetData: WidgetData;
    widgetCustomerContacts: WidgetData;
    widgetCustomerAddresses: WidgetData;
    // widgetCustomerChannels: WidgetData;
    widgetCustomerAgents: WidgetData;
    widgetCustomerEmergencyContacts: WidgetData;
    selectedInsurableObjectWidgetData: WidgetData;
    widgetCustomerComments: WidgetData;
    customerRelatedPersonWidget: WidgetData;
    personRelationPersonWidget: WidgetData;

    customerBankAccountWidget: WidgetData;

    contractToolbarButtons: WidgetHelperButton[];
    dialogCombinedContract: boolean;
    combinedContractFormVariation: "Antrag" | "Fremd";

    selectedPersonWidgetStyle: CrCustomerRelatedPersonWidgetStyle;
    selectedPersonRelationWidgetStyle: PmPersonRelationPersonWidgetStyle;
    filterCriteria: FilterCriteria[] = [];
    dialogCreateRelatedPerson: boolean;


    contractObjectContext: DtoImportObjectContext;
    insurableObjectContext: DtoImportObjectContext;
    personRelationPersonObjectContext: DtoImportObjectContext;
    customerEmergencyContactContext: DtoImportObjectContext;

    householdSubscription: Subscription;
    customerHouseholdRuntimeDto: CustomerHostedRuntimeDto;

    @Input() customerRelatedPersonWidgetStyle: CrCustomerRelatedPersonWidgetStyle;
    @Input() personRelationPersonWidgetStyle: PmPersonRelationPersonWidgetStyle;


    ngOnInit(): void {
        this.householdSubscription = this.customerPageHouseholdPersonService.getHouseholdSubject().subscribe(res => {
            this.customerHouseholdRuntimeDto = res;
            // this.refreshPersonRelationPersonWidget();
        });

        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected customerService: CustomerService,
        protected customerAddressService: CustomerAddressService,
        protected customerContractService: CustomerContractService,
        protected customerChannelService: CustomerChannelService,
        protected configService: MvsConfigService,
        protected navigationService: MvsObjectNavigationService,
        protected personRelationPersonService: PersonRelationPersonService,
        protected customerInsurableObjectsService: CustomerInsurableObjectService,
        protected customerPageHouseholdPersonService: CustomerPageHouseholdPersonService,
        protected customerRelatedPersonService: CustomerRelatedPersonService) {
        super(coreService, confirmationService, messageService, configService, navigationService);

    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.customerPageHouseholdPersonService.setCustomerId(this.objectIdentifier.objectId);
        this.contractObjectContext = this.importObjectContext;
        this.insurableObjectContext = this.importObjectContext;
        this.personRelationPersonObjectContext = new DtoImportObjectContext(
            [
                new DtoImportObjectContextEntry('cr.Customer', this.dto.id),
                new DtoImportObjectContextEntry('pm.Person', this.dto.personDtoId)
            ]
        );
        this.refreshWidgets();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }

        if (changes["changedFilter"]) {
            this.removeFilter();
        }

        if (changes["customerRelatedPersonWidgetStyle"]) {
            this.changeCustomerWidgetStyle();
        }

        if (changes["personRelationPersonWidgetStyle"]) {
            this.changePersonRelationWidgetStyle();
        }

    }

    removeFilter() {

        const dto: CustomerRelatedPersonDto = new CustomerRelatedPersonDto();
        dto.id = this.changedFilter.value;

        const person = this.selectedPersonWidgetStyle.getRelatedPerson(dto.id);
        if (person) {
            person['__selected'] = false;
            person['__color'] = null;
        }

        this.selectedPersonWidgetStyle.removeRelatedPerson(dto);

        this.selectedRelatedPersons = this.selectedRelatedPersons.clone();
        this.showAllCustomerContracts();
        this.showCustomerInsurableObjects();
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    refreshWidgets() {

        // CUSTOMER CONTACT
        this.customerContactsWidgetData = WidgetFactory.createWidgetListComplex(
            "cr.customer.basic.contact",
            "Kundeninfo",
            "data",
            "list",
            "entity",
            "cr.Customer",
            "Keine Kontakdaten vorhanden",
            new ObjectRequestList(
                true,
                [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
                [new Sorting("createdDate", false)]
            ),
            this.customerService.getCustomerWithContactComplexSelection(),
            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectWidget", "data"),
            WidgetDataParam.create("objectId", this.objectIdentifier.objectId),
            WidgetDataParam.create("columns", 3),
        );

        // PERSON INFORMATION
        this.customerPersonObjectWidgetData = new WidgetData();
        this.customerPersonObjectWidgetData.idAlias = "cr.customer.complex.widget.get.person";
        this.customerPersonObjectWidgetData.name = "Details";
        this.customerPersonObjectWidgetData.uiComponent = "data";
        this.customerPersonObjectWidgetData.dataProvider = "list";
        this.customerPersonObjectWidgetData.dataSource = "entity";
        this.customerPersonObjectWidgetData.dataProviderObject = "pm.Person";

        this.customerPersonObjectWidgetData.dataProviderListRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(false, false, ObjectRequestComplexNode.createSimpleAttributeNode("personDetail")),
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, this.dto.personDtoId)],
            null,
            null,
            null);

        this.customerPersonObjectWidgetData.setParamValue("size", "S");
        this.customerPersonObjectWidgetData.setParamValue("objectId", this.dto.personDtoId);
        this.customerPersonObjectWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();


        // // CUSTOMER ADDRESS INFORMATION
        this.widgetCustomerAddresses = new WidgetData();
        this.widgetCustomerAddresses.idAlias = "cr.customer.basic.address";
        this.widgetCustomerAddresses.name = "Anschrift";
        this.widgetCustomerAddresses.uiComponent = "list";
        this.widgetCustomerAddresses.dataProvider = "list";
        this.widgetCustomerAddresses.dataSource = "entity";
        this.widgetCustomerAddresses.dataProviderObject = "cr.CustomerAddress";
        this.widgetCustomerAddresses.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [new Sorting("startDate", false)]
        );
        this.widgetCustomerAddresses.dataProviderListRequest.objectRequestComplex = this.customerAddressService.getAddressComplexSelection()
        this.widgetCustomerAddresses.setParamValue("size", "S");
        this.widgetCustomerAddresses.setParamValue("objectWidget", "data");
        this.widgetCustomerAddresses.functionCallbacks = this.widgetProcessToolbarCreate();

        // CUSTOMER CHANNELS
        // this.widgetCustomerChannels = new WidgetData();
        // this.widgetCustomerChannels.idAlias = "cr.customer.basic.channels";
        // this.widgetCustomerChannels.name = "Channels";
        // this.widgetCustomerChannels.uiComponent = "list";
        // this.widgetCustomerChannels.dataProvider = "list";
        // this.widgetCustomerChannels.dataSource = "entity";
        // this.widgetCustomerChannels.dataProviderObject = "cr.CustomerChannel";
        // this.widgetCustomerChannels.dataProviderListRequest = new ObjectRequestList(
        //     true,
        //     [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
        //     [new Sorting("createdDate", false)]
        // );
        // this.widgetCustomerChannels.dataProviderListRequest.objectRequestComplex = this.customerChannelService.getChannelComplexSelection()
        // this.widgetCustomerChannels.setParamValue("size", "S");
        // this.widgetCustomerChannels.setParamValue("objectWidget", "data");
        // this.widgetCustomerChannels.functionCallbacks = this.widgetProcessToolbarCreate();

        // // CUSTOMER CONTACTS INFORMATION
        this.widgetCustomerContacts = new WidgetData();
        this.widgetCustomerContacts.idAlias = "cr.customer.basic.contacts";
        this.widgetCustomerContacts.name = "Kontaktdaten";
        this.widgetCustomerContacts.uiComponent = "list";
        this.widgetCustomerContacts.dataProvider = "list";
        this.widgetCustomerContacts.dataSource = "entity";
        this.widgetCustomerContacts.dataProviderObject = "cr.CustomerContact";
        this.widgetCustomerContacts.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [new Sorting("createdDate", false)]
        );
        this.widgetCustomerContacts.dataProviderListRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("contact"),
        );
        this.widgetCustomerContacts.setParamValue("size", "S");
        this.widgetCustomerContacts.setParamValue("objectWidget", "data");
        this.widgetCustomerContacts.functionCallbacks = this.widgetProcessToolbarCreate();

        // CUSTOMER AGENTS
        this.widgetCustomerAgents = new WidgetData();
        this.widgetCustomerAgents.idAlias = "cr.customer.agents.list";
        this.widgetCustomerAgents.name = "Berater";
        this.widgetCustomerAgents.uiComponent = "list";
        this.widgetCustomerAgents.dataProvider = "list";
        this.widgetCustomerAgents.dataSource = "entity";
        this.widgetCustomerAgents.dataProviderObject = "cr.CustomerAgent";
        this.widgetCustomerAgents.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [new Sorting("createdDate", false)]
        );

        this.widgetCustomerAgents.setParamValue("size", "S");
        this.widgetCustomerAgents.setParamValue("objectWidget", "data");
        this.widgetCustomerAgents.functionCallbacks = this.widgetProcessToolbarCreate();

        this.widgetCustomerEmergencyContacts = WidgetFactory.createWidgetList(
            'cr.customer.standard.navigation.customer.emergency.contacts.basic.1',
            'Notfallkontakt',
            'list',
            'list',
            'entity',
            'cr.CustomerEmergencyContact',
            'Keine Daten vorhanden',
            new ObjectRequestList(true, [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],[])
        );
        this.widgetCustomerEmergencyContacts.functionCallbacks = this.widgetProcessToolbarCreate();
        this.customerEmergencyContactContext = new DtoImportObjectContext(
            [
                    new DtoImportObjectContextEntry('cr.Customer',this.dto.id),
                    new DtoImportObjectContextEntry('pm.Person',this.dto.personDtoId)
            ]
        );

        // CUSTOMER COMMENTS
        this.widgetCustomerComments = new WidgetData();
        this.widgetCustomerComments.idAlias = "cr.customer.comments.list";
        this.widgetCustomerComments.name = "Kundeninformationen";
        this.widgetCustomerComments.uiComponent = "list";
        this.widgetCustomerComments.dataProvider = "list";
        this.widgetCustomerComments.dataSource = "entity";
        this.widgetCustomerComments.dataProviderObject = "cr.CustomerComment";
        this.widgetCustomerComments.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [new Sorting("createdDate", false)]
        );

        this.widgetCustomerComments.setParamValue("size", "S");
        this.widgetCustomerComments.setParamValue("objectWidget", "data");
        this.widgetCustomerComments.functionCallbacks = this.widgetProcessToolbarCreate();


        // // CUSTOMER CONTRACT INFORMATION
        this.showAllCustomerContracts();

        // // Customer Insurable Objects Information
        this.showCustomerInsurableObjects();

        // // CUSTOMER RELATED PERSONS
        this.getCustomerRelatedPerson();

        // customer bank information
        this.refreshBankAccountWidget();

    }

    setFilterCriteria(objectData: ObjectIdentifierData, field: string) {

        if (this.filterCriteria) {
            const found = this.filterCriteria.find(res => res.field == field);

            if (found) {
                const index = this.filterCriteria.findIndex(value => value.field == found.field);
                this.filterCriteria.splice(index, 1);
                this.onChangeCustomerFilter.emit(this.filterCriteria);
                return;
            }
        }

        const filterCriteria: FilterCriteria = FilterCriteria.create(field, FilterCriteria.cOperatorEqual, objectData.objectId);
        filterCriteria.label = objectData.data.label;
        this.filterCriteria.push(filterCriteria);

        this.onChangeCustomerFilter.emit(this.filterCriteria);

    }

    onRelatedPersonSelect(object: ObjectIdentifierData) {

        this.contractObjectContext = null;
        this.insurableObjectContext = null;

        const person: CustomerRelatedPersonDto = <CustomerRelatedPersonDto>object.data;

        person['__selected'] = !person['__selected'];

        const filterId = person.personDto.firstName + person.personDto.lastName + ' (' + person.relatedPersonTypeDto.name + ')';

        this.setFilterCriteria(object, filterId);

        const customerId = person.personDto['relatedCustomer'].id;

        if (!customerId) {
            return;
        }

        //create new instance if we don't have one
        if (!this.selectedPersonWidgetStyle) {
            const customerDto: CustomerDto = new CustomerDto();
            customerDto.id = this.objectIdentifier.objectId;

            this.selectedPersonWidgetStyle = new CrCustomerRelatedPersonWidgetStyle(this.configService, customerDto, []);
        }

        if (person['__selected'] && customerId) {
            this.selectedPersonWidgetStyle.addRelatedPerson(person, customerId);
        } else {
            this.selectedPersonWidgetStyle.removeRelatedPerson(person);
        }

        this.selectedPersonWidgetStyle = this.selectedPersonWidgetStyle.clone();

        person['__style'] = this.selectedPersonWidgetStyle.getStyleObjectForAvatar(person);

        this.handleRelatedPersonSelectionChange(this.selectedPersonWidgetStyle);

    }


    handleRelatedPersonSelectionChange(event: CrCustomerRelatedPersonWidgetStyle) {
        this.selectedRelatedPersons = event;
        this.showAllCustomerContracts();
        this.showCustomerInsurableObjects();
    }

    showAllCustomerContracts() {
        this.customerContractWidgetData = new WidgetData();
        this.customerContractWidgetData.idAlias = "cr.customer.basic.contract.v2";
        this.customerContractWidgetData.name = "Verträge";
        this.customerContractWidgetData.uiComponent = "table";
        this.customerContractWidgetData.dataProvider = "list";
        this.customerContractWidgetData.dataSource = "entity";
        this.customerContractWidgetData.dataProviderObject = "cm.Contract";

        this.customerContractWidgetData.groupBy = this.groupByContractWidgetData;

        let filterCriteria = [FilterCriteria.create("id", FilterCriteria.cOperatorIn, "${CR_CONTRACTS_OF-" + this.dto.id + "}")];
        this.customerContractWidgetData.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteria,
            [new Sorting('lastModifiedDate', false)],
            PagingDto.create(0, 7)
        );

        this.customerContractWidgetData.dataProviderListRequest.objectRequestComplex = this.getCustomerContractsComplexSelection();
        this.customerContractWidgetData.setParamValue("selectionMode", "rowSelect");
        this.customerContractWidgetData.setParamValue("size", "S");
        // this.customerContractWidgetData.setParamValue("mode", MvsCrudModeEnum.read);
        this.customerContractWidgetData.setParamValue("objectWidget", "data");
        // this.customerContractWidgetData.setParamValue("objectId", "109870");
        if (this.selectedRelatedPersons) {
            this.selectedRelatedPersons.setSelectedPerson(null);
        }

        this.customerContractWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();

        this.createContractToolbarButtons();
    }

    getCustomerContractsComplexSelection(): ObjectRequestComplex {

        return ObjectRequestComplex.build(false, false,

            // add contract type and group
            ObjectRequestComplexNode.createSimpleAttributeNode("contractType")
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("group")),

            // add base type
            ObjectRequestComplexNode.createSimpleAttributeNode("baseType"),

            // add base category
            ObjectRequestComplexNode.createSimpleAttributeNode("baseCategory"),

            // add main partner
            ObjectRequestComplexNode.createRelationNode("currentMainPartner", ObjectRequestRelation.createJoin("+currentMainContractPartner"))
                .addNodes(
                    ObjectRequestComplexNode.createRelationNode("partner", ObjectRequestRelation.createJoin("partner")),
                    ObjectRequestComplexNode.createRelationNode("partnerLink", ObjectRequestRelation.createJoin("partnerLink"))
                        .addNodes(ObjectRequestComplexNode.createRelationNode("partnerLinkPartner", ObjectRequestRelation.createJoin("linkPartner")))
                ),

            // current price
            ObjectRequestComplexNode.createRelationNode("currentPrice", ObjectRequestRelation.createJoin("+currentPrice"))
                .addNodes(
                    ObjectRequestComplexNode.createSimpleAttributeNode("priceFrequency")
                ),

            // current rating
            ObjectRequestComplexNode.createRelationNode("currentRating", ObjectRequestRelation.createJoin("+currentRating")),

            // ObjectRequestComplexNode.createRelationNode("contractInsurableObject", ObjectRequestRelation.createJoin("ci.ContractInsurableObject#contract"))
            //   .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('insurableObject')
            //     .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('type'))
            // ,

            // former contract
            ObjectRequestComplexNode.createRelationNode("contractFormer",
                ObjectRequestRelation.createList(
                    "cm.ContractFormer",
                    "contract",
                    null,
                    null,
                    ObjectRequestComplexRelationBindingEnum.LATEST)
            ).addNodes(ObjectRequestComplexNode.createRelationNode('contract', ObjectRequestRelation.createJoin('contract')))
        );
    }

    createContractToolbarButtons() {

        if (!this.contractToolbarButtons) {
            this.contractToolbarButtons = [];
        }

        //create widgetToolbar buttons
        const antragButton: WidgetHelperButton = WidgetFactory.createToolbarButton(
            'Antrag',
            'pi pi-plus',
            true,
            'contract',
            '+ Antrag'
        );

        const fremdButton: WidgetHelperButton = WidgetFactory.createToolbarButton(
            'Fremd',
            'pi pi-plus',
            true,
            'foreign-contract',
            '+ Fremd'
        );

        this.contractToolbarButtons = WidgetFactory.createToolbarButtons(antragButton, fremdButton);
    }

    handleContractButtonClick(event: WidgetHelperButton) {
        if (event.action == 'foreign-contract') {
            this.combinedContractFormVariation = "Fremd";
        } else {
            this.combinedContractFormVariation = "Antrag";
        }

        this.dialogCombinedContract = true;
    }


    showCustomerInsurableObjects() {

        this.customerInsurableObjectsWidgetData = new WidgetData();
        this.customerInsurableObjectsWidgetData.idAlias = "cr.customer.basic.insurable-objects";
        this.customerInsurableObjectsWidgetData.name = "Versicherte Objekte";
        this.customerInsurableObjectsWidgetData.uiComponent = "list";
        this.customerInsurableObjectsWidgetData.dataProvider = "list";
        this.customerInsurableObjectsWidgetData.dataSource = "entity";
        this.customerInsurableObjectsWidgetData.dataProviderObject = "cr.CustomerInsurableObject";
        this.customerInsurableObjectsWidgetData.noDataText = "Keine versicherten Objekte vorhanden";

        let filterCriteria = this.getCustomerRelatedPersonFilter();

        this.customerInsurableObjectsWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)]
        );

        this.customerInsurableObjectsWidgetData.dataProviderListRequest.objectRequestComplex =
            this.customerInsurableObjectsService.getCustomerInsurableObjectsComplexSelection();

        this.customerInsurableObjectsWidgetData.setParamValue("size", "S");
        this.customerInsurableObjectsWidgetData.setParamValue("selectionMode", "rowSelect");
        this.customerInsurableObjectsWidgetData.setParamValue("objectWidget", "data");
        this.customerInsurableObjectsWidgetData.setParamValue("columns", "3");
        this.customerInsurableObjectsWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();
    }

    getCustomerRelatedPersonFilter(): FilterCriteria[] {
        let filterCriteria = new Array<FilterCriteria>();
        const mainCustomerFilterCriteria = FilterCriteria.create("customer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId);

        if (this.selectedRelatedPersons && this.selectedRelatedPersons.hasRelatedPersons()) {

            const orFilter = new FilterCriteria();
            orFilter.addFilterCriteriaToOr(mainCustomerFilterCriteria);

            const relatedPersons = this.selectedRelatedPersons.getRelatedPersons();

            for (const item of relatedPersons) {
                // make sure that this person has a customer record
                let customerId: number = null;
                if (item.personDto['relatedCustomer']) {
                    customerId = item.personDto['relatedCustomer'].id;

                } else if (item.personDto['customerDto']) {
                    customerId = item.personDto['customerDto'].id;
                }
                if (item.personDto && customerId) {
                    const fieldCriteria = new FilterCriteria();
                    fieldCriteria.field = 'customer';
                    fieldCriteria.value = customerId;
                    orFilter.addFilterCriteriaToOr(FilterCriteria.create(fieldCriteria.field, FilterCriteria.cOperatorEqual, fieldCriteria.value, null));
                }
            }

            filterCriteria.push(orFilter);

        } else {
            filterCriteria.push(mainCustomerFilterCriteria);
        }

        return filterCriteria;
    }

    // handleViewContract(object: ObjectIdentifierData,id){
    //     this.contractBottomBar = true;
    //     this.selectedContract = object.data;
    //
    //
    //     if(this.selectedRelatedPersons){
    //         this.selectedRelatedPersons.setSelectedPerson(this.selectedContract['customerDtoId']);
    //     }
    //
    //     this.selectedItem = object.data;
    //
    //     this.scrollToDiv(id);
    //     let doc = document.querySelector(id)
    //     if(doc){
    //         doc.classList.add('scroll-margin');
    //         doc.scrollIntoView({
    //             behavior: 'smooth',
    //         });
    //     }
    // }

    // scrollToDiv(target) {
    //     let doc = document.querySelector(target)
    //     if (doc) {
    //         doc.classList.add('scroll-margin');
    //         doc.scrollIntoView({
    //             behavior: 'smooth',
    //         });
    //     }
    // }

    // handleViewInsurableObjects(object: ObjectIdentifierData, id) {
    //     this.selectedInsurableObject = object.data;
    //     this.getSelectedInsurableObjectContract();
    //     this.scrollToDiv(id);
    // }

    getSelectedInsurableObjectContract() {
        this.selectedInsurableObjectWidgetData = new WidgetData();
        this.selectedInsurableObjectWidgetData.idAlias = "cr.customer.basic.selected.insurable.object";
        this.selectedInsurableObjectWidgetData.name = "Insurable Objects Contract";
        this.selectedInsurableObjectWidgetData.uiComponent = "table";
        this.selectedInsurableObjectWidgetData.dataProvider = "list";
        this.selectedInsurableObjectWidgetData.dataSource = "entity";
        this.selectedInsurableObjectWidgetData.dataProviderObject = "ci.ContractInsurableObject";
        this.selectedInsurableObjectWidgetData.dataProviderListRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create("insurableObject", FilterCriteria.cOperatorEqual, this.selectedInsurableObject['insurableObjectDto'].id)],
            [new Sorting("createdDate", true)]
        );

        this.selectedInsurableObjectWidgetData.dataProviderListRequest.objectRequestComplex = this.customerContractService.getCustomerContractsComplexSelection(this.dto.personDtoId);
        this.selectedInsurableObjectWidgetData.setParamValue("selectionMode", "rowSelect");
        this.selectedInsurableObjectWidgetData.setParamValue("size", "S");
        this.selectedInsurableObjectWidgetData.functionCallbacks = this.widgetProcessToolbarCreate();

    }

    getCustomerRelatedPerson() {
        this.customerRelatedPersonWidget = new WidgetData();
        this.customerRelatedPersonWidget.idAlias = "cr.customer.standard.navigation.related.persons.object";
        this.customerRelatedPersonWidget.name = "Verwandte Personen";
        this.customerRelatedPersonWidget.uiComponent = "list";
        this.customerRelatedPersonWidget.dataProvider = "list";
        this.customerRelatedPersonWidget.dataSource = "entity";
        this.customerRelatedPersonWidget.dataProviderObject = "cr.CustomerRelatedPerson";
        this.customerRelatedPersonWidget.dataProviderListRequest = this.customerRelatedPersonService.getCustomerRelatedPersonComplexRequest(this.objectIdentifier.objectId, true);
        this.customerRelatedPersonWidget.functionCallbacks = this.widgetProcessToolbarCreate();
        // this.customerRelatedPersonWidget.setParamValue("mode", MvsCrudModeEnum.create);

        this.refreshPersonRelationPersonWidget();


    }

    refreshPersonRelationPersonWidget() {
        const filter = this.personRelationPersonService.getPersonRelationFilterOr(this.dto.personDtoId);
        const personRelationRequest = new ObjectRequestList(true,
            [filter],
            []
        );
        personRelationRequest.objectRequestComplex =
            ObjectRequestComplex.build(
                true,
                false,
                ObjectRequestComplexNode.createSimpleAttributeNode('personB')
                    .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('personDetail'))
            );


        this.personRelationPersonWidget = WidgetFactory.createWidgetList(
            'cr.customer.standard.navigation.related.persons.object.pm.related.person.basic',
            'Verknüpfte Personen',
            'list',
            'list',
            'entity',
            'pm.PersonRelationPerson',
            'Keine Daten vorhanden',
            personRelationRequest
        );
        this.personRelationPersonWidget.functionCallbacks = this.personRelationPersonToolbar();
    }

    refreshBankAccountWidget() {
        const request = new ObjectRequestList(true,
            [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            []
        );
        this.customerBankAccountWidget = WidgetFactory.createWidgetList(
            'cr.customer.standard.navigation.customer.bank.account',
            'Bank Account',
            'list',
            'list',
            'entity',
            'cr.CustomerBankAccount',
            'Keine Daten vorhanden',
            request
        );
        this.customerBankAccountWidget.functionCallbacks = this.widgetProcessToolbarCreate();
    }

    /*
    onCreateRelatedPerson() {
        this.dialogCreateRelatedPerson = true;
    }

    handleSaveRelatedPerson(event: number) {
        this.dialogCreateRelatedPerson = false;
        this.getCustomerRelatedPerson();
    }
     */

    changeCustomerWidgetStyle() {
        // this.contractObjectContext = new DtoImportObjectContext([new DtoImportObjectContextEntry(null,null)]);
        // this.contractObjectContext.copy();
        this.contractObjectContext = null;
        this.insurableObjectContext = null;
        this.selectedPersonWidgetStyle = this.customerRelatedPersonWidgetStyle;
        this.handleRelatedPersonSelectionChange(this.selectedPersonWidgetStyle);
    }

    changePersonRelationWidgetStyle() {
        this.selectedPersonRelationWidgetStyle = this.personRelationPersonWidgetStyle;
        // this.handleRelatedPersonSelectionChange(this.personRelationPersonWidgetStyle);
    }

    widgetProcessToolbarCreate() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto['customerDtoId'] = this.dto.id;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    personRelationPersonToolbar() {
        return {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new PersonRelationPersonDto();
                dto.personADtoId = this.dto.personDtoId;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('personADtoId', MvsFormFieldAccessEnum.READ);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    isSameHousehold(object:DtoDetail) {
        console.log(object);
    }


}
