import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren
} from "@angular/core";
import {TicketCommentService} from "../../service/api/ticket-comment.service";
import {TicketService} from "../../service/api/ticket.service";
import {TicketRelTicketService} from "../../service/api/ticket-rel-ticket.service";
import {TicketTagService} from "../../service/api/ticket-tag.service";
import {TicketTypeService} from "../../service/api/ticket-type.service";
import {TicketTypeObjectTypeService} from "../../service/api/ticket-type-object-type.service";
import {TicketObjectService} from "../../service/api/ticket-object.service";
import {TmTicketTypeObjectTypeDto} from "../../dto/tm-ticket-type-object-type.dto";
import {TmTicketDto} from "../../dto/tm-ticket.dto";
import {AgentService} from "../../../am/service/api/agent.service";
import {AgentPoolService} from "../../../am/service/api/agent-pool.service";
import {AgentPoolAgentService} from "../../../am/service/api/agent-pool-agent.service";
import {ConfirmationService, MenuItem} from "primeng/api";
import {ObjectTypeService} from "../../../cc/service/api/object-type.service";
import {AgentActiveObjectService} from "../../../am/service/api/agent-active-object.service";
import {AgentActiveObjectDto} from "../../../am/dto/agent-active-object.dto";
import {Subscription} from "rxjs";
import {DmDocumentAssignmentService} from "../../../dm/service/api/dm-document-assignment.service";
import {
    DtoDetail,
    DtoImportObjectContext,
    DtoImportObjectContextEntry,
    DtoList,
    FilterCriteria,
    MvsCoreService,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    MvsMessageService,
    MvsObjectService,
    ObjectBaseComponent,
    ObjectChangeInformation,
    ObjectIdentifier,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList,
    ObjectRequestRelation,
    ObserverService,
    Sorting,
    UiPageTypeEnum,
    UiPageUserService,
    WidgetData,
    WidgetHelperButton
} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService, WidgetFactory} from "@kvers/alpha-ui";
import {TicketAppointmentService} from "../../service/api/ticket-appointment.service";
import {TmTicketAppointmentDto} from "../../dto/tm-ticket-appointment.dto";
import {TicketActionDto} from "../../dto/ticket-action.dto";
import {TmCreateTicketAction} from "./ticket-components/create-tm-ticket-actions/data/tm-create-ticket-action";
import {TicketObjectDto} from "../../dto/ticket-object.dto";
import {ActivatedRoute, Router} from "@angular/router";
import {TicketActionAvailableResponseDto} from "../../service/api/dto/ticket-action-available-response.dto";
import {TmStatusEnum} from "../../enum/tm-status.enum";
import {TicketActionService} from "../../service/api/ticket-action.service";
import {ContractDto} from "../../../cm/dto/contract.dto";
import {CustomerDto} from "../../../cr/dto/customer.dto";
import {TmTicketObjectDto} from "../../dto/tm-ticket-object.dto";
import {TicketTypeActionDto} from "../../dto/ticket-type-action.dto";
import {TmTicketTypeActionInternalTypeEnum} from "../../enum/tm-ticket-type-action-internal-type.enum";
import {TicketDto} from "../../dto/ticket.dto";

@Component({
    selector: 'mvs-tm-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss']
})
export class TicketComponent
    extends ObjectBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    @Input() bread: any[] = [];
    @Input() breadcrumbsItems: MenuItem[] = [];
    ticketFormWidgetData: WidgetData;
    serviceTicket: TicketService;
    serviceTicketRelTicket: TicketRelTicketService;
    serviceTicketType: TicketTypeService;
    serviceTicketTypeObjectType: TicketTypeObjectTypeService;
    serviceTicketObject: TicketObjectService;
    serviceTicketTag: TicketTagService;
    serviceTicketComment: TicketCommentService;
    agentPoolAgentService: AgentPoolAgentService;
    agentService: AgentService;
    agentPoolService: AgentPoolService;
    selectedAgent: number;
    selectedAgentPool: number;
    ticketObjects: TicketObjectDto[];
    dto: TmTicketDto;
    dtoTicketType = new DtoDetail();
    dtoListTicketTypeObjectType: TmTicketTypeObjectTypeDto[];
    objectList: DtoDetail[] = [];
    objectTypeIds: number[] = [];
    objectTypes: string[] = []
    loadTicket: boolean = true;
    widgetButtons: WidgetHelperButton[];
    ticketObjectToolbarButtons: WidgetHelperButton[];
    attachmentLength: number;
    sectionWidth: number;
    fullPage: boolean = false;
    pinnedTicket: AgentActiveObjectDto;
    activeTicketMainObject: AgentActiveObjectDto;
    agentActiveObjects: AgentActiveObjectDto[];
    activeObjectsSubscription: Subscription;
    broadcastSubscription: Subscription;
    ticketActions: TicketActionDto[];
    currentView: string = 'overview';
    previousView: string = 'overview';
    ticketAppointmentList: DtoList<TmTicketAppointmentDto>;
    modeType: string;
    processIdentifier: ObjectIdentifier;
    notificationIdentifier: ObjectIdentifier;
    notificationSourceTypeId: number;
    notificationCurrentAction: TicketTypeActionDto;
    ticketIdentifier: ObjectIdentifier;
    visibleSections: string[] = [];
    availableActionsResponse: TicketActionAvailableResponseDto;
    suggestedTicketObjectLabel: string;
    currentMainNavigationObject: ObjectIdentifier;
    ticketActionObjectIdentifier: ObjectIdentifier;
    ticketDefaultDto: DtoDetail;
    ticketFormControlOverwrite: MvsFormControlOverwrite;
    ticketImportObjectContext: DtoImportObjectContext;
    isTicketMain: boolean = false;
    subTicketActive: boolean = false; // check if sub ticket is active
    paramSubscription: Subscription;
    mainTicketInfo: TicketDto;
    splitButtonItems: MenuItem[];

    @ViewChildren('tooltipRefs') tooltipRefs!: QueryList<ElementRef>;


    protected readonly ObjectIdentifier = ObjectIdentifier;
    protected readonly event = event;
    protected readonly TmStatusEnum = TmStatusEnum;


    ngOnInit(): void {

        this.paramSubscription = this.route.queryParams.subscribe(params => {
            const paramObject = params['tid-sbr'];
            if (paramObject && paramObject.includes(',')) {
                this.checkIfSubTicket(paramObject);
                //some ticket is active
                this.handleActiveObject(paramObject);
            }
        });

        this.ticketObjectToolbarButtons = [
            {
                label: null,
                icon: 'fa-regular fa-send-backward',
                display: true,
                action: 'background',
                tooltip: 'Open in background'
            },
            {
                label: null,
                icon: 'fa-regular fa-sidebar',
                display: true,
                action: 'sidebar',
                tooltip: 'Open in sidebar'
            }
        ];

        this.splitButtonItems = [
            {
                label: 'Cancel',
                icon: 'fa-regular fa-circle-xmark',
                command: () => {
                    this.handleCancelTicket();
                }
            },
            {separator: true},
            {
                label: 'Delete',
                icon: 'fa-regular fa-trash',
                command: () => {
                    this.handleDeleteTicket();
                }
            }
        ];

        this.handleWidgetButtons();
        this.handleBroadcastChange();
        this.getActiveTicket();
        this.logVisit();
        this.indicateActiveTicketToast();
        super.ngOnInit();

    }

    constructor(
        protected ticketAppointmentService: TicketAppointmentService,
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected navigationService: MvsObjectNavigationService,
        protected objectTypeService: ObjectTypeService,
        protected objectService: MvsObjectService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected observerService: ObserverService,
        protected agentActiveObjectService: AgentActiveObjectService,
        protected ticketActionService: TicketActionService,
        protected uiPageUserService: UiPageUserService,
    ) {
        super(coreService, messageService, confirmationService, observerService)
    }

    handleCancelTicket() {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: 'Are you sure you want to cancel the ticket?',
            header: 'Cancel Ticket',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: "none",
            rejectIcon: "none",
            rejectButtonStyleClass: "p-button-text",
            accept: () => {
                this.cancelTicket();
            },
            reject: () => {
            }
        });
    }

    cancelTicket() {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.serviceTicket.cancelTicket(this.objectIdentifier.objectId).subscribe(res => {
            this.messageService.showSuccessMessage('Cancel Ticket', res);
            this.navigationService.navigateTo(null, 'right');
        }, error => {
            this.messageService.showErrorMessage('Cancel Ticket', error.data);
        });
    }



    handleDeleteTicket() {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: 'Are you sure you want to delete the ticket?',
            header: 'Delete Ticket',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: "none",
            rejectIcon: "none",
            rejectButtonStyleClass: "p-button-text",
            accept: () => {
                this.deleteTicket();
            },
            reject: () => {
            }
        });
    }

    deleteTicket() {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.serviceTicket.deleteTicket(this.objectIdentifier.objectId).subscribe(res => {
            this.messageService.showSuccessMessage('Delete Ticket', res);
            this.navigationService.navigateTo(null, 'right');
        }, error => {
            this.messageService.showErrorMessage('Delete Ticket', error.data);
        });
    }

    checkIfSubTicket(paramObject: string) {
        const entities = paramObject.split(',');

        const ticketEntity = entities.find((entity) => entity.startsWith('tm.Ticket:'));

        if (ticketEntity) {
            const ticketValue = +ticketEntity.split(':')[1];

            if (this.objectIdentifier.objectId != ticketValue) {
                this.subTicketActive = true;
                this.getMainTicketInfo(ticketValue);

            }

        }
    }

    getMainTicketInfo(ticketId: number) {
        const crudService = this.coreService.getCrudService('tm.Ticket');
        crudService.get(ticketId).subscribe((res: TicketDto) => {
            this.mainTicketInfo = res;
            console.log('Main ticket info', res);
        }, error => {

        })
    }

    logVisit() {
        this.uiPageUserService.pageVisit('tm/ticket/:id', 'Ticket', UiPageTypeEnum.object, this.objectIdentifier.objectType, null, this.objectIdentifier.objectId);
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve ticket service
        this.serviceTicket = <TicketService><unknown>this.coreService.getCrudService("tm.Ticket");

        // retrieve ticket sub ticket service
        this.serviceTicketRelTicket = <TicketRelTicketService>this.coreService.getCrudService("tm.TicketRelTicket");

        // retrieve ticket type service
        this.serviceTicketType = <TicketTypeService>this.coreService.getCrudService("tm.TicketType");

        // retrieve ticket type service
        this.serviceTicketTypeObjectType = <TicketTypeObjectTypeService>this.coreService.getCrudService("tm.TicketTypeObjectType");

        // retrieve ticket type service
        this.serviceTicketObject = <TicketObjectService>this.coreService.getCrudService("tm.TicketObject");

        // retrieve ticket tags service
        this.serviceTicketTag = <TicketTagService>this.coreService.getCrudService("tm.TicketTag");

        // retrieve ticket comments service
        this.serviceTicketComment = <TicketCommentService>this.coreService.getCrudService("tm.TicketComment");

        this.agentService = <AgentService>this.coreService.getCrudService("am.Agent");

        this.agentPoolService = <AgentPoolService>this.coreService.getCrudService("am.AgentPool");

        this.agentPoolAgentService = <AgentPoolAgentService>this.coreService.getCrudService("am.AgentPoolAgent");

        this.activeObjectsSubscription = this.agentActiveObjectService.subjectAgentActiveObjects.subscribe(value => {
            this.agentActiveObjects = value;
            this.pinnedTicket = this.agentActiveObjects?.find(object => object.objectId == this.objectIdentifier.objectId);
            this.activeTicketMainObject = this.agentActiveObjects?.find(object => object.mainObject == true && object.objectId == this.objectIdentifier.objectId);

            const isThisTicketMain = this.agentActiveObjects?.find(object => object.mainObject == true && object.objectId == this.objectIdentifier.objectId);

            if (isThisTicketMain) {
                this.isTicketMain = true;
            } else {
                this.isTicketMain = false;
            }

            if (!this.activeTicketMainObject) {
                this.getActiveTicket();
            }
            this.handlePostChangeActiveTicket();
        });

        super.initComponent();
    }

    getActiveTicket() {
        this.activeTicketMainObject = this.agentActiveObjectService.getActiveTicket();
    }

    getTicketObjectSuggestions() {
        this.currentMainNavigationObject = this.navigationService.currentMainObject;

        if (!this.currentMainNavigationObject) {
            return;
        }

        const crudService = this.coreService.getCrudService(this.currentMainNavigationObject.objectType);
        crudService.get(this.currentMainNavigationObject.objectId).subscribe((res: ContractDto & CustomerDto) => {
            this.suggestedTicketObjectLabel = null;
            if (res?.form?.objectType == 'cm.Contract') {
                this.suggestedTicketObjectLabel = `Add Contract: ${res.contractTypeDtoName}`;
            } else if (res?.form?.objectType == 'cr.Customer') {
                this.suggestedTicketObjectLabel = `Add Customer: ${res.calculatedName}`;
            }
            this.checkSuggestionLabel();
        });
    }

    async checkSuggestionLabel() {
        let navigationObjectTypeId: number;
        await this.objectTypeService.getViaObjectType(this.currentMainNavigationObject.objectType).then(value => {
            navigationObjectTypeId = value.id;
        });

        if (!this.ticketObjects || !this.ticketObjects.length) {
            return;
        }

        for (let item of this.ticketObjects) {
            if (item.objectTypeDtoId == navigationObjectTypeId && item.objectId == this.currentMainNavigationObject.objectId) {
                this.suggestedTicketObjectLabel = null;
                return;
            }
        }
    }

    async handleAddSuggestedObject() {
        this.busy = true;
        const crudService = this.coreService.getCrudService('tm.TicketObject');
        const dto = new TmTicketObjectDto();
        dto.ticketDtoId = this.objectIdentifier.objectId;
        dto.objectId = this.currentMainNavigationObject.objectId;
        await this.objectTypeService.getViaObjectType(this.currentMainNavigationObject.objectType).then(value => {
            dto.objectTypeDtoId = value.id;
        });

        crudService.create(dto).subscribe(res => {
            //refresh component
            this.onObjectChanged();
            this.busy = false;
        }, error => {
            this.busy = false;
        });

    }

    showTooltipForIndex(index: number): void {
        const tooltipElement = this.tooltipRefs.toArray()[index - 1]?.nativeElement;
        if (tooltipElement) {
            // manually trigger tooltip
            const mouseEnterEvent = new Event('mouseenter');
            tooltipElement.dispatchEvent(mouseEnterEvent);
        }
    }

    handlePostChangeActiveTicket() {
        if (!this.widgetButtons || !this.widgetButtons.length) {
            return;
        }
        const activePinTicket = this.widgetButtons.find(item => item.action == 'activePinTicket');
        const pinTicket = this.widgetButtons.find(item => item.action == 'pinTicket');
        // if (this.activeTicketMainObject) {
        //     pinTicket.display = false;
        //     activePinTicket.icon = 'fa-solid fa-ticket bg-green-100 text-green-800 border-round p-2 ml-2';
        // } else if (!this.activeTicketMainObject && this.pinnedTicket) {
        //     pinTicket.display = true;
        //     pinTicket.icon = `fa-solid fa-thumbtack text-primary`;
        //     activePinTicket.icon = 'fa-regular fa-ticket';
        // } else if (!this.activeTicketMainObject && !this.pinnedTicket) {
        //     pinTicket.display = true;
        //     activePinTicket.icon = 'fa-regular fa-ticket';
        //     pinTicket.icon = `fa-regular fa-thumbtack text-700`;
        // }

        if (this.isTicketMain) {
            pinTicket.display = false;
            activePinTicket.icon = 'fa-solid fa-ticket bg-green-100 text-green-800 border-round p-2 ml-2';

        } else if (!this.isTicketMain && this.pinnedTicket) {
            pinTicket.display = true;
            pinTicket.icon = `fa-solid fa-thumbtack text-primary`;
            activePinTicket.icon = 'fa-regular fa-ticket';

        } else if (!this.isTicketMain && !this.pinnedTicket) {
            pinTicket.display = true;
            activePinTicket.icon = 'fa-regular fa-ticket';
            pinTicket.icon = `fa-regular fa-thumbtack text-700`;
        }


        if (this.subTicketActive) {
            activePinTicket.display = false;
        }

    }


    /**
     * Refresh Component.
     */
    onObjectChanged() {

        this.prepareTicketFormWidget();

        // refresh ticket Appointments
        this.refreshTicketAppointments()

        // refresh ticket type action
        this.refreshTicketTypeAction();

        this.getAvailableTicketActions();

        this.getTicketActionList();

        this.getAttachmentLength();

        this.adjustUiLayout();

    }

    getAvailableTicketActions() {
        this.serviceTicket.getAvailableActions(this.dto.id).subscribe(res => {
            this.availableActionsResponse = res;
        })
    }

    getTicketActionList() {
        const filterCriteria = [
            FilterCriteria.create('ticket', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
        ]

        const request = this.getObjectRequestForTicketActions(filterCriteria);

        this.ticketActionService.list(request).subscribe(res => {
            this.ticketActions = res.entries;
        })
    }

    getObjectRequestForTicketActions(filterCriteria: FilterCriteria[]): ObjectRequestList {
        const complexSelection =
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createSimpleAttributeNode('ticketTypeAction')
            );

        return ObjectRequestList.createComplexRequestList(
            false,
            complexSelection,
            filterCriteria,
            null,
            null,
            null);
    }

    prepareTicketFormWidget() {

        this.ticketFormWidgetData = WidgetFactory.createWidgetEntityData(
            'ticket.component.form.ticket',
            '',
            'tm.Ticket',
            this.objectIdentifier.objectId);

    }

    handleWidgetButtons() {

        this.widgetButtons = [
            {
                label: null,
                icon: 'fa-regular fa-file-lines ',
                display: true,
                action: 'overview',
                tooltip: 'Übersicht'
            }, {
                label: null,
                icon: 'fa-regular fa-paperclip-vertical',
                display: true,
                action: 'attachment',
                tooltip: 'Anhänge',
                type: 'badge'
            },
            {
                label: null,
                icon: 'fa-regular fa-comment',
                display: true,
                action: 'comments',
                tooltip: 'Aktivitäten',
            },
            {
                label: null,
                icon: 'fa-light fa-comments',
                display: true,
                action: 'notification',
                tooltip: 'Benachrichtigungen',
            },
            {
                label: null,
                icon: null,
                display: null,
                action: null,
                tooltip: null,
                type: 'divider'
            },
            {
                label: null,
                icon: 'fa-regular fa-ticket',
                display: true,
                action: 'activePinTicket',
                tooltip: 'Ticket aktivieren'
            },
            // icon: this.isMainTicket() ? `fa-solid fa-ticket text-primary` : 'fa-regular fa-ticket',


            {
                label: null,
                icon: this.pinnedTicket ? `fa-solid fa-thumbtack text-primary` : `fa-regular fa-thumbtack text-700`,
                display: true,
                action: 'pinTicket',
                tooltip: 'Ticket anpinnen'
            }
            // display: !this.isMainTicket() ? true : false,

        ];

    }


    isMainTicket(): boolean {
        // returns true if active ticket
        return this.activeTicketMainObject && this.activeTicketMainObject.objectId == this.objectIdentifier.objectId;
    }


    /**
     * refresh ticket
     */
    refreshTicketTypeAction() {

        const complexSelection =
            ObjectRequestComplex.build(false, false,
                ObjectRequestComplexNode.createSimpleAttributeNode('closingStatus'),
                ObjectRequestComplexNode.createSimpleAttributeNode('type')
                    .addNodes(ObjectRequestComplexNode.createRelationNode("ticketTypeObjectType",
                            ObjectRequestRelation.createList(
                                "tm.TicketTypeObjectType",
                                "ticketType",
                                null,
                                null,
                                ObjectRequestComplexRelationBindingEnum.ALL)
                        ).addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('objectType')),
                    ),
                ObjectRequestComplexNode.createRelationNode("ticketObject",
                    ObjectRequestRelation.createList(
                        "tm.TicketObject",
                        "ticket",
                        null,
                        null,
                        ObjectRequestComplexRelationBindingEnum.ALL)
                ).addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('ticketTypeObjectRole'))
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            false,
            complexSelection,
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            null,
            null,
            null);

        this.serviceTicket.list(objectRequestList).subscribe(
            (value: DtoList<TmTicketDto>) => {
                this.dto = value.entries[0];
                console.log(this.dto);
                this.addToBreadcrumb('Ticket');
                this.selectedAgent = this.dto.assigneeAgentDtoId;
                this.selectedAgentPool = this.dto.assigneeAgentPoolDtoId;
                this.ticketObjects = this.dto.ticketObject;

                if (this.dto.typeDto.ticketTypeObjectType) {

                    this.dtoListTicketTypeObjectType = this.dto.typeDto.ticketTypeObjectType;

                    this.objectTypes = this.dtoListTicketTypeObjectType.map(
                        (entries: TmTicketTypeObjectTypeDto) => {
                            return entries['objectTypeDto'].alias
                        }
                    )

                    this.objectTypeIds = this.dtoListTicketTypeObjectType.map(
                        (entries: TmTicketTypeObjectTypeDto) => {
                            return entries.objectTypeDtoId
                        }
                    )
                }

                if (this.dto.ticketObject) {
                    this.objectList = this.dto.ticketObject;
                }

                if (this.dto.typeDtoId) {
                    this.dtoTicketType.id = this.dto.typeDtoId;
                }

                this.getTicketObjectSuggestions();
                this.loadTicket = false;
                this.initialized = true;
            }
        )
    }

    indicateActiveTicketToast() {
        if (this.isMainTicket()) {
            this.messageService.showSuccessMessage('', 'You are in an active ticket');
        }
    }

    addToBreadcrumb(label: string) {
        const exists = this.breadcrumbsItems.find(item => item.label == label);
        if (exists) {
            return;
        }
        if (label == 'Ticket') {
            this.breadcrumbsItems.unshift({
                label: label,
                styleClass: 'cursor-pointer',
                badgeStyleClass: 'cursor-pointer'
            });
        } else {
            this.breadcrumbsItems.push({label: label, styleClass: 'cursor-pointer', badgeStyleClass: 'cursor-pointer'});
        }

    }

    removeFromBreadcrumb(label: string) {
        const index = this.breadcrumbsItems.findIndex(item => item.label == label);
        if (index > -1) {
            this.breadcrumbsItems.splice(index, 1);
        }
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        super.ngOnChanges(changes);

    }

    handleToolbarButtonClick(event: WidgetHelperButton) {
        if (this.currentView == 'show-process') {
            this.addProcessSections(event);
        } else {
            this.handleButtonClick(event);
        }
    }


    handleButtonClick(event: WidgetHelperButton) {

        if (event.action === 'overview') {
            this.setView('overview');
        } else if (event.action === 'attachment') {
            this.setView('attachment');
        } else if (event.action === 'comments') {
            this.setView('comments');
        } else if (event.action === 'activePinTicket') {
            this.activeTicketObject();
        } else if (event.action === 'pinTicket') {
            this.togglePinTicket();
        } else if (event.action === 'notification') {
            this.setView('notification');

        }

    }

    addProcessSections(event: WidgetHelperButton) {
        const exists = this.visibleSections.find(item => item == event.action);
        if (exists) {
            const index = this.visibleSections.findIndex(item => item == event.action);
            if (index > -1) {
                this.visibleSections.splice(index, 1);
            }
        } else {
            this.visibleSections.unshift(event.action); //always push to first index
        }
    }


    getWidgetHelperButtonIndex(fieldAction: string): number {
        return this.widgetButtons.findIndex(item => item.action == fieldAction);
    }

    setView(view: string) {
        this.currentView = view;
    }


    async getAttachmentLength() {

        const documentAssignmentService = <DmDocumentAssignmentService>this.coreService.getCrudService("dm.DmDocumentAssignment");

        const objectTypeDto = await this.objectTypeService.getViaObjectType("tm.Ticket")

        documentAssignmentService.listActiveObjectDocumentAssignmentsCount(objectTypeDto.id, this.dto.id).subscribe(value => {
            const index = this.getWidgetHelperButtonIndex('attachment');
            this.attachmentLength = value;
            //TODO: @Yasir the badge is not displayed in ticket overview header for attachments
            this.widgetButtons[index].icon = `fa-regular fa-paperclip-vertical ${this.attachmentLength ? '' : ' text-700'}`;
            this.widgetButtons[index].badge = this.attachmentLength;
            this.widgetButtons[index].type = this.attachmentLength > 0 ? 'badge' : null;
        });

    }


    adjustUiLayout() {
        const rootElement = document.getElementById('rootElement');
        if (rootElement) {
            this.sectionWidth = rootElement.offsetWidth;
            this.fullPage = this.sectionWidth > 1600;
            // if (this.sectionWidth > 1600) {
            //     this.fullPage = true;
            // } else {
            //     this.fullPage = false;
            // }
        }
    }


    /**
     * toggle ticket state for active objects
     */
    togglePinTicket() {

        if (this.pinnedTicket) {
            this.agentActiveObjectService.complete(this.pinnedTicket.id);

        } else {
            this.agentActiveObjectService.start("tm.Ticket", this.objectIdentifier.objectId);
        }
    }

    activeTicketObject() {

        if (this.activeTicketMainObject && this.activeTicketMainObject.objectId == this.objectIdentifier.objectId) {
            this.agentActiveObjectService.completeMainObject(this.activeTicketMainObject.id);
        } else {
            const objectAlias: string = 'tm.Ticket';
            const objectId: number = this.objectIdentifier.objectId;
            let mainObject: boolean = true;
            this.agentActiveObjectService.start(objectAlias, objectId, mainObject);
        }
    }

    handleComponentChange(event: string = '') {

        this.markAsDirty();
    }

    handleBroadcastChange() {
        this.broadcastSubscription = this.objectService.objectChangeEvent.subscribe(change => {
            if (change.objectType == "tm.TicketObject") {
                this.refreshComponent();
            }

            if (change.objectType == "dm.DmDocumentAssignment") {
                this.getAttachmentLength();
            }
            if (change.objectType == "tm.Ticket" && change.after['id'] == this.objectIdentifier.objectId) {
                const ticket = <TmTicketDto>change.after
                this.dto.status = ticket.status;
            }
        });
    }

    refreshTicketAppointments() {

        const objectRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(
                true,
                false,

                ObjectRequestComplexNode.createSimpleAttributeNode("ticket"),
                ObjectRequestComplexNode.createSimpleAttributeNode("appointment")
            ),
            [
                FilterCriteria.create(
                    'ticket',
                    FilterCriteria.cOperatorEqual,
                    this.objectIdentifier.objectId
                ),
            ],
            [new Sorting("createdDate", false)],
            null
        );

        this.ticketAppointmentService.list(objectRequest).subscribe(res => {
            this.ticketAppointmentList = res;
        });
    }

    handleAddTicketAction(event: string) {
        this.currentView = 'create-ticket-action';
        this.modeType = event;
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        if (this.activeObjectsSubscription) {
            this.activeObjectsSubscription.unsubscribe();
        }

        if (this.broadcastSubscription) {
            this.broadcastSubscription.unsubscribe();
        }

        if (this.paramSubscription) {
            this.paramSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    handleCreateTicketAction(event: TmCreateTicketAction) {
        this.currentView = event.mode;
        if (event.forceRefresh) {
            this.refreshComponent();
        }
    }

    postInit() {
    }

    handleInitiateProcess(event: ObjectIdentifier) {
        this.handleProcessQueryParam(event);
    }


    handleProcessQueryParam(event: ObjectIdentifier) {
        const currentParams = this.route.snapshot.queryParams;
        const objectRoute = currentParams['tid-sbr'];
        const paths = objectRoute.split(',');
        let updatedTidSbr: string;
        if (paths.length == 1) {
            updatedTidSbr = `${objectRoute},${event.objectType}:${event.objectId}`;
        } else if (paths.length == 2) {
            updatedTidSbr = `${paths[0]},${event.objectType}:${event.objectId}`;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                'tid-sbr': updatedTidSbr,
                'tid-sbr-action': currentParams['tid-sbr-action']
            },
            queryParamsHandling: 'merge'
        });
    }

    compareObjects(obj1: string, obj2: string): boolean {
        const identifier1 = this.createIdentifierFromString(obj1);
        const identifier2 = this.createIdentifierFromString(obj2);

        if (identifier1.objectType == identifier2.objectType) {
            return true;
        }
        return false;

    }

    createIdentifierFromString(obj: string): ObjectIdentifier {
        const paths = obj.split(':');
        return new ObjectIdentifier(paths[0], +paths[1]);
    }


    /**
     * Handle active object via route.
     * @param objectRoute
     */
    handleActiveObject(objectRoute: string) {
        const paths = objectRoute.split(',');
        const sameObjectType = this.compareObjects(paths[0], paths[1]);
        const identifier = this.createIdentifierFromString(paths[1]);
        if (sameObjectType && identifier.objectId == this.objectIdentifier.objectId) {
            return;
        }
        const process = paths[paths.length - 1];
        const processPath = process.split(':');
        const ticketActionObjectIdentifier = new ObjectIdentifier(processPath[0], +processPath[1]);
        this.ticketActionObjectIdentifier = ticketActionObjectIdentifier;
        // Derive this step from the API, this is always required if the user hits refresh in the Browser!!!!
        this.retrieveTicketActionById(ticketActionObjectIdentifier.objectId);

    }

    setActiveObject(ticketAction: TicketActionDto) {

        if (!ticketAction) {
            return;
        }

        switch (ticketAction.ticketTypeActionDto.internalType) {
            case TmTicketTypeActionInternalTypeEnum.ticket:

                this.ticketDefaultDto = {typeDtoId: ticketAction.ticketTypeActionDto.actionTicketTypeDtoId} as unknown as DtoDetail;
                this.ticketFormControlOverwrite = MvsFormControlOverwrite.createHideAll('assigneeAgentDtoId', 'assigneeAgentPoolDtoId');
                this.ticketFormControlOverwrite.addField("typeDtoId", MvsFormFieldAccessEnum.READ);

                this.currentView = 'show-ticket';

                const entry: DtoImportObjectContextEntry = new DtoImportObjectContextEntry('tm.TicketAction', ticketAction.id);
                this.ticketImportObjectContext = new DtoImportObjectContext([entry]);

                this.ticketIdentifier = new ObjectIdentifier('tm.Ticket', ticketAction.actionTicketDtoId);
                this.addToBreadcrumb('Sub-Ticket');

                break;

            case TmTicketTypeActionInternalTypeEnum.process:

                this.currentView = 'show-process';
                this.processIdentifier = new ObjectIdentifier('wf.WfProcess', ticketAction.processDtoId);
                this.addToBreadcrumb('Process');

                break;

            case TmTicketTypeActionInternalTypeEnum.notification:

                if (ticketAction.notificationDtoId) {
                    this.currentView = 'show-notification-sent';
                    this.notificationIdentifier = new ObjectIdentifier("ns.NsNotification", ticketAction.notificationDtoId);
                    this.addToBreadcrumb('Notification');
                } else {
                    this.currentView = 'show-notification-manual';
                    this.notificationIdentifier = new ObjectIdentifier("tm.TicketAction", ticketAction.id);
                    this.notificationSourceTypeId = ticketAction.ticketTypeActionDto.id;
                    this.addToBreadcrumb('Send Notification Manually');
                }
                break;
        }

    }

    retrieveTicketActionById(ticketActionId: number) {

        if (this.busy) {
            return;
        }
        this.busy = true;

        const filterCriteria = [
            FilterCriteria.create('id', FilterCriteria.cOperatorEqual, ticketActionId),
            FilterCriteria.create('ticket', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
        ]

        const request = this.getObjectRequestForTicketActions(filterCriteria);

        this.ticketActionService.list(request).subscribe(res => {
            this.busy = false;
            this.setActiveObject(res.entries[0]);
        }, error => {
            this.busy = false;
        });

    }

    handleActionChange(ticketAction: TicketActionDto) {
        this.getTicketActionList();
    }

    handleBreadcrumbClick(event: MenuItem) {

        //TODO: @Haris, you'll go to prison for this! We must never check on the LABEL!
        if (event.item['label'] == 'Ticket') {
            this.navigateToMainPage();
        }
    }

    navigateToMainPage() {

        if (this.subTicketActive) {
            this.onBackNavigation.emit();
            return;
        }

        this.currentView = 'overview';
        this.visibleSections = [];
        this.ticketActionObjectIdentifier = null;
        this.removeFromParam();
        this.removeFromBreadcrumb('Process');
        this.refreshComponent();
    }

    removeFromParam() {
        const currentParams = this.route.snapshot.queryParams;

        const objectRoute = currentParams['tid-sbr'];
        const paths = objectRoute.split(',');
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                'tid-sbr': paths[0],
                'tid-sbr-action': currentParams['tid-sbr-action']
            },
            queryParamsHandling: 'merge'
        });
    }

    completeTicket() {
        if (this.currentView == 'completeTicket') {
            this.currentView = this.previousView;
        } else {
            this.previousView = this.currentView;
            this.currentView = 'completeTicket';
        }
        this.getTicketActionList();
        this.getAvailableTicketActions();
    }

    handleChangeObject(event: ObjectChangeInformation) {
        this.currentView = this.previousView;
        this.getTicketActionList();
        this.getAvailableTicketActions();
    }

    /**
     * trigger function when ticket is created manually
     * @param event
     */
    handleObjectChangeManualTicket(event: ObjectChangeInformation) {
        // const ticketActionDto: TicketActionDto = new TicketActionDto();
        // ticketActionDto.id = this.ticketActionObjectIdentifier.objectId;
        // ticketActionDto.actionTicketDtoId = event.after.id;
        // this.ticketActionService.update(ticketActionDto).subscribe(res => {
        //     this.ticketIdentifier = new ObjectIdentifier(event.objectType, event.after.id);
        // });
        this.ticketIdentifier = new ObjectIdentifier(event.objectType, event.after.id);
    }

}
