import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectComponent} from "@kvers/alpha-ui";
import {PersonDto} from "../../../../../../../pm/dto/person.dto";
import {DtoDetail} from "@kvers/alpha-core-common";

@Component({
    selector: 'check-is-person-customer',
    templateUrl: './check-is-person-customer.component.html',
})
export class CheckIsPersonCustomerComponent implements OnInit, OnChanges, OnDestroy {

    @Input() dto: DtoDetail;
    @Input() personsCustomer:  {customerId: number, personId: number}[];

    customerId: number;
    found: boolean = false;
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor() {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        const personId = this.dto['dto']['personBDto']['id'];

        if (!this.personsCustomer || !personId) {
            return;
        }
        const exists = this.personsCustomer.find(item => item.personId == personId);
        if (exists) {
            this.found = true;
            this.customerId = exists.customerId;
        } else {
            this.found = false;
        }
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["personsCustomer"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
