import {Component, Input} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {DomSanitizer, SafeResourceUrl, SafeUrl} from "@angular/platform-browser";
import {DmDocumentService} from "../../service/api/dm-document.service";
import {TicketTypeService} from "../../../tm/service/api/ticket-type.service";
import {TicketTypeObjectTypeService} from "../../../tm/service/api/ticket-type-object-type.service";
import {WidgetData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DmDocumentDto} from "../../dto/dm-document.dto";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {DmAssignmentTypeObjectTypeService} from "../../service/api/dm-assignment-type-object-type.service";
import {DmDocumentAssignmentService} from "../../service/api/dm-document-assignment.service";
import {DmDocumentAssignmentTypeEnum} from "../../enum/dm-document-assignment-type.enum";
import {DmDocumentAssignmentDto} from "../../dto/dm-document-assignment.dto";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {AgentActiveObjectService} from "../../../am/service/api/agent-active-object.service";
import {AgentActiveObjectDto} from "../../../am/dto/agent-active-object.dto";
import {Subscription} from "rxjs";
import {WidgetHelperButton} from "@kvers/alpha-core-common";
import {ObserverService} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-dm-document-component',
    templateUrl: './dm-document.component.html',
    styleUrls: ['./dm-document.component.scss']
})
export class DmDocumentComponent extends ObjectBaseComponent {

    documentPdfSource: SafeResourceUrl;
    resultAnalyze: any;
    document: DmDocumentDto;
    toggleDocument: boolean;
    widgetDocument: WidgetData;
    widgetTableDocument: WidgetData;
    keyValuePairs: any;
    // widgetTableObject: WidgetData;
    formData: any;
    documentAssignmentObjectTypesDtoList: DtoList;
    selectedAssignmentType: number = 1;
    selectedAssignmentTypeObjectType: number;
    selectedObjectId: number;
    documentAssignment: boolean;
    selectedObjectTypeAlias: string;
    selectedKeyValue: any;
    expandPdf: boolean = false;
    documentAssignmentType = [
        {
            name: "Primary",
            value: DmDocumentAssignmentTypeEnum.primary
        },
        {
            name: "Secondary",
            value: DmDocumentAssignmentTypeEnum.secondary
        }
    ]

    isPdf:boolean;
    isImage:boolean;
    dto: DmDocumentDto;

    protected dmAssignmentTypeObjectTypeService: DmAssignmentTypeObjectTypeService;
    protected dmDocumentAssignmentService: DmDocumentAssignmentService;
    protected objectType: ObjectTypeService;

    constructor(
        protected agentActiveObjectService: AgentActiveObjectService,
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected documentService: DmDocumentService,
        private sanitizer: DomSanitizer,
        protected override observerService: ObserverService) {

        super(coreService, messageService, confirmationService, observerService);

        this.objectIdentifier = new ObjectIdentifier("dm.DmDocument", 19533);

        // documents IDs
        // 19533
        // 19611
        // 18738
        // 520418
        // 520422
        // 520424
        // 520426
        // 520427
        // 520430
        // 520432
    }

    test() {

        const ticketTypeService = <TicketTypeService>this.coreService.getCrudService("tm.TicketType");

        ticketTypeService.get(123).subscribe(dtoTicketType => {

            const ticketTypeObjectTypeService = <TicketTypeObjectTypeService>this.coreService.getCrudService("tm.TicketTypeObjectType");

            ticketTypeObjectTypeService.listByAttribute("ticketType", dtoTicketType.id).subscribe(dtoList => {


            })

        });


    }


    onObjectChanged() {
        super.onObjectChanged();

        // Determine the type of the file
        this.isPdf = this.dto.mimeType === "application/pdf";
        this.isImage = this.dto.mimeType.startsWith("image/");

        this.refreshDocument();

        /*
            const account = stubbedPublicClientApplication.getAllAccounts()[0];
            const myMSALObj = new this.msalService.PublicClientApplication(msalConfig);

            const accessTokenRequest = {
              scopes: ['basic.access'],
              account: account,
            };


        this.msalService.acquireTokenSilent(accessTokenRequest).subscribe(value => {
        });
        */
        this.documentService.downloadViaUrl(this.dto["id"]).subscribe(value => {
            this.documentPdfSource = this.sanitizer.bypassSecurityTrustResourceUrl(value + "#view=FitH&navpanes=0");
        });

        // this.documentPdfSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.crudService.getApiUrl() + "/data/" + this.dto["id"] + "/$");


    }

    /*
    keyValuePairs : { confidence, key.content, value.content}
    pages: { lines : [ content ]}
    paragraphs: { content, role }
    tables: [{ cells : { content, kind }}]
     */


    ngOnInit(): void {
        super.ngOnInit();

        // refresh component
        this.initComponent();
        if(!this.uiMode) {
            this.uiMode = 'light';
        }

    }

    initComponent() {

        // INITIALIZING THE SERVICES
        this.objectType = <ObjectTypeService>this.coreService.getCrudService("cc.ObjectType");
        this.dmAssignmentTypeObjectTypeService = <DmAssignmentTypeObjectTypeService>this.coreService.getCrudService("dm.DmAssignmentTypeObjectType");
        this.dmDocumentAssignmentService = <DmDocumentAssignmentService>this.coreService.getCrudService("dm.DmDocumentAssignment");

    }

    refreshDocument() {

        this.loadDocument();

        this.refreshWidgetDocumentAssignmentStatus();

        this.refreshAssignedDocuments();

        this.loadDocumentAssignmentTypesObjectTypes();

        this.handlePinnedObjects();

    }

    loadDocument() {

        this.documentService.get(this.objectIdentifier.objectId).subscribe(
            (res: DmDocumentDto) => {
                this.document = res;
                this.toggleDocument = res.analyzed;
                if (res.analyzed) {
                    this.loadFormData();
                }
            }
        )
    }

    /**
     * the following function is triggered when the document is not analyzed
     */
    onAnalyze(): void {
        this.busy = true;
        // this.dto["id"]
        this.documentService.analyze(this.objectIdentifier.objectId).subscribe(value => {
            this.resultAnalyze = value;
            this.messageService.showSuccessMessage("Form Recognizer", "Analyzed!");
            this.busy = false;
            // this.refreshComponent();
        });
    }

    refreshWidgetDocumentAssignmentStatus() {

        this.widgetDocument = new WidgetData();
        this.widgetDocument.id = 1;
        this.widgetDocument.idAlias = "document-test";
        this.widgetDocument.name = "Status";
        this.widgetDocument.uiComponent = "data";
        this.widgetDocument.dataProvider = "list";
        this.widgetDocument.dataSource = "entity";
        this.widgetDocument.dataProviderObject = "dm.DmDocument";

        const filterCriteriaList = [];
        filterCriteriaList.push(FilterCriteria.createSingleCondition('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId, null));

        this.widgetDocument.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteriaList,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 5)
        )
        this.widgetDocument.dataProviderObject = this.objectIdentifier.objectType;
        this.widgetDocument.setParamValue("objectId", this.objectIdentifier.objectId);
        //top, left, none
        this.widgetDocument.setParamValue("labelPosition", 'top');
        //entries per row
        this.widgetDocument.setParamValue("columns", 2);
        this.widgetDocument.setParamValue("spacing", 1);
        // this.widgetDocument.setParamValue("defaultFormFields", ["id",
        //     "status",
        //     "analyzed",
        //     "mimeType"]
        // );
    }

    refreshAssignedDocuments() {
        this.widgetTableDocument = new WidgetData();
        this.widgetTableDocument.id = 1;
        this.widgetTableDocument.name = 'Zugewiesene Dokumente';
        this.widgetTableDocument.idAlias = 'dm.assigned.documents';

        this.widgetTableDocument.uiComponent = 'table';
        this.widgetTableDocument.dataProvider = 'list';
        this.widgetTableDocument.dataSource = 'entity';
        this.widgetTableDocument.dataProviderObject = 'dm.DmDocumentAssignment';

        const filterCriteriaList = [];

        filterCriteriaList.push(FilterCriteria.createSingleCondition('document', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId, null));

        this.widgetTableDocument.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteriaList,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 5)
        )

        this.widgetTableDocument.setParamValue("selectionMode", "single");
        this.widgetTableDocument.setParamValue("size", "S");
    }

    onDocumentSelect(objectIdentifier: ObjectIdentifier) {
        console.log(objectIdentifier);
    }

    /**
     * the following function is triggered on page load but if the document is analyzed
     */
    loadFormData() {
        this.documentService.getFormData(this.objectIdentifier.objectId).subscribe(res => {
            this.formData = res;
        })
    }

    /**
     * the following function is called on page load to retrieve all object types
     */
    loadDocumentAssignmentTypesObjectTypes() {
        const dtoListRequest = new ObjectRequestList(false, [], []);

        this.dmAssignmentTypeObjectTypeService.list(dtoListRequest).subscribe(res => {
            this.documentAssignmentObjectTypesDtoList = res;
        })
    }

    /**
     * the following function is called when we select assignmentTypeObjectType to get the alias using objectTypeDtoId
     */
    onObjectTypeSelected() {
        this.objectType.get(this.selectedAssignmentTypeObjectType).subscribe(
            (res: ObjectTypeDto) => {
                this.selectedObjectTypeAlias = res.alias;
            }
        )
    }

    /**
     * the following function is used to assign the document to an object e.g: customer/insurance
     */
    onDocumentAssignment() {

        let documentAssignment: DmDocumentAssignmentDto = new DmDocumentAssignmentDto();

        documentAssignment.documentDtoId = this.objectIdentifier.objectId;
        documentAssignment.documentAssignmentType = this.selectedAssignmentType;
        documentAssignment.objectId = this.selectedObjectId;
        documentAssignment.objectTypeDtoId = this.selectedAssignmentTypeObjectType;

        this.busy = true;

        this.dmDocumentAssignmentService.create(documentAssignment).subscribe(res => {
            this.messageService.showSuccessMessage("Document", "Assigned!");
            this.busy = false;
            this.refreshComponent();
        })
    }

    /**
     * the following function is triggered when we select an object from mvs-search-entity
     * @param event
     */
    onObjectSelected(event) {
        this.selectedObjectId = event.id;
    }

    onSelectedAssignmentType(event) {
        if (event.checked) {
            this.selectedAssignmentType = 1;
        } else {
            this.selectedAssignmentType = 0;
        }
    }

    handleKayValuePairs(event) {
        this.keyValuePairs = event;
    }

    onRowHover(pair: any): void {
        // Handle hover
        this.selectedKeyValue = pair;
    }

    /**
     * the following function will delete the corresponding assignee of document
     * @param event
     */
    onDocumentDelete(event) {
        console.log(event);
        // this.busy = true;
        // this.dmDocumentAssignmentService.delete(event.objectId).subscribe(res => {
        //     this.messageService.showInfoMessage("Assignee", "Deleted!");
        //     this.busy = false;
        //     this.refreshComponent();
        // })
    }

    onClick(object) {
        console.log(object);
    }

    handlePinnedObjects() {
        this.activeObjectsSubscription = this.agentActiveObjectService.subjectAgentActiveObjects.subscribe(value => {
            this.pinnedObject = value.find(object => object.objectId == this.objectIdentifier.objectId);
            this.handleWidgetButtons()
        })
    }

    handleWidgetButtons() {
        this.widgetButtons = [
            {
                label: null,
                icon: this.pinnedObject ? `fa-solid fa-thumbtack` : `fa-regular fa-thumbtack text-700`,
                display: true,
                action: 'pinObject',
                tooltip: 'Pin Document'
            },
        ];

    }

    handleButtonClick(event: WidgetHelperButton) {

        if (event.action === 'pinObject') {

            this.togglePinObject();
            this.widgetButtons[0].icon = this.pinnedObject ? `fa-solid fa-thumbtack` : `fa-regular fa-thumbtack text-700`;

        }

    }

    widgetButtons: WidgetHelperButton[];
    activeObjectsSubscription: Subscription;
    pinnedObject: AgentActiveObjectDto;

    togglePinObject() {
        if (this.pinnedObject) {
            this.agentActiveObjectService.complete(this.pinnedObject.id);

        } else {
            this.agentActiveObjectService.start(this.objectIdentifier.objectType, this.objectIdentifier.objectId);
        }
    }

    handlePdfExpand() {
        this.expandPdf = !this.expandPdf;
    }
}
