import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    LgCalculationLogicTypeObjectComponent
} from "../../component/object-components/calculation-logic-type-object-component/lg-calculation-logic-type-object.component";

@Injectable({
    providedIn: 'root'
})
export class CalculationLogicTypeCommentService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/lg/calculationLogicTypeComments')
    }

    getCommentAttribute():string {
        return 'comment';
    }

    getLinkedAttribute(objectType: string): string {
        return 'calculationLogicType';
    }
}