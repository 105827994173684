import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

@Injectable()
export class CircuitBreakerInterceptor implements HttpInterceptor {

    private requestCount: number = 0;
    private requestWindow: number = 1000;
    private readonly requestThreshold: number = 500;
    private isCircuitOpen: boolean = false;
    private faultyURL: string;

    private urlRequestMap: Map<string, number[]> = new Map();
    private readonly duplicateRequestThreshold: number = 500;
    private readonly duplicateRequestWindow: number = 1000;

    constructor() {
        setInterval(() => this.requestCount = 0, this.requestWindow);
        setInterval(() => this.cleanupUrlRequestMap(), 10000);

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isCircuitOpen) {
            return throwError(() => new Error(`Endless loop at ${this.faultyURL}. All requests are blocked for this session.`));
        }

        // if (req.url.includes('photo')) {
        //     return next.handle(req);
        // }

        this.requestCount++;
        this.trackRequest(req.url);

        if (this.requestCount > this.requestThreshold && req.responseType != 'blob') {
            this.openCircuit();
            return throwError(() => new Error('Circuit breaker is active due to too many requests.'));
        }

        if ( req.responseType != 'blob' && this.isRequestFlooding(req.url)) {
            this.faultyURL = req.url;
            this.openCircuit();
        }

        return next.handle(req);
    }

    private openCircuit() {
        this.isCircuitOpen = true; // circuit closed, no more requests during this time
    }

    /**
     * track each request
     */
    private trackRequest(url: string) {
        const now = Date.now();
        if (!this.urlRequestMap.has(url)) {
            this.urlRequestMap.set(url, []);
        }
        const timestamps = this.urlRequestMap.get(url)!;
        timestamps.push(now);

        // Remove outdated timestamps outside the request window
        this.urlRequestMap.set(url, timestamps.filter(timestamp => now - timestamp < this.duplicateRequestWindow));
    }

    /**
     * check for duplicate request's threshold
     */
    private isRequestFlooding(url: string): boolean {
        const timestamps = this.urlRequestMap.get(url);
        return timestamps.length > this.duplicateRequestThreshold;
    }

    /**
     * cleanup our hashmap after a set interval (can be set in constructor)
     */
    private cleanupUrlRequestMap() {
        const now = Date.now();
        this.urlRequestMap.forEach((timestamps, url) => {
            const filteredTimestamps = timestamps.filter(timestamp => now - timestamp < this.duplicateRequestWindow);
            if (filteredTimestamps.length === 0) {
                this.urlRequestMap.delete(url);
            } else {
                this.urlRequestMap.set(url, filteredTimestamps);
            }
        });
    }
}