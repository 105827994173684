import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    EntityPriorityHandler, FilterCriteria,
    MvsCoreService,
    ObjectIdentifier,
    ObjectRequestList
} from "@kvers/alpha-core-common";
import {PageComponent} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin, Observable, Subscription} from "rxjs";
import {ReportService} from "../../service/api/report.service";
import {ModuleModel} from "../../dto/module.dto";
import {CategoryModel} from "../../dto/category.dto";
import {ReportFieldGroupEnum, ReportModel} from "../../dto/report-model.dto";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ReportFilterService} from "../../service/api/report-filter.service";
import {ReportFilterDto} from "../../dto/report-filter.dto";
import {MenuItem} from "primeng/api";
import {ReportAgentFavoriteService} from "../../service/api/report-agent-favorite.service";
import {ReportAgentFavoriteDto} from "../../dto/report-agent-favorite.dto";
import {ContextMenu} from "primeng/contextmenu";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {AgentService} from "../../../am/service/api/agent.service";
import {AgentDto} from "../../../am/dto/agent.dto";
import {ReportDateUtilService} from "../../service/util/report-date-util-service";

export interface PeriodFilter {
    label: string;
    value: string;
}

@Component({
    selector: 'rp-report-page',
    templateUrl: './rp-report.page.html',
    styleUrls: ['./rp-report.page.scss']
})
export class RpReportPage extends PageComponent implements OnInit, OnDestroy {

    moduleList: ModuleModel[];
    categoryList: CategoryModel[];
    reportList: ReportModel[];

    periodFilter: PeriodFilter[] = [
        { label: 'All', value: 'all' },
        { label: 'Current Month', value: 'currentMonth' },
        { label: 'Current Year', value: 'currentYear' },
        { label: 'Current Week', value: 'currentWeek' }
    ];

    selectedModule: ModuleModel | null = null;
    selectedCategory: CategoryModel | null = null;
    selectedReport: ReportModel | null = null;

    selectedPeriod = 'currentMonth';

    objectIdentifier: ObjectIdentifier;

    toggleFilters: boolean = true;

    reportFilterList: ReportFilterDto[];
    reportAgentFavoriteDtoList: ReportAgentFavoriteDto[];
    selectedFilter: ReportFilterDto;
    contextMenuId: number;
    slideMenuItems: MenuItem[];
    uiLayoutContextMenuItems: MenuItem[];
    manageFavoritesSidebar: boolean = false;
    groupContextMenuId: number;
    loggedOnAgent: AgentDto;
    refreshCounter: number = 0;

    isDataLoaded: boolean;
    isReportGenerated: boolean;

    roleBasedFilter: FilterCriteria[]

    @ViewChild('cmLayout') layoutContextMenu: ContextMenu;

    private paramSub: Subscription;

    constructor(
        protected reportService: ReportService,
        protected reportFilterService: ReportFilterService,
        protected reportAgentFavoriteService: ReportAgentFavoriteService,
        protected navigationService: MvsObjectNavigationService,
        protected agentService: AgentService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected coreService: MvsCoreService,
        protected reportDateUtilService: ReportDateUtilService) {
        super(route, coreService);
    }

    ngOnInit(): void {

        this.getLoggedOnAgent();
        this.prepareSlideMenuItems();
        this.getReportAgentFavorite();
        this.getReportFilters();

        this.paramSub = this.route.queryParams.subscribe((params) => {
            const moduleId = params['moduleId'];
            const categoryId = params['categoryId'];
            const reportId = params['reportId'];

            if (moduleId) {
                if (this.selectedModule?.name != moduleId) {
                    this.refreshModules(moduleId, categoryId, reportId);
                }
            } else {
                if (!this.initialized) {
                    this.refreshModules();
                }
            }

        });

        super.ngOnInit();
        this.initialized = true;
    }

    /**
     * Fetch modules from server, optionally pre-select if params exist.
     * @param moduleId
     * @param categoryId
     * @param reportId
     */
    refreshModules(moduleId?: string, categoryId?: number, reportId?: number) {
        this.categoryList = null;
        this.reportList = null;
        this.reportService.getModules().subscribe(res => {
            this.moduleList = res;
            this.selectedModule = this.moduleList.find((m) => m.name === moduleId) || null;
            if (categoryId) {
                this.refreshCategories(categoryId, reportId);
            } else {
                this.refreshCategories();
            }
        }, error => {
            this.moduleList = null;
        });
    }

    /**
     * Fetch categories for the selected module.
     * @param categoryId
     * @param reportId
     */
    refreshCategories(categoryId?: number, reportId?: number) {

        // this.isReportGenerated = false;
        this.selectedCategory = null;
        this.selectedReport = null;
        this.categoryList = null;
        this.reportList = null;
        if (!categoryId) {
            this.updateRoute()
        }

        if (!this.selectedModule?.name) {
            return;
        }

        this.reportService.getCategories(this.selectedModule.name).subscribe(res => {
            this.categoryList = res;
            this.selectedCategory = this.categoryList.find((c) => c.id === Number(categoryId)) || null;
            if (this.selectedCategory && reportId) {
                this.refreshReports(reportId);
            }else {
                this.refreshReports();
            }
        }, error => {
            this.categoryList = null;
        });
    }

    /**
     * Fetch reports for the selected module + category.
     * @param reportId
     */
    refreshReports(reportId?: number) {

        // this.isReportGenerated = false;
        this.selectedReport = null;
        this.reportList = null;
        this.roleBasedFilter = null;
        if (!reportId) {
            this.updateRoute();
        }

        if (!this.selectedModule?.name || !this.selectedCategory?.id) {
            return;
        }
        this.reportService.getReports(this.selectedModule?.name, this.selectedCategory?.id).subscribe(res => {
            this.reportList = res;
            this.selectedReport = this.reportList.find((r) => r.id === Number(reportId)) || null;
            if (this.selectedReport?.id) {
                this.handleRoleFilters();
            }
        }, error => {
            this.reportList = null;
        });
    }

    /**
     * Get current logged-on agent info.
     */
    getLoggedOnAgent() {
        this.agentService.me().subscribe(res => {
            this.loggedOnAgent = res;
        })
    }

    /**
     * Update the URL query params whenever user changes module/category/report
     */
    updateRoute(): void {
        this.isDataLoaded = false;
        const moduleId = this.selectedModule?.name ?? '';
        const categoryId = this.selectedCategory?.id ?? '';
        const reportId = this.selectedReport?.id ?? '';

        this.router.navigate(
            ['/rp', 'reports'],
            {
                queryParams: {
                    moduleId,
                    categoryId,
                    reportId
                },
                queryParamsHandling: 'merge'
            }
        );

    }

    /**
     * Toggle the filter section on or off.
     */
    toggleFilterSection() {
        this.toggleFilters = !this.toggleFilters;
    }

    /**
     * Open the side navigation to create/edit a favorite record.
     * @param favorite
     */
    handleFavorites(favorite?: ReportAgentFavoriteDto) {
        const objectNavigationActionEnum = favorite ? MvsObjectNavigationActionEnum.edit : MvsObjectNavigationActionEnum.create;
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('rp.ReportAgentFavorite', favorite?.id, null, "Object", null, null, objectNavigationActionEnum);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    /**
     * Get the full list of saved filters.
     */
    getReportFilters() {

        const objectRequestList = ObjectRequestList.createBasic(false, [], []);

        this.reportFilterService.list(objectRequestList).subscribe(res => {
            this.reportFilterList = res.entries;

        });
    }

    /**
     * Get the user's saved favorites.
     */
    getReportAgentFavorite() {

        const objectRequestList = ObjectRequestList.createBasic(false, [], []);

        this.reportAgentFavoriteService.list(objectRequestList).subscribe(res => {
            this.reportAgentFavoriteDtoList = res.entries;
        })
    }

    /**
     * Called when a favorite is clicked - updates route to reflect that favorite’s module/category/report
     * @param favorite
     */
    getFavoriteInfo(favorite: ReportAgentFavoriteDto) {
        this.isReportGenerated = true;
        const moduleId = favorite.module ?? '';
        const categoryId = favorite.categoryId ?? '';
        const reportId = favorite.reportId ?? '';

        this.router.navigate(
            ['/rp', 'reports'],
            {
                queryParams: {
                    moduleId,
                    categoryId,
                    reportId
                },
                queryParamsHandling: 'merge'
            }
        );
    }

    /**
     * Right-click context menu on favorite entry (update context menu items dynamically).
     * @param mouseEvent
     * @param favorite
     */
    onUiSelectedLayoutContextMenu(mouseEvent: MouseEvent, favorite: ReportAgentFavoriteDto) {
        this.groupContextMenuId = favorite.id;
        this.prepareLayoutContextMenu(favorite);
        this.layoutContextMenu.show(mouseEvent);
    }

    prepareLayoutContextMenu(favorite: ReportAgentFavoriteDto) {
        let contextMenuItems: MenuItem[] = [
            {label: 'Bearbeiten', icon: 'fas fa-pencil', command: () => this.handleFavorites(favorite)},];
        this.uiLayoutContextMenuItems = contextMenuItems;
    }

    /**
     * Slide menu items (when user clicks the ellipsis button near Favorites).
     */
    prepareSlideMenuItems() {
        this.slideMenuItems = [
            {
                label: 'Manage Favorites',
                icon: 'fas fa-pencil',
                command: () => {
                    this.handleManageFavorites();
                }
            }
        ];
    }

    /**
     * Show sidebar for reorganizing favorites.
     */
    handleManageFavorites() {
        this.manageFavoritesSidebar = true;
    }

    onUiHideContextMenu() {
        this.groupContextMenuId = null;
    }

    /**
     * Drag and drop to reorder favorites, then call backend to persist new priorities.
     * @param event
     */
    adjustPriority(event: CdkDragDrop<any[]>): void {
        moveItemInArray(this.reportAgentFavoriteDtoList, event.previousIndex, event.currentIndex);
        let operations = EntityPriorityHandler.adjustPriority(this.reportAgentFavoriteDtoList, "orderPriority", 50);

        let updatedArray = [];

        for (const item of operations) {
            updatedArray.push(item.entry);
        }

        const entryObservables: Observable<any>[] = updatedArray.map(entry => {
            return this.reportAgentFavoriteService.update(entry);
        });

        forkJoin(entryObservables).subscribe(value => {
        });

    }

    /**
     * Remove a favorite from the user’s list.
     * @param favorite
     */
    removeFromFavorite(favorite: ReportAgentFavoriteDto) {

        if (this.busy) {
            return;
        }
        this.busy = true;
        this.reportAgentFavoriteService.delete(favorite.id).subscribe(res => {
            this.getReportAgentFavorite();
            this.busy = false;
        });
    }

    /**
     * Generate the currently selected report by refreshing the rp-report-component.
     */
    generateReport() {
        this.isReportGenerated = true;
        this.refreshCounter++;
    }

    /**
     * Add the currently selected module/category/report to the favorites list.
     */
    addToFavorite() {
        const dto: ReportAgentFavoriteDto = new ReportAgentFavoriteDto();
        dto.agentDtoId = this.loggedOnAgent?.id;
        dto.orderPriority = 10;
        dto.module = this.selectedModule?.name;
        dto.categoryId = this.selectedCategory?.id;
        dto.reportId = this.selectedReport?.id;
        dto.name = this.selectedReport?.name;

        this.reportAgentFavoriteService.create(dto).subscribe((res) => {
            this.getReportAgentFavorite();
        });
    }

    handleDataLoad() {
        this.isDataLoaded = true;
    }

    handleSelectReport() {
        this.isReportGenerated=false;
        this.handleRoleFilters();
    }

    handleRoleFilters() {
        this.roleBasedFilter = [];
        const reportFieldGroup = this.selectedReport?.reportFieldGroups.find(entries => entries.internalType == ReportFieldGroupEnum.KEY_DATE_DEPENDENT);

        if (reportFieldGroup) {
            const startEndDate = this.reportDateUtilService.getStartEndDates(this.selectedPeriod);
            if(startEndDate) {
                for (let field of reportFieldGroup.fields) {
                    const filter = FilterCriteria.create(field.name, FilterCriteria.cOperatorBetween, startEndDate.start, startEndDate.end);
                    this.roleBasedFilter.push(filter)
                }
            }
        }

    }

    getKeyDateDependent():boolean {
        return !!this.selectedReport?.reportFieldGroups.find(entries => entries.internalType == ReportFieldGroupEnum.KEY_DATE_DEPENDENT);
    }


    ngOnDestroy(): void {
        if (this.paramSub) {
            this.paramSub.unsubscribe();
        }
    }

}
