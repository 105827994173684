import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomerService} from "../../service/api/customer.service";
import {CustomerDto} from "../../dto/customer.dto";
import {
    ObjectIdentifier,
    WidgetHelperButton,
    EntityStatusEnum,
    ObjectRequestList,
    FilterCriteria, DtoImportObjectContext, ObjectRequestComplex, ObjectRequestComplexNode,
} from "@kvers/alpha-core-common";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ObjectTypeService} from "../../../cc/service/api/object-type.service";
import {TicketObjectService} from "../../../tm/service/api/ticket-object.service";
import {forkJoin, Observable} from "rxjs";
import {TicketTypeObjectRoleDto} from "../../../tm/dto/ticket-type-object-role.dto";
import {TicketTypeService} from "../../../tm/service/api/ticket-type.service";

@Component({
    selector: 'cr-customer-inline-component',
    templateUrl: './cr-customer-inline-component.html',
})
export class CrCustomerInlineComponent implements OnInit, OnChanges {

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() size: string = 'M';
    icon: string;
    dto: CustomerDto;
    toolbarButtons: WidgetHelperButton[] = [
        {
            label: 'Open in background',
            icon: 'fa-regular fa-send-backward',
            display: true,
            action: 'background',
            tooltip: 'Open in background'
        },
        {
            label: 'Open in new window',
            icon: 'fa-regular fa-arrow-up-right-from-square',
            display: true,
            action: 'newWindow',
            tooltip: 'Open in new window'
        }
    ];

    protected readonly EntityStatusEnum = EntityStatusEnum;
    ticketId: number;
    initialized: boolean;
    busy: boolean;

    constructor(protected customerService: CustomerService,
                protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService
                ) {
    }

    ngOnInit() {
        this.initComponent();
    }

    initComponent() {
        // const ticketEntry = this.importObjectContext.getEntry('tm.Ticket');
        //
        // if (ticketEntry) {
        //     this.ticketId = ticketEntry.id;
        // }
        this.icon = this.customerService.getObjectIcon();
        this.refreshComponent();

    }

    async refreshComponent() {
        // let objectTypeId : number
        // const objectId: number = this.objectIdentifier.objectId;

        // await this.objectService.getViaObjectType(this.objectIdentifier.objectType).then(res => {
        //     objectTypeId = res.id;
        // });

        // const filterCriteria = [];
        // filterCriteria.push(FilterCriteria.create('objectType', FilterCriteria.cOperatorEqual, objectTypeId));
        // filterCriteria.push(FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, objectId));
        //
        // if (this.ticketId) {
        //     filterCriteria.push(FilterCriteria.create('ticket', FilterCriteria.cOperatorEqual, this.ticketId));
        // }
        //
        // const objectRequest = new ObjectRequestList(true, filterCriteria, []);
        // objectRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
        //     ObjectRequestComplexNode.createSimpleAttributeNode('ticketTypeObjectRole'));

        this.customerService.get(this.objectIdentifier.objectId).subscribe((res: CustomerDto) => {
            this.dto = res;
            this.initialized = true;
        })

        // forkJoin({
        //     customerDto: this.customerService.get(this.objectIdentifier.objectId) as Observable<CustomerDto>,
        //     ticketObjectDto: this.ticketObjectService.list(objectRequest)
        // }).subscribe(({ customerDto, ticketObjectDto }) =>  {
        //     this.dto = customerDto;
        //     this.ticketObjectRoleDto = ticketObjectDto.entries[0]?.ticketTypeObjectRoleDto;
        //     console.log(this.ticketObjectRoleDto);
        //     this.initialized = true;
        // });
        // this.getTicketTypeRoles();

    }

    // getTicketTypeRoles() {
    //     const objectRequest = new ObjectRequestList(
    //         false,
    //         [FilterCriteria.create('ticket', FilterCriteria.cOperatorEqual, this.ticketId)],
    //         []
    //     );
    //     this.ticketTypeService.list(objectRequest).subscribe(res => {
    //         console.log(res);
    //     })
    // }

    // handleButtonClick(event: WidgetHelperButton) {
    //     const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
    //     if (event.action == 'background') {
    //         this.navigationService.addOverlay(navigationEntry)
    //     } else if (event.action == 'newWindow') {
    //         this.navigationService.handleObjectNavigation(this.objectIdentifier,null,{openNewTab:true});
    //     }
    // }

    handleClick() {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.addOverlay(navigationEntry)
    }

    ngOnChanges(changes: SimpleChanges) {

    }
}
