import {TicketActionDto} from "../../../../../dto/ticket-action.dto";
import {ObjectIdentifier} from "@kvers/alpha-core-common";

export class UiTicketActionDto {
    ticketAction: TicketActionDto;
    showComments: boolean;
    icon: string;
    buttonClass: string;
    tooltip: string;
    status: string;
    commentsIdentifier: ObjectIdentifier;
    isProcess: boolean;
    actionIcon: string;
    isTicket: boolean;
    isNotification: boolean;
    isStarted: boolean;
    showSimple: boolean;
}