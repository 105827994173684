<ng-container *ngIf="initialized">
    <div class="grid">

        <div class="lg:col-3 col-12 px-1">

            <mvs-widget *ngIf="contractWidgetData"
                        [widgetData]="contractWidgetData"
            >
            </mvs-widget>

            <mvs-widget *ngIf="customerContractWidgetData"
                        [widgetData]="customerContractWidgetData"
            >
            </mvs-widget>

            <mvs-widget *ngIf="myWidget"
                        [widgetData]="myWidget"
            >
            </mvs-widget>

            <mvs-widget *ngIf="contractFormerWidgetData"
                        [widgetData]="contractFormerWidgetData"
            >
            </mvs-widget>

            <mvs-widget *ngIf="contractDataWidgetData"
                        [widgetData]="contractDataWidgetData"
            >
            </mvs-widget>

            <div>
                <mvs-object-tags
                        headline="Tags"
                        [mainObject]="objectIdentifier"
                        linkedEntityObjectType="cm.ContractTag"
                        [readOnly]="true"
                ></mvs-object-tags>
            </div>

        </div>

        <div class="lg:col-9 col-12">

            <div class="grid">

                <div class="lg:col-6 col-12">

                    <mvs-widget *ngIf="contractPartnerWidgetData"
                                [widgetData]="contractPartnerWidgetData"
                    >
                    </mvs-widget>

<!--                    <mvs-widget *ngIf="contractStatusWidgetData"-->
<!--                                [widgetData]="contractStatusWidgetData"-->
<!--                                (onChangedObject)="handleContractStatusAdd($event)"-->
<!--                    >-->
<!--                    </mvs-widget>-->

                    <mvs-widget *ngIf="contractPriceWidgetData"
                                [widgetData]="contractPriceWidgetData"
                    >
                    </mvs-widget>

                    <mvs-widget *ngIf="contractOneTimePaymentWidgetData"
                                [widgetData]="contractOneTimePaymentWidgetData"
                    >
                    </mvs-widget>

                    <mvs-widget *ngIf="contractLabelWidget"
                                [widgetData]="contractLabelWidget">
                    </mvs-widget>


                </div>

                <div class="lg:col-6 col-12">


                    <mvs-widget *ngIf="contractAgentWidgetData"
                                [widgetData]="contractAgentWidgetData">
                    </mvs-widget>

                    <!-- Document Comments-->
                    <mvs-widget *ngIf="ticketWidget"
                                [widgetData]="ticketWidget"
                                (onObjectSelect)="handleTicketSelect($event)"
                                (onChangedObject)="handleTicketCreation($event)">
                    </mvs-widget>

                    <mvs-object-comments class="mvs-widget p-0 block h-full"
                            headline="Kommentare/ Vetragsinformationen"
                            [mainObject]="objectIdentifier"
                            linkedEntityObjectType="cm.ContractComment"
                    ></mvs-object-comments>
                </div>
            </div>




        </div>



    </div>
</ng-container>