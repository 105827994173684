<ng-container *ngIf="initialized">

    <div class="grid mb-3">

        <div class="col-12">

            <h4>Appointments</h4>


            <mvs-widget *ngIf="customerAppointments" [widgetData]="customerAppointments"></mvs-widget>

        </div>

    </div>

</ng-container>