import {Injectable, Type} from '@angular/core';
import {MvsCrudService, MvsFormFieldDto} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoList} from "@kvers/alpha-core-common";
import {RpReportObjectComponent} from "../../component/rp-report/rp-report-object.component";
import {Observable} from "rxjs";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {CustomerContactDto} from "../../../cr/service/dto/customer-contact.dto";
import {RpReportComponent} from "../../component/rp-report-component/rp-report.component";
import {ReportModel} from "../../dto/report-model.dto";
import {CategoryDto} from "../../../cm/dto/category.dto";
import {CategoryModel} from "../../dto/category.dto";
import {ModuleModel} from "../../dto/module.dto";

@Injectable({
    providedIn: 'root'
})
export class ReportService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/rp/reports');
    }

    /**
     * Retrieve Meta information for report.
     * @param id
     * @param dtoListRequest
     */
    public getMeta(id: number, dtoListRequest: ObjectRequestList) {

        const url: string = MvsCrudService.baseUrl + '/rp/reportRuntimes/' + id + '/meta';

        return this.http.post<DtoList>(url, dtoListRequest);

    }

    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
        // TODO: Ask marko: which component to show
        // return RpReportObjectComponent;
        return RpReportComponent
    }

    getModules(): Observable<ModuleModel[]> {
        return this.getGeneric<ModuleModel[]>(`/modules`);
    }

    getCategories(moduleName: string): Observable<CategoryModel[]> {
        return this.getGeneric<CategoryModel[]>(`/module/${moduleName}/categories`);
    }

    getReports(moduleName: string, category: number): Observable<ReportModel[]> {
        return this.getGeneric<ReportModel[]>(`/module/${moduleName}/category/${category}/reports`);
    }

    getFields(moduleName: string, reportId: number): Observable<MvsFormFieldDto[]> {
        return this.getGeneric<MvsFormFieldDto[]>(`/module/${moduleName}/fields/${reportId}`);
    }

    getReport(moduleName: string, reportId: number, dtoListRequest: ObjectRequestList): Observable<DtoList> {
        const url: string = MvsCrudService.baseUrl + `/rp/reports/module/${moduleName}/run/${reportId}`;

        return this.http.post<DtoList>(url, dtoListRequest);
    }

    download(moduleName: string, reportId: number, dtoListRequest: ObjectRequestList): Observable<Blob> {
        const url = MvsCrudService.baseUrl + `/rp/reports/module/${moduleName}/download/${reportId}`;

        // IMPORTANT: Use { responseType: 'blob' }
        return this.http.post(url, dtoListRequest, { responseType: 'blob' }) as Observable<Blob>;
    }

}
