import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoList,
    FilterCriteria,
    MvsCrudModeEnum,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    ObjectRequestList,
    Sorting,
    WidgetData,
} from "@kvers/alpha-core-common";
import {CalculationLogicTypeService} from "../../service/api/calculation-logic-type.service";
import {CalculationLogicTypeStepDto} from "../../dto/calculation-logic-type-step.dto";
import {CalculationLogicTypeStepService} from "../../service/api/calculation-logic-type-step.service";
import {MvsUiObjectService} from "@kvers/alpha-ui";


interface StepEntry {
    label: string,
    description: string,
    stepNumber: number,
    index: number,
    widgetData: WidgetData,
    logicId: number,
    data: CalculationLogicTypeStepDto
}

@Component({
    selector: 'lg-calculation-logic-type-step-component',
    templateUrl: 'lg-calculation-logic-type-step.component.html',
    styleUrls: ['lg-calculation-logic-type-step.component.scss']
})
export class LgCalculationLogicTypeStepComponent implements OnInit, OnChanges {

    @Input() calculationLogicTypeId: number;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    steps: StepEntry[];

    expandedSteps: { [key: number]: boolean } = {}; // Track expanded steps
    expandedLogics: { [key: number]: boolean } = {}; // Track expanded steps

    constructor(
        protected calculationLogicTypeService: CalculationLogicTypeService,
        protected calculationLogicTypeStepService: CalculationLogicTypeStepService,
        protected objectService: MvsUiObjectService,
    ) {
    }

    ngOnInit(): void {
        this.refreshCalculationSteps();

    }

    refreshCalculationSteps() {

        if (this.busy) {
            return;
        }

        this.busy = true;

        this.steps = [];

        const objectRequest = ObjectRequestList.createBasic(false, [FilterCriteria.create('calculationLogicType', FilterCriteria.cOperatorEqual, this.calculationLogicTypeId)], [new Sorting('orderPriority', false)]);


        this.calculationLogicTypeStepService.list(objectRequest).subscribe((response: DtoList<CalculationLogicTypeStepDto>) => {

            let stepNumber = 1
            for (let [index, entry] of response.entries.entries()) {

                const widgetData: WidgetData = this.calculationLogicTypeService.refreshCalculationLogicTypeStepMappingWidgetData(entry.id)
                const stepEntry: StepEntry = {
                    label: entry.name,
                    description: entry.description,
                    stepNumber,
                    index,
                    logicId: entry.logicDtoId,
                    widgetData,
                    data: entry
                }

                this.steps.push(stepEntry);

                stepNumber++;
            }


            this.initialized = true;
            this.busy = false;
        }, error => {
            this.steps = [];
            this.busy = false;
        })
    }

    toggleStep(index: number) {
        this.expandedSteps[index] = !this.expandedSteps[index]; // Toggle visibility
    }

    toggleLogic(index: number) {
        this.expandedLogics[index] = !this.expandedLogics[index]; // Toggle visibility
    }


    showStep(id = 0) {

        let formControlOverwrite = new MvsFormControlOverwrite();
        formControlOverwrite.addField('calculationLogicTypeDtoId', MvsFormFieldAccessEnum.READ);

        let defaultDto = new CalculationLogicTypeStepDto();
        defaultDto.calculationLogicTypeDtoId = this.calculationLogicTypeId

        this.objectService.openObjectViaDialog(
            null,
            "lg.CalculationLogicTypeStep",
            id,
            defaultDto,
            false,
            false,
            () => {
                this.refreshCalculationSteps();
            },
            MvsCrudModeEnum.create,
            null,
            formControlOverwrite
        );
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        this.refreshCalculationSteps();
    }

}
