import {Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {PersonService} from "../../../pm/service/api/person.service";
import {PmPersonDto} from "../../dto/pm-person.dto";
import {CustomerService} from "../../service/api/customer.service";
import {CrCustomerDto} from "../../dto/cr-customer.dto";
import {DtoDetail} from "@kvers/alpha-core-common";
import {PmPersonDetailDto} from "../../dto/pm-person-detail.dto";
import {PersonDetailService} from "../../../pm/service/api/person-detail.service";
import {Router} from "@angular/router";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {
    FlexibleSearchDataEvent
} from "@kvers/alpha-ui";
import {PersonSearchSelectEvent} from "../../../pm/component/person-search/person-search-select.event";

@Component({
    selector: 'mvs-cr-create-object-customer',
    templateUrl: './cr-create-object-customer.component.html',
    styleUrls: ['./cr-create-object-customer.component.scss']
})
export class CrCreateObjectCustomerComponent
    implements OnInit, OnDestroy, OnChanges {


    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    loadFormFields: boolean;
    stepItems: MenuItem[];

    activeIndex: number = 0;
    personId: number;
    personDetailId: number;
    // customerId: number;
    customerDtoDetail: CrCustomerDto = new CrCustomerDto();
    flexiblePersonSearchDto: DtoDetail;

    protected personService: PersonService;
    protected customerService: CustomerService;
    protected personDetailService: PersonDetailService;

    constructor(private confirmationService: ConfirmationService, public messageService: MessageService, protected coreService: MvsCoreService, private router: Router) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.personService = <PersonService>this.coreService.getCrudService("pm.Person");

        this.personDetailService = <PersonDetailService>this.coreService.getCrudService("pm.PersonDetail");

        this.customerService = <CustomerService>this.coreService.getCrudService("cr.Customer");


    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.stepItems = [
            {
                label: 'Person Suchen',
                icon: 'fa-regular fa-magnifying-glass'
                // command: (event: any) => this.messageService.add({severity:'info', summary:'First Step', detail: event.item.label})
            },
            {
                label: 'Details erfassen',
                icon: 'fa-regular fa-keyboard'
                // command: (event: any) => this.messageService.add({severity:'info', summary:'Second Step', detail: event.item.label})
            },
            {
                label: 'Kundenstamm anlegen',
                icon: 'fa-regular fa-keyboard'
                // command: (event: any) => this.messageService.add({severity:'info', summary:'Third Step', detail: event.item.label})
            },
        ];

        this.initialized = true;
    }

    /**
     * Handle person select.
     * @param objectIdentifier
     */
    handlePersonSelect(object: PersonSearchSelectEvent) {

        if (object.selectedTargetObject.objectType == 'cr.Customer') {
            const id = object.selectedTargetObject.objectId
            this.router.navigateByUrl('cr/customers/' + id);
        }
        // alert(objectIdentifier.data["firstName"]);

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    /**
     * Check when the object was created.
     * @param event
     */

    // this onPersonSave function is called on creating person: The First Step
    onPersonSave(event: ObjectChangeInformation) {
        this.loadFormFields = false;
        this.personId = event.after.id;
        this.activeIndex = 1;
    }

    // this onPersonDetailSave function is called on creating personDetail: The Second Step
    onPersonDetailSave(event: ObjectChangeInformation) {


        this.loadFormFields = false;
        this.busy = true;
        this.personDetailId = event.after.id;

        let person: PmPersonDto = new PmPersonDto();
        person.id = this.personId;
        person.personDetailDtoId = this.personDetailId;

        this.personService.update(person).subscribe((res: PmPersonDto) => {
            this.customerDtoDetail.personDtoId = res.id;
            this.busy = false;
            this.activeIndex = 2;
        }, error => {
            this.busy = false;
        })
    }

    // this onCustomerSave function is called on creating customer: The Third Step
    onCustomerSave(event: ObjectChangeInformation) {
        if (event.after.id) {
            this.router.navigate(['cr/test']);
        }
    }


    // this onActiveIndexChange function is used to validate whether we can interact with next step
    onActiveIndexChange(event) {
        if (
            (event == 0 && this.activeIndex != 0) ||
            (event == 1 && this.activeIndex != 1 && this.personId) ||
            (event > 0 && this.activeIndex != 2 && this.personDetailId)) {
            this.activeIndex = event;
            this.loadFormFields = false;
        }
    }

    // this handleLifeCycle function is used to validate whether form is loaded or not and based on its response we are hiding skeleton loader
    handleLifeCycle(event) {
        if (event.name == "dto2form") {
            this.loadFormFields = true;
        }
    }

    // this revertChanges function is used to confirm and delete person and personDetail saved information from backend
    revertChanges(event: Event) {
        if (this.personId) {
            this.confirmationService.confirm({
                target: event.target,
                message: 'Are you sure that you want to proceed?',
                icon: 'pi pi-exclamation-triangle',

                accept: () => {

                    this.personService.delete(this.personId).subscribe(res => {

                        if (this.personDetailId) {

                            this.personDetailService.delete(this.personDetailId).subscribe(res => {

                                this.messageService.add({
                                    severity: 'info',
                                    summary: 'Confirmed',
                                    detail: 'You have accepted'
                                });

                                this.personId = null;
                                this.personDetailId = null;
                                this.customerDtoDetail = null;
                                this.loadFormFields = false;
                                this.activeIndex = 0;
                            })
                        } else {
                            this.messageService.add({
                                severity: 'info',
                                summary: 'Confirmed',
                                detail: 'You have accepted'
                            });

                            this.personId = null;
                            this.customerDtoDetail = null;
                            this.loadFormFields = false;
                            this.activeIndex = 0;

                        }
                    })

                },

                reject: () => {
                    this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
                }
            });

        }
    }


    selectedPersonId: number;
    flexibleSearchResult: PmPersonDto;

    handlePersonObject(event: FlexibleSearchDataEvent) {
        this.selectedPersonId = event.id;
    }

    onCreateNewPersonForm() {

        if (!this.flexibleSearchResult) {
            this.forcePersonSearch();
            return;
        }

    }

    handlePersonSearch(event: DtoDetail) {
        this.flexiblePersonSearchDto = <PmPersonDto>event;
    }

    forcePersonSearch() {
        this.confirmationService.confirm({
            header: 'Alert',
            message: 'Bitte suchen Sie nach der Person, bevor Sie eine neue Person erstellen!',
            acceptLabel: 'Okay',
            rejectVisible: false, // Hide the cancel button
            accept: () => {
                // Action to perform when the user clicks "Okay"
                console.log('Okay button clicked');
                // Add your custom logic here
            }
        });
    }

    onCreateNewCustomerRelatedPerson() {

    }

}
