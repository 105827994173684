<ng-container *ngIf="initialized">

    <p-splitButton class="button-pill mx-2"
                   styleClass="filter-btn"
                   [label]=" householdPersonsCount ? 'Haushalt (' + householdPersonsCount + ')' : 'Haushalt'"
                   icon="fa-sharp fa-solid fa-house mx-2"
                   menuStyleClass="split-button-menu-hover"
                   [model]="householdPersons"
                   (contextmenu)="openContextMenu($event, contextMenu)"
                   (onClick)="handleHouseholdClick()">
    </p-splitButton>

    <p-contextMenu #contextMenu
                   [model]="items"
                   appendTo="body">
    </p-contextMenu>

</ng-container>