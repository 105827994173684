<ng-container *ngIf="initialized && document">

    <div class="grid m-0">
        <div class="col-12">
            <div class="grid">
                <div class="col-12 pt-0">
                    <p-panel>
                        <ng-template pTemplate="header">
                            <div class="flex justify-content-between align-items-center w-full">
                                <!--                                <span class="font-semibold text-xl">Document</span>-->
                                <div class="flex align-items-center gap-4">
                                    <p-toggleButton *ngIf="document.analyzed" [(ngModel)]="toggleDocument"
                                                    onLabel="HTML View"
                                                    offLabel="PDF View"></p-toggleButton>

                                    <!--                                    <button-->
                                    <!--                                            (click)="handlePdfExpand()"-->
                                    <!--                                            pButton-->
                                    <!--                                            pRipple-->
                                    <!--                                            [icon]="expandPdf ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"-->
                                    <!--                                            class="surface-400"-->
                                    <!--                                    ></button>-->
                                </div>
                            </div>
                        </ng-template>


                        <!--                [ngClass]="expandPdf ? 'hidden' : 'block'"-->
                       <ng-container *ngIf="uiMode == 'regular'">
                            <div class="col-12 transition-delay-200 py-0">
                                <!-- Document Assignment Widget-->
                                <mvs-widget
                                        *ngIf="widgetDocument"
                                        [widgetToolbarButtons]="widgetButtons"
                                        [widgetData]="widgetDocument"
                                        (onToolbarButtonClick)="handleButtonClick($event)"
                                >
                                    <ng-template let-object="object" mvs-widget-custom-field-directive
                                                 label="testDataField" fieldId="testDataField">
                                        <p-tag value="new"></p-tag>
                                    </ng-template>
                                </mvs-widget>
                            </div>

                            <div class="col-12">
                                <!-- Document Comments-->
                                <mvs-object-comments
                                        [mainObject]="objectIdentifier"
                                        linkedEntityObjectType="dm.DmDocumentComment"
                                ></mvs-object-comments>
                            </div>

                        </ng-container>

                        <!-- Document HTML View-->
                        <ng-container *ngIf="toggleDocument && document.analyzed && formData">
                            <app-form-recognizer id="top" [formData]="formData"
                                                 (keyValuePairEvent)="handleKayValuePairs($event)"
                                                 [keyValuePairInput]="selectedKeyValue"></app-form-recognizer>
                        </ng-container>

                        <!-- Document PDF View-->
                        <ng-container *ngIf="!toggleDocument || !document.analyzed">
                            <!--                            [ngClass]="expandPdf ? 'pdf-min-height' : 'pdf-max-height'"-->
<!--                            <object class="w-full" style="height: 95vh"-->
<!--                                    [data]="documentPdfSource"-->
<!--                                    type="application/pdf"-->
<!--                                    *ngIf="this.documentPdfSource">-->
<!--                                <embed [src]="documentPdfSource" type="application/pdf"/>-->
<!--                            </object>-->

                            <ng-container *ngIf="isPdf; else imageTemplate">
                                <object
                                        class="w-full"
                                        style="height: 95vh"
                                        [data]="documentPdfSource"
                                        type="application/pdf">
                                    <embed [src]="documentPdfSource" type="application/pdf" />
                                </object>
                            </ng-container>
                            <ng-template #imageTemplate>
                                <img [src]="documentPdfSource" class="w-full" alt="Image Document" *ngIf="isImage" />
                            </ng-template>
                        </ng-container>

                    </p-panel>
                </div>

            </div>
        </div>

        <!--
        <div class="col-6">
            <p-panel header="Document Assignment">

                <div class="col-12">
                    <p-button label="Assign" (click)="documentAssignment = !documentAssignment"></p-button>
                </div>

                <div class="grid" *ngIf="documentAssignment">
                    <div class="col-12" *ngIf="!document.analyzed">
                        <p-progressSpinner *ngIf="initialized && busy"></p-progressSpinner>
                        <p-panel header="Document Analyze">
                            <p-button label="Analyze" (click)="onAnalyze()"></p-button>
                        </p-panel>
                    </div>

                    <div class="col-12">
                        <div class="flex gap-4">
                            <p-dropdown
                                    placeholder="Select Object Type"
                                    *ngIf="documentAssignmentObjectTypesDtoList && documentAssignmentObjectTypesDtoList.entries"
                                    [options]="documentAssignmentObjectTypesDtoList.entries"
                                    [(ngModel)]="selectedAssignmentTypeObjectType"
                                    optionValue="objectTypeDtoId"
                                    optionLabel="objectTypeDtoName"
                                    (onChange)="onObjectTypeSelected()"
                            ></p-dropdown>

                            <p-toggleButton
                                    *ngIf="documentAssignmentType"
                                    [(ngModel)]="selectedAssignmentType"
                                    (onChange)="onSelectedAssignmentType($event)"
                                    onLabel="Primary"
                                    offLabel="Secondary"
                            ></p-toggleButton>
                        </div>

                    </div>


                    <div class="col-12" *ngIf="selectedObjectTypeAlias">
                        <mvs-search-entity
                                [objectType]="selectedObjectTypeAlias"
                                (onObjectSelected)="onObjectSelected($event)"
                        ></mvs-search-entity>
                    </div>

                    <div class="col-12"
                         *ngIf="selectedAssignmentType != null && objectIdentifier.objectId && selectedObjectId">
                        <p-progressSpinner
                                *ngIf="initialized && busy"
                        ></p-progressSpinner>
                        <p-button
                                [disabled]="busy"
                                label="Assign Document"
                                (click)="onDocumentAssignment()"
                        ></p-button>
                    </div>


                </div>

                <div>
                    <mvs-widget
                            *ngIf="widgetTableDocument"
                            [widgetData]="widgetTableDocument"
                            (onObjectSelect)="onDocumentSelect($event)"
                            (onRowCustomAction)="onDocumentDelete($event)"
                    >

                        <ng-template let-object="object" mvs-widget-custom-field-directive area="columns" label="Add" fieldId="addButton">
                            <p-button label="add" (click)="onClick(object)"></p-button>
                        </ng-template>

                        <ng-template let-object="object" mvs-widget-custom-field-directive area="columns"
                                     label="Delete" fieldId="deleteButton">
                            <p-button label="delete" (click)="onClick(object)"></p-button>
                        </ng-template>

                        <ng-template let-object="object" mvs-widget-custom-field-directive area="columns"
                                     label="Show" fieldId="showButton">
                            <p-button label="show" (click)="onClick(object)"></p-button>
                        </ng-template>

                    </mvs-widget>
                </div>
            </p-panel>


            <p-panel header="Key-Value Pairs" *ngIf="keyValuePairs">

                <p-table [value]="keyValuePairs" class="cursor-pointer" [tableStyle]="{'min-width': '50rem'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-pair>
                        <tr [pSelectableRow]="pair" (mouseenter)="onRowHover(pair)">
                            <td>{{pair?.key?.content}}</td>
                            <td>{{pair?.value?.content}}</td>
                        </tr>
                    </ng-template>
                </p-table>

            </p-panel>

        </div>
        -->
    </div>


    <!--        <div class="grid" *ngIf="toggleDocument && document.analyzed && formData">-->

    <!--            <div class="col-12">-->
    <!--                <app-form-recognizer id="top2" [formData]="formData"></app-form-recognizer>-->

    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--                <app-form-recognizer id="side1" [formData]="formData"></app-form-recognizer>-->

    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--                <app-form-recognizer id="side2" [formData]="formData"></app-form-recognizer>-->

    <!--            </div>-->

    <!--            <div class="col-4">-->
    <!--                <app-form-recognizer id="first" [formData]="formData"></app-form-recognizer>-->

    <!--            </div>-->
    <!--            <div class="col-8">-->
    <!--                <app-form-recognizer id="second" [formData]="formData"></app-form-recognizer>-->
    <!--            </div>-->

    <!--        </div>-->


    <!--    <div class="grid">-->

    <!--        <div class="col-12">-->
    <!--            <object class="w-full h-full absolute" [data]="documentPdfSource" type="application/pdf"-->
    <!--                    *ngIf="this.documentPdfSource">-->
    <!--                <embed [src]="documentPdfSource" type="application/pdf"/>-->
    <!--            </object>-->
    <!--        </div>-->

    <!--    </div>-->


    <!--
    19611
    -->


    <!--

        <i class="fa-regular fa-loader pi-spin" *ngIf="busy"></i>

        <p-button
                (click)="onAnalyze()"
                label="Analyze Document"
                [disabled]="busy"
        ></p-button>




        <div class="grid" *ngIf="resultAnalyze">

            <div class="col-4">
                <h5>KeyValue Pairs</h5>
                <p-table [value]="resultAnalyze['keyValuePairs']">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Confidence</th>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Quantity</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-entry>
                        <tr>
                            <td>{{entry.confidence}}</td>
                            <td>{{entry.key.content}}</td>
                            <td>{{entry.value.content}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="col-4">
                <h5>pages</h5>

                <div *ngFor="let page of resultAnalyze['pages']">
                    <h5> page N </h5>
                    <p-table [value]="page['lines']">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>content</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-entry>
                            <tr>
                                <td>{{entry.content}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <div class="col-4">
                <h5>KeyValue Pairs</h5>
                <p-table [value]="resultAnalyze['paragraphs']">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>content</th>
                            <th>role</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-entry>
                        <tr>
                            <td>{{entry.content}}</td>
                            <td>{{entry.role}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="col-12">
                <h5>Tables</h5>
                <div *ngFor="let table of resultAnalyze['tables']">
                    <h5> page N </h5>
                    <p-table [value]="table['cells']">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>content</th>
                                <th>kind</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-entry>
                            <tr>
                                <td>{{entry.content}}</td>
                                <td>{{entry.kind}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>


            </div>
        </div>




    -->

    <!--
    <pdf-viewer [src]="documentPdfSource"
                [render-text]="true"
                [original-size]="false"
                style="width: 400px; height: 500px"
                *ngIf="documentPdfSource"
    ></pdf-viewer>
    -->


</ng-container>
