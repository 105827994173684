<ng-container *ngIf="initialized">

    @if (size == 'M') {
        <div (click)="handleClick()" class="pointer">
            <mvs-inline-component
                    [objectType]="'cr.Customer'"
                    [name]="dto.personDtoName"
                    [startDate]="dto.startDate"
                    [endDate]="dto.endDate"
                    [status]="EntityStatusEnum[dto.entityStatus]"
                    [buttons]="toolbarButtons"
                    (onButtonClick)="handleButtonClick($event)">
            </mvs-inline-component>
        </div>
    }



    <!--    [uiIcon]="ticketObjectRoleDto?.image"-->
    <!--    [uiName]="ticketObjectRoleDto?.name"-->
    <!--    [uiColor]="ticketObjectRoleDto?.rgbaColor"-->
    <!--    [uiColorBackground]="ticketObjectRoleDto?.rgbaColorBackground"-->

    <!--    <ng-container *ngIf="ticketObjectRoleDto">-->
    <!--         uiIcon: {{ticketObjectRoleDto?.image}}-->
    <!--         uiName: {{ticketObjectRoleDto?.name}}-->
    <!--         uiColor: {{ticketObjectRoleDto?.rgbaColor}}-->
    <!--         uiColorBackground: {{ticketObjectRoleDto?.rgbaColorBackground}}-->
    <!--    </ng-container>-->

</ng-container>

