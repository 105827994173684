import {Component, Input, SimpleChanges} from '@angular/core';
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {CallTypeDto} from "../../dto/call-type.dto";
import {CustomerContactPhoneInfoDto} from "../../../cr/service/dto/customer-contact-phone-info.dto";
import {CustomerContactService} from "../../../cr/service/api/customer-contact.service";
import {CallService} from "../../service/api/call.service";
import {CoChannelPhoneNumberDto} from "../../../co/dto/service/co-channel-phone-number.dto";
import {CallDto} from "../../dto/call.dto";
import {ConfirmationService} from "primeng/api";
import {CallUiService} from "../../service/ui/call-ui.service";
import {CoUiService} from "../../../co/service/ui/co-ui.service";
import {CoChannelAccessTypeEnum} from "../../../co/enum/co-channel-access-type-enum.enum";
import {CoUiChannelAccessTypeInfoUi} from "../../../co/service/ui/data/co-ui-channel-access-type-info.ui";
import {CallTypeService} from "../../service/api/call-type.service";
import {DtoList} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {ObjectRequestSimple} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {CallDirectionEnum} from "../../enum/call-direction-enum.enum";
import {CallRecipientTypeEnum} from "../../enum/call-recipient-type-enum.enum";
import {Sorting} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-pc-call-incoming-at-object',
    templateUrl: './pc-call-incoming-at-object.component.html',
})
export class PcCallIncomingAtObjectComponent {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() objectIdentifier: ObjectIdentifier;        // <Object Alias>: cr.Customer / <ID>: ID of Object
    @Input() sourceActionObject?: ObjectIdentifier;

    listIncomingCallTypeDtoList: DtoList<CallTypeDto>;
    selectedCallType: CallTypeDto;

    phoneNumber: string;
    callDto: CallDto;

    constructor(
        protected customerContactService: CustomerContactService,
        protected callTypeService: CallTypeService,
        protected callService: CallService,
        protected confirmationService: ConfirmationService,
        protected callUiService: CallUiService,
        protected coUiService: CoUiService,
        protected messageService: MvsMessageService,
    ) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        // retrieve all outgoing call types
        this.callTypeService.list(
            ObjectRequestList.createSimpleRequestList(
                false,
                ObjectRequestSimple.create(false, false, 0),
                [FilterCriteria.create("callDirection", FilterCriteria.cOperatorEqual, CallDirectionEnum.incoming),
                            FilterCriteria.create("callRecipientType", FilterCriteria.cOperatorEqual, CallRecipientTypeEnum.customer)],
                [ new Sorting("orderPriority", true)],
                null,
                null)).subscribe(value => {

                    this.listIncomingCallTypeDtoList = value;

                    if (this.listIncomingCallTypeDtoList && this.listIncomingCallTypeDtoList.entries && this.listIncomingCallTypeDtoList.entries.length > 0) {
                        this.selectedCallType = this.listIncomingCallTypeDtoList.entries[0];
                    }

                    this.refreshComponent();
        });

    }

    refreshUi() {

        this.initialized = true;

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["calledObject"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }



    handleStartCall() {

        if (this.busy) {
            return;
        }

        if (!this.selectedCallType) {
            this.messageService.showErrorMessage("Typ auswählen", "Bitte Anruftyp auswählen!");
            return;
        }

        this.busy = true;

        // check for active call
        this.callService.getMyActiveCalls().subscribe(activeCalls => {

            if (activeCalls && activeCalls.entries && activeCalls.entries.length > 0) {
                this.messageService.showErrorMessage("Laufender Anruf", "Bitte aktuellen Anruf zuerst beenden!");
                this.busy = false;
            } else {
                this.callService.startCustomerCall(this.selectedCallType.id, this.objectIdentifier.objectId, this.phoneNumber).subscribe(value => {
                    this.callDto = value;
                    this.callUiService.refreshHeaderActiveCall();
                    this.busy = false;
                });
            }
        }, error => {
            this.busy = false;
        });

    }

    protected getCallAccessInformation(access: CoChannelAccessTypeEnum): CoUiChannelAccessTypeInfoUi {

        return this.coUiService.getChannelAccessTypeInfo(access);

    }

}
