@if (initialized) {

    <div class="grid h-full">
        <div class="md:col-3 col-12 transition-col" [class.hidden-filter]="hideFilters">
            <div class="surface-0 shadow-1 p-3 border-round h-full" *ngIf="!hideFilters">
                <div class="flex justify-content-between align-items-center border-bottom-1 border-200 pb-2">
                    <h4 class="m-0">Filters</h4>
                    <p-button pTooltip="Create Filter" icon="pi pi-plus-circle text-xl" styleClass="p-button-text"
                              (click)="handleModifyFilters()"></p-button>
                </div>

                <div class="w-full mt-3">
                    <!-- Favorite Groups -->
                    <div class="">
                        <div class="flex justify-content-between align-items-center mb-2">
                            <div class="flex gap-2 align-items-center">
                                <i class="pi pi-star"></i>
                                <span class="font-medium">Favourites</span>
                            </div>
                            <button pButton pRipple icon="fa-regular fa-ellipsis fa-fw"
                                    class="p-button-rounded p-button-text"
                                    (click)="menu.toggle($event)">
                            </button>
                        </div>

                        @if (favoriteGroupFilters && favoriteGroupFilters.length) {
                            <div class="">
                                @for (group of favoriteGroupFilters; track group.id) {
                                    <div class="flex justify-content-between align-items-center gap-2 mb-2 p-2 border-round"
                                         [ngClass]="{'bg-primary-100': selectedFilter?.id == group.id,'bg-gray-100': group.id == groupContextMenuId}"
                                         (contextmenu)="onUiSelectedLayoutContextMenu($event, group, true)"
                                         (click)="getFilterInfo(group)">
                                        <div class="cursor word-break">
                                            {{ group.name }}
                                        </div>
                                        <div class="flex gap-2 align-items-center">

                                            <p-badge value="{{ group.groupFilterCountDto?.meCount }}"
                                                     [pTooltip]="'My tickets'"
                                                     (click)="handleBadgeCountClick(UiAgentAssignmentEnum.assignedToMe, group)"
                                                     styleClass="badge-style pointer"></p-badge>

                                            <p-badge value="{{ group.groupFilterCountDto?.poolsCount }}"
                                                     (click)="handleBadgeCountClick(UiAgentAssignmentEnum.assignedToMyPool, group)"
                                                     [pTooltip]="'My pool tickets'"
                                                     severity="success"
                                                     styleClass="badge-style pointer"></p-badge>

                                            <p-badge value="{{ group.groupFilterCountDto?.poolsUnassignedCount }}"
                                                     (click)="handleBadgeCountClick(UiAgentAssignmentEnum.unassigned, group)"
                                                     [pTooltip]="'Unassigned Tickets'"
                                                     severity="warning"
                                                     styleClass="badge-style pointer"></p-badge>

                                            <p-badge value="{{ group.groupFilterCountDto?.totalCount }}"
                                                     [pTooltip]="'All tickets'"
                                                     (click)="handleBadgeCountClick(UiAgentAssignmentEnum.total, group)"
                                                     styleClass="badge-style pointer"></p-badge>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        <div class="p-3" *ngIf="!favoriteGroupFilters || !favoriteGroupFilters.length">
                            No favorites added so far!
                        </div>

                    </div>

                    <!-- All Groups -->
                    <div class="mt-2">
                        <p-panel
                            #panel
                            expandIcon="pi pi-chevron-up"
                            collapseIcon="pi pi-chevron-down"
                            class="panel-blank"
                            styleClass="group-panel-section"
                            [iconPos]="'end'"
                            [toggleable]="true"
                            [collapsed]="true"
                            (collapsedChange)="handleCollapseGroups($event)">

                            <ng-template pTemplate="header">
                                <div class="font-medium pointer">
                                    <span (click)="panel.toggle($event)">My Groups</span>
                                </div>
                            </ng-template>

                            <div class="flex flex-column" *ngIf="groupFilters && groupFilters.length">
                                @for (group of groupFilters; track group.id) {
                                    @if (group.favorite == false) {
                                        <div class="flex justify-content-between align-items-center gap-2 p-2 border-round"
                                             [ngClass]="{'bg-primary-100': selectedFilter?.id == group.id,'bg-gray-100': group.id == groupContextMenuId}"
                                             (contextmenu)="onUiSelectedLayoutContextMenu($event, group, false)"
                                             (click)="getFilterInfo(group)">

                                            <span class="cursor word-break">
                                                @if (group.favorite == false) {
                                                } &nbsp;{{ group.name }}
                                            </span>

                                            @if (group.groupFilterCountDto) {
                                                <div class="flex gap-2 align-items-center">

                                                    <p-badge value="{{ group.groupFilterCountDto?.meCount }}"
                                                             (click)="handleBadgeCountClick(UiAgentAssignmentEnum.assignedToMe, group)"
                                                             [pTooltip]="'My tickets'"
                                                             styleClass="badge-style pointer"></p-badge>

                                                    <p-badge value="{{ group.groupFilterCountDto?.poolsCount }}"
                                                             (click)="handleBadgeCountClick(UiAgentAssignmentEnum.assignedToMyPool, group)"
                                                             [pTooltip]="'My Pool tickets'"
                                                             severity="success"
                                                             styleClass="badge-style pointer"></p-badge>

                                                    <p-badge
                                                            value="{{ group.groupFilterCountDto?.poolsUnassignedCount }}"
                                                            (click)="handleBadgeCountClick(UiAgentAssignmentEnum.unassigned, group)"
                                                            [pTooltip]="'Unassigned Tickets'"
                                                            severity="warning"
                                                            styleClass="badge-style pointer"></p-badge>

                                                    <p-badge value="{{ group.groupFilterCountDto?.totalCount }}"
                                                             [pTooltip]="'All tickets'"
                                                             (click)="handleBadgeCountClick(UiAgentAssignmentEnum.total, group)"
                                                             styleClass="badge-style pointer"></p-badge>
                                                </div>
                                            }
                                        </div>
                                    }
                                }
                            </div>

                            <div class="p-3" *ngIf="!groupFilters || !groupFilters.length">
                                No filters available!
                            </div>

                        </p-panel>

                    </div>

                </div>
            </div>
        </div>

        <div class="col-12 smooth-transition" [ngClass]="hideFilters ? 'md:col-12' : 'md:col-9'">

            @if (selectedFilterInfo) {
                <div class="flex gap-3 justify-content-between align-items-center mb-2 surface-0 shadow-1 p-2">
                    <div class="flex gap-3 overflow-auto align-items-center">
                        <p-button styleClass="p-button-text text-600" (click)="hideFilterSection()"
                                  icon="pi pi-bars"></p-button>
                        <div class="flex gap-3 overflow-hidden">
                            @for (ticketType of selectedFilterInfo.ticketTypeCounts | slice: 0:6; track ticketType.ticketType.id) {

                                <p-chip styleClass="w-max p-1 border-round-xl pointer {{ticketType.uiSelected ? 'bg-primary-100 text-primary border-1 border-primary-500' : 'surface-200 border-1 border-300'}}"
                                        (click)="handleTicketTypeSelect(ticketType, 'main')">
                                    <span class="mx-2"> {{ ticketType.ticketType.name }}</span>

                                    @if (selectedAssignment == UiAgentAssignmentEnum.assignedToMe) {
                                        <p-badge [value]="ticketType.count.meCount" class="mx-1"/>

                                    } @else if (selectedAssignment == UiAgentAssignmentEnum.assignedToMyPool) {
                                        <p-badge [value]="ticketType.count.poolsCount" class="mx-1"/>

                                    } @else if (selectedAssignment == UiAgentAssignmentEnum.unassigned) {
                                        <p-badge [value]="ticketType.count.poolsUnassignedCount" class="mx-1"/>

                                    } @else if (selectedAssignment == UiAgentAssignmentEnum.total) {
                                        <p-badge [value]="ticketType.count.totalCount" class="mx-1"/>
                                    }

                                </p-chip>

                            }
                        </div>

                        @if (selectedFilterInfo.ticketTypeCounts?.length > 4) {

                            <p-chip styleClass="p-1 border-round-xl surface-100 cursor w-max"
                                    (click)="overlay.toggle($event)">
                                <span class="mx-2"> Show All </span>
                            </p-chip>
                        }

                    </div>
                    <p-dropdown
                            [options]="assignmentOptions"
                            [(ngModel)]="selectedAssignment"
                            placeholder="Select Assignment"
                            optionLabel="label"
                            optionValue="value"
                            (onChange)="handleChangeAssignmentType($event)"
                    >
                    </p-dropdown>


                </div>

                @if (ticketWidget && ticketWidgetSwitch) {

                    <mvs-widget [widgetData]="ticketWidget"
                                (onObjectSelect)="handleObjectSelect($event)">
                    </mvs-widget>

                } @else if (ticketWidget && !ticketWidgetSwitch) {

                    <mvs-widget [widgetData]="ticketWidget"
                                (onObjectSelect)="handleObjectSelect($event)">
                    </mvs-widget>

                }

            } @else if (showFilterText) {
                <div class="flex gap-2 flex-column col-5 mt-5 mx-auto text-center">
                    <p-avatar icon="pi pi-star text-primary-600" styleClass="bg-primary-100" class="mb-3" shape="circle"
                              size="xlarge"/>
                    <p class="font-normal mb-1 text-2xl"> Please select a group to display its data or add it to your
                        favourites for quicker access.</p>
                    <p class="text-xl"> Simply right-click on your groups list to add a group to your favourites.</p>
                </div>

            }

        </div>

    </div>

    <p-sidebar [(visible)]="manageFavoritesSidebar" position="right" styleClass="w-30rem">
        <h4>Manage Favorites</h4>
        @if (favoriteGroupFilters && favoriteGroupFilters.length) {
            <div class="group-list" cdkDropList
                 [cdkDropListData]="favoriteGroupFilters"
                 (cdkDropListDropped)="adjustPriority($event)">
                @for (group of favoriteGroupFilters; track group.id) {
                    <div class="flex justify-content-between align-items-center mb-3 pl-3 shadow-1 p-2 border-round">
                        <div class="flex gap-3 align-items-center">
                            <i cdkDrag class="fa-solid fa-grip-dots-vertical cursor-move text-xl"></i> {{ group.name }}
                        </div>
                        <div class="flex gap-2 align-items-center">

                            @if (group.access == TicketGroupFilterAccess.public_access) {
                                <p-badge value="Public" severity="success" styleClass="badge-style"></p-badge>
                            } @else if (group.access == TicketGroupFilterAccess.private_access) {
                                <p-badge value="Private" styleClass="badge-style"></p-badge>
                            }

                            <p-button styleClass="p-button-text text-red-500 font-normal"
                                      (click)="removeFromFavorite(group)">
                                Remove
                            </p-button>

                        </div>
                    </div>
                }
            </div>
        }
    </p-sidebar>

    <p-contextMenu [appendTo]="'body'"
                   #cmLayout
                   [model]="uiLayoutContextMenuItems"
                   (onHide)="onUiHideContextMenu()">
    </p-contextMenu>

    <p-slideMenu #menu
                 [model]="slideMenuItems"
                 [popup]="true"
                 [menuWidth]="175"
                 class="sort-icon"
                 appendTo="body"
    >
    </p-slideMenu>

    <p-overlayPanel #overlay [style]="{ width: '45%'}">


        <div class="">
            <div class="flex justify-content-between gap-2 align-items-center mb-3">
                <span class="text-xl font-medium">Ticket Type</span>
                <p-button styleClass="p-button-text p-button-link text-red-500" icon="pi pi-times mr-2"
                          label="Clear All"
                          (click)="clearAllTicketTypeSelections()"></p-button>
            </div>
            @if (overlayTicketTypeCounts && overlayTicketTypeCounts.length) {
                <div class="flex flex-wrap gap-3">
                    @for (ticketType of overlayTicketTypeCounts; track ticketType.ticketType.id) {
                        <p-chip styleClass="p-1 border-round-xl cursor {{ticketType.uiSelected ? 'bg-primary-100 text-primary border-1 border-primary-500' : 'surface-200 border-1 border-300'}}"
                                (click)="handleTicketTypeSelect(ticketType, 'overlay')">
                            <span class="mx-2"> {{ ticketType.ticketType.name }}   </span>
                            @if (selectedAssignment == UiAgentAssignmentEnum.assignedToMe) {
                                <p-badge [value]="ticketType.count.meCount" class="mx-1"/>

                            } @else if (selectedAssignment == UiAgentAssignmentEnum.assignedToMyPool) {
                                <p-badge [value]="ticketType.count.poolsCount" class="mx-1"/>

                            } @else if (selectedAssignment == UiAgentAssignmentEnum.unassigned) {
                                <p-badge [value]="ticketType.count.poolsUnassignedCount" class="mx-1"/>

                            } @else if (selectedAssignment == UiAgentAssignmentEnum.total) {
                                <p-badge [value]="ticketType.count.totalCount" class="mx-1"/>
                            }
                        </p-chip>
                    }
                </div>
            }

        </div>
    </p-overlayPanel>


}