import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {AppointmentTypeDto} from "../../../../as/dto/appointment-type.dto";
import {MsBookingTypeDto} from "../../../../as/dto/ms-booking-type.dto";

@Component({
  selector: 'mvs-as-appointment-type-page',
  templateUrl: './config.page.html',
  styleUrls: ['./config.page.scss']
})
export class ConfigPage extends PageComponent implements OnInit {

  defaultLabel: string = "Billing Management Configuration";

  widgetDunningLevel : WidgetData;
  widgetPaymentCollectionVia : WidgetData;

  activeIndex: number = 0;

  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();
  }

  initComponent(): void {

    this.widgetDunningLevel = WidgetFactory.createWidgetList(
        "bm.config.dunning.level.simple",
        "Dunning Level - Mahnstufen",
        "table",
        "list",
        "entity",
        "bm.DunningLevel",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetPaymentCollectionVia = WidgetFactory.createWidgetList(
        "bm.config.payment.collection.via",
        "Payment Collection - Zahlungseinzugsart",
        "table",
        "list",
        "entity",
        "bm.PaymentCollectionVia",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.initialized = true;

  }


}
