import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectComponent, WidgetFactory} from "@kvers/alpha-ui";
import {
    DtoDetail,
    FilterCriteria, MvsCoreService, MvsMessageService,
    ObjectIdentifier,
    ObjectRequestList, ObserverService,
    WidgetData,
    WidgetDataParam
} from "@kvers/alpha-core-common";
import {CalculationLogicTypeService} from "../../service/api/calculation-logic-type.service";
import {
    LgCalculationLogicTypeObjectInterface
} from "../object-components/calculation-logic-type-object-component/lg-calculation-logic-type-object.interface";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationService} from "primeng/api";
import {LogicImportService} from "../../service/api/logic-import.service";
import {LogicExportService} from "../../service/api/logic-export.service";
import {LogicService} from "../../service/api/logic.service";
import {LogicDto} from "../../dto/logic.dto";
import {LogicCheckSyntaxResponseDto} from "../../dto/logic-check-syntax-response.dto";
import {LogicLanguage} from "../../enum/logic-language.enum";
import {LogicCheckSyntaxDto} from "../../dto/logic-check-syntax.dto";
import {LgLogicObjectInterface} from "../object-components/logic-object-component/lg-logic-object.interface";

@Component({
    selector: 'lg-logic-page-component',
    templateUrl: 'lg-logic-page.component.html'
})
export class LgLogicPageComponent implements OnInit, OnChanges, OnDestroy, LgLogicObjectInterface {

    @Input() objectIdentifier: ObjectIdentifier;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    activeTab:number = 0;

    script: string;
    language: string;

    dto: LogicDto;
    syntaxResponse: LogicCheckSyntaxResponseDto;

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected observerService: ObserverService,
                protected logicImportService: LogicImportService,
                protected logicExportService: LogicExportService,
                protected logicService: LogicService
    ) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.logicService.get(this.objectIdentifier.objectId).subscribe(logic => {
            this.dto = <LogicDto>logic;

            this.script = this.dto.script;

            this.language = LogicLanguage[this.dto.logicLanguage];
        })

        this.refreshComponent();
    }

    checkSyntax() {
        const codeSyntaxDto: LogicCheckSyntaxDto = new LogicCheckSyntaxDto();

        codeSyntaxDto.logicId = this.objectIdentifier.objectId;
        codeSyntaxDto.logicLanguage = this.dto.logicLanguage;
        codeSyntaxDto.code = this.script;

        this.logicService.checkCodeSyntax(codeSyntaxDto).subscribe(checkSyntax => {
            this.syntaxResponse = checkSyntax;
        })
    }

    updateScript() {

        const logicDto: LogicDto = new LogicDto();
        logicDto.id = this.dto.id;
        logicDto.script = this.script;
        logicDto.logicLanguage = this.dto.logicLanguage;

        this.logicService.update(logicDto).subscribe((res: LogicDto) => {
            this.dto = res;
            this.messageService.showSuccessMessage('Update', 'Logic has benn updated.');
        });

    }

    onContentChange(content: string) {
        this.script = content;
    }

    handleLogicLanguageChange(event: LogicLanguage) {
        this.dto.logicLanguage = event;
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }




    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        // if (changes["id"]) {
        this.refreshComponent();
        // }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
