<div class="bg-white">
    <mvs-config-header header="Jobs" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Job Types" leftIcon="fa-thin fa-layer-group"></p-tabPanel>
        <p-tabPanel header="Job Runs" leftIcon="fa-solid fa-diagram-venn"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="jbJobTypeWidget"
                        [widgetData]="jbJobTypeWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-8">
                <mvs-widget
                        *ngIf="jbJobRunWidget"
                        [widgetData]="jbJobRunWidget"
                        (onObjectSelect)="handleJobRunSelect($event)"
                ></mvs-widget>
            </div>

            <div class="col-4">
                <mvs-widget
                        *ngIf="jbJobRunLogWidget"
                        [widgetData]="jbJobRunLogWidget"
                ></mvs-widget>

            </div>
        </div>
    </ng-container>


</div>