import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {ObjectHouseholdComponent} from "../../component/object-household/object-household.component";

@Injectable({
    providedIn: 'root'
})
export class HouseholdService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/pm/households')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return ObjectHouseholdComponent;
        }
        return null;
    }
}