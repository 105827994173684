import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {WidgetHelperButton} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {CrCustomerDto} from "../../../cr/dto/cr-customer.dto";
import {ContractDto} from "../../dto/contract.dto";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {DtoImportObjectContextEntry} from "@kvers/alpha-core-common";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {CustomerDto} from "../../../cr/dto/customer.dto";

@Component({
    selector: 'cm-contract-person-object-widgets',
    templateUrl: './contract-person-object-widgets.component.html',
    styleUrls: ['./contract-person-object-widgets.component.scss']
})
export class ContractPersonObjectWidgetsComponent implements OnInit, OnChanges {

    @Input() customerDto: CustomerDto;
    @Input() contractDto: ContractDto;
    personsWidget: WidgetData;
    objectsWidget: WidgetData;
    contextContractAndCustomer: DtoImportObjectContext;
    personWidgetToolbarButtons: WidgetHelperButton[];
    initialized: boolean;

    constructor(protected objectService: MvsUiObjectService,) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.initialized = true;
    }

    initComponent() {
        this.refreshComponent();
    }

    refreshComponent() {
        this.refreshWidgets();
        this.initialized = true;
    }

    refreshWidgets() {

        // create import object context
        const personFromEntry = new DtoImportObjectContextEntry('pm.Person', this.customerDto.personDtoId, 'personFrom');
        this.contextContractAndCustomer = DtoImportObjectContext.createFromObjectIdentifiers(
            new ObjectIdentifier("cm.Contract", this.contractDto.id),
            new ObjectIdentifier("cr.Customer", this.customerDto.id),
        );
        this.contextContractAndCustomer.addEntry(personFromEntry);

        const filterCriteria = FilterCriteria.create("contract", FilterCriteria.cOperatorEqual, this.contractDto.id);

        this.personsWidget = WidgetFactory.createWidgetList(
            "cm.contract.objects.navigation.persons",
            "Personen",
            "list",
            "list",
            "entity",
            "ci.ContractPerson",
            "Keine versicherten Personen vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(true,false, ObjectRequestComplexNode.createRelationNode("person", ObjectRequestRelation.createJoin("person"))),
                [filterCriteria],
                [],
                null)
        );

        if (this.customerDto && this.customerDto.personDtoId) {
            this.personWidgetToolbarButtons = [{
                icon: 'fa-light fa-user-plus',
                label: '',
                display: true,
                action: 'assignCustomer',
                tooltip: 'Kunden hinzufügen'
            }];
        }

        // create widget to display the insurable objects
        this.objectsWidget = WidgetFactory.createWidgetList(
            "cm.contract.objects.navigation.objects",
            "Objekte",
            "list",
            "list",
            "entity",
            "ci.ContractInsurableObject",
            "Keine versicherten Objekte vorhanden",
            ObjectRequestList.createComplexRequestList(
                true,
                ObjectRequestComplex.build(true,false, ObjectRequestComplexNode.createRelationNode("insurableObject", ObjectRequestRelation.createJoin("insurableObject"))),
                [filterCriteria],
                [],
                null)
        );


    }

    handlePersonWidgetToolbarClick(event: WidgetHelperButton) {

        const context = this.contextContractAndCustomer.copy();
        context.addEntry(new DtoImportObjectContextEntry("pm.Person", this.customerDto.personDtoId, 'personTo'));

        if (event.action === 'assignCustomer') {
            this.objectService.openObjectViaDialog(
                context,
                "ci.ContractPerson",
                0,
                null,
                false,
                false,
                () => {
                    //
                },
                MvsCrudModeEnum.create
            );
        }

    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["customerDto"] || changes["contractDto"]) {
            this.refreshComponent();
        }

    }

}
