<ng-container *ngIf="initialized">
    <p-toolbar>

        <div class="flex flex-wrap justify-content-between align-items-center w-full">
            <div class="wf-process-title">

                <div class="flex align-items-center gap-4">
                            <span>
                            {{ dto.name }}
                        </span>
                    <span pButton class="flex align-items-center gap-2 p-button-text"
                          (click)="menu.toggle($event)">
                             <i class="fa-regular fa-ellipsis"></i>
                        </span>
                </div>

            </div>
            <div class="p-toolbar-group-end gap-4 pr-2">

                <p-chip
                        *ngIf="processRuntime.activeStepAgent"
                        icon="fa-solid fa-user"
                        [label]="processRuntime.activeStepAgent.name"
                        [removable]="true"
                        (onRemove)="confirmSelectedAgent()"
                ></p-chip>
                <p-chip
                        *ngIf="processRuntime.activeStepAgentPool"
                        icon="fa-solid fa-building"
                        [label]="processRuntime.activeStepAgentPool.name"
                        [removable]="true"
                        (onRemove)="confirmSelectedAgentPool()"
                ></p-chip>

                <p-button
                        icon="fa-regular fa-user-plus"
                        styleClass="p-button-secondary p-button-rounded"
                        (click)="onAgentSearchDisplay()"
                ></p-button>

                <p-button
                        *ngIf="!mainActivity?.activityMandatory"
                        [disabled]="busy"
                        (click)="handleContinue('skip')"
                        label="Skip"
                        icon="fa-solid fa-forward"
                ></p-button>
                <p-button
                        [disabled]="!(!!WfProcessRuntimeService.activityProcessingResult) || busy"
                        (click)="handleContinue('continue')"
                        [label]="isLastStep ? 'Finish':'Continue'"
                        icon="fa-solid fa-caret-right"
                ></p-button>

            </div>
        </div>
    </p-toolbar>

    <p-slideMenu #menu
                 [model]="configurationItems"
                 [popup]="true"
                 [viewportHeight]="250"
                 [menuWidth]="175"
                 appendTo="body"
    ></p-slideMenu>
</ng-container>

<p-dialog
        header="Header"
        [(visible)]="showAgentSearch"
        [style]="{width: '80vw', 'min-height': '80vh'}">
    <p-tabView>

        <p-tabPanel header="Agent">
            <search-flexible
                    [objectType]="'am.Agent'"
                    [currentObjectId]="0"
                    (onObjectSelect)="handleSelectedAgent($event)"
            ></search-flexible>

            <div class="flex gap-4 justify-content-end my-4">
                <p-button
                        *ngIf="selectedAgentId"
                        (click)="cancelForwardAssignments()"
                        [disabled]="busy"
                        label="Abbrechen"
                        icon="fa-solid fa-ban"
                        styleClass="p-button-danger"
                ></p-button>

                <p-button
                        *ngIf="selectedAgentId"
                        (click)="confirmSelectedAgent()"
                        [disabled]="busy"
                        label="Weiterleiten"
                        icon="pi pi-forward"
                ></p-button>
            </div>

        </p-tabPanel>

        <p-tabPanel header="Agent Pool">
            <search-flexible
                    [objectType]="'am.AgentPool'"
                    [currentObjectId]="0"
                    (onObjectSelect)="handleSelectedAgentPool($event)"
            ></search-flexible>
            <div class="flex gap-4 justify-content-end my-4">
                <p-button
                        *ngIf="selectedAgentPoolId"
                        (click)="cancelForwardAssignments()"
                        [disabled]="busy"
                        label="Abbrechen"
                        icon="fa-solid fa-ban"
                        styleClass="p-button-danger"></p-button>

                <p-button
                        *ngIf="selectedAgentPoolId"
                        (click)="confirmSelectedAgentPool()"
                        [disabled]="busy"
                        label="Forward"
                        icon="pi pi-forward"
                ></p-button>
            </div>

        </p-tabPanel>

    </p-tabView>
</p-dialog>