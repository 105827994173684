import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    ProvisionTypeObjectComponent
} from "../../component/provision-type-object-component/provision-type-object.component";

@Injectable({
    providedIn: 'root'
})
export class ProvisionTypeService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cp/provisionTypes')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return ProvisionTypeObjectComponent;
        }
        return null;
    }
}