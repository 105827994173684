import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {basicSetup} from 'codemirror';
import {EditorView} from '@codemirror/view';
import {EditorState, Extension} from '@codemirror/state';
import {html} from '@codemirror/lang-html';
import {javascript} from '@codemirror/lang-javascript'; // For Groovy fallback
import {java} from '@codemirror/lang-java'; // For SpEL fallback
import {history, undo, redo} from '@codemirror/commands';
import {LogicLanguage} from "../../enum/logic-language.enum";
import {TreeDragDropService, TreeNode, TreeNodeDragEvent} from "primeng/api";
import {Subscription} from "rxjs";
import {autocompletion, CompletionSource, Completion} from '@codemirror/autocomplete';
import {DataType} from "../../../cc/enum/data-type.enum";

@Component({
    selector: 'app-code-editor',
    templateUrl: 'code-editor.component.html',
    styleUrls: ['code-editor.component.scss'],
})
export class CodeEditorComponent
    implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    busy: boolean; // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() editorContent: string;
    @Input() logicLanguage: LogicLanguage; // New input for language
    @Output() contentChanged = new EventEmitter<string>();
    @Output() onLogicLanguageChanged = new EventEmitter<LogicLanguage>();

    @ViewChild('editor') myEditor: ElementRef;

    private view: EditorView | undefined;
    mouseEvent: MouseEvent;

    private dragStopSubscription: Subscription | undefined;
    languageList = [{label: 'Spel', value: LogicLanguage.spel, alias: 'spel'}, {
        label: 'Groovy',
        value: LogicLanguage.groovy,
        alias: 'groovy'
    }];

    constructor(
        protected treeDragDropService: TreeDragDropService,) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    ngAfterViewInit(): void {
        this.initializeEditor();

        this.initDragDropSubscription();
    }


    initializeEditor(): void {

        // Destroy the existing EditorView if it exists
        if (this.view) {
            this.view.destroy();
        }

        const myEditorElement = this.myEditor.nativeElement;
        const language = this.languageList.find((l) => l.value === this.logicLanguage);


        const extensions: Extension[] = [
            basicSetup,
            history(),
            this.getLanguageExtension(language.value),
            EditorView.updateListener.of((update) => {
                if (update.docChanged) {
                    this.contentChanged.emit(update.state.doc.toString());
                }
            }),
            EditorView.domEventHandlers({
                dragover: (event) => {
                    event.preventDefault(); // Allow dropping
                },
                drop: (event) => {
                    event.preventDefault();
                    this.mouseEvent = event;
                },
            }),
        ];

        const state = EditorState.create({
            doc: this.editorContent,
            extensions,
        });

        this.view = new EditorView({
            state,
            parent: myEditorElement,
        });
    }


    private getLanguageExtension(language: LogicLanguage): Extension {
        switch (language) {
            case LogicLanguage.groovy:
                return javascript(); // Use JavaScript highlighting for Groovy
            case LogicLanguage.spel:
                return java(); // Use Java highlighting for SpEL
            default:
                return html();
        }
    }

    onLanguageChange(): void {
        this.onLogicLanguageChanged.emit(this.logicLanguage);
        this.initializeEditor(); // Reinitialize editor with the new language
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.initialized) {
            return;
        }

        if (changes['contentChanged']) {
            this.initializeEditor(); // Reinitialize editor if language changes
        }

        // if (changes['editorContent']) {
        //     if (this.view) {
        //         this.view.dispatch({
        //             changes: {from: 0, to: this.view.state.doc.length, insert: this.editorContent},
        //         });
        //     }
        // }

        if (changes['language'] && !changes['language'].isFirstChange()) {
            this.initializeEditor(); // Reinitialize editor if language changes
        }
    }

    undo(): void {
        if (this.view) {
            undo(this.view);
        }
    }

    redo(): void {
        if (this.view) {
            redo(this.view);
        }
    }

    private getAliasFromNode(node: TreeNode): string {

        if (node.data["attributeName"]) {

            if ((node.data["attributeName"] as string).charAt(0) == "+") {
                return "metaAttribute_" + (node.data["attributeName"] as string).substring(1);
            }
            return node.data["attributeName"];

        } else if (node.data["alias"]) {
            return node.data["alias"];

        } else if (node.data["name"]) {

            let join = node.data["name"];
            join = join.replace('#', '_attr_');
            join = join.replace('.', '_');

            if ((join as string).charAt(0) == "+") {
                return "metaJoin_" + (join as string).substring(1);
            }
            return join;

        } else {

        }

        return "";

    }

    // find attribute parent nodes
    private findParentNodes(treeNode: TreeNode) {

        let variableName = "";

        const aliasFromNode = this.getAliasFromNode(treeNode);
        variableName = aliasFromNode;

        let node = treeNode.parent;
        while (node) {

            const aliasFromSubNode = this.getAliasFromNode(node);
            variableName = aliasFromSubNode + "." + variableName;

            node = node.parent;

        }

        return variableName;

    }

    initDragDropSubscription() {
        // Subscribe to dragStop$ events
        this.dragStopSubscription = this.treeDragDropService.dragStop$.subscribe((dragAttribute) => {
            if (
                dragAttribute?.node &&
                (!dragAttribute.node.children ||
                    dragAttribute.node.data.joinRelationEnum === 'oneToMany' || // Replace with your enum
                    dragAttribute.node.parent?.data.joinRelationEnum === 'oneToMany')
            ) {
                // Process node and insert into the editor
                this.insertNodeIntoEditor(dragAttribute.node);
            } else if (dragAttribute?.node.data.fieldTypeDto.dataType != DataType.Object) {
                // Process node and insert into the editor
                this.insertNodeIntoEditor(dragAttribute.node);
            }
        });
    }

    insertNodeIntoEditor(node: any): void {
        if (!this.view) return;

        // Extract the text to insert (e.g., the label of the node)
        // const insertText = node.data.label || 'Unknown Node';
        const insertText = this.findParentNodes(node);

        // Get the current cursor position
        // const pos = this.view.state.selection.main.head;

        const pos = this.view.posAtCoords({
            x: this.mouseEvent.clientX,
            y: this.mouseEvent.clientY,
        });


        // Dispatch changes to insert the text at the cursor position
        this.view.dispatch({
            changes: {from: pos, to: pos, insert: insertText},
        });
    }


    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        if (this.view) {
            this.view.destroy();
        }

        if (this.dragStopSubscription) {
            this.dragStopSubscription.unsubscribe();
        }
    }
}