import {Route, Routes} from "@angular/router";
import {RpConfigPage} from "./page/overview/config/rp-config.page";
import {RpTestConfigPage} from "./page/overview/test-config/rp-test-config.page";
import {RpConfigNewPage} from "./page/overview/new-config/rp-config-new.page";
import {RpReportPage} from "./page/report-page/rp-report.page";

export function RpSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'rp/config', component: RpConfigPage},
        {path: 'rp/config-test', component: RpTestConfigPage},
        {path: 'rp/config-new', component: RpConfigNewPage},

        {path: 'rp/reports', component: RpReportPage},
    ];

    return routes;

}
