import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {WfProcessTypeStepActivityDto} from "../../../dto/wf-process-type-step-activity.dto";
import {WfProcessStepActivityDto} from "../../../dto/wf-process-step-activity.dto";
import {
    WfProcessStepActivityBaseComponent
} from "../../process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {
    WfProcessStepActivitySideBaseComponent
} from "../wf-process-step-activity-side-base/wf-process-step-activity-side-base.component";

@Component({
    selector: 'mvs-wf-process-show-step-activities',
    templateUrl: './wf-process-step-activity-side-panel.component.html',
    styleUrls:['wf-process-step-activity-side-panel.component.scss']
})
export class WfProcessStepActivitySidePanelComponent extends WfProcessStepActivitySideBaseComponent {


    @Output() onActivitySelect = new EventEmitter<WfProcessTypeStepActivityDto>;
    selectedActivity:number;
    @Input() fullPage: boolean = true;

    onOptionalComponentSelect(activityType: WfProcessTypeStepActivityDto, index) {
        this.selectedActivity = index;
        this.onActivitySelect.emit(activityType)
    }

}
