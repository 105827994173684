<ng-container *ngIf="initialized && ticketExist">
Hello
    <i class="pi pi-ticket" pBadge [value]="resolvedTicketCount > 0 ? resolvedTicketCount: null" severity="info"
       style="font-size: 2rem" (click)="op.toggle($event)"></i>

    <p-overlayPanel #op [dismissable]="true" [style]="marginStyle">
        <ng-template pTemplate>

            <div class="flex justify-content-end">
                <p-button icon="pi pi-plus" iconPos="right" (click)="create.toggle($event)"></p-button>
                <p-overlayPanel #create>
                    <ng-template pTemplate>

                        <div class="col-12">
                            <p-dropdown [options]="ticketTypeList" [(ngModel)]="selectedTicketType" optionLabel="name"
                                        optionValue="id"></p-dropdown>
                        </div>

                        <div class="col-12">
                            <div class="p-inputgroup">
                                <input type="text" pInputText placeholder="Alias" [(ngModel)]="alias">
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="p-inputgroup">
                                <input type="text" pInputText placeholder="Name" [(ngModel)]="name">
                            </div>
                        </div>

                        <p-button label="Create Ticket" iconPos="right" (click)="createTicket()"
                                  [disabled]="!alias || !name || !selectedTicketType || busy"></p-button>


                    </ng-template>
                </p-overlayPanel>
            </div>

            <p-table [value]="objectList?.entries" selectionMode="single" [paginator]="resolvedTicketCount>10"
                     [rows]="10" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                     [rowsPerPageOptions]="[10,25,50]">

                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:50%">Object Type</th>
                        <th style="width:50%">Ticket</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-object>
                    <tr class="cursor-pointer" (click)="openTicketDrawer(object.ticketDtoId)">
                        <td>{{object.objectTypeDtoName}}</td>
                        <td>{{object.ticketDtoName}}</td>
                    </tr>
                </ng-template>
            </p-table>

        </ng-template>
    </p-overlayPanel>

</ng-container>

<ng-container *ngIf="initialized && !ticketExist">
    <i class="pi pi-ticket" severity="info" style="font-size: 2rem"></i>
</ng-container>