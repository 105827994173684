<ng-container *ngIf="initialized">

    <div class="grid mb-3">

        <div class="col-12">

            <h4>External Id's</h4>


            <mvs-widget *ngIf="customerExternalAlias"
                        [widgetData]="customerExternalAlias">
            </mvs-widget>

        </div>

    </div>

</ng-container>