<ng-container *ngIf="initialized && objectTypes && variables">
    <div>
        <mvs-widget-header
                *ngIf="configurationItems"
                [configurationItems]="configurationItems"
                [title]="title"
        ></mvs-widget-header>
        <p-tree
                [value]="variables"
                [selectionMode]="selectMode"
                [style]="{ width: treeWidth ? '185px' : '' }"
                (onNodeExpand)="nodeExpand($event)"
                (onNodeSelect)="nodeSelect($event)"
                [loading]="busy"
                [draggableNodes]="dragMode"
                [droppableNodes]="true"
                filterPlaceholder="Search Item"
                draggableScope="variable"
                styleClass="p-fluid text-sm px-0 template-component-treenode border-0"
                class="scrollable-section"
                pDraggable="variable"
                [(selection)]="selectionNode"
                [contextMenu]="cm"
        >
            <ng-template let-node pTemplate="actions">
                <div class="w-full flex justify-content-between">
                    <span>{{ node.label }} <span *ngIf="node?.data?.mandatory" class="text-red-500">*</span> </span>
                    <span *ngIf="!node.parent && node.data.variableType != 'system'" class="node-actions">
                    <i class="pi pi-pencil pi-pencil-variable-node" (click)="handleEditVariableName(node.data.id)"></i>
                    <i class="pi pi-trash pi-trash-variable-node" (click)="handleDeleteVariableName(node.data.id)"></i>
                </span>
                </div>
            </ng-template>
        </p-tree>
    </div>
</ng-container>

<p-contextMenu #cm [model]="items" appendTo="body"/>