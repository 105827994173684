<p-progressSpinner *ngIf="busy"></p-progressSpinner>

<div id="editor-container" #editor style="max-height: 300px; border-radius: 0 0 8px 8px; overflow: auto"></div>
<div class="toolbar mt-3 justify-content-between">
   <div>
       <p-button *ngIf="recoveredText"
                 pTooltip="Use recovered value"
                 tooltipPosition="left"
                 class="align-self-center"
                 styleClass="w-2rem h-2rem p-button-rounded"
                 icon="fa-regular fa-arrow-rotate-right text-sm"
                 (click)="loadRecoveredValue()">
       </p-button>
   </div>

    <div class="flex gap-3">
    <p-button *ngIf="recognition; else startButton"
              (click)="stopSpeechToText()"
              icon="fa-regular fa-circle-stop"
              styleClass="p-button-text p-button-rounded bg-red-500 text-white">
    </p-button>

    <ng-template #startButton>
        <p-button
                (click)="startSpeechToText()"
                icon="fa-regular fa-microphone"
                styleClass="p-button-text p-button-rounded">
        </p-button>
    </ng-template>
       <p-button (click)="saveContent()" [disabled]="buttonDisabled()" [label]="iconLabel"></p-button>
   </div>

</div>
