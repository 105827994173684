<ng-container *ngIf="initialized">
    <!--            tags and watch list-->
<!--    <ng-container>-->
<!--        <div class="flex flex-column gap-2">-->
<!--            &lt;!&ndash;ADD WATCHLIST: CREATE Tm.TicketComment &ndash;&gt;-->
<!--            <mvs-ticket-watchlist [ticketId]="objectIdentifier.objectId"></mvs-ticket-watchlist>-->
<!--        </div>-->

<!--    </ng-container>-->

    <div>

        <div class="flex justify-content-between align-items-center mt-3">
            <div class="px-3">

                <p-dropdown
                        [options]="actions"
                        [(ngModel)]="selectedAction"
                        optionLabel="name"
                        optionValue="code"
                ></p-dropdown>
            </div>

<!--            &lt;!&ndash; ADD TICKET TYPE ACTION    &ndash;&gt;-->
<!--            <mvs-ticket-type-action-->
<!--                    *ngIf="ticketTypeDto.id"-->
<!--                    [dtoTicketType]="ticketTypeDto"-->
<!--                    [ticketId]="objectIdentifier.objectId"-->
<!--                    (onAction)="refreshHistory()"-->
<!--            ></mvs-ticket-type-action>-->

        </div>

        <!-- ADD COMMENTS: CREATE Tm.TicketComment-->
        <div class="flex">

            <div class="pl-3 py-5">
                <p-avatar
                        icon="far fa-user"
                        shape="circle"
                ></p-avatar>
            </div>

            <mvs-comments
                    class="w-full"
                    [objectIdentifier]="objectIdentifier"
                    [documentTypeId]="documentTypeId"
                    [hideLabel]="true"
                    [referenceId]="objectIdentifier.objectId"
                    [serviceInterface]="ticketCommentService"
                    (onInputChange)="handleCommentChange($event)"
                    (onComment)="refreshHistory()"
            ></mvs-comments>

        </div>


    </div>
    <!--TICKET HISTORY-->

    <mvs-ticket-history [ticketId]="objectIdentifier.objectId"
                        [objectIdentifier]="objectIdentifier"
                        [selectedAction]="selectedAction">
    </mvs-ticket-history>
</ng-container>