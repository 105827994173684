import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {LogicCheckSyntaxResponseDto} from "../../dto/logic-check-syntax-response.dto";
import {LogicResultDto} from "../dto/logic-result.dto";
import {LogicCheckTestCaseVariablesResult} from "../dto/logic-check-test-case-variables-result.dto";

@Injectable({
  providedIn: 'root'
})
export class LogicTestCaseService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/lg/logicTestCases')
  }

  evaluateLogic(testCaseId: number): Observable<LogicResultDto> {
    const url: string = MvsCrudService.baseUrl + `/lg/logicTestCases/${testCaseId}/evaluate`;

    return this.http.post<LogicResultDto>(url,null);
  }

  checkVariables(testCaseId: number): Observable<LogicCheckTestCaseVariablesResult> {
    const url: string = MvsCrudService.baseUrl + `/lg/logicTestCases/${testCaseId}/variables`;

    return this.http.post<LogicCheckTestCaseVariablesResult>(url,null);
  }

}