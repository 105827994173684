import { Injectable } from '@angular/core';
import {MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {MvsApiCrudServiceCommentsInterface} from "@kvers/alpha-core-common";
import {
  WfProcessStepActivityMainProviderInterface
} from "../../../wf/component/process-activity/wf-process-step-activity-main-provider.interface";
import {WfProcessTypeStepActivityDto} from "../../../wf/dto/wf-process-type-step-activity.dto";
import {
  WfProcessStepActivityBaseComponent
} from "../../../wf/component/process-activity/wf-process-step-activity-base/wf-process-step-activity-base.component";
import {
  CrCustomerCommentWfProcessStepActivityComponent
} from "../../component/wf/cr-customer-comment-wf-process-step-activity/cr-customer-comment-wf-process-step-activity.component";

@Injectable({
  providedIn: 'root'
})
export class CustomerCommentService extends MvsCrudService implements MvsApiCrudServiceCommentsInterface, WfProcessStepActivityMainProviderInterface{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cr/customerComments');
  }

  /**
   * Return attribute used to store the comment.
   */
  getCommentAttribute(): string {
    return "comment";
  }

  /**
   * Return linked attribute (required to resolve 1:N relationships).
   * @param objectType
   */
  getLinkedAttribute(objectType: string): string {

    if (objectType === "cr.Customer") {
      return "customer";
    } else {
      return super.getLinkedAttribute(objectType);
    }
  }

  getActivityMainComponent(activityType: WfProcessTypeStepActivityDto): typeof WfProcessStepActivityBaseComponent | null {
    return CrCustomerCommentWfProcessStepActivityComponent;
  }

}
