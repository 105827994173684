import Quill from 'quill';

class ImageResize {
    private quill: any;
    private overlay: HTMLElement | undefined;
    private img: HTMLElement | undefined;
    private updateContent: (content: string) => void; // Callback function

    constructor(quill: any, options: { updateContent: (content: string) => void }) {
        this.quill = quill;
        this.updateContent = options.updateContent;

        // Add click listener to detect clicks on images
        this.quill.root.addEventListener('click', (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (target.tagName === 'IMG') {
                this.showOverlay(target);
            } else {
                this.hideOverlay();
            }
        });

        // Add scroll listener to update overlay position
        this.quill.root.addEventListener('scroll', () => {
            if (this.img) {
                this.updateOverlayPosition();
            }
        });
    }

    // Show the resize overlay on the selected image
    private showOverlay(image: HTMLElement) {
        this.img = image;

        // Create overlay if it doesn't exist
        if (!this.overlay) {
            this.overlay = document.createElement('div');
            Object.assign(this.overlay.style, {
                position: 'absolute',
                border: '1px dashed #ccc',
                zIndex: 10,
            });

            // Add resize handles to the overlay
            this.addResizeHandles();
            this.quill.root.parentNode!.appendChild(this.overlay);
        }

        this.updateOverlayPosition();
    }

    // Hide the overlay when clicking outside an image
    private hideOverlay() {
        if (this.overlay) {
            this.overlay.remove();
            this.overlay = undefined;
        }
        this.img = undefined;
    }

    // Add resize handles to the overlay
    private addResizeHandles() {
        ['nw', 'ne', 'sw', 'se'].forEach((corner) => {
            const handle = document.createElement('div');
            Object.assign(handle.style, {
                position: 'absolute',
                width: '10px',
                height: '10px',
                backgroundColor: '#fff',
                border: '1px solid #000',
                cursor: `${corner}-resize`,
            });

            // Position the handles
            const positions = {
                nw: { left: '0%', top: '0%' },
                ne: { right: '0%', top: '0%' },
                sw: { left: '0%', bottom: '0%' },
                se: { right: '0%', bottom: '0%' },
            };

            Object.assign(handle.style, positions[corner]);

            // Add mouse events to handle resizing
            handle.addEventListener('mousedown', (event) => this.startResize(event, corner));
            this.overlay!.appendChild(handle);
        });
    }

    // Start resizing the image
    private startResize(event: MouseEvent, corner: string) {
        event.preventDefault();

        const startX = event.clientX;
        const startY = event.clientY;
        const startWidth = this.img!.offsetWidth;
        const startHeight = this.img!.offsetHeight;

        const onMouseMove = (moveEvent: MouseEvent) => {
            const deltaX = moveEvent.clientX - startX;
            const deltaY = moveEvent.clientY - startY;

            let newWidth = startWidth;
            let newHeight = startHeight;

            // Calculate new dimensions based on the corner being resized
            if (corner.includes('e')) {
                newWidth = startWidth + deltaX;
            } else if (corner.includes('w')) {
                newWidth = startWidth - deltaX;
            }

            if (corner.includes('s')) {
                newHeight = startHeight + deltaY;
            } else if (corner.includes('n')) {
                newHeight = startHeight - deltaY;
            }

            // Apply the new dimensions to the image using the style attribute
            this.img!.style.width = `${newWidth}px`;
            this.img!.style.height = `${newHeight}px`;

            // Remove conflicting attributes
            this.img!.removeAttribute('width');
            this.img!.removeAttribute('height');

            // Persist the dimensions in the style attribute
            this.img!.setAttribute('style', `width: ${newWidth}px; height: ${newHeight}px;`);

            this.updateOverlayPosition();
        };

        const onMouseUp = () => {
            // Notify Quill of the change
            this.updateQuillContent();

            // Cleanup mouse events
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        // Add mouse event listeners
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    }

    // Notify Quill of the updated content
    private updateQuillContent() {
        if (this.img) {
            // Update the content by modifying the image element's attributes directly
            const src = this.img.getAttribute('src')!;
            const style = this.img.getAttribute('style')!;

            // Log the updated image attributes (for debugging purposes)
            console.log('Updated image:', { src, style });

            // The img element is already updated in the DOM, so no need to reinsert it
            this.quill.update(); // Notify Quill to ensure its internal model reflects DOM changes

            // Notify the Angular component of the updated content
            const updatedContent = this.quill.root.innerHTML;
            this.updateContent(updatedContent); // Call the passed-in callback
        }
    }


    // Update the overlay position and size to match the image
    private updateOverlayPosition() {
        const rect = this.img!.getBoundingClientRect();
        const containerRect = this.quill.root.parentNode!.getBoundingClientRect();

        Object.assign(this.overlay!.style, {
            width: `${rect.width}px`,
            height: `${rect.height}px`,
            left: `${rect.left - containerRect.left + this.quill.root.parentNode!.scrollLeft}px`,
            top: `${rect.top - containerRect.top + this.quill.root.parentNode!.scrollTop}px`,
        });
    }
}


// Register the ImageResize module
Quill.register('modules/imageResize', ImageResize);


const ImageFormat = Quill.import('formats/image') as any;

class MyImage extends ImageFormat {
    static sanitize(url: string) {
        return super.sanitize(url, ['http', 'https', 'data', 'blob']) ? url : '//:0';
    }

    static formats(domNode: HTMLElement) {
        const formats = super.formats(domNode) || {};

        // Add support for width and height styles
        formats.width = domNode.style.width;
        formats.height = domNode.style.height;

        return formats;
    }

    format(name: string, value: any) {
        if (name === 'width' || name === 'height') {
            this.domNode.style[name] = value;
        } else {
            super.format(name, value);
        }
    }
}

// class CustomImage extends ImageFormat {
//     static formats(domNode: HTMLElement) {
//         const formats = super.formats(domNode) || {};
//
//         // Add support for width and height styles
//         formats.width = domNode.style.width;
//         formats.height = domNode.style.height;
//
//         return formats;
//     }
//
//     format(name: string, value: any) {
//         if (name === 'width' || name === 'height') {
//             this.domNode.style[name] = value;
//         } else {
//             super.format(name, value);
//         }
//     }
// }

const LinkBlot = Quill.import('formats/link') as any;

class PdfBlot extends LinkBlot {
    static create(value: any) {
        const node = super.create(value); // Create the link node
        node.setAttribute('href', value.href);
        node.setAttribute('target', '_blank'); // Open in a new tab

        // Add a custom PDF icon
        node.innerHTML = `<i class="fas fa-file-pdf"></i> ${value.text}`;
        return node;
    }

    static formats(node: HTMLElement) {
        return {
            href: node.getAttribute('href'),
            text: node.innerText,
        };
    }
}


class CustomClipboard {
    private quill: any;

    constructor(quill: any) {
        this.quill = quill;

        // Add paste event listener
        this.quill.root.addEventListener('paste', (event: ClipboardEvent) => this.onPaste(event));
    }

    onPaste(event: ClipboardEvent) {
        // Prevent the default paste behavior
        event.preventDefault();

        // Get the clipboard data from the event
        const clipboardData = event.clipboardData;
        if (!clipboardData) {
            return; // Exit if clipboardData is not available
        }

        // Log clipboard items for debugging
        const items = clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            console.log(`Item ${i}: Type = ${items[i].type}`);
            if (items[i].type.startsWith('image/')) {
                console.log('Image data detected, preventing paste.');
                return; // Prevent pasting image data
            }
        }

        // Get the plain text from the clipboard
        const text = clipboardData.getData('text/plain');

        // Insert only the text data into the editor
        const range = this.quill.getSelection();
        this.quill.insertText(range.index, text);
        this.quill.setSelection(range.index + text.length);
    }
}

// Register the CustomClipboard module
Quill.register('modules/customClipboard', CustomClipboard);


// Register the custom blot
PdfBlot.blotName = 'pdf';
PdfBlot.tagName = 'a'; // Use <a> for links


Quill.register(PdfBlot);
Quill.register('formats/image', MyImage);

export default Quill;
