<div class="bg-white mt-5" *ngIf="initialized">
    <mvs-config-header [header]="getHeaderName()" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <ng-container *ngIf="activeNavItem == 'cr.CustomerRelatedPersonType'">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="customerRelatedPersonTypeWidget"
                        [widgetData]="customerRelatedPersonTypeWidget"
                        (onObjectSelect)="refreshObjectWidget($event)"
                ></mvs-widget>
            </div>
            <div class="col-8">
                <mvs-widget
                        *ngIf="objectWidget"
                        [widgetData]="objectWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeNavItem == 'name.check'">
        <p-button (click)="batchUpdateNames()" title="Namen aktualisieren" [disabled]="batchBusy">Namen aktualisieren</p-button>
        <p-progressSpinner ariaLabel="Processing" *ngIf="batchBusy" />
    </ng-container>


    <ng-container *ngIf="activeNavItem == 'cr.CustomerConsultingType'">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="customerConsultingTypeWidget"
                        [widgetData]="customerConsultingTypeWidget"
                        (onObjectSelect)="refreshObjectWidget($event)"
                ></mvs-widget>
            </div>
            <div class="col-8">
                <mvs-widget
                        *ngIf="objectWidget"
                        [widgetData]="objectWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="activeNavItem == 'cr.CustomerCategoryType'">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="customerCategoryTypeWidget"
                        [widgetData]="customerCategoryTypeWidget"
                        (onObjectSelect)="refreshObjectWidget($event)"
                ></mvs-widget>
            </div>
            <div class="col-8">
                <mvs-widget
                        *ngIf="objectWidget"
                        [widgetData]="objectWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="activeNavItem == 'cr.CustomerBrokerPoaType'">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="customerBrokerPoaTypeWidget"
                        [widgetData]="customerBrokerPoaTypeWidget"
                        (onObjectSelect)="refreshObjectWidget($event)"
                ></mvs-widget>
            </div>
            <div class="col-8">
                <mvs-widget
                        *ngIf="objectWidget"
                        [widgetData]="objectWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="activeNavItem == 'cr.CustomerBrokerPoaTypeReason'">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="customerBrokerPoaTypeReasonWidget"
                        [widgetData]="customerBrokerPoaTypeReasonWidget"
                        (onObjectSelect)="refreshObjectWidget($event)"
                ></mvs-widget>
            </div>
            <div class="col-8">
                <mvs-widget
                        *ngIf="objectWidget"
                        [widgetData]="objectWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="activeNavItem == 'cr.CustomerType'">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="customerTypeWidget"
                        [widgetData]="customerTypeWidget"
                        (onObjectSelect)="refreshObjectWidget($event)"
                ></mvs-widget>
            </div>
            <div class="col-8">
                <mvs-widget
                        *ngIf="objectWidget"
                        [widgetData]="objectWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="activeNavItem == 'cr.CustomerNotificationType'">
        <div class="grid">
            <div class="col-4">
                <mvs-widget
                        *ngIf="customerNotificationTypeWidget"
                        [widgetData]="customerNotificationTypeWidget"
                        (onObjectSelect)="refreshObjectWidget($event)"
                ></mvs-widget>
            </div>
            <div class="col-8">
                <mvs-widget
                        *ngIf="objectWidget"
                        [widgetData]="objectWidget"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeNavItem == 'cr.CustomerWelcome'">
        <p-tabView>
            <p-tabPanel header="Registration Configuration">
                <mvs-widget
                        *ngIf="customerRegistrationConfigurationWidget"
                        [widgetData]="customerRegistrationConfigurationWidget">
                </mvs-widget>


            </p-tabPanel>

            <p-tabPanel header="Configuration">
                <mvs-widget
                        *ngIf="customerUserConfigurationWidget"
                        [widgetData]="customerUserConfigurationWidget">
                </mvs-widget>
            </p-tabPanel>

            <p-tabPanel header="Welcome">
                <mvs-widget
                        *ngIf="customerWelcomeWidget"
                        [widgetData]="customerWelcomeWidget">
                </mvs-widget>
            </p-tabPanel>

            <p-tabPanel header="Info Welcome">
                <mvs-widget
                        *ngIf="customerInfoPageWidget"
                        [widgetData]="customerInfoPageWidget">
                </mvs-widget>
            </p-tabPanel>


        </p-tabView>
    </ng-container>

    <ng-container *ngIf="activeNavItem == 'cr.CustomerOnboarding'">
        <p-tabView>
            <p-tabPanel header="Type">
                <mvs-widget
                        *ngIf="customerOnboardingTypeWidget"
                        [widgetData]="customerOnboardingTypeWidget">
                </mvs-widget>


            </p-tabPanel>

            <p-tabPanel header="Type Contract">
                <mvs-widget
                        *ngIf="customerOnboardingTypeContractTypeWidget"
                        [widgetData]="customerOnboardingTypeContractTypeWidget">
                </mvs-widget>
            </p-tabPanel>

            <p-tabPanel header="Type Info">
                <mvs-widget
                        *ngIf="customerOnboardingTypeInfoStepWidget"
                        [widgetData]="customerOnboardingTypeInfoStepWidget">
                </mvs-widget>
            </p-tabPanel>


        </p-tabView>
    </ng-container>

    <ng-container *ngIf="activeNavItem == 'cr.CustomerInteraction'">
        <p-tabView>
            <p-tabPanel header="Type">
                <mvs-widget
                        *ngIf="customerInteractionTypeWidget"
                        [widgetData]="customerInteractionTypeWidget">
                </mvs-widget>


            </p-tabPanel>

            <p-tabPanel header="Type Info">
                <mvs-widget
                        *ngIf="customerInteractionTypeInfoStepWidget"
                        [widgetData]="customerInteractionTypeInfoStepWidget">
                </mvs-widget>
            </p-tabPanel>


        </p-tabView>
    </ng-container>

    <ng-container *ngIf="activeNavItem == 'cr.CustomerSignature'">
        <mvs-widget
                *ngIf="customerSignatureTypeWidget"
                [widgetData]="customerSignatureTypeWidget">
        </mvs-widget>

    </ng-container>

    <ng-container *ngIf="activeNavItem == 'cr.CustomerAlias'">
        <mvs-widget
                *ngIf="customerExternalAliasWidget"
                [widgetData]="customerExternalAliasWidget">
        </mvs-widget>

    </ng-container>

    <ng-container *ngIf="activeNavItem == 'cr.AssessmentGroup'">
        <mvs-widget
                *ngIf="assessmentGroupWidget"
                [widgetData]="assessmentGroupWidget">
        </mvs-widget>

        <mvs-widget
                *ngIf="assessmentGroupCheckWidget"
                [widgetData]="assessmentGroupCheckWidget">
        </mvs-widget>

        <mvs-widget
                *ngIf="assessmentOverwriteOptionWidget"
                [widgetData]="assessmentOverwriteOptionWidget">
        </mvs-widget>

        <mvs-widget
                *ngIf="assessmentResultRangeWidget"
                [widgetData]="assessmentResultRangeWidget">
        </mvs-widget>

    </ng-container>

    <ng-container *ngIf="activeNavItem == 'cr.ProfileCheckType'">
        <mvs-widget
                *ngIf="profileCheckTypeWidget"
                [widgetData]="profileCheckTypeWidget">
        </mvs-widget>

        <mvs-widget
                *ngIf="profileCheckCompletenessRangeWidget"
                [widgetData]="profileCheckCompletenessRangeWidget">
        </mvs-widget>

    </ng-container>

</div>
