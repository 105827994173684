<ng-container *ngIf="initialized && objectLoaded && dto && objectIdentifier">

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.standard">
        <cr-customer-standard-navigation
                [objectIdentifier]="objectIdentifier"
                [dto]="dto"
                [customerRelatedPersonWidgetStyle]="customerRelatedPersonWidgetStyle"
                [personRelationPersonWidgetStyle]="personRelationPersonWidgetStyle"
                [changedFilter]="changedFilter"
                [importObjectContext]="myContext"
                (onChangeCustomerFilter)="handleCustomerFilter($event)">
        </cr-customer-standard-navigation>

    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.service">
        <cr-customer-service-navigation
                [viewType]="viewType"
                [objectIdentifier]="objectIdentifier">
        </cr-customer-service-navigation>
    </ng-container>

    <ng-container *ngIf="viewType  == CrCustomerObjectViewTypeUiEnum.sales">
        <cr-customer-sales-navigation
                [viewType]="viewType"
                [objectIdentifier]="objectIdentifier"
                [dto]="dto">
        </cr-customer-sales-navigation>
    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.communication">
        <cr-customer-communication-navigation
                [viewType]="viewType"
                [objectIdentifier]="objectIdentifier">
        </cr-customer-communication-navigation>
    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.registration">
        <cr-customer-registration-navigation
                [viewType]="viewType"
                [objectIdentifier]="objectIdentifier">
        </cr-customer-registration-navigation>
    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.ticketsAndWorkflows">
        <cr-customer-tickets-workflows-navigation
                [objectIdentifier]="objectIdentifier">
        </cr-customer-tickets-workflows-navigation>
    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.contracts">
        <cr-customer-contracts-navigation
                [objectIdentifier]="objectIdentifier"
                [importObjectContext]="myContext"
                [dto]="dto"
                [customerRelatedPersonWidgetStyle]="customerRelatedPersonWidgetStyle"
                [householdPersons]="householdPersons"
                (onChangeCustomerFilter)="handleCustomerFilter($event)">
        </cr-customer-contracts-navigation>

    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.interactions">
        <cr-customer-interactions-navigation
                [objectIdentifier]="objectIdentifier"
                (onChangeCustomerFilter)="handleCustomerFilter($event)">
        </cr-customer-interactions-navigation>

    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.additionalInformation">
        <cr-customer-additional-information-navigation
                [objectIdentifier]="objectIdentifier">
        </cr-customer-additional-information-navigation>

    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.documentUpload">
        <cr-customer-document-upload
                [objectIdentifier]="objectIdentifier">
        </cr-customer-document-upload>
    </ng-container>

    <ng-container *ngIf="viewType == CrCustomerObjectViewTypeUiEnum.promo">
        <cr-customer-promo
                [objectIdentifier]="objectIdentifier">
        </cr-customer-promo>
    </ng-container>

    <mvs-ticket [visible]="viewType == CrCustomerObjectViewTypeUiEnum.genericTickets"
                [objectIdentifier]="objectIdentifier"
                (onTicketsCount)="handleTicketsCount($event)">

    </mvs-ticket>

    <mvs-workflow [visible]="viewType == CrCustomerObjectViewTypeUiEnum.genericWorkflows"
                  [objectRequestList]="workflowObjectRequestList"
                  (onWorkflowCount)="handleWorkflowsCount($event)">
    </mvs-workflow>

    @if (viewType == CrCustomerObjectViewTypeUiEnum.externalIds) {
        <cr-customer-external-ids [objectIdentifier]="objectIdentifier">

        </cr-customer-external-ids>

    }

    @if (viewType == CrCustomerObjectViewTypeUiEnum.appointments) {
        <cr-customer-appointments [objectIdentifier]="objectIdentifier">

        </cr-customer-appointments>

    }



</ng-container>