<ng-container *ngIf="objectIdentifier && initialized">


    <!-- Customer Navigation -->
    <div class="surface-0 shadow-1 px-4 py-3 mb-3 cr-customer-sticky-header">

        <div class="flex justify-content-between gap-2">
            <!--       left side of the header-->

            <div class="flex gap-2 w-full align-items-center">


                <!--               customer image commented, using it on right side for now     -->

                <div class="flex gap-2 align-items-center">
                    <!--                                    <div>-->
                    <!--                                        <img *ngIf="photoUrl && (photoUrl | mvsSecureBlob); else noImage"-->
                    <!--                                             [src]="photoUrl | mvsSecureBlob"-->
                    <!--                                             class="w-3rem h-3rem">-->
                    <!--                                        <ng-template #noImage>-->
                    <!--                                            <i *ngIf="customerDto?.personDto?.gender == 1" class="fa-thin fa-person fa-3x"></i>-->
                    <!--                                            <i *ngIf="customerDto?.personDto?.gender == 2" class="fa-thin fa-person-dress fa-3x"></i>-->
                    <!--                                            <i *ngIf="customerDto?.personDto?.gender == 3" class="fa-thin fa-people fa-3x"></i>-->
                    <!--                                        </ng-template>-->
                    <!--                                    </div>-->
                    <ng-container *ngIf="customerDto">
                        <div class="mr-5">
                            <h5 class="mb-0">{{ customerDto.personDtoName }}</h5>
                            <small>{{ customerDto.alias }}</small>
                        </div>
                    </ng-container>
                </div>

                <div *ngIf="customerDto">
                    <!--                    <pm-household-persons [customerDto]="customerDto"-->
                    <!--                                          (selectedRelatedPersonsChanged)="handlePersonRelationPersonsChanged($event)"-->
                    <!--                                          (householdPersonsLoaded)="handleHouseholdPersonsLoaded($event)">-->
                    <!--                    </pm-household-persons>-->

                    <customer-person-household-persons
                            [customerId]="objectIdentifier.objectId"></customer-person-household-persons>

                </div>


            </div>

            <!--        right side of the header -->


            <div class="flex align-items-center gap-2">

                <div class="flex pointer" (click)="overlayExternal.toggle($event)">
                    @if (customerDto && customerDto.customerExternalAlias?.length) {
                        <!--                        <p-button label="External ID's" (click)="overlayExternal.toggle($event)"></p-button>-->

                        @for (system of uiExternalAlias; track system.systemDtoId) {
                            <i class="{{system.systemDto.image}} text-{{system.systemDto.rgbaColor}} fa-2xl"></i>
                        }
                    }
                </div>

                <!--                <div class="flex align-items-center border-1 border-300 px-3 border-300 border-round">-->
                <!--                    <div class="flex flex-column justify-content-center gap-1">-->
                <!--                        <div class="flex align-items-center gap-3">-->
                <!--                        <span class="text-800 font-bold flex gap-2 align-items-center">-->
                <!--                            <i class="fa-regular fa-arrow-up"></i>-->
                <!--                            <span class="">600$</span>-->
                <!--                        </span>-->
                <!--                            <span class="text-secondary flex gap-2 align-items-center">-->
                <!--                            <i class="fa-regular fa-arrow-down"></i>-->
                <!--                                <span class="">800$</span>-->
                <!--                        </span>-->
                <!--                        </div>-->
                <!--                        <span class="text-500 text-sm">Monthly</span>-->
                <!--                    </div>-->
                <!--                    <p-divider layout="vertical"/>-->
                <!--                    <div class="flex flex-column justify-content-center gap-1">-->
                <!--                        <div class="flex align-items-center gap-3">-->
                <!--                        <span class="text-800 font-bold flex gap-2 align-items-center">-->
                <!--                            <i class="fa-regular fa-arrow-up"></i>-->
                <!--                            <span class="">1600$</span>-->
                <!--                        </span>-->
                <!--                            <span class="text-secondary flex gap-2 align-items-center">-->
                <!--                            <i class="fa-regular fa-arrow-down"></i>-->
                <!--                                <span class="">340$</span>-->
                <!--                        </span>-->
                <!--                        </div>-->
                <!--                        <span class="text-500 text-sm">Yearly</span>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="flex align-items-center border-1 border-300 border-round">
                    <div class="flex align-items-center gap-2 cursor-pointer hover:surface-hover px-2"
                         (click)="pc.toggle($event)">
                        <div class="p-1 border-round bg-{{profileCheckData?.completenessRangeDto?.profileColor}}">
                            <p-avatar
                                    class="mx-1 avatar-progress-border" style="width: 2.6rem; height: 2.6rem;"
                                    [style.background]="'conic-gradient(var(--' + profileCheckData?.completenessRangeDto?.ringLineColor + ') 0% ' +
                                    profileCheckData?.completionInPercent + '%,' +
                                    'var(--' + profileCheckData?.completenessRangeDto?.progressCircleColor + ') 0% 100%)'"
                                    [styleClass]="'mx-1 surface-100'"
                                    shape="circle">
                                <img
                                        *ngIf="photoUrl && (photoUrl | mvsSecureBlob); else fallbackContent"
                                        [src]="photoUrl | mvsSecureBlob"
                                        class="w-3rem h-3rem"
                                />
                                <ng-template #fallbackContent>
                                    <i *ngIf="customerDto?.personDto?.gender == 1" class="fa-thin fa-person"></i>
                                    <i *ngIf="customerDto?.personDto?.gender == 2" class="fa-thin fa-person-dress"></i>
                                    <i *ngIf="customerDto?.personDto?.gender == 3" class="fa-thin fa-people"></i>
                                </ng-template>
                            </p-avatar>
                        </div>
                        <div class="flex flex-column">
                            @if (profileCheckData?.completionInPercent) {
                                <span class="text-800 font-bold">
                            {{ profileCheckData?.completionInPercent }}%
                            </span>
                            }
                            <span class="text-500 text-sm">
                        {{ profileCheckData?.completenessRangeDto?.name }}
                            </span>
                        </div>
                    </div>


                    <p-divider styleClass="mx-2" layout="vertical"/>

                    <div class="px-3 py-1 cursor hover:surface-hover" (click)="ch.toggle($event)">
                        <i class="text-4xl {{contractHealthData?.result?.image}} text-{{contractHealthData?.result?.rgbaColor}}"></i>
                    </div>
                </div>

            </div>

        </div>
    </div>


    <!--Customer Filters -->
    <div class="flex mt-1 mb-2" *ngIf="customerFilter.length > 0 && viewType == 0">

        <div class="col-12 flex">
            <h3 class="mb-0">{{ customerName }}</h3>

            <div class="flex align-items-center mx-4 gap-2 flex-wrap">
                <p-chip
                        *ngFor="let filter of customerFilter"
                        [label]="filter.field"
                        [removable]="true"
                        (onRemove)="onRemoveFilterCriteria(filter)"
                ></p-chip>
            </div>

        </div>

    </div>

    <!-- Show Object -->
    <!--    *ngIf="householdPersons"-->
    <mvs-cr-customer
            [objectIdentifier]="objectIdentifier"
            [changedFilter]="changedFilter"
            [viewType]="viewType"
            [customerRelatedPersonWidgetStyle]="customerRelatedPersonWidgetStyle"
            [personRelationPersonWidgetStyle]="personRelatedPersonWidgetStyle"
            [householdPersons]="householdPersons"
            (onChangeCustomerFilter)="handleCustomerFilter($event)"
            (onTicketsCount)="handleTicketsCount($event)"
            (onWorkflowsCount)="handleWorkflowsCount($event)"
    >
    </mvs-cr-customer>

</ng-container>

<p-overlayPanel
        styleClass="ml-4"
        #op
        disabled="true"
        appendTo="body">

    <h3>Create Ticket</h3>

    <mvs-create-ticket-object *ngIf="showCreateTicketOverlay"
                              [objectId]="customerDto.id"
                              [objectAlias]="'cr.Customer'"
                              (onTicketCreation)="handleTicketCreation($event)">
    </mvs-create-ticket-object>

</p-overlayPanel>

<p-overlayPanel #pc styleClass="w-30rem">
    <h4 class="font-medium ml-2">Profile Analytics</h4>
    <div class="flex align-items-center gap-2">
        <div class="p-1 border-round bg-{{profileCheckData?.completenessRangeDto?.profileColor}}">
            <p-avatar
                    class="mx-1 avatar-progress-border" style="width: 4.4rem; height: 4.4rem"
                    [style.background]="'conic-gradient(var(--' + profileCheckData?.completenessRangeDto?.ringLineColor + ') 0% ' +
                                    profileCheckData?.completionInPercent + '%,' +
                                    'var(--' + profileCheckData?.completenessRangeDto?.progressCircleColor + ') 0% 100%)'"
                    [styleClass]="'mx-1 surface-100'"
                    size="xlarge" shape="circle">
                <img
                        *ngIf="photoUrl && (photoUrl | mvsSecureBlob); else noImgContent"
                        [src]="photoUrl | mvsSecureBlob"
                />
                <ng-template #noImgContent>
                    <i *ngIf="customerDto?.personDto?.gender == 1" class="fa-thin fa-person"></i>
                    <i *ngIf="customerDto?.personDto?.gender == 2" class="fa-thin fa-person-dress"></i>
                    <i *ngIf="customerDto?.personDto?.gender == 3" class="fa-thin fa-people"></i>
                </ng-template>
            </p-avatar>
        </div>
        <div class="flex flex-column gap-1">
                            <span class="text-800 font-medium text-3xl">
                            {{ profileCheckData?.completionInPercent }}%
                            </span>
            <span class="text-500 text-2xl">
                                {{ profileCheckData?.completenessRangeDto?.name }}
                            </span>
        </div>
    </div>
    <div class="ml-2" *ngIf="profileCheckData?.failedChecks?.length">
        <h5 class="mt-4 font-medium"><i class="fa-regular fa-circle-exclamation mr-2 text-red-600"></i> Failed Checks
        </h5>
        <div class="flex flex-column gap-2">
            <div class="p-2" *ngFor="let check of profileCheckData?.failedChecks">
                <span class="hover:underline cursor hover:text-primary hover:font-medium"
                      (click)="openObjectDrawer(check.personId)">{{ check.personName }} </span>
                @if (check.checkTypeDto.name) {
                    <span class="text-600"> : {{ check.checkTypeDto.name }}</span>
                }
            </div>
        </div>
    </div>

    <div class="mt-4 ml-2" *ngIf="profileCheckData?.successfulChecks?.length">
        <h5 class="mt-4 font-medium"><i class="fa-regular fa-circle-check mr-2 text-green-600"></i> Successful Checks
        </h5>
        <div class="flex flex-column gap-2">
            <div class="p-2" *ngFor="let check of profileCheckData?.successfulChecks">
                <span class="">{{ check.personName }} - </span>
                <span class="text-600">{{ check.checkTypeDto.name }}</span>
            </div>
        </div>
    </div>
</p-overlayPanel>


<p-overlayPanel #ch styleClass="w-30rem">
    <h4 class="font-medium ml-2"> Contract Health</h4>
    <div class="flex align-items-center gap-3 mb-3">
        <div class="p-1">
            <i class="text-6xl {{contractHealthData?.result?.image}} text-{{contractHealthData?.result?.rgbaColor}}"></i>
        </div>
        <div class="flex flex-column gap-1">
                            <span class="text-800 font-medium text-xl">
                            {{ contractHealthData?.result?.name }}
                            </span>
            <span class="text-500">
                               Overall Insurance Health
                            </span>
        </div>
    </div>

    <div *ngIf="contractHealthData?.groups" class="col-12 p-0">
        <p-accordion [multiple]="true" [activeIndex]="0">
            <p-accordionTab *ngFor="let entry of contractHealthData.groups"
                            tabStyleClass="mb-1 shadow-none border-bottom-1 border-300">
                <ng-template pTemplate="header">
                    <div class="flex align-items-center gap-2">
                        <i class="text-primary {{entry.group.image}}"></i>
                        <h6 class="mr-3 my-0 font-normal">{{ entry.group.name }} </h6>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="p-2 flex flex-column gap-3" *ngFor="let entryCheck of entry.checks">
                        <div class="flex w-full gap-2 justify-content-between">
                            <div class="flex align-items-center gap-2">
                                <i class="{{entryCheck.check.image ? entryCheck.check.image : 'fa-duotone fa-solid fa-circle'}}"></i>
                                <span class="">
                                    {{ entryCheck.check.name }}
                                </span>
                            </div>
                            <i class="pi pi-check-circle"
                               [ngClass]="{'text-green-500': entryCheck.checkResult == 'passed',
                                   'text-500': entryCheck.checkResult == 'not_passed', 'text-blue-400': entryCheck.checkResult == 'partially_passed'}"></i>
                        </div>

                        @if (entryCheck.personName) {
                            <div class="shadow-1 flex gap-2 p-3 mt-3">
                                <i class="pi pi-user"></i>
                                <div class="flex flex-column gap-2">
                                    <span class="font-medium"> {{ entryCheck.personName }}</span>
                                </div>
                            </div>
                        }
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </div>
</p-overlayPanel>

<p-overlayPanel #overlayExternal [style]="{ width: '27rem'}">

    <!--    <mvs-widget [widgetData]="customerExternalAliasWidget" *ngIf="customerExternalAliasWidget">-->

    <!--    </mvs-widget>-->

    <h5 class="pl-2">External Partners</h5>
<!--    @if (uiExternalAlias && customerDto.customerExternalAlias?.length) {-->
<!--        <div class="flex flex gap-3 flex-column">-->
<!--            @for (item of customerDto.customerExternalAlias; track item.id) {-->
<!--                <div class="flex align-items-center justify-content-between gap-2">-->
<!--                    <div class="flex gap-2 w-full">-->
<!--                        <i class="{{item.systemDto.image}} text-6xl text-{{item.systemDto.rgbaColor}}"></i>-->
<!--                        <div class="flex flex gap-1 flex-column w-full">-->
<!--                            <div class="flex flex gap-1 justify-content-between align-items-center">-->
<!--                                <span class="text-color-secondary text-lg">User:</span>-->
<!--                                <span class="font-bold text-xl pl-3">-->
<!--                                    {{ item.externalAlias }}-->
<!--                        </span>-->
<!--                            </div>-->
<!--                            <span class="text-color-secondary">{{ item.systemDtoName }}</span></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            }-->
<!--        </div>-->
<!--    }-->

    @if (uiExternalAlias && uiExternalAlias.length) {
        <div class="flex flex gap-3 flex-column">
            @for (item of uiExternalAlias; track item.systemDtoId) {
                <div class="flex align-items-center justify-content-between gap-2">
                    <div class="flex gap-2 w-full">
                        <i class="{{item.systemDto.image}} text-6xl text-{{item.systemDto.rgbaColor}}"></i>
                        <div class="flex gap-1 flex-column w-full">
                            <div class="flex gap-1 justify-content-between">

                                <div class="flex gap-1 flex-column text-color-secondary">
                                <span class="text-lg">User:</span>
                                <span class="">{{ item.systemDto.name }}</span>
                                </div>

                                <div class="flex gap-1 flex-column">

                                    @for (alias of item.uiAliasList; track alias) {
                                        <span class="font-medium text-xl pl-3">
                                            {{ alias }}
                                        </span>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    }


</p-overlayPanel>
