@if (initialized) {
    @if(currentView == 'allFilters') {
        <div class="flex justify-content-between align-items-center border-bottom-1 border-200 pb-2">
            <h4 class="m-0">Saved Filters</h4>
            <p-button pTooltip="Create Filter" icon="pi pi-plus-circle text-xl" styleClass="p-button-text"
                      (click)="handleCreateFilter()"></p-button>
        </div>
        <div class="group-list" *ngIf="groupFilters && groupFilters.length">
            @for (group of groupFilters; track group.id) {
                <div class="group-item flex justify-content-between align-items-center mt-1 p-2">
                    <div class="group-title">
                        &nbsp;{{ group.name }}
                    </div>
                    <div class="flex gap-2 align-items-center">
                        @if (group.access == TicketGroupFilterAccess.public_access) {
                            <p-badge value="Public" severity="success" styleClass="badge-style"></p-badge>
                        } @else if (group.access == TicketGroupFilterAccess.private_access) {
                            <p-badge value="Private" styleClass="badge-style"></p-badge>
                        }

                        <p-button [icon]="'fa-regular fa-pencil pointer'" [disabled]="group.access == TicketGroupFilterAccess.private_access" styleClass="p-button-text" (click)="handleEditFilter(group)"></p-button>
                        <p-button [icon]="'fa-regular fa-trash pointer'" [disabled]="group.access == TicketGroupFilterAccess.private_access" styleClass="p-button-text"></p-button>
<!--                        <i class="fa-regular fa-pencil ml-2 pointer" (click)="handleEditFilter(group)"></i>-->
<!--                        <i class="fa-regular fa-trash ml-2 pointer"></i>-->

                    </div>
                </div>
            }
        </div>
    } @else if (currentView != 'allFilters') {

        <div (click)="handleMainNavigation()" class="pointer">
            <i class="fa-solid fa-chevron-left"></i> Back To All Filters
        </div>
        <hr>

        @if (currentView == 'createFilter') {
            <mvs-object
                    [objectType]="'tm.TicketGroupFilter'"
                    [objectId]="0"
                    [mode]="MvsCrudModeEnum.create"
                    (onChangedObject)="handleMainNavigation()"
            ></mvs-object>
        }

        @if (currentView == 'updateFilter') {
            <mvs-object
                    *ngIf="selectedGroup"
                    [objectType]="'tm.TicketGroupFilter'"
                    [objectId]="selectedGroup.id"
                    [mode]="MvsCrudModeEnum.update"
            ></mvs-object>
        }


    }

}