import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {PersonService} from "../../service/api/person.service";
import {
    DtoDetail,
    DtoListAttributeRequestAggregateEnum,
    FilterCriteria,
    MetaDataEntityDto,
    MetaDataJoinDto,
    MetaService,
    MvsCoreService,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    ObjectChangeInformation,
    ObjectIdentifier,
    ObjectIdentifierData,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectRequestListAttribute,
    ObjectRequestListGroupBy,
    ObjectRequestRelation,
    ObjectRequestSimple,
    PagingDto,
    Sorting,
    WidgetData,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {PmPersonDto} from "../../../cr/dto/pm-person.dto";
import {PmPersonGender} from "../../../cr/enum/pm-person-gender.enum";
import {Subscription, timer} from "rxjs";
import {PersonSearchSelectEvent} from "./person-search-select.event";
import {PersonSearchCreateModeEnum} from "./person-search-create-mode.enum";
import {ConfirmationService} from "primeng/api";
import {MvsSearchFlexibleBaseComponent, MvsSearchFlexibleResultData, WidgetFactory} from "@kvers/alpha-ui";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'pm-person-search-component',
    templateUrl: './person-search.component.html',
})
export class PersonSearchComponent
    extends MvsSearchFlexibleBaseComponent
    implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() aliasSuffix: string = "";
    @Input() targetObjectType: string;
    @Input() createDefaultDto: DtoDetail;
    @Input() formControlOverwrite: MvsFormControlOverwrite;
    @Input() suggestionsObjectRequestList: ObjectRequestList;
    @Input() createMode: PersonSearchCreateModeEnum;
    @Output() onPersonSelect: EventEmitter<PersonSearchSelectEvent> = new EventEmitter<PersonSearchSelectEvent>();
    @Output() onContinueWithoutSelection: EventEmitter<PmPersonDto> = new EventEmitter<PmPersonDto>();

    protected pmPersonService: PersonService;
    targetObjectEntityMeta: MetaDataEntityDto;
    targetObjectDefaultCreateDto: DtoDetail;
    personDefaultCreateDto: PmPersonDto;

    person: PmPersonDto
    firstName: string
    lastName: string;
    gender: PmPersonGender;
    widgetPersonTable: WidgetData;
    widgetSuggestionTable: WidgetData;
    genderOptions = this.getGenderValues();
    subscription: Subscription;
    tabIndex: number;
    uiMode: string;
    personWidgetData: WidgetData;
    targetObjectWidgetData: WidgetData;

    myForm: FormGroup;

    prefixLatestTargetObject: string = "latest";

    constructor(private fb: FormBuilder,
                protected coreService: MvsCoreService,
                protected metaService: MetaService,
                protected confirmationService: ConfirmationService) {
        super();

        this.myForm = this.fb.group({
            firstName: [''],
            lastName: [''],
            gender: [null] // You can add validators for gender if needed
        });

    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        // initialize PmPersonService
        this.pmPersonService = <PersonService>this.coreService.getCrudService("pm.Person");
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        if (this.defaultDto) {
            const formValues = this.myForm.value;
            formValues.gender = this.defaultDto['gender'];
            formValues.firstName = this.defaultDto['firstName'];
            formValues.lastName = this.defaultDto['lastName'];

            this.myForm.patchValue(formValues);
        }

        this.uiMode = "search";

        if (this.targetObjectType) {
            this.loadMetaForTarget();
        } else {
            this.refreshComponentDelayed();
        }
        this.preparePersonWidget();

    }

    /**
     * Refresh component once all Meta information was retrieved.
     */
    refreshComponentDelayed() {
        this.loadSuggestionTable();
        this.initialized = true;
    }

    /**
     * Load meta information
     */
    loadMetaForTarget() {

        this.metaService.getByAlias(this.targetObjectType, false, true, true).subscribe(
            value => {
                this.targetObjectEntityMeta = value;
                this.refreshComponentDelayed();
            });

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    onInputFieldDetails() {
        //this.busy = true;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = timer(1000).subscribe(() => {
            this.onPersonDetails();
            this.subscription = null;
        });

    }

    onPersonDetails() {
        //this.busy = true;
        const filterCriteria: FilterCriteria[] = [];
        const formValues = this.myForm.value;

        if (formValues.firstName) {
            const firstNameFilterCriteria = FilterCriteria.create("firstName", FilterCriteria.cOperatorContainsPattern, '%' + formValues.firstName + '%', null);
            filterCriteria.push(firstNameFilterCriteria);
        }
        if (formValues.lastName) {
            const lastNameFilterCriteria = FilterCriteria.create("lastName", FilterCriteria.cOperatorContainsPattern, '%' + formValues.lastName + '%', null);
            filterCriteria.push(lastNameFilterCriteria);
        }

        if (formValues.gender) {
            const genderFilterCriteria = FilterCriteria.create("gender", FilterCriteria.cOperatorEqual, formValues.gender, null);
            filterCriteria.push(genderFilterCriteria);
        }

        this.onSearchAction.emit(formValues);

        this.loadPersonTable(filterCriteria);

    }

    loadPersonTable(filterCriteria: FilterCriteria[]) {

        const alias = this.getWidgetAlias("pm.person.search.list.table");

        this.widgetPersonTable = new WidgetData();
        this.widgetPersonTable.idAlias = alias;
        this.widgetPersonTable.name = "Person";
        this.widgetPersonTable.uiComponent = "table";
        this.widgetPersonTable.dataProvider = "list";
        this.widgetPersonTable.dataSource = "entity";
        this.widgetPersonTable.dataProviderObject = "pm.Person";
        this.widgetPersonTable.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 10)
        );

        this.adjustProviderListRequest(this.widgetPersonTable);
        this.widgetPersonTable.setParamValue("selectionMode", "rowSelect");
        this.widgetPersonTable.setParamValue("rowSelectionMode", "sticky");
        // this.widgetPersonTable.setParamValue("selectionMode", "none");
        // this.widgetPersonTable.setParamValue("size", "S");

        this.tabIndex = 0;

    }

    private adjustProviderListRequest(widgetData: WidgetData) {
        const requestComplex = this.getObjectRequestComplex();

        if (requestComplex) {
            widgetData.dataProviderListRequest.objectRequestComplex = requestComplex;
        } else {
            widgetData.dataProviderListRequest.objectRequestSimple = ObjectRequestSimple.create(true, true, 1);
        }
    }

    getObjectRequestComplex(): ObjectRequestComplex | null {

        if (!this.targetObjectType) {
            return null;
        }

        // check the connection to the person
        let personJoin: MetaDataJoinDto = this.getTargetObjectJoin();

        if (!personJoin) {
            return null;
        }

        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode('personDetail'),
            ObjectRequestComplexNode.createRelationNode(this.prefixLatestTargetObject + this.targetObjectType, ObjectRequestRelation.createLatest(this.targetObjectType, personJoin.attributeName)),

            ObjectRequestComplexNode.createRelationNode('count' + this.targetObjectType,
                ObjectRequestRelation.createGroupBy(this.targetObjectType, personJoin.attributeName,
                    ObjectRequestListGroupBy.create(true, [], [], [personJoin.attributeName],
                        [new ObjectRequestListAttribute(personJoin.attributeName, "Anzahl", DtoListAttributeRequestAggregateEnum.count)])))
        );

    }

    private getTargetObjectJoin(): MetaDataJoinDto {

        let personJoin: MetaDataJoinDto;

        for (let join of this.targetObjectEntityMeta.joins) {

            if (join.joinObjectTypeAlias === "pm.Person") {
                personJoin = join;
                break;
            }

        }
        return personJoin;
    }

    protected getWidgetAlias(alias: string): string {

        let adjustedAlias = alias;

        if (this.targetObjectType) {
            adjustedAlias = adjustedAlias + "." + this.targetObjectType;
        }

        if (this.aliasSuffix) {
            adjustedAlias = adjustedAlias + "." + this.aliasSuffix;
        }

        return adjustedAlias;

    }

    loadSuggestionTable() {

        if (this.suggestionsObjectRequestList) {

            const alias = this.getWidgetAlias("pm.person.suggestion.list.table");

            this.widgetSuggestionTable = new WidgetData();
            this.widgetSuggestionTable.idAlias = alias;
            this.widgetSuggestionTable.name = "Person Suggestion";
            this.widgetSuggestionTable.uiComponent = "table";
            this.widgetSuggestionTable.dataProvider = "list";
            this.widgetSuggestionTable.dataSource = "entity";
            this.widgetSuggestionTable.dataProviderObject = "pm.Person";
            this.widgetSuggestionTable.dataProviderListRequest = this.suggestionsObjectRequestList;

            this.adjustProviderListRequest(this.widgetSuggestionTable);

            this.widgetSuggestionTable.setParamValue("selectionMode", "single");
            this.widgetSuggestionTable.setParamValue("size", "S");
            this.widgetSuggestionTable.setParamValue("rowSelectionMode", "sticky");

            this.tabIndex = 1;

        } else {
            this.tabIndex = 0;
            this.widgetSuggestionTable = null;
        }

    }

    /**
     * Process selection of record.
     * @param object
     */
    onPersonSelection(object: any) {
        const objectIdentifierPerson = new ObjectIdentifier("pm.Person", object["id"]);
        let objectIdentifierTargetObject = null;

        if (this.targetObjectType) {

            if (object[this.prefixLatestTargetObject + this.targetObjectType + "#id"] && object[this.prefixLatestTargetObject + this.targetObjectType + "#id"] != '-') {
                objectIdentifierTargetObject = new ObjectIdentifier(this.targetObjectType, object[this.prefixLatestTargetObject + this.targetObjectType + "#id"]);
            } else {

                if (this.createMode === PersonSearchCreateModeEnum.TARGET || this.createMode === PersonSearchCreateModeEnum.ANY) {

                    // ask the user whether target object should be created
                    this.confirmationService.confirm({
                        message: 'Do you want to create the Target Object?',
                        header: 'Confirmation',
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => {
                            this.uiSwitchToCreateTarget(object["id"]);
                        },
                        reject: () => {
                            // do nothing
                        }
                    });

                    // stop further processing
                    return;

                }
            }

        } else {
            objectIdentifierTargetObject = objectIdentifierPerson;
        }

        this.onPersonSelect.emit(new PersonSearchSelectEvent(objectIdentifierPerson, objectIdentifierTargetObject, object));
    }


    onWithoutSelection() {
        this.person.firstName = this.firstName;
        this.person.lastName = this.lastName;
        if (this.gender) {
            this.person["gender"] = this.gender;
        }
        this.onContinueWithoutSelection.emit(this.person);
    }

    getGenderValues(): { key: string, value: number }[] {

        return [
            {value: PmPersonGender.notDefined.valueOf(), key: "nicht definiert"},
            {value: PmPersonGender.male.valueOf(), key: "männlich"},
            {value: PmPersonGender.female.valueOf(), key: "weiblich"},
            {value: PmPersonGender.nonBinary.valueOf(), key: "divers"},
        ];

    }

    onDataLoad($event) {
        /*
                this.tabIndex = 0;
                if ($event) {
                    this.busy = false;
                }
                */

    }

    /**
     * Target Object Created.
     * @param changeInfo
     */
    onTargetObjectCreate(changeInfo: ObjectChangeInformation) {
        const id = changeInfo.after["id"]
        const objectTypeAlias = changeInfo.objectType
        this.onPersonSelect.emit(new PersonSearchSelectEvent(null, new ObjectIdentifier(objectTypeAlias, id), changeInfo.after));
    }

    /**
     * Create Person.
     * @param changeInfo
     */
    onPersonObjectCreate(changeInfo: ObjectChangeInformation) {
        if (this.targetObjectType) {
            this.uiSwitchToCreateTarget(changeInfo.after["id"]);
        } else {
            const personObjectIdentifier = new ObjectIdentifier(changeInfo.objectType, changeInfo.after["id"]);
            this.onPersonSelect.emit(new PersonSearchSelectEvent(personObjectIdentifier, personObjectIdentifier, changeInfo.after));
        }
    }

    uiSwitchToCreateTarget(personId: number) {
        const personJoin: MetaDataJoinDto = this.getTargetObjectJoin();

        this.targetObjectDefaultCreateDto = new DtoDetail({});
        this.targetObjectDefaultCreateDto[personJoin.attributeName + "DtoId"] = personId;

        //combining the default dto sent from caller component with personDtoId as a default

        const combinedDefaultCreateDto: DtoDetail = new DtoDetail({
            ...this.targetObjectDefaultCreateDto,
            ...this.createDefaultDto
        });

        this.targetObjectDefaultCreateDto = combinedDefaultCreateDto;
        this.prepareTargetObjectWidget();
        this.uiMode = 'createTarget';


    }

    uiSwitchToCreatePerson() {
        this.uiMode = "createPerson";
        this.targetObjectDefaultCreateDto = null;

        this.personDefaultCreateDto = new PmPersonDto();

        const value = this.myForm.getRawValue();

        this.personDefaultCreateDto.firstName = value.firstName;
        this.personDefaultCreateDto.lastName = value.lastName;
        this.personDefaultCreateDto.gender = value.gender;

    }

    onShowCreateTargetObject() {
        this.uiSwitchToCreatePerson();
    }

    handleTabIndex(event: any) {
        this.tabIndex = event.index
    }

    preparePersonWidget() {
        // this.objectViewWidget = WidgetFactory.createWidgetObject(
        //     `person.search.create.person.widget`,
        //     'Object',
        //     'pm.Person',
        //     null,
        //     WidgetDataParam.create('createDefaultDto', this.personDefaultCreateDto)
        // );

        this.personWidgetData = WidgetFactory.createWidgetObjectWithCallback(
            "person.search.create.person.widget",
            "Object",
            "pm.Person",
            {
                onFunctionObjectDefaultNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {

                    let test: WidgetToolbarCallbackInterface;
                    test = {
                        dto: this.personDefaultCreateDto,
                    };
                    return test;
                }
            },
            null
        );

    }

    prepareTargetObjectWidget() {
        this.targetObjectWidgetData = WidgetFactory.createWidgetObjectWithCallback(
            "person.search.create.target.widget",
            "Object",
            this.targetObjectType,
            {
                onFunctionObjectDefaultNew : (widgetData: WidgetData):WidgetToolbarCallbackInterface => {
                    const overwrite = new MvsFormControlOverwrite();
                    overwrite.addField('personDtoId', MvsFormFieldAccessEnum.READ);

                    let test: WidgetToolbarCallbackInterface;
                    test = {
                        dto: this.targetObjectDefaultCreateDto,
                        formControlOverwrite: overwrite
                    };
                    return test;
                }
            },
            null
        );
    }

    handleObjectSelect(event: ObjectIdentifierData) {

        let name: string = "";

        if (event.data["firstName"] && event.data["lastName"]) {
            name = event.data["firstName"] + " " + event.data["lastName"];
        } else {
            name = event.objectId + "";
        }

        const result: MvsSearchFlexibleResultData =
            MvsSearchFlexibleResultData.create(event, name);

        this.onObjectSelect.emit(result);
    }
}
