import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {DmDocumentStorageService} from "../../service/api/dm-document-storage.service";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {DmDocumentTypeService} from "../../service/api/dm-document-type.service";
import {FilterCriteriaCollection} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {
  DtoListAttributeRequestAggregateEnum
} from "@kvers/alpha-core-common";
import {MvsApiServiceUploadInterface} from "@kvers/alpha-core-common";
import {DmDocumentService} from "../../service/api/dm-document.service";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {DmDashboardDto} from "../../dto/dm-dashboard.dto";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {ObserverService} from "@kvers/alpha-core-common";


@Component({
  selector: 'mvs-dm-dashboard-page',
  templateUrl: './dm-dashboard.component.html',
  styleUrls: ['./dm-dashboard.component.scss']
})
export class DmDashboardComponent extends ObjectBaseComponent {

  dto: DmDashboardDto;

  defaultDocumentTypeAlias: string = "customer.documents.notassigned";
  defaultStorageAlias: string = "azure.customer.docs";

  uploadInterface: MvsApiServiceUploadInterface;
  uploadDocumentType: number;

  documentService: DmDocumentService;

  defaultStorageDto: DtoDetail;
  storageService: DmDocumentStorageService;

  defaultDocumentTypeDto: DtoDetail;
  documentTypeService: DmDocumentTypeService;

  widgetUnassignedDocuments: WidgetData;
  widgetUnassignedDocumentsFilter: FilterCriteriaCollection;

  widgetDocumentStatus: WidgetData;
  widgetDocumentTypeHistory: WidgetData;

  showDocument: boolean;
  showDocumentObjectIdentifier: ObjectIdentifier;
  documentStatusFilter: FilterCriteria[]


  constructor(
      protected coreService: MvsCoreService,
      protected messageService: MvsMessageService,
      protected navigationService: MvsObjectNavigationService,
      protected confirmationService: ConfirmationService,
      protected override observerService: ObserverService) {
    super(coreService, messageService, confirmationService, observerService);

  }


  onDocumentUploaded(file: File) {
    this.refreshComponent();

  }

  ngOnInit(): void {
    debugger
    super.ngOnInit();

    this.storageService = <DmDocumentStorageService>this.coreService.getCrudService("dm.DmDocumentStorage");
    this.documentTypeService = <DmDocumentTypeService>this.coreService.getCrudService("dm.DmDocumentType");
    this.documentService = <DmDocumentService>this.coreService.getCrudService("dm.DmDocument");

    // retrieve default storage and retrieve default document type

    /*
    forkJoin({
      storageType: this.storageService.getByAttribute("alias", this.defaultStorageAlias),
      documentType: this.documentTypeService.getByAttribute("alias", this.defaultDocumentTypeAlias)
    })
        .subscribe(({storageType, documentType}) => {
          this.defaultStorageDto = storageType;
          this.defaultDocumentTypeDto = documentType;


          // store upload interface
          this.uploadInterface = this.documentService;
          this.uploadDocumentType = this.defaultDocumentTypeDto.id;

          if (this.defaultStorageDto) {
            this.initialized = true;
            this.refreshComponent();
          } else {
            this.messageService.showErrorMessage("Storage Missing", "Default StorageType " + this.defaultStorageAlias + " konnte nicht gefunden werden!");
          }
        });

     */


  }

  onObjectChanged() {

    // Dashboard changed, so we need to reload specific objects

    this.refreshWidgets();

  }

  refreshWidgets() {

    if (!this.initialized) {
      return;
    }

    this.refreshWidgetNotAssigned();
    this.refreshWidgetStatuses();
    this.refreshWidgetDocumentTypeHistory();

  }

  onProcessDocuments(objectIdentifier: ObjectIdentifier) {
    const url = '/dm/document-process/' + objectIdentifier.objectId;
    let status = null;
    if (this.documentStatusFilter && this.documentStatusFilter.length) {
      status = this.documentStatusFilter[0].value;
    }
    const queryParams = `?dmDocumentType=${this.dto.mainDocumentTypeDtoId}&status=${status}`;
    this.navigationService.handlePageNavigation(url, null, { openNewTab: true, queryParam: queryParams});
  }

  onDocumentSelect(objectIdentifier: ObjectIdentifierData) {
    // this.showDocument = true;
    // this.showDocumentObjectIdentifier = objectIdentifier;

    if (!this.documentStatusFilter) {
      this.documentStatusFilter = [];
    }

    const label = objectIdentifier.data.label
    const exists = this.documentStatusFilter.find(item => item.label == label);
    if (exists) {
      return;
    } else {
      const filterCriteria = FilterCriteria.create('status', FilterCriteria.cOperatorEqual, objectIdentifier.data.key);
      filterCriteria.label = label;
      this.documentStatusFilter.push(filterCriteria);
    }

    this.refreshWidgetNotAssigned();


  }

  onRemoveFilterCriteria(filter: FilterCriteria) {
    const index = this.documentStatusFilter.findIndex(item => item.label == filter.label);
    if (index > -1) {
      this.documentStatusFilter.splice(index,1);
    }
    this.refreshWidgetNotAssigned();
  }

  onDocumentsSelected(objectIdentifiers: ObjectIdentifierData[]) {

    let selectedIds: string;

    for (let objectIdentifier of objectIdentifiers) {
      selectedIds+=objectIdentifier.objectId+",";
    }

    // store selected objects within component for further processing


  }

  getWidgetAlias(widget: string) : string {
    return "dm.dashboard." + this.dto.id + "." + widget;

  }

  /**
   * Retrieve document type filter, based on the main Document Type.
   */
  getDocumentFilter() :FilterCriteriaCollection {

    return FilterCriteriaCollection.createFromFilterCriteria(
        [ FilterCriteria.create("dmDocumentType", FilterCriteria.cOperatorEqual, this.dto.mainDocumentTypeDtoId, null) ]);
  }

  refreshWidgetNotAssigned() {

    this.widgetUnassignedDocuments = null;

    // get main Filter
    const documentTypeFilter = this.getDocumentFilter();

    if (this.documentStatusFilter && this.documentStatusFilter.length) {
      for (let item of this.documentStatusFilter) {
        documentTypeFilter.filterCriteria.push(item);
      }
    }
    // documentTypeFilter.filterCriteria.push(FilterCriteria.create('status', FilterCriteria.cOperatorEqual, 0))

    this.widgetUnassignedDocuments = new WidgetData();
    this.widgetUnassignedDocuments.idAlias = this.getWidgetAlias("table");
    this.widgetUnassignedDocuments.name = this.dto.mainDocumentTypeDtoName;
    this.widgetUnassignedDocuments.uiComponent = "table";
    this.widgetUnassignedDocuments.dataProvider = "list";
    this.widgetUnassignedDocuments.dataSource = "entity";
    this.widgetUnassignedDocuments.dataProviderObject = "dm.DmDocument";
    this.widgetUnassignedDocuments.dataProviderListRequest = ObjectRequestList.createWithPaging(
        true,
        documentTypeFilter.filterCriteria,
        [new Sorting("createdDate", false)],
        PagingDto.create(0, 20)
    );
    // this.widgetUnassignedDocuments.setParamValue("selectionMode", "single");
    this.widgetUnassignedDocuments.setParamValue("selectionMode", "multiAndSingle");

    this.widgetUnassignedDocuments.setParamValue("size", "S");

  }



  refreshWidgetStatuses() {

    this.widgetDocumentStatus = new WidgetData();
    this.widgetDocumentStatus.idAlias = this.getWidgetAlias("status");
    this.widgetDocumentStatus.name = "Status";
    this.widgetDocumentStatus.uiComponent = "category";
    this.widgetDocumentStatus.dataProvider = "list";
    this.widgetDocumentStatus.dataSource = "entity.groupBy";
    this.widgetDocumentStatus.dataProviderObject = "dm.DmDocument";
    this.widgetDocumentStatus.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        this.getDocumentFilter().filterCriteria,
        [],
        ["status"],
        [ new ObjectRequestListAttribute("status", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetDocumentStatus.setParamValue("fieldCategory", "status");
    this.widgetDocumentStatus.setParamValue("fieldCategoryCount", "status_count");
    this.widgetDocumentStatus.setParamValue("selectionMode", "single");
    this.widgetDocumentStatus.setParamValue("size", "S");

  }

  refreshWidgetDocumentTypeHistory() {

    this.widgetDocumentTypeHistory = new WidgetData();
    this.widgetDocumentTypeHistory.id = 1;
    this.widgetDocumentTypeHistory.idAlias = this.getWidgetAlias("history");
    this.widgetDocumentTypeHistory.name = "Anlage Dokumenttypen";
    this.widgetDocumentTypeHistory.uiComponent = "history.chart";
    this.widgetDocumentTypeHistory.dataProvider = "list";
    this.widgetDocumentTypeHistory.dataSource = "entity.groupBy";
    this.widgetDocumentTypeHistory.dataProviderObject = "dm.DmDocument";
    this.widgetDocumentTypeHistory.dataProviderListRequest = ObjectRequestListGroupBy.create(
        true,
        this.getDocumentFilter().filterCriteria,
        [],
        ["dmDocumentType", "createdDate"],
        [ new ObjectRequestListAttribute("dmDocumentType", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]);

    this.widgetDocumentTypeHistory.setParamValue("fieldCategory", "dmDocumentType");
    this.widgetDocumentTypeHistory.setParamValue("fieldCategoryCount", "dmDocumentType_count");
    this.widgetDocumentTypeHistory.setParamValue("historyPeriodMode", "week");
    this.widgetDocumentTypeHistory.setParamValue("fieldCategory", "dmDocumentType");
    this.widgetDocumentTypeHistory.setParamValue("fieldDate", "createdDate");
    this.widgetDocumentTypeHistory.setParamValue("selectionMode", "single");
    this.widgetDocumentTypeHistory.setParamValue("size", "S");

  }


  // widgetDocumentTypeHistory



}
