<div class="surface-0">
    @if (currentView != 'show-ticket' || (currentView != 'show-ticket' || subTicketActive)) {
        <div *ngIf="initialized && objectLoaded"
             class="flex justify-content-between align-items-center py-3 sticky z-1 surface-0 border-bottom-1 border-300"
             style="top: -10px">

            <div class="flex gap-3 mx-2 align-items-center">

                @if (currentView != 'overview' && currentView != 'attachment' && currentView != 'comments' && currentView != 'notification' || (subTicketActive)) {
<!--                    currentView == 'show-process' || currentView == 'show-ticket' || currentView == 'show-notification-sent' || currentView == 'show-notification-manual' || (currentView == 'overview' && subTicketActive)-->
                    <i class="fa-regular fa-arrow-left cursor" (click)="navigateToMainPage()"></i>
                    <i *ngIf="subTicketActive && mainTicketInfo?.id == this.activeTicketMainObject?.objectId" class="fa-solid fa-ticket bg-green-100 text-green-800 border-round p-2"></i>
                }



                @if (subTicketActive) {
                    <h5 class="font-medium m-0 flex gap-1 align-items-center"> {{mainTicketInfo.id}}
                        <span class="text-color-secondary text-sm">
                            ({{mainTicketInfo.alias}})
                        </span>
                        <div class="flex gap-1 align-items-center">
                            <i class="fa fa-chevron-right text-500 text-sm mx-1"></i>
                            {{ dto.id }}
                            <span class="text-color-secondary text-sm">({{ dto.alias }})</span>
                        </div>
                    </h5>
                } @else {
                    <h5 class="font-medium m-0 flex gap-1 align-items-center">{{ dto.id }}
                        <span class="text-color-secondary text-sm">({{ dto.alias }})</span>
                    </h5>
                }



<!--                <p-button-->
<!--                        *ngIf="dto.status != TmStatusEnum.resolved"-->
<!--                        styleClass="h-2rem surface-200 text-color-secondary"-->
<!--                        [label]="currentView == 'completeTicket' ? dto.alias : 'Ticket abschließen'"-->
<!--                        [icon]="currentView == 'completeTicket' ? 'pi pi-chevron-left' : 'pi pi-check-circle'"-->
<!--                        (click)="completeTicket()">-->
<!--                </p-button>-->

                <p-splitButton
                        styleClass="h-2rem surface-200 text-color-secondary"
                        [icon]="currentView == 'completeTicket' ? 'pi pi-chevron-left' : 'pi pi-check-circle'"
                        [label]="currentView == 'completeTicket' ? dto.alias : 'Ticket abschließen'"
                        [model]="splitButtonItems"
                        (onClick)="completeTicket()">

                </p-splitButton>

                <!--            <p-button-->
                <!--                    *ngIf="currentView=='completeTicket'"-->
                <!--                    styleClass="h-2rem surface-200 text-color-secondary"-->
                <!--                    [label]="dto.alias"-->
                <!--                    icon="pi pi-chevron-left"-->
                <!--                    (click)="currentView='overview'"-->
                <!--            ></p-button>-->

                <div>
                    <mvs-user-object-access-output-component [objectType]="objectIdentifier.objectType"
                                                             [objectId]="objectIdentifier.objectId">
                    </mvs-user-object-access-output-component>
                </div>
            </div>

            <div class="flex gap-2 align-items-center">
                <ng-container *ngFor="let button of widgetButtons">
                    <ng-container *ngIf="button.type != 'divider'">
                        <div class="flex align-items-center cursor gap-2 overflow-visible" pRipple
                             [pTooltip]="button.tooltip"
                             tooltipPosition="bottom"
                             #tooltipRefs
                             (click)="handleToolbarButtonClick(button)">
                            <!--                    {{button.action}}-->
                            <!-- -->

                            <ng-container *ngIf="button.type == 'badge'">
                                <i class="{{button.icon}} p-2 mr-2"
                                   pBadge [value]="button.badge"
                                   [ngClass]="(currentView == 'show-process' && (visibleSections.includes(button.action) )) || currentView == button.action ? 'bg-primary-100 text-primary p-2 border-round-sm line-height-2' : ''"
                                   *ngIf="button.display">
                                </i>
                            </ng-container>

                            <ng-container *ngIf="button.type != 'badge'">
                                <i class="{{button.icon}} p-2"
                                   [ngClass]="(currentView == 'show-process' && (visibleSections.includes(button.action) )) || currentView == button.action ? 'bg-primary-100 text-primary p-2 border-round-sm line-height-2' : ''"
                                   *ngIf="button.display">
                                </i>
                            </ng-container>

                            <span class="" *ngIf="button.label">{{ button.label }}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="button.type == 'divider'">
                        <p-divider styleClass="border-1 border-300 m-0 p-1 px-0" layout="vertical"></p-divider>
                    </ng-container>

                </ng-container>
            </div>


        </div>
    }

    <div class="p-2 w-full" [class.mt-3]="currentView == 'show-process'" id="rootElement">
        <ng-container *ngIf="!objectLoaded">
            <p-skeleton width="100%" height="500px"></p-skeleton>
        </ng-container>
        <!--loadTicket-->
        <ng-container *ngIf="initialized && objectLoaded">




            <div class="grid h-full">
                <div class="side-nav-mr w-full">

                    <!-- Landing Page -->
                    <ng-container *ngIf="currentView == 'overview'">

                        <div class="flex flex-column gap-3 pt-2">

                            <div [class.grid]="fullPage">
                                <div [ngClass]="fullPage ? 'col-7' : ''">

                                    <!-- Error/ Warning active ticket  -->
                                    <ng-container *ngIf="!subTicketActive">

                                        @if (activeTicketMainObject && activeTicketMainObject.objectId != this.objectIdentifier.objectId) {
                                            <p-messages severity="error">
                                                <ng-template pTemplate>
                                                    <div class="w-full flex justify-content-between align-items-center">
                                                        <div class="flex gap-3 align-items-center">
                                                            <i class="fa-regular fa-triangle-exclamation text-2xl"></i>
                                                            <div class="flex flex-column gap-1">
<!--                                                                <div class="font-medium text-lg">Achtung</div>-->
                                                                <span>Du hast aktuell ein anderes Ticket aktiviert.</span>
                                                            </div>
                                                        </div>
                                                        <p-button styleClass="p-button-text p-0"
                                                                  label="Aktiviere Ticket"
                                                                  icon="fa-regular fa-ticket"
                                                                  (click)="activeTicketObject()"
                                                        >
                                                        </p-button>
                                                    </div>
                                                </ng-template>
                                            </p-messages>
                                        }


                                        @if (!activeTicketMainObject || !activeTicketMainObject.objectId) {
                                            <p-messages severity="warn">
                                                <ng-template pTemplate>
                                                    <div class="w-full flex justify-content-between align-items-center">
                                                        <div class="flex gap-3 align-items-center">
                                                            <i class="fa-regular fa-check text-2xl"></i>
                                                            <div class="flex flex-column gap-1">
<!--                                                                <div class="font-medium text-lg">Achtung</div>-->
                                                                <span>Denke bitte daran vor jeglicher Änderung dieses Ticket zu aktivieren.</span>
                                                            </div>
                                                        </div>
                                                        <p-button styleClass="p-button-text p-0"
                                                                  label="Aktiviere Ticket"
                                                                  icon="fa-regular fa-ticket"
                                                                  (click)="activeTicketObject()"
                                                        >
                                                        </p-button>
                                                    </div>
                                                </ng-template>
                                            </p-messages>
                                        }


                                        @if (activeTicketMainObject && activeTicketMainObject.objectId == this.objectIdentifier.objectId) {
                                            <p-messages severity="success">
                                                <ng-template pTemplate>
                                                    <div class="w-full flex justify-content-between align-items-center">
                                                        <div class="flex gap-3 align-items-center">
                                                            <i class="fa-regular fa-check text-2xl"></i>
                                                            <div class="flex flex-column gap-1">
                                                                <span>Du arbeitest in deinem aktiven Ticket.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-messages>
                                        }



                                    </ng-container>



                                    <div class="mvs-widget px-2 py-0 shadow-none">
                                        <mvs-widget [widgetData]="ticketFormWidgetData"
                                                    *ngIf="ticketFormWidgetData"
                                                    (onFormDirty)="handleComponentChange()">
                                        </mvs-widget>
                                    </div>

                                    <ng-container *ngIf="suggestedTicketObjectLabel">
                                        <p-button [label]="suggestedTicketObjectLabel"
                                                  [icon]="busy ? 'pi pi-spin pi-spinner' : 'pi pi-plus'"
                                                  (click)="handleAddSuggestedObject()"
                                        >

                                        </p-button>
                                    </ng-container>

                                    <tm-ticket-landing-components
                                            class="mt-3 block"
                                            [objectIdentifier]="objectIdentifier"
                                            [ticketTypeId]="dto?.typeDtoId"
                                            [importObjectContext]="importObjectContext"
                                            [ticketObjects]="ticketObjects"
                                            (onObjectChanged)="onObjectChanged()"
                                            [fullPage]="fullPage"
                                    ></tm-ticket-landing-components>

                                    <tm-ticket-actions
                                            class="mt-3 block"
                                            [ticketId]="dto.id"
                                            *ngIf="availableActionsResponse"
                                            [ticketActionsList]="ticketActions"
                                            [processMode]="false"
                                            [ticketBusyState]="busy"
                                            [availableActionsResponse]="availableActionsResponse"
                                            [ticketForm]=""
                                            (onAddTicketAction)="handleAddTicketAction($event)"
                                            (onInitiateProcess)="handleInitiateProcess($event)"
                                            (onActionStatusChange)="handleActionChange($event)">
                                    </tm-ticket-actions>
                                </div>
                                <div class="col-" *ngIf="fullPage">
                                    <p-divider layout="vertical"></p-divider>
                                </div>
                                <div [ngClass]="fullPage ? 'col-4' : ''">
                                    <tm-ticket-comment-history
                                            [objectIdentifier]="objectIdentifier"
                                            [documentTypeId]="dto?.typeDto?.dmDocumentTypeDtoId"
                                            [ticketTypeDto]="dtoTicketType"
                                            (onCommentChange)="handleComponentChange($event)"
                                    ></tm-ticket-comment-history>

                                </div>

                            </div>
                        </div>

                    </ng-container>


                    <!-- Attachments -->
                    <ng-container *ngIf="currentView == 'attachment'">
                        <div class="p-3">
                            <tm-ticket-attachment
                                    [objectIdentifier]="objectIdentifier"
                                    [ticketDto]="dto"
                                    (onDocumentUploaded)="getAttachmentLength()"
                            ></tm-ticket-attachment>

                            <tm-ticket-comment-history
                                    [objectIdentifier]="objectIdentifier"
                                    [documentTypeId]="dto?.typeDto?.dmDocumentTypeDtoId"
                                    [ticketTypeDto]="dtoTicketType"
                                    (onCommentChange)="handleComponentChange($event)"
                            ></tm-ticket-comment-history>
                        </div>
                    </ng-container>

                    <!-- Comments -->
                    <ng-container *ngIf="currentView == 'comments'">

                        <mvs-context-history *ngIf="objectIdentifier"
                                             [objectTypeAlias]="objectIdentifier.objectType"
                                             [objectId]="objectIdentifier.objectId"
                                             [columns]="1">

                        </mvs-context-history>

                        <tm-ticket-comment-history
                                [objectIdentifier]="objectIdentifier"
                                [documentTypeId]="dto?.typeDto?.dmDocumentTypeDtoId"
                                [objectTypeId]="objectIdentifier.objectId"
                                [ticketTypeDto]="dtoTicketType"
                                (onCommentChange)="handleComponentChange($event)"
                        ></tm-ticket-comment-history>
                    </ng-container>

                    <!-- Workflow Process -->
                    <ng-container
                            *ngIf="currentView == 'show-process' || currentView == 'show-ticket' ||
                                   currentView == 'show-notification-sent' || currentView == 'show-notification-manual'">
<!--                        *ngIf="currentView != 'show-ticket'"-->
<!--                        <p-breadcrumb [model]="breadcrumbsItems"-->

<!--                                      class="cursor-pointer"-->
<!--                                      styleClass="layout-breadcrumb pl-4 py-2 mb-3 mt-2 surface-50 cursor-pointer"-->
<!--                                      (onItemClick)="handleBreadcrumbClick($event)">-->
<!--                        </p-breadcrumb>-->

                        <div [class.grid]="fullPage">
                            <div [ngClass]="fullPage ? 'col-4' : ''">

                                <div *ngFor="let section of visibleSections">

                                    <ng-container *ngIf="section === 'overview'">
                                        <p-accordion [activeIndex]="0" styleClass="mb-3">
                                            <p-accordionTab header="Ticket Overview"
                                                            tabStyleClass="shadow-none border-1 border-300 border-round-xs">
                                                <mvs-widget [widgetData]="ticketFormWidgetData"
                                                            *ngIf="ticketFormWidgetData">
                                                </mvs-widget>
                                            </p-accordionTab>
                                        </p-accordion>
                                    </ng-container>

                                    <ng-container *ngIf="section === 'attachment'">
                                        <p-accordion [activeIndex]="0" styleClass="mb-3">
                                            <p-accordionTab header="Ticket Attachments"
                                                            tabStyleClass="shadow-none border-1 border-300 border-round-xs">
                                                <tm-ticket-attachment
                                                        [objectIdentifier]="objectIdentifier"
                                                        [processMode]="true"
                                                        [ticketDto]="dto"
                                                        (onDocumentUploaded)="getAttachmentLength()"
                                                ></tm-ticket-attachment>
                                            </p-accordionTab>
                                        </p-accordion>
                                    </ng-container>

                                    <ng-container *ngIf="section === 'comments'">
                                        <p-accordion [activeIndex]="0" styleClass="mb-3">
                                            <p-accordionTab header="Ticket Activity"
                                                            tabStyleClass="shadow-none border-1 border-300 border-round-xs">
                                                <tm-ticket-comment-history
                                                        [objectIdentifier]="objectIdentifier"
                                                        [documentTypeId]="dto?.typeDto?.dmDocumentTypeDtoId"
                                                        [processMode]="true"
                                                        [ticketTypeDto]="dtoTicketType"
                                                        (onCommentChange)="handleComponentChange($event)"
                                                ></tm-ticket-comment-history>
                                            </p-accordionTab>
                                        </p-accordion>
                                    </ng-container>

                                </div>

<!--                                <p-accordion styleClass="mb-3">-->
<!--                                    <p-accordionTab header="Ticket Actions"-->
<!--                                                    tabStyleClass="shadow-none border-1 border-300 border-round-xs">-->
<!--                                        <tm-ticket-actions-->
<!--                                                *ngIf="ticketActions && ticketActions.length" [ticketId]="dto.id"-->
<!--                                                [ticketActionsList]="ticketActions"-->
<!--                                                [availableActionsResponse]="availableActionsResponse"-->
<!--                                                [processMode]="true"-->
<!--                                                (onAddTicketAction)="handleAddTicketAction($event)"-->
<!--                                                (onInitiateProcess)="handleInitiateProcess($event)">-->
<!--                                        </tm-ticket-actions>-->
<!--                                    </p-accordionTab>-->
<!--                                </p-accordion>-->
                            </div>

                            <div class="col-" *ngIf="fullPage">
                                <p-divider layout="vertical"></p-divider>
                            </div>

                            <div [ngClass]="fullPage ? 'col-7' : ''">


                                <ng-container *ngIf="currentView == 'show-process'">
                                    <tm-ticket-sub-navigation-tasks
                                            [objectIdentifier]="processIdentifier"
                                            [mode]="currentView"
                                            (changeMode)="currentView = $event"
                                            [processMode]="true"
                                            [fullPage]="fullPage"
                                    ></tm-ticket-sub-navigation-tasks>
                                </ng-container>



                                <ng-container *ngIf="currentView == 'show-ticket'">

                                    <mvs-object
                                            *ngIf="ticketIdentifier && ticketIdentifier.objectId"
                                            [objectType]="ticketIdentifier.objectType"
                                            [objectId]="ticketIdentifier.objectId"
                                            (onBackNavigation)="navigateToMainPage()"
                                    ></mvs-object>

                                    @if (ticketIdentifier && !ticketIdentifier.objectId) {

                                        <p-breadcrumb [model]="breadcrumbsItems"
                                                      class="cursor-pointer"
                                                      styleClass="layout-breadcrumb pl-4 py-2 mb-3 mt-2 surface-50 cursor-pointer"
                                                      (onItemClick)="handleBreadcrumbClick($event)">
                                        </p-breadcrumb>

                                        <mvs-crud-object
                                                [objectType]="ticketIdentifier.objectType"
                                                [objectId]="0"
                                                [formControlOverwrite]="ticketFormControlOverwrite"
                                                [defaultCreateDto]="ticketDefaultDto"
                                                [importObjectContextDto]="ticketImportObjectContext"
                                                (onChangedObject)="handleObjectChangeManualTicket($event)">
                                        </mvs-crud-object>

                                    }

                                </ng-container>

                                <ng-container *ngIf="currentView == 'show-notification-sent'">
                                    <mvs-object
                                            *ngIf="notificationIdentifier"
                                            [objectType]="notificationIdentifier.objectType"
                                            [objectId]="notificationIdentifier.objectId"
                                    ></mvs-object>
                                </ng-container>

                                <ng-container *ngIf="currentView == 'show-notification-manual'">
                                    <mvs-ns-notification-send
                                            [mode]="1"
                                            [objectIdentifier]="notificationIdentifier"
                                            [notificationSourceTypeId]="notificationSourceTypeId"></mvs-ns-notification-send>
                                </ng-container>


                            </div>
                        </div>
                    </ng-container>

                    <!-- Create Ticket Actions -->
                    <div *ngIf="currentView == 'create-ticket-action'">
                        <create-tm-ticket-actions [actionType]="modeType"
                                                  [ticketId]="dto.id"
                                                  [availableActionsResponse]="availableActionsResponse"
                                                  (changeMode)="handleCreateTicketAction($event)">
                        </create-tm-ticket-actions>
                    </div>

                    <ng-container *ngIf="currentView=='completeTicket'">
                        <ticket-complete-form
                                [ticketId]="objectIdentifier.objectId"
                                (onAddTicketAction)="handleAddTicketAction($event)"
                                (onInitiateProcess)="handleInitiateProcess($event)"
                                (onChangedObject)="handleChangeObject($event)"
                        ></ticket-complete-form>
                    </ng-container>

                    <ng-container *ngIf="currentView == 'notification'">

                        <mvs-ns-notification-types-for-object
                                [objectIdentifier]="objectIdentifier"
                                [mode]="1"
                                class="mvs-widget block"
                        ></mvs-ns-notification-types-for-object>

                    </ng-container>


                </div>

            </div>

        </ng-container>
    </div>
</div>