import {Component, EventEmitter, Input, OnChanges, OnInit, Output,} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    ObjectChangeInformation,
    ObjectIdentifier,
    ObjectRequestComplex,
    ObjectRequestComplexNode, ObjectRequestComplexRelationBindingEnum,
    ObjectRequestList, ObjectRequestRelation,
} from "@kvers/alpha-core-common";
import {TmStatusEnum} from "../../enum/tm-status.enum";
import {TicketActionAvailableResponseDto} from "../../service/api/dto/ticket-action-available-response.dto";
import {TicketActionDto} from "../../dto/ticket-action.dto";
import {TicketActionStatusInternal} from "../../enum/ticket-action-status-internal.enum";
import {TicketActionAvailableDto} from "../../service/api/dto/ticket-action-available.dto";
import {UiTicketActionDto} from "../ticket/ticket-components/tm-ticket-actions/data/ui-ticket-action.dto";
import {TicketService} from "../../service/api/ticket.service";
import {TicketActionService} from "../../service/api/ticket-action.service";


@Component({
    selector: 'ticket-complete-form',
    templateUrl: 'ticket-complete-form.component.html',
})
export class TicketCompleteFormComponent implements OnInit, OnChanges {

    initialized: boolean;
    createDefaultDto: DtoDetail;
    formControlOverwrite: MvsFormControlOverwrite;
    actionsList: TicketActionDto[]
    actionAvailableDto: TicketActionAvailableDto[];

    @Input() ticketId: number;
    ticketActionsList: TicketActionDto[];
    availableActionsResponse: TicketActionAvailableResponseDto;

    @Output() onAddTicketAction = new EventEmitter<string>;
    @Output() onInitiateProcess = new EventEmitter<ObjectIdentifier>;
    @Output() onChangedObject = new EventEmitter<ObjectChangeInformation>;

    constructor(protected ticketService: TicketService, protected ticketActionService: TicketActionService) {
    }

    ngOnInit() {
        this.initComponent();
    }

    initComponent() {
        this.formControlOverwrite = MvsFormControlOverwrite.createHideAll('closingStatusDtoId', 'closingComment', 'status');
        this.formControlOverwrite.addField('status', MvsFormFieldAccessEnum.READ);

        const defaultDto: any = {
            status: TmStatusEnum.resolved,
            closingDate: new Date(),
        }

        this.createDefaultDto = defaultDto;

        this.getAvailableTicketActions();
        this.getTicketActionList();

        this.initialized = true;
    }

    getAvailableTicketActions() {
        this.ticketService.getAvailableActions(this.ticketId).subscribe(res => {
            this.availableActionsResponse = res;
            this.actionAvailableDto = this.availableActionsResponse?.availableActions.filter(action => (action.existsStatusInternal != TicketActionStatusInternal.completed && action.existsStatusInternal != TicketActionStatusInternal.cancelled));
            this.availableActionsResponse.availableActions = this.actionAvailableDto;
        })
    }

    getTicketActionList() {

        const complexSelection =
            ObjectRequestComplex.build(false, false,
                ObjectRequestComplexNode.createSimpleAttributeNode('ticketTypeAction')
            );


        const filterCriteria = [
            FilterCriteria.create('ticket', FilterCriteria.cOperatorEqual, this.ticketId),
            FilterCriteria.create('statusInternal', FilterCriteria.cOperatorNotEqual, TicketActionStatusInternal.completed),
            FilterCriteria.create('statusInternal', FilterCriteria.cOperatorNotEqual, TicketActionStatusInternal.cancelled),
        ]

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            false,
            complexSelection,
            filterCriteria,
            null,
            null,
            null);

        this.ticketActionService.list(objectRequestList).subscribe(res => {
            const ticketActions = res.entries;

            if (ticketActions && ticketActions.length) {
                this.ticketActionsList = ticketActions.filter(item => item.mandatory == true);
            }

            // this.actionsList = this.ticketActionsList?.filter(action => action.statusInternal != TicketActionStatusInternal.completed);
            // this.actionAvailableDto = this.availableActionsResponse?.availableActions.filter(action => action.existsStatusInternal != TicketActionStatusInternal.completed);
            // this.availableActionsResponse.availableActions = this.actionAvailableDto;
        })
    }

    handleAddTicketAction(event: string) {
        this.onAddTicketAction.emit(event);
    }

    handleInitiateProcess(event: ObjectIdentifier) {
        this.onInitiateProcess.emit(event);
    }

    handleObjectChange(event: ObjectChangeInformation) {
        this.onChangedObject.emit(event);
    }

    handleStatusChange(action: TicketActionDto) {
        this.getTicketActionList();
        // this.ticketActionsList = this.ticketActionsList.filter(actions => actions.statusInternal != action.statusInternal);
    }

    ngOnChanges() {
        if (!this.initialized) {
            return
        }

        this.getAvailableTicketActions()
    }

}
