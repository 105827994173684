@if (initialized) {
    <div class="bg-white mt-5">
        <mvs-config-header [header]="getHeaderName()" [categoryTypeMode]="'ticket'"
                           icon="fa-light fa-cards-blank">
        </mvs-config-header>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionPeriod'">
            <div class="grid">
                <div class="col-12">
                    <mvs-widget
                            *ngIf="provisionPeriodWidget"
                            [widgetData]="provisionPeriodWidget"
                    ></mvs-widget>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionAgent'">
            <p-tabView>

                <p-tabPanel header="Agent">
                    <div class="grid">
                        <div class="col-4">

                            <mvs-widget
                                    *ngIf="provisionAgentWidget"
                                    [widgetData]="provisionAgentWidget"
                                    (onObjectSelect)="handleObjectSelect($event)">
                            </mvs-widget>

                        </div>

                        <div class="col-8">

                            <mvs-widget
                                    *ngIf="provisionAgentGroupWidget"
                                    [widgetData]="provisionAgentGroupWidget">
                            </mvs-widget>


                            <mvs-widget
                                    *ngIf="provisionAgentCommentWidget"
                                    [widgetData]="provisionAgentCommentWidget">
                            </mvs-widget>
                        </div>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="Agent Pool">
                    <div class="grid">
                        <div class="col-4">

                            <mvs-widget
                                    *ngIf="provisionAgentPoolWidget"
                                    [widgetData]="provisionAgentPoolWidget"
                                    (onObjectSelect)="handleObjectSelect($event)">>
                            </mvs-widget>

                        </div>

                        <div class="col-8">

                            <mvs-widget
                                    *ngIf="provisionAgentPoolGroupWidget"
                                    [widgetData]="provisionAgentPoolGroupWidget">
                            </mvs-widget>

                        </div>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="Agent Pool Agent">
                    <mvs-widget
                            *ngIf="provisionAgentPoolAgentWidget"
                            [widgetData]="provisionAgentPoolAgentWidget">
                    </mvs-widget>
                </p-tabPanel>

            </p-tabView>
        </ng-container>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionCategoryType'">

            <div class="grid">
                <div class="col-4">

                    <mvs-widget
                            *ngIf="provisionCategoryTypeWidget"
                            [widgetData]="provisionCategoryTypeWidget"
                            (onObjectSelect)="handleObjectSelect($event)">>
                    </mvs-widget>

                </div>

                <div class="col-8">

                    <mvs-widget
                            *ngIf="provisionCategoryTypeAssignmentWidget"
                            [widgetData]="provisionCategoryTypeAssignmentWidget">
                    </mvs-widget>

                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionGroupType'">

            <div class="grid">
                <div class="col-4">

                    <mvs-widget
                            *ngIf="provisionGroupTypeWidget"
                            [widgetData]="provisionGroupTypeWidget"
                            (onObjectSelect)="handleObjectSelect($event)">>
                    </mvs-widget>

                </div>

                <div class="col-8">

                    <mvs-widget
                            *ngIf="provisionGroupTypeAssignmentWidget"
                            [widgetData]="provisionGroupTypeAssignmentWidget">
                    </mvs-widget>

                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionContractEligibilityType'">

            <p-tabView>

                <p-tabPanel header="Contract Eligibility Type">

                    <mvs-widget
                            *ngIf="provisionContractEligibilityTypeWidget"
                            [widgetData]="provisionContractEligibilityTypeWidget">
                    </mvs-widget>

                </p-tabPanel>

                <p-tabPanel header="Contract Change Eligibility Type">

                    <mvs-widget
                            *ngIf="provisionContractChangeEligibilityTypeWidget"
                            [widgetData]="provisionContractChangeEligibilityTypeWidget">
                    </mvs-widget>

                </p-tabPanel>

                <p-tabPanel header="Contract Eligibility">

                    <div class="grid">
                        <div class="col-4">

                            <mvs-widget
                                    *ngIf="provisionContractEligibilityWidget"
                                    [widgetData]="provisionContractEligibilityWidget"
                                    (onObjectSelect)="handleObjectSelect($event)">
                            </mvs-widget>
                        </div>

                        <div class="col-8">

                            <mvs-widget
                                    *ngIf="provisionContractEligibilityCommentWidget"
                                    [widgetData]="provisionContractEligibilityCommentWidget">
                            </mvs-widget>
                        </div>
                    </div>

                </p-tabPanel>

                <p-tabPanel header="Contract OTP Eligibility">

                    <mvs-widget
                            *ngIf="provisionContractOTPEligibilityWidget"
                            [widgetData]="provisionContractOTPEligibilityWidget">
                    </mvs-widget>

                </p-tabPanel>

                <p-tabPanel header="Contract Price Eligibility">

                    <mvs-widget
                            *ngIf="provisionContractPriceEligibilityWidget"
                            [widgetData]="provisionContractPriceEligibilityWidget">
                    </mvs-widget>

                </p-tabPanel>
            </p-tabView>

        </ng-container>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionCancellationAccountTemplate'">

            <p-tabView>

                <p-tabPanel header="Cancellation Account Template">

                    <mvs-widget
                            *ngIf="provisionCancellationAccountTemplateWidget"
                            [widgetData]="provisionCancellationAccountTemplateWidget">
                    </mvs-widget>

                </p-tabPanel>

                <p-tabPanel header="Liability Template">

                    <mvs-widget
                            *ngIf="provisionLiabilityTemplateWidget"
                            [widgetData]="provisionLiabilityTemplateWidget">
                    </mvs-widget>

                </p-tabPanel>

            </p-tabView>

        </ng-container>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionCurrency'">

            <p-tabView>

                <p-tabPanel header="Currency">

                    <mvs-widget
                            *ngIf="provisionCurrencyWidget"
                            [widgetData]="provisionCurrencyWidget">
                    </mvs-widget>

                </p-tabPanel>

                <p-tabPanel header="Currency Exchange">

                    <mvs-widget
                            *ngIf="provisionCurrencyExchangeWidget"
                            [widgetData]="provisionCurrencyExchangeWidget">
                    </mvs-widget>

                </p-tabPanel>

                <p-tabPanel header="Currency Monetary Value">

                    <mvs-widget
                            *ngIf="provisionCurrencyMonetaryValueWidget"
                            [widgetData]="provisionCurrencyMonetaryValueWidget">
                    </mvs-widget>

                </p-tabPanel>

            </p-tabView>

        </ng-container>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionTemplateType'">

            <mvs-widget
                    *ngIf="provisionTemplateTypeWidget"
                    [widgetData]="provisionTemplateTypeWidget"
                    (onObjectSelect)="navigateTo($event)">
            </mvs-widget>

        </ng-container>

        <ng-container *ngIf="activeNavItem == 'cp.ProvisionType'">

            <mvs-widget
                    *ngIf="provisionTypeWidget"
                    [widgetData]="provisionTypeWidget"
                    (onObjectSelect)="navigateTo($event)">

            </mvs-widget>

        </ng-container>

    </div>
}