import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    DtoList,
    FilterCriteria, MvsCoreService, MvsMessageService,
    MvsObjectService, ObjectBaseComponent, ObjectIdentifierData,
    ObjectRequestList, ObserverService,
    WidgetData
} from "@kvers/alpha-core-common";
import {TicketService} from "../../../../tm/service/api/ticket.service";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {TmTicketDto} from "../../../../tm/dto/tm-ticket.dto";
import {TmStatusEnum} from "../../../../tm/enum/tm-status.enum";
import {MvsObjectCount} from "../../../workflow/component/mvs-workflow/mvs-workflow.component";
import {Subscription} from "rxjs";
import {ConfirmationService} from "primeng/api";
import {CustomerService} from "../../../../cr/service/api/customer.service";
import {WfProcessService} from "../../../../wf/service/api/wf-process.service";


@Component({
    selector: 'mvs-ticket',
    templateUrl: './mvs-ticket.component.html',
})
export class MvsTicketComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    // @Input() objectId: number;
    // @Input() objectAlias: string;
    @Input() includeIndirect = true;
    @Input() visible: boolean = true;
    @Output() onTicketsCount = new EventEmitter<MvsObjectCount>();

    ticketWidget: WidgetData;
    completedCount: number;
    pendingCount: number;
    broadcastSubscription: Subscription;

    initialized: boolean;
    busy: boolean;

    ngOnInit(): void {
        super.ngOnInit();
    }

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected override observerService: ObserverService,
        protected ticketService: TicketService,
        protected objectService: MvsObjectService,
        protected navigationService: MvsObjectNavigationService
    ) {
        super(coreService, messageService, confirmationService, observerService);
    }

    onObjectChanged() {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.subscribeBroadcastChange();
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.ticketWidget = null;
        this.getTickets();
    }

    getTickets() {
        this.ticketService.listTicketObjects(this.objectIdentifier.objectId, this.objectIdentifier.objectType, this.includeIndirect, false).subscribe((res: DtoList<TmTicketDto>) => {
            this.getTicketsCount(res.entries);
            this.refreshTicketWidget(res);
            this.initialized = true;
        });
    }

    subscribeBroadcastChange() {
        this.broadcastSubscription = this.objectService.objectChangeEvent.subscribe(change => {
            this.refreshComponent();
        });
    }

    getTicketsCount(dtoList: TmTicketDto[]) {
        this.completedCount = 0;
        this.pendingCount = 0;

        for (let item of dtoList) {
            if (item.status == TmStatusEnum.resolved || item.status == TmStatusEnum.on_hold || item.status == TmStatusEnum.cancelled) {
                this.completedCount++;
            } else {
                this.pendingCount++;
            }
        }

        const event: MvsObjectCount = {
            completedCount: this.completedCount,
            pendingCount: this.pendingCount
        }

        this.onTicketsCount.emit(event);

    }

    refreshTicketWidget(res: DtoList<TmTicketDto>) {
        // const request = new ObjectRequestList(true, [], []);
        // request.preferredEndpoint = `/extend/listViaObject?objectId=${this.objectIdentifier.objectId}&objectTypeAlias=${this.objectIdentifier.objectType}&includeIndirect=true&includeTemplate=true`;
        // request.preferredMethod = 'GET';

        const request = new ObjectRequestList(
            true,
            [FilterCriteria.create("id", FilterCriteria.cOperatorIn, '${TM_TO-'+this.objectIdentifier.objectType+'-'+this.objectIdentifier.objectId+'-I}')],
            []);


        this.ticketWidget = WidgetFactory.createWidgetList(
            'mvs.tm.ticket.base.basic.123',
            'Tickets',
            'table',
            'list',
            'entity',
            'tm.Ticket',
            'No tickets found!',
            request,
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectAlias"] || changes["objectId"]) {
            this.refreshComponent();
        }
    }

    handleTicketSelect(event: ObjectIdentifierData) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", event.objectId, null, "Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        if (this.broadcastSubscription) {
            this.broadcastSubscription.unsubscribe();
        }

    }


}
