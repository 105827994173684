<ng-container *ngIf="ticketTypeList.length">
    <div class="p-2">
        <p-splitButton label="Actions" icon="far fa-bolt" [model]="menuItem"></p-splitButton>
    </div>

    <p-overlayPanel #op appendTo="body">
        <ng-template pTemplate>
            <p>Comments <span class="text-pink-500"
                              *ngIf="selectedAction.commentSetting == enum.mandatory">*</span>
            </p>
            <textarea [disabled]="busy" pInputTextarea
                      [mention]="userNamesList"
                      [(ngModel)]="actionComment"
                      placeholder="Enter your comment"
                      [rows]="5"
                      [cols]="40" pInputTextarea
                      [autoResize]="true"></textarea>
            <div class="flex justify-content-between">
                <p-button label="Abbrechen" (click)="op.hide()"></p-button>
                <p-button label="Bestätigen"
                          [disabled]="selectedAction.commentSetting == enum.mandatory && !actionComment"
                          (click)="createTicketAction(selectedAction.id)"></p-button>
            </div>
        </ng-template>
    </p-overlayPanel>
</ng-container>