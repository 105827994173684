import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TicketGroupFilterService} from "../../service/api/ticket-group-filter.service";
import {TicketGroupFilterDto} from "../../dto/ticket-group-filter.dto";
import {TicketGroupFilterAgentFavoriteService} from "../../service/api/ticket-group-filter-agent-favorite.service";
import {TicketGroupFilterCountDto} from "../../service/api/dto/ticket-group-filter-count.dto";
import {TicketGroupFilterAgentFavoriteDto} from "../../dto/ticket-group-filter-agent-favorite.dto";
import {
    EntityPriorityHandler,
    FilterCriteria,
    MvsCoreService, MvsObjectService, NavigationItem,
    ObjectIdentifierData, ObjectRequestComplex, ObjectRequestComplexNode,
    ObjectRequestList, ObjectRequestRelation,
    PageComponent, PagingDto,
    WidgetData
} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {TicketGroupFilterInfoDto} from "../../service/api/dto/ticket-group-filter-info.dto";
import {TicketTypeDto} from "../../dto/ticket-type.dto";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {TicketTypeCountDto} from "../../service/api/dto/ticket-type-count.dto";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {forkJoin, Observable, Subscription} from "rxjs";
import {ContextMenu} from "primeng/contextmenu";
import {MenuItem} from "primeng/api";
import {TicketGroupFilterAccess} from "../../enum/ticket-group-filter-access.enum";
import {UiAgentAssignmentEnum} from "./data/ui-agent-assignment.enum";
import {AgentService} from "../../../am/service/api/agent.service";
import {AgentDto} from "../../../am/dto/agent.dto";
import {AgentPoolDto} from "../../../am/dto/agent-pool.dto";
import {AgentPoolService} from "../../../am/service/api/agent-pool.service";
import {DropdownChangeEvent} from "primeng/dropdown";
import {TmStatusEnum} from "../../enum/tm-status-enum.enum";

@Component({
    selector: 'ticket-management',
    templateUrl: './ticket-management.component.html',
    styleUrl: './ticket-management.component.scss'
})
export class TicketManagementComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    groupFilters: TicketGroupFilterDto[];
    favoriteGroupFilters: TicketGroupFilterDto[];
    selectedFilter: TicketGroupFilterDto;
    selectedFilterInfo: TicketGroupFilterInfoDto;
    ticketWidget: WidgetData;
    selectedTicketType: TicketTypeDto;
    showFilterText: boolean = false;
    hideFilters: boolean = false;
    groupContextMenuId: number;
    uiLayoutContextMenuItems: MenuItem[];
    slideMenuItems: MenuItem[];
    manageFavoritesSidebar: boolean = false;
    selectedAssignment: number = UiAgentAssignmentEnum.assignedToMe;
    loggedOnAgent: AgentDto;
    loggedOnAgentPools: AgentPoolDto[];
    selectedTicketTypeCountDto: TicketTypeCountDto;
    overlayTicketTypeCounts: TicketTypeCountDto[];
    objectServiceSubscription: Subscription;
    panelVisible: boolean = false;
    pageTitlePrefix = 'TM - ';
    ticketWidgetSwitch: boolean;
    routeGroupId: number;

    items = [
        { label: 'Ticket Dashboard', type: 'main' }
    ];

    home = { icon: 'pi pi-home', routerLink: '/' };

    assignmentOptions: MenuItem[] = [
        { label: 'Alle', value: UiAgentAssignmentEnum.total },
        { label: 'Meine Tickets', value: UiAgentAssignmentEnum.assignedToMe },
        { label: 'Abteilungstickets (zugewiesen)', value: UiAgentAssignmentEnum.assignedToMyPool },
        { label: 'Abteilungstickets (nicht zugewiesen)', value: UiAgentAssignmentEnum.unassigned }
    ];

    @ViewChild('cmLayout') layoutContextMenu: ContextMenu;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected ticketGroupFilterService: TicketGroupFilterService,
                protected agentPoolService: AgentPoolService,
                protected navigationService: MvsObjectNavigationService,
                protected objectService: MvsObjectService,
                protected agentService: AgentService,
                private router: Router,
                protected ticketGroupFilterAgentFavoriteService: TicketGroupFilterAgentFavoriteService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.routeGroupId = +params['id'];

            if (!this.routeGroupId) {
                this.router.navigate(['tm/me', 'default']);
                return;
            }

            if (typeof this.routeGroupId == 'string') {
                this.routeGroupId = null;
            }

        });

        const selectedAssignment = this.route.snapshot.queryParamMap.get('type');
        if (selectedAssignment !== null) {
            this.selectedAssignment = +selectedAssignment; // Set selectedAssignment if it exists in query params
        } else {
            this.selectedAssignment = UiAgentAssignmentEnum.assignedToMe; // Default to assignedToMe
        }


        super.ngOnInit();
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectServiceSubscription = this.objectService.objectChangeEvent.subscribe(value => {
            if (value.objectType.startsWith('tm.TicketGroupFilter')) {
                this.refreshComponent();
            }
        });
        this.prepareSlideMenuItems();
        this.fetchLoggedOnAgent();
        this.refreshComponent();
    }

    prepareSlideMenuItems() {
        this.slideMenuItems = [
            {
                label: 'Manage Favorites',
                icon: 'fas fa-pencil',
                command: () => {
                    this.handleManageFavorites();
                }
            }
        ];
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getTicketGroupFilters();
    }

    getTicketGroupFilters() {
        this.ticketGroupFilterService.getMeGroups().subscribe(res => {
            this.groupFilters = res.entries;
            this.prepareFavoritesFromGroupFilters();
            this.postGroupRetrievalHandling();
            this.getFilterGroupCount();
        });
    }

    postGroupRetrievalHandling() {
        if (!this.selectedFilterInfo) {
            if (this.routeGroupId) {
                const filter = this.groupFilters.find(item => item.id == this.routeGroupId);

                // retrieve from route
                if (filter) {
                    if (!this.initialized) {
                        this.hideFilters = true;
                    }
                    this.getFilterInfo(filter);
                }

            } else {
                this.defaultFilter();
            }

        } else if (this.selectedFilterInfo && !this.selectedTicketType) {
            this.getFilterInfo(this.selectedFilter);
        }
    }

    defaultFilter() {
        if (!this.favoriteGroupFilters || !this.favoriteGroupFilters.length) {
            this.showFilterText = true;
            return;
        }
        this.getFilterInfo(this.favoriteGroupFilters[0]);
    }

    getFavoriteTicketGroupFilters() {
        this.ticketGroupFilterAgentFavoriteService.getFavorites().subscribe(res => {
            this.prepareFavoritesFromFavoriteFilters(res.entries);
        });
    }

    prepareFavoritesFromGroupFilters() {
        const favorites = this.groupFilters.filter(item => item.favorite == true);
        this.favoriteGroupFilters = favorites;
        this.sortByOrderPriority(this.favoriteGroupFilters);
    }

    prepareFavoritesFromFavoriteFilters(entries: TicketGroupFilterAgentFavoriteDto[]) {
        this.favoriteGroupFilters = [];
        for (let item of entries) {
            const filter = this.groupFilters.find(value => value.id == item.ticketGroupFilterDtoId);

            if (filter) {
                this.favoriteGroupFilters.push(filter);
            }
        }
        this.sortByOrderPriority(this.favoriteGroupFilters);
        if (!this.selectedFilter) {
            this.defaultFilter();
        }
    }

    getFilterGroupCount() {
        const filterIds: number[] = [];


        if (!this.panelVisible) {
            for (let item of this.favoriteGroupFilters) {
                filterIds.push(item.id);
            }
        } else {
            for (let item of this.groupFilters) {
                filterIds.push(item.id);
            }
        }

        if (!filterIds.length) {
            this.initialized = true;
            return;
        }

        this.ticketGroupFilterService.getCount(filterIds).subscribe(res => {
            this.distributeCounts(res);
            this.initialized = true;
        })
    }

    distributeCounts(countResponse: TicketGroupFilterCountDto[]) {
        for (let item of countResponse) {
            const entry = this.groupFilters.find(value => value.id == item.ticketGroupId);
            if (entry) {
                entry.groupFilterCountDto = item;
            }
        }
    }

    addToFavorite(filter: TicketGroupFilterDto) {
        event.stopPropagation();
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.ticketGroupFilterAgentFavoriteService.addFavorite(filter.id).subscribe(res => {
            filter.favorite = true;
            this.getFavoriteTicketGroupFilters();
            this.busy = false;
        });
    }

    removeFromFavorite(filter: TicketGroupFilterDto) {
        event.stopPropagation();
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.ticketGroupFilterAgentFavoriteService.removeFavorite(filter.id).subscribe(res => {
            filter.favorite = false;
            this.getFavoriteTicketGroupFilters();
            this.busy = false;
        });
    }

    getFilterInfo(filter: TicketGroupFilterDto) {

        if (this.busy) {
            return;
        }

        this.busy = true;
        this.selectedFilter = filter;
        if (this.initialized) {
            // this.router.navigate(['tm/me', this.selectedFilter.id]);
            this.router.navigate(['tm/me', this.selectedFilter.id], {
                queryParamsHandling: 'preserve'
            });

        }
        this.addToBreadcrumb(filter.name, 'sub');
        this.selectedTicketType = null;
        this.ticketGroupFilterService.getGroupInfo(filter.id).subscribe(res => {
            this.selectedFilterInfo = res;
            this.changeTabName();
            this.handleOverlayTicketTypes(res);
            this.sortTicketTypes();
            this.prepareTicketWidget(res.filterCriteria);
            this.busy = false;
        }, error => {
            this.busy = false;
            }
            );
    }

    changeTabName() {
        this.defaultLabel = this.pageTitlePrefix + this.selectedFilter.name;
        this.setActiveTitle();
    }

    addToBreadcrumb(label: string, type: 'sub') {
        const index = this.items.findIndex(item => item.type == type);
        const object = {label: label, type: type};

        if (index > -1) {
            this.items.splice(index, 1);
            this.items.push(object);
        } else {
            this.items.push(object);
        }
        this.items = structuredClone(this.items);
        this.setBreadcrumbItems(this.items);
    }

    handleOverlayTicketTypes(res: TicketGroupFilterInfoDto) {
        let field = this.getSortingField();

        this.overlayTicketTypeCounts = structuredClone(res.ticketTypeCounts);
        this.overlayTicketTypeCounts = this.overlayTicketTypeCounts.sort((a, b) => b.count[field] - a.count[field]);
    }

    getSortingField(): string {
        let field: string;
        if (this.selectedAssignment == UiAgentAssignmentEnum.assignedToMe) {
            field = 'meCount';
        } else if (this.selectedAssignment == UiAgentAssignmentEnum.assignedToMyPool) {
            field = 'poolsCount';
        } else if (this.selectedAssignment == UiAgentAssignmentEnum.unassigned) {
            field = 'poolsUnassignedCount';
        } else if (this.selectedAssignment == UiAgentAssignmentEnum.total) {
            field = 'totalCount';
        }
        return field;
    }

    sortTicketTypes() {
        if (!this.selectedFilterInfo.ticketTypeCounts?.length) {
            return;
        }

        const countField = this.getSortingField();

        const selected = this.selectedFilterInfo.ticketTypeCounts.filter(tt => tt.uiSelected);
        const nonSelected = this.selectedFilterInfo.ticketTypeCounts.filter(tt => !tt.uiSelected);

        selected.sort((a, b) => b.count[countField] - a.count[countField]);
        nonSelected.sort((a, b) => b.count[countField] - a.count[countField]);

        this.selectedFilterInfo.ticketTypeCounts = [...selected, ...nonSelected];
    }

    handleCollapseGroups(event: boolean) {
        this.panelVisible = !this.panelVisible;
        if (event == false) {
            this.getFilterGroupCount();
        }
    }

    handleTicketTypeSelect(ticketTypeObject: TicketTypeCountDto, selectionType: 'main' | 'overlay') {
        if (this.busy) {
            return;
        }
        this.busy = true;
        ticketTypeObject.uiSelected = !ticketTypeObject.uiSelected;
        this.syncTicketTypeSelection(ticketTypeObject.uiSelected, selectionType, ticketTypeObject.ticketType.id)
        this.getTicketTypeEntries(ticketTypeObject);
        this.sortTicketTypes();
    }

    syncTicketTypeSelection(newValue: boolean, selectionType: 'main' | 'overlay', ticketTypeId: number) {

        let ticketTypes: TicketTypeCountDto[];
        if (selectionType == 'overlay') {
            ticketTypes = this.selectedFilterInfo.ticketTypeCounts;
        } else if (selectionType == 'main') {
            ticketTypes = this.overlayTicketTypeCounts;
        }
        const ticketType = ticketTypes.find(item => item.ticketType.id == ticketTypeId);
        if (ticketType) {
         ticketType.uiSelected = newValue;
        }
    }

    getTicketTypeEntries(ticketTypeObject: TicketTypeCountDto) {
        this.selectedTicketTypeCountDto = ticketTypeObject;
        this.selectedTicketType = ticketTypeObject?.ticketType;

        const filter = FilterCriteria.cloneFilterCriteriaArray(this.selectedFilterInfo.filterCriteria);

        //prepare selected ticket types
        const filterOr: FilterCriteria = new FilterCriteria();
        for (let item of this.selectedFilterInfo.ticketTypeCounts) {
            if (item.uiSelected == true) {
                filterOr.addFilterCriteriaToOr(FilterCriteria.create('type', FilterCriteria.cOperatorEqual, item.ticketType.id))
            }
        }

        if (filterOr.or && filterOr.or.length) {
            filter.push(filterOr);
        }

        // prepare assignment of dropdown
        const assignmentFilters = this.getAssignmentFilter();

        if (assignmentFilters && assignmentFilters.length) {
            for (let item of assignmentFilters) {
                filter.push(item);
            }
        }

        this.prepareTicketWidget(filter);
        this.busy = false;
    }

    adjustPriority(event: CdkDragDrop<any[]>): void {
        moveItemInArray(this.favoriteGroupFilters, event.previousIndex, event.currentIndex);
        let operations = EntityPriorityHandler.adjustPriority(this.favoriteGroupFilters, "orderPriority", 50);

        let updatedArray = [];

        for (const item of operations) {
            updatedArray.push(item.entry);
        }

        const entryObservables: Observable<any>[] = updatedArray.map(entry => {
            return this.ticketGroupFilterService.update(entry);
        });

        forkJoin(entryObservables).subscribe(value => {
        });

    }

    prepareTicketWidget(filter: FilterCriteria[]) {
        const clonedFilter =  FilterCriteria.cloneFilterCriteriaArray(filter);
        const assignmentFilter = this.getAssignmentFilter();
        if (assignmentFilter) {
            for (let item of assignmentFilter) {
                clonedFilter.push(item);
            }
        }

        // // skip the entries which are not active
        // clonedFilter.push(FilterCriteria.create('status', FilterCriteria.cOperatorNotEqual, TmStatusEnum.resolved));
        // clonedFilter.push(FilterCriteria.create('status', FilterCriteria.cOperatorNotEqual, TmStatusEnum.cancelled));

        const objectRequest = ObjectRequestList.createWithPaging(true, clonedFilter, [], PagingDto.create(0,10));
        // objectRequest.objectRequestComplex = ObjectRequestComplex.build(
        //     true,
        //     false,
        //     ObjectRequestComplexNode.createRelationNode("objectLatestCustomer", ObjectRequestRelation.createJoin("+objectLatestCustomer")),
        //     ObjectRequestComplexNode.createRelationNode("objectLatestContract", ObjectRequestRelation.createJoin("+objectLatestContract"))
        //     );

        this.ticketWidget = WidgetFactory.createWidgetList(
            'cm.contract.standard.navigation.tm.ticket.base.basic.widget' + this.selectedFilter.id,
            'Tickets',
            'table',
            'list',
            'entity',
            'tm.Ticket',
            'No tickets found!',
            objectRequest
        );
        this.ticketWidgetSwitch = !this.ticketWidgetSwitch;
    }
    handleObjectSelect(event: ObjectIdentifierData) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry("tm.Ticket", event.objectId, null, "Ticket", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    sortByOrderPriority(array: TicketGroupFilterDto[]): any[] {
        return array.sort((a, b) => a.orderPriority - b.orderPriority);
    }

    onUiSelectedLayoutContextMenu(event: MouseEvent, group: TicketGroupFilterDto, isFavorite: boolean) {
        this.groupContextMenuId = group.id;
        this.prepareLayoutContextMenu(group, isFavorite);
        this.layoutContextMenu.show(event);
    }

    onUiHideContextMenu() {
        this.groupContextMenuId = null;
    }

    prepareLayoutContextMenu(group: TicketGroupFilterDto, isFavorite: boolean) {
        let contextMenuItems: MenuItem[] = [
            {label: 'Bearbeiten', icon: 'fas fa-pencil', command: () => this.onEditGroup(group)},];

        if (!isFavorite) {
            contextMenuItems.push(
                {
                    label: 'Zu Favoriten hinzufügen ',
                    icon: 'pi pi-star',
                    command: () => this.addToFavorite(group)
                }
            );
        } else {
            contextMenuItems.push(
                {
                    label: 'Von Favoriten entfernen',
                    icon: 'pi pi-star-fill',
                    command: () => this.removeFromFavorite(group)
                }
            );
        }
        this.uiLayoutContextMenuItems = contextMenuItems;
    }

    onEditGroup(group: TicketGroupFilterDto) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('tm.TicketGroupFilter', group.id, null, "Object", null, null, MvsObjectNavigationActionEnum.edit);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    handleManageFavorites() {
        this.manageFavoritesSidebar = true;
    }


    getAssignmentFilter(): FilterCriteria[] {

        const filters: FilterCriteria[] = [];
        const myAgentPools = this.getAgentPools();

        if (this.selectedAssignment == UiAgentAssignmentEnum.assignedToMe) {
            filters.push(FilterCriteria.create('assigneeAgent', FilterCriteria.cOperatorEqual, this.loggedOnAgent.id));

        } else if (this.selectedAssignment == UiAgentAssignmentEnum.assignedToMyPool) {
            filters.push(myAgentPools);

        } else if (this.selectedAssignment == UiAgentAssignmentEnum.unassigned){
            filters.push(myAgentPools);
            filters.push(FilterCriteria.create('assigneeAgent', FilterCriteria.cOperatorIsNull, ''));
        }

        return filters;
    }

    getAgentPools() {
        const filter = FilterCriteria.createOrFromArray('assigneeAgentPool', 'id', this.loggedOnAgentPools);
        return filter;
    }

    fetchLoggedOnAgent() {
        this.agentService.me().subscribe(res => {
            this.loggedOnAgent = res;
            this.fetchLoggedOnAgentPools();
        })
    }

    handleBadgeCountClick(value: number, group: TicketGroupFilterDto) {
        if (group.id != this.selectedFilter.id) {
            this.selectedFilter = group; // check if a badge is clicked on an inactive group
            this.getFilterInfo(group);
            return;
        }
        this.selectedAssignment = value;
        this.handleChangeAssignmentType();
        event.stopPropagation();
    }

    handleChangeAssignmentType(event?: DropdownChangeEvent) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { type: this.selectedAssignment },
            queryParamsHandling: 'merge',
        });
        this.getTicketTypeEntries(this.selectedTicketTypeCountDto);
    }

    fetchLoggedOnAgentPools() {

        this.agentPoolService.my().subscribe(res => {
            this.loggedOnAgentPools = res.entries;
        })
    }

    clearAllTicketTypeSelections() {
        for (let filter of this.selectedFilterInfo.ticketTypeCounts) {
            filter.uiSelected = false;
        }

        for (let filter of this.overlayTicketTypeCounts) {
            filter.uiSelected = false;
        }
        this.prepareTicketWidget([]);
    }

    handleModifyFilters() {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('tm.TicketGroupFilter', 0, null, "Object", null, null, 'tm.TicketGroupFilter');
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }

    hideFilterSection() {
       this.hideFilters = !this.hideFilters;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        if (this.objectServiceSubscription) {
            this.objectServiceSubscription.unsubscribe();
        }
    }

    // isTopBarVisible(): boolean {
    //     return false;
    // }

    protected readonly TicketGroupFilterAccess = TicketGroupFilterAccess;
    protected readonly UiAgentAssignmentEnum = UiAgentAssignmentEnum;
}
