import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    DtoList, MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    ObjectIdentifier,
    Sorting,
    WidgetData,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {LogicTestCaseDto} from "../../dto/logic-test-case.dto";
import {LogicTestCaseImportVariableDto} from "../../dto/logic-test-case-import-variable.dto";
import {LogicImportService} from "../../service/api/logic-import.service";
import {LogicTestCaseService} from "../../service/api/logic-test-case.service";
import {LogicImportDto} from "../../dto/logic-import.dto";
import {LogicExportDto} from "../../dto/logic-export.dto";
import {WidgetFactory} from "@kvers/alpha-ui";
import {LogicResultDto} from "../../service/dto/logic-result.dto";
import {LogicCheckTestCaseVariablesResult} from "../../service/dto/logic-check-test-case-variables-result.dto";
import {WidgetFunctionCallback} from "@kvers/alpha-core-common/lib/widget/widget-function-callback";
import {CalculationLogicTestCaseService} from "../../service/api/calculation-logic-test-case.service";
import {CalculationResultDataDto} from "../../service/dto/calculation-result-data.dto";
import {CalculationTestCaseVariablesResultDto} from "../../service/dto/calculation-test-case-variables-result.dto";

interface TestCaseChangeEvent {
    dto: DtoDetail,
    variables: LogicImportDto[] & LogicExportDto[]
}

@Component({
    selector: 'mvs-lg-calculation-test-case',
    templateUrl: './lg-calculation-test-case.component.html',
    styleUrls: ['./lg-calculation-test-case.component.scss']
})
export class LgCalculationTestCaseComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    selectedTestCase: LogicTestCaseDto;
    testCaseDtoList: DtoList;

    occurredException: string;
    occurredExceptionDetails: string;

    variables;

    widgetTestCases: WidgetData;
    logicImportObjectContext: DtoImportObjectContext;
    widgetTestCaseVariables: WidgetData;

    testCaseEvaluation: CalculationResultDataDto;
    testCaseVariableCheck: CalculationTestCaseVariablesResultDto;


    @Input() calculationLogicTypeId: number;
    @Input() screen: string;
    @Output() onSelectTestCase = new EventEmitter;

    constructor(
        protected coreService: MvsCoreService,
        protected testCaseService: CalculationLogicTestCaseService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.widgetTestCases = WidgetFactory.createWidgetList(
            'lg.calculation.test.case.setup.test.cases',
            "Test Cases",
            'selectable',
            'list',
            'entity',
            'lg.CalculationLogicTestCase',
            'Keine Test Cases vorhanden',
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("calculationLogicType", FilterCriteria.cOperatorEqual, this.calculationLogicTypeId)],
                [new Sorting("name", true)]
            )
        );

        this.widgetTestCases.functionCallbacks = this.widgetToolbarCreate("calculationLogicTypeDtoId", this.calculationLogicTypeId);

    }

    handleTestCaseSelect(event: ObjectIdentifier) {

        this.widgetTestCaseVariables = WidgetFactory.createWidgetListEntity(
            "lg.calculation.test.case.setup.test.case.variables",
            "Variablen",
            "lg.CalculationLogicTestCaseImportVariable",
            "Keine Variablen gefunden",
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("testCase", FilterCriteria.cOperatorEqual, event.objectId)],
                [new Sorting("name", true)]
            )
        );


        this.widgetTestCaseVariables.functionCallbacks = this.widgetToolbarCreate('testCaseDtoId', event.objectId);

    }

    /**
     * defaulting fields for adding new entry
     * @param fieldName
     * @param fieldValue
     */
    widgetToolbarCreate(fieldName: string, fieldValue: number) {
        return {
            onFunctionCreateNew: (): WidgetToolbarCallbackInterface => {
                const dto = new DtoDetail();
                dto[fieldName] = fieldValue;
                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(fieldName, MvsFormFieldAccessEnum.READ);

                return {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite,
                };
            },
        };
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshTestCases();

        if (this.calculationLogicTypeId) {
            this.initialized = true;
        }

    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["calculationLogicTypeId"]) {
            this.refreshComponent();
        }
    }

    /**
     * Refresh Test Cases.
     */
    refreshTestCases() {
        this.busy = true;

        const filterCriteria = FilterCriteria.create('calculationLogicType', FilterCriteria.cOperatorEqual, this.calculationLogicTypeId);

        // retrieve test cases
        this.testCaseService.list(ObjectRequestList.createBasic(false, [filterCriteria], [new Sorting("name", true)])).subscribe(value => {
            this.testCaseDtoList = value;
            this.busy = false;
        });

    }

    /**
     * Generate Test.
     */
    onRunLogic() {
        this.runLogic();
    }

    runLogic() {

        if (this.busy) {
            return;
        }

        // run logic
        this.testCaseService.evaluateLogic(this.selectedTestCase.id).subscribe(result => {
            this.testCaseEvaluation = result;
            this.busy = false;
        }, error => {
            this.busy = false;
        });

    }

    refreshTestCaseVariables() {

        if (this.busy) {
            return;
        }

        // check variables
        this.testCaseService.checkVariables(this.selectedTestCase.id).subscribe(result => {
            this.testCaseVariableCheck = result;
            this.busy = false;
        }, error => {
            this.busy = false;
        });

    }

    onRefreshTestCases() {
        this.refreshTestCases();
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
