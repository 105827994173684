import {Component, Input, OnChanges, OnInit, SimpleChanges,} from "@angular/core";
import {
    MvsFormControlOverwrite,
    ObjectBaseComponent, WidgetData, WidgetDataParam,
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: "rp-report-agent-favorite-component",
    templateUrl: "./rp-report-agent-favorite.component.html",
    styleUrls: ["./rp-report-agent-favorite.component.scss"],
})
export class RpReportAgentFavoriteComponent extends ObjectBaseComponent
    implements OnInit, OnChanges {

    objectWidget: WidgetData;

    ngOnInit() {
        super.ngOnInit();
    }
    onObjectChanged() {
        this.refreshObjectWidget();
    }

    refreshObjectWidget() {
        const formControlOverwrite = MvsFormControlOverwrite.createHideAll('name', 'image','rgbaColor','rgbaColorBackground');
        this.objectWidget = WidgetFactory.createWidgetObject(
            'rp.report.agent.favorite.object.widget',
            'Favorite',
            'rp.ReportAgentFavorite',
            this.objectIdentifier.objectId,
            WidgetDataParam.create('formControlOverwrite', formControlOverwrite))
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

}

