import {Route, Routes} from "@angular/router";
import {NsConfigPage} from "./page/overview/ns-config/ns-config.page";
import {NsSmsConfigPage} from "./page/overview/ns-sms-config/ns-sms-config.page";
import {NsEmailConfigPage} from "./page/overview/ns-email-config/ns-email-config.page";
import {NsBlackListPage} from "./page/overview/ns-black-list/ns-black-list.page";
import {NsWhiteListPage} from "./page/overview/ns-white-list/ns-white-list.page";
import {NsDashboardPage} from "./page/dashboard/ns-dashboard/ns-dashboard.page";
import {NsNotificationObjectPage} from "./page/object/notification-object-page/ns-notification-object.page";
import {NsNotificationTypeObjectComponent} from "./component/ns-notification/ns-notification-type-object.component";
import {NsConfigTestPage} from "./page/overview/ns-config-test/ns-config-test.page";
import {NotificationOverviewPage} from "./page/notification-overview-page/notification-overview.page";

export function NsSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        //TODO @Yasir: Please Merge all configuraitons together

        // BELONGS ALL TO CONFIGURATION         ***********
        {path: 'ns/config', component: NsConfigPage},
        // BELONGS ALL TO CONFIGURATION         ***********


        {path: 'ns/dashboard', component: NsDashboardPage},
        {path: 'ns/notification/:id', component: NsNotificationObjectPage},
        {path: 'ns/notificationType', component: NsNotificationTypeObjectComponent},


    ];

    return routes;

}
