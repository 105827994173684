import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {CustomerContractDto} from "../../../../../../dto/customer-contract.dto";
import {ObjectInfo} from "../../data/object-info";
import {CustomersContractsObjectsRuntime} from "../../data/customer-contracts-object-runtime";


@Component({
    selector: 'contracts-relationships',
    templateUrl: './contracts-relationships.component.html',
})
export class ContractsRelationshipsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() customerContractDto: CustomerContractDto;
    @Input() customerContractsRuntime:  CustomersContractsObjectsRuntime;
    selectedPerson: ObjectInfo;
    selectedContract: ObjectInfo;
    initialized: boolean;

    ngOnInit(): void {
        this.initComponent();
    }

    constructor(protected navigationService:MvsObjectNavigationService,
                protected coreService: MvsCoreService,) {

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshInternal();
        this.initialized = true;
    }

    refreshInternal() {
        //person contracts => person -> contract
        //insurableObject contracts =>
        const personObject = this.customerContractsRuntime.personContracts.find(item => item.toId == this.customerContractDto.id);
        if (personObject) {
            this.selectedPerson = this.customerContractsRuntime.objectInfos.find(item => item.id == personObject.fromId);
        }

        const contractsObject = this.customerContractsRuntime.insurableObjectContracts.find(item => item.toId == this.customerContractDto.id);
        if (contractsObject) {
            this.selectedContract = this.customerContractsRuntime.objectInfos.find(item => item.id == contractsObject.fromId);
        }
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["objectIdentifier"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
