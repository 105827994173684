<ng-container *ngIf="initialized">

    <!-- Topbar for Undo/Redo -->
    <div class="flex align-items-center px-3 py-2 justify-content-between border-round-top-xl border-1 border-300 surface-ground ">
        <div class="gap-3">
            <p-button pTooltip="Undo" tooltipPosition="top" icon="fa-solid fa-arrow-turn-left" styleClass="p-button-text text-color-secondary" (click)="undo()"></p-button>
            <p-button pTooltip="Redo" tooltipPosition="top" icon="fa-solid fa-arrow-turn-right" styleClass="p-button-text text-color-secondary" (click)="redo()"></p-button>
        </div>
        <p-dropdown
                [options]="languageList"
                [(ngModel)]="logicLanguage"
                optionValue="value"
                optionLabel="label"
                (onChange)="onLanguageChange()">
        </p-dropdown>

    </div>

    <!-- CodeMirror Editor -->
    <div #editor class="cm-editor border-1 border-300 pt-2 overflow-auto" style="height: 50vh;"></div>


</ng-container>