import {Component, OnInit} from '@angular/core';
import {DtoList, PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MetaService} from "@kvers/alpha-core-common";
import {InsurableObjectTypeService} from "../../../service/api/insurable-object-type.service";
import {InsurableObjectTypeDto} from "../../../dto/insurable-object-type.dto";
import {MenuItem} from "primeng/api";

@Component({
    selector: 'mvs-ci-basic-config',
    templateUrl: './basic-config.page.html',
    styleUrls: ['./basic-config.page.scss']
})
export class BasicConfigPage extends PageComponent implements OnInit {
    objectBrowserRequest: any;
    subObjectBrowserRequest: any;

    defaultLabel: string = "Versicherungskonfiguration";

    activeIndex: number = 0;

    widgetContractPersonType: WidgetData;

    widgetInsurableObjectType: WidgetData;
    widgetInsurableObjectTypeField: WidgetData;
    widgetInsurableObjectsWidget:WidgetData;
    widgetContractTypeInsurableObjectType: WidgetData;


    selectedInsurableObjectType: ObjectIdentifierData;
    importContextInsurableObjectType: DtoImportObjectContext;

    items: MenuItem[] | undefined;
    showBreadCrumb: boolean = false;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected metaService: MetaService,
        protected insurableObjectTypeService: InsurableObjectTypeService,
        // private titleService: Title
    ) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initComponent();
    }

    initComponent(): void {

        this.widgetContractPersonType = WidgetFactory.createWidgetList(
            "ci.config.contract.person.type",
            "Personentypen",
            "table",
            "list",
            "entity",
            "ci.ContractPersonType",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [])
        );

        this.widgetInsurableObjectType = WidgetFactory.createWidgetList(
            "ci.config.insurable.object.type",
            "Objekttypen",
            "treeTable",
            "list",
            "entity",
            "ci.InsurableObjectType",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [])
        );

        this.initialized = true;

    }

    handleSelectInsurableObjectType(event: ObjectIdentifierData) {

        this.showBreadCrumb = false;
        this.items = [];

        this.selectedInsurableObjectType = event;

        this.importContextInsurableObjectType = DtoImportObjectContext.createFromObjectIdentifier(event);

        this.widgetInsurableObjectTypeField = WidgetFactory.createWidgetList(
            "ci.config.insurable.object.type.fields",
            "Direkte Felder",
            "table",
            "list",
            "entity",
            "ci.InsurableObjectTypeField",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [FilterCriteria.create("type", FilterCriteria.cOperatorEqual, event.objectId)], [])
        );

        this.widgetInsurableObjectsWidget = WidgetFactory.createWidgetList(
            "ci.config.insurable.object.example.data",
            "Beispieldaten",
            "table",
            "list",
            "entity",
            "ci.InsurableObject",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [FilterCriteria.create("type", FilterCriteria.cOperatorEqual, event.objectId)], [])
        );


        this.getInsurableObjectType(this.selectedInsurableObjectType.objectId);

        this.metaService.getByAlias("ci.InsurableObject", true, true, true, false, false).subscribe(value => {
            console.log(value);
        });

    }

    getInsurableObjectType(id: number) {

        const filterCriteria = FilterCriteria.create('id', FilterCriteria.cOperatorEqual, id);

        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], [])

        this.insurableObjectTypeService.list(objectRequest).subscribe((res: DtoList<InsurableObjectTypeDto>) => {

            const object: InsurableObjectTypeDto = res.entries[0]


            this.items.push({icon: object['euImage'], label: object.name});

            if (object.parentDtoId) {
                this.getInsurableObjectType(object.parentDtoId);
            } else {
                this.items[0].iconClass = 'text-primary';
                this.items = this.items.reverse();
                this.showBreadCrumb = true;

            }
        })

    }

}
