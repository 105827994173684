<ng-container *ngIf="initialized">

    <div class="grid min-h-screen px-3">
        <div class="p-0 mb-3" [ngClass]="screen === 'sm' ? 'col-12' : 'lg:col-4 col-12'">
            <div class="surface-border border-round flex-auto h-full surface-section p-2 border-round shadow-1">

                <div class="col-12" *ngIf="testCaseDtoList">
                    <p-panel header="Auswahl Test Case" styleClass="shadow-1">
                        <div class="flex flex-wrap gap-2 align-items-center">
                            <p-dropdown
                                    [options]="testCaseDtoList.entries"
                                    [(ngModel)]="selectedTestCase"
                                    placeholder="Select a test case"
                                    optionLabel="name"
                                    (onChange)="refreshTestCaseVariables()"
                                    class="col-5 p-0" styleClass="w-full">
                            </p-dropdown>

                            <p-button [disabled]="busy" icon="fa-solid fa-arrows-rotate text-color-secondary"
                                      styleClass="surface-200" (click)="onRefreshTestCases()"></p-button>

                            <p-button [disabled]="busy" icon="fa-sharp fa-solid fa-flask-vial" label="View Result"
                                      (click)="onRunLogic()" *ngIf="selectedTestCase"></p-button>

                        </div>
                    </p-panel>

                </div>

                <div class="col-12">


                    @if (testCaseVariableCheck) {
                        <p-panel header="Mapping Variablen" styleClass="h-full shadow-1">

                            @for (mappedVariable of testCaseVariableCheck.mappedVariables; track mappedVariable) {

                                <div class="flex mt-3 mb-1">
                                    <div class="text-color-secondary w-20rem">
                                        {{ mappedVariable.config.name }}
                                    </div>
                                    <div class="font-medium">

                                        @if (mappedVariable.variable.valueIsNull) {
                                            NULL
                                        } @else if (mappedVariable.variable.valueObject) {
                                            Object ID: {{ mappedVariable.variable.valueObject.id }}
                                            @if (mappedVariable.variable.valueObject.name) {
                                                {{ mappedVariable.variable.valueObject.name }}
                                            }
                                        } @else if (mappedVariable.variable.valueSimple) {
                                            {{ mappedVariable.variable.valueSimple }}
                                        }
                                    </div>
                                </div>
                            }

                        </p-panel>

                        @if (testCaseVariableCheck.missingVariables && testCaseVariableCheck.missingVariables.length > 0) {
                            <p-panel header="Fehlende Variablen" styleClass="h-full shadow-1">

                                @for (missingVariable of testCaseVariableCheck.missingVariables; track missingVariable) {

                                    <div class="flex mt-3 mb-1">
<!--                                        <div style="color: var(&#45;&#45;red-500);">-->
<!--                                            <i class="fa fa-exclamation-circle"></i>-->
<!--                                        </div>-->
                                        <div  class="text-color-secondary">
                                            {{ missingVariable.name }}
                                        </div>
                                    </div>
                                }

                            </p-panel>
                        }

                    }

                </div>

                <div class="col-12" *ngIf="occurredException">
                    <p-message severity="error" [text]="occurredException"></p-message>

                    <p-message *ngIf="occurredExceptionDetails" severity="error"
                               [text]="occurredExceptionDetails"></p-message>
                </div>

                @if (testCaseEvaluation) {
                    <div class="col-12">
                        <p-panel header="Result" styleClass="h-full shadow-1">

                            <div class="flex mt-3 mb-1">
                                <div  class="text-color-secondary w-20rem">
                                    Complete Value
                                </div>
                                <div  class="font-medium">
                                    @if (testCaseEvaluation.rawReturnIsNull) {
                                        NULL
                                    } @else if (testCaseEvaluation.rawReturnSimple) {
                                        {{ testCaseEvaluation.rawReturnSimple }}
                                    } @else if (testCaseEvaluation.rawReturnObject) {
                                        Object ID: {{ testCaseEvaluation.rawReturnObject.id }}
                                        @if (testCaseEvaluation.rawReturnObject.name) {
                                            {{ testCaseEvaluation.rawReturnObject.name }}
                                        }
                                    }
                                </div>
                            </div>

                            @for (exportVariable of testCaseEvaluation.exportVariables; track exportVariable) {

                                <div class="flex mt-3 mb-1">
<!--                                    <div style="color: var(&#45;&#45;green-500);">-->
<!--                                        <i class="fa fa-check-circle p-success"></i>-->
<!--                                    </div>-->
                                    <div  class="text-color-secondary w-20rem">
                                        {{ exportVariable.config.name }}
                                    </div>
                                    <div  class="font-medium">
                                        @if (exportVariable.variable.valueIsNull) {
                                            NULL
                                        } @else if (exportVariable.variable.valueObject) {
                                            Object ID: {{ exportVariable.variable.valueObject.id }}
                                            @if (exportVariable.variable.valueObject.name) {
                                                {{ exportVariable.variable.valueObject.name }}
                                            }
                                        } @else if (exportVariable.variable.valueSimple) {
                                            {{ exportVariable.variable.valueSimple }}
                                        }
                                    </div>
                                </div>
                            }

                        </p-panel>
                    </div>
                }

            </div>
        </div>

        <div class="p-0 mb-3" [ngClass]="screen === 'sm' ? 'col-5' : 'col-12 lg:col-3'">
            <div class="min-h-screen surface-section border-round shadow-1" [ngClass]="screen === 'sm' ? 'mr-3' : 'mx-3'">
                <mvs-widget
                        *ngIf="widgetTestCases"
                        [widgetData]="widgetTestCases"
                        [importObjectContext]="logicImportObjectContext"
                        (onObjectSelect)="handleTestCaseSelect($event)"
                ></mvs-widget>
            </div>
        </div>

        @if (widgetTestCaseVariables) {
        <div class="p-0 mb-3" [ngClass]="screen === 'sm' ? 'col-7' : 'col-12 lg:col-5'">
            <div class="min-h-screen surface-section border-round shadow-1">
                <mvs-widget
                        *ngIf="widgetTestCaseVariables"
                        [widgetData]="widgetTestCaseVariables"
                ></mvs-widget>
            </div>
        </div>
        }

    </div>


</ng-container>