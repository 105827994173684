import {Injectable, Type} from '@angular/core';
import {MvsCrudModeEnum, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
  ProvisionTemplateTypeObjectComponent
} from "../../component/provision-template-type-object-component/provision-template-type-object.component";

@Injectable({
  providedIn: 'root'
})
export class ProvisionTemplateTypeService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/cp/provisionTemplateTypes')
  }

  getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
    if (mode != MvsCrudModeEnum.create) {
      return ProvisionTemplateTypeObjectComponent;
    }
    return null;
  }
}