import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ObjectComponent} from "@kvers/alpha-ui";
import {PersonDto} from "../../../../../../../pm/dto/person.dto";
import {DtoDetail} from "@kvers/alpha-core-common";

@Component({
    selector: 'check-person-same-household',
    templateUrl: './check-customer-household.component.html',
})
export class CheckCustomerHouseholdComponent implements OnInit, OnChanges, OnDestroy {

    @Input() dto: DtoDetail;
    @Input() persons: PersonDto[];
    found: boolean = false;
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor() {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {
        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        const personId = this.dto['dto']['personBDto']['id'];

        if (!this.persons || !personId) {
            return;
        }
        const exists = this.persons.find(item => item.id == personId);
        if (exists) {
            this.found = true;
        } else {
            this.found = false;
        }
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["personsCustomer"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
