<ng-container *ngIf="initialized">

    <div class="grid mx-0">
        <div class="col-5">
            <mvs-widget class="shadow-1 block border-round"
                    *ngIf="widgetTestCases"
                    [widgetData]="widgetTestCases"
                    (onObjectSelect)="handleTestCaseSelect($event)"
            ></mvs-widget>
        </div>

        <div class="col-7">
            <mvs-widget  class="shadow-1 block border-round"
                    *ngIf="widgetTestCaseVariables"
                    [widgetData]="widgetTestCaseVariables"
                    [importObjectContext]="importContext"
            ></mvs-widget>
        </div>

    </div>

</ng-container>