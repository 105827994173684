import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria, MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    ObjectIdentifierData,
    ObjectRequestList,
    PageComponent,
    WidgetData, WidgetToolbarCallbackInterface,
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    WidgetFactory
} from "@kvers/alpha-ui";
import {LogicDto} from "../../dto/logic.dto";

@Component({
    selector: 'lg-config-page',
    templateUrl: './lg-config-page.page.html',
    styleUrls: ['./lg-config-page.page.scss']
})
export class LgConfigPagePage extends PageComponent implements OnInit, OnDestroy, OnChanges {

    activeIndex: number = 0;
    lgGroupWidget: WidgetData;
    lgLogicWidget: WidgetData;

    lgLogicTestCaseWidget: WidgetData;
    lgLogicTestCaseImportVariableWidget: WidgetData;


    lgCalculationLogicTypeWidget: WidgetData;

    selectedLogicId: number;
    selectedCalculationLogicId: number;

    defaultLabel = 'Logic Configuration';


    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService, protected navigationService: MvsObjectNavigationService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.refreshWidgets();
    }


    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes)
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    refreshWidgets() {
        this.refreshGroupWidget();
        this.refreshLogicWidget();
        this.refreshLogicTestCaseWidgets();
        this.refreshCalculationLogicTypeWidget();
    }


    refreshGroupWidget() {

        const objectRequest = ObjectRequestList.createBasic(true, [], []);

        this.lgGroupWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-group-widget',
            'Group',
            'lg.LogicGroup',
            'No Data',
            objectRequest
        );
    }

    refreshLogicWidget() {

        const objectRequest = ObjectRequestList.createBasic(true, [], []);

        this.lgLogicWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-logic-widget',
            'Logic',
            'lg.Logic',
            'No Data',
            objectRequest
        );
    }

    refreshLogicTestCaseWidgets() {

        this.lgLogicTestCaseWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-logic-test-case-widget',
            'Test Cases',
            'lg.LogicTestCase',
            'No Data',
            ObjectRequestList.createSimple(null)
        );

        this.lgLogicTestCaseImportVariableWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-logic-test-case-import-variable-widget',
            'Import Variables',
            'lg.LogicTestCaseImportVariable',
            'No Data',
            ObjectRequestList.createSimple(null)
        );


    }

    refreshCalculationLogicTypeWidget() {

        this.lgCalculationLogicTypeWidget = WidgetFactory.createWidgetTableEntity(
            'lg-config-page-calculation-logic-type-widget',
            'Calculation logic Type',
            'lg.CalculationLogicType',
            'No Data',
            ObjectRequestList.createSimple(null)
        );
    }


    handleLogicSelect(object: ObjectIdentifierData) {

        if (object.objectType == 'lg.Logic') {
            this.selectedLogicId = object.objectId;
        } else if (object.objectType == 'lg.CalculationLogicType') {
            this.selectedCalculationLogicId = object.objectId;
        }

    }


    handleNavigation(event: ObjectIdentifierData) {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(event.objectType, event.objectId, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
    }


    openObjectInNewTab(event: MouseEvent) {
        const url = `/lg/logic/${this.selectedLogicId}`;
        this.navigationService.handlePageNavigation(url, null, {openNewTab: true});
    }

    openCalculationLogicInNewTab(event: MouseEvent) {
        const url = `/lg/calculation-logic/${this.selectedCalculationLogicId}`;
        this.navigationService.handlePageNavigation(url, null, {openNewTab: true});
    }

}
