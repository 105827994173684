@if (initialized) {

    <div class="flex justify-content-between align-items-center py-1 sticky z-1 surface-0 border-bottom-1 border-300 mb-3 p-2" style="top: -10px">
        <div class="flex w-full justify-content-between align-items-center">
            <h5 class="text-color text-xl m-0">{{ dto.name }}</h5>
            <div class="flex gap-2 align-items-center">
                @for (button of widgetButtons; track button) {
                    <div class="flex align-items-center cursor gap-2 overflow-visible" pRipple
                         [pTooltip]="button.tooltip"
                         tooltipPosition="left" (click)="setView(button.action)">
                        <i class="{{button.icon}} p-2"
                           [ngClass]="currentView ==  button.action ? 'bg-primary-100 text-primary p-2 border-round-sm line-height-2' : 'text-700'"
                           *ngIf="button.display">
                        </i>
                    </div>
                }
            </div>
        </div>
    </div>


    @if (currentView === 'logicCalculation') {
        <mvs-widget class="shadow-1 border-round mb-3 surface-section block"
                *ngIf="calculationLogicTypeFieldWidgetData"
                [widgetData]="calculationLogicTypeFieldWidgetData"
        ></mvs-widget>

        <lg-calculation-logic-type-step-component class="shadow-1 border-round mb-3 block surface-section"
                [calculationLogicTypeId]="this.objectIdentifier.objectId"
        ></lg-calculation-logic-type-step-component>

        <mvs-object-comments class="shadow-1 border-round mb-3 px-2 block surface-section"
                headline="Kommentare"
                [mainObject]="objectIdentifier"
                linkedEntityObjectType="lg.CalculationLogicTypeComment"
        ></mvs-object-comments>
    }

    @if (currentView === 'testCases') {
        <mvs-lg-calculation-test-case [screen]="'sm'"
                [calculationLogicTypeId]="objectIdentifier.objectId"
        ></mvs-lg-calculation-test-case>
    }

    @if (currentView === 'info') {
        <mvs-widget
                *ngIf="calculationLogicTypeWidgetData"
                [widgetData]="calculationLogicTypeWidgetData"
        ></mvs-widget>
    }
}