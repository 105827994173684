import {Injectable, Type} from '@angular/core';
import {
    FilterCriteria,
    MvsCrudModeEnum,
    MvsCrudService,
    ObjectRequestList,
    WidgetData,
    WidgetDataParam
} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {
    LgCalculationLogicTypeObjectComponent
} from "../../component/object-components/calculation-logic-type-object-component/lg-calculation-logic-type-object.component";
import {WidgetFactory} from "@kvers/alpha-ui";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../core/helper/widget-function-call-back-create";

@Injectable({
    providedIn: 'root'
})
export class CalculationLogicTypeService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/lg/calculationLogicTypes')
    }

    getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
        if (mode != MvsCrudModeEnum.create) {
            return LgCalculationLogicTypeObjectComponent
        }
        return null;
    }

    getCalculationLogicTypeWidget(id:number): WidgetData {
        return WidgetFactory.createWidgetEntityData(
            'lg.calculation.logic.type.widget',
            'Calculation Logic Type',
            'lg.CalculationLogicType',
            id
        );
    }

    getCalculationLogicTypeFieldWidget(id:number): WidgetData {
        const filterCriteria = FilterCriteria.create('type',FilterCriteria.cOperatorEqual, id)

        const objectRequestList = ObjectRequestList.createBasic(true,[filterCriteria],[]);

        const widgetData: WidgetData = WidgetFactory.createWidgetTableEntity(
            'lg.calculation.logic.type.field.widget',
            'Calculation Logic Type Field',
            'lg.CalculationLogicTypeField',
            'No Result',
            objectRequestList
        );

        const fields:WidgetToolbarCreateInterface[] = [{fieldName:'typeDtoId', fieldValue:id}]

        widgetData.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
        return widgetData;

    }

    refreshCalculationLogicTypeStepWidgetData(id:number): WidgetData {

        const filterCriteria = FilterCriteria.create('calculationLogicType',FilterCriteria.cOperatorEqual, id)

        const objectRequestList = ObjectRequestList.createBasic(true,[filterCriteria],[]);

        const widgetData: WidgetData  = WidgetFactory.createWidgetTableEntity(
            'lg.calculation.logic.type.step.widget',
            'Calculation Logic Type Step',
            'lg.CalculationLogicTypeStep',
            'No Result',
            objectRequestList,
            WidgetDataParam.create('attributeToDisplay', 'name')
        );
        const fields:WidgetToolbarCreateInterface[] = [{fieldName:'calculationLogicTypeDtoId', fieldValue:id}]

        widgetData.uiComponent = 'selectable';
        widgetData.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);

        return widgetData;

    }

    refreshCalculationLogicTypeStepMappingWidgetData(id: number): WidgetData {

        const filterCriteria = FilterCriteria.create('calculationLogicTypeStep', FilterCriteria.cOperatorEqual, id)

        const objectRequestList = ObjectRequestList.createBasic(true, [filterCriteria], []);

        const widgetData: WidgetData  = WidgetFactory.createWidgetTableEntity(
            'lg.calculation.logic.type.step.mapping.widget',
            'Calculation Logic Type Step Mapping',
            'lg.CalculationLogicTypeStepMapping',
            'No Result',
            objectRequestList
        );

        const fields:WidgetToolbarCreateInterface[] = [{fieldName:'calculationLogicTypeStepDtoId', fieldValue:id}]

        widgetData.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);

        return widgetData;
    }


}