import {Component, Input} from '@angular/core';
import {MvsObjectService, ObjectBaseComponent} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {PartnerDto} from "../../dto/partner.dto";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ContractPartnerService} from "../../service/api/contract-partner.service";
import {ContractPartnerDto} from "../../dto/contract-partner.dto";
import {PartnerLinkDto} from "../../dto/partner-link.dto";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {CmPartnerWidgetStyle} from "./uiStyle/cm-partner-widget-style";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {PartnerLinkService} from "../../service/api/partner-link.service";
import {DtoList} from "@kvers/alpha-core-common";
import {MvsCrudService} from "@kvers/alpha-core-common";
import {ObserverService} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";

@Component({
    selector: 'mvs-cm-partner-component',
    templateUrl: './cm-partner.component.html',
    styleUrls: ['./cm-partner.component.scss']
})
export class CmPartnerComponent extends ObjectBaseComponent {


    dto: PartnerDto;

    partnerWidget: WidgetData;
    contractPartnerWidget: WidgetData;
    partnerLinkWidget: WidgetData;
    selectedPartnerLink: PartnerLinkDto;
    contractPartners: ContractPartnerDto[];
    partnerLinkWidgetStyle: CmPartnerWidgetStyle;


    partnerImportObjectContext: DtoImportObjectContext;
    partnerCategoryWidget: WidgetData;
    partnerContactWidget: WidgetData;
    partnerAddressWidget: WidgetData;

    partnerSelectableWidget: WidgetData;
    partnerDataWidget: WidgetData;
    partnerLinksDtoList: DtoList<DtoDetail>;
    partnerLinkCount: number;

    objectSubscription: Subscription;

    /**
     * Constructor.
     */
    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected contractPartnerService: ContractPartnerService,
        protected partnerLinkService: PartnerLinkService,
        protected configService: MvsConfigService,
        protected objectService: MvsObjectService,
        protected confirmationService: ConfirmationService,
        protected override observerService: ObserverService) {
        super(coreService, messageService, confirmationService, observerService);
    }

    ngOnInit(): void {

        this.objectSubscription = this.objectService.objectChangeEvent.subscribe(change => {
            if (change.objectType == 'cm.PartnerLink') {
                this.getPartnerLinks();
            }
        });

        super.ngOnInit();
    }

    /**
     * Init tasks.
     */
    initComponent() {

        // const filterCriteria = [FilterCriteria.create('partner', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)]

        // const objectRequest = ObjectRequestList.createBasic(true, filterCriteria, []);

        // this.partnerSelectableWidget = WidgetFactory.createWidgetList(
        //     'cm.PartnerLink.selectable.widget',
        //     "Partner Link 1",
        //     'selectable',
        //     'list',
        //     'entity',
        //     'cm.PartnerLink',
        //     'No Data',
        //     objectRequest,
        //     WidgetDataParam.create('attributeToDisplay', 'partner'),
        //     WidgetDataParam.create('colorField', 'rgbacolor')
        // );

        this.getPartnerLinks();


    }

    handlePartnerSelectableWidget() {


        this.partnerSelectableWidget = WidgetFactory.createWidgetTransient(
            'cm.PartnerLink.selectable.widget',
            "Partner Link",
            'selectable',
            'transient',
            'entity',
            'cm.PartnerLink',
            this.partnerLinksDtoList,
            WidgetDataParam.create('attributeToDisplay', 'linkPartnerDtoName'),
        );

        this.partnerSelectableWidget.functionCallbacks = {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const entityFieldName = 'partnerDtoId';
                const defaultDto: DtoDetail = new DtoDetail();
                defaultDto[entityFieldName] = this.objectIdentifier.objectId;
                const formControlOverwrite: MvsFormControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(entityFieldName, MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: defaultDto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    getPartnerLinks() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('partner', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            [])

        this.partnerLinkService.list(dtoRequest).subscribe(res => {
            this.partnerLinksDtoList = MvsCrudService.transformDtoListOutToIn(res);
            this.partnerLinkCount = res.entries.length;

            if (!this.partnerLinkCount) {
                this.partnerLinkCount = 0;
            }

            this.handlePartnerSelectableWidget();

        }, error => {

        })
    }

    /**
     * On object changed.
     */
    onObjectChanged() {

        this.partnerWidget = WidgetFactory.createWidgetTransient(
            'partner.component.form.main',
            "Partner",
            'data',
            'transient',
            'transient',
            'cm.Partner',
            this.dto,
            new WidgetDataParam("objectId", this.dto.id)
        );

        this.refreshContractPartnerWidget();

        this.refreshPartnerCategoryWidget();

        this.refreshPartnerContactWidget();

        this.refreshPartnerAddressWidget();

    }

    handlePartnerLinkSelect(event: ObjectIdentifierData) {
        this.selectedPartnerLink = event.data;

        this.refreshPartnerWidget(event.objectId);

        if (!this.partnerLinkWidgetStyle) {
            this.partnerLinkWidgetStyle = new CmPartnerWidgetStyle(this.configService, this.selectedPartnerLink);
        }

        this.partnerLinkWidgetStyle.addSelectedPartner(this.selectedPartnerLink);
        this.refreshPartnerLinkWidget();
        this.partnerLinkWidgetStyle = this.partnerLinkWidgetStyle.clone();
    }

    refreshPartnerWidget(objectId: number) {
        this.partnerDataWidget = WidgetFactory.createWidgetEntityData(
            'cm.partner.data.widget',
            'Partner Link',
            'cm.PartnerLink',
            objectId);

        // this.partnerDataWidget = WidgetFactory.createWidgetTransient(
        //     'cm.partner.data.widget',
        //     'Partner Link 2',
        //     'data',
        //     'transient',
        //     'entity',
        //     'cm.PartnerLink',
        //     this.partnerLinksDtoList,
        //     WidgetDataParam.create('objectId', objectId)
        //
        // )

    }

    refreshPartnerLinkWidget() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('partnerLink', FilterCriteria.cOperatorEqual, this.selectedPartnerLink.id)],
            []
        )

        this.partnerLinkWidget = WidgetFactory.createWidgetList(
            'partner.component.contract.partner.link.widget.table',
            this.selectedPartnerLink.linkPartnerDtoName + ' Contact',
            'list',
            'list',
            'entity',
            'cm.PartnerLinkContact',
            'Keine Daten vorhanden',
            dtoRequest
        );

        this.partnerLinkWidget.functionCallbacks = {
            onFunctionCreateNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const entityFieldName = 'partnerLinkDtoId';
                const defaultDto: DtoDetail = new DtoDetail();
                defaultDto[entityFieldName] = this.selectedPartnerLink.id;
                const formControlOverwrite: MvsFormControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField(entityFieldName, MvsFormFieldAccessEnum.HIDDEN);
                formControlOverwrite.addField("id", MvsFormFieldAccessEnum.HIDDEN);

                let test: WidgetToolbarCallbackInterface;
                test = {
                    dto: defaultDto,
                    formControlOverwrite: formControlOverwrite
                };
                return test;
            }
        };
    }

    refreshPartnerCategoryWidget() {

        this.partnerImportObjectContext = DtoImportObjectContext.createFromObjectIdentifier(new ObjectIdentifier(this.objectIdentifier.objectType, this.objectIdentifier.objectId));

        const filterCriteria = [FilterCriteria.create("partner", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];
        const objectRequestList = ObjectRequestList.createComplexRequestList(
            true,
            null,
            filterCriteria,
            [new Sorting("createdDate", false)],
            null);

        this.partnerCategoryWidget = WidgetFactory.createWidgetList(
            'cm.partner.category.chip.widget',
            "Partner Category",
            'chip',
            'list',
            'entity',
            'cm.PartnerCategory',
            'No Data',
            objectRequestList,
            new WidgetDataParam("linkedEntityTargetAttributeName", "categoryDtoName")
        );


    }

    refreshPartnerContactWidget() {

        const complexRequest = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("contact")
        );

        const filterCriteria = [FilterCriteria.create("partner", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            true,
            complexRequest,
            filterCriteria,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 10));


        //TODO: Daud: I changed it back to a LIST widget, as the ICON UI Component is not working!
        this.partnerContactWidget = WidgetFactory.createWidgetList(
            'cm.partner.contact.data.list.widget',
            "Contact",
            'list',
            'list',
            'entity',
            'cm.PartnerContact',
            'No Data',
            objectRequestList,
        );


    }

    refreshPartnerAddressWidget() {
        const complexRequest = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("address")
        );

        const filterCriteria = [FilterCriteria.create("partner", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)];

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            true,
            complexRequest,
            filterCriteria,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 10));


        this.partnerAddressWidget = WidgetFactory.createWidgetList(
            'cm.partner.address.data.icon.widget',
            "Address",
            'dataIcon',
            'list',
            'entity',
            'cm.PartnerAddress',
            'No Data',
            objectRequestList,
        );
    }

    refreshContractPartnerWidget() {
        /*
        this.contractPartnerWidget = WidgetFactory.createWidgetTransient(
            "partner.component.contract.partner.widget.chart",
            "Contract Partner",
            "history.chart",
            "transient",
            "transient",
            "cm.ContractPartner",
            this.contractPartners,
            WidgetDataParam.create("fieldCategory", "startDate"),
            WidgetDataParam.create("fieldCategoryCount", "startDate_count"),
            WidgetDataParam.create("historyPeriodMode", "day"),
            WidgetDataParam.create("fieldDate", "startDate"),
        );

        this.contractPartnerWidget = WidgetFactory.createWidgetList(
            "partner.component.contract.partner.widget.chart",
            "Contract Partner",
            "history.chart",
            "list",
            "entity.groupBy",
            "cm.ContractPartner",
            "Keine Anrufe vorhanden",
            ObjectRequestListGroupBy.create(
                true,
                [FilterCriteria.create('partner', FilterCriteria.cOperatorEqual, this.dto.id)],
                [],
                ["startDate"],
                [ new ObjectRequestListAttribute("startDate", "Anzahl", DtoListAttributeRequestAggregateEnum.count) ]),

            WidgetDataParam.create("fieldCategory", "startDate"),
            WidgetDataParam.create("fieldCategoryCount", "startDate_count"),
            WidgetDataParam.create("historyPeriodMode", "day"),
            WidgetDataParam.create("fieldDate", "startDate"),
        );
         */
    }


}
