import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {CustomerInsurableObjectDto} from "../../../cr/dto/customer-insurable-object.dto";
import {CustomerEntityAssignmentType} from "../../../cr/enum/customer-entity-assignment-type.enum";
import {EntityStatusEnum} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ContractInsurableObjectDto} from "../../../ci/dto/contract-insurable-object.dto";
import {WidgetToolbarCallbackInterface} from "@kvers/alpha-core-common";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {CmInsurableObjectWidgetStyle} from "./uiStyle/cm-insurable-object-widget-style";
import {MvsConfigService} from "@kvers/alpha-core-common";
import {ContractInsurableObjectService} from "../../../ci/service/api/contract-insurable-object.service";

@Component({
    selector: 'cm-create-insurable-object-others',
    templateUrl: './cm-create-insurable-object-others.component.html',
})
export class CmCreateInsurableObjectOthersComponent implements OnInit, OnChanges, OnDestroy {

    @Input() customerId: number;
    @Input() contractId: number;
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() highlightRelation: boolean;

    @Output() onChangedObject: EventEmitter<ObjectChangeInformation> = new EventEmitter<ObjectChangeInformation>();

    widgetObject: WidgetData;
    formWidget: WidgetData;
    contractInsurableObjectWidget: WidgetData;
    customerInsurableObjectWidget: WidgetData;

    cmInsurableObjectWidgetStyle: CmInsurableObjectWidgetStyle;

    contractInsurableObjectList: ContractInsurableObjectDto[];

    contextPersonId: number;
    insurableObjectId: number;
    activeStep: number;

    stepItems = [];

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    constructor(
        protected configService: MvsConfigService,
        protected contractInsurableObjectService: ContractInsurableObjectService
    ) {
    }

    ngOnInit(): void {
        this.initComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        if (this.highlightRelation) {
            this.handleContractInsurableObject();
        } else {
            this.refreshComponent();
        }

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        if (this.highlightRelation) {
            this.handleContractSelect();
        }

        this.resetComponent();

        this.retrieveImportVariables();

        this.stepItems = [
            {
                label: 'Überprüfen, ob vorhanden', id: 'check' // Check if exist
            },
            {
                label: 'Objekt erstellen', id: 'create' // Create New Insurable Object
            },
            {
                label: 'Kunden zuweisen', id: 'assignCustomer' // Create New Customer Insurable Object
            },
        ];

        if (this.contractId) {
            this.stepItems.push({label: "Vertragszuweisung", id: 'assignContract'});
        }

        this.gotoStep(0);

        this.initialized = true;
    }

    resetComponent() {
        this.widgetObject = undefined;
        this.formWidget = undefined;
        this.contractInsurableObjectWidget = undefined;
        this.insurableObjectId = undefined;
        this.contextPersonId = undefined;
    }

    retrieveImportVariables() {

        if (this.importObjectContext) {
            if (!this.customerId) {
                const customerEntry = this.importObjectContext.getEntry("cr.Customer");
                if (customerEntry) {
                    this.customerId = customerEntry.id;
                }
            }
            if (!this.contractId) {
                const contractEntry = this.importObjectContext.getEntry("cm.Contract");
                if (contractEntry) {
                    this.contractId = contractEntry.id;
                }
            }

        }
    }

    gotoStep(step: number) {

        switch (step) {
            case 0:             //Display Insurable Objects
                this.initStep0();

                break;
            case 1:             //Create new insurable object
                this.initStep1();

                break;
            case 2:             // create new customer insurable object
                this.initStep2();
                break;
            case 3:             // create contract insurable object (if needed)
                this.initStep3();
                break;

        }
        this.activeStep = step;

    }


    initStep0() {

        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('customer', FilterCriteria.cOperatorEqual, this.customerId)],
            []
        );

        dtoRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode('insurableObject')
        );

        this.widgetObject = WidgetFactory.createWidgetList(
            'cm.create.insurable.object.widget.show',
            'Insurable Objects',
            'list',
            'list',
            'entity',
            'cr.CustomerInsurableObject',
            'Keine Daten vorhanden!',
            dtoRequest
        );

    }

    handleCreateInsurableObject() {
        this.gotoStep(1);
    }

    initStep1() {

        this.formWidget = WidgetFactory.createWidgetList(
            'cm.create.insurable.object.create.form.widget',
            'Versicherbares Objekt',
            'object',
            'list',
            'entity',
            'ci.InsurableObject',
            'Keine Daten vorhanden!',
            null
        );
    }

    initStep2() {

        this.customerInsurableObjectWidget = WidgetFactory.createWidgetObjectWithCallback(
            'cm.create.insurable.object.widget',
            'Kundenversicherbares Objekt',
            'cr.CustomerInsurableObject',
            {
                onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    const dto = new CustomerInsurableObjectDto();
                    dto.customerDtoId = this.customerId;
                    dto.insurableObjectDtoId = this.insurableObjectId;
                    dto.assignmentType = CustomerEntityAssignmentType.main;
                    dto.entityStatus = EntityStatusEnum.active;

                    const formControlOverwrite = new MvsFormControlOverwrite();
                    formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);
                    formControlOverwrite.addField('insurableObjectDtoId', MvsFormFieldAccessEnum.HIDDEN);


                    let widgetToolbarCallbackInterface: WidgetToolbarCallbackInterface;
                    widgetToolbarCallbackInterface = {
                        dto: dto,
                        formControlOverwrite: formControlOverwrite
                    };
                    return widgetToolbarCallbackInterface;
                }
            }
        );
    }

    handleInsurableObjectSelect(objectIdentifier: ObjectIdentifierData) {

        if (this.contractId) {
            // create the contract assignment
            const customerInsurableObject = <CustomerInsurableObjectDto>objectIdentifier.data;
            this.insurableObjectId = customerInsurableObject.insurableObjectDtoId;
            this.gotoStep(3);
        } else {
            // we are finished as the related person actually exists!

        }

    }

    handleCreateObject(event: ObjectChangeInformation) {
        this.insurableObjectId = event.after.id;
        this.gotoStep(2);
    }

    handleContractInsurableObjectCreated(event: ObjectChangeInformation) {
        this.handleChangedObject(event);
        this.handleContractInsurableObject();
    }

    handleCustomerInsurableObjectCreated(event: ObjectChangeInformation) {
        if (!this.contractId) {
            this.handleChangedObject(event);
            return;
        }

        //there is another step to assign the object to a contract (ContractInsurableObject)
        this.gotoStep(3);
    }

    initStep3() {
        this.contractInsurableObjectWidget = WidgetFactory.createWidgetObjectWithCallback(
            "cm.create.insurable.object.create.contract.widget",
            "Zuweisung Vertrag",
            "ci.ContractInsurableObject",
            {
                onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    const dto = new ContractInsurableObjectDto();
                    dto.contractDtoId = this.contractId;
                    dto.insurableObjectDtoId = this.insurableObjectId;
                    dto.entityStatus = EntityStatusEnum.active;

                    const formControlOverwrite = new MvsFormControlOverwrite();
                    formControlOverwrite.addField('contractDtoId', MvsFormFieldAccessEnum.HIDDEN);
                    formControlOverwrite.addField('insurableObjectDtoId', MvsFormFieldAccessEnum.HIDDEN);

                    return {
                        dto: dto,
                        formControlOverwrite: formControlOverwrite
                    };

                }
            }
        );
    }

    handleContractInsurableObject() {

        this.resetComponent();

        const filterCriteria = [FilterCriteria.create('contract', FilterCriteria.cOperatorEqual, this.contractId)];

        const objectRequestList = new ObjectRequestList(false, filterCriteria, null);

        this.contractInsurableObjectService.list(objectRequestList).subscribe(res => {
            this.contractInsurableObjectList = res.entries;

            this.refreshComponent();
        })
    }

    handleContractSelect() {

        if (!this.cmInsurableObjectWidgetStyle) {
            this.cmInsurableObjectWidgetStyle = new CmInsurableObjectWidgetStyle(this.configService, this.contractInsurableObjectList);
        }

        this.cmInsurableObjectWidgetStyle.addSelectedPartner(this.contractInsurableObjectList);
        this.cmInsurableObjectWidgetStyle = this.cmInsurableObjectWidgetStyle.clone();
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"] || changes["contractId"] || changes["customerId"]) {
            this.initComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    handleChangedObject(event: ObjectChangeInformation) {
        this.onChangedObject.emit(event);
    }
}
