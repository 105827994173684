<div class="bg-white">
    <mvs-config-header header="Logic Configuration" [categoryTypeMode]="'ticket'"
                       icon="fa-light fa-cards-blank">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Groups" leftIcon="fa-thin fa-layer-group"></p-tabPanel>
        <p-tabPanel header="Logics" leftIcon="fa-solid fa-diagram-venn"></p-tabPanel>
        <p-tabPanel header="Test Cases" leftIcon="fa-solid fa-diagram-venn"></p-tabPanel>
        <p-tabPanel header="Calculation Logic Type" leftIcon="fa-solid fa-diagram-venn"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
                <mvs-widget
                        *ngIf="lgGroupWidget"
                        [widgetData]="lgGroupWidget"
                ></mvs-widget>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">

                <mvs-widget
                        *ngIf="lgLogicWidget"
                        [widgetData]="lgLogicWidget"
                        (dblclick)="openObjectInNewTab($event)"
                        (onObjectSelect)="handleLogicSelect($event)"
                ></mvs-widget>

    </ng-container>

    <ng-container *ngIf="activeIndex==2">

        <mvs-widget
                *ngIf="lgLogicTestCaseWidget"
                [widgetData]="lgLogicTestCaseWidget"
        ></mvs-widget>

        <mvs-widget
                *ngIf="lgLogicTestCaseImportVariableWidget"
                [widgetData]="lgLogicTestCaseImportVariableWidget"
        ></mvs-widget>
    </ng-container>

    <ng-container *ngIf="activeIndex==3">

        <mvs-widget
                *ngIf="lgCalculationLogicTypeWidget"
                [widgetData]="lgCalculationLogicTypeWidget"
                (dblclick)="openCalculationLogicInNewTab($event)"
                (onObjectSelect)="handleLogicSelect($event)"
        ></mvs-widget>

    </ng-container>


</div>