import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MvsObjectService, ObjectChangeInformation} from "@kvers/alpha-core-common";
import {Observable, Subject, Subscription} from "rxjs";
import {CustomerHostedRuntimeDto} from "../dto/customer-household-runtime.dto";
import {CustomerService} from "../api/customer.service";

@Injectable()
export class CustomerPageHouseholdPersonService implements OnDestroy {

    private customerId?: number;
    private busy: boolean = false;
    private triedDuringBusy: boolean = false;


    private householdSubject = new Subject<CustomerHostedRuntimeDto>();
    private objectChangeSubscription: Subscription;

    constructor(protected http: HttpClient, protected objectService: MvsObjectService, protected customerService: CustomerService) {

        this.objectChangeSubscription = this.objectService.objectChangeEvent.subscribe(change => {
            if (change.objectType === 'pm.Person' || change.objectType === 'pm.PersonRelationPerson'
                || change.objectType === 'pm.Household' || change.objectType === 'pm.HouseholdPerson') {

                this.refreshHouseholdInformation();
            }
        });

    }

    /**
     * Set customer ID.
     * @param customerId
     */
    public setCustomerId(customerId: number): void {

        if (this.customerId != customerId) {
            this.customerId = customerId;

            // retrieve the information
            this.refreshHouseholdInformation();
        }

    }

    protected refreshHouseholdInformation() {

        // we don't do anything if the customerId is missing
        if (this.customerId == null) {
            return;
        }

        if (this.busy == true) {
            this.triedDuringBusy = true; // tried during busy state
            return;
        }

        this.busy = true;

        this.customerService.getHousehold(this.customerId).subscribe(value => {
            this.busy = false;
            this.householdSubject.next(value);

            // refresh again if something changed in the meantime and the service tried to refresh it
            if (this.triedDuringBusy) {
                this.triedDuringBusy = false;
                this.refreshHouseholdInformation();
            }

        },error => {
            this.busy = false;
            this.householdSubject.next(null); // try to inform the users of the issue!
        });
    }


    /**
     * Listen for changes to pm.Person, pm.PersonRelationPerson, pm.Household, pm.HouseholdPerson
     */
    getHouseholdSubject(): Subject<CustomerHostedRuntimeDto> {
        return this.householdSubject;
    }

    ngOnDestroy() {
        this.objectChangeSubscription.unsubscribe();
        this.householdSubject.unsubscribe();
        // this.householdSubject.complete();
    }


}