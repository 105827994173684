import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,} from "@angular/core";
import {
    FilterCriteria,
    MvsCoreService, MvsMessageService,
    ObjectBaseComponent,
    ObjectRequestList, ObserverService, PagingDto,
    WidgetData,
    WidgetHelperButton
} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ReportService} from "../../service/api/report.service";

@Component({
    selector: "rp-report-component",
    templateUrl: "./rp-report.component.html",
    styleUrls: ["./rp-report.component.scss"],
})
export class RpReportComponent extends ObjectBaseComponent
    implements OnInit, OnChanges {

    initialized: boolean;
    reportWidgetData: WidgetData;
    toolbarButtons: WidgetHelperButton[];

    @Input() module: string;
    @Input() categoryId: number;
    @Input() reportId: number;
    @Input() refreshTrigger: number | string | boolean;
    @Input() roleBasedFilter: FilterCriteria[];

    @Output() onDataLoaded = new EventEmitter<void>();

    filterCriteria: FilterCriteria[];

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected observerService: ObserverService,
        protected reportService: ReportService,
    ) {

        super(coreService, messageService, confirmationService, observerService);

    }

    ngOnInit() {

        if (this.params) {
            this.module = this.params.module;
            this.reportId = Number(this.params.report);
            this.filterCriteria = this.params.filters;
        }

        this.initToolbarButtons();

        this.refresh();
        this.initialized = true;
    }

    initToolbarButtons() {
        this.toolbarButtons = [{
            icon: 'fa-solid fa-download',
            label: '',
            display: true,
            action: 'download',
            tooltip: 'download'
        }];
    }

    /**
     * On Object Change.
     */
    refresh() {

        let filter = this.filterCriteria;

        if (this.roleBasedFilter) {
            if (!filter) {
                filter = [];
            }
            filter.push(...this.roleBasedFilter);
        }

        const objectRequest = ObjectRequestList.createWithPaging(true, filter, [], PagingDto.create(0, 80));
        const dataProviderObject = `${this.module}.${this.categoryId}.${this.reportId}`

        this.reportWidgetData = WidgetFactory.createWidgetTableEntity(
            `rp.report.${this.module}.${this.reportId}`,
            'Report',
            dataProviderObject,
            'No Record',
            objectRequest);

        this.reportWidgetData.dataSource = "report";

    }

    handleToolbarClick(event: WidgetHelperButton) {

        if (this.busy) {
            return;
        }
        this.busy = true;


        if (event.action === 'download') {

            this.reportWidgetData;

            this.toolbarButtons[0].icon = 'fa-sharp-duotone fa-solid fa-loader fa-spin';
            // const objectRequest = ObjectRequestList.createBasic(true, [], []);
            const objectRequest = this.reportWidgetData.dataProviderListRequest;

            this.reportService.download(this.module, this.reportId, objectRequest)
                .subscribe((blob: Blob) => {

                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = `report_${this.module}_${this.reportId}.xlsx`;
                    link.click();

                    // revoke the URL to free memory
                    URL.revokeObjectURL(blobUrl);


                    this.toolbarButtons[0].icon = 'fa-solid fa-download';
                    this.busy = false;
                }, error => {

                    this.toolbarButtons[0].icon = 'fa-solid fa-download';
                    this.busy = false;
                });
        }
    }


    handleDataLoad(event: boolean) {
        this.onDataLoaded.emit();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (!this.initialized) {
            return;
        }
        this.refresh();
    }

}

