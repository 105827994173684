import { Component, OnInit } from '@angular/core';
import {PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {AppointmentTypeDto} from "../../../dto/appointment-type.dto";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {MsBookingTypeDto} from "../../../dto/ms-booking-type.dto";

@Component({
  selector: 'mvs-as-appointment-type-page',
  templateUrl: './config.page.html',
  styleUrls: ['./config.page.scss']
})
export class ConfigPage extends PageComponent implements OnInit {

  defaultLabel: string = "Termin und Microsoft Bookings Konfiguration";

  widgetAppointmentGroup: WidgetData;
  widgetAppointmentType: WidgetData;
  widgetAppointmentTypeScheduleType: WidgetData;
  widgetScheduleType: WidgetData;
  widgetMsBookingType: WidgetData;
  widgetMsCalendarType: WidgetData;
  widgetAppointmentResultType: WidgetData;
  selectedAppointmentType: AppointmentTypeDto;

  selectedMsBookingType: MsBookingTypeDto;

  activeIndex: number = 0;

  ngOnInit(): void {
    super.ngOnInit();

    this.initComponent();
  }

  initComponent(): void {

    this.widgetAppointmentGroup = WidgetFactory.createWidgetList(
        "as.config.appointment.group",
        "Termingruppen",
        "list",
        "list",
        "entity",
        "as.AppointmentGroup",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetAppointmentType = WidgetFactory.createWidgetList(
        "as.config.appointment.type",
        "Termintypen",
        "list",
        "list",
        "entity",
        "as.AppointmentType",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetScheduleType = WidgetFactory.createWidgetList(
        "as.config.schedule.type",
        "Buchungstypen",
        "list",
        "list",
        "entity",
        "as.ScheduleType",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetMsBookingType = WidgetFactory.createWidgetList(
        "as.config.ms.booking.type",
        "Termintypen",
        "list",
        "list",
        "entity",
        "as.MsBookingType",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetMsCalendarType = WidgetFactory.createWidgetList(
        "as.config.ms.calendar.type",
        "Microsoft Kalender",
        "list",
        "list",
        "entity",
        "as.MsCalendarType",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.widgetAppointmentResultType = WidgetFactory.createWidgetList(
        "as.config.appointment.result.type.simple",
        "Temin Ergebnisarten",
        "list",
        "list",
        "entity",
        "as.AppointmentResultType",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(true, [], [new Sorting("id", false)])
    );

    this.initialized = true;

  }

  onObjectSelect(event: ObjectIdentifierData) {
    this.selectedAppointmentType = event.data;
    this.refreshAppointmentTypeWidgets();
  }

  onMsBookingTypeSelect(event: ObjectIdentifierData) {
    this.selectedMsBookingType = event.data;
    this.refreshMsBookingType();
  }

  refreshMsBookingType() {
    // nothing to do -> only components
  }

  refreshAppointmentTypeWidgets() {

    this.widgetAppointmentTypeScheduleType = WidgetFactory.createWidgetList(
        "as.config.appointment.type.schedule.type",
        "Zugewiesene Buchungsarten",
        "list",
        "list",
        "entity",
        "as.AppointmentTypeScheduleType",
        "Keine Daten vorhanden",
        ObjectRequestList.createBasic(
            true,
            [FilterCriteria.create("appointmentType", FilterCriteria.cOperatorEqual, this.selectedAppointmentType.id)],
            [new Sorting("id", false)]
        )
    );

  }


}
