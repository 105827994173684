import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {
    DtoDetail,
    DtoList,
    FilterCriteria,
    MvsConfigService,
    MvsCoreService,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    MvsMessageService,
    ObjectBaseComponent,
    ObjectChangeInformation,
    ObjectIdentifier,
    ObjectIdentifierData,
    ObjectRequestList,
    Sorting,
    WidgetData,
    WidgetToolbarCallbackInterface
} from "@kvers/alpha-core-common";
import {PmPersonDto} from "../../../../dto/pm-person.dto";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationService} from "primeng/api";
import {HouseholdPersonService} from "../../../../../pm/service/api/household-person.service";
import {HouseholdService} from "../../../../../pm/service/api/household.service";
import {Observable} from "rxjs";
import {FlexibleSearchDataEvent, WidgetFactory} from "@kvers/alpha-ui";
import {PersonRelationPersonDto} from "../../../../../pm/dto/person-relation-person.dto";
import {HouseholdPersonDto} from "../../../../../pm/dto/household-person.dto";
import {CrCustomerEmergencyContactDto} from "../../../../../cm/dto/customer-emergency-contact.dto";
import {HouseholdDto} from "../../../../../pm/dto/household.dto";
import {CustomerService} from "../../../../service/api/customer.service";
import {ObserverService} from "@kvers/alpha-core-common";


@Component({
    selector: 'cr-create-emergency-contact',
    templateUrl: './cr-create-emergency-contact.component.html',
})
export class CrCreateEmergencyContactComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    @Input() customerId: number;
    @Input() customerPersonId: number;

    @Output() onObjectSelected: EventEmitter<ObjectIdentifier> = new EventEmitter<ObjectIdentifier>();

    stepItems = [];
    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    activeStep: number;

    selectedCustomerRelatedPersonId: number;
    selectedPersonId: number;
    personRelationCreated: boolean;

    customerRelatedPersonWidget: WidgetData;
    personRelationPersonForm: WidgetData;
    customerEmergencyContactForm: WidgetData;
    personFormWidget: WidgetData;

    flexiblePersonSearchDto: PmPersonDto;

    constructor(protected route: ActivatedRoute,
                protected coreService: MvsCoreService,
                protected customerService: CustomerService,
                protected confirmationService: ConfirmationService,
                protected messageService: MvsMessageService,
                protected configService: MvsConfigService,
                protected householdPersonService: HouseholdPersonService,
                protected householdService: HouseholdService,
                protected override observerService: ObserverService
    ) {
        super(coreService, messageService, confirmationService, observerService);

        // this component is only used to create
        this.createOnly = true;
    }


    protected getRetrieveObjectObservable(): Observable<any> {
        return null;
    }

    ngOnInit(): void {
        this.initComponent();
    }


    /**
     * Initialize Component.
     */
    initComponent() {
            this.refreshComponent();
    }

    resetComponent() {

        this.selectedCustomerRelatedPersonId = undefined;
        this.selectedPersonId = undefined;
        this.customerRelatedPersonWidget = undefined;
        this.personRelationPersonForm = undefined;
        this.personFormWidget = undefined;
        this.flexiblePersonSearchDto = undefined;
    }

    /**
     * Retrieve import variables either from the direct variables customerId and contractId or from the ImportObjectContext.
     */
    retrieveImportVariables() {
        debugger
        if (this.importObjectContext) {
            // if (!this.customerId) {
            //     const customerEntry = this.importObjectContext.getEntry("cr.Customer");
            //     if (customerEntry) {
            //         this.customerId = customerEntry.id;
            //     }
            // }
            if (!this.customerId) {
                const customerEntry = this.importObjectContext.getEntry("cr.Customer");
                if (customerEntry) {
                    this.customerId = customerEntry.id;
                }
            }

            this.customerService.get(this.customerId).subscribe(res => {
                this.customerPersonId = res['personDtoId'];
                this.gotoStep(0);
            })

            // const personEntry = this.importObjectContext.getEntry("pm.Person");
            //
            // if (personEntry) {
            // }

        }

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.resetComponent();

        this.retrieveImportVariables();

        this.stepItems = [
            {
                label: 'Überprüfen, ob vorhanden', id: 'check'   // Check if exist
            },
            {
                label: 'Person suchen', id: 'search'  // Select Person
            },
            {
                label: 'Person erstellen', id: 'create'  // Create New Person
            },
            {
                label: 'Kunden zuweisen', id: 'assignCustomer'  // Create Customer Relation
            },
            {
                label: 'Notfallkontakt anlegen', id: 'createEmergencyContact'
            }
        ];


        this.initialized = true;

    }


    /**
     * Initialize Step 1)
     * Check whether Customer Related Person already exists.
     */
    initStep0() {

        this.customerRelatedPersonWidget = WidgetFactory.createWidgetList(
            'cm.create.customer.relation.person.basic.customer.related.persons',
            'Personen im Zusammenhang mit Kunden',
            'list',
            'list',
            'entity',
            'pm.PersonRelationPerson',
            'Keine Personen vorhanden',
            ObjectRequestList.createBasic(
                true,
                [FilterCriteria.create("personA", FilterCriteria.cOperatorEqual, this.customerPersonId)],
                [new Sorting("createdDate", true)]
            )
        );

    }

    /**
     * A related person was selected, therefore we need to decide how to continue.
     */
    handleStep0RelatedPersonSelect(objectIdentifier: ObjectIdentifierData) {
        debugger
        this.selectedPersonId = objectIdentifier.data['personBDtoId'];
        this.gotoStep(4);

    }

    gotoStep(step: number) {

        switch (step) {
            case 0:             // search customer persons
                this.initStep0();

                break;
            case 1:             // search persons
                this.initStep1();

                break;
            case 2:             // create person
                this.initStep2();
                break;

            case 3:             // create customer - person assignment
                this.initStep3();
                break;

            case 4:             // create customer - person assignment
                this.initStep4();
                break;

        }
        this.activeStep = step;

    }

    /**
     * Initialize Step 2)
     * Within this step the user needs to search for a person.
     */
    initStep1() {
        // no initialization required
    }


    /**
     * User has selected a person.
     * @param event
     */
    handlePersonSelectStep1(event: FlexibleSearchDataEvent) {
        this.selectedPersonId = event.id;
    }

    /**
     * Continue with step 3.
     */
    handleContinueWithSelectedPersonStep1() {
        if (this.selectedPersonId) {
            this.gotoStep(3);
        }
    }

    /**
     * Store search result.
     * @param event
     */
    handlePersonSearchStep1(event: DtoDetail) {
        this.flexiblePersonSearchDto = <PmPersonDto>event;
    }

    /**
     * User requests to create a new person.
     */
    handleCreateNewPersonStep1() {

        // only allow to move to the next step, if user actually searched
        if (!this.flexiblePersonSearchDto) {
            this.confirmationService.confirm({
                header: 'Alert',
                message: 'Bitte suchen Sie nach der Person, bevor Sie eine neue Person erstellen!',
                acceptLabel: 'Okay',
                rejectVisible: false, // Hide the cancel button
                accept: () => {
                }
            });

            return;
        }

        this.gotoStep(2);

    }


    /**
     * Step 3) Create a new person.
     */
    initStep2() {

        this.personFormWidget = WidgetFactory.createWidgetObjectWithCallback(
            "cm.create.customer.related.person.object.create.person.form.widget",
            "Person erstellen",
            "pm.Person",
            {
                onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    return {dto: this.flexiblePersonSearchDto};
                }
            }
        );

    }

    /**
     * Person was successfully created so we can move to the next step.
     * @param objectChangeInformation
     */
    handlePersonCreateStep2(objectChangeInformation: ObjectChangeInformation) {
        this.selectedPersonId = objectChangeInformation.after.id;
        this.gotoStep(3);
    }

    initStep3() {

        this.personRelationPersonForm = new WidgetData();
        this.personRelationPersonForm.idAlias = "cm.create.person.relation.person.object.create.form.widget";
        this.personRelationPersonForm.name = "Zuweisung Kunde";
        this.personRelationPersonForm.uiComponent = "object";
        this.personRelationPersonForm.dataProvider = "list";
        this.personRelationPersonForm.dataSource = "entity";
        this.personRelationPersonForm.dataProviderObject = "pm.PersonRelationPerson";

        this.personRelationPersonForm.functionCallbacks = {
            onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                const dto = new PersonRelationPersonDto();
                dto.personADtoId = this.customerPersonId;
                dto.personBDtoId = this.selectedPersonId;

                const formControlOverwrite = new MvsFormControlOverwrite();
                formControlOverwrite.addField('personADtoId', MvsFormFieldAccessEnum.READ);
                formControlOverwrite.addField('personBDtoId', MvsFormFieldAccessEnum.READ);

                let widgetToolbarCallbackInterface: WidgetToolbarCallbackInterface;
                widgetToolbarCallbackInterface = {
                    dto: dto,
                    formControlOverwrite: formControlOverwrite
                };
                return widgetToolbarCallbackInterface;
            }
        };

    }

    handleSameHousehold() {
        const request = new ObjectRequestList(
            false,
            [FilterCriteria.create('person', FilterCriteria.cOperatorEqual, this.customerPersonId)],
            []
        );

        this.householdPersonService.list(request).subscribe((res: DtoList<HouseholdPersonDto>) => {
            if (!res.entries || !res.entries.length) {
                // create a new household
                this.handleCreateNewHousehold(this.customerPersonId, this.selectedPersonId);

                this.gotoStep(4);
            } else {
                // update household person
                const householdId = res.entries[0].householdDtoId;
                this.updateExistingHousehold(householdId);
            }
        })
    }

    updateExistingHousehold(householdId: number) {
        this.createHouseholdPerson(this.selectedPersonId, householdId);
        this.gotoStep(4);
    }

    handleCreateRelatedPersonStep3(objectChangeInformation: ObjectChangeInformation) {
        this.personRelationCreated = true;
    }

    personCreationFinished(objectIdentifier: ObjectIdentifier) {
        this.onObjectSelected.emit(objectIdentifier);
        this.refreshComponent();
    }


    initStep4() {
        this.initEmergencyContactForm();
    }

    initEmergencyContactForm() {
        debugger
        this.customerEmergencyContactForm = WidgetFactory.createWidgetObjectWithCallback(
            "cm.create.emergency.contact.form.emergency.contact",
            "Notfallkontakt erfassen",
            "cr.CustomerEmergencyContact",
            {
                onFunctionObjectDefaultNew: (widgetData: WidgetData): WidgetToolbarCallbackInterface => {
                    const dto = new CrCustomerEmergencyContactDto();
                    dto.customerDtoId = this.customerId;
                    dto.personDtoId = this.selectedPersonId;

                    const formControlOverwrite = new MvsFormControlOverwrite();
                    formControlOverwrite.addField('personDtoId', MvsFormFieldAccessEnum.READ);
                    formControlOverwrite.addField('customerDtoId', MvsFormFieldAccessEnum.HIDDEN);

                    return {
                        dto: dto,
                        formControlOverwrite: formControlOverwrite
                    };

                }
            }
        );
    }

    handleContactEmergencyContactCreated(event: ObjectChangeInformation) {
        this.personCreationFinished(new ObjectIdentifier('cr.CustomerEmergencyContact', event.after.id));
    }

    handleCompleteWithoutCreatingHousehold() {
        this.gotoStep(4);

    }

    handleCreateNewHousehold(personA: number, personB: number) {
        const dto = new HouseholdDto();
        this.householdService.create(dto).subscribe(res => {
            this.createHouseholdPerson(personA, res.id);
            this.createHouseholdPerson(personB, res.id);
        });
    }

    createHouseholdPerson(personId: number, householdId: number) {
        const dto = new HouseholdPersonDto();
        dto.householdDtoId = householdId;
        dto.personDtoId = personId;
        dto.endDate = new Date();

        this.householdPersonService.create(dto).subscribe(res => {
            console.log(personId + ' is created');
        });
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"] || changes["contractId"] || changes["customerId"]) {
            this.initComponent();
        }

        if (changes["importObjectContext"]) {
            this.initComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
