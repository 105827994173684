@if(initialized) {
<div class="grid">
    <div class="col-12">
        <mvs-widget
                *ngIf="provisionTemplateTypeWidget"
                [widgetData]="provisionTemplateTypeWidget"
        ></mvs-widget>
    </div>

    <div class="col-12">
        <mvs-widget
                *ngIf="provisionTemplateTypeFieldWidget"
                [widgetData]="provisionTemplateTypeFieldWidget"
        ></mvs-widget>
    </div>

    <div class="col-12">
        <mvs-widget
                *ngIf="provisionTemplateTypeMappingWidget"
                [widgetData]="provisionTemplateTypeMappingWidget"
        ></mvs-widget>
    </div>
</div>
}