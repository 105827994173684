import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MvsCoreService, ObjectRequestList} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {ConfirmationService} from "primeng/api";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationProviderGeneric} from "@kvers/alpha-ui";
import {CustomerService} from "../../service/api/customer.service";
import {CustomerDto} from "../../dto/customer.dto";
import {
    CrCustomerObjectViewTypeUiEnum
} from "../../page/object/cr-customer-object-page/data/cr-customer-object-view-type-ui.enum";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {
    CrCustomerRelatedPersonWidgetStyle
} from "../cr-customer-related-persons/uiStyle/cr-customer-related-person-widget-style";
import {WfProcessService} from "../../../wf/service/api/wf-process.service";
import {MvsObjectCount} from "../../../core/workflow/component/mvs-workflow/mvs-workflow.component";
import {
    PmPersonRelationPersonWidgetStyle
} from "../../../pm/component/pm-household/uiStyle/pm-person-relation-person-widget-style";
import {HouseholdPersonDto} from "../../../pm/dto/household-person.dto";
import {ObserverService} from "@kvers/alpha-core-common";
import {CustomerPageHouseholdPersonService} from "../../service/ui/customer-page-household-person.service";

@Component({
    selector: 'mvs-cr-customer',
    templateUrl: './cr-customer.component.html',
    styleUrls: ['./cr-customer.component.scss'],
    providers: [CustomerPageHouseholdPersonService],
})
export class CrCustomerComponent
    extends ObjectBaseComponent implements OnChanges {

    @Input() viewType: CrCustomerObjectViewTypeUiEnum = CrCustomerObjectViewTypeUiEnum.standard;
    @Input() changedFilter: FilterCriteria;
    @Input() customerRelatedPersonWidgetStyle: CrCustomerRelatedPersonWidgetStyle;
    @Input() personRelationPersonWidgetStyle: PmPersonRelationPersonWidgetStyle;
    @Input() householdPersons: HouseholdPersonDto[];

    @Output() onChangeCustomerFilter: EventEmitter<FilterCriteria[]> = new EventEmitter<FilterCriteria[]>();
    @Output() onTicketsCount: EventEmitter<MvsObjectCount> = new EventEmitter<MvsObjectCount>();
    @Output() onWorkflowsCount: EventEmitter<MvsObjectCount> = new EventEmitter<MvsObjectCount>();

    dto: CustomerDto;
    ticketIndicatorFilterCriteria: FilterCriteria[];
    workflowObjectRequestList: ObjectRequestList;

    constructor(
        protected coreService: MvsCoreService,
        protected confirmationService: ConfirmationService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService,
        protected customerService: CustomerService,
        protected processService: WfProcessService,
        protected override observerService: ObserverService,
        protected customerPageHouseholdPersonService: CustomerPageHouseholdPersonService
    ) {

        super(coreService, messageService, confirmationService, observerService);

    }

    /**
     * Object Changed
     */
    onObjectChanged() {
        super.onObjectChanged();
    }


    ngOnInit(): void {
        super.ngOnInit();

        this.navigationService.setNavigationProvider(new MvsObjectNavigationProviderGeneric());
        this.setGeneralTabsData();

    }

    setGeneralTabsData() {

        this.ticketIndicatorFilterCriteria = [
            FilterCriteria.create("referenceCustomer", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
        ];

        this.workflowObjectRequestList = this.processService.getRequestForProcessForObjectViaAlias(
            this.objectIdentifier.objectType,
            this.objectIdentifier.objectId,
            true
        );
    }

    ngOnChanges(changes: SimpleChanges): void {


        super.ngOnChanges(changes);

        if (!this.initialized) {
            return;
        }

        if (changes['objectIdentifier']) {
            this.dto = null;
            this.refreshObject(false);
        }

        if (changes["viewType"] || changes["customerFilter"]) {
            this.refreshObject(false);
        }

    }

    handleCustomerFilter(event: FilterCriteria[]) {
        this.onChangeCustomerFilter.emit(event);
    }

    handleTicketsCount(event: MvsObjectCount) {
        this.onTicketsCount.emit(event);
    }

    handleWorkflowsCount(event: MvsObjectCount) {
        this.onWorkflowsCount.emit(event);
    }

    protected readonly CrCustomerObjectViewTypeUiEnum = CrCustomerObjectViewTypeUiEnum;
}
