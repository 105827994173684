import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoImportObjectContext, DtoImportObjectContextEntry,
    FilterCriteria,
    ObjectBaseComponent,
    ObjectRequestList,
    WidgetData,
    WidgetDataParam
} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";


@Component({
    selector: 'object-ticket-group-filter',
    templateUrl: './object-ticket-group-filter.component.html',
})
export class ObjectTicketGroupFilterComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    selectionTicketGroupWidget: WidgetData;
    selectionTicketTypeWidget: WidgetData;
    selectionAgentPoolWidget: WidgetData;
    selectionAgentWidget: WidgetData;
    restrictAgentPoolWidget: WidgetData;
    restrictAgentWidget: WidgetData;
    selectionStatusWidget: WidgetData;
    selectionUrgencyWidget: WidgetData;
    ticketGroupFilterWidget: WidgetData;

    /**
     * On Object Change.
     */
    onObjectChanged() {
        this.importObjectContext = new DtoImportObjectContext([new DtoImportObjectContextEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId)]);
        this.refreshWidgets();
        this.initialized = true;
    }

    refreshWidgets() {
        this.refreshSelectionAgentPoolWidget();
        this.refreshSelectionTicketTypeWidget();
        this.refreshSelectionTicketGroupWidget();
        this.refreshSelectionAgentWidget();
        this.refreshRestrictionAgentPoolWidget();
        this.refreshRestrictionAgentWidget();
        this.refreshSelectionStatusWidget();
        this.refreshSelectionUrgencyWidget();
        this.refreshTicketGroupWidget();
    }

    refreshTicketGroupWidget() {
        this.ticketGroupFilterWidget = WidgetFactory.createWidgetList(
            "object.ticket.group.config.ticket.group.filter.simple",
            "Ticket Group Filter",
            "list",
            "list",
            "entity",
            "tm.TicketGroupFilter",
            'No ticket group filter found!',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
                []
            )
        );
    }

    refreshSelectionTicketGroupWidget() {
        this.selectionTicketGroupWidget = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.selection.ticket.group',
            "Selection Ticket Group",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterSelectionTicketGroup',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter',FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                []),
            new WidgetDataParam("linkedEntityTargetAttributeName", "ticketGroupDtoName")
        );
    }

    refreshSelectionTicketTypeWidget() {
        this.selectionTicketTypeWidget = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.selection.ticket.type',
            "Selection Ticket Type",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterSelectionTicketType',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter',FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                []
            ),
            new WidgetDataParam("linkedEntityTargetAttributeName", "ticketTypeDtoName")
        );
    }

    refreshSelectionAgentPoolWidget() {
        this.selectionAgentPoolWidget = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.selection.agent.pool',
            "Selection Agent Pool",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterSelectionAgentPool',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter',FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                []),
            new WidgetDataParam("linkedEntityTargetAttributeName", "agentPoolDtoName")
        );
    }

    refreshSelectionAgentWidget() {
        this.selectionAgentWidget = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.selection.agent',
            "Selection Agent",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterSelectionAgent',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter',FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                []),
            new WidgetDataParam("linkedEntityTargetAttributeName", "agentDtoName")
        );
    }

    refreshRestrictionAgentPoolWidget() {
        this.restrictAgentPoolWidget = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.restriction.agent.pool',
            "Restriction Agent Pool",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterRestrictionAgentPool',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter',FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                []),
            new WidgetDataParam("linkedEntityTargetAttributeName", "agentPoolDtoName")
        );
    }

    refreshRestrictionAgentWidget() {
        this.restrictAgentWidget = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.restriction.agent',
            "Restriction Agent",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterRestrictionAgent',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter',FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                []),
            new WidgetDataParam("linkedEntityTargetAttributeName", "agentDtoName")
        );
    }

    refreshSelectionStatusWidget() {
        this.selectionStatusWidget = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.selection.status',
            "Selection Status",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterSelectionStatus',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter',FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                []),
            new WidgetDataParam("linkedEntityTargetAttributeName", "status")
        );
    }

    refreshSelectionUrgencyWidget() {
        this.selectionUrgencyWidget = WidgetFactory.createWidgetList(
            'object.ticket.group.filter.selection.urgency',
            "Selection Urgency",
            'chip',
            'list',
            'entity',
            'tm.TicketGroupFilterSelectionUrgency',
            'No Data',
            new ObjectRequestList(
                true,
                [FilterCriteria.create('ticketGroupFilter',FilterCriteria.cOperatorEqual,this.objectIdentifier.objectId)],
                []),
            new WidgetDataParam("linkedEntityTargetAttributeName", "urgency")
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
