import { NgModule } from '@angular/core';
import {CoreModule} from "../core/core.module";
import {RpConfigPage} from "./page/overview/config/rp-config.page";
import {RpReportObjectComponent} from "./component/rp-report/rp-report-object.component";
import {RpTestConfigPage} from "./page/overview/test-config/rp-test-config.page";
import {BlockUIModule} from 'primeng/blockui';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {RpConfigNewPage} from "./page/overview/new-config/rp-config-new.page";
import {RpReportComponent} from "./component/rp-report-component/rp-report.component";
import {RpReportPage} from "./page/report-page/rp-report.page";
import {AmModule} from "../am/am.module";
import {RpReportAgentFavoriteComponent} from "./component/rp-report-agent-favorite/rp-report-agent-favorite.component";


@NgModule({
  declarations: [
    RpConfigPage,
    RpReportObjectComponent,
    RpTestConfigPage,
    RpConfigNewPage,
    RpReportComponent,
    RpReportPage,
    RpReportAgentFavoriteComponent,
  ],
  exports:[
    RpConfigPage,
    RpReportObjectComponent,
    RpTestConfigPage,
    RpConfigNewPage,
    RpReportComponent,
    RpReportPage,
    RpReportAgentFavoriteComponent,
  ],
  imports: [
    CoreModule,
    BlockUIModule,
    ProgressSpinnerModule,
    AmModule
  ]
})
export class RpModule { }



