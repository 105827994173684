import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FilterCriteria, ObjectBaseComponent, ObjectRequestList, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../core/helper/widget-function-call-back-create";


@Component({
    selector: 'provision-type-object-component',
    templateUrl: './provision-type-object.component.html',
})
export class ProvisionTypeObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {



    /**
     * On Object Change.
     */
    onObjectChanged() {
        this.refreshWidgets();
    }

    refreshWidgets() {
    }



    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
