import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import {
    DtoDetail, DtoList, MvsMessageService,
    MvsObjectService,
    ObjectIdentifier,
    ObjectTypeService,
    UiPageUserService, UiPageVisitRequestDto
} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {TicketService} from "../../service/api/ticket.service";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {forkJoin, Observable, of} from "rxjs";
import {UiPageVisitResponseDto} from "../../../ui/dto/ui-page-visit-response.dto";
import {catchError, map} from "rxjs/operators";
import {DmDocumentService} from "../../../dm/service/api/dm-document.service";
import {TicketCommentService} from "../../service/api/ticket-comment.service";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";

//TODO: We are not using LABELS as KEYS!!!
export enum CommentHistoryEnum {
    'All Activity' = 1,
    'Comment',
    'History'
}

@Component({
    selector: 'mvs-ticket-history',
    templateUrl: './ticket-comment-history.component.html',
    styleUrls: ['./ticket-comment-history.component.scss']
})
export class TicketHistoryComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() documentTypeId: number;
    @Input() selectedAction: CommentHistoryEnum = CommentHistoryEnum["All Activity"];
    historyList: DtoDetail[] = [];
    first = 0;
    rows = 5;
    enumCommentHistory = CommentHistoryEnum;
    ticketObjectTypeId: number;
    selectedObject: DtoDetail;


    constructor(protected ticketService: TicketService,
                protected documentService: DmDocumentService,
                protected messageService: MvsMessageService,
                protected ticketCommentService: TicketCommentService,
                private renderer: Renderer2, private el: ElementRef,
                protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService,) {
    }

    async ngOnInit() {

        await this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    async initComponent() {
        await this.objectService.getViaObjectType('tm.Ticket').then(res => {
            this.ticketObjectTypeId = res.id;
        })
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.refreshHistory();
        // this.initialized = true;
    }

    /**
     * refresh comments and actions and sort them
     */
    refreshHistory() {
        this.historyList = [];

        const complexSelection =
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createRelationNode("comments",
                    ObjectRequestRelation.createList(
                        "tm.TicketComment",
                        "ticket",
                        null,
                        null,
                        ObjectRequestComplexRelationBindingEnum.ALL)
                ),
                ObjectRequestComplexNode.createRelationNode("actions",
                    ObjectRequestRelation.createList(
                        "tm.TicketAction",
                        "ticket",
                        null,
                        null,
                        ObjectRequestComplexRelationBindingEnum.ALL)
                )
            );

        const objectRequestList = ObjectRequestList.createComplexRequestList(
            false,
            complexSelection,
            [FilterCriteria.create("id", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)],
            null,
            null,
            null
        );

        // const visitObjectRequest = new ObjectRequestList(false,
        //     [
        //         FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId),
        //         FilterCriteria.create('objectType', FilterCriteria.cOperatorEqual, this.ticketObjectTypeId),
        //     ],
        //     []
        // );

        this.ticketService.list(objectRequestList).subscribe(res => {
            this.postProcessTicketData(res);
            this.sortEntries();
        })

        // forkJoin({
        //     ticketData: this.ticketService.list(objectRequestList),
        //     visitData: this.uiPageUserService.list(visitObjectRequest)
        // }).subscribe({
        //     next: ({ ticketData, visitData }) => {
        //        this.postProcessTicketData(ticketData);
        //        // this.postProcessVisitData(visitData);
        //        this.sortEntries();
        //
        //     }
        // });
    }

    postProcessVisitData(visitData: DtoList<UiPageVisitResponseDto>) {
        if (!visitData || !visitData.entries.length) {
            return;
        }

        for (let item of visitData.entries) {
            const dto = new DtoDetail();
            dto.createdDate = item.visitedAt;
            dto.lastModifiedDate = item.visitedAt;
            dto.createdBy = item.user;
            dto['uiType'] = 'visit';

            this.historyList.push(dto);
        }
    }
    postProcessTicketData(ticketData: DtoList<DtoDetail>) {
        // Process the first API call response
        const ticket = ticketData.entries[0];
        if (ticket['comments']) {
            ticket['comments'].forEach(element => {
                    element["uiType"] = "comment";
                    this.historyList.push(element);
                    this.processString(element['comment']).subscribe((updatedComment) => {
                        element['uiComment'] = updatedComment;

                });
                }
            )
        }

        if (ticket['actions']) {
            ticket['actions'].forEach(element => {
                    element["uiType"] = "action";
                    this.historyList.push(element);
                }
            )
        }
        this.initialized = true;
    }

    sortEntries() {
        this.historyList.sort((a, b) => {
            const dateA: any = new Date(a.createdDate);
            const dateB: any = new Date(b.createdDate);

            return dateB - dateA;

        });
    }

    processString(content: string): Observable<string> {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const images = doc.querySelectorAll('img');

        if (!images.length) {
            // Return original content if no <img> tags are found
            return of(content);
        }

        const imageObservables = Array.from(images).map((img) => {
            const src = img.getAttribute('src');
            if (src && src.startsWith('documentId:')) {
                const documentId = +src.split(':')[1];
                return this.documentService.downloadViaUrl(documentId).pipe(
                    map((blobUrl) => {
                        const enhancedUrl = `${blobUrl}#view=FitH&navpanes=0&documentId=${documentId}`;
                        return { img, blobUrl: enhancedUrl };
                    }),
                    catchError((error) => {
                        console.error(`Error downloading documentId ${documentId}:`, error);
                        return of({ img, blobUrl: null });
                    })
                );
            }
            return of(null);
        });


        return forkJoin(imageObservables).pipe(
            map((results) => {
                results.forEach((result) => {
                    if (result && result.blobUrl) {
                        // Update the img tag with the new blob URL
                        result.img.setAttribute('src', result.blobUrl);

                        // Preserve existing styles
                        const style = result.img.getAttribute('style');
                        if (style) {
                            result.img.setAttribute('style', style);
                        }
                    }
                });

                // Return the updated HTML string
                return doc.body.innerHTML;
            })
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }


    ngAfterViewInit() {
        const commentsContainer = this.el.nativeElement;
        this.renderer.listen(commentsContainer, 'click', (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (target.tagName === 'A') {
                event.preventDefault();
                const href = target.getAttribute('href');
                const path = href?.split('&');
                const documentId = path[1]?.split('=')[1];
                this.openDocumentDrawer(+documentId);
            } else if (target.tagName === 'IMG') {
                event.preventDefault();
                const href = target.getAttribute('src');
                const path = href?.split('&');
                const documentId = path[2]?.split('=')[1];
                if (!documentId) {
                    return;
                }
                this.openDocumentDrawer(+documentId);
            }
        });
    }

    openDocumentDrawer(documentId: number) {
        if (!documentId) {
            return;
        }

        // open document in left sidebar
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(
            "dm.DmDocument",
            documentId,
            'object',
            "Dokument",
            null,
            null,
            MvsObjectNavigationActionEnum.any,
            'light');

        this.navigationService.navigateTo(mvsObjectNavigationEntry, "left");
    }

    handleEditComment(item: DtoDetail) {
        this.selectedObject = item;
        item['uiEdit'] = !item['uiEdit'];

        if (item['uiEdit'] == false) {
            this.selectedObject = null;
        }

    }

    handleCloseEditor(item: DtoDetail) {
        item['uiEdit'] = false;
    }

    handleUpdateComment(comment: string) {
        if (this.busy) {
            return;
        }

        this.busy = true;

        const dto = new DtoDetail();
        dto.id = this.selectedObject.id;
        dto['comment'] = comment;

        this.ticketCommentService.update(dto).subscribe(value => {

            this.refreshHistory();
            this.busy = false;
            this.messageService.showSuccessMessage("Ticket", "Comment created");
        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Ticket", "Error occured");
        })

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
