import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FilterCriteria, ObjectBaseComponent, ObjectRequestList, WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {
    WidgetFunctionCallBackCreate,
    WidgetToolbarCreateInterface
} from "../../../core/helper/widget-function-call-back-create";


@Component({
    selector: 'provision-template-type-object-component',
    templateUrl: './provision-template-type-object.component.html',
})
export class ProvisionTemplateTypeObjectComponent extends ObjectBaseComponent implements OnInit, OnChanges, OnDestroy {

    provisionTemplateTypeWidget: WidgetData;
    provisionTemplateTypeFieldWidget: WidgetData;
    provisionTemplateTypeMappingWidget: WidgetData;

    /**
     * On Object Change.
     */
    onObjectChanged() {
        this.refreshWidgets();
    }

    refreshWidgets() {
        this.refreshProvisionTemplateTypeWidget();
        this.refreshProvisionTemplateTypeFieldWidget();
        this.refreshProvisionTemplateTypeMappingWidget();
    }

    refreshProvisionTemplateTypeWidget() {
        this.provisionTemplateTypeWidget = WidgetFactory.createWidgetEntityData(
            'cp.provision-template-type-widget',
            'Provision Template Type',
            'cp.ProvisionTemplateType',
            this.objectIdentifier.objectId
        )
    }

    refreshProvisionTemplateTypeFieldWidget() {
        this.provisionTemplateTypeFieldWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision-template-type-field-widget',
            'Provision Template Type Field',
            'cp.ProvisionTemplateTypeField',
            'No Entry',
            ObjectRequestList.createBasic(true, [FilterCriteria.create('type', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)], [])
        );

        const fields: WidgetToolbarCreateInterface[] = [{fieldName: 'typeDtoId', fieldValue: this.objectIdentifier.objectId}]
        this.provisionTemplateTypeFieldWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }

    refreshProvisionTemplateTypeMappingWidget() {
        this.provisionTemplateTypeMappingWidget = WidgetFactory.createWidgetTableEntity(
            'cp.provision-template-type-mapping-widget',
            'Provision Template Type Mapping',
            'cp.ProvisionTemplateTypeMapping',
            'No Entry',
            ObjectRequestList.createBasic(true, [FilterCriteria.create('provisionTemplateType', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId)], [])
        );

        const fields: WidgetToolbarCreateInterface[] = [{fieldName: 'provisionTemplateTypeDtoId', fieldValue: this.objectIdentifier.objectId}]
        this.provisionTemplateTypeMappingWidget.functionCallbacks = WidgetFunctionCallBackCreate.widgetToolbarCreate(fields);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
