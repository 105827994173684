import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ContractDto} from "../../dto/contract.dto";
import {ContractService} from "../../service/api/contract.service";
import {
    ObjectIdentifier,
    WidgetHelperButton,
    DtoImportObjectContext,
} from "@kvers/alpha-core-common";
import {MvsObjectNavigationActionEnum, MvsObjectNavigationEntry, MvsObjectNavigationService} from "@kvers/alpha-ui";
import {TicketObjectService} from "../../../tm/service/api/ticket-object.service";
import {ObjectTypeService} from "../../../cc/service/api/object-type.service";

//TODO: REVIEW


@Component({
    selector: 'cm-contract-inline-component',
    templateUrl: './cm-contract-inline-component.html',
})
export class CmContractInlineComponent implements OnInit, OnChanges {

    @Input() objectIdentifier: ObjectIdentifier;
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() size: string = 'M';
    icon: string;
    dto?: ContractDto;

    toolbarButtons: WidgetHelperButton[] = [
        {
            label: 'Open in background',
            icon: 'fa-regular fa-send-backward',
            display: true,
            action: 'background',
            tooltip: 'Open in background'
        },
        {
            label: 'Open in new window',
            icon: 'fa-regular fa-arrow-up-right-from-square',
            display: true,
            action: 'newWindow',
            tooltip: 'Open in new window'
        }
    ];

    initialized: boolean;
    busy: boolean;

    constructor(protected contractService: ContractService,
                protected objectService: ObjectTypeService,
                protected navigationService: MvsObjectNavigationService,) {

    }

    ngOnInit() {
        this.initComponent();
    }

    initComponent() {
        this.icon = this.contractService.getObjectIcon();
        this.refreshComponent();

    }

    refreshComponent() {

        this.contractService.get(this.objectIdentifier.objectId, DtoImportObjectContext.createEmpty(), false).subscribe((res: ContractDto) => {
            this.dto = res;
            this.initialized = true;
        });

        // let objectTypeId : number
        // const objectId: number = this.objectIdentifier.objectId;
        //
        // await this.objectService.getViaObjectType(this.objectIdentifier.objectType).then(res => {
        //     objectTypeId = res.id;
        // });
        //
        // const filterCriteria = [];
        // filterCriteria.push(FilterCriteria.create('objectType', FilterCriteria.cOperatorEqual, objectTypeId));
        // filterCriteria.push(FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, objectId));
        //
        // if (this.ticketId) {
        //     filterCriteria.push(FilterCriteria.create('ticket', FilterCriteria.cOperatorEqual, this.ticketId));
        // }
        //
        // const objectRequest = new ObjectRequestList(true, filterCriteria, []);
        // objectRequest.objectRequestComplex = ObjectRequestComplex.build(true, false,
        //     ObjectRequestComplexNode.createSimpleAttributeNode('ticketTypeObjectRole'));
        //

        //
        // //TODO: This is wrong!
        // forkJoin({
        //     customerDto: this.contractService.get(this.objectIdentifier.objectId, DtoImportObjectContext.createEmpty(), false) as Observable<ContractDto>,
        //     ticketObjectDto: this.ticketObjectService.list(objectRequest)
        // }).subscribe(({ customerDto, ticketObjectDto }) =>  {
        //     this.dto = customerDto;
        //     this.ticketObjectRoleDto = ticketObjectDto.entries[0]?.ticketTypeObjectRoleDto;
        //     console.log(this.ticketObjectRoleDto);
        //     this.initialized = true;
        // })

    }

    ngOnChanges(changes: SimpleChanges) {

    }

    handleButtonClick(event: WidgetHelperButton) {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        if (event.action == 'background') {
            this.navigationService.addOverlay(navigationEntry)
        } else if (event.action == 'newWindow') {
            this.navigationService.handleObjectNavigation(this.objectIdentifier,null,{openNewTab:true});
        }
    }

    handleClick() {
        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, this.objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.addOverlay(navigationEntry)
    }
}
