<ng-container *ngIf="initialized">

    @if (selectedPerson) {
        <i [class]="selectedPerson.image"></i>
    }

    @if (selectedContract) {
        <i class="{{selectedContract.image}} ml-1"></i>
    }

<!--    @for(insurableObject of customerContractDto.contractDto.contractInsurableObject; track insurableObject) {-->
<!--        @if(insurableObject) {-->
<!--            <i [class]="insurableObject?.insurableObjectDto?.typeDto.image"-->
<!--               [pTooltip]="insurableObject?.insurableObjectDto?.typeDto.name"-->
<!--               tooltipPosition="top"-->
<!--            >-->
<!--            </i>-->
<!--        }-->
<!--    }-->

<!--    @for(contractPerson of customerContractDto.contractDto.contractPerson; track contractPerson) {-->
<!--        @if(contractPerson) {-->
<!--            <i [class]="contractPerson.personDto.personRelationPerson?.relationTypeADto?.image"-->
<!--               [pTooltip]="contractPerson.personDto.personRelationPerson?.relationTypeADto?.name"-->
<!--               tooltipPosition="top"-->
<!--            ></i>-->

<!--            <i [class]="contractPerson.personDto.personRelationPerson?.relationTypeBDto?.image"-->
<!--               [pTooltip]="contractPerson.personDto.personRelationPerson?.relationTypeBDto?.name"-->
<!--               tooltipPosition="top"-->
<!--            >-->

<!--            </i>-->
<!--        }-->
<!--    }-->


</ng-container>