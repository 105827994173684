import {Component, OnInit} from '@angular/core';
import {MvsCoreService, PageComponent} from "@kvers/alpha-core-common";
import {EventInterface, Person} from "@kvers/alpha-ui";
import {ActivatedRoute, Route} from "@angular/router";

@Component({
    selector: 'mvs-test-form-ather',
    templateUrl: 'test-calendar.page.html',
    styleUrls: ['./test-calendar.page.scss']
})
export class TestCalendarPage extends PageComponent implements OnInit {

    showSideDrawer: boolean;

    persons: Person[] = [
        { id: 1, name: 'Michel Ather', color: 'var(--red-600)', bgColor: 'var(--red-100)' },
        { id: 2, name: 'Don Jon', color: 'var(--blue-700)', bgColor: 'var(--blue-100)' },
        { id: 3, name: 'Kamala Haris', color: 'var(--green-600)', bgColor: 'var(--green-100)' },
    ];


    events: EventInterface[];

    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit() {
        super.ngOnInit();
        // this.initEvents();

    }


    handleSideDrawer() {
        this.showSideDrawer = !this.showSideDrawer;
    }

    initEvents() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Start from midnight

        this.events = [
            {
                id: 1,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 8 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 8.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 8.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 9.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 9.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 9.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 1,
                personDto: {id: 1, name: 'Person 1', color: 'var(--red-600)', bgColor: 'var(--red-100)'},
            },
            {
                id: 2,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 8 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 8.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 8.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 9.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 9.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 9.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 2,
                personDto: {id: 2, name: 'Person 2', color: 'var(--blue-700)', bgColor: 'var(--blue-100)'},
            },
            {
                id: 3,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 8 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 8.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 8.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 9.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 9.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 9.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 3,
                personDto: {id: 3, name: 'Person 3', color: 'var(--green-600)', bgColor: 'var(--green-100)'},
            },

            // Very Distinct Events
            {
                id: 4,
                title: 'Lunch Break',
                preEventDto: {
                    start: new Date(today.getTime() + 12 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 12.25 * 60 * 60 * 1000)
                }, // 12:00 PM - 12:15 PM
                mainEventDto: {
                    start: new Date(today.getTime() + 12.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 12.75 * 60 * 60 * 1000)
                }, // 12:15 PM - 12:45 PM
                postEventDto: {
                    start: new Date(today.getTime() + 12.75 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 13 * 60 * 60 * 1000)
                }, // 12:45 PM - 1:00 PM
                personId: 1,
                personDto: {id: 1, name: 'Person 1', color: 'var(--red-600)', bgColor: 'var(--red-100)'},
            },
            {
                id: 5,
                title: 'Client Call',
                preEventDto: {
                    start: new Date(today.getTime() + 10 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 10.15 * 60 * 60 * 1000)
                }, // 10:00 AM - 10:09 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 10.15 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 11 * 60 * 60 * 1000)
                }, // 10:09 AM - 11:00 AM
                postEventDto: {
                    start: new Date(today.getTime() + 11 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 11.25 * 60 * 60 * 1000)
                }, // 11:00 AM - 11:15 AM
                personId: 2,
                personDto: {id: 2, name: 'Person 2', color: 'var(--blue-700)', bgColor: 'var(--blue-100)'},
            },
            {
                id: 6,
                title: 'Project Discussion',
                preEventDto: {
                    start: new Date(today.getTime() + 14 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 14.25 * 60 * 60 * 1000)
                }, // 2:00 PM - 2:15 PM
                mainEventDto: {
                    start: new Date(today.getTime() + 14.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 15 * 60 * 60 * 1000)
                }, // 2:15 PM - 3:00 PM
                postEventDto: {
                    start: new Date(today.getTime() + 15 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 15.5 * 60 * 60 * 1000)
                }, // 3:00 PM - 3:30 PM
                personId: 3,
                personDto: {id: 3, name: 'Person 3', color: 'var(--green-600)', bgColor: 'var(--green-100)'},
            },

            // Consecutive Events with Overlapping Time Slots
            {
                id: 7,
                title: 'Status Update',
                preEventDto: {
                    start: new Date(today.getTime() + 16 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 16.15 * 60 * 60 * 1000)
                }, // 4:00 PM - 4:09 PM
                mainEventDto: {
                    start: new Date(today.getTime() + 16.15 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 16.75 * 60 * 60 * 1000)
                }, // 4:09 PM - 4:45 PM
                postEventDto: {
                    start: new Date(today.getTime() + 16.75 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 17.25 * 60 * 60 * 1000)
                }, // 4:45 PM - 5:00 PM
                personId: 1,
                personDto: {id: 1, name: 'Person 1', color: 'var(--red-600)', bgColor: 'var(--red-100)'},
            },
            {
                id: 8,
                title: 'Follow-Up Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 17.5 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 17.65 * 60 * 60 * 1000)
                }, // 5:00 PM - 5:09 PM
                mainEventDto: {
                    start: new Date(today.getTime() + 17.65 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 18 * 60 * 60 * 1000)
                }, // 5:09 PM - 5:45 PM
                postEventDto: {
                    start: new Date(today.getTime() + 18 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 18.5 * 60 * 60 * 1000)
                }, // 5:45 PM - 6:00 PM
                personId: 2,
                personDto: {id: 2, name: 'Person 2', color: 'var(--blue-700)', bgColor: 'var(--blue-100)'},
            },
            {
                id: 9,
                title: 'Wrap-Up Session',
                preEventDto: {
                    start: new Date(today.getTime() + 15.75 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 16 * 60 * 60 * 1000)
                }, // 3:45 PM - 4:00 PM
                mainEventDto: {
                    start: new Date(today.getTime() + 16 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 16.75 * 60 * 60 * 1000)
                }, // 4:00 PM - 4:45 PM
                postEventDto: {
                    start: new Date(today.getTime() + 16.75 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 17 * 60 * 60 * 1000)
                }, // 4:45 PM - 5:00 PM
                personId: 3,
                personDto: {id: 3, name: 'Person 3', color: 'var(--green-600)', bgColor: 'var(--green-100)'},
            },

            {
                id: 10,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 1,
                personDto: {id: 1, name: 'Person 1', color: 'var(--red-600)', bgColor: 'var(--red-100)'},
            },
            {
                id: 11,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 2,
                personDto: {id: 2, name: 'Person 2', color: 'var(--blue-700)', bgColor: 'var(--blue-100)'},
            },
            {
                id: 12,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 3,
                personDto: {id: 3, name: 'Person 3', color: 'var(--green-600)', bgColor: 'var(--green-100)'},
            },
            {
                id: 13,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 1,
                personDto: {id: 1, name: 'Person 1', color: 'var(--red-600)', bgColor: 'var(--red-100)'},
            },
            {
                id: 14,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 2,
                personDto: {id: 2, name: 'Person 2', color: 'var(--blue-700)', bgColor: 'var(--blue-100)'},
            },
            {
                id: 15,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 3,
                personDto: {id: 3, name: 'Person 3', color: 'var(--green-600)', bgColor: 'var(--green-100)'},
            },
            {
                id: 16,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 1,
                personDto: {id: 1, name: 'Person 1', color: 'var(--red-600)', bgColor: 'var(--red-100)'},
            },
            {
                id: 17,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 2,
                personDto: {id: 2, name: 'Person 2', color: 'var(--blue-700)', bgColor: 'var(--blue-100)'},
            },
            {
                id: 18,
                title: 'Morning Meeting',
                preEventDto: {
                    start: new Date(today.getTime() + 19 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 19.25 * 60 * 60 * 1000)
                }, // 8:00 AM - 8:15 AM
                mainEventDto: {
                    start: new Date(today.getTime() + 19.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.25 * 60 * 60 * 1000)
                }, // 8:15 AM - 9:15 AM
                postEventDto: {
                    start: new Date(today.getTime() + 20.25 * 60 * 60 * 1000),
                    end: new Date(today.getTime() + 20.5 * 60 * 60 * 1000)
                }, // 9:15 AM - 9:30 AM
                personId: 3,
                personDto: {id: 3, name: 'Person 3', color: 'var(--green-600)', bgColor: 'var(--green-100)'},
            },

        ];
    }

}
