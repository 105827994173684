<ng-container *ngIf="initialized && notificationTypesOverview">


    <div   class="relative surface-0 pb-3">
        <div class="justify-content-between mt-4 d-flex align-items-center">

            <div class="flex-grow-1">
                <p-tabView class="w-full" [scrollable]="true">
                    <p-tabPanel header="Alle">
                    </p-tabPanel>
                    <ng-container *ngFor="let channelType of notificationTypesOverview.channelTypes">
                        <p-tabPanel [leftIcon]="channelType.image">
                        </p-tabPanel>
                    </ng-container>
                </p-tabView>
            </div>

        </div>

        <table  class="channel-table relative" style="border-spacing: 4px;">

            <colgroup>
                <col style="width: 50%;">
                <col style="width: 50%;">
            </colgroup>
            <tr>
                <th class="channel-table-th" style="text-align:left">Benachrichtigung</th>
                <th class="channel-table-th" style="text-align:left">Kanal</th>
            </tr>

            <ng-container *ngFor="let notificationType of notificationTypesOverview.types">
                <tr class="cursor">
                    <td (click)="handleClickNotification(notificationType)"
                        class="channel-table-td">{{ notificationType.notificationSourceName }}</td>
                    <td class="channel-table-td">
                        <div class="flex gap-4">
                            <div *ngFor="let channelType of notificationType.channels" class="flex">

                                <i class="{{channelType.channelTypeDto.image}} text-2xl"
                                   pBadge
                                   [ngStyle]="{color: 'var(--surface-500)'}"

                                   [badgeDisabled]="!channelType.history || !channelType.history.count"
                                   [value]="channelType.history?.count+''"></i>

                                <!-- (click)="handleHistoryDialog(item, channelType)" -->

                            </div>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

</ng-container>


 <p-dialog
         [(visible)]="showSendNotification"
         *ngIf="showSendNotification && selectedNotificationType"
         (visibleChange)="onDialogVisibleChange($event)"
         [style]="{width: '80vw', height: '80vh', maxWidth: '80rem'}"
 >

    <mvs-ns-notification-send
        [mode]="mode"
        [objectIdentifier]="objectIdentifier"
        [notificationSourceTypeId]="selectedNotificationType.notificationSource.objectId"
    ></mvs-ns-notification-send>

</p-dialog>

