import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FilterCriteria, MvsFormFieldDto} from "@kvers/alpha-core-common";
import {MvsLinkReportOutputFieldInterface} from "../interface/mvs-link-report-output-field.interface";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {ReportService} from "../../../../../../rp/service/api/report.service";

export interface ReportLinkage {
    thisReportField: string,
    toReportField: string,
    operator: string
}

@Component({
    selector: 'mvs-link-report-output-field-config-component',
    templateUrl: './mvs-link-report-output-field-config.component.html',
})
export class MvsLinkReportOutputFieldConfigComponent implements OnInit, OnChanges, OnDestroy, MvsLinkReportOutputFieldInterface {

    module: string;
    category: number;
    report: number;
    reportLinkage: ReportLinkage[];
    location: string;

    @Input() formGroup: FormGroup;
    @Input() formFields: MvsFormFieldDto[];
    @Input() widgetUiField: any;


    moduleList: { name: string, label: string }[];
    categoryList: { id: number, name: string, label: string }[];
    reportList: { id: number, name: string, label: string }[];
    fieldList: MvsFormFieldDto[];
    locationList: string[] = ['left', 'right', 'bottom', 'main', 'newTab']
    objectIdOptions: { label: string; value: any }[] = [];
    operatorList = [
        {label: 'Equal', value: FilterCriteria.cOperatorEqual},
        {label: 'Not Equal', value: FilterCriteria.cOperatorNotEqual},
        {label: 'Contains Pattern', value: FilterCriteria.cOperatorContainsPattern},
        {label: 'Lower Equal', value: FilterCriteria.cOperatorLowerEqual},
        {label: 'Lower Than', value: FilterCriteria.cOperatorLowerThan},
        {label: 'Greater Equal', value: FilterCriteria.cOperatorGreaterEqual},
        {label: 'Greater Than', value: FilterCriteria.cOperatorGreaterThan},
        {label: 'Between', value: FilterCriteria.cOperatorBetween},
        {label: 'Starts With', value: FilterCriteria.cOperatorStartsWith},
        {label: 'Ends With', value: FilterCriteria.cOperatorEndsWith},
        {label: 'In', value: FilterCriteria.cOperatorIn},
        {label: 'In Not', value: FilterCriteria.cOperatorInNot},
        {label: 'Is Null', value: FilterCriteria.cOperatorIsNull},
        {label: 'Is Not Null', value: FilterCriteria.cOperatorIsNotNull},
    ];

    initialized: boolean;

    constructor(
        protected reportService: ReportService) {
    }

    ngOnInit(): void {

        this.initComponent()
    }


    /**
     * Initialize Component.
     */
    initComponent() {
        this.objectIdOptions = this.getObjectIdOptions();
        this.refreshComponent();
    }

    getObjectIdOptions(): { label: string; value: any }[] {
        return this.formFields.map((field) => ({
            label: field.uiLabel,
            value: field.id,
        }));
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {


        let uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
        if (!uiConfigData) {
            uiConfigData = new FormGroup({});
            this.widgetUiField.addControl('uiConfigData', uiConfigData);
        }

        // Ensure module control exists
        if (!uiConfigData.get('module')) {
            uiConfigData.addControl('module', new FormControl(''));
        }

        // Ensure category control exists
        if (!uiConfigData.get('category')) {
            uiConfigData.addControl('category', new FormControl(null));
        }

        // Ensure report control exists
        if (!uiConfigData.get('report')) {
            uiConfigData.addControl('report', new FormControl(null));
        }

        // Ensure location control exists
        if (!uiConfigData.get('location')) {
            uiConfigData.addControl('location', new FormControl(null));
        }

        // Ensure reportLinkage control exists
        if (!uiConfigData.get('reportLinkage')) {
            uiConfigData.addControl('reportLinkage', new FormArray([]));
        } else {
            const existing = uiConfigData.get('reportLinkage');
            if (existing instanceof FormControl) {
                const rawValue = existing.value || [];

                // Create a brand-new FormArray
                const newArray = new FormArray([]);

                if (Array.isArray(rawValue)) {
                    rawValue.forEach((link) => {
                        newArray.push(
                            new FormGroup({
                                thisReportField: new FormControl(link?.thisReportField ?? null),
                                toReportField: new FormControl(link?.toReportField ?? null),
                                operator: new FormControl(link?.operator ?? FilterCriteria.cOperatorEqual)
                            })
                        );
                    });
                }

                uiConfigData.setControl('reportLinkage', newArray);
            }
        }


        this.initialized = true;
        this.refreshModules();

        // Optionally: if there's already saved data in uiConfigData.value.reportLinkage, load it:
        this.populateSavedLinkages();


    }

    refreshModules() {
        this.reportService.getModules().subscribe(res => {

            const uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
            this.moduleList = res;
            if (uiConfigData.get('module')?.value) {
                this.refreshCategories();
            }
        }, error => {
            this.moduleList = null;
        });
    }

    refreshCategories() {
        this.reportList = [];

        const uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
        const moduleValue = uiConfigData.get('module')?.value;

        this.reportService.getCategories(moduleValue).subscribe(res => {
            this.categoryList = res;
            if (uiConfigData.get('category')?.value) {
                this.refreshReports();
            }
        }, error => {
            this.categoryList = null;
        });
    }


    refreshReports() {
        const uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
        const moduleValue = uiConfigData.get('module')?.value;
        const categoryValue = uiConfigData.get('category')?.value;
        this.reportService.getReports(moduleValue, categoryValue).subscribe(res => {
            this.reportList = res;
            if (uiConfigData.get('report')?.value) {
                this.refreshFields();
            }
        }, error => {
            this.reportList = null;
        });
    }

    refreshFields() {
        const uiConfigData = this.widgetUiField.get('uiConfigData') as FormGroup;
        const moduleValue = uiConfigData.get('module')?.value;
        const reportValue = uiConfigData.get('report')?.value;
        this.reportService.getFields(moduleValue, reportValue).subscribe(res => {
            this.fieldList = res;
        }, error => {
            this.fieldList = null;
        });
    }

    /**
     * If we already have saved values (an array of objects),
     * we convert them into FormGroups in the FormArray.
     */
    private populateSavedLinkages(): void {
        const saved = this.uiConfigData.value.reportLinkage;
        if (Array.isArray(saved) && saved.length > 0) {
            // Clear existing items
            while (this.reportLinkageArray.length > 0) {
                this.reportLinkageArray.removeAt(0);
            }

            // For each saved item, push a new FormGroup
            saved.forEach(link => {
                this.reportLinkageArray.push(
                    new FormGroup({
                        thisReportField: new FormControl(link?.thisReportField || null),
                        toReportField: new FormControl(link?.toReportField || null),
                        operator: new FormControl(link?.operator || FilterCriteria.cOperatorEqual)
                    })
                );
            });
        }
    }


    /** A quick getter to cast the "reportLinkage" control as a FormArray */
    get reportLinkageArray(): FormArray {
        return this.uiConfigData.get('reportLinkage') as FormArray;
    }


    /**
     * Add a new row (FormGroup) in the FormArray
     */
    addNewLinkage(): void {
        const group = new FormGroup({
            thisReportField: new FormControl(null),
            toReportField: new FormControl(null),
            operator: new FormControl(FilterCriteria.cOperatorEqual)
        });
        this.reportLinkageArray.push(group);
    }


    /**
     * Remove a row by index
     */
    removeLinkage(index: number): void {
        this.reportLinkageArray.removeAt(index);
    }

    get uiConfigData(): FormGroup {
        return this.widgetUiField.get('uiConfigData') as FormGroup;
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }
}
