import {Injectable, Type} from '@angular/core';
import {FilterCriteria, MvsCrudService} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {
    CrCreateCustomerContractObjectComponent
} from "../../component/cr-create-customer-contract-component/object/cr-create-customer-contract-object/cr-create-customer-contract-object.component";
import {MvsCrudModeEnum} from "@kvers/alpha-core-common";
import {Observable} from "rxjs";
import {CustomerContactPhoneInfoDto} from "../dto/customer-contact-phone-info.dto";
import {
    CustomersContractsObjectsRuntime
} from "../../component/cr-customer/component/new-contract-navigation/data/customer-contracts-object-runtime";

@Injectable({
    providedIn: 'root'
})
export class CustomerContractService extends MvsCrudService {

    constructor(protected http: HttpClient) {
        super(http, MvsCrudService.baseUrl + '/cr/customerContracts')
    }

    getCustomerContractsComplexSelection(personId: number): ObjectRequestComplex {
        return ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("contract")
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contractType")
                    .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("group")))
                .addNodes(ObjectRequestComplexNode.createRelationNode("currentMainPartner", ObjectRequestRelation.createJoin("+currentMainContractPartner"))
                    .addNodes(
                        ObjectRequestComplexNode.createRelationNode("partner", ObjectRequestRelation.createJoin("partner")),
                        ObjectRequestComplexNode.createRelationNode("partnerLink", ObjectRequestRelation.createJoin("partnerLink"))
                            .addNodes(ObjectRequestComplexNode.createRelationNode("partnerLinkPartner", ObjectRequestRelation.createJoin("linkPartner")))
                    )
                )
                .addNodes(ObjectRequestComplexNode.createRelationNode("currentPrice", ObjectRequestRelation.createJoin("+currentPrice"))
                    .addNodes(
                        ObjectRequestComplexNode.createSimpleAttributeNode("priceFrequency")
                    )
                )
                .addNodes(ObjectRequestComplexNode.createRelationNode("currentRating", ObjectRequestRelation.createJoin("+currentRating")))
                .addNodes(
                    ObjectRequestComplexNode.createRelationNode("contractInsurableObject", ObjectRequestRelation.createJoin("ci.ContractInsurableObject#contract"))
                        .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('insurableObject')
                            .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('type')))
                )

                //TODO HARIS WE NEED TO DOUBLE CHECK THIS
                // why do we need this?
                // .addNodes(ObjectRequestComplexNode.createRelationNode("contractPerson", ObjectRequestRelation.createJoin("ci.ContractPerson#contract"))
                //     .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('person')
                //         .addNodes(ObjectRequestComplexNode.createRelationNode("personRelationPerson",
                //             ObjectRequestRelation.createList(
                //                 "pm.PersonRelationPerson",
                //                 "personA",
                //                 [FilterCriteria.create('personB', FilterCriteria.cOperatorEqual, personId)],
                //                 null,
                //                 ObjectRequestComplexRelationBindingEnum.ANY)
                //         ).addNodes(
                //             ObjectRequestComplexNode.createSimpleAttributeNode('relationTypeA'),
                //             ObjectRequestComplexNode.createSimpleAttributeNode('relationTypeB'),
                //             )
                //         )
                //     )
                // )

                .addNodes(
                    ObjectRequestComplexNode.createRelationNode("contractFormer",
                        ObjectRequestRelation.createList(
                            "cm.ContractFormer",
                            "contract",
                            null,
                            null,
                            ObjectRequestComplexRelationBindingEnum.LATEST)
                    ).addNodes(ObjectRequestComplexNode.createRelationNode('contract', ObjectRequestRelation.createJoin('contract'))))
        );
    }

    testCustomerContractsComplexSelection(): ObjectRequestComplex {
        return ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("contract")
                .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contractType")
                    .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("group")))
        );
    }

    getContractPersonFromContractComplexSelection(): ObjectRequestComplex {
        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("contract").addNodes(
                ObjectRequestComplexNode.createRelationNode("contractPersons",
                    ObjectRequestRelation.createList(
                        "ci.ContractPerson",
                        "contract",
                        null,
                        [new Sorting("lastModifiedDate", false)],
                        ObjectRequestComplexRelationBindingEnum.ALL)
                ))
        );
    }

    getCompanyContactFromContractComplexSelection(): ObjectRequestComplex {
        return ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("contract").addNodes(
                ObjectRequestComplexNode.createRelationNode("contractPartners",
                    ObjectRequestRelation.createList(
                        "cm.ContractPartner",
                        "contract",
                        null,
                        [new Sorting("lastModifiedDate", false)],
                        ObjectRequestComplexRelationBindingEnum.ALL)
                ).addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("partner")
                    .addNodes(ObjectRequestComplexNode.createRelationNode("partnerAddresses",
                        ObjectRequestRelation.createList(
                            "cm.PartnerAddress",
                            "partner",
                            null,
                            [new Sorting("lastModifiedDate", false)],
                            ObjectRequestComplexRelationBindingEnum.ALL)
                    ))
                    .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("address")))
            )
        );
    }

    getCustomerContractFromContractComplexSelection(): ObjectRequestComplex {
        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("customer")
        );
    }

    getContractPartnerComplexSelection(): ObjectRequestComplex {
        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("partner"),
            ObjectRequestComplexNode.createRelationNode("partnerLink", ObjectRequestRelation.createJoin("partnerLink")).addNodes(ObjectRequestComplexNode.createRelationNode("partnerLinkPartner", ObjectRequestRelation.createJoin("linkPartner"))),
            ObjectRequestComplexNode.createRelationNode("currentRating", ObjectRequestRelation.createJoin("+currentRating")),
        );
    }

    getExtendedContractPartnerComplexSelection(): ObjectRequestComplex {
        return ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("partner")
                .addNodes(
                    ObjectRequestComplexNode.createRelationNode("partnerAddress", ObjectRequestRelation.createJoin("cm.PartnerAddress#partner"))
                        .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("address")),
                    ObjectRequestComplexNode.createRelationNode("partnerContact", ObjectRequestRelation.createJoin("cm.PartnerContact#partner"))
                        .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("contact")),
                    ObjectRequestComplexNode.createRelationNode("partnerGroup", ObjectRequestRelation.createJoin("cm.PartnerGroup#partner"))
                        .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("group")),
                    ObjectRequestComplexNode.createRelationNode("partnerLink", ObjectRequestRelation.createJoin("cm.PartnerLink#partner"))
                        .addNodes(ObjectRequestComplexNode.createSimpleAttributeNode("linkPartner")),
                ),
            ObjectRequestComplexNode.createRelationNode("partnerLink", ObjectRequestRelation.createJoin("partnerLink"))
                .addNodes(ObjectRequestComplexNode.createRelationNode("partnerLinkPartner", ObjectRequestRelation.createJoin("linkPartner"))),
            ObjectRequestComplexNode.createRelationNode("currentRating", ObjectRequestRelation.createJoin("+currentRating")),
        );
    }

    getCustomerMainAddressComplexSelection(): ObjectRequestComplex {
        return ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createSimpleAttributeNode('customer')
                .addNodes(ObjectRequestComplexNode.createRelationNode("mainAddress", ObjectRequestRelation.createJoin("+mainAddress")))
        )
    }

    // getObjectComponent(): Type<any> {
    //   debugger
    //     return CmCombinedFormContractPostFormsComponent;
    // }


    getObjectComponent(mode: MvsCrudModeEnum = MvsCrudModeEnum.update): Type<any> {
        // if (mode == MvsCrudModeEnum.create) {
        //     return CrCreateCustomerContractObjectComponent;
        // } else {
        //     return null; // use default
        // }
        return null;

    }

    public getCustomerContracts(customerId: number, showActive: boolean = true) : Observable<CustomersContractsObjectsRuntime> {
        const url = `${this.apiUrl}/query/household/${customerId}`;
        let queryParams = {
            showActive: showActive
        }
        return <Observable<CustomersContractsObjectsRuntime>>this.http.get(url, {params: queryParams});

    }


}