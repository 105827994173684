import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    DtoDetail, DtoImportObjectContextEntry, DtoList, FilterCriteria,
    MvsCoreService,
    MvsCrudModeEnum,
    ObjectIdentifier, ObjectRequestComplex, ObjectRequestComplexNode, ObjectRequestList, Sorting,
    WidgetHelperButton
} from "@kvers/alpha-core-common";
import {TmTicketObjectDto} from "../../../../dto/tm-ticket-object.dto";
import {ObjectTypeService} from "../../../../../cc/service/api/object-type.service";
import {ContractDto} from "../../../../../cm/dto/contract.dto";
import {CustomerDto} from "../../../../../cr/dto/customer.dto";
import {
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
    MvsUiObjectService
} from "@kvers/alpha-ui";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {TmTicketAppointmentDto} from "../../../../dto/tm-ticket-appointment.dto";
import {TicketAppointmentService} from "../../../../service/api/ticket-appointment.service";
import {TicketObjectDto} from "../../../../dto/ticket-object.dto";
import {TicketObjectService} from "../../../../service/api/ticket-object.service";
import {TicketTypeObjectRoleDto} from "../../../../dto/ticket-type-object-role.dto";
import {firstValueFrom} from "rxjs";
import {MenuItem} from "primeng/api";
import {ContextMenu} from "primeng/contextmenu";
import {Tick} from "chart.js";
import {TicketTypeService} from "../../../../service/api/ticket-type.service";
import {TicketTypeObjectRoleService} from "../../../../service/api/ticket-type-object-role.service";

@Component({
    selector: 'tm-ticket-landing-components',
    templateUrl: './tm-ticket-landing-components.component.html',
    styleUrls: ['./tm-ticket-landing-components.component.scss']
})
export class TmTicketLandingComponentsComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() ticketObjects: TicketObjectDto[];
    @Input() importObjectContext: DtoImportObjectContext;
    @Input() objectIdentifier: ObjectIdentifier;
    @Input() fullPage: boolean = false;
    @Input() ticketTypeId: number;

    @Output() onObjectChanged: EventEmitter<any> = new EventEmitter();
    @Output() changeMode: EventEmitter<string> = new EventEmitter();

    ticketObjectContext: DtoImportObjectContext;
    ticketObjectToolbarButtons: MenuItem[] = [
        {
            label: 'Open in background',
            icon: 'fa-regular fa-send-backward',
            display: true,
            action: 'background',
            tooltip: 'Open in background'
        },
        {
            label: 'Open in sidebar',
            icon: 'fa-regular fa-sidebar',
            display: true,
            action: 'sidebar',
            tooltip: 'Open in sidebar'
        },
        {
            label: 'Delete',
            icon: 'fa-regular fa-trash',
            display: true,
            action: 'delete',
            tooltip: 'Delete Object'
        }
    ];
    ticketTypeAvailableRoles: TicketTypeObjectRoleDto[];

    contextMenuItems: any[];
    selectedContextMenuEntry: TicketObjectDto;
    @ViewChild("cm1") contextMenu!: ContextMenu;

    constructor(
        protected objectTypeService: ObjectTypeService, protected coreService: MvsCoreService,
        protected navigationService: MvsObjectNavigationService,
        protected ticketObjectService: TicketObjectService,
        protected ticketTypeObjectRoleService: TicketTypeObjectRoleService,
        protected objectService: MvsUiObjectService,
        protected ticketAppointmentService: TicketAppointmentService,) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.ticketObjectContext = new DtoImportObjectContext([new DtoImportObjectContextEntry('tm.Ticket', this.objectIdentifier.objectId)]);
        this.getAvailableRoles();

    }

    prepareContextMenu() {
        const generalToolbarButtons = structuredClone(this.ticketObjectToolbarButtons);
        this.contextMenuItems = [];


        for (let item of generalToolbarButtons) {
            const contextMenuItem =  {
                label: item.label,
                icon: item.icon,
                display: item.display,
                action: item.action,
                tooltip: item.tooltip,
                command: () => this.handleButtonClick(item)
            }

            this.contextMenuItems.push(contextMenuItem);
        }

        // check for available roles, if exist then show in context menu
        if (this.ticketTypeAvailableRoles && this.ticketTypeAvailableRoles.length) {
            const contextMenuItemAdd =  {
                label: 'Change Role',
                icon: 'pi pi-plus',
                display: true,
                action: 'addRole',
                tooltip: 'Add Object Role',
                items: []
            }

            for (let item of this.ticketTypeAvailableRoles) {

                if (this.selectedContextMenuEntry.ticketTypeObjectRoleDtoId == item.id) {
                    continue;
                }

                const child = {
                    label: item.name,
                    icon: item.image,
                    command: () => this.addObjectRole(item)
                }
                contextMenuItemAdd.items.push(child);
            }

            if (contextMenuItemAdd.items.length > 0) {
                this.contextMenuItems.push(contextMenuItemAdd);
            }

        }


        // if it has a role assigned then show remove button
        if (this.selectedContextMenuEntry.ticketTypeObjectRoleDtoId) {
            const contextMenuItem =  {
                label: 'Remove role',
                icon: 'fa-regular fa-trash',
                display: true,
                action: 'removeRole',
                tooltip: 'Remove object role',
                command: () => this.removeObjectRole()
            }
            this.contextMenuItems.push(contextMenuItem);
        }

    }

    addObjectRole(item: TicketTypeObjectRoleDto) {
        if (this.busy) {
            return;
        }

        this.busy = true;
        const dto = new TicketObjectDto();
        dto.id = this.selectedContextMenuEntry.id;
        dto.ticketTypeObjectRoleDtoId = item.id;
        this.ticketObjectService.update(dto).subscribe(res => {
            this.busy = false;
        }, error => {
            this.busy = false;
        })
    }

    removeObjectRole() {
        if (this.busy) {
            return;
        }

        this.busy = true;
        const dto = new TicketObjectDto();
        dto.id = this.selectedContextMenuEntry.id;
        dto.ticketTypeObjectRoleDtoId = null;
        this.ticketObjectService.update(dto).subscribe(res => {
            this.busy = false;
        }, error => {
            this.busy = false;
        })
    }

    getAvailableRoles() {
        if (this.ticketTypeAvailableRoles && this.ticketTypeAvailableRoles.length) {
            return; // roles are retrieved once, no need to retrieve again
        }

        const objectRequest = new ObjectRequestList(
            false,
            [FilterCriteria.create('ticketType', FilterCriteria.cOperatorEqual, this.ticketTypeId )],
            []
        );

        this.ticketTypeObjectRoleService.list(objectRequest).subscribe(res => {
            this.ticketTypeAvailableRoles = res.entries;
        })
    }

    async handleButtonClick(event: MenuItem) {
        const objectAlias: string = await this.getObjectAlias(this.selectedContextMenuEntry.objectTypeDtoId);

        if (!objectAlias) {
            return;
        }

        const identifier = new ObjectIdentifier(objectAlias, this.selectedContextMenuEntry.objectId);

        const navigationEntry = MvsObjectNavigationEntry.createNavigationEntry(identifier.objectType, identifier.objectId, null, identifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
        if (event.action == 'background') {
            this.navigationService.addOverlay(navigationEntry)
        } else if (event.action == 'newWindow') {
            this.navigationService.handleObjectNavigation(identifier,null,{openNewTab:true});
        } else if (event.action == 'delete') {
            this.deleteTicketObject();
        }
    }

    deleteTicketObject() {
        if (this.busy || !this.selectedContextMenuEntry.objectTypeDtoId) {
            return;
        }
        this.busy = true;
        this.ticketObjectService.delete(this.selectedContextMenuEntry.id).subscribe(res => {
           this.busy = false;
        }, error => {
            console.log(error);
            this.busy = false;
        });
    }

    async getObjectAlias(objectTypeId: number): Promise<string> {
        if (!objectTypeId) {
            return null;
        }

        const res = await this.objectTypeService.getViaObjectId(this.selectedContextMenuEntry.objectTypeDtoId);
        return res.alias;
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        // this.initialized = false;
        this.prepareTicketRoles();
        // this.getTicketObjectIdentifier();
    }

    async prepareTicketRoles() {

        if (!this.ticketObjects || !this.ticketObjects.length) {
            this.initialized = true;
            return;
        }

        for (const item of this.ticketObjects) {
            const roleDto = await this.getObjectTicketRoles(item.objectTypeDtoId, item.objectId);
            // const objectTypeDto = await this.objectTypeService.getViaObjectId(item.objectTypeDtoId);
            // item['objectIdentifier'] = new ObjectIdentifier(objectTypeDto.alias, item.objectId);
            item.ticketTypeObjectRoleDto = roleDto;
        }

        this.initialized = true;
    }

    async getObjectTicketRoles(objectTypeId: number, objectId: number): Promise<TicketTypeObjectRoleDto | undefined> {
        const filterCriteria = [];
        filterCriteria.push(FilterCriteria.create('objectType', FilterCriteria.cOperatorEqual, objectTypeId));
        filterCriteria.push(FilterCriteria.create('objectId', FilterCriteria.cOperatorEqual, objectId));
        filterCriteria.push(FilterCriteria.create('ticket', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId));

        const objectRequest = new ObjectRequestList(true, filterCriteria, []);
        objectRequest.objectRequestComplex = ObjectRequestComplex.build(
            true,
            false,
            ObjectRequestComplexNode.createSimpleAttributeNode('ticketTypeObjectRole')
        );

        const response = await firstValueFrom(this.ticketObjectService.list(objectRequest));
        return response.entries[0]?.ticketTypeObjectRoleDto;
    }

    handleTicketTypeToolbarButton(event: WidgetHelperButton, objectIdentifier: ObjectIdentifier) {
        if (event.action == 'background') {
            this.navigationService.changeMainObject(objectIdentifier);
        } else if (event.action == 'sidebar') {
            const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(objectIdentifier.objectType, objectIdentifier.objectId, null, objectIdentifier.objectType, null, null, MvsObjectNavigationActionEnum.any);
            this.navigationService.navigateTo(mvsObjectNavigationEntry, 'left');
        }
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes['ticketObjects']) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

    handleContextMenu(object: TicketObjectDto, event: MouseEvent) {
        this.selectedContextMenuEntry = object;
        this.prepareContextMenu();
        this.contextMenu.toggle(event);
        event.preventDefault();
    }

}
