<ng-container *ngIf="initialized">

    <!--new design layout-->
    <div>
        <div class="grid mb-3">
            <div class="xl:col-2 lg:col-3 col-12 flex flex-column gap-2">

                <!--            <div class="mvs-widget">-->
                <!--                <mvs-cr-customer-related-person-->
                <!--                        [customerDto]="dto"-->
                <!--                        (selectedRelatedPersonsChanged)="handleRelatedPersonSelectionChange($event)"-->
                <!--                >-->
                <!--                </mvs-cr-customer-related-person>-->
                <!--            </div>-->


                <div class="mvs-widget">
                    <mvs-widget *ngIf="customerContactsWidgetData"
                                [widgetData]="customerContactsWidgetData"
                    ></mvs-widget>
                </div>

                <div class="mvs-widget">
                    <mvs-widget *ngIf="customerPersonObjectWidgetData"
                                [widgetData]="customerPersonObjectWidgetData">
                    </mvs-widget>
                </div>


                <div class="mvs-widget">
                    <mvs-widget *ngIf="widgetCustomerContacts"
                                [widgetData]="widgetCustomerContacts"
                    ></mvs-widget>
                </div>

                <div class="mvs-widget">
                    <mvs-widget *ngIf="widgetCustomerAddresses"
                                [widgetData]="widgetCustomerAddresses"
                    ></mvs-widget>
                </div>
            </div>

            <div class="xl:col-10 lg:col-9 col-12">
                <div class="grid  my-0">
                    <div class="lg:col-6 col-12 flex flex-column gap-2">

                        <div class="mvs-widget">


<!--                            <mvs-widget *ngIf="customerRelatedPersonWidget"-->
<!--                                        [widgetData]="customerRelatedPersonWidget"-->
<!--                                        [uiStyle]="selectedRelatedPersons"-->
<!--                                        [importObjectContext]="importObjectContext"-->
<!--                            >-->
<!--                            </mvs-widget>-->


                            <mvs-widget *ngIf="personRelationPersonWidget"
                                        [widgetData]="personRelationPersonWidget"
                                        [uiStyle]="selectedPersonRelationWidgetStyle"
                                        [importObjectContext]="personRelationPersonObjectContext"
                            >
                                <ng-template let-object="object" mvs-widget-custom-field-directive label="Same Household"
                                             fieldId="sameHousehold">
                                    <check-person-same-household *ngIf="object"
                                                                 [dto]="object"
                                                                 [persons]="customerHouseholdRuntimeDto.persons">
                                    </check-person-same-household>
                                </ng-template>

                                <ng-template let-object="object" mvs-widget-custom-field-directive label="Is Customer"
                                             fieldId="isCustomer">
                                    <check-is-person-customer *ngIf="object"
                                                              [dto]="object"
                                                              [personsCustomer]="customerHouseholdRuntimeDto.personCustomers">

                                    </check-is-person-customer>
                                </ng-template>

                            </mvs-widget>


                        </div>


                    </div>

                    <div class="lg:col-6 col-12 flex flex-column gap-2">

                        <div class="mvs-widget">
                            <mvs-widget *ngIf="customerInsurableObjectsWidgetData"
                                        [widgetData]="customerInsurableObjectsWidgetData"
                                        [uiStyle]="selectedRelatedPersons"
                                        [importObjectContext]="insurableObjectContext"
                            >
                                <!-- (onObjectSelect)="handleViewInsurableObjects($event,'#target')" Not required anymore -->
                            </mvs-widget>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mvs-widget">
                            <mvs-widget *ngIf="customerContractWidgetData"
                                        [widgetData]="customerContractWidgetData"
                                        [widgetToolbarButtons]="contractToolbarButtons"
                                        [importObjectContext]="contractObjectContext"
                                        [uiStyle]="selectedRelatedPersons"
                                        (onObjectSelect)="handleRowSelection($event,'#target')"
                                        (onObjectSelectDtoList)="handleContractsDtoList($event,'#target')"
                                        (onToolbarButtonClick)="handleContractButtonClick($event)">
                            </mvs-widget>
                        </div>
                    </div>

                    <div class="grid w-full mt-1">

                        <!--                        <div class="mvs-widget">-->
                        <!--                            <mvs-widget *ngIf="widgetCustomerChannels"-->
                        <!--                                        [widgetData]="widgetCustomerChannels"-->
                        <!--                            ></mvs-widget>
                                                </div>-->

                        <div class="lg:col-3 col-12">
                            <div class="mvs-widget">

                                <mvs-widget *ngIf="widgetCustomerEmergencyContacts"
                                            [widgetData]="widgetCustomerEmergencyContacts"
                                            [importObjectContext]="customerEmergencyContactContext">

                                </mvs-widget>

                                <mvs-widget *ngIf="widgetCustomerAgents"
                                            [widgetData]="widgetCustomerAgents">

                                </mvs-widget>

                                <mvs-widget *ngIf="customerBankAccountWidget"
                                            [widgetData]="customerBankAccountWidget">
                                </mvs-widget>
                            </div>
                        </div>

                        <div class="lg:col-9 col-12">
                            <div class="mvs-widget">
                                <!--                    <mvs-widget *ngIf="widgetCustomerComments"-->
                                <!--                                [importObjectContext]="importObjectContext"-->
                                <!--                                [widgetData]="widgetCustomerComments">-->
                                <!--                        -->
                                <!--                    </mvs-widget>-->

                                <mvs-object-comments
                                        headline="Kommentare"
                                        [mainObject]="objectIdentifier"
                                        [showFullText]="true"
                                        [showGroupedComments]="false"
                                        linkedEntityObjectType="cr.CustomerComment"
                                ></mvs-object-comments>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <p-dialog
            [(visible)]="dialogCombinedContract"
            [modal]="true"
            [style]="{width: '50%', height:'80%'}"
            [closeOnEscape]="false">

        <cr-create-contract-combined-form
                *ngIf="dialogCombinedContract"
                [variation]="combinedContractFormVariation"
                [customerId]="objectIdentifier.objectId">
        </cr-create-contract-combined-form>

    </p-dialog>


    <p-sidebar #bottomBar
               [style]="{'width':'95%', 'left' : '4.5%', 'border':'1px solid lightgray', 'height':'50%','min-height' : '550px'}"
               [(visible)]="contractBottomBar"
               [blockScroll]="true"
               [showCloseIcon]="true"
               [baseZIndex]="2003"
               position="bottom"
               styleClass="layout-config p-sidebar-lg fs-small p-0"
               class="sidebar-close-header"
               [modal]="false">

        <div id="target" class="cr-cutomer-table-widget-bottom p-3">

            <mvs-cr-customer-contract-details
                    [selectedContract]="selectedItem"
                    [selectedCustomer]="dto"
                    [selectedRelatedPersons]="selectedRelatedPersons"
            >
            </mvs-cr-customer-contract-details>

        </div>

    </p-sidebar>

    <!--    <mvs-sidebar *ngIf="contractBottomBar" sidebarPosition="bottom" [isSidebarOpen]="contractBottomBar">-->

    <!--        <ng-template let-object="object" mvs-sidebar-directive>-->
    <!--            <mvs-cr-customer-contract-details-->
    <!--                    [selectedContract]="selectedItem"-->
    <!--                    [selectedRelatedPersons]="selectedRelatedPersons"-->
    <!--            >-->
    <!--            </mvs-cr-customer-contract-details>-->
    <!--        </ng-template>-->

    <!--    </mvs-sidebar>-->

    <!--
    <p-dialog
            [(visible)]="dialogCreateRelatedPerson"
            [modal]="true"
            [style]="{width: '90%', height:'90%'}"
    >

            <cm-create-insurable-object-person
                    *ngIf="dialogCreateRelatedPerson"
                    [customerId]="this.objectIdentifier.objectId"
                    (onChangedObject)="handleSaveRelatedPerson($event)"
                    [backStepAllowed]="true"
            >
            </cm-create-insurable-object-person>

    </p-dialog>
    -->


</ng-container>