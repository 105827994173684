import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    MvsFormFieldOutputBaseComponent,
    MvsObjectNavigationActionEnum,
    MvsObjectNavigationEntry,
    MvsObjectNavigationService,
} from "@kvers/alpha-ui";
import {MvsCreateTicketOutputInterface} from "./interface/mvs-create-ticket-output.interface";
import {MvsCoreService, MvsMessageService, ObjectTypeService} from "@kvers/alpha-core-common";
import {TicketService} from "../../../../../tm/service/api/ticket.service";
import {ConfirmationService} from "primeng/api";

@Component({
    selector: 'mvs-create-ticket-output-component',
    templateUrl: './mvs-create-ticket-output.component.html',
})
export class MvsCreateTicketOutputComponent extends MvsFormFieldOutputBaseComponent implements OnInit, OnChanges, OnDestroy, MvsCreateTicketOutputInterface {


    objectType: string;
    objectId: number;
    ticketTypeId: number;

    busy: boolean;
    initialized: boolean;


    constructor(protected navigationService: MvsObjectNavigationService,
                protected objectService: ObjectTypeService,
                protected messageService: MvsMessageService,
                protected coreService: MvsCoreService,
                protected ticketService: TicketService,
                private confirmationService: ConfirmationService) {
        super(navigationService, objectService, coreService);
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();

    }


    /**
     * Initialize Component.
     */
    initComponent() {

        this.objectType = this.formField['uiConfigData']['objectType'];
        this.ticketTypeId = this.formField['uiConfigData']['ticketType'];
        const objectIdField = this.formField['uiConfigData']['objectId'];
        this.objectId = this.dto[objectIdField];

        this.refreshComponent();

    }

    onCreateTicket(event: MouseEvent) {
        event.stopPropagation();

        this.confirmationService.confirm({
            message: 'Möchten Sie wirklich ein neues Ticket erstellen?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // Call the ticket creation logic
                this.ticketService.createTicketForObject(this.ticketTypeId, this.objectId, this.objectType).subscribe(res => {
                    // this.messageService.add({ severity: 'success', summary: '', detail: 'Ticket created' });
                    const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(
                        'tm.Ticket', res.id, null, "Object", null, null, MvsObjectNavigationActionEnum.any
                    );
                    this.navigationService.navigateTo(mvsObjectNavigationEntry, 'right');
                }, error => {

                });
            },
            reject: () => {
            }
        });
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

            this.refreshComponent();

    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }
}
