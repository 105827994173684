import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {WfProcessTypeService} from "../../service/api/wf-process-type.service";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {ObjectBaseComponent} from "@kvers/alpha-core-common";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem} from "primeng/api";
import {WfProcessTypeDto} from "../../dto/wf-process-type.dto";
import {WfProcessTypeStepDto} from "../../dto/wf-process-type-step.dto";
import {WfProcessTypeStepActivityDto} from "../../dto/wf-process-type-step-activity.dto";
import {WfProcessTypeStepHintDto} from "../../dto/wf-process-type-step-hint.dto";
import {MvsFormControlOverwrite} from "@kvers/alpha-core-common";
import {MvsFormFieldAccessEnum} from "@kvers/alpha-core-common";
import {DtoDetail} from "@kvers/alpha-core-common";
import {WfProcessTypeStepNextDto} from "../../dto/wf-process-type-step-next.dto";
import {WfActivityTypeObjectActivityEnum} from '../../enum/wf-activity-type-object-activity.enum';
import {WfProcessTypeStepActivityService} from "../../service/api/wf-process-type-step-activity.service";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {MvsFormDto} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {WfProcessTypeFlowTypeEnum} from "../../enum/wf-process-type-flow-type.enum";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {CdkDragDrop, CdkDragEnter, CdkDragStart, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {EntityPriorityHandler} from "@kvers/alpha-core-common";
import {WfProcessTypeStepService} from "../../service/api/wf-process-type-step.service";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {DtoListAttributeRequestAggregateEnum} from "@kvers/alpha-core-common";
import {WfProcessStepService} from "../../service/api/wf-process-step.service";
import {WfProcessStepActivityService} from "../../service/api/wf-process-step-activity.service";
import {WfActivityTypeEnum} from "../../enum/wf-activity-type.enum";
import {WfProcessTypeStepProcessingEnum} from "../../enum/wf-process-type-step-processing.enum";
import {WfProcessTypeStepNextService} from "../../service/api/wf-process-type-step-next.service";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObserverService} from "@kvers/alpha-core-common";


@Component({
    selector: 'wf-process-config-overview',
    templateUrl: './wf-process-config-overview.component.html',
    styleUrls: ['./wf-process-config-overview.component.scss']
})


export class WfProcessConfigOverviewComponent extends ObjectBaseComponent implements OnInit, AfterViewInit {
    objectIdentifier: ObjectIdentifier;
    processSteps: WfProcessTypeStepDto[];
    widgetFieldsTable: WidgetData;
    widgetDefaultFieldsTable: WidgetData;
    widgetBindingFieldsTable: WidgetData;
    objectFormWidget: WidgetData;
    selectObjectIdentifier: ObjectIdentifier;
    formControlOverwrite: MvsFormControlOverwrite;
    createDefaultDto: DtoDetail;
    createDefaultDtoForFormDefaults: DtoDetail;
    createDefaultDtoForBinding: DtoDetail;
    formControlOverwriteForFormDefaults: MvsFormControlOverwrite;
    formControlOverwriteForBinding: MvsFormControlOverwrite;
    activityType = WfActivityTypeObjectActivityEnum;
    configurationItems: MenuItem[] | undefined;
    selectedObject;
    selectedStep: WfProcessTypeStepDto;
    processForm: MvsFormDto;
    flowType = WfProcessTypeFlowTypeEnum;
    dto: WfProcessTypeDto;
    tabName: string = 'Process';
    wfHoverStep: string | null = null;
    stepActivityFormDefaultId: number;
    stepActivityBindingId: number;
    tabIndex: number;
    visible: boolean;
    showRuntimeInfo: boolean;
    dialogHeaderLabel: string;
    activityWaitTimeFrequency: any;
    draggedActivity: WfProcessTypeStepActivityDto;
    // Global variable to store the dragged next step
    draggedNextStep: WfProcessTypeStepDto;
    // Flag indicating whether an activity drag has started
    activityDragStarted: boolean = false;
    // Flag indicating whether a step drag has started
    stepDragStarted: boolean = false;
    // Menu items for next steps configuration
    nextStepsConfigurationItems: MenuItem[];
    // Flag indicating overlay visibility for activity steps
    isActivityStepsOverlay: boolean;

    showProcessStart: boolean;

    selectedActivity: WfProcessTypeStepActivityDto;

    @ViewChildren(CdkDropList) activityDropLists!: QueryList<CdkDropList>;
    @ViewChildren(CdkDropList) stepDropLists!: QueryList<CdkDropList>;
    @ViewChild('slider') sliderRef: ElementRef;

    constructor(
        protected coreService: MvsCoreService,
        protected messageService: MvsMessageService,
        protected confirmationService: ConfirmationService,
        protected processTypeService: WfProcessTypeService,
        protected processStepActivityService: WfProcessStepActivityService,
        protected processTypeStepService: WfProcessTypeStepService,
        protected processStepService: WfProcessStepService,
        protected processTypeStepActivityService: WfProcessTypeStepActivityService,
        protected processTypeStepNextService: WfProcessTypeStepNextService,
        protected override observerService: ObserverService
    ) {

        super(coreService, messageService, confirmationService, observerService)

        this.configurationItems = [
            {
                icon: 'fa-regular fa-pen-to-square',
                label: 'Edit Step',
                command: () => this.onStepSelect(this.selectedStep)
            },
            {
                icon: 'fa-regular fa-circle-info',
                label: 'Add Hint',
                command: () => this.onCreateHint(this.selectedStep)
            },
            {
                icon: 'fa-regular fa-trash',
                label: 'Delete Step',
                command: () => this.onStepDelete(this.selectedStep)
            }
        ];
    }

    ngOnInit() {

        // this.objectIdentifier = new ObjectIdentifier('wf.WfProcessType', 1093931);
        this.selectObjectIdentifier = this.objectIdentifier.clone();
        super.ngOnInit();

    }

    /**
     * Initialize.
     */
    initComponent() {

        this.processTypeStepActivityService.template(new DtoTemplate()).subscribe(value => {
            this.processForm = value;

            this.activityWaitTimeFrequency = this.processForm.getFormField('waitTimeFrequency').valueList.entries;
        });
    }

    /**
     * Retrieve Process Type and all related information.
     * @protected
     */
    protected retrieveObject() {
        this.busy = true;

        // retrieve full process information
        this.processTypeService.getFull(this.objectIdentifier.objectId, true).subscribe((value: WfProcessTypeDto) => {
            this.dto = value;
            this.processSteps = this.dto['steps'];
            // const operations = EntityPriorityHandler.adjustPriority(this.processSteps, 'position', 10);
            this.busy = false;

        });

    }

    processRuntimeInfo() {

        if (!this.showRuntimeInfo) {
            return;
        }

        const filterCriteriaList: FilterCriteria[] = [FilterCriteria.createOrFromArray("processTypeStep", "id", this.processSteps)];

        const objectRequestList = ObjectRequestListGroupBy.create(
            false,
            filterCriteriaList,
            [],
            ["processTypeStep"],
            [new ObjectRequestListAttribute("processTypeStep", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);

        // retrieving the processSteps from processTypeSteps

        this.processStepService.groupBy(objectRequestList).subscribe(res => {

            const processStepCounts = res.entries;

            if (res.entries) {

                this.processSteps.forEach(steps => {
                    const processStepCount = processStepCounts.find(counts => counts['processTypeStepDtoId'] === steps.id);
                    if (processStepCount) {
                        steps['processStepsCount'] = processStepCount['processTypeStep_count'];
                    }
                });

            }
        })


        // retrieving the processStepActivity from processTypeStepActivities

        this.processTypeStepActivityService.list(objectRequestList).subscribe(res => {

            const filterCriteria: FilterCriteria[] = [FilterCriteria.createOrFromArray("processTypeStepActivity", "id", res.entries)];
            const objectRequestList = ObjectRequestListGroupBy.create(
                false,
                filterCriteria,
                [],
                ["processTypeStepActivity"],
                [new ObjectRequestListAttribute("processTypeStepActivity", "Anzahl", DtoListAttributeRequestAggregateEnum.count)]);


            this.processStepActivityService.groupBy(objectRequestList).subscribe(res => {

                const processStepActivityCounts = res.entries;

                if (res.entries) {

                    this.processSteps.forEach(step => {

                        if (step.activities) {
                            step.activities.forEach(activity => {
                                const processStepActivityCount = processStepActivityCounts.find(counts => counts['processTypeStepActivityDtoId'] === activity.id);
                                if (processStepActivityCount) {
                                    activity['processTypeStepActivity_count'] = processStepActivityCount['processTypeStepActivity_count'];
                                }
                            });
                        }
                    });
                }
            });
        })
    }


    onChangeObject(object: ObjectChangeInformation) {

        this.selectedObject = object?.after?.id;

        if (object.objectType == "wf.WfProcessTypeStepActivity") {
            const activity: WfProcessTypeStepActivityDto = <WfProcessTypeStepActivityDto>object?.after;
            if (activity) {
                this.onActivitySelect(activity, this.selectedStep);
            } else {
                this.onCreateActivity(this.selectedStep);
            }
        }

        if (object.objectType == 'wf.WfProcessTypeField') {
            this.selectObjectIdentifier.objectType = 'wf.WfProcessTypeField';
            this.selectObjectIdentifier.objectId = object?.after?.id;
            this.uiRefreshFieldsWidget();
        }

        if (object.objectType == 'wf.WfProcessTypeResult') {
            this.selectObjectIdentifier.objectType = 'wf.WfProcessTypeResult';
            this.selectObjectIdentifier.objectId = object?.after?.id;
            this.uiRefreshProcessResultWidget();
        }

        if (object.objectType == 'wf.WfProcessTypeStepActivityFormDefault') {
            this.stepActivityFormDefaultId = object?.after?.id;
            this.uiRefreshDefaultFieldsWidget(object?.after['stepActivityDtoId']);
        }

        if (object.objectType == 'wf.WfProcessTypeStepActivityBinding') {
            this.stepActivityBindingId = object?.after?.id;
            this.uiRefreshBindingFieldsWidget(object?.after['processTypeStepActivityDtoId']);
        }

        this.retrieveObject();

    }

    private initializeForms(objectType: string, objectId: number = null, fieldValue: string, fieldId: number, stepPosition?: number) {

        this.setHeaderLabel(objectType);

        if (objectType != 'wf.WfProcessTypeField' && objectType != 'wf.WfProcessTypeResult') {
            this.widgetFieldsTable = null;
        }

        this.createDefaultDto = new DtoDetail({});
        this.createDefaultDto[fieldValue] = fieldId;

        this.formControlOverwrite = new MvsFormControlOverwrite();
        this.formControlOverwrite.addField(fieldValue, MvsFormFieldAccessEnum.HIDDEN);

        if (objectType == 'wf.WfProcessTypeStep' && !this.dto.steps) {
            this.createDefaultDto['position'] = stepPosition ? stepPosition : 10000;
            this.formControlOverwrite.addField('position', MvsFormFieldAccessEnum.HIDDEN);
        }

        if (objectType == 'wf.WfProcessTypeStep' && stepPosition) {
            this.createDefaultDto['position'] = stepPosition;
            this.formControlOverwrite.addField('position', MvsFormFieldAccessEnum.HIDDEN);
        }

        if (objectType != 'wf.WfProcessTypeStepActivityFormDefault') {
            this.selectObjectIdentifier.objectType = objectType;
            this.selectObjectIdentifier.objectId = objectId;
        }

        if (objectType != 'wf.WfProcessTypeStepActivityBinding') {
            this.selectObjectIdentifier.objectType = objectType;
            this.selectObjectIdentifier.objectId = objectId;
        }

        this.refreshFormObject(objectType, this.createDefaultDto, this.formControlOverwrite, objectId)
        if (!this.isActivityStepsOverlay) {
            this.visible = true;
        }

        this.isActivityStepsOverlay = false;

    }


    /**
     * open forms for selected step
     * @param step
     */
    onStepSelect(step: WfProcessTypeStepDto) {
        this.tabName = 'Step';
        this.selectedObject = step;
        this.selectedObject["_class"] = "wf-selected-step";
        this.initializeForms('wf.WfProcessTypeStep', step.id, 'processTypeDtoId', step.id);
    }

    /**
     * Delete selected step
     * @param step
     */
    onStepDelete(step: WfProcessTypeStepDto) {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Sind Sie sicher, dass Sie fortfahren möchten?',
            icon: 'fa-regular fa-triangle-exclamation',
            acceptButtonStyleClass: "p-button-danger p-button-text",
            rejectButtonStyleClass: "p-button-text p-button-text",
            accept: () => {
                this.processTypeStepService.delete(step.id).subscribe(() => {
                    this.retrieveObject();
                })
            },
            reject: () => {
                //reject action
            }
        });


    }

    /**
     * open forms for selected activity
     * @param activity
     */
    onActivitySelect(activity: WfProcessTypeStepActivityDto, step: WfProcessTypeStepDto) {

        this.selectedActivity = activity;

        this.selectedStep = step

        if (this.isActivityStepsOverlay) {
            return;
        }

        this.uiRefreshDefaultFieldsWidget(activity.id);
        this.uiRefreshBindingFieldsWidget(activity.id);

        // defaulting form with values for form default
        this.createDefaultDtoForFormDefaults = new DtoDetail({});
        this.createDefaultDtoForFormDefaults['stepActivityDtoId'] = activity.id;
        this.formControlOverwriteForFormDefaults = new MvsFormControlOverwrite();
        this.formControlOverwriteForFormDefaults.addField('stepActivityDtoId', MvsFormFieldAccessEnum.HIDDEN);

        // defaulting form with values for bindings
        this.createDefaultDtoForBinding = new DtoDetail({});
        this.createDefaultDtoForBinding['processTypeStepActivityDtoId'] = activity.id;
        this.formControlOverwriteForBinding = new MvsFormControlOverwrite();
        this.formControlOverwriteForBinding.addField('processTypeStepActivityDtoId', MvsFormFieldAccessEnum.HIDDEN);

        this.tabName = 'Activity';
        this.selectedObject = activity;
        this.selectedObject["_class"] = "wf-selected-box";
        this.initializeForms('wf.WfProcessTypeStepActivity', activity.id, 'processTypeStepDtoId', activity.processTypeStepDtoId);
    }

    /**
     * open forms for selected hint
     * @param hint
     */
    onHintSelect(hint: WfProcessTypeStepHintDto) {
        this.tabName = 'Hint';
        this.selectedObject = hint;
        this.selectedObject["_class"] = "wf-selected-box";
        this.initializeForms('wf.WfProcessTypeStepHint', hint.id, 'processTypeStepDtoId', hint.processTypeStepDtoId);
    }

    /**
     * open forms for selected hint
     * @param nextStep
     */
    onNextStepSelect(nextStep: WfProcessTypeStepNextDto) {
        this.tabName = 'Next Step';
        this.selectedObject = nextStep;
        this.selectedObject["_class"] = "wf-selected-box";
        this.initializeForms('wf.WfProcessTypeStepNext', nextStep.id, 'processTypeStepDtoId', nextStep.processTypeStepDtoId);
    }

    onNextStepCreate(step: WfProcessTypeStepDto) {
        this.tabName = 'Next Step';
        this.selectedObject = null;
        this.initializeForms('wf.WfProcessTypeStepNext', 0, 'processTypeStepDtoId', step.id);
    }


    /**
     * open form to show process info
     */
    onShowProcess() {
        this.tabName = 'Process';
        this.selectedObject = null;
        this.initializeForms(this.objectIdentifier.objectType, this.objectIdentifier.objectId, null, null);
    }


    /**
     * open form to create a new step
     */
    onCreateStep() {
        this.tabName = 'Step';
        this.selectedObject = null;
        this.initializeForms('wf.WfProcessTypeStep', null, 'processTypeDtoId', this.dto.id);
    }

    onCreateStepInBetween(step: WfProcessTypeStepDto[], index) {
        let position: number;
        if (index == 0) {
            position = (step[index].position / 2);
        } else if (step.length == index) {
            position = step[index - 1].position + 10000;
        } else {
            if (step[index - 1].position >= (step[index].position / 2) || step[index].position >= (step[index - 1].position / 2)) {
                position = (step[index - 1].position + step[index].position) / 2;
            } else {
                position = this.halfwayBetween(step[index - 1].position, step[index].position);
            }
        }

        this.tabName = 'Step';
        this.selectedObject = null;
        this.initializeForms('wf.WfProcessTypeStep', null, 'processTypeDtoId', this.dto.id, position);
    }

    halfwayBetween(num1: number, num2: number): number {
        return (num1 + num2) / 2 + Math.abs(num1 - num2) / 2;
    }


    /**
     * open form to show and create fields for steps
     */
    onCreateFields(tabName: string) {
        if (tabName == 'Field') {
            this.tabName = 'Fields';
            this.selectedObject = null;
            this.initializeForms('wf.WfProcessTypeField', null, 'typeDtoId', this.dto.id);
            this.uiRefreshFieldsWidget();
        }

        if (tabName == 'Result') {
            this.tabName = 'Process Result';
            this.selectedObject = null;
            this.initializeForms('wf.WfProcessTypeResult', null, 'processTypeDtoId', this.dto.id);
            this.uiRefreshProcessResultWidget();
        }

    }

    /**
     * open form to create a new activity
     * @param step
     */
    onCreateActivity(step: WfProcessTypeStepDto) {
        this.tabName = 'Activity';
        this.selectedObject = null;
        this.initializeForms('wf.WfProcessTypeStepActivity', null, 'processTypeStepDtoId', step.id);
    }

    /**
     * open form to create a new hint
     * @param step
     */
    onCreateHint(step: WfProcessTypeStepDto) {
        this.tabName = 'Hint';
        this.selectedObject = null;
        this.initializeForms('wf.WfProcessTypeStepHint', null, 'processTypeStepDtoId', step.id);
    }

    /**
     * open form to create a new hint
     * @param step
     */
    onCreateNextStep(step: WfProcessTypeStepDto) {
        this.tabName = 'Next Step';
        this.selectedObject = null;
        this.initializeForms('wf.WfProcessTypeStepNext', null, 'processTypeStepDtoId', step.id);
    }


    onParentWfHover(hoverStep: string) {
        this.wfHoverStep = hoverStep;
    }

    clearHoveredWf() {
        this.wfHoverStep = null;
    }

    getActivityField(activityType: WfActivityTypeEnum) {

        return this.processForm?.formFields['activityType']?.valueList?.entries[activityType]?.label;
    }

    getActivityImage(activityType: WfActivityTypeEnum) {
        return this.processForm?.formFields['activityType']?.valueList?.entries[activityType]?.image;
    }

    onFieldSelect(object: ObjectIdentifierData) {
        if (this.selectObjectIdentifier.objectType == 'wf.WfProcessTypeField') {
            this.tabName = 'Fields';
            this.selectedObject = object.objectId;
            // this.selectedObject["_class"] = "wf-selected-box";
            this.initializeForms(object.objectType, object.objectId, 'typeDtoId', object.data.typeDtoId);
        } else if (this.selectObjectIdentifier.objectType == 'wf.WfProcessTypeResult') {
            this.tabName = 'Process Results';
            this.selectedObject = object.objectId;
            // this.selectedObject["_class"] = "wf-selected-box";
            this.initializeForms(object.objectType, object.objectId, 'processTypeDtoId', object.data.typeDtoId);
        }

    }

    onDefaultFieldSelect(object: ObjectIdentifierData) {
        this.stepActivityFormDefaultId = object.objectId
        this.tabName = 'Activity';
        this.selectedObject = object.objectId;
        // this.selectedObject["_class"] = "wf-selected-box";
        this.initializeForms(object.objectType, object.objectId, 'stepActivityDtoId', object.data.stepActivityDtoId);
    }

    onBindingSelect(object: ObjectIdentifierData) {
        this.stepActivityBindingId = object.objectId
        this.tabName = 'Activity';
        this.selectedObject = object.objectId;
        // this.selectedObject["_class"] = "wf-selected-box";
        this.initializeForms(object.objectType, object.objectId, 'processTypeStepActivityDtoId', object.data.stepActivityDtoId);
    }

    changeTab(event) {
        this.tabIndex = event.index;
        this.onTabChangeRefreshFormObject(event.index);
    }

    onTabChangeRefreshFormObject(index: number) {
        if (index == 0) {
            if (this.selectObjectIdentifier.objectType == 'wf.WfProcessTypeStepActivityFormDefault' || this.selectObjectIdentifier.objectType == 'wf.WfProcessTypeStepActivityBinding') {
                this.selectObjectIdentifier = new ObjectIdentifier("wf.WfProcessTypeStepActivity", this.selectedActivity.id);
            }
            this.refreshFormObject(this.selectObjectIdentifier.objectType, this.createDefaultDto, this.formControlOverwrite, this.selectObjectIdentifier.objectId);
        } else if (index == 1) {
            this.refreshFormObject('wf.WfProcessTypeStepActivityFormDefault', this.createDefaultDtoForFormDefaults, this.formControlOverwriteForFormDefaults, this.stepActivityFormDefaultId);
        } else if (index == 2) {
            this.refreshFormObject('wf.WfProcessTypeStepActivityBinding', this.createDefaultDtoForBinding, this.formControlOverwriteForBinding, this.stepActivityBindingId);
        }
    }

    resetForm() {
        this.onTabChangeRefreshFormObject(this.tabIndex);
    }

    drop(event) {

        moveItemInArray(this.processSteps, event.previousIndex, event.currentIndex);

        const operations = EntityPriorityHandler.adjustPriority(this.processSteps, 'position', 10000);

        if (operations.length) {
            this.busy = true;
        }

        for (let operation of operations) {
            const processTypeDto: WfProcessTypeStepDto = new WfProcessTypeStepDto();
            processTypeDto.id = operation.entry['id'];
            processTypeDto.position = operation.entry['position'];

            this.processTypeStepService.update(processTypeDto).subscribe(() => {
                this.busy = false;
                this.retrieveObject();
            })
        }

    }

    uiRefreshFieldsWidget() {
        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(FilterCriteria.createSingleCondition("type", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId, null));

        const objectRequestList = ObjectRequestList.createBasic(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)]);

        // this.widgetFieldsTable.setParamValue("size", "S");

        this.widgetFieldsTable = WidgetFactory.createWidgetList(
            'wf.config-overview.process-type-field.list',
            'Felder',
            'table',
            'list',
            'entity',
            'wf.WfProcessTypeField',
            'Keine Daten vorhanden',
            objectRequestList);
    }

    uiRefreshProcessResultWidget() {
        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(FilterCriteria.createSingleCondition("processType", FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId, null));

        const objectRequestList = ObjectRequestList.createBasic(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)]);

        // this.widgetFieldsTable.setParamValue("size", "S");

        this.widgetFieldsTable = WidgetFactory.createWidgetList(
            'wf.config-overview.process-type-result.list',
            'Process Result',
            'table',
            'list',
            'entity',
            'wf.WfProcessTypeResult',
            'Keine Daten vorhanden',
            objectRequestList);
    }

    uiRefreshDefaultFieldsWidget(activityId: number) {
        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(FilterCriteria.createSingleCondition("stepActivity", FilterCriteria.cOperatorEqual, activityId, null));

        const objectRequestList = ObjectRequestList.createWithPaging(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 5));

        // this.widgetDefaultFieldsTable.setParamValue("size", "S");

        this.widgetDefaultFieldsTable = WidgetFactory.createWidgetList(
            'wf.config-overview.process-type-activity-default-field.list',
            'Default Fields',
            'table',
            'list',
            'entity',
            'wf.WfProcessTypeStepActivityFormDefault',
            'Keine Daten vorhanden',
            objectRequestList);
    }

    uiRefreshBindingFieldsWidget(activityId: number) {
        const filterCriteria: FilterCriteria[] = [];

        filterCriteria.push(FilterCriteria.createSingleCondition("processTypeStepActivity", FilterCriteria.cOperatorEqual, activityId, null));

        const objectRequestList = ObjectRequestList.createWithPaging(
            true,
            filterCriteria,
            [new Sorting("createdDate", false)],
            PagingDto.create(0, 5));
        //
        // this.widgetBindingFieldsTable.setParamValue("size", "S");

        this.widgetBindingFieldsTable = WidgetFactory.createWidgetList(
            'wf.config-overview.process-type-activity-binding-field.list',
            'Binding Fields',
            'table',
            'list',
            'entity',
            'wf.WfProcessTypeStepActivityBinding',
            'Keine Daten vorhanden',
            objectRequestList);
    }

    /**
     * creates form
     * @param dataProviderObject
     * @param createDefaultDto
     * @param formControlOverwrite
     * @param objectId
     */
    refreshFormObject(dataProviderObject: string, createDefaultDto: DtoDetail, formControlOverwrite: MvsFormControlOverwrite, objectId: number = 0) {

        let name: string = 'Anlegen';
        if (objectId) {
            name = 'Bearbeiten';
        }

        this.objectFormWidget = null;


        this.objectFormWidget = WidgetFactory.createWidget(
            'wf.processed.config.object.widget.' + dataProviderObject,
            name,
            "object",
            "list",
            "entity",
            dataProviderObject,

            WidgetDataParam.create("size", "S"),
            WidgetDataParam.create("objectId", objectId),
            WidgetDataParam.create("formControlOverwrite", formControlOverwrite),
            WidgetDataParam.create("createDefaultDto", createDefaultDto),
        );
    }

    /**
     * function call when dialog is closed
     * @param event
     */
    onDialogHide(event) {
        this.tabIndex = 0;
        this.selectedObject = 0;
        this.stepActivityFormDefaultId = 0;
        this.stepActivityBindingId = 0;
    }


    setHeaderLabel(objectType: string) {
        switch (objectType) {
            case 'wf.WfProcessType': {
                this.dialogHeaderLabel = 'Process';
                break;
            }
            case 'wf.WfProcessTypeStep': {
                this.dialogHeaderLabel = 'Step';
                break;
            }
            case 'wf.WfProcessTypeStepActivity': {
                this.dialogHeaderLabel = 'Activity';
                break;
            }
            case 'wf.WfProcessTypeField': {
                this.dialogHeaderLabel = 'Felder';
                break;
            }
            case 'wf.WfProcessTypeResult': {
                this.dialogHeaderLabel = 'Results';
                break;
            }
            case 'wf.WfProcessTypeStepNext': {
                this.dialogHeaderLabel = 'Next Steps';
                break;
            }
            case 'wf.WfProcessTypeStepHint': {
                this.dialogHeaderLabel = 'Hint';
                break;
            }
        }
    }

    /**
     * Initiates the drag-and-scroll functionality on the specified slider element.
     */
    screenDragAndScrollOnHold() {
        // Variables to track mouse state and initial positions
        let mouseDown = false;
        let startX, startY, scrollLeft, scrollTop;

        // Get the slider element by class name
        // const slider: any = document.querySelector('.parent');

        const slider = this.sliderRef.nativeElement;


        // Function to start dragging on mouse down
        const startDragging = (e) => {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            startY = e.pageY - slider.offsetTop;
            scrollLeft = slider.scrollLeft;
            scrollTop = slider.scrollTop;
        };

        // Function to stop dragging on mouse up
        const stopDragging = () => {
            mouseDown = false;
        };

        // Function to handle mouse move and scroll the slider
        const move = (e) => {
            e.preventDefault();
            if (!mouseDown) {
                return;
            }

            // Calculate the new scroll positions based on mouse movement
            const x = e.pageX - slider.offsetLeft;
            const y = e.pageY - slider.offsetTop;
            const scrollX = x - startX;
            const scrollY = y - startY;

            // Update the slider's scroll position
            slider.scrollLeft = scrollLeft - scrollX;
            slider.scrollTop = scrollTop - scrollY;
        };

        // Add event listeners for mouse actions
        slider.addEventListener('mousemove', move, false);
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    }

    /**
     * Handles the drop of an activity onto a step in the workflow.
     * @param event - The drop event.
     * @param step - The workflow step.
     */
    activityDrop(event: any, step: WfProcessTypeStepDto): void {
        // Check if the activity is already in the step
        const sameStep = step?.activities?.find(activity => activity?.id == this.draggedActivity.id);

        if (sameStep) {
            return;
        }

        // Check if the dragged activity is main or background type
        const isMainOrBackground =
            this.draggedActivity.processing === WfProcessTypeStepProcessingEnum.main ||
            this.draggedActivity.processing === WfProcessTypeStepProcessingEnum.background;

        // Handle creating or updating the activity based on its type
        if (isMainOrBackground) {
            const activityFound = step.activities.find(activity =>
                activity.processing == WfProcessTypeStepProcessingEnum.main ||
                activity.processing == WfProcessTypeStepProcessingEnum.background
            );

            // Display a message if main activity already exists, otherwise create a new main activity
            if (activityFound) {
                this.messageService.showInfoMessage('Already Exist', 'Main activity already exists');
            } else {
                this.handleCreateActivity(step);
            }
        } else {
            this.handleCreateActivity(step);
        }

        // Reset drag-related variables
        this.draggedActivity = null;
        this.activityDragStarted = false;
        this.stepDragStarted = false;
    }

    /**
     * Handles the drop of a next step onto a workflow step.
     * @param event - The drop event.
     * @param step - The workflow step.
     */
    nextStepDrop(event: any, step: WfProcessTypeStepDto): void {
        // Check if the dropped next step is the same as the target step
        if (step.id == this.draggedNextStep.id) {
            return;
        }

        // Check if the dropped next steps contains the target step
        const stepExist = this.draggedNextStep?.nextSteps?.find(nextStep => nextStep.nextProcessTypeStepDtoId == step.id);

        if (stepExist) {
            this.messageService.showInfoMessage('Next Step Exist', 'Next Step Already Exist');
            return;
        }

        // Create a new WfProcessTypeStepNextDto and associate it with the step
        const dtoDetail: WfProcessTypeStepNextDto = new WfProcessTypeStepNextDto();
        dtoDetail.processTypeStepDtoId = this.draggedNextStep.id;
        dtoDetail.nextProcessTypeStepDtoId = step.id;
        dtoDetail.name = 'Zur ' + step.name;
        dtoDetail.position = 10;

        // Create the next step association and refresh the workflow
        this.processTypeStepNextService.create(dtoDetail).subscribe(() => {
            this.retrieveObject();
            this.messageService.showSuccessMessage('Next Step added', 'Next Step added');
        });

        // Reset drag-related variables
        this.draggedNextStep = null;
        this.activityDragStarted = false;
        this.stepDragStarted = false;
    }

    /**
     * Handles the creation of a new activity associated with a workflow step.
     * @param step - The workflow step.
     * @private
     */
    private handleCreateActivity(step: WfProcessTypeStepDto): void {
        // Create a new WfProcessTypeStepActivityDto based on the dragged activity
        const dtoDetail: WfProcessTypeStepActivityDto = new WfProcessTypeStepActivityDto(this.draggedActivity);
        dtoDetail.id = null;
        dtoDetail.processTypeStepDtoId = step.id;
        dtoDetail.processTypeStepNextDtoId = null;

        // Create the activity and refresh the workflow
        this.processTypeStepActivityService.create(dtoDetail).subscribe(() => {
            this.retrieveObject();
            this.messageService.showSuccessMessage('Activity added', 'Activity added');
        });
    }

    /**
     * Initializes the IDs for activity and step drop lists after view initialization.
     */
    ngAfterViewInit() {
        // Set dynamic IDs for activity drop lists
        this.activityDropLists.forEach((dropList, index) => {
            dropList.id = 'dynamicActivityDropList_' + index;
        });

        // Set dynamic IDs for step drop lists
        this.stepDropLists.forEach((dropList, index) => {
            dropList.id = 'dynamicStepDropList_' + index;
        });

        this.screenDragAndScrollOnHold();
    }

    /**
     * Gets the connected drop lists for activity.
     * @returns An array of connected drop lists.
     */
    getActivityConnectedLists(): (CdkDropList | string)[] {
        return this.activityDropLists ? this.activityDropLists.toArray() : [];
    }

    /**
     * Gets the connected drop lists for steps.
     * @returns An array of connected drop lists.
     */
    getStepConnectedLists(): (CdkDropList | string)[] {
        return this.stepDropLists ? this.stepDropLists.toArray() : [];
    }

    /**
     * Handles the start of dragging an activity.
     * @param event - The drag start event.
     * @param activity - The dragged activity.
     */
    onDragActivityStarted(event: CdkDragStart, activity: WfProcessTypeStepActivityDto): void {
        // Store the dragged activity
        this.draggedActivity = activity;
    }

    /**
     * Handles the start of dragging a next step.
     * @param event - The drag start event.
     * @param step - The dragged next step.
     */
    onDragNextStepStarted(event: CdkDragStart, step: WfProcessTypeStepDto): void {
        // Store the dragged next step
        this.draggedNextStep = step;
    }

    /**
     * Handles entering the dragged item into an activity drop zone.
     * @param event - The drag enter event.
     */
    onActivityDragEntered(event: CdkDragEnter<any>): void {
        // Check if the dragged item is an activity
        this.activityDragStarted = !!event.item.data;
    }

    /**
     * Handles entering the dragged item into a step drop zone.
     * @param event - The drag enter event.
     */
    onStepDragEntered(event: CdkDragEnter<any>): void {
        // Check if the dragged item is a step
        this.stepDragStarted = !!event.item.data;
    }

    /**
     * Handles the drop event for either activity or step.
     * @param event - The drop event.
     * @param step - The target workflow step (if applicable).
     */
    onDrop(event: CdkDragDrop<any[]>, step?: WfProcessTypeStepDto): void {
        if (!step) {
            return;
        }

        // Check if an activity is being dragged
        if (this.activityDragStarted && this.draggedActivity) {
            // Handle the drop for activity
            this.activityDrop(event, step);
        } else {
            // Handle the drop for the outer container
            this.nextStepDrop(event, step);
        }

        // Reset drag-related variables
        this.stepDragStarted = false;
        this.activityDragStarted = false;
    }

    /**
     * Checks if there is a missing main activity in the provided activities.
     * @param activities - The list of activities in a workflow step.
     * @returns The missing main activity or null if none is missing.
     */
    handleMainActivityMissing(activities: WfProcessTypeStepActivityDto[]): WfProcessTypeStepActivityDto {
        return activities?.find(activity =>
            activity?.processing == WfProcessTypeStepProcessingEnum.main ||
            activity?.processing == WfProcessTypeStepProcessingEnum.background
        );
    }

    /**
     * Checks if there is a missing optional activity in the provided activities.
     * @param activities - The list of activities in a workflow step.
     * @returns The missing optional activity or null if none is missing.
     */
    handleOptionalActivityMissing(activities: WfProcessTypeStepActivityDto[]): WfProcessTypeStepActivityDto {
        return activities?.find(activity =>
            activity?.processing == WfProcessTypeStepProcessingEnum.optional ||
            activity?.processing == WfProcessTypeStepProcessingEnum.background_time
        );
    }

    /**
     * Configures and opens the context menu for selecting next steps for an activity.
     * @param menu - The context menu.
     * @param nextSteps - The list of available next steps.
     * @param activity - The selected activity.
     * @param event - The triggering event.
     * @param detachStep - Indicates whether to provide an option to detach the next step (default is false).
     */
    // openStepsMenu(menu, nextSteps: WfProcessTypeStepNextDto[], activity: WfProcessTypeStepActivityDto, event, detachStep: boolean = false) {
    //     // Initialize the configuration items for the context menu
    //     this.nextStepsConfigurationItems = [];
    //
    //     // Toggle the menu visibility
    //     menu.toggle(event);
    //
    //     // Populate the configuration items based on the available next steps
    //     for (let nextStep of nextSteps) {
    //         this.nextStepsConfigurationItems.push({
    //             label: nextStep.name,
    //             command: () => {
    //                 // Update the activity with the selected next step
    //                 const dtoDetail: WfProcessTypeStepActivityDto = new WfProcessTypeStepActivityDto();
    //                 dtoDetail.id = activity.id;
    //                 dtoDetail.processTypeStepNextDtoId = nextStep.id;
    //                 this.processTypeStepActivityService.update(dtoDetail).subscribe(() => {
    //                     this.retrieveObject();
    //                     this.messageService.showSuccessMessage('Next Step', 'Next Step Updated Success');
    //                 });
    //             }
    //         });
    //     }
    //
    //     // Add an option to detach the next step if needed
    //     if (detachStep) {
    //         this.nextStepsConfigurationItems.push({
    //             label: 'Detach NextStep',
    //             command: () => {
    //                 // Detach the next step from the activity
    //                 const dtoDetail: WfProcessTypeStepActivityDto = new WfProcessTypeStepActivityDto();
    //                 dtoDetail.id = activity.id;
    //                 dtoDetail.processTypeStepNextDtoId = null;
    //                 this.processTypeStepActivityService.update(dtoDetail).subscribe(() => {
    //                     this.retrieveObject();
    //                     this.messageService.showSuccessMessage('Next Step', 'Next Step Removed Success');
    //                 });
    //             }
    //         });
    //     }
    //
    //     // Set flags for overlay visibility
    //     this.isActivityStepsOverlay = true;
    //     this.visible = false;
    // }


    /**
     * Common function for updating activity and displaying success messages.
     * @param activityDto - The activity DTO to be updated.
     * @param successMessage - The success message to be displayed.
     * @param detachStep - Indicates whether to detach the next step (default is false).
     * @param nextStepId - The ID of the next step (if applicable).
     */
    private updateActivityAndShowSuccess(
        activityDto: WfProcessTypeStepActivityDto,
        successMessage: string,
        detachStep: boolean = false,
        nextStepId?: number
    ): void {
        // Update the activity with the selected next step
        activityDto.processTypeStepNextDtoId = nextStepId || null;

        // Use your service to update the activity
        this.processTypeStepActivityService.update(activityDto).subscribe(() => {
            this.retrieveObject();
            this.messageService.showSuccessMessage('Next Step', successMessage);
        });

        // Detach the next step if needed
        if (detachStep) {
            const dtoDetail: WfProcessTypeStepActivityDto = new WfProcessTypeStepActivityDto();
            dtoDetail.id = activityDto.id;
            dtoDetail.processTypeStepNextDtoId = null;
            this.processTypeStepActivityService.update(dtoDetail).subscribe(() => {
                this.retrieveObject();
                this.messageService.showSuccessMessage('Next Step', 'Next Step Removed Success');
            });
        }
    }

    /**
     * Handles the creation of a new activity associated with a workflow step.
     * @param step - The workflow step.
     * @private
     */
    private handleUpdateActivity(step: WfProcessTypeStepDto): void {
        const dtoDetail: WfProcessTypeStepActivityDto = new WfProcessTypeStepActivityDto(this.draggedActivity);
        dtoDetail.id = null;
        dtoDetail.processTypeStepDtoId = step.id;

        // Call the common function to update activity and display success message
        this.updateActivityAndShowSuccess(dtoDetail, 'Activity added');
    }

    /**
     * Configures and opens the context menu for selecting next steps for an activity.
     * @param menu - The context menu.
     * @param nextSteps - The list of available next steps.
     * @param activity - The selected activity.
     * @param event - The triggering event.
     * @param detachStep - Indicates whether to provide an option to detach the next step (default is false).
     */
    openStepsMenu(menu, nextSteps: WfProcessTypeStepNextDto[], activity: WfProcessTypeStepActivityDto, event, detachStep: boolean = false) {
        // ... (existing code)

        this.nextStepsConfigurationItems = [];

        // Toggle the menu visibility
        menu.toggle(event);

        // Populate the configuration items based on the available next steps
        for (let nextStep of nextSteps) {
            this.nextStepsConfigurationItems.push({
                label: nextStep.name,
                command: () => {
                    // Call the common function to update activity and display success message
                    this.updateActivityAndShowSuccess(activity, 'Next Step Updated Success', false, nextStep.id);
                }
            });
        }

        // Add an option to detach the next step if needed
        if (detachStep) {
            this.nextStepsConfigurationItems.push({
                label: 'Detach NextStep',
                command: () => {
                    // Call the common function to update activity and display success message
                    this.updateActivityAndShowSuccess(activity, 'Next Step Removed Success', true);
                }
            });
        }

        // Set flags for overlay visibility
        this.isActivityStepsOverlay = true;
        this.visible = false;
    }

    createNextStep(step: WfProcessTypeStepDto) {
        this.onNextStepCreate(step)
    }


}

