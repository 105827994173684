import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {MvsFormFieldOutputBaseComponent} from "@kvers/alpha-ui";
import {UserService} from "../../../../../um/service/api/user.service";
import {UmUserDto} from "../../../../../um/dto/um-user.dto";
import {UserProfileHelper} from "../../../../../um/components/user-profile/helper/user-profile-helper";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsCoreService, ObjectTypeService} from "@kvers/alpha-core-common";
import {UmUserService} from "../../../../../um/service/api/um-user.service";
import {LogicService} from "../../../../../lg/service/api/logic.service";
import {LogicDto} from "../../../../../lg/dto/logic.dto";
import {LogicLanguage} from "../../../../../lg/enum/logic-language.enum";


@Component({
  selector: 'mvs-form-control-output-logic-display',
  templateUrl: `mvs-form-field-output-logic-display.component.html`,
  styleUrls: ['mvs-form-field-output-logic-display.component.scss'],
})
export class MvsFormFieldOutputLogicDisplayComponent
  extends MvsFormFieldOutputBaseComponent
  implements OnInit, OnDestroy, OnChanges {

  logicDto: LogicDto;

  constructor(protected navigationService: MvsObjectNavigationService,
              protected objectService: ObjectTypeService,
              protected coreService: MvsCoreService,
              protected logicService: LogicService
              ) {
    super(navigationService, objectService, coreService);
  }


  /**
   * Initialize component.
   * @constructor
   */
  override ngOnInit() {

    super.ngOnInit();

    this.refreshComponent();


  }

  /**
   * Process on changes.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {

    if (!this.initialized) {
      return;
    }

    this.refreshComponent();

  }

  override refreshComponent() {
    this.logicService.get(this.value).subscribe((res: LogicDto) => {
      this.logicDto = res;
      this.initialized = true;
    })
  }


  protected readonly LogicLanguage = LogicLanguage;
}
