<p-skeleton *ngIf="!dto && !processRuntime" width="100%" height="100%"></p-skeleton>

<ng-container *ngIf="dto && processRuntime && mode == 'show-process'">

    <div [ngClass]="!processMode ? 'wf-top-navigation shadow-2' : ''"
         *ngIf="processRuntime && processRuntime.process.processStatusInternal != 2">
        <div class="col-12 px-0">

            <wf-process-header
                    [processRuntime]="processRuntime"
                    [importObjectContext]="importObjectContext"
                    [objectIdentifier]="objectIdentifier"
                    [dto]="dto"
                    (showProcessedObject)="this.visible = $event"
                    (onContinue)="onContinue($event)"
                    (onStepUpdate)="resumeProcess()"
            ></wf-process-header>

        </div>
    </div>

    <ng-container *ngIf="processRuntime && processRuntime.process.processStatusInternal == 2">
        <h3>Process completed</h3>

        <mvs-widget
                *ngIf="processDataWidget"
                [widgetData]="processDataWidget">
        </mvs-widget>

        <mvs-widget
                *ngIf="processTypeListWidget"
                [widgetData]="processTypeListWidget">
        </mvs-widget>

    </ng-container>


    <ng-container *ngIf="processRuntime && processRuntime.process.processStatusInternal != 2">
        <div class="wf-process-main-body">
            <div [style]="{'background':'var(--surface-card, #FFF)'}">


                <div class="grid">
                    <div class="col-12" *ngIf="breadCrumbItems && breadCrumbItems.length > 1">
                        <p-breadcrumb
                                styleClass="layout-breadcrumb pl-4 py-2 border-none"
                                [model]="breadCrumbItems"
                        ></p-breadcrumb>
                    </div>


                    <div class="col-12" *ngIf="stepItems && stepItems.length>1">
                        <mvs-stepper
                                [stepItems]="stepItems"
                                [activeStep]="stepperActiveStep"
                                [busy]="busy"
                                [fullPage]="fullPage"
                                (onStepSelected)="onStepSelected($event)"
                                (onChangeStep)="handleStepChange($event)">
                            <ng-template mvs-stepper-overlay-directive>
                                <mvs-widget
                                        *ngIf="processedStepWidget"
                                        [widgetData]="processedStepWidget"
                                ></mvs-widget>
                            </ng-template>
                        </mvs-stepper>
                    </div>


                    <div class="col-12">
                        <div class="p-2">
                            <div class="grid">

                                <!--    Display Script and hints before main activity   -->
                                <div *ngIf="listHintTypeBefore && listHintTypeBefore.length" class="col-12">
                                    <p-accordion [multiple]="true" [activeIndex]="0">
                                        <p-accordionTab *ngFor="let hint of listHintTypeBefore"
                                                        [selected]="hint.hintTypeDto.visibility"
                                                        [headerStyleClass]="'bg-' + hint.hintTypeDto.rgbaColorBackground + ' border-bottom-1 border-solid border-300'"
                                                        [contentStyleClass]="'bg-' + hint.hintTypeDto.rgbaColorBackground"
                                                        tabStyleClass="mb-1 shadow-none">
                                            <ng-template pTemplate="header">
                                                <div class="flex align-items-center">
                                                    <i [class]="hint.hintTypeDto.image + ' text-'+hint.hintTypeDto.rgbaColor"></i>
                                                    <span class="vertical-align-middle ml-2">{{ hint.hintTypeDtoName }}</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p [innerHTML]="hint.description | mvsSavePipe: 'html'"></p>
                                            </ng-template>
                                        </p-accordionTab>
                                    </p-accordion>
                                </div>

                                @if(!fullPage) {
                                    <div class="col-12"
                                         *ngIf="optionalActivityForStep && optionalActivityForStep.length">

                                        <div class="wf-process-side-activities-panel">

                                            <p-accordion styleClass="">
                                                <p-accordionTab header="Side Activity"
                                                                tabStyleClass="shadow-none border-1 border-300 border-round-xs">
                                                    <mvs-wf-process-show-step-activities
                                                            [processRuntime]="processRuntime"
                                                            [processMeta]="processMeta"
                                                            [stepType]="activeStepType"
                                                            [fullPage]="fullPage"
                                                            [optionalActivityTypes]="optionalActivityForStep"
                                                            (onActivitySelect)="onOptionalComponentSelect($event)"
                                                    ></mvs-wf-process-show-step-activities>
                                                </p-accordionTab>
                                            </p-accordion>
                                        </div>
                                    </div>
                                }

                                <div [ngClass]="this.optionalActivityForStep?.length > 0 && this.fullPage ? 'col-10' : 'col-12'"
                                     *ngIf="mainActivityForStep">
                                    <div class="w-full flex">
                                        <!--Display Script and hints left to main activity-->
                                        <div *ngIf="listHintTypeLeft && listHintTypeLeft.length">
                                            <div class="flex flex-column align-items-center gap-4 mr-2">
                                                <ng-container *ngFor="let hint of listHintTypeLeft">
                                                    <p-button
                                                            (click)="selectedHint = hint; hintOverlayPanel.toggle($event)"
                                                            [icon]="hint.hintTypeDto.image"
                                                            [styleClass]="'bg-'+hint.hintTypeDto.rgbaColorBackground + ' text-'+hint.hintTypeDto.rgbaColor"
                                                    ></p-button>
                                                </ng-container>
                                            </div>
                                        </div>


                                        <mvs-wf-process-show-main-activities class="activity-left-side w-full"
                                                                             [processRuntime]="processRuntime"
                                                                             [processMeta]="processMeta"
                                                                             [stepType]="activeStepType"
                                                                             [activityType]="mainActivityForStep"
                                                                             (onNextStepSelect)="processNextStepActivityEvent($event)"
                                                                             (onProcessCreated)="handleProcessCreated($event)"
                                        ></mvs-wf-process-show-main-activities>


                                        <!--Display Script and hints right to main activity   -->
                                        <div *ngIf="listHintTypeRight && listHintTypeRight.length">
                                            <div class="flex flex-column align-items-center gap-4 ml-2">
                                                <ng-container *ngFor="let hint of listHintTypeRight">
                                                    <p-button
                                                            (click)="selectedHint = hint; hintOverlayPanel.toggle($event)"
                                                            [icon]="hint.hintTypeDto.image"
                                                            [styleClass]="'bg-'+hint.hintTypeDto.rgbaColorBackground + ' text-'+hint.hintTypeDto.rgbaColor"
                                                    ></p-button>
                                                </ng-container>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                @if(fullPage) {
                                    <div class="col-2"
                                         *ngIf="optionalActivityForStep && optionalActivityForStep.length">

                                        <div class="wf-process-side-activities-panel">

                                            <div class="w-full">
                                                <mvs-wf-process-show-step-activities
                                                        [processRuntime]="processRuntime"
                                                        [processMeta]="processMeta"
                                                        [stepType]="activeStepType"
                                                        [fullPage]="fullPage"
                                                        [optionalActivityTypes]="optionalActivityForStep"
                                                        (onActivitySelect)="onOptionalComponentSelect($event)"
                                                ></mvs-wf-process-show-step-activities>
                                            </div>

                                        </div>
                                    </div>
                                }



                                <div *ngIf="listHintTypeBelow && listHintTypeBelow.length" class="col-12">
                                    <p-accordion [multiple]="true" [activeIndex]="0">
                                        <p-accordionTab *ngFor="let hint of listHintTypeBelow"
                                                        [selected]="hint.hintTypeDto.visibility"
                                                        [headerStyleClass]="'bg-' + hint.hintTypeDto.rgbaColorBackground + ' border-bottom-1 border-solid border-300'"
                                                        [contentStyleClass]="'bg-' + hint.hintTypeDto.rgbaColorBackground"
                                                        tabStyleClass="mb-1 shadow-none">
                                            <ng-template pTemplate="header">
                                                <div class="flex align-items-center">
                                                    <i [class]="hint.hintTypeDto.image + ' text-'+hint.hintTypeDto.rgbaColor"></i>
                                                    <span class="vertical-align-middle ml-2">{{ hint.hintTypeDtoName }}</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p [innerHTML]="hint.description | mvsSavePipe: 'html'"></p>
                                            </ng-template>
                                        </p-accordionTab>
                                    </p-accordion>
                                </div>

                                <div class="col-12"
                                     *ngIf="processedStepActivities && processedStepActivities.length">
                                    <h4>Optional Steps</h4>
                                    <mvs-wf-process-step-optional-activity-object-create
                                            [activityTypes]="optionalActivityForStep"
                                            [stepActivities]="processedStepActivities"
                                            [tabIndex]="tabIndex"
                                            [combinedActivities]="combinedActivities"
                                            (onActivitySelect)="onProcessedObjectSelect($event)"
                                    >
                                    </mvs-wf-process-step-optional-activity-object-create>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="visible && dto">
        <mvs-wf-process-show-all-processed-activities
                [dto]="dto"
                [visible]='visible'
                (dialogBoxVisibility)=onDialogBoxVisibility($event)
        ></mvs-wf-process-show-all-processed-activities>
    </ng-container>

    <mvs-sidebar sidebarPosition="left-40%" [isSidebarOpen]="showSideActivity"
                 (check)="showSideActivity = $event">

        <ng-template let-object="object" mvs-sidebar-directive>
            <ng-template #sideActivityRef>
            </ng-template>
        </ng-template>

    </mvs-sidebar>

</ng-container>

<ng-container *ngIf="mode == 'show-subProcess'">

    <mvs-wf-process
            [objectIdentifier]="subProcessObjectIdentifier"
            [breadCrumbItems]="breadCrumbItems"
            (onProcessCreated)="handleProcessCreatedEvent()"
    >
    </mvs-wf-process>
</ng-container>


<p-overlayPanel #hintOverlayPanel [style]="{'max-width':'50rem'}">
    <p *ngIf="selectedHint" [innerHTML]="selectedHint.description | mvsSavePipe: 'html'"></p>
</p-overlayPanel>