import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    DtoDetail,
    FilterCriteria, MvsFormControlOverwrite, MvsFormFieldAccessEnum,
    ObjectIdentifierData,
    ObjectRequestList,
    PageComponent,
    WidgetData, WidgetToolbarCallbackInterface,
} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";

@Component({
    selector: 'lg-config-page',
    templateUrl: './jb-config-page.page.html',
    styleUrls: ['./jb-config-page.page.scss']
})
export class JbConfigPagePage extends PageComponent implements OnInit, OnDestroy, OnChanges {

    activeIndex: number = 0;
    jbJobTypeWidget: WidgetData;
    jbJobRunWidget: WidgetData;
    jbJobRunLogWidget: WidgetData;

    constructor(protected route: ActivatedRoute, protected coreService: MvsCoreService) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.refreshWidgets();
    }


    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes)
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    refreshWidgets() {
        this.refreshJobTypeWidget();
        this.refreshJobRunWidget();
    }

    clearWidgets() {
        this.jbJobTypeWidget = null;
        this.jbJobRunWidget = null;
        this.jbJobRunLogWidget = null;
    }

    refreshJobTypeWidget() {

        const objectRequest = ObjectRequestList.createBasic(true, [], []);

        this.jbJobTypeWidget = WidgetFactory.createWidgetTableEntity(
            'jb-config-page-job-type-widget',
            'Job Types',
            'jb.JbJobType',
            'No Data',
            objectRequest
        );
    }

    refreshJobRunWidget() {

        const objectRequest = ObjectRequestList.createBasic(true, [], []);

        this.jbJobRunWidget = WidgetFactory.createWidgetTableEntity(
            'jb-config-page-job-run-widget',
            'Job Runs',
            'jb.JbJobRun',
            'No Data',
            objectRequest
        );

    }

    refreshJobRunLogWidget(runId: number) {

        const filterCriteria = FilterCriteria.create('jobRun', FilterCriteria.cOperatorEqual, runId)
        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], []);

        this.jbJobRunLogWidget = WidgetFactory.createWidgetTableEntity(
            'jb-config-page-job-run-log-entry-widget',
            'Run Log',
            'jb.JbJobRunLogEntry',
            'No Data',
            objectRequest
        );

    }


    handleJobRunSelect(object: ObjectIdentifierData) {
        this.refreshJobRunLogWidget(object.objectId);
    }


}
