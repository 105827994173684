<ng-container *ngIf="initialized">

    <div class="grid">


        <div class="col-2 flex flex-column gap-2">
            <!--
            <mvs-widget class="mvs-widget" *ngIf="customerExternalAliasWidget"
                        [widgetData]="customerExternalAliasWidget"
            >
            </mvs-widget>
            -->

            <mvs-widget class="mvs-widget" *ngIf="customerInfoWidgetData"
                        [widgetData]="customerInfoWidgetData"
            >
            </mvs-widget>

            <mvs-widget class="mvs-widget" *ngIf="customerContactsWidget"
                        [widgetData]="customerContactsWidget"
            >
            </mvs-widget>


            <mvs-widget class="mvs-widget"
                        *ngIf="customerCallsCategoryWidget"
                        [widgetData]="customerCallsCategoryWidget"
                        (onObjectSelect)="onSetFilterCriteria($event, 'callResult')"
            >
            </mvs-widget>

            <div class="mvs-widget">
                <mvs-widget *ngIf="widgetCustomerChannels"
                            [widgetData]="widgetCustomerChannels"
                ></mvs-widget>
            </div>

<!--            <mvs-widget *ngIf="customerChannelWidget"-->
<!--                        [widgetData]="customerChannelWidget">-->
<!--            </mvs-widget>-->


            <!--
            <mvs-widget class="mvs-widget" *ngIf="customerAgentsWidget"
                        [widgetData]="customerAgentsWidget"
            >
            </mvs-widget>
            -->

        </div>


        <div class="col-6">

            <div class="col-12" *ngIf="callFilterCriteria?.length>0">
                <p-panel header="Filter">
                    <div class="flex align-items-center gap-2 flex-wrap">
                        <p-chip
                                *ngFor="let filter of callFilterCriteria"
                                [label]="filter.label"
                                [removable]="true"
                                (onRemove)="onRemoveFilterCriteria(filter)"
                        ></p-chip>
                    </div>
                </p-panel>
            </div>

            <mvs-widget *ngIf="customerCallsWidget"
                        [widgetData]="customerCallsWidget"
                        (onObjectSelect)="handleCustomerCallSelect($event)"
            >
            </mvs-widget>
            <!--
            <mvs-widget *ngIf="customerAddressWidget"
                        [widgetData]="customerAddressWidget"
            >

            </mvs-widget>
            -->


        </div>

        <div class="col-4 flex flex-column gap-2">

            <div class="mvs-widget">
                <mvs-pc-call-incoming-at-object [objectIdentifier]="objectIdentifier"></mvs-pc-call-incoming-at-object>
            </div>

            <div class="mvs-widget">
                <mvs-pc-call-dial [calledObject]="objectIdentifier"></mvs-pc-call-dial>
            </div>

            <mvs-cr-customer-phone-contact-availability [customerObjectId]="objectIdentifier.objectId">

            </mvs-cr-customer-phone-contact-availability>

            <div class="mvs-widget" *ngIf="objectIdentifier.objectId">
                <mvs-ns-notification-types-for-object [objectIdentifier]="objectIdentifier"></mvs-ns-notification-types-for-object>
            </div>

        </div>


    </div>

</ng-container>