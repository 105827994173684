<div *ngIf="initialized">
    <mvs-config-header header="Termine und Microsoft Bookings Integration"
                       icon="fa-sharp fa-thin fa-calendars">
    </mvs-config-header>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Mahnstufen" leftIcon="fa-solid fa-file-invoice"></p-tabPanel>
        <p-tabPanel header="Einzugsarten" leftIcon="fa-solid fa-credit-card"></p-tabPanel>
    </p-tabView>

    <ng-container *ngIf="activeIndex==0">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetDunningLevel"
                        [widgetData]="widgetDunningLevel"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeIndex==1">
        <div class="grid">
            <div class="col-12">
                <mvs-widget
                        *ngIf="widgetPaymentCollectionVia"
                        [widgetData]="widgetPaymentCollectionVia"
                ></mvs-widget>
            </div>
        </div>
    </ng-container>


</div>