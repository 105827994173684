import {Injectable, Type} from '@angular/core';
import {DtoList, MvsCrudModeEnum, MvsCrudService, ObjectRequestList} from "@kvers/alpha-core-common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {LogicCheckSyntaxDto} from "../../dto/logic-check-syntax.dto";
import {LogicCheckSyntaxResponseDto} from "../../dto/logic-check-syntax-response.dto";
import {LgLogicObjectComponent} from "../../component/object-components/logic-object-component/lg-logic-object.component";

@Injectable({
  providedIn: 'root'
})
export class LogicService extends MvsCrudService{

  constructor(protected http: HttpClient) {
    super(http, MvsCrudService.baseUrl + '/lg/logics')
  }

  checkCodeSyntax(logicCheckSyntaxDto: LogicCheckSyntaxDto): Observable<LogicCheckSyntaxResponseDto> {
    const url: string = MvsCrudService.baseUrl + `/lg/logics/checkCodeSyntax`;

    return this.http.post<LogicCheckSyntaxResponseDto>(url, logicCheckSyntaxDto);
  }

  getObjectComponent(mode?: MvsCrudModeEnum): Type<any> {
    if (mode != MvsCrudModeEnum.create) {
      return LgLogicObjectComponent;
    }
    return null;
  }


}