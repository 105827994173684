import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MvsCoreService} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {ObjectTypeService} from "@kvers/alpha-core-common";
import {DmDocumentViewOptionsEnumEnum} from "../../enum/dm-document-view-options.enum";
import {DmDocumentAssignmentService} from "../../service/api/dm-document-assignment.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {
    WidgetUiStyleInterface
} from "@kvers/alpha-ui";
import {DmDocumentService} from "../../service/api/dm-document.service";
import {DmDocumentAssignmentDto} from "../../dto/dm-document-assignment.dto";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {ObjectRequestListGroupBy} from "@kvers/alpha-core-common";
import {ObjectRequestListAttribute} from "@kvers/alpha-core-common";
import {DtoListAttributeRequestAggregateEnum} from "@kvers/alpha-core-common";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {WidgetHelperButton} from "@kvers/alpha-core-common";
import {Subscription} from "rxjs";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {WidgetDataRefresh} from "@kvers/alpha-core-common";
import {WfActivityRefreshType} from "../../../wf/enum/wf-activity-refresh-type.enum";
import {EntityStatusEnum} from "@kvers/alpha-core-common";

@Component({
    selector: 'mvs-dm-object-documents',
    templateUrl: './object-documents.component.html',
    styleUrls: ['./object-documents.component.scss']
})
export class ObjectDocumentComponent implements OnInit, OnDestroy, OnChanges {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() viewOptions: DmDocumentViewOptionsEnumEnum;
    @Input() widthSize: string;
    @Input() uiStyle?: WidgetUiStyleInterface;
    @Input() refreshWidget: boolean;
    @Input() layoutVariation: string;
    @Input() autoRefresh: WfActivityRefreshType;

    @Input() objectIdentifier: ObjectIdentifier;

    @Output() onObjectSelectEvent: EventEmitter<DmDocumentAssignmentDto> = new EventEmitter<DmDocumentAssignmentDto>()

    objectType: ObjectTypeDto;

    title: string = 'Zugewiesene Dokumente';
    maximizable: boolean = true;
    dialogDisplayMode: string;
    visible: boolean;
    documentObjectId: number;
    widgetTableDocument: WidgetData;
    widgetButtons: WidgetHelperButton[];
    documentAssignmentList: DmDocumentAssignmentDto[];
    documentUploadSubscription: Subscription;

    protected documentService: DmDocumentService;
    protected objectTypeService: ObjectTypeService;
    protected dmDocumentAssignmentService: DmDocumentAssignmentService;
    displayValue: string = 'list';
    displayOptions: any[] = [
        {icon: 'fa fa-list', displayValue: 'list'},
        {icon: 'fa fa-grid', displayValue: 'grid'}
    ];

    constructor(
        protected coreService: MvsCoreService,
        public messageService: MessageService,
        protected confirmationService: ConfirmationService,
        protected navigationService: MvsObjectNavigationService) {
    }

    ngOnInit(): void {
        this.initComponent();
        this.refreshComponent();
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.documentService = <DmDocumentService>this.coreService.getCrudService("dm.DmDocument");
        this.objectTypeService = <ObjectTypeService>this.coreService.getCrudService("cc.ObjectType");
        this.dmDocumentAssignmentService = <DmDocumentAssignmentService>this.coreService.getCrudService("dm.DmDocumentAssignment");

    }


    /**
     * Refresh Component.
     */
    refreshComponent() {

        if (this.documentUploadSubscription) {
            this.documentUploadSubscription.unsubscribe();
        }

        this.documentUploadSubscription = this.dmDocumentAssignmentService.subjectDocumentUpload.subscribe(value => {
            this.refreshAssignedDocuments();
        });

        this.handleWidgetButtons();
        this.initialized = true;
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }


        if (changes["objectIdentifier"]) {
            this.refreshAssignedDocuments();
        }

        if (changes["refreshWidget"]) {
            this.refreshAssignedDocuments();
        }


    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
        this.documentUploadSubscription.unsubscribe();
    }

    /**
     * the following function will retrieve and render the table based on provided objectType and objectId
     */
    refreshAssignedDocuments() {

        // retrieve object type
        if (!this.objectType || this.objectType.alias != this.objectIdentifier.objectType) {
            this.deriveObjectType();
            return;
        }

        this.widgetTableDocument = new WidgetData();
        this.widgetTableDocument.id = 1;
        this.widgetTableDocument.name = this.title;
        this.widgetTableDocument.idAlias = 'document.show.object.documents';

        if (this.layoutVariation) {
            this.widgetTableDocument.idAlias = this.widgetTableDocument.idAlias + "." + this.layoutVariation;
        }

        this.widgetTableDocument.uiComponent = 'table';
        this.widgetTableDocument.dataProvider = 'list';
        this.widgetTableDocument.dataSource = 'entity';
        this.widgetTableDocument.dataProviderObject = 'dm.DmDocumentAssignment';

        this.widgetTableDocument.dataProviderListRequest = this.getListRequest();

        this.widgetTableDocument.uiRefresh = this.handleRefresh();


        this.widgetTableDocument.setParamValue("selectionMode", "rowSelect");

        this.widgetTableDocument.setParamValue("rowSelectionMode", "sticky");
        this.widgetTableDocument.setParamValue("size", "S");

    }

    private handleRefresh(): WidgetDataRefresh {
        if (this.autoRefresh == WfActivityRefreshType.available) {
            return WidgetDataRefresh.createSimple();
        } else if (this.autoRefresh == WfActivityRefreshType.auto || this.autoRefresh == WfActivityRefreshType.available_auto) {

            let displayRefresh = false;

            if (this.autoRefresh == WfActivityRefreshType.available_auto) {
                displayRefresh = true;
            }

            return WidgetDataRefresh.createAutoRefresh(displayRefresh, 5, 20);
        }
        return null;
    }

    protected getListRequest(paging: number = 10): ObjectRequestList {

        const filterCriteriaList = [];

        filterCriteriaList.push(FilterCriteria.createSingleCondition('objectType', FilterCriteria.cOperatorEqual, this.objectType.id, null));
        filterCriteriaList.push(FilterCriteria.createSingleCondition('objectId', FilterCriteria.cOperatorEqual, this.objectIdentifier.objectId, null));
        filterCriteriaList.push(FilterCriteria.createSingleCondition('entityStatus', FilterCriteria.cOperatorEqual, EntityStatusEnum.active, null));

        /*
        this.widgetTableDocument.dataProviderListRequest = ObjectRequestList.createWithPaging(
            true,
            filterCriteriaList,
            [new Sorting("lastModifiedDate", false)],
            PagingDto.create(0, 5)
        )
         */

        // dmDocumentType
        const complexSelection = ObjectRequestComplex.build(false, false,
            ObjectRequestComplexNode.createSimpleAttributeNode("document")
                .addNodes(
                    ObjectRequestComplexNode.createSimpleAttributeNode("dmDocumentType"),
                    ObjectRequestComplexNode.createRelationNode(
                        "comments",
                        ObjectRequestRelation.createGroupBy(
                            "dm.DmDocumentComment",
                            "document",
                            ObjectRequestListGroupBy.create(
                                true,
                                [],
                                [],
                                ["document"],
                                [new ObjectRequestListAttribute("document", "Anzahl Kommentare", DtoListAttributeRequestAggregateEnum.count)])
                        )
                    )
                ));
        return this.widgetTableDocument.dataProviderListRequest = ObjectRequestList.createComplexRequestList(
            true,
            complexSelection,
            filterCriteriaList,
            [new Sorting("lastModifiedDate", false)],
            PagingDto.create(0, paging),
            null
        );


    }

    /**
     * the following function will be called if user provide objectTypeAlias instead of objectId and the function will retrieve
     * the objectTypeId and will call the refreshAssignedDocuments() function
     * @param objectTypeAlias
     */
    deriveObjectType() {

        this.objectTypeService.getViaObjectType(this.objectIdentifier.objectType).then((res: any) => {
            this.objectType = res;

            if (this.objectType.alias == this.objectIdentifier.objectType) {
                this.refreshAssignedDocuments();
            }

        });
    }

    /**
     * the following function will be called if user select an object in table
     * @param event
     */
    onObjectSelect(dto: DmDocumentAssignmentDto) {

        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry(
            "dm.DmDocument",
            dto.documentDtoId,
            'object',
            "Dokument " + dto.objectTypeDtoId,
            null,
            null,
            MvsObjectNavigationActionEnum.any,
            'light');

        this.navigationService.navigateTo(mvsObjectNavigationEntry, "left");

        this.onObjectSelectEvent.emit(dto);

    }

    /**
     * the following function will delete the corresponding document
     * @param event
     */
    onObjectDelete(id: number, event) {
        event.stopPropagation();
        this.confirmationService.confirm({
            target: event.target,
            message: 'Soll das Dokument sowie die Zuordnung gelöscht werden?',
            icon: 'pi pi-exclamation-triangle',

            accept: () => {
                this.dmDocumentAssignmentService.deleteSoft(id).subscribe(() => {
                    this.messageService.add({severity: 'info', summary: 'Deleted', detail: 'Dokument wurde gelöscht.'});
                    if (this.displayValue == 'grid') {
                        this.getDocuments();
                    } else {
                        this.refreshComponent();
                    }
                })
            },

            reject: () => {
                // this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
            }
        });

    }

    changeView(selectedOption: string) {
        this.displayValue = selectedOption;
        if (selectedOption == 'grid') {
            this.documentUploadSubscription = this.dmDocumentAssignmentService.subjectDocumentUpload.subscribe(value => {
                this.getDocuments();
            });
            this.maximizable = false;
            this.dialogDisplayMode = 'maxOnly';
        } else {
            this.maximizable = true;
            this.dialogDisplayMode = '';
        }
    }

    handleWidgetButtons() {
        this.widgetButtons = [
            // {
            //     label: null,
            //     icon: 'fa fa-list',
            //     display: true,
            //     action: 'list',
            //     tooltip: 'List'
            // },
            {
                label: null,
                icon: 'fa fa-grid',
                display: true,
                action: 'grid',
                tooltip: 'Grid'

            }
        ];
    }

    handleButtonClick(event: WidgetHelperButton) {
        if (event.action === 'list') {
            this.changeView(event.action);
        } else if (event.action === 'grid') {
            this.changeView(event.action);
        }

    }

    getDocuments() {

        const listRequest = this.getListRequest();

        this.dmDocumentAssignmentService.list(listRequest).subscribe(res => {
            this.documentAssignmentList = <DmDocumentAssignmentDto[]>res.entries;
        })
    }

}
