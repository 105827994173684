import {Route, Routes} from "@angular/router";
import {JbConfigPagePage} from "./page/jb-config-page/jb-config-page.page";

export function JbSubRoutes() : Routes {

    /**
     *
     * path:
     * Prefix of the module will always be added to the path name
     *
     *      Example
     *      path: "addresses" => route path "ad/addresses" !
     * ------------------------------------------------------------------
     *
     * canActivate:
     * The authentication guard MsalGuard will be added automatically!
     * ------------------------------------------------------------------
     *
     * component:
     * Added components need to be from type "Page"!
     * ------------------------------------------------------------------
     *
     */
    const routes = [

        {path: 'jb/config', component: JbConfigPagePage},

    ];

    return routes;

}
