<ng-container *ngIf="initialized">

    @if (ticketObjects && ticketObjects.length) {
    <div class="flex flex-column gap-3 pt-2">
<!--            <h4 class="m-0 px-3 text-lg font-medium">Verknüpfte Objekte</h4>-->
            <div *ngIf="busy"
                 class="absolute top-0 left-0 w-full h-full flex flex-column align-items-center justify-content-center"
                 style="background-color: rgb(244 244 244 / 30%); z-index: 9999;">
                <p-progressSpinner styleClass="text-primary"></p-progressSpinner>
            </div>
            <div class="grid px-3">

                @for (object of ticketObjects; track object.id) {
<!--                    *ngFor="let object of ticketObjects"-->

                <div  [ngClass]="fullPage ? 'col-4' : 'col-6'">

                    <!-- Object Role TEMPLATE  -->

                    <!--                @if (object.ticketTypeObjectRoleDto) {-->

                    <!--                    uiIcon: {{object.ticketTypeObjectRoleDto?.image}} <br>-->
                    <!--                    uiName: {{object.ticketTypeObjectRoleDto?.name}} <br>-->
                    <!--                    uiColor: {{object.ticketTypeObjectRoleDto?.rgbaColor}} <br>-->
                    <!--                    uiColorBackground: {{object.ticketTypeObjectRoleDto?.rgbaColorBackground}}-->
                    <!--                }-->

                    <div class="border-round-sm block flex flex-column p-1"
                         style="background: var(--bluegray-50);"
                         (contextmenu)="handleContextMenu(object, $event)"
                         [ngStyle]="{'border-left': '4px solid var(--' + (object?.ticketTypeObjectRoleDto?.rgbaColor ?
                      object.ticketTypeObjectRoleDto.rgbaColor : 'bluegray-50') + ')'}">

                        <mvs-object-quick-view
                                [importObjectContext]="ticketObjectContext"
                                [objectIdentifier]="object['objectIdentifier']"
                                [objectId]="object.objectId"
                                [objectTypeId]="object.objectTypeDtoId"
                                [mode]="'inline'"
                                [size]="'M'"
                                [widgetToolbarButtons]="ticketObjectToolbarButtons"
                                (onToolbarButtonClick)="handleTicketTypeToolbarButton($event, object['objectIdentifier'])">
                        </mvs-object-quick-view>

                        @if (object.ticketTypeObjectRoleDto) {
                            <div class="flex gap-2 pl-3 py-1 text-{{object.ticketTypeObjectRoleDto?.rgbaColor}}">
                                <i class="text-sm {{object.ticketTypeObjectRoleDto?.image}}"></i>
                                <span class="text-sm">{{ object.ticketTypeObjectRoleDto?.name }}</span>
                            </div>
                        }
                    </div>


                </div>

                }
            </div>
    </div>

        <p-contextMenu #cm1 appendTo="body"
                       [model]="contextMenuItems">
        </p-contextMenu>
<!--        [target]="targetDiv"-->


    }
</ng-container>