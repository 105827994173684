import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ReportDateUtilService {
    /**
     * Get start and end dates for the given period.
     * @param period Period for which dates are calculated ('currentMonth', 'currentYear', 'currentWeek', 'all').
     * @returns Object containing start and end dates.
     */
    getStartEndDates(period: string): { start: Date; end: Date } {
        const today = new Date();

        switch (period) {
            case 'currentMonth':
                return {
                    start: this.startOfMonth(today),
                    end: this.endOfMonth(today),
                };

            case 'currentYear':
                return {
                    start: this.startOfYear(today),
                    end: this.endOfYear(today),
                };

            case 'currentWeek':
                return {
                    start: this.startOfWeek(today), // Week starts on Monday
                    end: this.endOfWeek(today),     // Week ends on Sunday
                };

            case 'all':
            default:
                return {
                    start: new Date(1970, 0, 1), // Epoch start
                    end: new Date(9999, 11, 31), // Far future
                };
        }
    }

    /**
     * Returns the first day of the month for the given date.
     * @param date The input date.
     * @returns The start of the month.
     */
    startOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    /**
     * Returns the last day of the month for the given date.
     * @param date The input date.
     * @returns The end of the month.
     */
    endOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    /**
     * Returns the first day of the year for the given date.
     * @param date The input date.
     * @returns The start of the year.
     */
    startOfYear(date: Date): Date {
        return new Date(date.getFullYear(), 0, 1);
    }

    /**
     * Returns the last day of the year for the given date.
     * @param date The input date.
     * @returns The end of the year.
     */
    endOfYear(date: Date): Date {
        return new Date(date.getFullYear(), 11, 31);
    }

    /**
     * Returns the first day of the week for the given date.
     * Assumes the week starts on Monday (can be adjusted if needed).
     * @param date The input date.
     * @param weekStartsOn The day the week starts (0 = Sunday, 1 = Monday, etc.).
     * @returns The start of the week.
     */
    startOfWeek(date: Date, weekStartsOn: number = 1): Date {
        const day = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - diff);
    }

    /**
     * Returns the last day of the week for the given date.
     * Assumes the week ends on Sunday (can be adjusted if needed).
     * @param date The input date.
     * @param weekStartsOn The day the week starts (0 = Sunday, 1 = Monday, etc.).
     * @returns The end of the week.
     */
    endOfWeek(date: Date, weekStartsOn: number = 1): Date {
        const start = this.startOfWeek(date, weekStartsOn);
        return new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
    }
}