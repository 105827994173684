<ng-container *ngIf="initialized && availableActionsResponse?.availableActions?.length">
    <div class="flex flex-column gap-2 px-3 " style="position: relative;">
        <div *ngIf="ticketBusyState"
             class="absolute top-0 left-0 w-full h-full flex flex-column align-items-center justify-content-center"
             style="background-color: rgb(244 244 244 / 30%); z-index: 9999;">
            <p-progressSpinner styleClass="text-primary"></p-progressSpinner>
        </div>
        <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
<!--                <h4 *ngIf="!processMode" class="m-0 text-lg font-medium">Aktionen</h4>-->
            </div>
            <button pButton pRipple icon="pi pi-plus fa-fw"
                    class="p-button-rounded p-button-text mvs-wigdet-menu-btn"
                    *ngIf="buttonItems && buttonItems.length && addActions"
                    (click)="toggleSlideMenu($event)">
            </button>
        </div>
        <div class="">
            @for (uiAction of uiTicketActionsList; track uiAction) {
                <div class="border-1 border-300 border-round-md mb-3">
                    <div class="grid align-items-center pt-2 mr-1">
                        <div class="flex gap-1 align-items-center col-5">
                            <button pButton pRipple
                                    [icon]="uiAction.showComments ? 'fa-regular fa-chevron-down' : 'fa-regular fa-chevron-right'"
                                    class="p-button-rounded p-button-text"
                                    (click)="toggleShowComments(uiAction)">
                            </button>
                            <div class="flex gap-1 justify-content-between align-items-center text-sm w-full">
                               <div class="flex gap-1 align-items-center">
                                <i class="fa-regular text-600 {{uiAction.actionIcon}}"></i>
                                <span>
                                    {{ uiAction.ticketAction.name || uiAction.ticketAction.ticketTypeActionDto.name }}
                                </span>
                               </div>
                                <i *ngIf="uiAction.ticketAction.mandatory" class="fa-sharp fa-solid fa-star text-red-500 text-xs"></i>

                            </div>
                        </div>

                        <div class="col-7">
                            <div class="flex align-items-center gap-1 justify-content-between">
                                <div class="flex align-items-center gap-3">
                                    <i class="fa-regular fa-circle-info text-primary text-lg"
                                       [pTooltip]="uiAction.ticketAction.description || 'N/A'"
                                       tooltipPosition="top"></i>

                                    <p-tag *ngIf="!uiAction.showSimple"  rounded="true" [value]="uiAction.status"></p-tag>

                                    <div class="flex gap-3 align-items-center ml-2" *ngIf="uiAction.isTicket">

                                        <div class="flex align-items-center gap-2" *ngIf="uiAction.ticketAction.assignedAgentDtoName">
<!--                                            <p-avatar icon="pi pi-user" shape="circle"></p-avatar>-->
                                            <img *ngIf="uiAction.ticketAction.assignedAgentAvatar"
                                                 class="h-2rem w-2rem border-circle"
                                                 [src]="uiAction.ticketAction.assignedAgentAvatar | mvsSecureBlob"
                                                 tooltipPosition="top"/>
                                            <span class="text-600 text-sm">{{uiAction.ticketAction.assignedAgentDtoName}}</span>
                                        </div>

                                        <div class="flex align-items-center gap-1" *ngIf="uiAction.ticketAction.dueDate">
                                            <i class="fa fa-regular fa-clock text-600 text-xs"></i>
                                            <span class="text-700 ">{{uiAction.ticketAction.dueDate  | date: 'dd.MM.yyyy'}}</span>
                                        </div>
                                    </div>

                                </div>

                                <!-- Process and SIMPLE handling -->
                                <p-button *ngIf="uiAction.isProcess"
                                          [icon]="uiAction.icon"
                                          [pTooltip]="uiAction.tooltip"
                                          tooltipPosition="left"
                                          [styleClass]="'p-button-rounded p-button-text w-2rem h-2rem ' + uiAction.buttonClass"
                                          (click)="triggerAction(uiAction)">
                                </p-button>


                                <p-button *ngIf="uiAction.isTicket && !uiAction.isStarted"
                                          icon="fas fa-ticket-alt text-0"
                                          pTooltip="Create Manually"
                                          tooltipPosition="left"
                                          [styleClass]="'p-button-rounded p-button-text w-2rem h-2rem ' + uiAction.buttonClass"
                                          (click)="triggerManualAction(uiAction)">
                                </p-button>

                                <p-button *ngIf="uiAction.isTicket"
                                          [icon]="uiAction.icon"
                                          [pTooltip]="uiAction.tooltip"
                                          tooltipPosition="left"
                                          [styleClass]="'p-button-rounded p-button-text w-2rem h-2rem ' + uiAction.buttonClass"
                                          (click)="triggerAction(uiAction)">
                                </p-button>

                                <p-button *ngIf="uiAction.isNotification && !uiAction.ticketAction.notificationDtoId"
                                          icon="fa-thin fa-envelope-open-text text-0"
                                          pTooltip="Send Manually"
                                          tooltipPosition="left"
                                          [styleClass]="'p-button-rounded p-button-text w-2rem h-2rem ' + uiAction.buttonClass"
                                          (click)="triggerManualAction(uiAction)">
                                </p-button>


                                <p-button *ngIf="uiAction.isNotification"
                                          [icon]="uiAction.icon"
                                          [pTooltip]="uiAction.tooltip"
                                          tooltipPosition="left"
                                          [styleClass]="'p-button-rounded p-button-text w-2rem h-2rem ' + uiAction.buttonClass"
                                          (click)="triggerAction(uiAction)">
                                </p-button>

                                <ng-container *ngIf="uiAction.showSimple">
                                    <p-dropdown
                                            [options]="actionStatus"
                                            [(ngModel)]="uiAction.ticketAction.statusInternal"
                                            optionLabel="label"
                                            optionValue="key"
                                            styleClass="w-full"
                                            (onChange)="handleStatusChange($event, uiAction)"
                                    ></p-dropdown>
<!--                                    <p-splitButton-->
<!--                                            [label]="uiAction.status"-->
<!--                                            (onClick)="handleStatusChange($event, uiAction)"-->
<!--                                            [model]="actionStatus"-->
<!--                                            class="w-full">-->
<!--                                    </p-splitButton>-->

                                </ng-container>
                            </div>
                        </div>

                        <ng-container *ngIf="uiAction.showComments">
                            <ng-container *ngIf="uiAction.ticketAction.description">
                               <div class="flex flex-column p-3" *ngIf="uiAction.ticketAction.description">
                                   <h6 class="text-lg font-medium mb-2">Description</h6>
                                   <span>{{uiAction.ticketAction.description}}</span>
                               </div>
                            </ng-container>

                            <mvs-object-comments
                                    style="width: 100%"
                                    headline="Kommentare"
                                    [mainObject]="uiAction.commentsIdentifier"
                                    [showFullText]="true"
                                    [showGroupedComments]="false"
                                    linkedEntityObjectType="tm.TicketActionComment">
                            </mvs-object-comments>
                        </ng-container>
                    </div>
                </div>

            }
        </div>
    </div>
</ng-container>

<p-slideMenu #menu
             [model]="buttonItems"
             [popup]="true"
             [menuWidth]="175"
             class="sort-icon"
             appendTo="body">
</p-slideMenu>