<ng-container *ngIf="initialized">
    <form [formGroup]="widgetUiField">
        <div [formGroupName]="'uiConfigData'">
            <div class="grid">
                <div class="col-12">
                    <h4>Ticket Create Output Config</h4>
                </div>

                <div class="col-3">
                    <label for="objectType">Object Type</label>
                    <mvs-object-type id="objectType"
                                     [objectTypeAlias]="widgetUiField.get('uiConfigData').get('objectType').value"
                                     (objectTypeChange)="handleObjectType($event)"></mvs-object-type>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="objectId">Object ID</label>
                        <p-dropdown
                                [options]="objectIdOptions"
                                formControlName="objectId"
                                id="objectId"
                                appendTo="body"
                                class="w-full"
                                styleClass="w-full"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select Object ID"
                        >
                        </p-dropdown>
                    </div>
                </div>


                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="ticketType">Ticket Type</label>
                        <p-dropdown
                                *ngIf="ticketTypeList"
                                [options]="ticketTypeList"
                                formControlName="ticketType"
                                id="ticketType"
                                appendTo="body"
                                class="w-full"
                                styleClass="w-full"
                                optionLabel="name"
                                optionValue="id"
                                placeholder="Select Ticket Type"
                        >
                        </p-dropdown>
                        <p-progressSpinner *ngIf="!ticketTypeList" ariaLabel="Processing" styleClass="w-4rem h-4rem" />
                    </div>
                </div>

            </div>
        </div>
    </form>

</ng-container>


<ng-container  *ngIf="!initialized">
    <p-progressSpinner ariaLabel="Processing" />
</ng-container>
