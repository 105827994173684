import {Component, OnInit} from '@angular/core';
import {DtoList, PageComponent} from "@kvers/alpha-core-common";
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {ActivatedRoute} from "@angular/router";
import {MvsCoreService} from "@kvers/alpha-core-common";
import {MetaService} from "@kvers/alpha-core-common";
import {MenuItem} from "primeng/api";
import {ContactTypeService} from "../../../service/api/contact-type.service";
import {ContactTypeDto} from "../../../dto/contact-type.dto";

@Component({
    selector: 'cd-config',
    templateUrl: './basic-config.page.html',
    styleUrls: ['./basic-config.page.scss']
})
export class BasicConfigPage extends PageComponent implements OnInit {
    objectBrowserRequest: any;
    subObjectBrowserRequest: any;

    defaultLabel: string = "Konfiguration der Kontaktdaten";

    activeIndex: number = 0;

    widgetContactTypeObjectTypeWidget: WidgetData;

    widgetContactType: WidgetData;
    widgetContactTypeField: WidgetData;
    widgetContactWidget:WidgetData;


    selectedContactType: ObjectIdentifierData;
    importContextContactType: DtoImportObjectContext;

    items: MenuItem[] | undefined;
    showBreadCrumb: boolean = false;

    constructor(
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService,
        protected metaService: MetaService,
        protected contactTypeService: ContactTypeService,
        // private titleService: Title
    ) {
        super(route, coreService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initComponent();
    }

    initComponent(): void {

        this.widgetContactTypeObjectTypeWidget = WidgetFactory.createWidgetList(
            "cd.config.contact.type.object.type",
            "Zuordnung Kontakttype zu Objekttyp",
            "table",
            "list",
            "entity",
            "cd.ContactTypeObjectType",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [])
        );

        this.widgetContactType = WidgetFactory.createWidgetList(
            "cd.config.contact.type",
            "Kontakt- Typen",
            "treeTable",
            "list",
            "entity",
            "cd.ContactType",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [], [])
        );

        this.initialized = true;

    }

    handleSelectInsurableObjectType(event: ObjectIdentifierData) {

        this.showBreadCrumb = false;
        this.items = [];

        this.selectedContactType = event;

        this.importContextContactType = DtoImportObjectContext.createFromObjectIdentifier(event);

        this.widgetContactTypeField = WidgetFactory.createWidgetList(
            "cd.config.contact.type.field",
            "Direkte Felder",
            "table",
            "list",
            "entity",
            "cd.ContactTypeField",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [FilterCriteria.create("type", FilterCriteria.cOperatorEqual, event.objectId)], [])
        );

        this.widgetContactWidget = WidgetFactory.createWidgetList(
            "cd.config.contact.example.data",
            "Beispieldaten",
            "table",
            "list",
            "entity",
            "cd.Contact",
            "Keine Daten vorhanden",
            ObjectRequestList.createBasic(true, [FilterCriteria.create("type", FilterCriteria.cOperatorEqual, event.objectId)], [])
        );


        this.getInsurableObjectType(this.selectedContactType.objectId);

        // this.metaService.getByAlias("ci.InsurableObject", true, true, true, false, false).subscribe(value => {
        //     console.log(value);
        // });

    }

    getInsurableObjectType(id: number) {

        const filterCriteria = FilterCriteria.create('id', FilterCriteria.cOperatorEqual, id);

        const objectRequest = ObjectRequestList.createBasic(false, [filterCriteria], [])

        this.contactTypeService.list(objectRequest).subscribe((res: DtoList<ContactTypeDto>) => {

            const object: ContactTypeDto = res.entries[0]


            this.items.push({icon: object['euImage'], label: object.name});

            if (object.parentDtoId) {
                this.getInsurableObjectType(object.parentDtoId);
            } else {
                this.items[0].iconClass = 'text-primary';
                this.items = this.items.reverse();
                this.showBreadCrumb = true;

            }
        });

    }

}
