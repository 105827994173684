import {NgModule} from '@angular/core';
import {CoreModule} from "../core/core.module";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DynamicModule} from "../core/interface/dynamic-module.interface";
import {ModuleRegistryService} from "../core/interface/module-registration.service";
import {UiModule} from "../ui/ui.module";
import {LgConfigPagePage} from "./page/lg-config-page/lg-config-page.page";
import {LgLogicObjectComponent} from "./component/object-components/logic-object-component/lg-logic-object.component";
import {LgLogicPagePage} from "./page/lg-logic-page/lg-logic-page.page";
import {TeModule} from "../te/te.module";
import {DragDropModule} from "primeng/dragdrop";
import {LgVariablesComponent} from "./component/lg-variables/lg-variables.component";
import {CodeEditorComponent} from "./component/code-editor/code-editor.component";
import {LgTestCaseComponent} from "./component/lg-test-case/lg-test-case.component";
import {
    LgCalculationLogicTypeObjectComponent
} from "./component/object-components/calculation-logic-type-object-component/lg-calculation-logic-type-object.component";
import {LgCalculationTestCaseComponent} from "./component/lg-calculation-test-case/lg-calculation-test-case.component";
import {LgCalculationLogicPagePage} from "./page/lg-calculation-logic-page/lg-calculation-logic-page.page";
import {
    LgCalculationLogicTypePageComponent
} from "./component/calculation-logic-type-page-component/lg-calculation-logic-type-page.component";
import {LgLogicPageComponent} from "./component/logic-page-component/lg-logic-page.component";
import {
    LgCalculationLogicTypeStepComponent
} from "./component/calculation-logic-type-step-component/lg-calculation-logic-type-step.component";


@NgModule({
    declarations: [
        LgConfigPagePage,
        LgLogicObjectComponent,
        LgLogicPagePage,
        LgVariablesComponent,
        CodeEditorComponent,
        LgTestCaseComponent,
        LgCalculationLogicTypeObjectComponent,
        LgCalculationTestCaseComponent,
        LgCalculationLogicPagePage,
        LgCalculationLogicTypePageComponent,
        LgLogicPageComponent,
        LgCalculationLogicTypeStepComponent
    ],
    exports: [
        LgConfigPagePage,
        LgLogicObjectComponent,
        LgLogicPagePage,
        LgVariablesComponent,
        CodeEditorComponent,
        LgTestCaseComponent,
        LgCalculationLogicTypeObjectComponent,
        LgCalculationTestCaseComponent,
        LgCalculationLogicPagePage,
        LgCalculationLogicTypePageComponent,
        LgLogicPageComponent,
        LgCalculationLogicTypeStepComponent

    ],
    imports: [
        CoreModule,
        UiModule,
        BlockUIModule,
        ProgressSpinnerModule,
        TeModule,
        DragDropModule
    ]
})
export class LgModule implements DynamicModule {

    alias = 'lg';

    constructor(private moduleRegistry: ModuleRegistryService) {
        this.moduleRegistry.registerModule(this);
    }

    getKpiComponent() {
        return null;
    }

    getStatisticsComponent() {
        return null;
    }
}
