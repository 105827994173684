import {DtoDetail} from "@kvers/alpha-core-common";
import { LogicGroupDto } from "./logic-group.dto";
import { LogicType } from "../enum/logic-type.enum";
import { LogicLanguage } from "../enum/logic-language.enum";

export class LogicDto extends DtoDetail{


    public logicGroupDto? : LogicGroupDto;
    public logicGroupDtoId : number;
    public logicGroupDtoName : string;
    public alias: string;
    public name: string;
    public logicType: LogicType;
    public logicLanguage: LogicLanguage;
    public script: string;

}