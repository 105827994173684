import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MvsCoreService, PageComponent, WidgetData} from "@kvers/alpha-core-common";
import {ActivatedRoute, Router} from "@angular/router";
import {EuEuRegistrationStatusInternal, EuRegistrationDto} from "../../../../eu/dto/eu-registration.dto";
import {WidgetFactory} from "@kvers/alpha-ui";
import {WidgetDataParam} from "@kvers/alpha-core-common";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {CustomerDto} from "../../../dto/customer.dto";
import {ObjectIdentifierData} from "@kvers/alpha-core-common";
import {Sorting} from "@kvers/alpha-core-common";
import {DtoTemplate} from "@kvers/alpha-core-common";
import {EuRegistrationService} from "../../../../eu/service/api/eu-registration.service";
import {ObjectRequestSimple} from "@kvers/alpha-core-common";
import {ObjectRequestComplex} from "@kvers/alpha-core-common";
import {ObjectRequestComplexNode} from "@kvers/alpha-core-common";
import {ObjectRequestRelation} from "@kvers/alpha-core-common";
import {
    ObjectRequestComplexRelationBindingEnum
} from "@kvers/alpha-core-common";
import {PagingDto} from "@kvers/alpha-core-common";
import {CustomerUserDto} from "../../../dto/customer-user.dto";
import {combineLatest} from "rxjs";
import {CustomerOnboardingDto} from "../../../dto/customer-onboarding.dto";
import {MenuItem} from "primeng/api";
import {MvsObjectNavigationEntry} from "@kvers/alpha-ui";
import {MvsObjectNavigationActionEnum} from "@kvers/alpha-ui";
import {MvsObjectNavigationService} from "@kvers/alpha-ui";
import {CustomerContractDto} from "../../../dto/customer-contract.dto";
import {WidgetHelperButton} from "@kvers/alpha-core-common";
import {MvsMessageService} from "@kvers/alpha-core-common";
import {ObjectChangeInformation} from "@kvers/alpha-core-common";
import {EuUserAddedInsuranceDocumentDto} from "../../../../eu/dto/eu-user-added-insurance-document.dto";
import {MvsFormValueListEntryDto} from "@kvers/alpha-core-common";
import {DmDocumentDto} from "../../../../dm/dto/dm-document.dto";
import {DmDocumentService} from "../../../../dm/service/api/dm-document.service";
import {DmDocumentStatusInternalEnum} from "../../../../dm/enum/dm-document-status-internal.enum";
import {ContractDto} from "../../../../cm/dto/contract.dto";
import {ContractService} from "../../../../cm/service/api/contract.service";
import {ObjectIdentifier} from "@kvers/alpha-core-common";
import {DtoImportObjectContext} from "@kvers/alpha-core-common";
import {DtoImportObjectContextEntry} from "@kvers/alpha-core-common";

@Component({
    selector: 'cr-manage-registration-user',
    templateUrl: './cr-manage-registration-user.component.html',
    styleUrls: ['cr-manage-registration-user.component.scss']
})
export class CrManageRegistrationUserComponent extends PageComponent implements OnInit, OnChanges, OnDestroy {

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    selectedUserRegistrationId: number;
    selectedUserRegistration: EuRegistrationDto;
    userRegistrationInternalStatusEntries: MvsFormValueListEntryDto[];
    isPending = true;

    userRegistrationDtoList: EuRegistrationDto[];

    registrationWidget: WidgetData;
    onboardingWidget: WidgetData;
    appointmentWidget: WidgetData;
    customerWidget: WidgetData;
    userWidget: WidgetData;
    userAddedInsurancesWidget: WidgetData;

    selectedCustomer: CustomerDto;
    selectedCustomerUser: CustomerUserDto;
    selectedCustomerContracts: CustomerContractDto[];
    contractsDtoList: ContractDto[];
    selectedCustomerOnboarding: CustomerOnboardingDto;


    selectedDocument: EuUserAddedInsuranceDocumentDto;
    selectedDocumentStatus: DmDocumentStatusInternalEnum;
    documentStatusEntries: MvsFormValueListEntryDto[];

    selectedUserAddedInsuranceId: number;
    selectedContractId: number;
    selectedContract: ContractDto;
    showSideDrawer: boolean = true;
    createContractBool: boolean;

    lastDocument: boolean;
    disableNext: boolean = true;
    disablePrevious: boolean = true;

    tabIndex: number = 0;

    direction: 'prev' | 'next';
    toolbarButton: WidgetHelperButton[];

    contextContractAndCustomer: DtoImportObjectContext;

    breadcrumbItems: MenuItem[] | undefined;
    home: MenuItem | undefined = {
        icon: 'pi pi-home', command: () => {
            this.navigateToOverviewPage();
        }
    };


    constructor(
        protected contractService: ContractService,
        protected documentService: DmDocumentService,
        protected messageService: MvsMessageService,
        protected navigationService: MvsObjectNavigationService,
        protected registrationService: EuRegistrationService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected coreService: MvsCoreService) {
        super(route, coreService)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.breadcrumbItems = [{
            label: 'Registrierung', command: () => {
                this.navigateToRegistrationScreen();
            }
        }];

        // to execute refreshSelectedRegistrationUser after the param and querry param both are recieved
        combineLatest([
            this.route.params,
            this.route.queryParams
        ]).subscribe(([params, queryParams]) => {
            this.selectedUserRegistrationId = params['id'];
            this.isPending = queryParams['isPending'] === 'true';
            this.refreshSelectedRegistrationUser();
        });
    }

    /**
     * navigate to registration screen if clicked on breadcrumb
     */
    navigateToRegistrationScreen() {
        this.selectedUserAddedInsuranceId = null;
        this.selectedDocument = null;
        if (this.breadcrumbItems.length > 1) {
            this.breadcrumbItems.pop();
        }
    }

    /**
     * retrieve selected registration information
     */
    refreshSelectedRegistrationUser() {

        if (this.busy) {
            return;
        }
        this.busy = true;

        const complexRequest = ObjectRequestList.createComplexRequestList(
            true,
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createRelationNode('customer', ObjectRequestRelation.createJoinWithBindingType('cr.Customer#registration', ObjectRequestComplexRelationBindingEnum.LATEST))
                    .addNodes(ObjectRequestComplexNode.createRelationNode("customerContracts", ObjectRequestRelation.createJoinWithBindingType("cr.CustomerContract#customer", ObjectRequestComplexRelationBindingEnum.ALL)))
                    .addNodes(ObjectRequestComplexNode.createRelationNode("user", ObjectRequestRelation.createJoinWithBindingType("cr.CustomerUser#customer", ObjectRequestComplexRelationBindingEnum.LATEST)))
                    .addNodes(ObjectRequestComplexNode.createRelationNode("customerOnboarding", ObjectRequestRelation.createJoinWithBindingType("cr.CustomerOnboarding#customer", ObjectRequestComplexRelationBindingEnum.LATEST)))),
            [FilterCriteria.create('id', FilterCriteria.cOperatorEqual, this.selectedUserRegistrationId)],
            [new Sorting("id", false)],
            PagingDto.create(0, 1)
        )

        this.registrationService.list(complexRequest).subscribe(res => {
            this.selectedUserRegistration = res.entries[0];
            this.selectedUserRegistration.form = res.form;
            this.userRegistrationInternalStatusEntries = res.form.getFormField('registrationStatusInternal').valueList.entries;
            this.selectedCustomer = this.selectedUserRegistration?.customer;
            this.selectedCustomerUser = this.selectedUserRegistration?.customer?.user;
            this.selectedCustomerOnboarding = this.selectedUserRegistration?.customer?.customerOnboarding;

            const contracts = this.selectedUserRegistration?.customer?.customerContracts;
            if (contracts?.length) {
                this.selectedCustomerContracts = contracts
            } else {
                this.selectedCustomerContracts = null;
            }

            if (this.selectedCustomerOnboarding?.ticketDtoId) {
                this.refreshToolbarButton();
            }

            this.refreshWidget();

            this.busy = false;
            if (!this.initialized) {
                this.handleCheckForPreviousNextRegistration(FilterCriteria.cOperatorGreaterThan, true, () => {
                    this.handleCheckForPreviousNextRegistration(FilterCriteria.cOperatorLowerThan, false, () => {

                        if (this.selectedCustomerContracts?.length) {
                            this.handleContractList();
                        } else {
                            this.refreshComponent();
                        }

                    })
                })
            } else {
                // this.handleContractList();

                if (this.selectedCustomerContracts?.length) {
                    this.handleContractList();
                }
            }


        }, error => {
            this.busy = false;
            this.messageService.showErrorMessage('', error);
        });
    }

    /**
     * retrieve contract list related to customer
     */

    handleContractList() {

        if (this.busy) {
            return;
        }

        this.busy = true;

        const filterCriteriaList = [FilterCriteria.createOrFromArray("id", "contractDtoId", this.selectedCustomerContracts)];


        const complexRequest = ObjectRequestComplex.build(true, false,
            ObjectRequestComplexNode.createRelationNode("contractPartner",
                ObjectRequestRelation.createJoinWithBindingType('cm.ContractPartner#contract', ObjectRequestComplexRelationBindingEnum.LATEST)
            ),
            ObjectRequestComplexNode.createRelationNode("contractStatus",
                ObjectRequestRelation.createJoinWithBindingType('cm.ContractStatus#contract', ObjectRequestComplexRelationBindingEnum.LATEST)
            ),
            ObjectRequestComplexNode.createRelationNode("contractAgent",
                ObjectRequestRelation.createJoinWithBindingType('cm.ContractAgent#contract', ObjectRequestComplexRelationBindingEnum.LATEST)
            ),
            ObjectRequestComplexNode.createRelationNode("contractPerson",
                ObjectRequestRelation.createJoinWithBindingType('ci.ContractPerson#contract', ObjectRequestComplexRelationBindingEnum.ALL)
            ).addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('contractPersonType')),
            ObjectRequestComplexNode.createRelationNode("contractInsurableObject",
                ObjectRequestRelation.createJoinWithBindingType('ci.ContractInsurableObject#contract', ObjectRequestComplexRelationBindingEnum.ALL)
            ).addNodes(ObjectRequestComplexNode.createSimpleAttributeNode('insurableObject')));

        const dtoRequest = ObjectRequestList.createComplexRequestList(false, complexRequest, filterCriteriaList, [new Sorting("createdDate", false)], null)


        this.contractService.list(dtoRequest).subscribe(res => {

            this.contractsDtoList = res.entries;

            // check which steps are completed
            this.contractsDtoList.forEach(contract => {
                contract['completedTask'] = this.getCompletedTasks(contract);
            })

            // if a contract is selected, assign that contract to selectedContract
            if (this.selectedContractId) {
                this.selectedContract = this.contractsDtoList.find(item => item.id == this.selectedContractId);
            }

            this.refreshComponent();
            this.busy = false;
        }, error => {
            this.busy = false;
            this.messageService.showErrorMessage('', error);
        });
    }


    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.initialized = true;
    }

    /**
     * refresh widgets
     */
    refreshWidget() {

        if (this.selectedUserRegistrationId) {
            this.refreshRegistrationWidget();
        }

        if (this.selectedCustomer) {
            this.refreshCustomerWidget();
        }

        if (this.selectedCustomerOnboarding) {
            this.refreshOnboardingWidget();
        }

        if (this.selectedCustomerOnboarding?.appointmentDtoId) {
            this.refreshAppointmentWidget();
        }

        if (this.selectedCustomerUser) {
            this.refreshUserWidget();
            this.userAddedInsuranceWidget();
        }
    }

    /**
     * refresh registration widget
     */
    refreshRegistrationWidget() {
        this.registrationWidget = WidgetFactory.createWidget(
            'cr-manage-registration-user',
            "Registrierung",
            'data',
            'list',
            'entity',
            'eu.EuRegistration',
            new WidgetDataParam("objectId", this.selectedUserRegistrationId)
        );

    }

    /**
     * refresh registered customer widget
     */
    refreshCustomerWidget() {

        this.customerWidget = WidgetFactory.createWidget(
            'cr-manage-registration-user-customer-widget',
            "Kunde",
            'data',
            'list',
            'entity',
            'cr.Customer',
            new WidgetDataParam("objectId", this.selectedCustomer.id)
        );

    }

    /**
     * refresh onboarding widget
     */
    refreshOnboardingWidget() {

        this.onboardingWidget = WidgetFactory.createWidget(
            'cr-manage-registration-user-onboarding-widget',
            "Einarbeitung",
            'data',
            'list',
            'entity',
            'cr.CustomerOnboarding',
            new WidgetDataParam("objectId", this.selectedCustomerOnboarding?.id)
        );

    }

    /**
     * refresh appointment widget
     */
    refreshAppointmentWidget() {
        this.appointmentWidget = WidgetFactory.createWidget(
            'cr-manage-registration-user-appointment-widget',
            "Termin",
            'data',
            'list',
            'entity',
            'as.Appointment',
            new WidgetDataParam("objectId", this.selectedCustomerOnboarding?.appointmentDtoId)
        );
    }

    /**
     * refresh user widget
     */
    refreshUserWidget() {
        this.userWidget = WidgetFactory.createWidget(
            'cr-manage-registration-user-user-widget',
            "EuUser",
            'data',
            'list',
            'entity',
            'eu.EuUser',
            new WidgetDataParam("objectId", this.selectedCustomerUser.userDtoId)
        );
    }


    /**
     * refresh user added insurance widget
     */

    userAddedInsuranceWidget() {
        const filterCriteria = FilterCriteria.create('user', FilterCriteria.cOperatorEqual, this.selectedCustomerUser.userDtoId);

        const objectRequest = ObjectRequestList.createBasic(true, [filterCriteria], [new Sorting("id", false)]);

        this.userAddedInsurancesWidget = WidgetFactory.createWidgetList(
            'cr-manage-registration-user-added-insurance-widget',
            "Vom Benutzer hinzugefügte Versicherungen",
            'list',
            'list',
            'entity',
            'eu.EuUserAddedInsurance',
            'No Data',
            objectRequest,
        );
    }


    /**
     * on selecting user added insurance
     * @param objectIdentifier
     */
    handleUserAddedInsuranceObject(objectIdentifier: ObjectIdentifierData) {

        const insuranceItem: MenuItem = {label: 'Versicherung'};
        this.breadcrumbItems = [...this.breadcrumbItems, insuranceItem];
        this.selectedUserAddedInsuranceId = objectIdentifier.objectId;
        this.tabIndex = 2;

    }


    /**
     * navigate to next registration
     */
    navigateToNextRegistration() {
        if (this.disableNext) {
            return;
        }
        this.handleUpcomingRegistrationSelection(FilterCriteria.cOperatorLowerThan)
    }

    /**
     * navigate to previous registration
     */
    navigateToPreviousRegistration() {
        if (this.disablePrevious) {
            return;
        }
        this.handleUpcomingRegistrationSelection(FilterCriteria.cOperatorGreaterThan, true)
    }

    /**
     * handle current registration
     * @param operator
     * @param ascending
     */
    handleUpcomingRegistrationSelection(operator: string, ascending: boolean = false) {

        if (this.busy) {
            return;
        }

        // remove the contracts
        this.contractsDtoList = [];

        this.busy = true;

        const objectRequest = ObjectRequestList.createSimpleRequestList(
            false,
            ObjectRequestSimple.create(false, false, 0),
            this.getFilter(operator),
            [new Sorting("id", ascending)],
            PagingDto.create(0, 2));
        this.registrationService.list(objectRequest).subscribe(res => {
            this.userRegistrationDtoList = res.entries;

            this.busy = false;

            this.disableNext = false;
            this.disablePrevious = false;
            if (this.userRegistrationDtoList.length <= 1) {

                if (ascending) {
                    this.disablePrevious = true;
                } else {
                    this.disableNext = true;
                }
            }

            if (this.userRegistrationDtoList.length) {

                this.selectedUserRegistrationId = this.userRegistrationDtoList[0].id;
                this.router.navigate(['cr/registrationManage/', this.selectedUserRegistrationId], {
                    queryParams: {isPending: this.isPending ? 'true' : 'false'}
                });
                this.resetWidgets();


            } else {
                if (ascending) {
                    this.disablePrevious = true;
                } else {
                    this.disableNext = true;
                }
            }

        }, error => {
            this.messageService.showErrorMessage('', error);
        });
    }

    /**
     * check for previous items
     * @param operator
     * @param ascending
     * @param callBack
     */

    handleCheckForPreviousNextRegistration(operator: string, ascending: boolean = false, callBack: () => void) {


        const objectRequest = ObjectRequestList.createSimpleRequestList(
            false,
            ObjectRequestSimple.create(false, false, 0),
            this.getFilter(operator),
            [new Sorting("id", ascending)],
            PagingDto.create(0, 2));
        this.registrationService.list(objectRequest).subscribe(res => {

            if (res.entries.length >= 1) {

                if (ascending) {
                    this.disablePrevious = false;
                } else {
                    this.disableNext = false;
                }
            }

            callBack();

        });
    }

    /**
     * filter for precious or next registration
     * @param operator
     */

    getFilter(operator: string = FilterCriteria.cOperatorEqual): FilterCriteria[] {

        let filter: FilterCriteria[];

        if (this.isPending) {
            filter = FilterCriteria.createAnd(
                FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorNotEqual, 2),
                FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorNotEqual, 3),
                FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorNotEqual, 4),
                FilterCriteria.create('id', operator, this.selectedUserRegistrationId)
            );
        } else {
            filter = [FilterCriteria.createOr(
                FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorEqual, 2),
                FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorEqual, 3),
                FilterCriteria.create('registrationStatusInternal', FilterCriteria.cOperatorEqual, 4)),
                FilterCriteria.create('id', operator, this.selectedUserRegistrationId)];
        }

        return filter;
    }


    /**
     * reset all widgets
     */
    resetWidgets() {
        this.registrationWidget = null;
        this.customerWidget = null;
        this.onboardingWidget = null
        this.appointmentWidget = null;
        this.userWidget = null;
        this.userAddedInsurancesWidget = null;
    }

    /**
     * navigate to overview screen
     */
    navigateToOverviewPage() {
        this.router.navigate(['cr/registrationManage/']);
    }

    /**
     * display appointment ticket
     */
    showTicket() {
        const mvsObjectNavigationEntry = MvsObjectNavigationEntry.createNavigationEntry('tm.Ticket', this.selectedCustomerOnboarding?.ticketDtoId, null, "Object", null, null, MvsObjectNavigationActionEnum.any);
        this.navigationService.navigateTo(mvsObjectNavigationEntry, 'left');

    }

    /**
     * the toolbar button for ticket
     */
    refreshToolbarButton() {
        this.toolbarButton = [
            {
                label: null,
                icon: 'fa-solid fa-ticket border-green-500 border-bottom-2 line-height-2',
                display: true,
                action: 'ticket',
                tooltip: 'Show Ticket'
            }
        ];


    }

    /**
     * handling toolbar button click
     * @param button
     */
    handleToolbarButton(button: WidgetHelperButton) {
        if (button.action == 'ticket') {
            this.showTicket();
        }
    }

    /**
     * change detection from combined form component
     * @param objectChange
     */
    handleCombinedFormObjectChange(objectChange: ObjectChangeInformation) {
        if (objectChange.objectType == 'cm.Contract') {
            this.selectedContractId = objectChange.after.id;
            this.handleImportObjectContext();
        }
        this.refreshSelectedRegistrationUser();
    }

    /**
     * handle insurable object change
     * @param event
     */
    handleInsurableObjectChange(event: (ObjectChangeInformation | ObjectIdentifier)) {
        if (event.objectType == 'ci.ContractInsurableObject' || event.objectType == 'ci.ContractPerson') {
            this.refreshSelectedRegistrationUser();
        }
    }


    /**
     * get document status information
     */
    handleDocumentStatus() {
        this.selectedDocumentStatus = this.selectedDocument.documentDto.status;
        if (!this.documentStatusEntries?.length) {
            this.documentService.template(new DtoTemplate()).subscribe(res => {
                this.documentStatusEntries = res.getFormField('status').valueList.entries;
            });
        }
    }


    /**
     * update document status
     */
    handleDocumentChangeStatus() {

        if (this.busy) {
            return
        }

        this.busy = true;

        const dto: DmDocumentDto = new DmDocumentDto();
        dto.id = this.selectedDocument.documentDtoId;
        dto.status = this.selectedDocumentStatus;
        this.documentService.update(dto).subscribe(res => {
            this.selectedDocument.documentDto = <DmDocumentDto>res;
            if (!this.lastDocument) {
                this.direction = "next";
            }

            this.handleRemoveContractTab();

            this.busy = false;
        }, () => {
            this.busy = false;
        });
        this.direction = null;
    }

    /**
     * reset selected contract
     */
    resetContract() {
        this.selectedContractId = null;
        this.selectedContract = null;
        this.contextContractAndCustomer = null;
    }

    /**
     * update the status of registration
     * @param key
     */
    handleRegistrationChangeStatus(key: EuEuRegistrationStatusInternal) {

        if (this.busy) {
            return
        }

        this.busy = true;

        const dto = new EuRegistrationDto();
        dto.id = this.selectedUserRegistrationId;
        dto.registrationStatusInternal = key;
        dto.phoneNumber = this.selectedUserRegistration.phoneNumber;

        this.registrationService.update(dto).subscribe(() => {
            this.busy = false;
            this.refreshSelectedRegistrationUser();
        }, () => {
            this.busy = false;
        })
    }

    /**
     * check whether the tasks related to contracts are completed or not
     * @param contract
     */
    getCompletedTasks(contract: ContractDto) {

        const keysToCheck = ['contractAgent', 'contractPartner', 'contractStatus'];
        let count = 0;

        // Increment the count for each key that exists in the object
        keysToCheck.forEach(key => {
            if (contract.hasOwnProperty(key)) {
                count++;
            }
        });

        return count + 1;

    }

    /**
     * handle contract select
     * @param contract
     */
    handleContractSelect(contract: ContractDto, event: MouseEvent) {

        if (event.ctrlKey) {
            this.onCtrlClickContract(contract);
        } else {
            this.onSingleClickContract(contract);
        }


    }

    onSingleClickContract(contract: ContractDto) {
        this.selectedContract = contract;
        this.selectedContractId = contract.id;
        this.handleImportObjectContext();
        this.moveToContractTab();
    }

    onCtrlClickContract(contract: ContractDto) {

        const objectIdentifier = new ObjectIdentifier('cm.Contract', contract.id);

        this.navigationService.handleObjectNavigation(objectIdentifier, null, {openNewTab: true})

    }

    /**
     * move to contract tab
     */
    moveToContractTab() {
        setTimeout(() => {
            // Update activeIndex to the new tab's index after the tab is rendered
            this.tabIndex = 3;
        }, 0); // timeout set to 0 ms
    }

    /**
     * remove contract tab
     */
    handleRemoveContractTab() {
        this.resetContract();
        this.createContractBool = false;
        this.tabIndex = 2;
    }

    handleImportObjectContext() {
        this.contextContractAndCustomer = DtoImportObjectContext.createFromObjectIdentifiers(
            new ObjectIdentifier("cm.Contract", this.selectedContractId),
            new ObjectIdentifier("cr.Customer", this.selectedCustomer.id)
        );
    }

    addMeAsInsurablePerson() {
        this.handleImportObjectContext();
        this.contextContractAndCustomer.addEntry(new DtoImportObjectContextEntry("pm.Person", this.selectedCustomer.personDtoId));
    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["id"]) {
            this.refreshComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
