<ng-container *ngIf="initialized">

<!--    <tm-ticket-crete-new-task-->
<!--            (changeMode)="handleBackToTicket()"-->
<!--            *ngIf="mode == 'show-new-task'"-->
<!--    ></tm-ticket-crete-new-task>-->

<!--    <tm-ticket-select-predefine-task-->
<!--            (changeMode)="handleBackToTicket()"-->
<!--            *ngIf="mode == 'show-predefine-task'"-->
<!--    ></tm-ticket-select-predefine-task>-->

    <tm-ticket-completion
            (changeMode)="handleBackToTicket()"
            *ngIf="mode == 'show-complete'"
    ></tm-ticket-completion>


    <ng-container *ngIf="mode == 'show-process'">


<!--        <div (click)="handleBackToTicket()">-->
<!--            <i class="fa-solid fa-chevron-left"></i> Back To Ticket-->
<!--        </div>-->
<!--        <hr>-->

        <mvs-wf-process
                [objectIdentifier]="objectIdentifier"
                [processMode]="processMode"
                [fullPage]="fullPage"
        ></mvs-wf-process>

    </ng-container>

</ng-container>