<ng-container *ngIf="initialized">
    <div class="task-list">
        <h3>Predefined tasks</h3>

        <ng-container
                *ngIf="uiAvailableActions.length">
<!--            <ng-container *ngFor="let task of availableActionsResponse?.availableActions">-->
<!--                <div class="flex justify-content-between align-item-center mb-3 border-1 border-200 p-3 border-round-lg cursor-pointer"-->
<!--                     *ngIf="task.ticketTypeAction.internalType != TmTicketTypeActionInternalTypeEnum.user_defined"-->
<!--                     [class.bg-primary-50]="task.selected"-->
<!--                     (click)="taskSelection(task)">-->

<!--                    {{task.ticketTypeAction.internalType}}-->

<!--                    <div class="flex align-items-center">-->
<!--                        <p-checkbox-->
<!--                                [(ngModel)]="task.selected"-->
<!--                                (onChange)="onCheckboxChange($event, task)"-->
<!--                                [binary]="true">-->
<!--                        </p-checkbox>-->

<!--                        <span class="ml-2">-->
<!--                            <ng-container *ngIf="task.ticketTypeAction.objectRoleDtoName; else defaultLabel">-->
<!--                                {{ task.ticketTypeAction.objectRoleDtoName }} - {{ task.ticketTypeAction.name }}-->
<!--                            </ng-container>-->
<!--                            <ng-template #defaultLabel>-->
<!--                                {{ task.ticketTypeAction.name }}-->
<!--                            </ng-template>-->


<!--                    </span>-->
<!--                    </div>-->
<!--                    <p-tag  *ngIf="task.exists" value="Already Exists" rounded="true" styleClass="text-500 border-1 border-400 bg-transparent"></p-tag>-->

<!--                </div>-->
<!--            </ng-container>-->

            <ng-container *ngFor="let item of uiAvailableActions">

                <h4>{{item.label}}</h4>

                @for (task of item.availableActions; track task.ticketTypeAction.id) {
                    <div class="flex justify-content-between align-item-center mb-3 border-1 border-200 p-3 border-round-lg cursor-pointer"
                         *ngIf="task.ticketTypeAction.internalType != TmTicketTypeActionInternalTypeEnum.user_defined"
                         [class.bg-primary-50]="task.selected"
                         (click)="taskSelection(task)">



                        <div class="flex align-items-center">
                            <p-checkbox
                                    [(ngModel)]="task.selected"
                                    (onChange)="onCheckboxChange($event, task)"
                                    [binary]="true">
                            </p-checkbox>

                            <span class="ml-2">
                            <ng-container *ngIf="task.ticketTypeAction.objectRoleDtoName; else defaultLabel">
                                {{ task.ticketTypeAction.objectRoleDtoName }} - {{ task.ticketTypeAction.name }}
                            </ng-container>
                            <ng-template #defaultLabel>
                                {{ task.ticketTypeAction.name }}
                            </ng-template>


                    </span>
                        </div>
                        <p-tag *ngIf="task.exists" value="Already Exists" rounded="true" styleClass="text-500 border-1 border-400 bg-transparent"></p-tag>

                    </div>
                }


            </ng-container>

            <div class="flex justify-content-end mt-5"
                 *ngIf="uiAvailableActions.length">
                <p-button [disabled]="busy" label="Save" styleClass="px-5" (click)="handleClickTicketAction()"></p-button>
            </div>

        </ng-container>

        <ng-container
                *ngIf="!uiAvailableActions.length">
            Keine Aktionen verfügbar
        </ng-container>

    </div>
</ng-container>

