<ng-container *ngIf="initialized">
    <form [formGroup]="widgetUiField">
        <div formGroupName="uiConfigData">
            <div class="grid">
                <div class="col-12">
                    <h4>Link Report Config</h4>
                </div>

                <!-- STILL: top-level fields with formControlName -->
                <div class="col-3">
                    <label for="module">Module</label>
                    <p-dropdown
                            [options]="moduleList"
                            formControlName="module"
                            id="module"
                            appendTo="body"
                            class="w-full"
                            optionLabel="label"
                            optionValue="name"
                            styleClass="w-full"
                            placeholder="Select Module"
                            (onChange)="refreshCategories()"
                    >
                    </p-dropdown>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="category">Category</label>
                        <p-dropdown
                                [options]="categoryList"
                                formControlName="category"
                                id="category"
                                appendTo="body"
                                class="w-full"
                                optionLabel="label"
                                optionValue="id"
                                styleClass="w-full"
                                placeholder="Select Category"
                                (onChange)="refreshReports()"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="report">Report</label>
                        <p-dropdown
                                [options]="reportList"
                                formControlName="report"
                                id="report"
                                appendTo="body"
                                class="w-full"
                                optionLabel="label"
                                optionValue="id"
                                styleClass="w-full"
                                placeholder="Select Report"
                                (onChange)="refreshFields()"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-3">
                    <div class="flex flex-column">
                        <label for="location">Location</label>
                        <p-dropdown
                                [options]="locationList"
                                id="location"
                                formControlName="location"
                                class="w-full"
                                styleClass="w-full"
                                placeholder="Select Location"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <!-- This is the FormArray for linkages -->
                <div class="col-12">
                    <h5>Report Linkages</h5>
                </div>

                <!-- We bind the array name "reportLinkage" -->
                <div formArrayName="reportLinkage">
                    <!-- Each item is a FormGroup. We loop with *ngFor, reference i as the index. -->
                    <div
                            class="grid align-items-center gap-2"
                            style="margin-bottom: 1rem;"
                            *ngFor="let group of reportLinkageArray.controls; let i = index"
                            [formGroupName]="i"
                    >
                        <!-- "group" is a FormGroup with thisReportField, toReportField, operator -->

                        <div class="col-3">
                            <label for="thisReportField-{{ i }}">This Report Field</label>
                            <p-dropdown
                                    id="thisReportField-{{ i }}"
                                    [options]="formFields"
                                    optionLabel="uiLabel"
                                    optionValue="id"
                                    placeholder="Select This Field"
                                    class="w-full"
                                    formControlName="thisReportField"
                            >
                            </p-dropdown>
                        </div>

                        <div class="col-3">
                            <label for="toReportField-{{ i }}">To Report Field</label>
                            <p-dropdown
                                    id="toReportField-{{ i }}"
                                    [options]="fieldList"
                                    optionLabel="uiLabel"
                                    optionValue="id"
                                    placeholder="Select Target Field"
                                    class="w-full"
                                    formControlName="toReportField"
                            >
                            </p-dropdown>
                        </div>

                        <div class="col-3">
                            <label for="operator-{{ i }}">Operator</label>
                            <p-dropdown
                                    id="operator-{{ i }}"
                                    [options]="operatorList"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Select Operator"
                                    class="w-full"
                                    formControlName="operator"
                            >
                            </p-dropdown>
                        </div>

                        <div
                                class="col-2 flex justify-content-center align-items-end"
                                style="margin-top: 1.5rem;"
                        >
                            <button
                                    pButton
                                    type="button"
                                    icon="pi pi-trash"
                                    class="p-button-danger"
                                    (click)="removeLinkage(i)"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Add new linkage row -->
                <div class="col-12" style="margin-top: 1rem;">
                    <button
                            pButton
                            type="button"
                            icon="pi pi-plus"
                            label="Add Linkage"
                            (click)="addNewLinkage()"
                    >
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-container>
