import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    DataType,
    DataTypeHelper,
    DtoDetail,
    DtoImportObjectContext,
    DtoList,
    FilterCriteria,
    IconClassHelper,
    MetaDataEntityDto,
    MetaDataJoinRelationEnum,
    MetaService,
    MvsCoreService,
    MvsCrudModeEnum,
    MvsCrudService,
    MvsFormControlOverwrite,
    MvsFormFieldAccessEnum,
    MvsMessageService,
    ObjectIdentifier,
    ObjectRequestComplex,
    ObjectRequestComplexNode,
    ObjectRequestList,
    ObjectTypeService,
    Sorting
} from "@kvers/alpha-core-common";
import {ConfirmationService, MenuItem, MessageService, TreeNode} from 'primeng/api';
import {firstValueFrom} from 'rxjs';
import {MvsUiObjectService} from "@kvers/alpha-ui";
import {OverlayPanel} from "primeng/overlaypanel";
import {ObjectTypeDto} from "../../../cc/dto/object-type.dto";
import {TeContentTypeEnum} from "../../../te/enum/te-content-type.enum";
import {TeVariableProviderService} from "../../../te/service/api/interfaces/te-variable-provider.service";
import {LogicImportDto} from "../../dto/logic-import.dto";
import {LogicExportDto} from "../../dto/logic-export.dto";
import {FieldTypeService} from "../../../cc/service/api/field-type.service";

@Component({
    selector: "mvs-lg-variables",
    templateUrl: "./lg-variables.component.html",
    styleUrls: ["./lg-variables.component.scss"],
})
export class LgVariablesComponent
    implements OnInit, OnChanges, OnDestroy {

    busy: boolean; // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    @Input() contentType: TeContentTypeEnum;
    @Input() contentProviderId: number;
    @Input() variableProviderService: MvsCrudService;
    @Input() alias: string;
    @Input() attributeName: string;
    @Input() title: string;

    // When add button is active, then the user is able to add variable.
    @Input() isAddButtonShown?: boolean = true;
    // When title is active, then the title will be shown on the header.
    @Input() isTitleShown?: boolean = true;
    // we can pass the tree width for required width.
    @Input() treeWidth?: boolean = false;
    // When drag mode is active, then the user is able to drag one variable into the editor.
    @Input() dragMode?: boolean = true;

    @Input() selectMode?: string = null;

    @Output() onVariableSelect: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("simpleVariable", {static: false}) simpleVariablePanel: OverlayPanel;
    @ViewChild("objectVariable", {static: false}) objectVariablePanel: OverlayPanel;
    objectTypes: ObjectTypeDto[];

    variableName: string;

    selectedObjectType: ObjectTypeDto;
    variables: TreeNode[] = [];
    selectionNode: TreeNode;
    selectedNode: TreeNode;
    configurationItems: MenuItem[] | undefined;
    variableToggle: boolean;

    items!: MenuItem[];
    selectedFile!: TreeNode | null;


    constructor(
        protected coreService: MvsCoreService,
        protected metaService: MetaService,
        protected messageService: MvsMessageService,
        protected toast: MessageService,
        protected objectService: MvsUiObjectService,
        protected confirmationService: ConfirmationService,
        protected objectTypeService: ObjectTypeService,
        protected fieldTypeService: FieldTypeService,
    ) {
    }

    ngOnInit(): void {
        this.initComponent();

        this.items = [
            {label: 'Copy', icon: 'pi pi-clipboard', command: (event) => this.copyToClipboard()},
        ];
    }

    copyToClipboard() {

        const node = this.selectionNode[0];

        const insertText = this.findParentNodes(node);


        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = insertText || '';
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    /**
     * Initialize Component.
     */
    initComponent() {

        this.variableProviderService = <MvsCrudService & TeVariableProviderService>this.coreService.getCrudService(this.alias);

        this.refreshComponent();
    }

    /**
     * Refresh Component.
     */
    refreshComponent() {
        this.getAllObjectTypes();
        this.refreshVariables();
        this.handleConfigurationItems();
        this.initialized = true;
    }


    getAllObjectTypes() {
        this.objectTypeService.list(
            new ObjectRequestList(false, [], [new Sorting("alias", true)])
        ).subscribe((res: DtoList<ObjectTypeDto>) => {
            this.objectTypes = res.entries;
            this.objectTypes.sort((a, b) => a.alias.localeCompare(b.alias));
        });
    }

    /**
     * Retrieve variables.
     */
    refreshVariables() {


        const filterCriteria = FilterCriteria.create('logic', FilterCriteria.cOperatorEqual, this.contentProviderId);


        const objectRequest = ObjectRequestList.createComplexRequestList(
            false,
            ObjectRequestComplex.build(true, false,
                ObjectRequestComplexNode.createSimpleAttributeNode("fieldType")
            ),
            [filterCriteria],
            [],
            null
        )

        this.variableProviderService.list(objectRequest).subscribe(value => {
            this.variables = value.entries.map(variable => this.createRootNode(variable, ''));
        });

    }


    handleEditVariableName(id: number) {
        this.objectService.openObjectViaDialog(
            new DtoImportObjectContext([]),
            this.alias,
            id,
            null,
            false,
            false,
            () => {
                this.refreshVariables();
            },
            MvsCrudModeEnum.create
        );
    }

    handleDeleteVariableName(id: number): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteVariableName(id);
            },
            reject: () => {
            },
        });
    }

    deleteVariableName(id: number) {
        this.busy = true;
        this.variableProviderService.delete(id).subscribe(response => {

            this.busy = false;
            this.messageService.showSuccessMessage("Template", "Variable Deleted");

            this.refreshVariables();

        }, error => {

            this.busy = false;
            this.messageService.showErrorMessage("Template", "Error occured during delete");
        });
    }

    createRootNode(nodeItem: LogicImportDto | LogicExportDto, iconType: string): TreeNode {

        let expandedIcon: string = 'pi pi-folder-open';
        let collapsedIcon: string = 'pi pi-folder';

        const label = nodeItem[this.attributeName];

        return {
            label: label,
            data: nodeItem,
            expandedIcon: expandedIcon,
            collapsedIcon: collapsedIcon,
            leaf: true,
            children: [{}],
            type: 'actions'
        };
    }


    createNode(nodeItem: any, iconType: string): TreeNode {

        // set label for node
        const label = (nodeItem.typeId || nodeItem.typeName) ? nodeItem.typeName : nodeItem.name;

        let icon;

        switch (iconType) {
            case 'joins':
                switch (nodeItem.joinRelationEnum) {
                    case MetaDataJoinRelationEnum.oneToOne: //'oneToOne':
                        icon = 'fa-regular fa-list';
                        break;
                    case MetaDataJoinRelationEnum.manyToOne: //'manyToOne':
                        icon = 'fa-regular fa-table-list';
                        break;
                    case MetaDataJoinRelationEnum.oneToMany: //'oneToMany':
                        icon = 'fa-regular fa-table-cells';
                        break;
                    default:
                        break;
                }
                break;
            case 'generic':
                icon = 'pi pi-th-large';
                break;
            default:
                icon = 'pi pi-folder';
                break;
        }
        const expandedIcon: string = icon == 'pi pi-folder' ? 'pi pi-folder-open' : icon;

        let node: TreeNode = {
            label: label,
            data: nodeItem,
            expandedIcon: expandedIcon,
            collapsedIcon: icon,
            leaf: true,
            children: [{}],
            type: 'actions'
        };

        return node;
    }


    nodeExpandOnMetaData(node: TreeNode, res: MetaDataEntityDto) {

        // declaring variables
        let childrens: any = [];
        let genericType: any;
        let joins: any;

        const attributes = res.attributes;

        // assigning node attributes
        const children = attributes.map((element: any) => {
            // returns attribute object
            return this.setAttributes(element);
        });
        // assigning node sub joins
        joins = res.joins.map((items: any) => this.createNode(items, 'joins'));

        // assigning node generic types if any
        if (res.genericType) {
            genericType = res.genericTypes.map((items: any) => this.createNode(items, 'generic'));
        }

        // merging/concating all attributes joins and generic types
        childrens = res.genericType ? childrens.concat(genericType, children, joins) : childrens.concat(children, joins);


        // assigning childrens to node
        node.children = childrens;
    }

    /**
     * Expand Nodes.
     * @param event
     */
    nodeExpand(event: any) {

        const node: LogicImportDto | LogicExportDto = event.node.data;

        this.fieldTypeService.get(node.fieldTypeDtoId).subscribe(fieldType => {
        })

        if (node?.fieldTypeDto?.dataType == DataType.Object || event?.node?.data?.joinObjectTypeId) {

            const id = node?.fieldTypeDto?.dataTypeObjectTypeDtoId ? node?.fieldTypeDto?.dataTypeObjectTypeDtoId : event?.node?.data?.joinObjectTypeId;

            this.busy = true;

            firstValueFrom(this.metaService.getById(id, true, true, true, true, true)).then(
                (res: MetaDataEntityDto) => {

                    if (res.joins) {
                        res.joins.sort((a, b) => a.name.localeCompare(b.name));
                    }

                    this.nodeExpandOnMetaData(event.node, res);

                    this.busy = false;
                });

        } else if (event.node.data.typeId) {

            // assigning node attributes
            event.node.children = event.node.data.attributes.map((element: any) => {
                // returns attribute object
                return this.setAttributes(element);
            });

            this.busy = false;

        } else {

            // assigning simple object to variable type simple
            event.node.children = null
            this.busy = false;
        }
    }

    setAttributes(nodeItem: any) {
        // Function for setting attributes in nodes

        const dataType = DataTypeHelper.getInternalDataTypeForFormField(nodeItem);
        const icon = IconClassHelper.getIconClass(dataType);
        // = this.mvsDataTypeIcons(dataType);

        return {
            // label: nodeItem.attributeName,
            label: nodeItem.label,
            data: nodeItem,
            collapsedIcon: icon,
            leaf: true
        };
    }


    nodeSelect(selectedNode: any) {
        this.selectedNode = selectedNode;
        this.onVariableSelect.emit(selectedNode);
    }

    /**
     * slide menu configuration
     */
    handleConfigurationItems() {

        this.configurationItems = [
            {
                label: 'Create Variable',
                icon: 'fa-solid fa-plus',
                command: (event: any) => this.toggleDialog(true)
            },
        ];
    }

    toggleDialog(toggle: boolean) {
        // this.visible = true;
        this.variableToggle = toggle;

        //const objectContext = DtoImportObjectContext.createFromObjectIdentifier(new ObjectIdentifier(this.alias, this.contentProviderId))

        const defaultDto: DtoDetail = new DtoDetail();

        defaultDto['logicDtoId'] = this.contentProviderId;

        const overwrite = new MvsFormControlOverwrite();
        overwrite.addField("logicDtoId", MvsFormFieldAccessEnum.READ);

        this.objectService.openObjectViaDialog(
            DtoImportObjectContext.createEmpty(),
            this.alias,
            0,
            defaultDto,
            false,
            false,
            () => {
                this.refreshVariables();
            },
            MvsCrudModeEnum.create,
            null,
            overwrite
        );

    }

    private getAliasFromNode(node: TreeNode): string {

        if (node.data["attributeName"]) {

            if ((node.data["attributeName"] as string).charAt(0) == "+") {
                return "metaAttribute_" + (node.data["attributeName"] as string).substring(1);
            }
            return node.data["attributeName"];

        } else if (node.data["alias"]) {
            return node.data["alias"];

        } else if (node.data["name"]) {

            let join = node.data["name"];
            join = join.replace('#', '_attr_');
            join = join.replace('.', '_');

            if ((join as string).charAt(0) == "+") {
                return "metaJoin_" + (join as string).substring(1);
            }
            return join;

        } else {

        }

        return "";

    }

    // find attribute parent nodes
    private findParentNodes(treeNode: TreeNode) {

        let variableName = "";

        const aliasFromNode = this.getAliasFromNode(treeNode);
        variableName = aliasFromNode;

        let node = treeNode.parent;
        while (node) {

            const aliasFromSubNode = this.getAliasFromNode(node);
            variableName = aliasFromSubNode + "." + variableName;

            node = node.parent;

        }

        return variableName;

    }


    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.initialized) {
            return;
        }

        this.initComponent();
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {
    }


}
