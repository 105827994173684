import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WidgetData} from "@kvers/alpha-core-common";
import {WidgetFactory} from "@kvers/alpha-ui";
import {ObjectRequestList} from "@kvers/alpha-core-common";
import {FilterCriteria} from "@kvers/alpha-core-common";
import {AgentPoolAgentService} from "../../service/api/agent-pool-agent.service";


@Component({
    selector: 'am-agent-pool-info-detail-object',
    templateUrl: './agent-pool-info-detail-object.component.html',
})
export class AgentPoolInfoDetailObjectComponent implements OnInit, OnChanges, OnDestroy {

    @Input() agentPoolId: number;
    agentIds: number[];

    tabIndex: number = 0;

    agentPoolWidget: WidgetData;
    agentPoolContactWidget: WidgetData;

    busy: boolean;  // indicator whether the component is busy
    initialized: boolean; // indicator whether the component was initialized

    constructor(protected agentPoolAgentService: AgentPoolAgentService) {
    }

    ngOnInit(): void {
        this.initComponent();

    }

    /**
     * Initialize Component.
     */
    initComponent() {

        const filterCriteria = FilterCriteria.create('agentPool', FilterCriteria.cOperatorEqual, this.agentPoolId)

        const objectRequest = new ObjectRequestList(false, [filterCriteria], []);

        this.agentPoolAgentService.list(objectRequest).subscribe(res => {
            this.agentIds = res.entries.map(item => item.agentDtoId);
            this.refreshComponent();
        })

    }

    /**
     * Refresh Component.
     */
    refreshComponent() {

        this.refreshWidgets();
        this.initialized = true;
    }

    /**
     * refresh all widgets
     */
    refreshWidgets() {
        this.refreshAgentPoolWidget();
        this.refreshAgentPoolContactWidget();
    }

    /**
     * refresh agent pool widget
     */
    refreshAgentPoolWidget() {
        this.agentPoolWidget = WidgetFactory.createWidgetEntityData(
            'am.agent.pool.info.detail',
            'Agent Pool',
            'am.AgentPool',
            this.agentPoolId,
        );
    }

    /**
     * refresh agent pool contact widget
     */
    refreshAgentPoolContactWidget() {
        const dtoRequest = new ObjectRequestList(
            true,
            [FilterCriteria.create('agentPool', FilterCriteria.cOperatorEqual, this.agentPoolId)],
            []
        );

        this.agentPoolContactWidget = WidgetFactory.createWidgetList(
            'am.agent.pool.contact.info.detail',
            'Agent Pool Contact',
            'list',
            'list',
            'entity',
            'am.AgentPoolContact',
            'Keine Daten vorhanden',
            dtoRequest
        );
    }

    /**
     * Process changes within Binding.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {

        if (!this.initialized) {
            return;
        }

        if (changes["agentPoolId"]) {
            this.initComponent();
        }
    }

    /**
     * Destroy component.
     */
    ngOnDestroy(): void {

    }

}
