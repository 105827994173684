import { TicketDto } from "./ticket.dto";
import { TicketTypeObjectRoleDto } from "./ticket-type-object-role.dto";
import {DtoDetail, ObjectTypeDto} from "@kvers/alpha-core-common";

export class TicketObjectDto extends DtoDetail {


    public ticketDto? : TicketDto;
    public ticketDtoId : number;
    public ticketDtoName : string;
    public objectTypeDto? : ObjectTypeDto;
    public objectTypeDtoId : number;
    public objectTypeDtoName : string;
    public objectId: number;
    public ticketTypeObjectRoleDto? : TicketTypeObjectRoleDto;
    public ticketTypeObjectRoleDtoId : number;
    public ticketTypeObjectRoleDtoName : string;

}